import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { ShipmentTransportationPaymentStatus } from "@features/transportation-payments/enums/shipment-transportation-payment-status.enum";
import { User } from '../../users/models/user';
import { ShipmentInvoice } from '../../shipments/models/shipment-invoice.model';

export class InvoiceTransportation extends Model {
  @Expose()
  amount: string;

  @Expose()
  bankReference: string;

  @Expose()
  createdAt: number;

  @Type(() => User)
  @Expose()
  createdBy: User;

  @Expose()
  endDate: number;

  @Expose()
  id: number;

  @Expose()
  rejectedBy: any;

  @Expose()
  rejectionReason: string;

  @Expose()
  startDate: number;

  @Expose()
  status: ShipmentTransportationPaymentStatus;

  @Expose()
  paidAt: number;

  @Expose()
  updatedAt: number;
}
