import { Pipe, PipeTransform } from '@angular/core';
import { FunctionUtil } from '@shared/utils/function.util';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(value: number): string {
    return FunctionUtil.formatFileSize(value);
  }

}
