import { hashString } from '@shared/utils/hash-string';
import { getValueByPath } from '@shared/utils/get-value-by-path.util';

export class LazyDropdownOption <T> {
  selected = false;
  highlighted = false;
  display: string;
  value: any;
  id: string;
  disabled = false;

  static generateIdFromOption(value: any): string {
    return `${hashString(`${JSON.stringify(value).replace(/[{}\[\],"]/g, '')}`)}`;
  }

  constructor(public option: T, public index: number, valuePath: string, displayPath: string) {
    this.display = getValueByPath(option, displayPath);
    this.value = getValueByPath(option, valuePath);
    this.id = LazyDropdownOption.generateIdFromOption(this.value);
  }

  isEqualTo(option: LazyDropdownOption<T>): boolean {
    return this.id === option.id;
  }
}
