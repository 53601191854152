import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class DriverReport extends Model {
    @Expose() id: number;
    @Expose() name: string;
    @Expose() iqama: string;
    @Expose() Sponsor: string;
    @Expose() noOfShipment: number;
    @Expose() mobileNumber: string;
    @Expose() truckId: number;
    @Expose() truckType: string;
    @Expose() truckSponsor: string;
    @Expose() plateNumber: string;
    @Expose() model: number;
}

export class DriverReportWithCount extends Model {
    @Expose()
    @Type(() => DriverReport)
    data: DriverReport[];

    @Expose() count: number;
}
