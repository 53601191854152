export class ColorsConstant {
  static Primary100 = '#C6D3F2';
  static Primary200 = '#2953B2';
  static Primary300 = '#9CB3E8';
  static Primary400 = '#20408A'
  static Success100 = '#00875A';
  static Success200 = '#00875A';
  static Success300 = '#80C3AD';
  static MdAccent100 = '#DD3A2A';
  static MdAccent200 = '#DD3A2A';
  static MdAccent300 = '#EE9C94';
  static MdAccent500 = '#F16344';
}
