<div class="header" [class.rtl]="layoutService.websiteDirection$.value === 'rtl'">
  <div class="container">
    <div class="row align-center header__row">
      <div class="col-auto">
        <app-logo></app-logo>
      </div>

      <div class="col-auto ml-auto">
        <div class="flex align-center">
          <div class="col-auto">
            <app-search></app-search>
          </div>

          <div class="header__notification-icon px-2 mx-3">
            <button mat-button (click)="onNotificationsButtonClicked()" class="d-inline">
              <mat-icon
                [matBadge]="unreadCount > 99 ? '99+': unreadCount"
                [matBadgeHidden]="unreadCount < 1"
                matBadgeSize="small"
                [matBadgeColor]="'warn'"
                class="d-inline align-center"
              >
                <app-icon class="d-inline align-center" name="notification"></app-icon>
              </mat-icon>
            </button>
          </div>

          <div class="d-none d-block-md">
            <button
              mat-button
              class="d-none d-inline-block-sm"
              [popoverTrigger]="popoverMenu"
              #popoverInstance="popoverTrigger"
              [closeOnClickOutside]="true"
              [position]="position"
            >
              <span class="flex align-center">
                <app-icon name="arrow_drop_down" type="MaterialDesign" class="d-inline icon icon--small align-center"></app-icon>

                <span>{{userFirstName}} {{userLastName}}</span>

                <span class="flex align-center icon icon--small ml-2">
                  <img class="w-100 h-100" src="../../../../assets/images/avatar.min.svg" />
                </span>
              </span>
            </button>

            <ng-template #popoverMenu>
              <mat-nav-list class="header__popover-container">
                <mat-list-item class="my-3">
                  <div class="flex align-center">
                    <img class="mr-2" src="../../../../assets/images/avatar.med.svg" />
                    <div class="flex column m">
                      <span class="header__avatar-title">{{userFirstName}} {{userLastName}}</span>
                      <span class="header__avatar-subtitle">{{userEmail}}</span>
                    </div>
                  </div>
                </mat-list-item>

                <mat-divider></mat-divider>

                <app-avatar-Menu (click)="popoverInstance.close()"></app-avatar-Menu>
                <div class="flex align-center justify-center m-2 text-300">V{{ConfigConstant.VERSION}}</div>
              </mat-nav-list>
            </ng-template>
          </div>

          <div class="col-auto d-block d-none-md" [ngClass]="{ 'navbar--active': openMenu }">
            <button mat-icon-button (click)="handelClick()" class="navbar__toggle">
              <span class="navbar__toggle-bars">
                <span class="navbar__toggle-bar"></span>
                <span class="navbar__toggle-bar"></span>
                <span class="navbar__toggle-bar"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
