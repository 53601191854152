<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form" [formGroup]="form" *ngIf="sectionState === sectionStateStatus.Ready">
    <div class="flex column" *ngIf="!isB2c">
      <mat-label class="field-label">{{ "Filter.Form.CustomerName" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select multiple [placeholder]="'Placeholder.Search' | translate" formControlName="customerId">
          <div class="search-field">
            <app-icon matPrefix name="search" class="search-icon"></app-icon>

            <input
              matInput
              type="text"
              tabindex="1"
              placeholder="{{ 'Placeholder.Search' | translate }}"
              (keydown)="$event?.stopPropagation()"
              [formControl]="customersSearch"
            />
          </div>
          <mat-checkbox
            app-click-stop-propagation
            class="mat-option"
            tabindex="-1"
            [disableRipple]="true"
            [indeterminate]="isIndeterminate()"
            [checked]="isChecked()"
            (change)="onToggleSelection($event)"
          >
            {{ "Options.SELECT_ALL" | translate }}
          </mat-checkbox>
          <mat-option tabindex="-1" *ngFor="let item of searchResultCustomers | sortDropdown" [value]="item.id">{{ item.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-row>
      <mat-label class="field-label">{{ "Filter.Form.FleetName" | translate }}</mat-label>
      <app-col cols="12">
        <app-dropdown [list]="fleets" [multipleMode]="true" formControlName="fleetId"></app-dropdown>
      </app-col>
    </app-row>

    <div class="flex column" *ngIf="showCreatorByField && !isB2c">
      <div class="field flex column">
        <mat-label class="field-label">{{ "Filter.Form.CreatedBy" | translate }}</mat-label>
        <mat-form-field appearance="outline" class="form-control-full-width">
          <mat-select formControlName="creator">
            <app-dropdown-search formControlName="creatorSearchTerm"></app-dropdown-search>
            <mat-option [value]="sharedConstants.ALL">{{ 'Options.ALL' | translate }}</mat-option>
            <mat-option *ngFor="let item of filteredCreators | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.AssignedBy" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="assignedBy">
          <app-dropdown-search formControlName="assignedBySearchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">{{ 'Options.ALL' | translate }}</mat-option>
          <mat-option *ngFor="let item of filteredAssignedBy | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="field flex column">
      <div class="field flex column">
        <mat-label>{{ 'Dashboard.CustomerLabel' | translate }}</mat-label>
        <app-col cols="12">
          <app-dropdown [list]="companyLabels" [multipleMode]="true" formControlName="customerLabel"></app-dropdown>
        </app-col>
      </div>
    </div>

    <div class="flex" *ngIf="!isB2c">
      <mat-checkbox formControlName="withoutPrice" (change)="onWithoutPriceSelectionChange($event)">
        {{ "Filter.Form.WithoutPrice" | translate}}
      </mat-checkbox>
    </div>

    <div class="flex" *ngIf="!isB2c">
      <mat-checkbox formControlName="withoutDeliveryNote" (change)="onWithoutDeliveryNoteSelectionChange($event)">
        {{ "Filter.Form.WithoutDeliveryNote" | translate}}
      </mat-checkbox>
    </div>

    <div class="flex" *ngIf="!isB2c">
      <mat-checkbox formControlName="isReceived" (change)="onIsReceivedSelectionChange($event)">
        {{ "Shipments.Table.IsReceived" | translate}}
      </mat-checkbox>
    </div>

    <div class="py-3">
      <mat-divider></mat-divider>

      <div class="flex py-3">
        <img src="../../../../../assets/images/star.svg" alt="" />
        <span class="pl-2 weight-bold">{{ "Filter.ApplyMyFilter" | translate}}</span>
        <img
          [matTooltip]="'Filter.Form.exclamationMark' | translate"
          matTooltipPosition="above"
          class="pl-2"
          src="../../../../../assets/images/exclamation_mark.svg"
          alt=""
        />
      </div>

      <div class="control-list">
        <ng-container *ngIf="favFilters && favFilters.length > 0; else filterEmpty">
          <div *ngFor="let filter of favFilters; let i = index" class="control-list__item mb-1">
            <app-row gutter="x-sm" align="center">
              <app-col cols="2">
                <mat-radio-button [value]="filter" [checked]="selectedFilter === filter" (click)="selectFilter(filter,i)"></mat-radio-button>
              </app-col>

              <app-col cols="6">
                <div>{{ filter.filterName }}</div>
              </app-col>

              <app-col cols="4">
                <div class="flex align-center justify-flex-end">
                  <button mat-icon-button type="button" (click)="editFilter(filter , i)">
                    <app-icon name="edit" type="MaterialDesign"></app-icon>
                  </button>

                  <button mat-icon-button type="button" (click)="deleteFilter(filter)">
                    <app-icon name="remove_circle" type="MaterialDesign"></app-icon>
                  </button>
                </div>
              </app-col>
            </app-row>
          </div>
        </ng-container>

        <ng-template #filterEmpty>
          <span class="small text-muted">{{ "Filter.NoCustomFilterAdded" | translate}}</span>
        </ng-template>
      </div>

      <div class="field flex py-1">
        <button mat-button class="px-0" (click)="createFilter()" [disabled]="favFilters?.length >= 6">
          <img src="../../../../../assets/images/arrow_right.svg" alt="" />
          <span class="text-small pl-2 text-primary">{{ "Filter.CreateCustomFilter" | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</app-state-section>
