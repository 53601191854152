import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { TabModel } from '../../model/tab.model';
import { Icon } from '@shared/model/icon.model';
import { ButtonTheme } from "@shared/enums/button-theme.enum";

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
})
export class FilterButtonComponent implements OnInit {
  @Input() filterNavigationConfig: TabModel = null;
  @Output() clicked = new EventEmitter();
  @Input() theme: ButtonTheme = ButtonTheme.Flat;
  @Input() size: 'sm'| 'md' = 'md';

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {}

  onFilterButtonClicked() {
    this.filterService.filterNavigationConfig$.next(this.filterNavigationConfig);
    this.clicked.emit();
  }
}
