
import {Expose, Type} from "class-transformer";
import { DeliveryNote } from "../../invoices/models/delivery-note";
import { Model } from "../../shared-features/models/model";

export class FleetDeliveryNoteShipments extends Model {
  @Type(() => DeliveryNote)
  @Expose() shipmentDeliveryNoteList: DeliveryNote[];
  @Expose() count: number;
}
