import { Component, EventEmitter,  Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Customer } from '@features/customers/models/customer';
import { Fleet } from '@features/fleets/models/fleet';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { UserType } from '@features/users/enums/user-type.enum';
import { User } from '@features/users/models/user';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { UserPreferencesService } from '@shared-features/services/user-preferences.service';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { convertToArray } from '@shared/utils/parse-query-param';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-shipment-list-form-fav-section',
  templateUrl: './shipment-list-form-fav-section.component.html',
  styleUrls: ['./shipment-list-form-fav-section.component.scss']
})
export class ShipmentListFormFavSectionComponent extends BaseComponent implements OnInit {
  @Input() form:FormGroup
  @Input() favFiltersIndex
  @Output() clickCreateFilter = new EventEmitter<boolean>()
  @Output() clickEditFilter = new EventEmitter<boolean>()
  @Output() setFavFiltersIndex = new EventEmitter<number>()
  @Output() setFormValues = new EventEmitter()

  isB2c
  fleets:Fleet[]
  customers: Customer[];
  creators:User[]
  favFilter
  MigrationFavFilterObj

  constructor(
    private router: Router,
    private sharedApiService: SharedApiService,
    private userPreferencesService:UserPreferencesService,
    private usersCommonApiService: UsersCommonApiService,
  ) {
    super()
  }

  ngOnInit() {
    this.isB2c = this.router.url.toLowerCase().includes(BusinessType.B2C.toLowerCase());
    this.getFavFilter()
  }

  selectFilter(filter,i): void {
    this.setFavFiltersIndex.emit(i)
    this.setFormValues.emit({})
    this.setFormValues.emit(filter)
  }

  openCreateFilter(): void {
    this.form.get('filterName').setValue("")
    this.form.get('filterName').setValidators([Validators.required])
    this.clickCreateFilter.emit(true)
  }

  openEditFilter(filterItem, i): void {
    this.setFavFiltersIndex.emit(i)
    this.setFormValues.emit(filterItem)
    this.form.get('filterName').setValidators([Validators.required])
    this.clickEditFilter.emit(true)
  }

  deleteFilter(filterItem): void {
    const favFiltersAfterDelete = this.form.get('favFilter').value.filter(
      (filter) => JSON.stringify(filter) !== JSON.stringify(filterItem)
    );
    this.load(
      this.userPreferencesService.updateUserPreferences(
        favFiltersAfterDelete,
        UserPreferencesType.Filters
      ),
      {isLoadingTransparent:true}
    ).subscribe(res=>{
      this.form.get('favFilter').patchValue(favFiltersAfterDelete)
    });
  }

  getFavFilter(): void {
    this.load(
      this.userPreferencesService.getUserPreferences(UserPreferencesType.Filters),
      {isLoadingTransparent :true}
    ).subscribe(favFilter=>{
      this.form?.get('favFilter').setValue(favFilter || [])
      this.checkFavFilter(favFilter || [])
    })
  }

  checkFavFilter(favFilters){
    this.MigrationFavFilterObj = {}
    favFilters.forEach((favFilter , i)=>{
      if(favFilter?.customerId?.length && !favFilter?.customerName?.length ||
        favFilter?.fleetId?.length && !favFilter?.fleetName?.length ||
        !Array.isArray(favFilter?.creator) && !Array.isArray(favFilter?.creatorName)||
        !Array.isArray(favFilter?.assignedBy) && !Array.isArray(favFilter?.assignedByName)||
        typeof favFilter.customerLabel?.[0] === "object"
      ){
        this.MigrationFavFilterObj[i] = true
      }
    })

    if(Object.values(this.MigrationFavFilterObj).some(el=>el)){
      this.getData()
    }
  }

  getData(): void {
    this.load(
      combineLatest([
        this.sharedApiService.getActiveFleets(true, this.isB2c ? BusinessType.B2C : BusinessType.B2B),
        this.sharedApiService.getAllCustomers(),
        this.usersCommonApiService.getAllUsers(UserType.USER)
      ]),
      {isLoadingTransparent :true}
    ).subscribe(([fleets, customers, users])=>{
      this.fleets = fleets
      this.customers = customers
      this.creators = users
      this.migrationFavFilter()
    })
  }

  migrationFavFilter(){
    const newFavFilter = this.form?.get('favFilter').value.map((favFilter ,i) => {
      return !this.MigrationFavFilterObj[i] ? favFilter : {
        ...favFilter,
        customerName : favFilter.customerId.map(id=>{
          return this.customers.find(customer => customer?.id == id).title
        }),

        fleetId : favFilter.fleetId.map(fleet => fleet?.id),

        fleetName : favFilter.fleetId.map(fleet=> {
          return this.fleets.find(fleet => fleet.id == fleet.id).name
        }),

        customerLabel : favFilter.customerLabel.map(customerLabel=> customerLabel?.dropDownDisplayName ),
        creator : convertToArray(favFilter.creator),
        creatorName : convertToArray(this.creators.find(creator => creator.id == favFilter.creator)?.name),
        assignedBy : convertToArray(favFilter.assignedBy),
        assignedByName : convertToArray(this.creators.find(assignedBy => assignedBy.id == favFilter.assignedBy)?.name)
      }
    });

    this.load(
      this.userPreferencesService.updateUserPreferences(
        newFavFilter,
        UserPreferencesType.Filters
      ),
      {isLoadingTransparent:true}
    ).subscribe((statusCode) => {
      this.form?.get('favFilter').setValue(newFavFilter || [])
    });
  }
}

