import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { GridBase } from '@shared/components/grid/grid.base';

export declare type Cols = 1|2|3|4|5|6|7|8|9|10|11|12|'1'|'2'|'3'|'4'|'5'|'6'|'7'|'8'|'9'|'10'|'11'|'12'|'auto'|'col';
export interface ColsWithBreakpoint {
  cols?: Cols;
  xs?: Cols;
  sm?: Cols;
  md?: Cols;
  lg?: Cols;
  xl?: Cols;
  xxl?: Cols;
}

@Component({
  selector: 'app-col',
  template: `<ng-content></ng-content>`,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColComponent extends GridBase {
  @Input() cols: Cols;
  @Input() xs: Cols;
  @Input() sm: Cols;
  @Input() md: Cols;
  @Input() lg: Cols;
  @Input() xl: Cols;
  @Input() xxl: Cols;
  @Input() offset: ColsWithBreakpoint;

  constructor(protected changeDetector: ChangeDetectorRef) {
    super();
  }

  calculateClassList(): void {
    const cols = this.cols ? `col-${this.cols}` : '';
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
    const offset = this.offset && this.offset.cols ? `offset-${this.offset.cols}` : '';

    const offsetPerBreakPoints = breakpoints.filter((breakpoint) => !!this.offset && !!this.offset[breakpoint])
        .map((breakpoint) => `offset-${breakpoint}-${this.offset[breakpoint]}`)
        .concat([offset]).filter(cls => !!cls);

    const colsPerBreakPoint = breakpoints
      .filter((breakpoint) => !!this[breakpoint])
      .map((breakpoint) => {
        const infix = this[breakpoint] == 'col' ? '' : `-${this[breakpoint]}`;
        return `col-${breakpoint}${infix}`;
      });

    const hasClasses = cols || offsetPerBreakPoints?.length || colsPerBreakPoint?.length;

    this.classList$.next(hasClasses ? [cols, ...colsPerBreakPoint, ...offsetPerBreakPoints] : ['col']);
  }
}
