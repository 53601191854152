import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';;
import { DeliveryNote } from './delivery-note';

export class DeliveryNoteDetails extends Model {

    @Type(()=> DeliveryNote)
    @Expose() shipmentDeliveryNoteList: DeliveryNote[] = [];
    @Expose() count: number;
    @Expose() allStatusCount: number;
    @Expose() availableToBeIssuedCount: number;
    @Expose() issuedCount: number;
    @Expose() notReceivedCount: number;
    @Expose() exportTypeCount: number;
    @Expose() localTypeCount: number;
    @Expose() sisterCompanyCount: number;
}
