import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';
import { TruckOperationStatus } from "@features/fleets/enums/truck-operation-status.enum";

export class TruckStatusRange extends Model {
  @Expose() from: number|Date;
  @Expose() to: number|Date;
  @Expose() createdAt: number|Date;
  @Expose() createdBy: string;
  @Expose() value: TruckOperationStatus;
  @Expose() note: string;
}

