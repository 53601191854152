import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LazyLibURL } from "@shared/enums/lazy-lib-url.enum";
import { LazyLibId } from "@shared/enums/lazy-lib-id.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { DynamicScriptService } from '@shared/services/dynamic-script.service';
import { LoadingService } from '@shared/services/loading.service';

@Component({
  selector: 'app-print-sections',
  templateUrl: './print-sections.component.html',
  styleUrls: ['./print-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintSectionsComponent {
  @Input() sectionIds: string[];
  @Input() fileName: string;
  @Input() titleElementId: string;
  @Input() printTitle: string;

  private jsPDF: any;
  constructor(
    private dynamicScriptService: DynamicScriptService,
    private loadingService: LoadingService,
  ) {}

  async print(): Promise<void> {
    const { jsPDF } = await this.dynamicScriptService
      .loadDynamicScript(LazyLibId.jspdf, LazyLibURL.jspdf)
      .then(() => (window as any).jspdf);

    const titleElement = document.getElementById(this.titleElementId);
    const currentTitle = titleElement?.innerHTML;

    const sections = (this.sectionIds || []).map((sectionId) => document.getElementById(sectionId))
      .filter((section) => !!section);

    if (!sections.length) {
      console.warn('[PrintSectionsComponent]: there is no sections to print');
      return;
    }

    this.loadingService.startLoading(SectionStateStatus.LoadingTransparent);
    this.jsPDF = new jsPDF('p', 'mm', 'a4', true);
    titleElement.innerHTML = this.printTitle || currentTitle;


    await Promise.all(sections.map((section) => this.addPageToPdf(section)));
    this.jsPDF.save(`${this.fileName}.pdf`);
    titleElement.innerHTML = currentTitle;
    this.loadingService.stopLoading();
  }

  private async addPageToPdf(page, width?: number): Promise<void> {
    if (!page) { return Promise.resolve(); }

    const html2canvas = await this.dynamicScriptService
      .loadDynamicScript(LazyLibId.html2canvas, LazyLibURL.html2canvas)
      .then(() => (window as any).html2canvas);
    const canvas = await html2canvas(page);
    const imageWidth = width || 200;
    const imageHeight = (canvas.height * imageWidth) / canvas.width;
    const contentDataURL = canvas.toDataURL('image/png');
    this.jsPDF.addImage(contentDataURL, 'PNG', 5, 15, imageWidth, imageHeight, '', 'SLOW');
    this.jsPDF.addPage();
  }
}
