<div *ngIf="form" class="form-wrapper">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <mat-label *ngIf="title" class="mb-1 d-inline-block" [class.asterisk-after]="isRequired">{{ title }}</mat-label>
      </div>
      <div class="col-12">
        <div [ngClass]=" {'empty-list': !filteredOptions?.length } ">
          <mat-form-field appearance="outline" class="form-control-full-width">
            <ng-container matPrefix class="prefix">
              <p class="prefix" *ngIf="prefixText">{{ prefixText }}</p>
              <ng-content select="[prefix]"></ng-content>
            </ng-container>

            <mat-select
              [multiple]="multipleMode"
              placeholder=" {{ placeholder ? placeholder : 'Placeholder.Search' | translate }} "
              (selectionChange)="selectionChange($event)"
              formControlName="dropdown"
              (openedChange)="openedChange()"
              (focus)="focusEvent.emit()"
            >
              <app-dropdown-search formControlName="searchTerm"></app-dropdown-search>

              <mat-checkbox
                *ngIf="multipleMode && filteredOptions?.length"
                app-click-stop-propagation
                class="mat-option"
                [disableRipple]="true"
                [indeterminate]="isIndeterminate()"
                [checked]="allSelected"
                (change)="onToggleSelection($event)"
                color="primary"
              >
                {{ "Options.SELECT_ALL" | translate }}
              </mat-checkbox>
              <mat-option *ngIf="includeALLOption && !multipleMode && filteredOptions?.length " [value]="SharedConstants.ALL"> {{ 'Options.ALL' | translate  }} </mat-option>
              <mat-option *ngFor="let item of filteredOptions | sortDropdown" [value]="item">
                {{ item.dropDownDisplayName }}
              </mat-option>

              <div *ngIf="!filteredOptions?.length" class="gray-hint">{{ "General.noResult" | translate }}</div>
            </mat-select>
          </mat-form-field>
        <div class="text-right">
          <mat-hint *ngIf="!_list?.length">{{ "General.EmptyList" | translate }}</mat-hint>
        </div>
        </div>
      </div>
    </div>
  </form>
</div>
