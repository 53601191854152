import { Injectable } from '@angular/core';

import { Bloc } from '@core/bloc/bloc';
import { BlocEvent } from '@core/bloc/bloc-event';
import { BlocState } from '@core/bloc/bloc-state';
import { Drawing } from '@shared-features/models/drawing';
import { ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { DropdownLoadEvent } from './dropdown-event';
import { DropdownLoadingState, DropdownReadyState } from './dropdown-state';

@Injectable()
export class DropdownBloc extends Bloc<BlocEvent, BlocState> {
  constructor() {
    super();
  }

  onIncomingEvents(event: BlocEvent) {
    if (event instanceof DropdownLoadEvent) {
      this.onState$.next(new ProcessingState(null));

     event.caller().subscribe((list) => {
        this.onState$.next(new DropdownReadyState(list));
     }, error => {
      this.onState$.next(new ErrorState<Drawing>(new Drawing(), error));
     });
    }
  }
}
