<div
  class="uploader"
  [ngClass]="{'uploader--dragging': isDragging, 'uploader--of-type-input': type === 'input', 'uploader--of-type-avatar': type === 'avatar'}"
>
  <ng-container *ngTemplateOutlet="type === 'input' ? templateOfTypeInput : templateOfTypeAvatar"></ng-container>
</div>

<ng-template #uploaderInputTemplate>
  <input
    class="uploader__input"
    *ngIf="showInput"
    type="file"
    #uploaderInput
    [accept]="accept"
    [multiple]="multiple"
    (change)="onInputValueChange($event)"
  />
</ng-template>

<ng-template #uploaderErrorTemplate>
  <div class="uploader__errors">
    <ng-content select="[error]"></ng-content>
  </div>
</ng-template>

<ng-template #uploaderHintTemplate>
  <div class="uploader__hint" #hint>
    <ng-content select="[hint]"></ng-content>
  </div>
</ng-template>

<ng-template #templateOfTypeInput>
  <div
    class="uploader__drag-area"
    (dragover)="isDragging = true"
    (dragleave)="isDragging = false"
    *ngIf="multiple || (!multiple && !filesList.length)"
  >
    <div class="uploader__label">
      <div class="row align-center gx-xs justify-center">
        <div class="col-auto">
          <app-icon name="upload" type="MaterialDesign" class="align-middle"></app-icon>
        </div>
        <div class="col-auto" [class]="{'small': labelFont === 'small'}">{{label}}</div>
        <div class="col-12" [ngClass]="{'uploader__hint--hidden': !hasHint }">
          <ng-container *ngTemplateOutlet="uploaderHintTemplate"></ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="uploaderInputTemplate"></ng-container>
  </div>

  <ng-container *ngIf="multiple">
    <ng-container *ngTemplateOutlet="uploaderErrorTemplate"></ng-container>
  </ng-container>

  <div class="uploader__preview-list preview-list">
    <div class="row">
      <div [ngClass]="previewListItemClass" *ngFor="let file of filesList; let i = index;">
        <div class="preview-list__item" [ngClass]="{'mt-0': i === 0 }">
          <div class="row align-center gx-sm">
            <div class="col-auto">
              <div class="preview-list__item-icon flex align-center">
                <app-icon name="attachment" type="MaterialDesign"></app-icon>
              </div>
            </div>
            <div class="col col--text-container align-center">
              <div
                class="preview-list__item-title"
                [class]="{'preview-list__item-title--clickable': file && file.media, 'small': labelFont === 'small' }"
                (click)="handleDownloadFile(file)"
              >
                {{fileDisplayName || file.name}}
              </div>
              <small *ngIf="displayFileInfo">{{file.size | fileSize}}</small>
            </div>
            <div class="col-auto">
              <button mat-icon-button type="button" (click)="removeFile(file)">
                <app-icon name="delete" type="MaterialDesign"></app-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!multiple">
    <ng-container *ngTemplateOutlet="uploaderErrorTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #templateOfTypeAvatar>
  <div class="uploader__label" (dragover)="isDragging = true" (dragleave)="isDragging = false">
    <div class="row gx-sm align-center justify-center">
      <div class="col-auto">
        <div class="uploader__avatar uploader__drag-area" [ngStyle]="{width: avatarSize + 'px', height: avatarSize + 'px'}">
          <app-icon name="upload" type="MaterialDesign" *ngIf="!filesList.length"></app-icon>
          <img *ngIf="filesList.length && isImage" [src]="previewImageUrl" class="w-100 h-100" alt="" />
          <b class="h4 m-auto" *ngIf="filesList.length && !isImage">{{filesList[0].name.split('')[0].toUpperCase()}}</b>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12">
            <div class="uploader__text">{{label}}</div>
          </div>
          <div class="col-12">
            <div *ngIf="filesList[0]" class="uploader__text-sm">{{fileDisplayName || filesList[0]?.name}} | {{filesList[0]?.size | fileSize}}</div>
            <ng-container *ngTemplateOutlet="uploaderHintTemplate"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="uploaderInputTemplate"></ng-container>
  </div>

  <ng-container *ngTemplateOutlet="uploaderErrorTemplate"></ng-container>
</ng-template>
