<div class="rect-tag" [ngClass]="['rect-tag--' + color, 'rect-tag--' + size, 'rect-tag--' + theme]" [class.rect-tag--inverted]="inverted">
  <div class="rect-tag__prefix" #prefix [hidden]="!prefix.childNodes?.length">
    <ng-content select="[prefix]"></ng-content>
  </div>
  <div class="rect-tag__rect">
    <div class="text-ellipsis d-inline-block" [style.maxWidth]="excretionWidth">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="rect-tag__suffix" #suffix [hidden]="!suffix.childNodes?.length">
    <ng-content select="[suffix]"></ng-content>
  </div>
</div>
