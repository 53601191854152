<div class="announcement-bar" *ngIf="announcement && announcementLocalStorage.showAnnouncementBar">
  <div>
    <span class="announcement-bar__suffix py-1 px-2 weight-medium">{{"shared.announcementBar.news" | translate}}</span>
    <span class="ml-1">
      {{announcement.message}}
      <a
        class="text-white"
        *ngIf="isEmail(announcement.url) || isLink(announcement.url)"
        target="_blank"
        [href]="isEmail(announcement.url) ? 'mailto:'+ announcement.url : isLink(announcement.url) ? announcement.url : null "
      >
        {{announcement.urlName ? announcement.urlName : ("shared.announcementBar.link" | translate) }}
      </a>
    </span>
  </div>

  <button mat-button (click)="hideAnnouncementBar()">
    <app-icon class="align-middle text-white" name="close-circle"></app-icon>
  </button>
</div>
