import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { ShipmentTransportationPaymentStatus } from "@features/transportation-payments/enums/shipment-transportation-payment-status.enum";
import { InvoiceTransportation } from '../../invoices/models/invoice-transportation';
import { User } from '../../users/models/user';
export class ShipmentTransportationPayment extends Model {
  @Expose()
  status: ShipmentTransportationPaymentStatus;

  @Expose()
  createdAt: Date;

  @Type(() => User)
  @Expose()
  createdBy: User

  @Expose()
  invoiceTransportationPayment: InvoiceTransportation = new InvoiceTransportation();
}
