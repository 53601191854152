import { Component, OnDestroy, OnInit, Input, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FunctionUtil } from '../../utils/function.util';
import { MatDatepicker } from '@angular/material/datepicker';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

export const DATE_INPUT_FORMATE = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: '',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-birth-date',
  templateUrl: './birth-date.component.html',
  styleUrls: ['./birth-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BirthDateComponent,
      multi: true,
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMATE },
  ],
})
export class BirthDateComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input('min') minDate = null;
  @Input('max') maxDate = null;
  @Input('required') required = true;
  @Output() changeEvent = new EventEmitter<any>();

  onTouched: any = () => {};

  form: UntypedFormGroup = this.formBuilder.group({
    date: new UntypedFormControl(null),
  });

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {}

  chosenYearHandler(normalizedYear: Moment) {
    this.form.get('date').setValue(moment())
    let ctrlValue = this.form.value['date'];
    ctrlValue.year(normalizedYear.year());
    this.form.get('date').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    let ctrlValue = this.form.value['date'];
    ctrlValue.month(normalizedMonth.month());
    this.form.get('date').setValue(FunctionUtil.convertDateWithoutDays(new Date(ctrlValue)));
    datepicker.close();
  }

  registerOnChange(onChange: any): void {
    this.form.get('date').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(onChange);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['required'] != null && changes['required'].currentValue != null) {
       this.form.get('date').clearValidators();
     if(this.required){
       this.form.get('date').setValidators([Validators.required]);
     }else{
       this.form.get('date').setValidators(null);
     }
     this.form.get('date').updateValueAndValidity();
   }
  }

  writeValue(value: any) {
    if (value) this.form.get('date').setValue(value);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.get('date').disable();
    } else {
      this.form.get('date').enable();
    }
  }
}
