type color = 'primary' | 'warn';
export class TabModel {
  group?: string = '';
  label?: string = '';
  count?: number = null;
  showCount?: boolean = false;
  index?: number = 0;
  disabled?: boolean;
  pageIndex?: number = 1;
  type?: color = 'primary';
  tooltip?: string;
  icon?: string;
  iconType?: 'icon' | 'img' | 'svg';
}
