import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { BreadcrumbService } from '../../services/breadcrumb.service';
import { BreadCrumb } from '../../model/breadcrumb';
import { BreadcrumbLabels } from '@shared/enums/breadcrumb-labels.enum';
import { BreadcrumbIcons } from '@shared/enums/breadcrumb-icons.enum';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  @Input() set bgColor(value: string) {
    this.elementRef.nativeElement?.style.setProperty('--background-color', value);
  }

  breadcrumbs: BreadCrumb[] = [];
  showBreadcrumb = true;

  readonly BreadcrumbLabels = BreadcrumbLabels;
  readonly BreadcrumbIcons = BreadcrumbIcons;

  private breadcrumbSubscription: Subscription;
  constructor(private breadcrumbService: BreadcrumbService, private elementRef: ElementRef) {
    this.breadcrumbSubscription = this.breadcrumbService.breadcrumbList$.subscribe(breadcrumbList => {
      return this.breadcrumbs = breadcrumbList;
    });
    this.showBreadcrumb = this.breadcrumbService.visibility$.value;
    this.breadcrumbService.visibility$.subscribe(value => this.showBreadcrumb = value);
  }

  ngOnDestroy(): void {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
  }
}
