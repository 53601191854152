import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryNotesManagementPageComponent } from './pages/delivery-notes-management-page/delivery-notes-management-page.component';

const routes: Routes = [
  {
    path: '',
    component: DeliveryNotesManagementPageComponent,
    data: {
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'DeliveryNotes',
      },
    },
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeliveryNotesRoutingModule {}
