import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { LookupService } from '@shared-features/services/lookups.service';
import {
  catchError,
  debounceTime,
  filter,
  map,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getValueByPath } from '@shared/utils/get-value-by-path.util';
import { LookupType } from '@shared/enums/lookup-type.enum';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { Lookup } from '@shared-features/models/Lookup.model';
import { TranslateService } from '@ngx-translate/core';
import { ClassConstructor } from 'class-transformer';
import { MatSelect } from '@angular/material/select';
import { isDefined } from '@shared/utils/is-defined.util';
import { LazyDropdownOption } from '@shared/components/forms/controls/lazy-dropdown/lazy-dropdown-option';
import { OptionLabelDirective } from '@shared/directives/option-label.directive';

export type Model = LazyDropdownComponent['Model'];

export interface DropdownFilter {
  offset: number;
  limit: number;
  searchTerm: string;
  lang: string;
}

export const ALL_OPTION_VALUE = 'ALL';

@Component({
  selector: 'app-lazy-dropdown',
  templateUrl: './lazy-dropdown.component.html',
  styleUrls: ['./lazy-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LazyDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LazyDropdownComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @HostBinding('attr.id') @Input() id: string;
  @ViewChild('matSelect') matSelect: MatSelect;
  @ViewChild('scrollElement') scrollElementRef: ElementRef<HTMLElement>;
  @ViewChild('loadMoreTrigger') loadMoreTriggerElementRef: ElementRef<HTMLElement>;
  @ContentChild(OptionLabelDirective)
  optionLabelDirective: OptionLabelDirective;

  @Input() theme: 'outlined' | 'highlighted' | 'unbounded' = 'outlined';
  @Input() size: 'sm' | 'md' = 'md';

  // [{ name: 'display' }, {...}]
  @Input() displayPath = 'dropDownDisplayName';
  // [{ id: 1 }, {...}]
  // if valuePath is null || '' it would be the whole object.
  @Input() valuePath = 'dropDownValue';
  // response => {data: { list: [] }} => listPath = 'data.list'
  // if listPath null means the list input or response is Array
  @Input() listPath = null;
  @Input() multiple: boolean;
  @Input() delay = 500;
  @Input() margin = 30;
  @Input() limit = 20;
  @Input() valuePersist: boolean;
  @Input() selectFirstByDefault: boolean;
  @Input() placeholder: string;
  @Input() label = '';
  @Input() allowSelectAll = true;
  @Input() appearance: 'outline' | 'fill' = 'outline';

  @Input() set readonly(isReadonly) {
    this.readonlyState = isReadonly;
  }

  @Input() filter: (options: Model[]) => Model[];
  @Input() clearable: boolean;
  @Input() Model: ClassConstructor<any> = Lookup;
  @Input() disableOption: (option: Model) => boolean;

  @Input() lookup:
    | LookupType
    | ((filters: { offset: number; limit: number; searchTerm: string }) => Observable<Model[]>)
    | Model[]
    | Observable<Model[]>;

  // if need to add extra to filters from outside
  @Input() lookupExtraParams: any;
  @Input() highlightSelection: boolean;
  @Output() selectionChange = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() loadDataStart = new EventEmitter<{ options: Model[] } & DropdownFilter>();
  @Output() loadDataEnd = new EventEmitter<
    { options: Model[]; loadedOptions: Model[]; end: boolean } & DropdownFilter
  >();
  @Output() cleared = new EventEmitter();

  loading = false;
  readonlyState = false;
  endOfResult = false;
  sectionState = SectionStateStatus;

  searchTerm$ = new BehaviorSubject('');
  valueControl = new FormControl();
  destroy$ = new Subject();

  options: { [id: string]: LazyDropdownOption<Model> } = {};

  get selectedOptions(): LazyDropdownOption<Model>[] {
    return Object.values(this.options).filter((o) => o.selected);
  }

  get selectedValues(): any[] {
    return this.selectedOptions?.map((o) => o.value);
  }

  get optionIds(): string[] {
    return Object.keys(this.options).sort((a, b) => this.options[a].index - this.options[b].index);
  }

  displayText = '';
  selectedCount = 0;

  firstLoad = true;
  firstOpen = true;
  disabled = false;
  refreshCachedLookups = false;

  private pagination$: BehaviorSubject<{ limit: number; offset: number }>;
  private language$: Observable<string>;
  private allOption: LazyDropdownOption<Model>;
  private searchTermChanged = false;

  private internalChange = false;
  private postponedValues = [];

  onChange = (value: any) => null;
  onTouched = () => null;

  constructor(
    private lookupService: LookupService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {}

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  get value(): any | any[] {
    const controlValue = this.valueControl?.value;
    if (!controlValue) {
      return null;
    }

    if (this.multiple && controlValue) {
      return controlValue?.map((id) => this.options[id].value);
    } else if (controlValue) {
      return this.options[controlValue]?.value;
    }
  }

  writeValue(value: any): void {
    const selection = this.multiple ? value || [] : value || null;

    if (
      (Array.isArray(selection) && selection[0] instanceof this.Model) ||
      selection instanceof this.Model
    ) {
      this.writeValueFromModel(selection);
    } else if (
      (Array.isArray(selection) && selection.length) ||
      (!Array.isArray(selection) && !!selection)
    ) {
      this.writeValueFromValue(selection);
    } else {
      this.collectChanges(selection, false);
      this.parseDisplay();
    }
  }

  private writeValueFromModel(value: Model | Model[]): void {
    let controlValue = null;
    if (this.multiple) {
      const values = Array.isArray(value) ? value : [value];
      controlValue = values
        .map((item) => this.insertOption(item))
        .map((item) => {
          return item.id;
        });
      this.collectChanges(controlValue, false);
      this.parseDisplay();
      return;
    }

    controlValue = this.insertOption(value as Model)?.id;
    this.collectChanges(controlValue, false);
  }

  private writeValueFromValue(value: any | any[]): void {
    if (this.multiple) {
      const values = Array.isArray(value) ? value : [value];
      this.postponedValues = values.map((item) => LazyDropdownOption.generateIdFromOption(item));
      return;
    }
    this.postponedValues = [LazyDropdownOption.generateIdFromOption(value)];
  }

  ngOnInit(): void {
    if (this.multiple && this.allowSelectAll) {
      this.allOption = this.insertOption(this.getOptionOfSelectAll());
    }

    this.pagination$ = new BehaviorSubject({ offset: 0, limit: this.limit });
    this.language$ = this.translateService.onLangChange.pipe(
      takeUntil(this.destroy$),
      map((event) => event.lang),
      tap(() => this.reset(true)),
      startWith(this.translateService.currentLang)
    );
    this.handleFiltersChange();
    this.handleSelectionChange();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.markForCheck();
    this.handleScrollEvent();
  }

  ngOnChanges({ lookupExtraParams: extraParams, lookup }: SimpleChanges): void {
    if (
      (extraParams && extraParams.currentValue !== extraParams.previousValue) ||
      (lookup && lookup.currentValue !== lookup.previousValue)
    ) {
      this.firstLoad = true;
      this.reset(!this.valuePersist);
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleSelectionChange(): void {
    this.valueControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((selection) => {
      if (this.internalChange) {
        this.internalChange = false;
        return;
      }
      this.collectChanges(selection);
    });
  }

  collectChanges(selection: any | any[], emitEvent = true): void {
    let changes = { controlValue: null, changeValue: null };
    if (this.multiple) {
      changes = this.handleMultipleSelection(selection);
    } else {
      changes = this.handleSingleSelection(selection);
    }
    this.internalChange = true;
    this.valueControl.setValue(changes.controlValue, { emitEvent: true, onlySelf: false });
    this.parseDisplay();
    if (emitEvent) {
      this.onChange(changes.changeValue);
      this.selectionChange.emit(changes.changeValue);
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  private handleMultipleSelection(values: any[]): { controlValue: any; changeValue: any } {
    const list = values || [];
    const isAllSelected = this.multiple && list.includes(this.allOption?.id);
    const wasAllSelected = this.multiple && this.allOption?.selected;
    const beforeLastSelected = list.length === this.optionIds.length - 1;
    const selectedAllOptions = !wasAllSelected && !isAllSelected && beforeLastSelected;
    const deselectOnlyAllOption = wasAllSelected && isAllSelected && beforeLastSelected;
    const isSearchActive = !!this.searchTerm$.value.toString().trim();
    let controlValue = [];
    let changeValue = [];

    if (
      this.allowSelectAll &&
      ((isAllSelected && !wasAllSelected) ||
        (this.endOfResult && selectedAllOptions && !isSearchActive))
    ) {
      // select all values and send to out only option of all
      controlValue = this.optionIds;
      changeValue = this.allOption ? [this.allOption.value] : [];
    } else if (this.allowSelectAll && wasAllSelected && !isAllSelected) {
      // deselect all and send to out only empty array
      controlValue = [];
      changeValue = [];
    } else if (this.allowSelectAll && deselectOnlyAllOption) {
      // deselect only all option

      // [workaround] filter list to fix the out of no where returned value of select
      // after search, select item, select all, deselect all, search item, select item
      // after these steps the returned value is list of many options which is wrong
      // it should be only one item
      controlValue = list.filter((id) => id !== this.allOption.id && this.options[id]);
      changeValue = controlValue.map((id) => this.options[id]?.value || null);
    } else {
      // [workaround] filter list to fix the out of no where returned value of select
      // after search, select item, select all, deselect all, search item, select item
      // after these steps the returned value is list of many options which is wrong
      // it should be only one item
      controlValue = list.filter((id) => !!this.options[id]);
      changeValue = controlValue.map((id) => this.options[id].value);
    }

    this.optionIds.forEach((id) => {
      this.options[id].selected = controlValue.includes(id);
    });

    return {
      controlValue,
      changeValue,
    };
  }

  private handleSingleSelection(value): { controlValue: any; changeValue: any } {
    if (this.options[value]) {
      this.optionIds.forEach((id) => (this.options[id].selected = false));
      this.options[value].selected = true;
    }

    return {
      controlValue: value,
      changeValue: this.selectedValues[0],
    };
  }

  parseDisplay(): void {
    this.displayText = '';
    this.selectedCount = 0;

    if (!this.multiple) {
      this.displayText = this.options[this.valueControl.value]?.display;
    } else if (this.allOption?.selected) {
      this.displayText = this.allOption.display;
    } else if (this.valueControl.value?.length > 1) {
      this.displayText = this.label;
    } else {
      const selected = this.valueControl.value && this.valueControl.value[0];
      this.displayText = selected ? this.options[selected]?.display : this.placeholder || '';
    }

    this.selectedCount =
      (this.multiple && this.allOption?.selected) || this.selectedOptions.length < 2
        ? 0
        : this.selectedOptions.length;
  }

  handleFiltersChange(): void {
    const search$ = this.searchTerm$.pipe(
      tap(() => this.resetLoadedItemsState()),
      tap(() => (this.searchTermChanged = true))
    );

    const subscription = combineLatest([this.pagination$, search$, this.language$])
      .pipe(
        filter(() => isDefined(this.disabled) && !this.disabled),
        filter(() => !!this.lookup && !this.endOfResult),
        tap(() => (this.loading = true)),
        tap(([pagination, searchTerm, lang]) =>
          this.loadDataStart.emit({
            ...pagination,
            options: this.optionIds.map((id) => this.options[id].option),
            searchTerm,
            lang,
          })
        ),
        debounceTime(this.delay),
        tap(() => this.searchTermChanged && this.unhighlight()),
        takeUntil(this.destroy$),
        switchMap(([{ offset, limit }, searchTerm, lang]) =>
          this.handleRetrieveData({
            offset,
            limit,
            searchTerm,
            lang,
          })
        ),
        map((list) => (this.filter ? this.filter(list) : list)),
        catchError((err) => {
          if (subscription && !subscription.closed) {
            Promise.resolve().then(() => {
              subscription.unsubscribe();
              this.handleFiltersChange();
            });
          }
          return throwError(err);
        })
      )
      .subscribe(
        (list: Model[]) => {
          const { limit } = this.pagination$.value;
          const isStaticList = typeof this.lookup === 'object' || this.lookup instanceof Observable;
          const reachedLimit = !list?.length || list?.length < limit;
          const isSearchActive = !!this.searchTerm$.value.toString().trim();
          const highlightSearch =
            isSearchActive && this.multiple && this.valueControl.value?.length;

          this.endOfResult = isStaticList || reachedLimit;

          list?.forEach((item) => this.insertOption(item, highlightSearch));

          this.loadDataEnd.emit({
            ...this.pagination$.value,
            options: this.optionIds.map((id) => this.options[id].option),
            loadedOptions: list,
            searchTerm: this.searchTerm$.value,
            lang: this.translateService.currentLang,
            end: this.endOfResult,
          });

          if (this.searchTermChanged) {
            this.searchTermChanged = false;
          }

          this.loading = false;
          this.refreshCachedLookups = false;
          this.changeDetectorRef.markForCheck();
          if (
            this.firstLoad &&
            this.selectFirstByDefault &&
            this.optionIds?.length &&
            !this.postponedValues?.length &&
            this.isEmpty()
          ) {
            const firstOptionId = this.optionIds[0];
            const optionId =
              this.multiple && firstOptionId === this.allOption?.id
                ? this.optionIds[1]
                : firstOptionId;
            this.collectChanges(this.multiple ? [optionId] : optionId);
          } else if (this.postponedValues?.length) {
            this.collectPostponedValues();
          } else if (this.multiple && this.allOption?.selected) {
            this.allOption.selected = false;
            this.collectChanges([this.allOption.id], false);
          }
          this.firstLoad = false;
          this.changeDetectorRef.markForCheck();
        },
        (err) => {
          console.error(err);
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        }
      );
  }

  loadMore(): void {
    const { offset, limit } = this.pagination$.value;
    this.pagination$.next({ offset: offset + 1, limit });
  }

  handleScrollEvent(): void {
    const $scrollElement = this.scrollElementRef?.nativeElement;
    if (!$scrollElement) {
      return;
    }
    fromEvent($scrollElement, 'scroll')
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.delay),
        filter(() => !this.loading && !this.endOfResult),
        filter(() => this.isLoadMoreTriggerVisible())
      )
      .subscribe(() => {
        this.loadMore();
        this.changeDetectorRef.markForCheck();
      });
  }

  private isLoadMoreTriggerVisible(): boolean {
    const $scrollElement = this.scrollElementRef?.nativeElement;
    const $triggerElement = this.loadMoreTriggerElementRef?.nativeElement;

    if (!$scrollElement || !$triggerElement) {
      return false;
    }

    const { bottom, height } = $scrollElement.getBoundingClientRect();
    const { top } = $triggerElement.getBoundingClientRect();

    if (top === 0 && height === 0) {
      return false;
    }
    return top - this.margin <= bottom;
  }

  setDisabledState(isDisabled: boolean): void {
    const action: 'disable' | 'enable' = isDisabled ? 'disable' : 'enable';
    const disabledChanged = this.disabled !== isDisabled;
    this.disabled = isDisabled;
    if (disabledChanged) {
      this.valueControl[action]();

      if (!isDisabled) {
        this.reset();
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  forceUpdate(): void {
    this.refreshCachedLookups = true;
    this.resetLoadedItemsState();
  }

  handleOpenChange(opening: boolean): void {
    const ngControl = this.injector.get(NgControl);
    if (this.firstOpen && ngControl.control.value) {
      ngControl.control.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.firstOpen = false;
    }

    if (!opening) {
      this.onTouched();
      this.close.emit();
    } else {
      this.open.emit();
    }
  }

  clearValue(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.valueControl.setValue(null);
    this.onChange(null);
    this.cleared.emit();
    this.changeDetectorRef.markForCheck();
  }

  private resetLoadedItemsState(): void {
    this.endOfResult = false;
    const ids = this.optionIds;
    ids.forEach((id) => {
      const option = this.options[id];
      if (!option.selected && option.id !== this.allOption?.id) {
        delete this.options[id];
      }
    });
    if (this.pagination$?.next) {
      this.pagination$?.next({ ...this.pagination$.value, offset: 0 });
    }

    this.parseDisplay();
    this.changeDetectorRef.markForCheck();
  }

  private reset(clearValue?: boolean): void {
    if (clearValue) {
      this.optionIds.forEach((id) => {
        if (id !== this.allOption?.id) {
          delete this.options[id];
        }
      });
      this.valueControl.setValue(this.multiple ? [] : null);
    }
    this.resetLoadedItemsState();
  }

  private getFromPreConfiguredLookups(dropdownFilter: DropdownFilter): Observable<Model[]> {
    const { offset, limit, searchTerm, lang } = dropdownFilter;
    return this.lookupService
      .getLookup(
        this.lookup as LookupType,
        { lang, offset, limit, searchTerm, ...(this.lookupExtraParams || {}) },
        this.refreshCachedLookups,
        this.Model
      )
      .pipe(map((res) => (this.listPath ? getValueByPath<Model[]>(res, this.listPath) : res)));
  }

  private getFromStaticList(dropdownFilter: DropdownFilter): Observable<Model[]> {
    const { offset, searchTerm } = dropdownFilter;
    const isObservable = this.lookup instanceof Observable;
    if (offset > 0) {
      return of([]);
    }
    return (isObservable ? (this.lookup as Observable<Model[]>) : of(this.lookup as Model[])).pipe(
      map((res) => (this.listPath ? getValueByPath<Model[]>(res, this.listPath) : res)),
      map((list) =>
        list.filter((item) => {
          const display = getValueByPath(item, this.displayPath);
          const displayAsString = typeof display === 'string' ? display : JSON.stringify(display);
          return displayAsString.toLowerCase().includes((searchTerm || '').toLowerCase());
        })
      )
    );
  }

  private handleRetrieveData(dropdownFilter: DropdownFilter): Observable<Model[]> {
    const { offset, limit, searchTerm, lang } = dropdownFilter;
    let data$ = of([]);
    switch (typeof this.lookup) {
      case 'string':
        data$ = this.getFromPreConfiguredLookups(dropdownFilter);
        break;

      case 'function':
        data$ = this.lookup({
          offset,
          limit,
          searchTerm,
          ...(this.lookupExtraParams || {}),
          cleanCache: this.refreshCachedLookups,
        });

        break;
      case 'object':
        data$ = this.getFromStaticList(dropdownFilter);
        break;
    }
    return data$.pipe(
      catchError((err) => {
        return of([]);
      })
    );
  }

  private getOptionOfSelectAll(): Model {
    const model: Model = new this.Model() as unknown as Model;
    const display =
      model instanceof Lookup && this.displayPath === 'dropDownDisplayName'
        ? 'value'
        : this.displayPath;
    const value =
      model instanceof Lookup && this.valuePath === 'dropDownValue' ? 'id' : this.valuePath;
    this.setToModel(
      model,
      display,
      this.translateService.instant('shared.lazyDropdown.all', {
        items: isDefined(this.label) && this.translateService.instant(this.label),
      })
    );
    this.setToModel(model, value, ALL_OPTION_VALUE);
    return model;
  }

  private setToModel(model: Model, path: string, value: any): Model {
    const newModel = path.split('.').reduce((obj, key, i, arr) => {
      model[key] = i === arr.length - 1 ? value : obj[key] || {};
      return model[key];
    }, {});
    return Object.assign({}, model, newModel);
  }

  private insertOption(option: Model, highlightSelected = false): LazyDropdownOption<Model> {
    let lazyOption = new LazyDropdownOption(
      option,
      this.optionIds.length,
      this.valuePath,
      this.displayPath
    );
    if (this.disableOption) {
      lazyOption.disabled = this.disableOption(option);
    }
    lazyOption = this.insertLazyOption(lazyOption, highlightSelected);
    return lazyOption;
  }

  private insertLazyOption(
    lazyOption: LazyDropdownOption<Model>,
    highlightSelected = false
  ): LazyDropdownOption<Model> {
    if (this.options[lazyOption.id]) {
      this.options[lazyOption.id].highlighted = highlightSelected;
      return this.options[lazyOption.id];
    }

    this.options[lazyOption.id] = lazyOption;
    this.options[lazyOption.id].highlighted = highlightSelected;
    return lazyOption;
  }

  private isEmpty(): boolean {
    const controlValue = this.valueControl.value;
    return this.multiple ? !(controlValue && controlValue.length) : !controlValue;
  }

  private collectPostponedValues(): void {
    const { retrieved, postponed } = (this.postponedValues || []).reduce(
      (group, id) => {
        if (this.options[id]) {
          group.retrieved.push(id);
        } else {
          group.postponed.push(id);
        }
        return group;
      },
      { retrieved: [], postponed: [] }
    );

    if (!this.multiple && retrieved.length) {
      this.collectChanges(retrieved[0]);
    } else if (retrieved.length) {
      this.collectChanges(retrieved);
      this.postponedValues = postponed;
    }
  }

  private unhighlight(): void {
    this.optionIds.forEach((id) => {
      this.options[id].highlighted = false;
    });
    this.changeDetectorRef.markForCheck();
  }
}
