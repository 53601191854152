import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class RentedTrucksDashboard extends Model {
  @Expose() 'totalTrucksCount': number;
  @Expose() 'busyTruckCount': number;
  @Expose() 'freeTruckCount': number;
  @Expose() 'trucksNotReadyCount': number;
  @Expose() 'assignedRequestsCount': number;
}
