import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { G2gLocation } from '@features/live-tracking/models/g2g-location.model';

@Component({
  selector: 'app-gate-to-gate-marker',
  template: `
    <app-marker
      [size]="markerSize"
      style="transform: translate(-50%, -50%)"
      (click)="togglePopup.emit(position?.positionId)"
    >
      <app-icon name="route" type="MaterialDesign" [ngClass]="'text-error'"></app-icon>
    </app-marker>

    <div class="card--shifted" *ngIf="activePopupId === position.positionId">
      <mat-card class="p-3" style="padding: 0.75rem !important;">
        <app-row>
          <app-col class="ml-auto" cols="auto">
            <button
              mat-icon-button
              type="button"
              style="margin: -.75rem -.75rem 0 0;"
              (click)="togglePopup.emit(null)"
            >
              <app-icon name="close" type="MaterialDesign"></app-icon>
            </button>
          </app-col>
        </app-row>

        <app-row style="margin-top: -1rem;">
          <app-col>
            <div class="pb-2" *ngFor="let action of position.actions">
              <b>Action</b>: {{ 'Enum.ShipmentStatus.' + action | translate }}
            </div>
            <div class="py-2"><b>At</b>: {{ position.createdAt | date : 'short' }}</div>
          </app-col>
        </app-row>
      </mat-card>
    </div>
  `,
  styles: [
    `
      .card--shifted {
        transform: translate(-50%, -125%);
        padding-bottom: 1rem;
        font-size: 1rem;
        min-width: 240px;
      }
    `,
  ],
})
export class GateToGateMarkerComponent implements OnChanges {
  @Input() zoom: number;
  @Input() position: G2gLocation;
  @Input() activePopupId: number;
  @Output() togglePopup = new EventEmitter();

  markerSize: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  constructor() {}

  ngOnChanges({ zoom }: SimpleChanges): void {
    if (zoom) {
      this.markerSize = this.zoom < 11 ? (this.zoom >= 7 ? 'sm' : 'xs') : 'md';
    }
  }
}
