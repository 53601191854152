import { Component, Input, OnInit, Injector } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilterService } from '@shared/services/filter.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedConstants } from '@shared/model/shared-constants';
import { ActivatedRoute } from '@angular/router';
import { CityApiService } from '@shared-features/services/city-api.service';
import { zip } from 'rxjs';
import { Fleet } from '@features/fleets/models/fleet';
import { City } from '@shared-features/models/city';
import { FleetsB2cApiService } from '@features/b2c-fleets/services/fleet-b2c-api.service';

@Component({
  selector: 'app-b2c-rules-filter',
  templateUrl: './b2c-rules-filter.component.html',
  styleUrls: ['./b2c-rules-filter.component.scss'],
})
export class B2cRulesFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<void>;
  @Input() tabGroup: TabGroup;
  @Input() tabIndex: TabIndex;

  form: UntypedFormGroup;
  params: any = null;
  FleetsB2c: Fleet[] = [];
  cities: City[] = [];

  sharedConstants = SharedConstants;

  constructor(
    private filterService: FilterService,

    private activatedRoute: ActivatedRoute,
    private cityApiService: CityApiService,
    private FleetsB2cApiService: FleetsB2cApiService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.getPrerequisiteData();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  getPrerequisiteData() {
    this.sectionState = SectionStateStatus.Loading;
    zip(this.cityApiService.getAllCities(), this.FleetsB2cApiService.getAllFleetsB2c())
      .pipe(take(1))
      .subscribe(
        ([cities, FleetsB2cDetails]) => {
          this.cities = cities;
          this.FleetsB2c = FleetsB2cDetails.fleets;
          this.initForm();
          this.sectionState = SectionStateStatus.Ready;
        },
        (error) => {
          this.showErrorMessage(error);
          this.sectionState = SectionStateStatus.Error;
        }
      );
  }

  get fromCities(): City[] {
    return [...this.cities];
  }

  get toCities(): City[] {
    return [...this.cities];
  }

  getFilterValuesBasedOnURLParams() {
    return {
      from: this.params.from ? this.fromCities.find((city) => city.id == Number(this.params.from)) : null,
      to: this.params.to ? this.toCities.find((city) => city.id == Number(this.params.to)) : null,
      FleetsB2c: this.params.FleetsB2c ? this.FleetsB2c.find((FleetsB2c) => FleetsB2c.id == Number(this.params.FleetsB2c)) : null,
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form = new UntypedFormGroup({
        from: new UntypedFormControl(filterValues.from),
        to: new UntypedFormControl(filterValues.to),
        FleetsB2c: new UntypedFormControl(filterValues.FleetsB2c),
      });
    } else {
      this.form = new UntypedFormGroup({
        from: new UntypedFormControl(null),
        to: new UntypedFormControl(null),
        FleetsB2c: new UntypedFormControl(null),
      });
    }
  }

  subscribeToFilterAction() {
    this.action.pipe(takeUntil(this.destroy$)).subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  resetForm() {
    this.form.setValue({
      from: null,
      to: null,
      FleetsB2c: null,
    });
    this.submitForm();
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.setValue({
        from: filterValues.from,
        to: filterValues.to,
        FleetsB2c: filterValues.FleetsB2c,
      });
    } else {
      this.resetForm();
    }
  }

  submitForm() {
    const formValues = this.form.value;
    const submittedFilterValues = {
      from: formValues.from?.id,
      to: formValues.to?.id,
      FleetsB2c: formValues.FleetsB2c?.id,
      tabGroup: TabGroup.RulesB2C,
    };

    this.filterService.navigateToTargetPageWithFilter(submittedFilterValues);
  }
}
