import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";


@Component({
  selector: 'app-state-section',
  templateUrl: './state-section.component.html',
  styleUrls: ['./state-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateSectionComponent implements OnInit, OnChanges {
  @Input() state: SectionStateStatus;
  @Input() loadingLabel: string;
  @Input() errorStateLabel: string;
  @Input() emptyStateMainLabel: string;
  @Input() emptyStateSubLabel: string;
  @Input() emptyStateImagePath: string;
  @Input() comingSoonStateLabel: string;
  @Input() comingSoonStateImagePath: string;
  @Input() isPageSection = false;
  @Input() flexibleHeight = false;
  @Input() transparentBackground = false;
  @Output() reload = new EventEmitter();
  @Output() clear = new EventEmitter();

  SectionStateStatus = SectionStateStatus;

  constructor(private comRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges({ state }: SimpleChanges): void {
    if (state && state.currentValue !== state.previousValue) {
      this.comRef.detectChanges();
    }
  }

  onReload(): void {
    this.reload.emit(null);
  }

  onClearButtonClicked(): void{
    this.clear.emit(null);
  }
}
