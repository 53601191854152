import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class ShipmentStatusCount extends Model {

  @Expose() totalCount: number;
  @Expose() totalNewStatusCount: number;
  @Expose() totalAcceptedStatusCount: number;
  @Expose() totalAssignedStatusCount: number;
  @Expose() totalDriverAcceptedStatusCount: number;
  @Expose() totalInTransitStatusCount: number;
  @Expose() totalArrivePickupLocationStatusCount: number;
  @Expose() totalGoodsLoadedStatusCount: number;
  @Expose() totalArriveDropOffLocationStatusCount: number;
  @Expose() totalGoodsDeliveredStatusCount: number;
  @Expose() totalReturnedStatusCount: number;
  @Expose() totalRejectedStatusCount: number;
  @Expose() totalIncompleteStatusCount: number;
  @Expose() totalCanceledStatusCount: number;
  @Expose() totalNotFulfilledStatusCount: number;
  @Expose() totalDelayedActionCount: number;
  @Expose() totalTripStartedStatusCount: number;
  @Expose() lastRefreshTime:Date;

  constructor() {
    super();
  }
}
