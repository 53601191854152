import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { Expose, Type } from 'class-transformer';
import { Media } from '../../shared-features/models/media';
import { ProductType } from '../../shipments/models/product-type';
import { LocationModel } from '../../live-tracking/models/location.model';
import { CustomerEtaConfiguration } from '@features/customers/enums/customer-eta-configuration.enum';
import { Receiver } from '../../receivers/models/receiver';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { CustomerType } from '@features/customers/enums/customer-type.enum';
import { ShipmentCreationTemplate } from '../../shipments/models/shipment-template.model';
import { User } from '../../users/models/user';
import { TruckType } from '../../fleets/models/truck-type';

export class Customer extends DropdownModel {
  @Expose() id?: number;
  @Expose() title = '';
  @Expose() contactFirstName = '';
  @Expose() contactLastName = '';
  @Expose() email: string;
  @Expose() shipmentsCount?: number;
  @Expose() mobileNumber: string;
  @Expose() contactMobileNumber? = '';
  @Expose() code?: string;
  @Expose() commercialRegistration?: Media = new Media();
  @Expose() logo?: Media = new Media();
  @Expose() primaryTheme?: string;
  @Expose() usersCount?: number;
  @Expose() status?: string;
  @Expose() crNumber: number;
  @Expose() activationDate?: number;
  @Expose() vatNumber?: number;
  @Expose() createdAt?: number;
  @Expose() updatedAt?: number;
  @Expose() productTypes?: ProductType[] = [];
  @Expose() locations?: LocationModel[] = [];
  @Expose() canAssignDrivers?: boolean;
  @Expose() dueDays?: number;
  @Expose() waitingChargePerDay?: number;
  @Expose() showAllTruckType?: boolean;
  @Expose() rejectReason?: string;
  @Expose() verifyShipmentsByReceivers?: boolean;
  @Expose() etaConfiguration?: CustomerEtaConfiguration;
  @Expose() localVatPercentage?: number;
  @Expose() exportVatPercentage?: number;
  @Expose() sisterCompanyVatPercentage?: number;
  @Expose() sisterCompanies?: Receiver[] = [];
  @Expose() contactType?: string;
  @Expose() isDefault?: boolean;
  @Expose() customerCommercialNameEn: string;
  @Expose() customerCommercialNameAr: string;
  @Expose() address: string;
  @Expose() addressAr: string;
  @Expose() customerType: CustomerType;
  @Expose() customerForLocation?: boolean;
  @Expose() parentId?: number;
  @Expose() commercialRegistrationId?: number;
  @Expose() canCreateShipmentWithoutPriceAndCost?: boolean;
  @Expose() parent?: any;
  @Type(() => LocationModel)
  @Expose()
  childrenLocations?: LocationModel[] = [];
  allLocations?: LocationModel[] = [];
  @Type(() => User)
  @Expose()
  createdBy?: User;
  @Expose() allowToSelectAllDriversAndTrucks?: boolean;
  @Expose() allowToCancelShipment?: boolean;
  @Expose() isTwoFactorAuthEnabled?: boolean;

  @Expose()
  creationTemplates?: ShipmentCreationTemplate[];
  @Expose() labelType?: CompanyLabelType;
  @Expose() reference?: string;

  @Type(() => TruckType)
  @Expose()
  defaultTruckType?: TruckType;
  @Expose()
  isWalletEnabled?: boolean;
  @Expose()
  hasTransactions?: boolean;

  constructor() {
    super();
  }

  get contactName(): string {
    return this.contactFirstName + ' ' + this.contactLastName;
  }

  get dropDownDisplayName(): string {
    return this.title;
  }
  get dropDownValue(): Customer {
    return this;
  }
}
