import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { hashString } from '@shared/utils/hash-string';

@Injectable({
  providedIn: 'root'
})
export class MemoizeService {
  private cache = {};
  private currentlyLoading = {};
  private currentlyLoadingObservables = {};

  constructor() { }

  hashInputs(inputs: any, key?: string): string {
    return `${hashString(`${key || ''}_${JSON.stringify(inputs).replace(/[{}\[\],"]/g, '')}`)}`;
  }


  memoize<T = any>(id, requestCallBack): Observable<T> {
    if (this.cache[id] && this.cache[id]?.data) {
      return of(this.cache[id]);
    } else if (this.currentlyLoading[id] && this.currentlyLoadingObservables[id]) {
      return this.currentlyLoadingObservables[id];
    } else {
      const observable$  = requestCallBack().pipe(share());
      this.currentlyLoading[id] = true;
      this.currentlyLoadingObservables[id] = observable$;
      return observable$.pipe(
        tap((res: any) => {
          this.cache[id] = res;
          this.currentlyLoading[id] = false;
          this.currentlyLoadingObservables[id] = null;
        }),
      );
    }
  }

  resetCache(id?: string): void {
    if (id) {
      this.cache[id] = null;
      this.currentlyLoading[id] = false;
      this.currentlyLoadingObservables[id] = null;
    } else {
      this.cache = {};
      this.currentlyLoading = {};
      this.currentlyLoadingObservables = {};
    }
  }
}
