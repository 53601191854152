import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorLocalizationService {
    paginatorIntl = new MatPaginatorIntl();
    constructor(private readonly translate: TranslateService) {
        this.translate.onLangChange.subscribe(_ => {
            this.getPaginatorIntl();
        });
    }

    getPaginatorIntl(): MatPaginatorIntl {
        if (!this.paginatorIntl) {
            this.paginatorIntl = new MatPaginatorIntl();
        }
        this.paginatorIntl.itemsPerPageLabel = this.translate.instant('Paginator.ItemsPerPage');
        this.paginatorIntl.nextPageLabel = this.translate.instant('Paginator.NextPage');
        this.paginatorIntl.previousPageLabel = this.translate.instant('Paginator.PreviousPage');
        this.paginatorIntl.firstPageLabel = this.translate.instant('Paginator.FirstPage');
        this.paginatorIntl.lastPageLabel = this.translate.instant('Paginator.LastPage');
        this.paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return this.paginatorIntl;
    }

    private getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return this.translate.instant('Paginator.PageRange1', { length });
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('Paginator.PageRange2', { startIndex: startIndex + 1, endIndex, length });
    }
}
