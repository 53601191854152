import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class ProductTypeItem extends Model {
  @Expose()
  id?: number;

  @Expose()
  name: string;
  @Expose()
  SKU: string;
  @Expose()
  note: string;
}
