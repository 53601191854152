import { LanguageEnum } from "@shared/enums/language.enum";
import { Model } from './model';

export class Language extends Model {
  constructor(
    public id: string = '',
    public name = 'Arabic',
    public isoCode = LanguageEnum.Arabic,
    public isDefault: boolean = false
  ) {
    super();
  }
}
