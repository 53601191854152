<div class="info" [ngClass]="['info--'+ type, 'info--'+ direction]">
  <div class="info__item">
    <div class="info__item-icon" *ngIf="item.icon">
      <app-icon [name]="item.icon " [type]="item.iconType || IconType.SVG"></app-icon>
    </div>
    <div class="info__item-content">
      <div class="info__item-label">{{ item.label }}</div>
      <div class="info__item-value text-900 weight-medium">
        {{ item.value || 'ــ ــ' }}
        <small class="info__item-suffix">{{ item.suffix }}</small>
      </div>
    </div>
  </div>
</div>
