import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivityLogTemplateDirective } from '@shared/directives/activity-log-template.directive';
import { ActivityLogTemplate } from '@shared/enums/activity-log-template.enum';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { getValueByPath } from '@shared/utils/get-value-by-path.util';

@Component({
  selector: 'app-activity-log-list',
  templateUrl: './activity-log-list.component.html',
  styleUrls: ['./activity-log-list.component.scss'],
  host: { class: 'timeline madar--v2' },
})
export class ActivityLogListComponent implements OnChanges, AfterViewInit {
  @Input() items: any[];
  @Input() namePath: string;
  @Input() avatarPath: string;
  @Input() datePath: string;
  @Input() avatarSize = 32;

  names: string[] = [];
  avatars: string[] = [];
  dates: Date[] = [];

  @ContentChildren(ActivityLogTemplateDirective) templates: QueryList<ActivityLogTemplateDirective>;
  protected customTemplates: Record<ActivityLogTemplate, TemplateRef<any>> =
    {} as unknown as Record<ActivityLogTemplate, TemplateRef<any>>;
  destroy$ = new Subject();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.items?.length && this.namePath) {
      this.names = this.items.map((item) => getValueByPath(item, this.namePath));
    }

    if (this.items?.length && this.avatarPath) {
      this.avatars = this.items.map((item) => getValueByPath(item, this.avatarPath));
    }

    if (this.items?.length && this.datePath) {
      this.dates = this.items.map((item) => getValueByPath(item, this.datePath));
    }
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewInit() {
    this.templates.changes
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.templates),
        startWith(this.templates)
      )
      .subscribe((templates) => {
        this.customTemplates = templates.reduce((g, t) => {
          const type: string = t.type;
          g[type] = t.templateRef;
          return g;
        }, {} as Record<string, TemplateRef<any>>);
        this.changeDetectorRef.markForCheck();
      });
  }

  protected readonly ActivityLogTemplate = ActivityLogTemplate;
}
