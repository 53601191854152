<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <ng-container>
    <div class="row mt-3">
      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.TotalDistance" | translate }}</span>
        <p class="weight-semibold text-900 m-0">{{avlSummary?.distance ? (avlSummary.distance | number:'1.0-2') : '_______'}} {{"Shipments.km" | translate}}</p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.AvgSpeed" | translate }}</span>
        <p class="weight-semibold text-900 m-0">
          {{avlSummary?.averageSpeed ? (avlSummary.averageSpeed | number:'1.0-2') : '_______'}} {{"Shipments.km" | translate}}/{{"Shipments.Hour" | translate}}
        </p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.MaxSpeed" | translate }}</span>
        <p class="weight-semibold text-900 m-0">
          {{avlSummary?.maxSpeed ? (avlSummary.maxSpeed | number:'1.0-2') : '_______'}} {{"Shipments.km" | translate}}/{{"Shipments.Hour" | translate}}
        </p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.DrivingHours" | translate }}</span>
        <p class="weight-semibold text-900 m-0">{{avlSummary?.drivingHours ? (avlSummary.drivingHours | number:'1.0-2') : '_______'}} {{"Shipments.Hour" | translate}}</p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.StopHours" | translate }}</span>
        <p class="weight-semibold text-900 m-0">{{avlSummary?.stopHours ? (avlSummary.stopHours | number:'1.0-2') : '_______'}} {{"Shipments.Hour" | translate}}</p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.EngineActive" | translate }}</span>
        <p class="weight-semibold text-900 m-0">{{avlSummary?.engineOn ? (avlSummary.engineOn | number:'1.0-2') : '_______'}} {{"Shipments.Hour" | translate}}</p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.EngineIdle" | translate }}</span>
        <p class="weight-semibold text-900 m-0">
          {{avlSummary?.engineOff ? ((avlSummary.engineOff/(3600000)) | number:'1.0-2') : '_______'}} {{"Shipments.Hour" | translate}}
        </p>
      </div>

      <div class="col-6 mb-2">
        <span class="text-600 h6">{{ "Shipments.AvlPerformance.HardBraking" | translate }}</span>
        <p class="weight-semibold text-900 m-0">{{avlSummary?.hardBraking || '_______'}}</p>
      </div>
    </div>
  </ng-container>
</app-state-section>

