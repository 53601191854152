import { Component, ElementRef,  Input, SimpleChanges, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LanguageEnum } from '@shared/enums/language.enum';
import { PlateLetters } from '@shared/model/plate-letters.model';
import {
  arabicLettersValidator,
  englishLettersValidator,
  lettersOnlyValidator,
} from '@shared/validators/form-validators';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'app-plate-letters',
  templateUrl: './plate-letters.component.html',
  styleUrls: ['./plate-letters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PlateLettersComponent,
    },
  ],
})
export class PlateLettersComponent extends BaseComponent implements ControlValueAccessor {
  @Input() language: LanguageEnum = LanguageEnum.Arabic;
  @Input() required = false;
  @ViewChild('left') left: ElementRef;
  @ViewChild('middle') middle: ElementRef;
  @ViewChild('right') right: ElementRef;
  form = this.formBuilder.group({
    left: [''],
    middle: [''],
    right: [''],
  });
  value: PlateLetters = {} as PlateLetters;
  Language = LanguageEnum;
  validators = [lettersOnlyValidator];

  onChange = (value: { left: string; middle: string; right: string }) => {};
  onTouched: () => void;

  constructor(private formBuilder: UntypedFormBuilder, ) {
    super();
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(50))
      .subscribe(({ left, middle, right }) => {
        this.value = this.form.value;
        this.onChange(this.value);
        this.onTouched();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['language'] &&
      changes['language'].currentValue &&
      changes['language'].currentValue !== changes['language'].previousValue
    ) {
      if (this.language === LanguageEnum.English) {
        this.validators.push(englishLettersValidator);
      } else {
        this.validators.push(arabicLettersValidator);
      }
    }

    if (
      changes['required'] &&
      changes['required'].currentValue &&
      changes['required'].currentValue !== changes['required'].previousValue
    ) {
      this.validators.push(Validators.required);
    }

    this.form.controls.left.setValidators([...this.validators]);
    this.form.controls.middle.setValidators([...this.validators]);
    this.form.controls.right.setValidators([...this.validators]);
    this.form.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  writeValue(value: PlateLetters): void {
    const left = (value && value.left) || null;
    const middle = (value && value.middle) || null;
    const right = (value && value.right) || null;

    this.form.patchValue(
      {
        left,
        middle,
        right,
      },
      { onlySelf: true, emitEvent: false }
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  moveCursorToNextField(nextField: string) {
    switch (nextField) {
      case 'left':
        this.left.nativeElement.focus();
        break;

      case 'middle':
        this.middle.nativeElement.focus();
        break;

      case 'right':
        this.right.nativeElement.focus();
        break;
    }
  }
}
