import { Expose } from 'class-transformer';
import { NotificationType } from "@features/notifications/enums/notification-type.enum";
import { Model } from './model';

export class NotificationItem extends Model {
  public timeout = 5000;

  @Expose() id: string;
  @Expose() title: string;
  @Expose() body: string;
  @Expose() type: NotificationType = NotificationType.Shipment;
  @Expose() elementId: number;
  @Expose() isRead = false;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;

}
