import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@features/users/models/user';
import { GeneralResponse } from '@core/enums/general-response.enum';
import { AccountVerificationType } from '@features/auth/enums/account-verification-type.enum';
import { JWTTokenValidation } from '@core/enums/JWT-token-validation.enum';
import { UserDeviceService } from './user-device.service';
import { JwtDecoderService } from './jwt-decoder.service';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { ApiConstant } from '@shared/constants/api.constant';
import { ApiBaseService } from '@core/services/api-base.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { SharedConstants } from '@shared/model/shared-constants';
import { Mapper } from './mapper';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import { ResetPasswordMethods } from '@features/auth/enums/reset-password-methods.enum';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { UserDevice } from '@features/users/models/user-device';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  public redirectionURL = '';

  constructor(
    private baseAPI: ApiBaseService,
    private mapper: Mapper,
    private userDeviceService: UserDeviceService,
    private jwtDecoderService: JwtDecoderService,
    private refreshTokenHandler: HttpBackend,
    private featureFlagService: FeatureFlagService
  ) {
    if (this.jwtDecoderService.isThereValidToken() === JWTTokenValidation.Valid) {
      this.currentUser$.next(this.jwtDecoderService.getCurrentUserFromJWTToken());
    }
  }

  isLoggedIn(): boolean {
    return this.jwtDecoderService.isThereValidToken() !== JWTTokenValidation.NotFound;
  }

  registerDevice(notificationToken: string): Observable<UserDevice> {
    let userDevice = this.userDeviceService.getCurrentUserDevice();
    if (!userDevice || !notificationToken) {
      userDevice = this.userDeviceService.generateNewDevice();
    }
    const timezoneOffset = -1 * new Date().getTimezoneOffset();
    const body = filterNullEntity({ ...userDevice, timezone: timezoneOffset });

    return this.baseAPI.post(ApiConstant.REGISTER_DEVICE, body).pipe(
      map((res) => {
        this.userDeviceService.saveUserDevice(userDevice);
        return userDevice;
      })
    );
  }

  login(email: string, password: string, otp?: string): Observable<User> {
    const userDevice = this.userDeviceService.getCurrentUserDevice();
    const body = filterNullEntity({
      username: email,
      password,
      deviceId: userDevice?.deviceId,
      platform: userDevice?.osType || 'WEB',
      app: SharedConstants.LOGIN_APP,
      otp,
    });
    return this.baseAPI.post(ApiConstant.LOGIN, body).pipe(
      map((res) => this.mapper.fromJson(User, res.data)),
      switchMap((user) => this.featureFlagService.init(user)),
      tap((user) => this.saveTokenAndUpdateUser(user))
    );
  }

  refreshTheToken(): Observable<User> {
    const userDevice = this.userDeviceService.getCurrentUserDevice();
    const currentUser = this.jwtDecoderService.getCurrentUserFromJWTToken();
    /**
     * @Note:
     * Wrokaround to override the JwtHttpInterceptor for refreshing the token.
     */
    const http = new HttpClient(this.refreshTokenHandler);
    const body = {
      deviceId: userDevice?.deviceId,
      platform: userDevice?.osType,
      app: SharedConstants.LOGIN_APP,
    };
    const options = {
      headers: this.baseAPI
        .getHeaders()
        .append('Authorization', 'Bearer ' + currentUser?.refreshToken),
    };
    return http.post(this.baseAPI.switchUrlBase(ApiConstant.REFRESH_TOKEN), body, options).pipe(
      map((res: any) => this.mapper.fromJson(User, res.data)),
      tap((user) => this.saveTokenAndUpdateUser(user))
    );
  }

  verifyEmail(
    token: string,
    verificationType: AccountVerificationType,
    password: string
  ): Observable<GeneralResponse> {
    const body = { token, verificationType, password };
    return this.baseAPI.post(ApiConstant.VERIFY_EMAIL, body).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  forgetPassword(type: ResetPasswordMethods, value: string): Observable<string> {
    const idKeyName = type === ResetPasswordMethods.EMAIL ? 'email' : 'mobileNumber';
    const body = { type, [idKeyName]: value };
    return this.baseAPI.post(ApiConstant.FORGET_PASSWORD, body).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  forgetPasswordVerifyMobile(otp: string, mobileNumber: string): Observable<string> {
    const body = { otp, mobileNumber };
    return this.baseAPI.post(ApiConstant.FORGET_PASSWORD_VERIFY_MOBILE, body).pipe(
      map((res) => {
        return res.data;
      })
    );
  }
  requireOTP(payload: {
    mobileNumber: string;
    password: string;
    isLogin: boolean;
  }): Observable<any> {
    return this.baseAPI.post(ApiConstant.REQUEST_OTP, payload).pipe(
      map((res) => {
        return res;
      })
    );
  }
  private saveTokenAndUpdateUser(user: User): void {
    this.jwtDecoderService.saveToken(user.toJson());
    this.currentUser$.next(this.jwtDecoderService.getCurrentUserFromJWTToken());
  }
}
