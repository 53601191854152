import { Expose } from 'class-transformer';
import { DropdownModel } from '@shared-features/models/dropdown-model';
import { GateDefinitionEnum } from '@features/shipments/enums/gate-definition.enum';

export class GateDefinition extends DropdownModel {
  @Expose()
  public id?: number;
  @Expose()
  public type: GateDefinitionEnum;
  @Expose()
  public temperatureControl?: boolean;
  @Expose()
  public name: string;
  @Expose()
  public reference: string;
  @Expose()
  public truckClassify: string;

  @Expose()
  get dropDownDisplayName(): string {
    const infix = this.reference ? ` (${this.reference})` : '';
    return this.name + infix;
  }
  @Expose()
  get dropDownValue(): GateDefinition {
    return this;
  }
}
