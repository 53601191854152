import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input() type: 'symbol' | 'full';
  @Input() size: 'sm' | 'md' | 'lg';
  private ratio = 167 / 40;
  private sizeMap = {
    sm: 36,
    md: 48,
    lg: 72,
  };
  private urls = {
    symbol: '/assets/images/Logo.svg',
    full: '/assets/images/madar-logo.svg',
  };

  get width(): string {
    const size = this.size || 'md';
    const ratio = this.type === 'symbol' ? 1 : this.ratio;
    return `${Math.round(this.sizeMap[size] * ratio)}px`;
  }

  get height(): string {
    const size = this.size || 'md';
    return `${this.sizeMap[size]}px`;
  }

  get imgSrc(): string {
    return this.urls[this.type] || this.urls.full;
  }

  constructor() {}

  ngOnInit(): void {}
}
