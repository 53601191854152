<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form form-wrapper" [formGroup]="form" *ngIf="sectionState === sectionStateStatus.Ready">
    <!-- date -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Time" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="dateRange">
          <mat-option *ngFor="let range of dateRangeTypes | enumToArray" [value]="range.value">
            {{ 'Enum.DateRangeType.' + range.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- CreatedBy -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.CreatedBy" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="creatorId">
          <app-dropdown-search formControlName="searchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of filteredCreators | sortDropdown" [value]="item.id">{{ item.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- status -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Status" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="invoicesStatus">
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of InvoicesStatus | enumToArray" [value]="item.value">
            {{ 'Enum.InvoicesStatus.' + item.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
  </form>
</app-state-section>
