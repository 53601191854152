import { Component, OnInit, Input,  ChangeDetectorRef,ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { OperationPerformanceDNStatus } from "@features/dashboard/enums/operation-performance-DN-status.enum";
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { SharedConstants } from '@shared/model/shared-constants';
import { Customer } from '@features/customers/models/customer';
import { FilterService } from '@shared/services/filter.service';
import {
  OperationPerformanceFilterBloc,
} from '../../../dashboard/bloc/operation-performance-filter/operation-performance-filter-bloc';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { debounceTime, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BlocState, ProcessingState, ReadyState } from '@core/bloc/bloc-state';
import { InitEvent } from '@core/bloc/bloc-event';
import { LocalStorageDashboardStatus } from '@shared/model/dashboard.model';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { FunctionUtil } from '@shared/utils/function.util';
import { CompanyLabelType } from "@shared/enums/company-label-type.enum";
import { CustomerType } from "@features/customers/enums/customer-type.enum";
import { EnumToArrayPipe } from '@shared/pipes/enum-to-array.pipe';
import { DropdownModel } from '@shared-features/models/dropdown-model';
import { TranslateService } from '@ngx-translate/core';
import { StorageConstant } from '@shared/constants/storage.constant';

@Component({
  selector: 'app-operation-performance-filter',
  templateUrl: './operation-performance-filter.component.html',
  styleUrls: ['./operation-performance-filter.component.scss'],
  providers: [OperationPerformanceFilterBloc],
})
export class OperationPerformanceFilterComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() action: Observable<void>;
  form: FormGroup;
  dispose$ = new Subject();

  sharedConstants = SharedConstants;
  dateRangeType = DateRangeType;
  filterActionSubscription: Subscription;
  sectionStateStatus = SectionStateStatus;
  params: any = null;

  customers: Customer[] = [];
  searchResultCustomers: Customer[] = [];
  selectedCustomerIds: number[] = [];
  maxDate: Date = new Date();
  dashboardStatus: LocalStorageDashboardStatus = new LocalStorageDashboardStatus();
  DeliveryNoteStatus = OperationPerformanceDNStatus;
  CustomerType = CustomerType;
  CompanyLabelType = CompanyLabelType;

  enumToArrayPipe = new EnumToArrayPipe();
  companyLabels;

  constructor(
    private filterService: FilterService,
    private ref: ChangeDetectorRef,
    private bloc: OperationPerformanceFilterBloc,
    
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.bloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    this.bloc.setEvent.next(new InitEvent());
    this.dashboardStatus = LocalStorageUtil.getItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS);
    this.subscribeToFilterAction();

    this.translateService.onLangChange.pipe(
      takeUntil(this.destroy$),
      startWith(true),
      switchMap(() => of(this.enumToArrayPipe.transform(this.CompanyLabelType))),
      map((labels) => labels.map(item => ({
        dropDownDisplayName: this.translateService.instant('Enum.CompanyLabelType.' + item.key),
        dropDownValue: item.value,
      })))
    ).subscribe((labels) => {
      this.companyLabels = labels;
      this.initForm();
    });
  }

  ngOnDestroy(): void {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      customers: [this.searchResultCustomers.map((item) => item.id)],
      customersType: [this.dashboardStatus.operationPerformanceCustomersType || ''],
      customerLabel: [
        this.companyLabels?.filter((label) =>
          this.dashboardStatus.customerLabel?.includes(label.dropDownValue))
      ],
      fleetLabel: [
        this.companyLabels?.filter((label) =>
          this.dashboardStatus.fleetLabel?.includes(label.dropDownValue))
      ],
      time: [DateRangeType.TODAY],
      startFrom: [null],
      endAt: [null],
      deliveryNoteStatus: [
        this.dashboardStatus.operationPerformanceDeliveryNoteStatus || this.DeliveryNoteStatus.All
      ],
    });

    if (this.dashboardStatus?.operationPerformanceCustomers?.length) {
      this.form.controls.customers.setValue([...this.dashboardStatus.operationPerformanceCustomers]);
    }

    if (this.dashboardStatus?.operationPerformanceTime) {
      this.form.controls.time.setValue(this.dashboardStatus.operationPerformanceTime);

      if (
        this.dashboardStatus?.operationPerformanceTime === DateRangeType.CUSTOM &&
        this.dashboardStatus?.operationPerformanceTimeStartFrom &&
        this.dashboardStatus?.operationPerformanceTimeEndAt
      ) {
        this.form.controls.startFrom.setValue(this.dashboardStatus?.operationPerformanceTimeStartFrom);
        this.form.controls.endAt.setValue(this.dashboardStatus?.operationPerformanceTimeEndAt);
      }
    }

    this.filterService.generalDashboardConfig$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.form.patchValue({
        time: res?.range?.range || DateRangeType.TODAY,
        startFrom: res?.range?.startFrom || null,
        endAt: res?.range?.endAt || null,
        customers: res?.customers?.map((customer) => customer.id) || [],
        customerLabel: res?.customerLabels?.map((label) => this.companyLabels.find(l => l.dropDownValue === label.id)) || [],
      });
    });

    this.form.get('time').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
    ).subscribe((value) => {
      if (value !== DateRangeType.CUSTOM) {
        this.form.controls.startFrom.setValue(null);
        this.form.controls.endAt.setValue(null);
      }
    });

    this.form.get('customersType').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
    ).subscribe((value) => {
      if (this.form.get('customersType').value !== '') {
        this.form.controls.customers.setValue([]);
      }
    });

    this.form.get('customerLabel').valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(250),
    ).subscribe((value) => {
      if (this.form.get('customerLabel').value !== '') {
        this.form.controls.customers.setValue([]);
      }
    });
  }

  subscribeToFilterAction(): void {
    this.filterActionSubscription = this.action.subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  resetForm(): void {
    this.form.setValue({
      customers: [...this.searchResultCustomers.map((item) => item.id)],
      time: DateRangeType.TODAY,
      startFrom: null,
      endAt: null,
      deliveryNoteStatus: this.DeliveryNoteStatus.All,
      customersType: '',
      customerLabel: [],
      fleetLabel: [],
    });
    this.submitForm();
  }

  cancelChanges() {
    this.form.setValue({
      customers: this.dashboardStatus.operationPerformanceCustomers,
      customersType: this.dashboardStatus.operationPerformanceCustomersType,
      customerLabel: this.dashboardStatus.customerLabel,
      fleetLabel: this.dashboardStatus.fleetLabel,
      time: this.dashboardStatus.operationPerformanceTime,
      startFrom: this.dashboardStatus.operationPerformanceTimeStartFrom,
      endAt: this.dashboardStatus.operationPerformanceTimeEndAt,
      deliveryNoteStatus: this.dashboardStatus.operationPerformanceDeliveryNoteStatus,
    });
  }

  submitForm(): void {
    const { value } = this.form;

    this.dashboardStatus.operationPerformanceTime = value.time;
    this.dashboardStatus.operationPerformanceDeliveryNoteStatus = value.deliveryNoteStatus;
    this.dashboardStatus.operationPerformanceCustomers = value.customers;
    this.dashboardStatus.operationPerformanceCustomersType = value.customersType;
    this.dashboardStatus.customerLabel = value.customerLabel.map((item) => (item.dropDownValue));
    this.dashboardStatus.fleetLabel = value.fleetLabel.map((item) => (item.dropDownValue))
    if (value.time === DateRangeType.CUSTOM && value.startFrom && value.endAt) {
      this.dashboardStatus.operationPerformanceTimeStartFrom = FunctionUtil.convertDate(value.startFrom);
      this.dashboardStatus.operationPerformanceTimeEndAt = FunctionUtil.convertDate(value.endAt);
    } else {
      this.dashboardStatus.operationPerformanceTimeStartFrom = null;
      this.dashboardStatus.operationPerformanceTimeEndAt = null;

    }
    this.filterService.selectedFiltrationValue$.next(
      {
        group: TabGroup.Dashboard,
        index: TabIndex.OperationPerformance,
        filteredValue: this.dashboardStatus,
      });
    LocalStorageUtil.setItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS, this.dashboardStatus);
  }

  applyFilter(value) {
    this.searchResultCustomers = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.customers.filter((option: Customer) => option.title.toLowerCase().startsWith(filter));
  }

  isIndeterminate(): boolean {
    return (
      this.form.controls.customers.value &&
      this.searchResultCustomers?.length &&
      this.form.controls.customers.value?.length < this.searchResultCustomers?.length
    );
  }

  isChecked(): boolean {
    return (
      this.form.controls.customers.value &&
      this.searchResultCustomers?.length &&
      this.form.controls.customers.value?.length === this.searchResultCustomers?.length
    );
  }

  onToggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.form.controls.customers.patchValue([...this.searchResultCustomers.map((item) => item.id)]);
      this.selectedCustomerIds = [...this.form.controls.customers.value];
    } else {
      this.form.controls.customers.patchValue([]);
      this.selectedCustomerIds = [];
    }
  }

  private handleBlocStates(state: BlocState) {
    if (!state) {
      return;
    }

    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof ReadyState) {
      this.customers = state.data;
      this.searchResultCustomers = [...this.customers] || [];
      this.selectedCustomerIds = [...this.searchResultCustomers.map((item) => item.id)];
      this.initForm();
      this.sectionState = SectionStateStatus.Ready;
    }
  }
}
