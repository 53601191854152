import { Component, EventEmitter,  Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FilterService } from '@shared/services/filter.service';
import { SharedConstants } from '@shared/model/shared-constants';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { FilterAction } from '@features/filter/models/filter-action';
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { City } from '@shared-features/models/city';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { FunctionUtil } from '@shared/utils/function.util';
@Component({
  selector: 'app-delivery-notes-filter',
  templateUrl: './delivery-notes-filter.component.html',
  styleUrls: ['./delivery-notes-filter.component.scss'],
})
export class DeliveryNotesFilterComponent extends BaseComponent implements OnInit {
  form: UntypedFormGroup;
  sharedConstants = SharedConstants;

  filterActionSubscription: Subscription;
  @Input() action: Observable<FilterAction>;
  params: any = null;
  dateRangeType = DateRangeType;
  fromCity: City[] = [];
  toCity: City[] = [];
  waittingCharge: number;
  multiDis: number;

  sectionStateStatus = SectionStateStatus;

  dispose$ = new Subject();
  @Input() tabGroup: TabGroup;
  @Input() tabIndex: TabIndex;
  dateRangeValue: DateRangeType = DateRangeType.TODAY;
  @Output() startFrom = new EventEmitter();
  @Output() endAt = new EventEmitter();
  @Output() selectedDateRangeChanged = new EventEmitter();
  constructor(private filterService: FilterService, private activatedRoute: ActivatedRoute, ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.initForm();
  }

  ngOnDestroy() {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.dispose$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  subscribeToFilterAction() {
    this.filterActionSubscription = this.action.subscribe((action: FilterAction) => {
      switch (action.action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  getFilterValuesBasedOnURLParams() {
    return {
      dateRange: this.params.dateRange === SharedConstants.ALL ? SharedConstants.ALL : String(this.params.dateRange),
      fromCity: this.params.fromCity === SharedConstants.ALL ? SharedConstants.ALL : String(this.params.fromCity),
      toCity: this.params.toCity === SharedConstants.ALL ? SharedConstants.ALL : String(this.params.toCity),
      waittingCharge: this.params.waittingCharge === Number(this.params.waittingCharge),
      multiDis: this.params.multiDis === Number(this.params.multiDis),
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      this.form = new UntypedFormGroup({
        dateRange: new UntypedFormControl(this.params.dateRange),
        fromCity: new UntypedFormControl(this.params.fromCity),
        toCity: new UntypedFormControl(this.params.toCity),
        waittingCharge: new UntypedFormControl(this.params.waittingCharge),
        multiDis: new UntypedFormControl(this.params.multiDis),
        from: new UntypedFormControl(null),
        to: new UntypedFormControl(null),
        fromCitySearchTerm: new UntypedFormControl(''),
        toCitySearchTerm: new UntypedFormControl(''),
      });
    } else {
      this.form = new UntypedFormGroup({
        dateRange: new UntypedFormControl(SharedConstants.ALL),
        fromCity: new UntypedFormControl(SharedConstants.ALL),
        toCity: new UntypedFormControl(SharedConstants.ALL),
        waittingCharge: new UntypedFormControl(SharedConstants.ALL),
        multiDis: new UntypedFormControl(SharedConstants.ALL),
        from: new UntypedFormControl(null),
        to: new UntypedFormControl(null),
        fromCitySearchTerm: new UntypedFormControl(''),
        toCitySearchTerm: new UntypedFormControl(''),
      });
    }
  }

  get filteredFromCities() {
    return this.fromCity.filter((city) =>
      city.dropDownDisplayName.toLowerCase().includes(this.form.controls.fromCitySearchTerm.value?.toLowerCase())
    );
  }

  get filteredToCities() {
    return this.toCity.filter((user) =>
      user.dropDownDisplayName.toLowerCase().includes(this.form.controls.toCitySearchTerm.value?.toLowerCase())
    );
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.controls['dateRange'].setValue(filterValues.dateRange);
      this.form.controls['fromCity'].setValue(filterValues.fromCity);
      this.form.controls['toCity'].setValue(Number(filterValues.toCity));
      this.form.controls['waittingCharge'].setValue(filterValues.waittingCharge);
      this.form.controls['multiDis'].setValue(filterValues.multiDis);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.form.controls['dateRange'].setValue(SharedConstants.ALL);
    this.form.controls['fromCity'].setValue(SharedConstants.ALL);
    this.form.controls['toCity'].setValue(SharedConstants.ALL);
    this.form.controls['waittingCharge'].setValue(null);
    this.form.controls['multiDis'].setValue(null);

    this.submitForm();
  }

  submitForm() {
    const obj = this.form.value;
    obj.tabIndex = this.tabIndex;
    obj.tabGroup = this.tabGroup;
    this.filterService.navigateToTargetPageWithFilter(obj);
  }
  customDateChanged() {
    if (this.form.controls['to'].value) {
      this.startFrom.emit(FunctionUtil.convertDate(this.form.controls['from'].value));
      this.endAt.emit(FunctionUtil.convertDate(this.form.controls['to'].value));
      this.selectedDateRangeChanged.emit(this.dateRangeValue);
    }
  }

  OnDateSelectionChange(item: MatSelectChange) {
    this.dateRangeValue = item.value;
    if (this.dateRangeValue !== DateRangeType.CUSTOM) {
      this.selectedDateRangeChanged.emit(this.dateRangeValue);
    } else {
      this.form.controls['from'].setValue(null);
      this.form.controls['to'].setValue(null);
    }
  }
}
