<form [formGroup]="form" class="field flex justify-center column">
  <mat-label [ngClass]="{'asterisk-after': !isExternal}" *ngIf="showLabel">{{ "Providers.Trucks.ManufactureYear" | translate }}</mat-label>
  <mat-form-field appearance="outline">
    <input
      [max]="maxManufactureYear"
      type="number"
      min="0"
      oninput="validity.valid||(value='');"
      matInput
      formControlName="year"
      [matDatepicker]="yearDate"
      [placeholder]="'Placeholder.YearPlaceholder' | translate"
    />
    <!-- TODO: may be needed later -->
    <!-- (click)="yearDate.open()" -->
    <mat-datepicker-toggle matSuffix [for]="yearDate"></mat-datepicker-toggle>
    <mat-datepicker #yearDate startView="multi-year" (yearSelected)="yearSelected($event, yearDate)"></mat-datepicker>
    <mat-error *ngIf="form.invalid && form.get('year').hasError('required')">{{ "ErrorMessages.Required" | translate }}</mat-error>
  </mat-form-field>
</form>
