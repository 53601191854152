import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NamedField } from '@shared/interfaces/field.interface';
import { FormArray, FormGroup } from '@angular/forms';
import { FieldBase } from './field-base';

@Component({
  selector: 'app-textarea-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-label class="field__label" *ngIf="field.label"
        >{{ field.label | translate }}
        <span *ngIf="isRequired" class="text-warn">*</span></mat-label
      >
      <mat-form-field class="field__group d-block w-100" appearance="outline">
        <div *ngIf="field.prefix" matPrefix (click)="prefixClick.emit($event)">
          <ng-container #prefixRef></ng-container>
        </div>
        <textarea
          matInput
          class="field__input"
          #element
          [id]="field.name + '_' + uuid"
          [formControl]="group.get(field.name)"
          type="text"
          [placeholder]="field.placeholder | translate"
          (click)="fieldClick.emit($event)"
        >
        </textarea>
        <div *ngIf="field.suffix" matSuffix (click)="suffixClick.emit($event)">
          <ng-container #suffixRef></ng-container>
        </div>
        <mat-error *ngIf="errors.length" class="field__error">
          <app-field-errors [errors]="errors"></app-field-errors>
        </mat-error>
        <mat-hint *ngIf="field.inputs?.maxLength" class="text-right d-block px-0 w-100"
          >{{ group.get(field.name).value?.length || 0 }} / {{ field.inputs.maxLength }}</mat-hint
        >
      </mat-form-field>
    </ng-container>
  `,
  host: { class: 'field text-area' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaFieldComponent extends FieldBase {
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;
  @ViewChild('element') element: ElementRef;
}
