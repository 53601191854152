import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { LayoutService } from '@shared/services/layout.service';
@Directive({
  selector: '[popoverTrigger]',
  exportAs: 'popoverTrigger',
})
export class PopoverDirective implements OnDestroy, OnInit {
  @Input()
  popoverTrigger!: TemplateRef<object> | null;

  @Input()
  closeOnClickOutside: boolean = false;

  @Input()
  position: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  };

  @Input() mouseOnHover: boolean = false;

  @Input() backDrop: boolean = true;

  @Input() width: string;

  unsubscribe = new Subject();
  overlayRef!: OverlayRef;
  state: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private elementRef: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.createOverlay();
    this.getState().subscribe((resp) => {
      if (resp) {
        this.detachOverlay();
      }
    });
  }

  ngOnDestroy(): void {
    this.detachOverlay();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  @HostListener('click') clickou() {
    if (this.popoverTrigger) {
      this.toggle();
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.mouseOnHover && !this.overlayRef.hasAttached()) {
      this.open();
    }
  }

  createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.block();

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([this.position]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      hasBackdrop: this.backDrop,
      width: this.width,
      backdropClass: '',
    });

    this.overlayRef
      .backdropClick()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        if (this.closeOnClickOutside) {
          this.detachOverlay();
        }
      });
  }

  open(): void {
    const periodSelectorPortal = new TemplatePortal(this.popoverTrigger, this.viewContainerRef);
    this.overlayRef.attach(periodSelectorPortal);
    const direction = this.layoutService.websiteDirection$.getValue();
    this.overlayRef.setDirection(direction as any);
  }

  getState(): Observable<boolean> {
    return this.state.asObservable();
  }

  setState(value: boolean) {
    return this.state.next(value);
  }

  close(): void {
    this.overlayRef.detach();
  }

  attachOverlay(): void {
    if (!this.overlayRef.hasAttached()) {
      this.open();
    }
  }

  toggle(): void {
    if (!this.overlayRef.hasAttached()) {
      this.open();
    } else {
      this.close();
    }
  }

  detachOverlay(): void {
    if (this.overlayRef.hasAttached()) {
      this.close();
    }
  }
}
