import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';

export interface DialogState {
  id: string;
  ref?: MatDialogRef<any>;
  size?: { width: string; height: string; };
  position?: { top?: string; left?: string; right?: string; bottom?: string; };
  minimizeOrder?: number;
  shown: boolean;
  minimized: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MinimizableDialogsService implements OnDestroy {
  dialogs$: Observable<DialogState[]>;
  updates$ = new Subject();

  activeDialogs$ = new BehaviorSubject<string[]>([]);
  destroy$ = new Subject();
  private maximumCols = 1;
  get maximumColumns(): number {
    return this.maximumCols;
  }


  private dialogStates$ = new BehaviorSubject<DialogState[]>([]);

  private minimizedDialogs$ = new BehaviorSubject<DialogState[]>([]);


  constructor() {
    this.dialogs$ = this.dialogStates$.asObservable();

    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroy$), debounceTime(250), startWith(true))
      .subscribe(() => {
      this.maximumCols = Math.floor(window.innerWidth / 200);
      this.updates$.next(true);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getDialogById(id: string): Observable<DialogState|undefined> {
    return this.dialogs$.pipe(map((states) => states.find(state => state.id === id)));
  }

  add(dialogState: DialogState): void {
    const currentDialogs = (this.dialogStates$.value || []).filter((state) => state.id !== dialogState.id);
    const ids = this.activeDialogs$.value.filter((id) => id !== dialogState.id);
    this.dialogStates$.next([...currentDialogs, dialogState]);
    this.activeDialogs$.next([...ids, dialogState.id]);
  }

  remove(id: string): void {
    const states = {};
    const minimized = this.minimizedDialogs$.value.filter(d => d.id !== id).map((d, i) => {
      const state = { ...d, minimizeOrder: i};
      states[state.id] = state;
      return state;
    });
    this.dialogStates$.next(
      this.dialogStates$.value.filter((d) => d.id !== id)
        .map((d) => states[d.id] ? states[d.id] : d),
    );
    this.minimizedDialogs$.next(minimized);
    this.activeDialogs$.next(this.activeDialogs$.value.filter((activeId) => activeId !== id));
  }

  minimize(id: string): boolean {
    const dialog = this.dialogStates$.value.find((state) => state.id === id);
    if (dialog) {
      const states = {};
      const minimized = this.minimizedDialogs$.value.filter(d => d.id !== id).map((d, i) => {
        const state = { ...d, minimizeOrder: i};
        states[state.id] = state;
        return state;
      });
      states[dialog.id] = {...dialog, minimized: true, minimizeOrder: minimized?.length};
      this.minimizedDialogs$.next([...minimized, states[dialog.id]]);
      this.dialogStates$.next(
        this.dialogStates$.value.map((d) => states[d.id] ? states[d.id] : d),
      );
      this.activeDialogs$.next(this.activeDialogs$.value.filter((activeId) => activeId !== id));
      this.updates$.next(true);
      return true;
    } else {
      console.error(`[minimize service]: there is no active dialog with id: ${id}`);
      return false;
    }
  }

  maximize(id: string): boolean {
    const dialog = this.dialogStates$.value.find((state) => state.id === id);
    if (dialog) {
      dialog.minimizeOrder = null;
      dialog.minimized = false;
      const states = {};
      const minimized = (this.minimizedDialogs$.value || []).filter((state) => state.id !== dialog.id)
        .map((state, i) => {
          const newState = { ...state, minimizeOrder: i};
          states[newState.id] = newState;
          return newState;
        });

      this.minimizedDialogs$.next(minimized);
      this.dialogStates$.next(this.dialogStates$.value.map((state) => {
        if (states[state.id]) {
          return states[state.id];
        } else if (state.id === dialog.id) {
          return dialog;
        }
        return state;
      }));
      this.activeDialogs$.next(this.activeDialogs$.value.filter((activeId) => activeId !== id).concat(id));
      return true;
    } else {
      console.error(`[minimize service]: there is no minimized dialog with id: ${id}`);
      return false;
    }
  }

  update(dialogState: DialogState): void {
    this.dialogStates$.next(this.dialogStates$.value.map((d) => {
      if (d.id === dialogState.id) {
        return {...d, ...dialogState};
      }
      return d;
    }));
  }
}
