import { Pipe, PipeTransform } from '@angular/core';
import { DropdownModel } from '@shared-features/models/dropdown-model';
import { EnumModel } from '../model/enum-model';
@Pipe({
  name: 'sortDropdown',
})
export class sortDropdownPipe implements PipeTransform {
  transform(value: DropdownModel[] | EnumModel[] | any[]): any[] {
    if (value) {
      if (value[0] instanceof DropdownModel) {
        return value.sort((a, b) => a.dropDownDisplayName.toLowerCase().localeCompare(b.dropDownDisplayName.toLowerCase()));
      } else if (value[0] instanceof EnumModel) {
        return value.sort((a, b) => a['value'].toLowerCase().localeCompare(b['value'].toLowerCase()));
      } else {
        return value.sort((a, b) => a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()));
      }
    } else {
      return value;
    }
  }
}
