import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parenthese'
})
export class ParenthesePipe implements PipeTransform {

  transform(value: string|number): string {
    if (value !== '' && value !== null && typeof value !== 'undefined' && value !== 0) {
      return `( ${value} )`;
    }
    return '';
  }
}
