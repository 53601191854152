import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class TruckShipmentCounts extends Model {
    @Expose()
    completed: number;

    @Expose()
    unCompleted: number;

    @Expose()
    customers: number;
}
