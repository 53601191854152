export function clusterTextCalculator(markers, numStyles): any {
    let index = 0;
    const count = markers.length.toString();

    let dv = count;
    while (dv !== 0) {
      // @ts-ignore
      dv = parseInt(dv / 10, 10);
      index++;
    }

    index = Math.min(index, numStyles);
    return {
      text: '',
      index,
      title: count
    };
}


