import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RequestLog } from '@shared-features/models/request-log.model';
import { SafeUrl } from '@angular/platform-browser';
import { RequestCreatedByType } from "@features/logs/enums/request-created-by-type.enum";

@Component({
  selector: 'app-request-and-response-log',
  templateUrl: './request-and-response-log.component.html',
  styleUrls: ['./request-and-response-log.component.scss']
})
export class RequestAndResponseLogComponent {

  @Input() selectedItem: RequestLog & {label: string} = null;
  @Input() selectedItemPayload: string = null;

  @Output() closeModal = new EventEmitter();
  downloadString: SafeUrl;
  creatorType = RequestCreatedByType;

  constructor() { }

}
