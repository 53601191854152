export enum ShipmentStatus {
  None = '',
  New = 'NEW',
  DelayedAction = 'DELAYED_ACTION',
  Assigned = 'ASSIGNED',
  Accepted = 'ACCEPTED',
  InTransit = 'IN_TRANSIT',
  ArrivePickupLocation = 'ARRIVED_AT_PICKUP_LOCATION',
  GoodsLoaded = 'GOODS_LOADED',
  ArriveDropOffLocation = 'ARRIVED_AT_DROP_OFF_LOCATION',
  GoodsDelivered = 'GOODS_DELIVERED',
  Closed = 'CLOSED',
  NotFulfilled = 'NOT_FULFILLED',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED',
  Start = 'TRIP_STARTED',
}
