import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class AVLSummary extends Model {
  @Expose() distance: number;
  @Expose() averageSpeed: number;
  @Expose() maxSpeed: number;
  @Expose() stopHours: number;
  @Expose() engineOn: number;
  @Expose() engineOff: number;
  @Expose() hardBraking: number;
  @Expose() drivingHours: number;

  constructor() {
    super();
  }
}
