import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '@features/users/models/user';
// import { User } from '../data/model/user';

import { StorageConstant } from '@shared/constants/storage.constant';
import { JWTTokenValidation } from "@core/enums/JWT-token-validation.enum";
import { Mapper } from './mapper';

@Injectable({
  providedIn: 'root',
})
export class JwtDecoderService {
  constructor(private jwtHelperService: JwtHelperService, private mapper: Mapper) {}

  saveToken(authUSer: string) {
    if (authUSer) {
      localStorage.setItem(StorageConstant.AUTH_USER, authUSer);
    }
  }

  removeCurrentToken() {
    localStorage.removeItem(StorageConstant.AUTH_USER);
  }

  isThereValidToken(): JWTTokenValidation {
    const user = this.getCurrentUserFromJWTToken();
    if (user) {
      if (this.isTokenExpired(user.accessToken)) {
        return JWTTokenValidation.Expired;
      } else {
        return JWTTokenValidation.Valid;
      }
    }
    return JWTTokenValidation.NotFound;
  }

  isTokenExpired(authToken) {
    return authToken == null || this.jwtHelperService.isTokenExpired(authToken);
  }

  getDecodedToken() {
    const user = localStorage.getItem(StorageConstant.AUTH_USER);
    return JSON.parse(user);
  }

  getCurrentUserFromJWTToken() {
    const payloadMap = this.getDecodedToken();
    if (payloadMap != null) {
      const modifiedPayload = Object.assign(payloadMap, { userRoles: payloadMap['roles'].map((role) => role.userRole) });
      return this.mapper.fromJson(User, modifiedPayload);
    }
    return null;
  }

  editCurrentUserJWTToken(/*userProfile: User*/) {
    // const user = this.getDecodedToken();
    // user.firstName = userPrfoile.firstName;
    // user.lastName = userPrfoile.lastName;
    // user.media = userPrfoile.media;
    // localStorage.setItem(Constants.AUTH_TOKEN, JSON.stringify(user));
  }

  getTokenJson(token) {
    return this.jwtHelperService.decodeToken(token);
  }
}
