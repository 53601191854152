import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';


@Component({
  selector: 'app-legend-labels',
  templateUrl: './legend-labels.component.html',
  styleUrls: ['./legend-labels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LegendLabelsComponent implements AfterViewInit {
  @Input() reverseOrder: boolean = false;
  @Input() chartData;
  @Output() removeChartClicked = new EventEmitter<number>();
  @Input() columns: number = 3;
  @Input() showLastPoint: boolean = false;

  clickedIndexes: number[] = [];

  ngAfterViewInit(){
    const canceledIndex = this.chartData.findIndex(dataPoint => dataPoint.label === 'Canceled');
    if (canceledIndex !== -1) {
      // work around until investigate
      setTimeout(() => this.chartToggleClick(canceledIndex), 2000);
    }
  }

  calculateTotal(dataArray: number[]): number {
    return dataArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  lastPoint(dataArray: number[]): number {
    return dataArray[dataArray.length-1] ? dataArray[dataArray.length-1] : 0;
  }

  calcCompletedPercentage() {
    const total = this.calculateTotal(this.chartData[0].data);
    const completedSum = this.calculateTotal(this.chartData[1].data);
    return(completedSum / total) * 100;
  }

  chartToggleClick(index: number) {
    if(this.chartData[index]?.data.length > 0){
      this.removeChartClicked.emit(index)
      if (this.clickedIndexes.includes(index)) {
        this.clickedIndexes = this.clickedIndexes.filter((i) => i !== index);
      } else {
        this.clickedIndexes.push(index);
      }
    }
  }
}
