import { Model } from '@shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { RentedTruck } from '@features/fleets/models/rented-truck.model';

export class RentedTrucks extends Model {
  @Expose() count: number;
  @Expose() activeCount: number;
  @Expose() inActiveCount: number;
  @Type(() => RentedTruck)
  @Expose()
  truck: RentedTruck[];
}
