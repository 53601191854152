<div class="card-section">
  <app-row gutter="x-xs">
    <app-col cols="auto" [class.d-none]="!showIcon">
      <div #iconColumn>
        <ng-content select="[icon]"></ng-content>
      </div>
    </app-col>
    <app-col>
      <div class="card-section__title">
        <ng-content select="[title]"></ng-content>
      </div>
      <div class="card-section__content">
        <ng-content select="[content]"></ng-content>
      </div>
    </app-col>
  </app-row>
</div>
