import { Expose } from 'class-transformer';
import { Model } from '@shared-features/models/model';

export class TrucksNonContractedDays extends Model {
  @Expose()
  truckId: number;

  @Expose()
  plateNumber: string;

  @Expose()
  nonContractedDays: number;
}
