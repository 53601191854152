import { Expose, Type } from 'class-transformer';
import { FleetHasPayments } from './fleet-has-payments.model';
import { Model } from "../../shared-features/models/model";

export class FleetHasPaymentsDetails extends Model {
    @Type(()=> FleetHasPayments)
    @Expose() fleet: FleetHasPayments[]=[];

    @Expose() count: number;
}
