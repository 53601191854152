import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class LocalStorageUtil {
  private static storageChanges$ = fromEvent(window, 'storage').pipe(startWith(true));
  private static changes$ = new Subject();

  static typeObject = 'object';

  static changes(): Observable<any> {
    return combineLatest([
      LocalStorageUtil.changes$.asObservable(),
      LocalStorageUtil.storageChanges$,
    ]).pipe(map(() => true));
  }

  static setItem(key: string, value: any): void {
    if (typeof value === LocalStorageUtil.typeObject) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
    LocalStorageUtil.changes$.next(true);
  }

  static getItem(key: string): any {
    const value = localStorage.getItem(key);
    let parsedValue = value;
    try {
      parsedValue = JSON.parse(value);
    } catch (err) {
      parsedValue = value;
    }
    if (typeof parsedValue === LocalStorageUtil.typeObject) {
      return parsedValue;
    }
    return value;
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
    LocalStorageUtil.changes$.next(true);
  }
}
