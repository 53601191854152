<mat-tab-group class="tab-group" dynamicHeight [ngClass]="[
        !stretchTabs ? 'tab-group--' + alignTabs || 'center' : '',
        !stretchTabs ? 'tab-group--limited-tab' : '',
        'tab-group--' + theme,
      ]" [mat-stretch-tabs]="stretchTabs" [selectedIndex]="selectedIndex" (selectedTabChange)="tabChanged($event)"
  [dir]="layoutService.websiteDirection$.value" [backgroundColor]="'white'" animationDuration="0ms">
  <mat-tab *ngFor="let tab of tabs; let i = index;" [disabled]="tab.disabled" [labelClass]="['text-' + tab.type]"
    [aria-label]="tab.type">
    <ng-template mat-tab-label class="template">
      <div [matTooltip]="tab.tooltip">
        <ng-container *ngIf="theme === 'trapezoid'">
          <ng-container *ngTemplateOutlet="trapezoidTemplate; context: { $implicit: tab }"></ng-container>
        </ng-container>

        <ng-container *ngIf="theme !== 'trapezoid'">
          <ng-container *ngTemplateOutlet="defaultTemplate; context: { $implicit: tab }"></ng-container>
        </ng-container>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<ng-content></ng-content>

<ng-template #defaultTemplate let-tab>
  <span *ngIf="tab.icon" class="mr-2">
    <span *ngIf="tab.icon && tab.iconType === 'img'">
      <img [src]="tab.icon" class="d-inline-block" />
    </span>
    <span *ngIf="tab.icon && tab.iconType === 'icon'" class="align-middle">
      <app-icon [name]="tab.icon" type="MaterialDesign" class="tab-group__icon"></app-icon>
    </span>
  </span>
  <span class="tab-group__label">{{ "Tab." + tab.label | translate }}</span>
  <div *ngIf="tab.showCount" class="tab-group__count d-inline-block">{{ tab.count }}</div>
</ng-template>

<ng-template #trapezoidTemplate let-tab>
  <div class="tab-content">
    <div class="tab-content__text">
      <ng-container *ngTemplateOutlet="defaultTemplate; context: { $implicit: tab }"></ng-container>
    </div>
    <div class="tab-content__shape">
      <app-icon class="tab-content__shape-bg" *ngIf="theme === 'trapezoid'" name="trapezoid-edge"></app-icon>
    </div>
  </div>
</ng-template>