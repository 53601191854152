import { DeliveryNoteStatus } from "@features/delivery-notes/enums/delivery-note-status.enum";
import { UserType } from "@features/users/enums/user-type.enum";
import { BlocEvent } from '@core/bloc/bloc-event';

export abstract class DeliveryNotesManagementEvent extends BlocEvent {}
export class GetAllCustomersEvent extends DeliveryNotesManagementEvent {
  constructor() {
    super();
  }
}
export class GetAllUsersEvent extends DeliveryNotesManagementEvent {
  constructor(public userType: UserType = UserType.ADMIN, public id?: number) {
    super();
  }
}

export class ExportDeliveryNotesEvent extends DeliveryNotesManagementEvent {
  constructor(
    public customerId?: number,
    public offset?: number,
    public limit?: number,
    public searchTerm?: string,
    public startFrom?: string,
    public endAt?: string,
    public deliveryNoteStatus?: DeliveryNoteStatus,
    public isForExporting?: boolean
  ) {
    super();
  }
}
