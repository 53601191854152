import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IconType } from "@shared/enums/icon-type.enum";
import { LabeledInfoItem } from '@shared/model/labeled-info-item.model';

@Component({
  selector: 'app-labeled-info-item',
  templateUrl: './labeled-info-item.component.html',
  styleUrls: ['./labeled-info-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledInfoItemComponent implements OnChanges {
  @Input() type: 'lg' | 'md' | 'xs' = 'md';
  @Input() direction: 'column-reverse' | 'column' = 'column';
  @Input() item: LabeledInfoItem;

  IconType = IconType;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnChanges({ item }: SimpleChanges): void {
    if (item && item.currentValue !== item.previousValue) {
      this.changeRef.markForCheck();
    }
  }
}
