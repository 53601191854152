import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsCardComponent {
  @Input() hidePrice: boolean;
  @Input() state: {
    iconUrl: string;
    theme: string;
    label: string;
    value: number;
    suffix: number;
    additionalValue: number;
  };
}
