import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationItem } from '@shared-features/models/notification-item';
import { LayoutService } from '@shared/services/layout.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public notifications: NotificationItem[] = [];

  private destroy$ = new Subject();

  constructor(
    public layoutService: LayoutService,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationService.notification$.pipe(takeUntil(this.destroy$)).subscribe(notification => {
      this.addNotification(notification);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  // UI Events
  close(notification: NotificationItem) {
    this.notifications = this.notifications.filter(findingNotification => findingNotification.id !== notification.id);
  }

  onNotificationClicked(notification: NotificationItem) {
    this.notificationService.navigateToNotificationTarget(notification);
    this.close(notification);
  }

  private addNotification(notification: NotificationItem) {
    if (this.notifications.find(findingNotification => findingNotification.id === notification.id)) { return; }
    this.notifications.push(notification);

    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }
}
