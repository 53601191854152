import { AfterViewInit, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInputComponent } from '@shared/components/forms/controls/file-input/file-input.component';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UploaderComponent,
    },
  ],
})
export class UploaderComponent
  extends FileInputComponent
  implements ControlValueAccessor, AfterViewInit
{
  /////////////////////////
  // in case of avatar only
  @Input() type: 'input' | 'avatar' = 'input';
  @Input() avatarSize = 72;
  @Input() labelFont: 'small' | 'medium' | 'large';
  @Input() previewListItemClass = 'col-12'; // 'col-sm-6 col-md-4 col-xl-3';
  @Input() fileDisplayName: string;
  @Input() displayFileInfo = true;
  isImage = false;
  previewImageUrl = null;
  /////////////////////////

  writeValue(data: File | File[]): void {
    super.writeValue(data);
    if (this.type === 'avatar') {
      this.setPreview(this.value);
    }
  }

  onInputValueChange(event): void {
    super.onInputValueChange(event);
    if (this.type === 'avatar') {
      this.setPreview(this.value);
    }
  }

  private setPreview(value: File | File[]): void {
    const file = Array.isArray(value) ? value[0] : value;
    if (file && file.size) {
      const reader = new FileReader();

      reader.onload = () => {
        this.isImage = file.type.toLowerCase().includes('image');
        this.previewImageUrl = reader.result;
      };

      reader.readAsDataURL(file);
    } else if (file && (file as any).media) {
      this.isImage = file.type.toLowerCase().includes('image');
      this.previewImageUrl = (file as any)?.media.url;
    }
  }
}
