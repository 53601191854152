export enum InvoiceStatus {
  TotalPaid = 'TotalPaid',
  TotalUnpaid = 'TotalUnpaid',
  TotalDue = 'TotalDue',
  NoPaidInvoices = 'NoPaidInvoices',
  NoUnPaidInvoices = 'NoUnPaidInvoices',
  NoDueInvoices = 'NoDueInvoices',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  REJECTED = 'REJECTED',
  DRAFTED = 'DRAFTED',
  IN_PROGRESS = 'IN_PROGRESS',
  OverDue = 'OVER_DUE'
}
