import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NamedField } from '@shared/interfaces/field.interface';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { FieldBase } from './field-base';
import { IconType } from '@shared/enums/icon-type.enum';
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-text-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-label class="field__label" *ngIf="field.label"
        >{{ field.label | translate }} <span *ngIf="isRequired" class="text-warn">*</span>
      </mat-label>
      <mat-form-field class="field__group d-block w-100" appearance="outline">
        <div *ngIf="field.prefix" matPrefix (click)="prefixClick.emit($event)">
          <ng-container #prefixRef></ng-container>
        </div>
        <input
          matInput
          class="field__input"
          #element
          [id]="field.name + '_' + uuid"
          [formControl]="group.get(field.name)"
          type="text"
          [placeholder]="field.placeholder | translate"
          (click)="fieldClick.emit($event)"
        />
        <div *ngIf="field.suffix" matSuffix (click)="suffixClick.emit($event)">
          <ng-container #suffixRef></ng-container>
        </div>
        <button
          mat-icon-button
          *ngIf="field.inputs?.type === 'search'"
          matSuffix
          class="btn--small text-400"
          (click)="
            suffixClick.emit($event) ||
              (!!group?.value[field.name] && group.get(field.name).setValue(''))
          "
        >
          <app-icon
            class="d-inline-flex"
            [type]="group?.value[field.name] ? IconType.MaterialDesign : IconType.SVG"
            [name]="group?.value[field.name] ? 'clear' : 'search'"
            size="sm"
          ></app-icon>
        </button>
        <mat-error *ngIf="errors.length" class="field__error">
          <app-field-errors [errors]="errors"></app-field-errors>
        </mat-error>
      </mat-form-field>
    </ng-container>
  `,
  host: { class: 'field' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextFieldComponent extends FieldBase {
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;
  @ViewChild('element') element: ElementRef;
  protected readonly IconType = IconType;
}
