<ng-container>
  <section class="filter-layout">
    <ng-container *ngIf="settings?.filterLayoutType == shipmentFilterLayout.Basic ; else headerCreateFilter">
      <ng-container *ngTemplateOutlet=headerBasicFilter></ng-container>
    </ng-container>


    <content class="filter-layout__body">
        <ng-template #dynamicFilterTemplate></ng-template>
    </content>

    <ng-container *ngIf="settings?.filterLayoutType == shipmentFilterLayout.Basic ; else ButtonsCreateFilter">
      <ng-container *ngTemplateOutlet=buttonsBasicFilter></ng-container>
    </ng-container>

  </section>
</ng-container>


<ng-template #headerBasicFilter>
  <header class="filter-layout__header">
    <app-row class="align-center">
      <app-col>
        <h4 class="filter-layout__title">{{ (settings?.title || "Filter.Filters") | translate }}</h4>
      </app-col>
      <app-col cols="3">
        <app-button
          (click)="emitAction(filterAction.Reset)"
          [theme]="settings?.buttons.reset.theme"
          color="settings?.buttons.reset.color"
          class="d-block w-100">
          {{ "Buttons."+settings?.buttons.reset.label | translate }}
        </app-button>
      </app-col>
    </app-row>
  </header>
</ng-template>

<ng-template #headerCreateFilter>
  <header class="filter-layout__header">
    <app-row class="align-center">
      <app-col>
        <h5 class="filter-layout__title">{{ (settings?.title || "Filter.CreateCustomFilter") | translate }}</h5>
      </app-col>
      <app-col cols="6">
        <app-button
            [theme]="settings?.buttons.reset.theme"
            color="settings?.buttons.reset.color"
            (click)="emitAction(filterAction.Cancel)"
            class="d-block w-100"
            >
            {{ "Buttons."+settings?.buttons.reset.label | translate }}
        </app-button>
      </app-col>
    </app-row>
  </header>
</ng-template>


<ng-template #buttonsBasicFilter>
  <div class="filter-layout__actions">
    <div class="filter-layout__action">
      <app-button
          [color]="settings?.buttons.submit.color"
          [theme]="settings?.buttons.submit.theme"
          (click)="emitAction(filterAction.Submit)"
          class="d-block w-100"
          >
          {{ "Buttons."+settings?.buttons.submit.label | translate }}
      </app-button>
    </div>

    <div class="filter-layout__action">
      <app-button
            [theme]="settings?.buttons.cancel.theme"
            color="settings?.buttons.cancel.color"
            (click)="emitAction(filterAction.Cancel)"
            class="d-block w-100"
            >
            {{ "Buttons."+settings?.buttons.cancel.label| translate }}
          </app-button>
    </div>

  </div>
</ng-template>

<ng-template #ButtonsCreateFilter>
  <div class="filter-layout__actions">
    <div class="filter-layout__action">
      <app-button
            [theme]="settings?.buttons.submit.theme"
            [color]="settings?.buttons.submit.color"
            (click)="emitAction(filterAction.Submit)"
            class="d-block w-100"
            >
            {{ "Buttons."+settings?.buttons.submit.label | translate }}
      </app-button>
    </div>
  </div>
</ng-template>
