<app-dialog-layout class="dialog-layout madar--v2">
  <ng-container dialog-title>{{ 'shared.TransactionHistory.changingLog' | translate }}</ng-container>
  <section class="mt-4">
    <app-state-section [state]="sectionState">
      <mat-card>
        <mat-card-content>
          <span>{{ 'shared.TransactionHistory.changingLogDetails' | translate }}</span>

          <ul class="timeline" *ngIf="transactionHistory?.length">
            <li class="timeline__item timeline--avatar-bullet" *ngFor="let item of transactionHistory; let isFirst = first">
              <div class="timeline__bullet">
                <div class="timeline__list-item-title">
                  <app-avatar
                    size="32"
                    class="timeline__avatar"
                    bgColor="var(--color-gray-200)"
                    fgColor="var(--color-gray-900)"
                    [name]="item.userName"
                  ></app-avatar>
                </div>
              </div>
              <div class="timeline__item-content">
                <div class="row align-center gx-sm">
                  <div class="flex align-center justify-space-between">
                    <div class="flex align-center">
                      <div class="ml-5">
                        <span class="weight-medium text-900 h6">{{item.userName}}</span>
                        <app-rect-tag color="primary" inverted="true" class="ml-1" *ngIf="isFirst">current</app-rect-tag>
                      </div>
                    </div>

                    <span class="text-400">{{item.updatedAt | date}} | {{item.updatedAt | date:'shortTime' }}</span>
                  </div>

                  <div class="flex column timeline__description pl-6 ml-1">
                    <div *ngIf="item.oldCost || item.newCost">
                      <span>{{ 'shared.TransactionHistory.cost' | translate }} :</span>
                      <span class="text-400 text-decoration-line-through">{{item.oldCost ||0}}</span>
                      <span>&#8594;</span>
                      <span class="text-accent">{{item.newCost || 0}}</span>
                      <span *ngIf="item.isContractedCost">[{{'shared.TransactionHistory.PriceContracted' | translate}}]</span>
                      <span *ngIf="!item.isContractedCost">[{{'shared.TransactionHistory.PriceManual' | translate}}]</span>
                    </div>
                    <div *ngIf="item.oldPrice || item.newPrice">
                      <span>{{ 'shared.TransactionHistory.price' | translate }} :</span>
                      <span class="text-400 text-decoration-line-through">{{item.oldPrice || 0}}</span>
                      <span>&#8594;</span>
                      <span class="text-accent">{{item.newPrice || 0}}</span>
                      <span *ngIf="item.isContractedPrice">[{{'shared.TransactionHistory.PriceContracted' | translate}}]</span>
                      <span *ngIf="!item.isContractedPrice">[{{'shared.TransactionHistory.PriceManual' | translate}}]</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </mat-card-content>
      </mat-card>
    </app-state-section>
  </section>
</app-dialog-layout>
