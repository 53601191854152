import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class TransactionHistory extends Model {
  @Expose() updatedAt: Date;
  @Expose() userName: string;
  @Expose() oldCost?: number;
  @Expose() newCost?: number;
  @Expose() oldPrice?: number;
  @Expose() newPrice?: number;
  @Expose() isContractedCost?: false;
  @Expose() isContractedPrice?: false;
}
