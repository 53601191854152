import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FieldBase } from '@shared/components/forms';
import { Field } from '@shared/interfaces';

@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteButtonComponent extends FieldBase {
  @Input() field: Field & { name: string };
  @Input() group: FormGroup | FormArray;
  @ViewChild('template') element: ElementRef<HTMLElement>;

  constructor() {
    super();
  }
}
