import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConstant } from '@shared/constants/api.constant';
import { HttpParams } from '@angular/common/http';
import { City } from '@shared-features/models/city';

@Injectable({
  providedIn: 'root',
})
export class CityApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getActiveCities(): Observable<City[]> {
    return this.baseAPI.get(ApiConstant.GET_ACTIVE_CITIES).pipe(
      map((res) => {
        return this.mapper.fromJson(City, res.data);
      })
    );
  }

  getAllCities(countryId?: number): Observable<City[]> {
    let params = new HttpParams();
    if(countryId)
    params = params.append('countryId', countryId.toString());
    return this.baseAPI.get(ApiConstant.GET_ALL_CITIES, {
      params: params

    }).pipe(
      map((res) => {
        return this.mapper.fromJson(City, res.data);
      })
    );
  }
}
