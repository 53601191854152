import { Expose, Type } from 'class-transformer';
import { AvlShipmentStop } from './avl-shipment-stop.model';
import { Model } from '../../shared-features/models/model';
import { AvlOverSpeed } from '@features/live-tracking/models/avl-over-speed.model';

export class AvlShipmentStops extends Model {
  @Expose() stopDurations: string;
  @Expose() stopsCount: number;

  @Type(() => AvlShipmentStop)
  @Expose() stops: AvlShipmentStop[];

  @Expose() overSpeedCount: number;

  @Type(() => AvlOverSpeed)
  @Expose() overSpeed: AvlOverSpeed[];
}
