import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SecurityContext,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { LayoutService } from '@shared/services/layout.service';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { PopoverDirective } from '@shared/directives/popover-directive';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.scss'],
})
export class NavListComponent implements OnChanges {
  @Input() color: 'primary' | 'basic' = 'basic';
  @Input() menuList: NavBarItem[];
  @Input() collapsed: boolean = false;
  @Input() divider: boolean = false;
  @Output() action = new EventEmitter<any>();
  @ViewChildren('popoverInstance') popovers: QueryList<PopoverDirective>;

  position: ConnectedPosition = {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'top',
  };

  protected htmlItemsMap: Record<string, string> = {};

  constructor(public layoutService: LayoutService, private sanitizer: DomSanitizer) {}

  ngOnChanges({ menuList }: SimpleChanges): void {
    if (menuList?.currentValue) {
      this.htmlItemsMap = {};
      this.menuList.forEach((item) => {
        const isHtml = /<[a-z][\s\S]*>/i.test(item.title);
        if (!isHtml) return;
        this.htmlItemsMap[item.title] = this.sanitizer.sanitize(SecurityContext.HTML, item.title);
      });
    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  handleOpenPopover(popover: PopoverDirective): void {
    this.popovers.forEach((element) => {
      if (element.overlayRef.hasAttached() && element !== popover) {
        element.close();
      }
    });
  }
}
