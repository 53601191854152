import { Injectable } from '@angular/core';
import { UserDevice } from '@features/users/models/user-device';
import * as uuid from 'uuid';
import { Mapper } from './mapper';
import { ConfigConstant } from '@shared/constants/config.constant';
import { StorageConstant } from '@shared/constants/storage.constant';


@Injectable({
  providedIn: 'root',
})
export class UserDeviceService {
  constructor(private mapper: Mapper) {}

  saveUserDevice(userDevice: UserDevice): void {
    if (userDevice) {
      localStorage.setItem(StorageConstant.USER_DEVICE_KEY, userDevice.toJson());
    }
  }

  // TODO: implement a new way to generate device id to be more reliable
  getCurrentUserDevice(): UserDevice {
    const json = localStorage.getItem(StorageConstant.USER_DEVICE_KEY);
    return json ? this.mapper.fromJson(UserDevice, JSON.parse(json)) : null;
  }

  generateNewDevice(): UserDevice {
    const userDevice = new UserDevice();
    userDevice.deviceId = uuid.v4();
    userDevice.appVersion = ConfigConstant.VERSION;
    userDevice.osVersion = navigator.platform;
    userDevice.deviceModel = navigator.appVersion;
    userDevice.languageIsoCode = 'en';

    return userDevice;
  }
}
