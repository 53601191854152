<app-dialog-layout class="madar--v2" >
  <div dialog-title class="d-block w-100">
    <span>{{'shared.announcementDialog.announcement' | translate}}</span>
  </div>

  <app-state-section [state]="sectionState">
    <mat-card class="mb-2">
      <mat-card-content class="px-6" [formGroup]="form" *ngIf="showDialog">
        <span class="text-500 h6">{{'shared.announcementDialog.announcementText' | translate}}</span>
        <div class="flex column form-control-full-width text-area mt-2">
          <mat-label>{{ "shared.announcementDialog.text" | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              rows="4"
              cols="40"
              type="text"
              formControlName="message"
            ></textarea>
            <mat-error *ngIf="form?.controls?.message?.touched && form.controls?.message?.invalid && !form?.controls?.message?.hasError('maxlength')">
              {{ "ErrorMessages.Required" | translate }}
            </mat-error>
            <mat-error *ngIf="form?.controls?.message?.hasError('maxlength') && form.controls?.message?.invalid">
              {{ "ErrorMessages.InValidMaxLength100" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex column form-control-full-width text-area ">
          <mat-label>{{ "shared.announcementDialog.linkName" | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input  matInput type="text"  formControlName="urlName">
            <mat-error *ngIf="form?.controls?.urlName?.touched && form.controls?.urlName?.invalid ">
              {{ "ErrorMessages.Required" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex column form-control-full-width text-area ">
          <mat-label>{{ "shared.announcementDialog.link" | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <textarea
              matInput
              rows="4"
              cols="40"
              type="text"
              formControlName="url"
            ></textarea>
          </mat-form-field>
        </div>

        <span class="text-500 h6">{{'shared.announcementDialog.toBeShownFor' | translate}}</span>

        <app-row>
          <app-col>
            <mat-slide-toggle  color="primary" formControlName="isForAdmin">{{'shared.announcementDialog.admin' | translate}}</mat-slide-toggle>
          </app-col>

          <app-col>
            <mat-slide-toggle  color="primary" formControlName="isForCustomer">{{'shared.announcementDialog.customer' | translate}}</mat-slide-toggle>
          </app-col>
        </app-row>
      </mat-card-content>
    </mat-card>
  </app-state-section>

  <div dialog-action>
    <app-row justify="end">
      <app-col cols="6">
        <button mat-stroked-button color="primary" class="w-100 d-block" mat-dialog-close>
          {{'shared.Buttons.Cancel'|translate}}
        </button>
      </app-col>
      <app-col cols="6">
        <button mat-flat-button color="primary" class="w-100 d-block" (click)="setAnnouncement()" [disabled]="form?.invalid">
          {{'shared.Buttons.Confirm'|translate}}
        </button>
      </app-col>
    </app-row>
  </div>
</app-dialog-layout>
