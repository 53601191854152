import { Expose, Type } from 'class-transformer';
import { ShipmentRequestLocationType } from "@features/shipments/enums/shipment-request-location-type.enum";
import { Model } from '../../shared-features/models/model';
import { LocationModel } from '../../live-tracking/models/location.model';
import { Trailer } from '@features/fleets/models/trailer.model';
import { ShipmentRequestLabel } from './shipment-request-label.model';
import { ShipmentRequestStatus } from "@features/shipments/enums/shipment-request-status.enum";
import { User } from '../../users/models/user';

export class ShipmentRequest extends Model {
  @Expose() id?: number;
  @Expose() status?: ShipmentRequestStatus;

  @Type(() => Trailer)
  @Expose()
  trailer?: Trailer;

  @Expose() notes?: string;

  @Type(() => LocationModel)
  @Expose()
  fromLocation: LocationModel;

  @Type(() => LocationModel)
  @Expose()
  toLocation: LocationModel;

  @Expose() fromType: ShipmentRequestLocationType;
  @Expose() toType: ShipmentRequestLocationType;
  @Expose() label?: ShipmentRequestLabel[] = [];

  @Type(() => User)
  @Expose()
  dropOffUser?: User;

  @Expose() arrivedPickUpLocation?: number;
  @Expose() leavePickUpLocation?: number;
  @Expose() arrivedDropLocation?: number;
  @Expose() leaveDropLocation?: number;

  constructor() {
    super();
  }
}
