import { ExportDeliveryNote } from '@features/invoices/models/export-delivery-note';
import { BlocState } from '@core/bloc/bloc-state';
import { User } from '@features/users/models/user';
import { Customer } from '@features/customers/models/customer';
import { DeliveryNoteDetails } from '@features/invoices/models/delivery-note-details';

export abstract class DeliveryNotesManagementState extends BlocState {}

export class DeliveryNotesManagementReadyState extends DeliveryNotesManagementState {
  constructor(public deliveryNoteDetails: DeliveryNoteDetails) {
    super();
  }
}
export class GetAllCustomersReadyState extends DeliveryNotesManagementState {
  constructor(public customers: Customer[]) {
    super();
  }
}

export class GetAllCreatorsReadyState extends DeliveryNotesManagementState {
  constructor(public creators: User[]) {
    super();
  }
}
export class ExportDeliveryNotesReadyState extends DeliveryNotesManagementState {
  constructor(public data: ExportDeliveryNote) {
    super();
  }
}
