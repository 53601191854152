import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class AssignmentLogs extends Model {
  @Expose() id: number;
  @Expose() fromFleetId: number;
  @Expose() toFleetId: number;
  @Expose() fromTruckId: number;
  @Expose() toTruckId: number;
  @Expose() fromDriverId: number;
  @Expose() toDriverId: number;
  @Expose() fromFleetName: string;
  @Expose() toFleetName: string;
  @Expose() fromTruckPlateNumber: string;
  @Expose() toTruckPlateNumber: string;
  @Expose() fromDriverName: string;
  @Expose() toDriverName: string;
  @Expose() assignedByName: string;
  @Expose() createdAt: Date;
  @Expose() assignedById: number;
}
