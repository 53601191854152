import { Expose, Transform } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { OrderLineStatus } from '@features/orders/enums/order-line-status.enum';
import { Pallet } from './pallet.model';
import { OrderType } from '../enums/order-type.enum';
import { DefinitionOrderTemperatureControl } from '../enums/definition-order-temperature-control.enum';

export class Order extends Model {
  @Expose() SNo: number;
  @Expose() warehouse: string;
  @Expose() client: string;
  @Expose() orderNumber: string;
  @Expose() genericItem: string;
  @Expose() genericItemDescription: string;
  @Expose() quantity: number;
  @Expose() batch: string;
  @Expose() expireDate: Date;
  @Expose() palletNumber: string;
  @Expose() storageCondition: string;
  @Expose() custody: string;
  @Expose() shipToCustomer: string;
  @Expose() rush?: boolean;
  @Expose() destinationLocation: string;
  @Expose() stagedDate: Date;
  @Expose() dockLocation: string;
  @Expose() shipment: string;
  @Expose() id?: number;
  @Expose() reference?: string;
  @Expose() dropOffCustomerTitle?: string;
  @Expose() dropOffCustomerReference?: string;
  @Expose() dropOffLocationTitle?: string;
  @Expose() dropOffLocationReference?: string;
  @Expose() dropOffGateName?: string;
  @Expose() dropOffGateReference?: string;
  @Expose() dispatchedDate?: string;
  @Expose() createdAt?: Date;
  @Expose() noOfPallets?: number;
  @Expose() actualNoOfPallets?: number;
  @Expose() noOfItems?: number;
  @Expose() actualNoOfItems?: number;
  @Expose() orderLineStatus?: OrderLineStatus;
  @Expose() dropOffLocationId?: number;
  @Expose() pallets?: Pallet[]; // that pallets here isn't a part of API response it's only used to handle the UI work. also shift it to the bottom of the class
  @Expose() shipmentId?: number;
  @Expose() orderType?: OrderType;
  @Expose() temperatureControl?: DefinitionOrderTemperatureControl;
  @Expose() deliveryNoteUrl?: string;
  @Expose() isPalletDetails?: boolean;
  @Expose() customerTitle?: string;
  @Expose() remainingQuantity?: number;

  @Transform(({ value }) => +value)
  @Expose()
  latitude?: number;
  @Transform(({ value }) => +value)
  @Expose()
  longitude?: number;

  @Expose() sealNumber?: string;
  @Expose()
  get latlng() {
    return {
      lat: +this.latitude,
      lng: +this.longitude,
    };
  }

  @Expose() get uiId() {
    const { lat, lng } = this.latlng;
    return `${lat}-${lng}`;
  }
}
