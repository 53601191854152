<div class="filter-controls-wrapper flex">
  <div class="row justify-space-between">
    <app-col cols="12" lg="6" xl="6" md="6" class="ml-4 mb-1">
      <app-search-input
        *ngIf="includeSearchControl"
        placeholder=" {{ searchPlaceholder ? searchPlaceholder : 'Placeholder.DottedSearch' | translate }} "
        [showSearchActions]="showSearchActions"
        class="search flex"
        (searchValue)="onSearch($event)"
        [multiple]="isMultiple"
      ></app-search-input>
    </app-col>

    <div class="col-auto row">
      <ng-container *ngIf="includePickupDateControl">
        <div class="col-auto">
          <ng-container *ngTemplateOutlet="dateFilterType"></ng-container>
        </div>
      </ng-container>
      <div class="col-auto">
        <div class="row gx-sm">
          <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
        </div>
      </div>
    </div>

    <div class="col-auto">
      <div class="row">
        <div class="col-auto" *ngIf="includeColumnOptions">
          <button mat-flat-button class="btn-secondary" (click)="toggleColumnOptionsMenu()">
            <img src="assets/images/col-options.svg" class="btn-icon" />
            {{ "Buttons.ColumnOptions" | translate}}
          </button>
        </div>

        <div class="col-auto">
          <div [ngClass]="{'row': !breakControlsIntoMultipleLines}">

            <div *ngIf="includeShipmentFilter && !isB2c">
              <ng-container *ngTemplateOutlet="filterButton"></ng-container>
            </div>

            <app-filter-button
              [ngClass]="{'col-auto': !breakControlsIntoMultipleLines}"
              [filterNavigationConfig]="filterNavigationConfig"
              *ngIf="includeFilterButton && !includeShipmentFilter && !isB2c"
            ></app-filter-button>

            <ng-container *ngIf="!breakControlsIntoMultipleLines">
              <div [ngClass]="{'col-auto': !breakControlsIntoMultipleLines}">
                <ng-container *ngTemplateOutlet="sortByFilter"></ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-3 justify-space-between" *ngIf="breakControlsIntoMultipleLines" style="gap: 10px">
    <div class="col-auto toggle-btn">
      <mat-button-toggle-group
        [formControl]="customerTypeControl"
        *ngIf="includeShipmentSpecificFleetFilter"
        (change)="onCustomerTypeChanges($event)"
      >
        <mat-button-toggle [value]="null">{{ "Shipments.All" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="CustomerType.Service">{{ "Shipments.Ops" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="CustomerType.Saas">{{ "Shipments.Saas" | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="col-auto toggle-btn">
      <mat-button-toggle-group
        [formControl]="shipmentRequestsMultiplicationTypeControl"
        *ngIf="includeShipmentRequestsMultiplicationFilter"
        (change)="onShipmentRequestsMultiplicationTypeChanges($event)"
      >
        <mat-button-toggle [value]="null">{{ "Shipments.All" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="ShipmentRequestsMultiplicationType.Single">{{ "Request.Single" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="ShipmentRequestsMultiplicationType.Multiple">{{ "Request.Multiple" | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="col-auto toggle-btn">
      <mat-button-toggle-group [formControl]="trackingTypeControl" *ngIf="includeTrackingTypeFilter" (change)="ontrackingTypeChanges($event)">
        <mat-button-toggle [value]="TrackingType.All">{{ "Shipments.All" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="TrackingType.AVL">{{ "Shipments.AVL" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="TrackingType.Mobile">{{ "Shipments.Mobile" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="TrackingType.Empty">{{ "Shipments.NoTracking" | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <app-col cols="auto" *ngIf="includeBayanTypeFilter" class="toggle-btn">
      <mat-button-toggle-group [formControl]="bayanTypeFilterControl" (change)="ontBayanTypeChanges($event)">
        <mat-button-toggle [value]="null">{{ "Shipments.All" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="true">{{ "Shipments.BayanVerified" | translate}}</mat-button-toggle>
        <mat-button-toggle [value]="false">{{ "Shipments.BayanNotVerified" | translate}}</mat-button-toggle>
      </mat-button-toggle-group>
    </app-col>

    <div class="col-auto">
      <ng-container *ngTemplateOutlet="sortByFilter"></ng-container>
    </div>
    <app-col cols="auto" class="col-auto ml-auto mt-2" *ngIf="includeLastRefreshTimeShipmentList">
      <mat-slide-toggle color="primary" [ngModel]="autoCache" (ngModelChange)="autoCacheChange.emit($event)">
        {{'shared.AutoCache' | translate}}
      </mat-slide-toggle>
    </app-col>
    <app-col cols="auto" class="col-auto mt-2" *ngIf="includeLastRefreshTimeShipmentList">
      <mat-slide-toggle color="primary" (change)="autoUpdateChanges($event)">{{'shared.AutoUpdate' | translate}}</mat-slide-toggle>
    </app-col>

    <div class="col-auto refresh" *ngIf="includeLastRefreshTime">
      <span>{{ "Shipments.LastRefreshTime" | translate}}:</span>
      <span class="primary-color">{{ lastRefreshTime | date: 'dd-MMM hh:mm a' }}</span>
    </div>
  </div>
</div>

<ng-template #dateFilter>
  <ng-container *ngIf="includeDateControl">
    <div class="date-dropdown col-auto">
      <mat-form-field class="dropdown" appearance="outline">
        <p matPrefix class="prefix" *ngIf="!includePickupDateControl">{{ "Filter.Date" | translate }}</p>

        <mat-select (selectionChange)="OnDateSelectionChange($event)" [value]="dateRangeValue">
          <mat-option
            *ngFor="let item of dateRangeType | enumToArray"
            [value]="item.value"
            [ngClass]="{'hide-option': item.key === dateRangeType.CUSTOM && !showCustomDateOption}"
          >
            <ng-container *ngIf="item.key !== dateRangeType.CUSTOM || ( item.key === dateRangeType.CUSTOM && showCustomDateOption)">
              {{ "Enum.DateRangeType." + item.key | translate }}
            </ng-container>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <form class="date-range-container flex justify-center column p-0" [formGroup]="form" *ngIf="dateRangeValue === dateRangeType.CUSTOM">
      <mat-form-field appearance="outline">
        <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
          <input readonly formControlName="from" matInput matStartDate [placeholder]="'Placeholder.StartDate' | translate" />
          <input
            readonly
            formControlName="to"
            (dateChange)="customDateChanged()"
            matInput
            matEndDate
            [placeholder]="'Placeholder.EndDate' | translate"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </form>

    <div class="col-auto" *ngIf="dateRangeValue !== dateRangeType.CUSTOM">
      <small class="py-3 d-inline-block text-primary">{{previewSelectedDate}}</small>
    </div>
  </ng-container>
</ng-template>

<ng-template #dateFilterType>
  <div class="date-dropdown" *ngIf="includePickupDateControl">
    <mat-form-field class="dropdown" appearance="outline">
      <p matPrefix class="prefix">
        <ng-container *ngIf="!includePickupDateControl; else dateField">{{ "Filter.Date" | translate }}</ng-container>
        <ng-template #dateField>{{ "Filter.DateType" | translate }}</ng-template>
      </p>
      <mat-select (selectionChange)="selectedShipmentDateFilterTypeChanged.emit($event.value)" [value]="shipmentDateFilterTypeValue">
        <mat-option *ngFor="let item of ShipmentDateFilterType | enumToArray" [value]="item.value">
          {{ "Enum.ShipmentDateFilterType." + item.key | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #sortByFilter>
  <div class="sort-by col-auto" *ngIf="includeSortByControl">
    <mat-form-field appearance="outline" class="flex row">
      <p matPrefix class="sort-by-prefix">{{ "Table.SortByPrefix" | translate }}</p>
      <mat-select (selectionChange)="onSelectSortBy($event.value)" [value]="sortOptions.MostRecent">
        <mat-option *ngFor="let item of sortOptions | enumToArray" [value]="item.value">{{ "Enum.SortOptions." + item.key | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>


<ng-template #filterButton>
  <app-filter-button
    [popoverTrigger]="popover"
    #popoverTrigger="popoverTrigger"
    size="sm"
    [closeOnClickOutside]="true"
    [position]="position"
    >
  </app-filter-button>
  <ng-template #popover>
    <div class="popover-container">
      <app-shipment-list-filter
        (closeFilter)="popoverTrigger.detachOverlay()"
      >
      </app-shipment-list-filter>
    </div>
  </ng-template>
</ng-template>
