import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  ClassifiedField,
  Field,
  FieldArray,
  FieldGrid,
  FieldGroup,
  NamedField,
} from '@shared/interfaces/field.interface';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { FieldControlType } from '@shared/enums/field-control-type.enum';
import { FieldType } from '@shared/enums/field-type.enum';

export declare type Alignment = 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormComponent implements OnChanges, OnInit, OnDestroy {
  private readonly fb = inject(FormBuilder);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly controlContainer = inject(ControlContainer);
  private destroy$ = new Subject();

  @Input() fields: FieldGroup | FieldArray;
  @Input() value: any;
  @Input() isChildForm;
  @Input() id = Math.random().toString(36).substr(2, 9) + Date.now();
  @Input() align: Alignment = 'center';

  @Output() valueChange = new EventEmitter();
  @Output() prefixClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() suffixClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() fieldClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() change = new EventEmitter<{ field: string; value: any }>();
  @Output() render = new EventEmitter<{ form: FormGroup | FormArray; firstChange: boolean }>();
  @Output() created = new EventEmitter<FormGroup | FormArray>();

  fields$ = new BehaviorSubject<FieldGroup | FieldArray>([]);
  value$ = new BehaviorSubject<any>(null);
  fieldsGroupedByRow$ = new BehaviorSubject<ClassifiedField[][]>([]);

  form: FormGroup | FormArray;
  isFormArray = false;
  firstChange = true;
  protected readonly FieldType = FieldType;
  protected rowsExtraProps: Record<number, Pick<FieldGrid, 'align' & 'justify'>> = {};
  protected nestedFormsInitialized: Record<string, Subject<any>> = {};
  private previousFields: FieldGroup | FieldArray;
  private renderResolver: () => void;
  private subscribedToFormChanges = false;
  private innerValueChange = false;
  ngOnChanges({ fields, value }: SimpleChanges): void {
    if (fields?.currentValue !== fields?.previousValue) {
      this.previousFields = fields.previousValue;
      this.fields$.next(this.fields);
    }

    if (value?.currentValue !== value?.previousValue) {
      this.value$.next(this.value);
    }
  }

  rerender(value?: any): Promise<void> {
    return new Promise((resolve) => {
      this.renderResolver = resolve;
      this.previousFields = this.fields;
      this.fields$.next(this.fields);
      this.value$.next(value ?? this.value);
    });
  }

  ngOnInit(): void {
    const controlsGroup = this.controlContainer.control;
    this.isFormArray = controlsGroup instanceof FormArray;
    this.form = this.isFormArray ? (controlsGroup as FormArray) : (controlsGroup as FormGroup);
    const value$ = this.value$.pipe(
      filter((v) => {
        if (this.innerValueChange) {
          this.innerValueChange = false;
          return false;
        }
        return true;
      })
    );
    this.fields$
      .pipe(
        filter((fields) => !!fields),
        filter((fields) => (this.isFormArray && !!fields.length) || Object.keys(fields).length > 0),
        tap((fields) => this.groupFieldsByRow(fields)),
        switchMap((fields) => this.buildForm(fields)),
        tap(() => !this.isChildForm && !this.subscribedToFormChanges && this.listenFormChanges()),
        switchMap((form) => combineLatest([of(form), value$])),
        debounceTime(0),
        takeUntil(this.destroy$)
      )
      .subscribe(([form, value]) => {
        this.patchValue(form, value);
        this.changeDetectorRef.markForCheck();
        this.render.emit({
          form: this.form,
          firstChange: this.firstChange,
        });
        this.firstChange = false;
        if (this.renderResolver) {
          this.renderResolver();
          this.renderResolver = null;
        }
        this.fieldsAreCreated(form);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private buildForm(fields: FieldGroup | FieldArray): Observable<FormGroup | FormArray> {
    return this.isFormArray
      ? this.buildFromArray(fields as FieldArray)
      : this.buildFromGroup(fields as FieldGroup);
  }

  private buildFromArray(fields: FieldArray): Observable<FormArray> {
    const form = this.form as FormArray;
    const values = form.value.concat([]);
    this.nestedFormsInitialized = {};
    let nestedFormsCount = 0;

    fields.forEach((field, i) => {
      const valueIndex = ((this.previousFields as FieldArray) || []).indexOf(field);
      const hasOldValue = valueIndex > -1;
      const oldValue = values[valueIndex];
      if (form.at(i)) {
        form.removeAt(i, { emitEvent: false });
      }

      if (Array.isArray(field) || field.controlType === FieldControlType.FormArray) {
        const array = this.fb.array([]);
        this.nestedFormsInitialized[i] = new Subject();
        nestedFormsCount++;
        form.push(array, { emitEvent: false });
        return;
      }

      if (!this.isField(field) || field.controlType === FieldControlType.FormGroup) {
        const group = this.fb.group({});
        this.nestedFormsInitialized[i] = new Subject();
        nestedFormsCount++;
        form.push(group, { emitEvent: false });
        return;
      }

      if (this.isField(field)) {
        form.push(this.fb.control(hasOldValue ? oldValue : null, (field as Field).validators), {
          emitEvent: false,
        });
      }
    });

    if (form.controls.length > fields.length) {
      for (let i = form.controls.length; i > fields.length; i--) {
        form.removeAt(i - 1);
      }
    }
    if (!nestedFormsCount) {
      return of(form);
    }
    return combineLatest(Object.values(this.nestedFormsInitialized)).pipe(
      take(1),
      map(() => form)
    );
  }

  private buildFromGroup(fields: FieldGroup): Observable<FormGroup> {
    const form = this.form as FormGroup;
    const value = Object.assign({}, form.value);
    const previousFields: FieldGroup = (this.previousFields as FieldGroup) || {};
    this.nestedFormsInitialized = {};
    let nestedFormsCount = 0;
    Object.keys(fields).forEach((name) => {
      const field = fields[name];
      const hasOldValue = previousFields[name] !== undefined;
      const oldValue = value[name];

      if (form.contains(name)) {
        form.removeControl(name, { emitEvent: false });
      }

      if (Array.isArray(field) || (field as Field).controlType === FieldControlType.FormArray) {
        const array = this.fb.array([]);
        this.nestedFormsInitialized[name] = new Subject();
        nestedFormsCount++;
        form.addControl(name, array, { emitEvent: false });
        return;
      }

      if (!this.isField(field) || (field as Field).controlType === FieldControlType.FormGroup) {
        const group = this.fb.group({});
        this.nestedFormsInitialized[name] = new Subject();
        nestedFormsCount++;
        form.addControl(name, group, { emitEvent: false });
        return;
      }
      if (this.isField(field)) {
        const isSeparator = field.type === FieldType.Separator;
        const isHead = field.type === FieldType.Head;
        if (isSeparator || isHead) {
          return;
        }
        const namedField = fields[name] as NamedField;
        namedField.name = name;
        form.addControl(
          name,
          this.fb.control(hasOldValue ? oldValue : null, namedField.validators),
          {
            emitEvent: false,
          }
        );
      }
    });
    Object.keys(previousFields).forEach((name) => {
      if (!fields[name]) {
        form.removeControl(name, { emitEvent: false });
      }
    });
    if (!nestedFormsCount) {
      return of(form);
    }
    return combineLatest(Object.values(this.nestedFormsInitialized)).pipe(
      take(1),
      map(() => form)
    );
  }

  private listenFormChanges(): void {
    if (this.isChildForm) {
      return;
    }
    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe((value) => {
      this.innerValueChange = true;
      this.value$.next(value);
      // this.valueChange.emit(value);
    });
    this.subscribedToFormChanges = true;
  }

  private patchValue(form: FormGroup | FormArray, value: any): void {
    const newValue = value || (this.isFormArray ? [] : {});
    (form as any).patchValue(newValue, { emitEvent: false });
  }

  private groupFieldsByRow(fields: FieldGroup | FieldArray): void {
    let classifiedFields: ClassifiedField[] = [];
    const grid: { [index: string]: ClassifiedField[] } = {};

    if (Array.isArray(fields)) {
      classifiedFields = fields.map((field, i) => {
        const name = `${i}`;
        return this.getClassifyObject(field, name);
      });
    } else {
      classifiedFields = Object.keys(fields).map((name) => {
        const field = fields[name];
        return this.getClassifyObject(field, name);
      });
    }

    classifiedFields.forEach((field, i) => {
      if (field.classify === 'field' && field.data.grid?.row !== undefined) {
        const { row, colConfig, ...gridRest } = field.data.grid;
        grid[row] = grid[row] || [];
        grid[row].push(field);
        this.rowsExtraProps[row] = gridRest;
      } else {
        grid[i] = grid[i] || [];
        grid[i].push(field);
      }
    });

    this.fieldsGroupedByRow$.next(Object.keys(grid).map((key) => grid[key]) as ClassifiedField[][]);
  }

  private isField(field: any): boolean {
    return field?.type && typeof field.type === 'string';
  }

  private getClassifyObject(field: Field | FieldGroup | FieldArray, name: string): any {
    if (Array.isArray(field)) {
      return { classify: 'array', name, data: field };
    }

    if (this.isField(field)) {
      return { classify: 'field', name, data: { ...field, name } };
    }

    if (typeof field === 'object') {
      return { classify: 'group', name, data: field };
    }
  }

  protected handleInnerFormCreation(name: string, form: FormGroup | FormArray): void {
    const builtForm$ = this.nestedFormsInitialized[name];
    if (builtForm$ && !builtForm$.isStopped) {
      builtForm$.next(form);
      builtForm$.complete();
    }
  }

  private fieldsAreCreated(form?: FormGroup | FormArray): boolean {
    const nestedForms = Object.values(this.nestedFormsInitialized);

    if (!nestedForms?.length) {
      this.created.emit(form);
      return true;
    }

    if (nestedForms.every((nestedFormFields) => nestedFormFields.isStopped)) {
      this.created.emit(form);
      return true;
    }
    return false;
  }
}
