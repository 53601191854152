import { Component, Input } from '@angular/core';
import { ColorPalette } from "@shared/enums/color-palette.enum";

@Component({
  selector: 'app-order-items-card',
  templateUrl: './order-items-card.component.html',
  styleUrls: ['./order-items-card.component.scss']
})
export class OrderItemsCardComponent {
  @Input() selectedPallet;
  protected readonly ColorPalette = ColorPalette;
}
