import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class AvlOverSpeed extends Model {
  @Expose() deviceId: number;
  @Expose() deviceName: string;
  @Expose() speed: number;
  @Expose() latitude: number;
  @Expose() longitude: number;
}
