import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-datetime-range',
  templateUrl: './datetime-range.component.html',
  styleUrls: ['./datetime-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeRangeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimeRangeComponent implements ControlValueAccessor, OnInit, OnDestroy ,OnChanges{
  @Input() hiddenInput = false;
  @Input() open :boolean;
  @Input() maxHight : string;
  @Output() openChange = new EventEmitter<boolean>();
  form: FormGroup;
  isOpen = false;
  fromLabel: Date = null;
  toLabel: Date = null;
  uuid = `picker-${(Math.random() * 1000).toString(32)}-${Date.now().toString(32)}`;
  hours = new Array(11).fill(1).map((_, i) => `${i + 1}`);
  minsAndSecs = new Array(60).fill(1).map((_, i) => `${i}`);

  selectedTabIndex = 0;

  previousValue = {
    from: { date: '', hours: '', minutes: '', period: 0 },
    to: { date: '', hours: '', minutes: '', period: 0 },
  };

  private destroy$ = new Subject();

  onChange = (value: any) => null;
  onTouched = () => null;

  constructor(
    private builder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
  ) { }

  registerOnTouched(fn: any): void { this.onTouched = fn; }
  registerOnChange(fn: any): void { this.onChange = fn; }

  get value(): { to: Date, from: Date } {
    return {
      from: this.fromLabel,
      to: this.toLabel,
    };
  }

  get isToValid(): boolean {
    return typeof this.value.to?.getTime() === 'number';
  }

  get isFromValid(): boolean {
    return typeof this.value.from?.getTime() === 'number';
  }

  writeValue(value: { to: string|Date, from: string|Date }): void {
    const from = this.parseValue(value?.from);
    const to = this.parseValue(value?.to);
    this.form.setValue({ from, to });
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.form = this.builder.group({
      from: this.builder.group({
        date: [''],
        hours: ['12'],
        minutes: ['00'],
        period: [0]
      }),

      to: this.builder.group({
        date: [''],
        hours: ['12'],
        minutes: ['00'],
        period: [0]
      }),
    });

    this.changeDetector.markForCheck();

    this.form.valueChanges
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        const { from, to } = this.form.value;
        if (from && from.date) {
          this.fromLabel = this.parseDateTime(from);
        }

        if (to && to.date) {
          this.toLabel = this.parseDateTime(to);
        }
        this.changeDetector.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.open && changes.open.currentValue === true) {
      this.handleOpen();
    }
  }

  onKeydown(event): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  close(): void {
    this.reset();
    this.isOpen = false;
    this.openChange.emit(false);
  }

  handleChange(): void {
    if (this.selectedTabIndex) {
      this.onChange(this.value);
      this.isOpen = false;
      this.openChange.emit(false);
      this.previousValue = this.form.value;
      this.selectedTabIndex = 0;
      return;
    }
    this.selectedTabIndex = 1;
  }

  reset(): void {
    this.form.setValue(this.previousValue);
  }

  handleOpen(): void {
    this.isOpen = true;
    this.onTouched();
  }

  parseDateTime(data): Date {
    const { date, hours, minutes, period } = data;
    const dateTime = moment(date);
    dateTime.set({
      hours: (+hours) + (+period),
      minutes: +minutes,
      seconds: 0,
    });
    return dateTime.toDate();
  }

  parseValue(value: string|Date): { date: string|Date, hours: string, minutes: string, period: number } {
    if (value) {
      const momentDate = moment(value);
      const hours = momentDate.hours();
      return {
        date: momentDate.toDate(),
        hours: (hours > 12 ? hours - 12 : hours).toString().padStart(2, '0'),
        minutes: momentDate.minutes().toString().padStart(2, '0'),
        period: hours > 12 ? 12 : 0,
      };
    }
    return {
      date: '',
      hours: '01',
      minutes: '00',
      period: 0,
    };
  }
}
