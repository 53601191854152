import { Expose, Transform, Type } from 'class-transformer';
import { TruckType } from './truck-type';
import { DriverModel } from './driver.model';
import { Model } from '@shared-features/models/model';
import { TruckAvailableStatus } from '@features/fleets/enums/truck-available-status.enum';
import { CityArea } from '../../shared-features/models/city-area.model';
import { ShipmentList } from '@features/shipments/models/shipment-list';
import { TruckOperationStatus } from '@features/fleets/enums/truck-operation-status.enum';
import { PlanningTruckStatus } from '@features/trip-planning/enums/planning-truck-status.enum';
import { isDefined } from '@shared/utils/is-defined.util';
import { Fleet } from '@features/fleets/models/fleet';

export class PlanningTruck extends Model {
  @Expose() id: number;
  @Expose() avlDeviceId: string;
  @Expose() plateNumber: string;
  @Expose() status: PlanningTruckStatus;
  @Type(() => DriverModel)
  @Expose()
  driver: DriverModel = new DriverModel();
  @Expose() latitude: number;
  @Expose() longitude: number;
  @Expose() shipmentCreationDate: Date;
  @Expose() shipmentId: number;
  @Expose() type: TruckType = new TruckType();
  @Expose() fromShipment: string;
  @Expose() toShipment: string;
  @Expose() resolvedCity: CityArea;
  @Expose() currentCity: string;
  @Expose() operationStatus: TruckOperationStatus;
  @Expose() lastUpdate: string;
  @Expose() isOperationStatusChangeable: boolean;

  @Expose()
  @Type(() => Fleet)
  fleet: Fleet;

  @Type(() => ShipmentList)
  @Expose()
  assignedShipments: ShipmentList[];

  @Expose()
  @Transform(({ value }) => {
    return isDefined(value) ? new Date(value as any) : value;
  })
  shipmentExpectedDeliveryDate: string | number;

  constructor() {
    super();
  }

  @Expose() get isFree(): boolean {
    return (
      this.status === PlanningTruckStatus.Available || this.status === PlanningTruckStatus.Assigned
    );
  }

  @Expose() get isBusy(): boolean {
    return this.status === PlanningTruckStatus.NotAvailable;
  }

  @Expose() get isNotReady(): boolean {
    return this.status === PlanningTruckStatus.NotReady;
  }
}
