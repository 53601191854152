import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-plate-number',
  templateUrl: './plate-number.component.html',
  styleUrls: ['./plate-number.component.scss'],
})
export class PlateNumberComponent implements OnInit {
  @Input() number = null;
  @Input() plateNumber: number = null;
  @Input() plateText1: any  = null;
  @Input() plateText2: any = null;
  @Input() plateText3: any = null;

  public form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
    if (this.number) {
      const numbers = this.number.split('');
      this.form.controls['number1'].setValue(numbers[numbers.length - 3]);
      this.form.controls['number2'].setValue(numbers[numbers.length - 2]);
      this.form.controls['number3'].setValue(numbers[numbers.length - 1]);
      this.form.controls['main'].setValue(numbers.splice(0, 3).join(''));
    }
  }

  ngOnChanges() {
    if(this.form)
    {
      this.form.controls['main'].setValue(this.plateNumber);
      this.form.controls['number1'].setValue(this.plateText1);
      this.form.controls['number2'].setValue(this.plateText2);
      this.form.controls['number3'].setValue(this.plateText3);
    }
    
  }

  getValue() {
    const value = [];
    Object.keys(this.form.controls).forEach((control) => {
      value.push(this.form.controls[control]?.value);
    });
    return value.join('');
  }

  private initForm() {
    this.form = this.formBuilder.group({
      main: new UntypedFormControl('', [Validators.required, Validators.maxLength(4)]),
      number1: new UntypedFormControl('', Validators.required),
      number2: new UntypedFormControl('', Validators.required),
      number3: new UntypedFormControl('', Validators.required),
    });
  }
}
