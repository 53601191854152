import { Expose, Type } from "class-transformer";
import { AvlDevicePosition } from "../../live-tracking/models/avl-device-position";
import { AvlShipmentStops } from "../../live-tracking/models/avl-shipment-stops.model";
import { AVLSummary } from "../../live-tracking/models/avl-summary.model";
import { Model } from "../../shared-features/models/model";
import { ShipmentSummaryPerTruck } from "../../shipments/models/shipment-summary-per-truck.model";

export class TruckPath extends Model {
    @Type(() => AvlDevicePosition)
    @Expose()
    positions: AvlDevicePosition[] = [];

    @Type(() => AVLSummary)
    @Expose()
    summary: AVLSummary = new AVLSummary();

    @Type(() => AvlShipmentStops)
    @Expose() stops: AvlShipmentStops = new AvlShipmentStops();

    @Type(()=> ShipmentSummaryPerTruck)
    @Expose()
    shipments: ShipmentSummaryPerTruck[]=[];
}
