import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@env/environment';
import { AppError } from '@shared/bloc/errors';

@Injectable()
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() { }

  handleError(error: any): void {
    const isHttpError = error instanceof AppError;
    if (environment.production && !isHttpError) {
      Sentry.captureException(error.originalError || error);
    }
    console.error(error)
  }
}
