export enum URLSegment {
  Auth = 'auth/',
  User = 'user/',
  Shared = 'shared/',
  Role = 'role/',
  Customer = 'customer/',
  Receiver = 'receiver/',
  ProductType = 'product-type/',
  Fleet = 'fleet/',
  Shipment = 'shipment/',
  TruckType = 'truck-type/',
  Driver = 'driver/',
  Truck = 'truck/',
  City = 'city/',
  Contract = 'contract/',
  Brand = 'brand/',
  Elm = 'elm/',
  Vehicle = 'vehicle/',
  DriverLocation = 'driver_location/',
  Lead = 'lead/',
  Dashboard = 'dashboard/',
  ShipmentLocation = 'shipment_location/',
  DeliveryNotes = 'delivery_notes',
  Invoice = 'invoice/',
  PartialInvoice = 'partial-invoice/',
  Organization = 'organization/',
  InvoiceTransportation = 'invoice-transportation/',
  InvoiceHistory = 'invoice-history/',
  RouteTime = 'route-time/',
  ShipmentRule = 'shipment-rule/',
  Seller = 'seller/',
  MeasurementUnit = 'measurement-unit/',
  Permits = 'permit/',
  Trailer = 'trailer/',
  Traccar = 'traccar/',
  Logs = 'logs',
  Request = 'request/',
  CustomerSupport = 'customer-support/',
  Ticket = 'ticket/',
  Geofencing = 'geofencing/',
  TripPerformance = 'shipment-performance-config/',
  SmartCenter = 'smart-center/',
  RentedTrucksContracts = 'rented-trucks-contracts/',
  WCC = 'wcc/',
  AssingingLogs = 'assinging-logs/',
  Order = 'order/',
  Pallet = 'pallet/',
  Athr = 'athr/',
  Integration = 'integration/',
  Payment = 'payment/',
  Announcement = 'announcement/',
  SalesOrder = 'sales-order/',
  ShipmentRouting = 'shipment-routing/',
  TruckAreaPreference = 'truck-area-preference/',
  AssignmentRequest = 'assignment-request/',
  Wallet = 'wallet/',
  ExternalTracking = 'external-tracking/',
  ReleaseNote = 'release-note/',
  PrimaryConfiguration = 'primary-config/',
}
