import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '../base-component/base.component';
import { ShipmentsApiService } from '@features/shipments/services/shipments-api.service';
import { TransactionHistory } from '@features/shipments/models/transaction-history.model';

@Component({
  selector: 'app-transaction-history-dialog',
  templateUrl: './transaction-history-dialog.component.html',
  styleUrls: ['./transaction-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionHistoryDialogComponent extends BaseComponent implements OnInit {
  transactionHistory: TransactionHistory[];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { shipmentId: number; type: string },
    private shipmentService: ShipmentsApiService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.getTransactionHistory();
  }

  getTransactionHistory() {
    this.load(
      this.shipmentService.getTransactionHistory({
        shipmentId: this.data.shipmentId,
        type: this.data.type,
      }),
      {
        isLoadingTransparent: true,
      }
    ).subscribe((res: TransactionHistory[]) => {
      this.transactionHistory = res;
      this.changeDetectorRef.markForCheck();
    });
  }
}
