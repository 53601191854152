import { Injectable } from '@angular/core';
import { Bloc } from '@core/bloc';
import { take } from 'rxjs/operators';
import { ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { BlocEvent, InitEvent } from '@core/bloc/bloc-event';
import { Drawing } from '@shared-features/models/drawing';
import { ReadyState } from '@core/bloc/bloc-state';
import { BlocState } from '@core/bloc/bloc-state';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { User } from '@features/users/models/user';

@Injectable()
export class InvoicesFilterBloc extends Bloc<BlocEvent, BlocState> {
  constructor(private usersCommonApiService: UsersCommonApiService) {
    super();
  }

  onIncomingEvents(event: BlocEvent): void {
    if (event instanceof InitEvent) {
      this.onState$.next(new ProcessingState<Drawing>(new Drawing()));

      this.usersCommonApiService
        .getAllUsers()
        .pipe(take(1))
        .subscribe(
          (creators) => {
            this.onState$.next(new ReadyState<User[]>(creators));
          },
          (error) => {
            this.onState$.next(new ErrorState<Drawing>(new Drawing(), error));
          }
        );
    }
  }
}
