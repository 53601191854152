import { DropdownModel } from './dropdown-model';
import { Expose } from 'class-transformer';

export class Country extends DropdownModel {
  @Expose() id: number;
  @Expose() name: string = '';
  @Expose() phoneKey: string = '';
  @Expose() isoCode: string = '';

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): Object {
    return this;
  }
}
