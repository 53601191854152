import {
  AfterViewInit, ChangeDetectorRef,
  Component, ContentChildren,
  ElementRef,
  EventEmitter,
  Input, NgZone, OnChanges, OnDestroy,
  Output, QueryList, SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';

interface LatLng {
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-map-overlay',
  template: `<div #content><div style="position:absolute" [hidden]="!visible"><ng-content></ng-content></div></div>`,
  styles: []
})
export class MapOverlayComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() latitude: number;
  @Input() longitude: number;

  @Input() zIndex = 1;
  @Input() bounds: {x: LatLng, y: LatLng };

  @Output() markerClick = new EventEmitter<void>();

  @Input() openInfoWindow = true;
  @ContentChildren(MapInfoWindow) infoWindow: QueryList<MapInfoWindow> = new QueryList<MapInfoWindow>();

  // TODO, implement this
  @Input('markerDraggable') draggable = false;

  @ViewChild('content', { read: ElementRef }) template: ElementRef;

  visible = true;

  destroyed: boolean;
  overlayView: any;
  // private _observableSubscriptions: Subscription[] = [];
  mapLoaded = false;
  viewInit = false;

  nativeMarker = null;

  constructor(
    private googleMap: GoogleMap,
    private ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
  ) {
  }

  ngAfterViewInit(): void {
    const infoWindows = this.template.nativeElement.getElementsByTagName('map-info-window');
    for (let x = infoWindows.length - 1; x >= 0; --x){
      infoWindows[x].parentNode.removeChild(infoWindows[x]);
    }

    if (this.googleMap?.googleMap) {
      this.load();
    } else {
      console.error('google maps is not installed');
    }
  }

  ngOnChanges({ latitude, longitude, zIndex }: SimpleChanges): void {
    if (this.overlayView) {
      if (latitude || longitude || zIndex) {
        this.overlayView.longitude = longitude;
        this.overlayView.latitude = latitude;
        this.overlayView.zIndex = zIndex;
        this.destroy();
        this.load();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  load(): void {
    const overlay = this.getOverlay(this.googleMap.googleMap);
    this.createMarker(overlay);
    if (this.nativeMarker?.map) {
      this.overlayView.setMap(this.nativeMarker.map);
      this.visible = true;
    }

    const setMap = this.nativeMarker.setMap.bind(this.nativeMarker);
    this.nativeMarker.setMap = (map) => {
      setMap(map);
      if (this.overlayView) {
        this.overlayView.setMap(map);
      }
      this.visible = !!map;
      this.changeDetector.detectChanges();
      this.changeDetector.markForCheck();
    };
  }

  getOverlay( map ): google.maps.OverlayView {
    this.overlayView = this.overlayView || new google.maps.OverlayView();

    /* make into foo marker that AGM likes */
    this.overlayView.iconUrl = ' ';
    this.overlayView.latitude = this.latitude;
    this.overlayView.longitude = this.longitude;
    this.overlayView.visible = false; // hide 40x40 transparent placeholder that prevents hover events
    /* end */

    if (this.bounds){
      this.overlayView.bounds_ = new google.maps.LatLngBounds(
        new google.maps.LatLng(
          this.latitude + this.bounds.x.latitude,
          this.longitude + this.bounds.x.longitude
        ),
        new google.maps.LatLng(
          this.latitude + this.bounds.y.latitude,
          this.longitude + this.bounds.y.longitude
        )
      )
    }

    // js-marker-clusterer does not support updating positions. We are forced to delete/add and compensate for .removeChild calls
    const elm = this.template.nativeElement.children[0];
    // const elm =  this.elmGuts || this.template.nativeElement.children[0]

    // we must always be sure to steal our stolen element back incase we are just in middle of changes and will redraw
    const restore = (div) => {
      this.template.nativeElement.appendChild( div );
    };

    this.overlayView.remove = function(): void {
      if (!this.div) { return; }
      this.div.parentNode.removeChild(this.div);
      restore( this.div );
      delete this.div;
    };

    this.overlayView.getDiv = function(): any{
      return this.div;
    };

    this.overlayView.draw = function(): void{
      if ( !this.div ) {
        this.div = elm;
        const panes = this.getPanes();
        // if no panes then assumed not on map
        if (!panes || !panes.overlayImage) { return; }
        panes.overlayImage.appendChild(elm);
      }

      const latlng = new google.maps.LatLng(this.latitude,this.longitude);

      const proj = this.getProjection();
      if (!proj) { return; }

      const point = proj.fromLatLngToDivPixel(latlng);

      if (point) {
        elm.style.left = (point.x - 10) + 'px';
        elm.style.top = (point.y - 20) + 'px';
      }

      if (this.bounds_){
        // stretch content between two points leftbottom and righttop and resize
        const boundsProj = this.getProjection();
        const sw = boundsProj.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        const ne = boundsProj.fromLatLngToDivPixel(this.bounds_.getNorthEast());

        this.div.style.left = sw.x + 'px';
        this.div.style.top = ne.y + 'px';
        this.div.children[0].style.width = ne.x - sw.x + 'px';
        this.div.children[0].style.height = sw.y - ne.y + 'px';
      }
    };

    elm.addEventListener('click', event => {
      event.stopPropagation();
      this.handleInfoWindowUpdate();
    });

    return this.overlayView;
  }

  createMarker(markerOptions): void {
    this.ngZone.runOutsideAngular(() => {
      this.nativeMarker = new google.maps.Marker({
        position: {lat: markerOptions.latitude, lng: markerOptions.longitude},
        label: markerOptions.label,
        draggable: markerOptions.draggable,
        icon: markerOptions.iconUrl,
        opacity: markerOptions.opacity,
        visible: markerOptions.visible,
        zIndex: markerOptions.zIndex,
        title: markerOptions.title,
        clickable: markerOptions.clickable,
        map: this.googleMap.googleMap,
      });
    });
  }

  destroy(): void {
    this.destroyed = true;
    this.visible = false;
    if (this.nativeMarker) {
      this.nativeMarker.setMap(null);
      this.nativeMarker = null;
    }

    if (this.overlayView?.div) {
      this.overlayView.remove();
    }

    if (this.overlayView) {
      this.overlayView.setMap(null);
    }
  }

  get marker(): any {
    return this.nativeMarker;
  }

  private handleInfoWindowUpdate(): void {
    if (this.infoWindow.length > 1) {
      throw new Error('Expected no more than one info window.');
    }

    this.infoWindow.forEach((infoWindow) => {
      infoWindow.open({ getAnchor: () => this.nativeMarker });
    });
  }
}
