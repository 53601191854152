import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component,  OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { takeUntil } from 'rxjs/operators';
import { EmptyState, ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { ConfigConstant } from '@shared/constants/config.constant';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FunctionUtil } from '@shared/utils/function.util';
import { FeedbackModel, FeedbackType } from '@shared/model/feedback';
import { InvoiceType } from "@features/invoices/enums/invoice-type.enum";
import { DeliveryNoteStatus } from "@features/delivery-notes/enums/delivery-note-status.enum";
import { InvoiceStatus } from "@features/invoices/enums/invoice-status.enum";
import { drawingType } from "@shared/enums/drawing-type.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { DeliveryNotesManagementBloc } from '../../bloc/delivery-notes-management-bloc/delivery-notes-managament-bloc';
import {
  DeliveryNotesManagementState,
  ExportDeliveryNotesReadyState,
  GetAllCreatorsReadyState,
  GetAllCustomersReadyState,
} from '../../bloc/delivery-notes-management-bloc/delivery-notes-management-state';
import {
  ExportDeliveryNotesEvent,
  GetAllCustomersEvent,
  GetAllUsersEvent,
} from '../../bloc/delivery-notes-management-bloc/delivery-notes-management-event';
import { SharedConstants } from '@shared/model/shared-constants';
import { User } from '@features/users/models/user';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { Customer } from '@features/customers/models/customer';
import { Shipment } from '@features/shipments/models/shipment';
import { DeliveryNote } from '@features/invoices/models/delivery-note';

@Component({
  selector: 'app-delivery-notes-page-management',
  templateUrl: './delivery-notes-management-page.component.html',
  styleUrls: ['./delivery-notes-management-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('10ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [DeliveryNotesManagementBloc],
})
export class DeliveryNotesManagementPageComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  sharedConstants = SharedConstants;
  form: UntypedFormGroup;
  SectionStateStatus = SectionStateStatus;
  expandMore: boolean = false;
  expandedElement: Shipment = new Shipment();
  //customer Filter
  filterSection = true;
  customers: Customer[] = [];
  creators: User[] = [];
  filterForm: UntypedFormGroup;
  allStatusCount: number;
  availableToBeIssuedCount: number;
  notReceivedCount: number;
  issuedCount: number;
  customerId: number;
  createdBy: number;
  from: string;
  to: string;
  fromValue: string;
  toValue: string;
  isForExporting: boolean;
  // pagination
  pageSize: number = ConfigConstant.PAGE_SIZE;
  pageIndex = 1;
  offset: number = ConfigConstant.INIT_PAGE_OFFSET;
  totalRowsCount: number;
  deliveryNoteStatus: DeliveryNoteStatus;
  DeliveryNoteStatus = DeliveryNoteStatus;
  InvoicesStatus = InvoiceStatus;

  searchTerm: string = '';

  public dataSource = new MatTableDataSource<DeliveryNote>();
  public displayedColumns: string[] = [
    'customerName',
    'from',
    'to',
    'deliveryNoteId',
    'transportationCost',
    'customerPrice',
    'margin',
    'createdBy',
    'truckNo',
    'driverName',
    'status',
    'invoiceStatus',
    'expand',
  ];

  constructor(
    
    private bloc: DeliveryNotesManagementBloc,
    private sharedApiService: SharedApiService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.bloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    this.filterSection = false;
    this.sectionState = SectionStateStatus.Empty;
    this.initForm();
    this.initFilterForm();
    this.form.get('from').setValue(this.fromValue);
    this.form.get('to').setValue(this.toValue);
  }

  initForm() {
    this.form = this.formBuilder.group({
      from: new UntypedFormControl(null),
      to: new UntypedFormControl(null),
    });
  }

  onValidateButtonClicked() {
    this.getDeliveryNotes();
    this.filterSection = true;
  }

  setupTable(deliveryNotes: DeliveryNote[]) {
    this.dataSource.data = deliveryNotes;
  }

  onSearch(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.getDeliveryNotes();
  }

  onPageIndexChange(index: number) {
    this.pageIndex = index;
    if (index > 1) {
      this.offset = index - 1;
    } else {
      this.offset = 0;
    }
    this.getDeliveryNotes();
  }

  expandRow(shipment: Shipment) {
    if (shipment.deliveryNote.length > 1) {
      this.expandedElement = this.expandedElement?.id === shipment.id ? null : shipment;
    }
  }

  private initFilterForm() {
    this.filterForm = new UntypedFormGroup({
      customer: new UntypedFormControl(SharedConstants.ALL),
      creator: new UntypedFormControl(SharedConstants.ALL),
      customerSearchTerm: new UntypedFormControl(''),
      creatorSearchTerm: new UntypedFormControl('')
    });
  }

  get filteredCustomers() {
    return this.customers.filter((customer) => customer.dropDownDisplayName.toLowerCase().includes(this.filterForm.controls.customerSearchTerm.value?.toLowerCase()));
  }


  get filteredCreators() {
    return this.creators.filter((user) => user.dropDownDisplayName.toLowerCase().includes(this.filterForm.controls.creatorSearchTerm.value?.toLowerCase()));
  }

  onCustomerSelectionChange(event) {
    this.customerId = event.value;
    this.getDeliveryNotes();
  }

  onCreatorSelectionChange(event) {
    this.createdBy = event.value;
    this.getDeliveryNotes();
  }

  onExportClicked() {
    this.bloc.setEvent.next(
      new ExportDeliveryNotesEvent(
        this.customerId,
        this.offset,
        this.pageSize,
        this.searchTerm,
        FunctionUtil.convertDate(this.form.get('from').value),
        FunctionUtil.convertDate(this.form.get('to').value),
        this.deliveryNoteStatus,
        this.isForExporting
      )
    );
  }

getDeliveryNotes(): void {
  this.load(
    this.sharedApiService.getDeliveryNotes({
      customerId: this.customerId,
      offset: this.offset,
      limit: this.pageSize,
      searchTerm: this.searchTerm,
      startFrom: FunctionUtil.convertDate(this.form.get('from').value),
      endAt: FunctionUtil.convertDate(this.form.get('to').value),
      createdBy: this.createdBy,
      deliveryNoteStatus: this.deliveryNoteStatus,
      invoiceType: InvoiceType.Customer,
    }),
    {
      emptyContentCheck: (deliveryNoteDetails) =>
        !deliveryNoteDetails.shipmentDeliveryNoteList ||
        !deliveryNoteDetails.shipmentDeliveryNoteList?.length,
    }
  ).subscribe((deliveryNoteDetails) => {
    this.totalRowsCount = deliveryNoteDetails.count;
    this.allStatusCount = deliveryNoteDetails.allStatusCount;
    this.availableToBeIssuedCount = deliveryNoteDetails.availableToBeIssuedCount;
    this.notReceivedCount = deliveryNoteDetails.notReceivedCount;
    this.issuedCount = deliveryNoteDetails.issuedCount;
    this.bloc.setEvent.next(new GetAllCustomersEvent());
    this.bloc.setEvent.next(new GetAllUsersEvent());
    this.setupTable(deliveryNoteDetails.shipmentDeliveryNoteList);
  });
}

  private handleBlocStates(state: DeliveryNotesManagementState) {
    if (!state) {
      return;
    }
    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof GetAllCustomersReadyState) {
      this.customers = state.customers;

      this.sectionState = SectionStateStatus.Ready;
    } else if (state instanceof GetAllCreatorsReadyState) {
      this.creators = state.creators;
      this.sectionState = SectionStateStatus.Ready;
    } else if (state instanceof ExportDeliveryNotesReadyState) {
      window.open(state.data.fileUri, '_blank');
      this.sectionState = SectionStateStatus.Ready;
      this.asyncFeedbackService.showSuccessMessage(this.translationsList['SuccessMessages']['FileExportedSuccessfully']);
    } else if (state instanceof EmptyState) {
      this.sectionState = SectionStateStatus.Empty;
    } else if (state instanceof ErrorState) {
      this.sectionState = state.drawingType;
      if (state.drawingType === drawingType.NonDrawing) {
        this.sectionState = SectionStateStatus.Ready;
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Failure,
            state.error ? state.error.errors[0] : this.translationsList['ErrorMessages']['ErrorHappened']
          )
        );
      } else {
        this.sectionState = SectionStateStatus.Error;
      }
    }
  }
}
