import { Expose } from 'class-transformer';
import { ShipmentStatusCount } from './shipment-status-count';

export class ShipmentsCount extends ShipmentStatusCount {

  @Expose() hidePrice: boolean;

  constructor() {
    super();
  }
}
