import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class WCCDeliveryNoteLine extends Model {
  @Expose()
  shortDesc: string;

  @Expose()
  desc: string;

  @Expose()
  orderAmount: number;

  @Expose()
  loadedAmount: number;

  @Expose()
  unitMeasurement: string;

  @Expose()
  loadingRemark: string;

  @Expose()
  deliveryLineId: number;

  @Expose()
  productId: string;

  @Expose()
  deletedFlag: string;
}
