/**
 * get value from nested objects by path string separated with . like(res.data.x)
 * which retrieved from {res: { data: { x: 5 }}}
 * @param data: any object to retrieve value from
 * @param path: string path to the value key
 */
export function getValueByPath<T= any >(data: any, path: string): T {
  if (path) {
    const keys = path.split('.');
    return keys.reduce((list, key) => {
      if (list && list[key]) {
        return list[key];
      }
      return null;
    }, data);
  }
  return data;
}
