import { registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import localeEn from '@angular/common/locales/en';
import localeArExtra from '@angular/common/locales/extra/ar';
import localeEnExtra from '@angular/common/locales/extra/en';
import { TranslateService } from '@ngx-translate/core';

export class LocaleIdHandlerService extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    switch (this.service.currentLang) {
      case 'ar':
        registerLocaleData(localeAr, 'ar', localeArExtra);
        break;

      case 'en':
        registerLocaleData(localeEn, 'en', localeEnExtra);
        break;

      default:
        break;
    }

    return this.service.currentLang;
  }
}
