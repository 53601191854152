<div class="breadcrumb" [class.d-none]="showBreadcrumb === false">
  <div
    *ngFor="let breadcrumb of breadcrumbs; last as isLast; first as isFirst"
    class="breadcrumb__item"
    [class.breadcrumb__item--last]="isLast"
    [class.breadcrumb__item--first]="isFirst"
  >
    <span class="breadcrumb__item-bg"></span>
    <ng-container
      *ngTemplateOutlet="
      !isLast && breadcrumb.path !== '' ? route : lastRoute;
      context:{breadcrumb: breadcrumb, isFirst: isFirst, isLast: isLast}"
    ></ng-container>
  </div>
</div>

<ng-template #lastRoute let-breadcrumb="breadcrumb" let-isLast="isLast" let-isFirst="isFirst">
  <div class="breadcrumb__route" [class.breadcrumb__route--active]="isLast">
    <ng-container *ngTemplateOutlet="routeContent; context: {breadcrumb: breadcrumb, isLast: isLast, isFirst: isFirst}"></ng-container>
  </div>
</ng-template>

<ng-template #route let-breadcrumb="breadcrumb" let-isLast="isLast" let-isFirst="isFirst">
  <a
    class="breadcrumb__route"
    [class.breadcrumb__route--active]="breadcrumb.isActive"
    [routerLink]="breadcrumb.path"
    [queryParams]="breadcrumb?.queryParams"
  >
    <ng-container *ngTemplateOutlet="routeContent; context: {breadcrumb: breadcrumb, isLast: isLast, isFirst: isFirst}"></ng-container>
  </a>
</ng-template>

<ng-template #routeContent let-breadcrumb="breadcrumb" let-isLast="isLast" let-isFirst="isFirst">
  <app-icon
    class="breadcrumb__route-icon"
    *ngIf="isFirst && BreadcrumbIcons[breadcrumb.labelKey]"
    [matTooltip]="isFirst && !isLast ? (breadcrumb.isValue ? breadcrumb.labelKey : ('BreadCrumb.' + breadcrumb.labelKey | translate)) : null"
    matTooltipPosition="above"
    [name]="BreadcrumbIcons[breadcrumb.labelKey]"
  ></app-icon>
  <ng-container *ngIf="!isFirst || (isFirst && isLast)">
    <span *ngIf="breadcrumb.isValue" class="breadcrumb__route-label">{{ breadcrumb.labelKey }}</span>
    <span *ngIf="!breadcrumb.isValue" class="breadcrumb__route-label">{{ 'BreadCrumb.' + breadcrumb.labelKey | translate }}</span>
  </ng-container>
</ng-template>
