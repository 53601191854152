export function hashString(text: string): number {
  let hash = 0;
  let char;

  if (text.length === 0) {
    return hash;
  }

  for (let i = 0; i < text.length; i++) {
    char = text.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    hash = ((hash << 5) - hash) + char;
    // tslint:disable-next-line:no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
