<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form" [formGroup]="form" *ngIf="form">
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Time" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="time">
          <mat-option *ngFor="let range of dateRangeType | enumToArray" [value]="range.value">
            {{ 'Enum.DateRangeType.' + range.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    
       <!-- custom range -->
       <div class="field flex column" *ngIf="form.get('time').value === dateRangeType.CUSTOM ">
        <mat-label class="field-label">{{ "Filter.Form.SelectDateRange" | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-date-range-input [rangePicker]="picker">
            <input 
              readonly 
              [max]="maxDate"
              formControlName="startFrom" 
              matInput 
              matStartDate 
              [placeholder]="'Placeholder.StartDate' | translate" 
              />
    
            <input
              readonly
              formControlName="endAt"
              matInput
              matEndDate
              [placeholder]="'Placeholder.EndDate' | translate"
            />
    
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Customer" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="customerId" (selectionChange)="onCustomerSelectionChange($event)" >
          <app-dropdown-search formControlName="customerSearchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of filteredCustomers | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Fleet" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="fleetId" (selectionChange)="onFleetSelectionChange($event)" >
          <app-dropdown-search formControlName="fleetSearchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of filteredFleets | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</app-state-section>
