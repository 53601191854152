<div class="container flex flex justify-center">
  <div style="display: block; width: 100%; height: 100%">

    <canvas
    *ngIf="render"
      #chart
      baseChart
      [datasets]="chartDataSets"
      [labels]="chartLabels"
      [chartType]="type"
      [options]="chartOptions"
      [plugins]="chartPlugins"
      [colors]="chartColors"
      [legend]="false"
      id="chart"
    ></canvas>
  </div>
</div>
  