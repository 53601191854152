import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { IResponseList } from '@shared-features/interfaces/response-list.interface';
import { ApiBaseService } from '@core/services/api-base.service';
import { IPagingPayload, ISortPayload } from '@shared-features/interfaces/common-api-payloads.interface';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { ApiConstant } from '@shared/constants/api.constant';
import { Organization } from '../models/organization';
import { Customer } from '@features/customers/models/customer';

@Injectable({
  providedIn: 'root',
})
export class OrganizationsApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getOrganizations(params: IPagingPayload & ISortPayload & {searchTerm?: string }): Observable<IResponseList<Organization>> {
    return this.baseAPI.get(ApiConstant.GET_ALL_ORGANIZATIONS, { params: filterNullEntity(params) })
      .pipe(map((res) => ({
        items: this.mapper.fromJson(Organization, res.data.organizations),
        count: res.data.count,
      })));
  }

  getOrganizationById(organizationId: number): Observable<Organization> {
    return this.baseAPI.get(ApiConstant.GET_ORGANIZATION_BY_ID + organizationId).pipe(
      map((res) => {
        return this.mapper.fromJson(Organization, res.data);
      })
    );
  }

  addOrganization(organization: Organization): Observable<Organization> {
    const body = {
      title: organization.name,
      email: organization.email,
      mobileNumber: organization.mobileNumber,
      customerIds: organization.customers.map((item) => item.id),
    };

    if (organization.contactFirstName) {
      body['contactFirstName'] = organization.contactFirstName;
    }

    if (organization.contactLastName) {
      body['contactLastName'] = organization.contactLastName;
    }

    return this.baseAPI.post(ApiConstant.ADD_ORGANIZATION, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Organization, res.data);
      })
    );
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    const body = {
      organizationId: organization.id,
    };

    if (organization.name) body['title'] = organization.name;
    if (organization.mobileNumber) body['mobileNumber'] = organization.mobileNumber;
    if (organization.email) body['email'] = organization.email;
    if (organization.contactFirstName) body['contactFirstName'] = organization.contactFirstName;
    if (organization.contactLastName) body['contactLastName'] = organization.contactLastName;

    return this.baseAPI.put(ApiConstant.UPDATE_ORGANIZATION, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Organization, res.data);
      })
    );
  }

  addOrganizationCustomers(organization: Organization): Observable<Organization> {
    const body = {
      organizationId: organization.id,
    };
    if (organization.customers.length) body['customerIds'] = organization.customers.map((item) => item.id);

    return this.baseAPI.put(ApiConstant.ADD_ORGANIZATION_CUSTOMERS, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Organization, res.data);
      })
    );
  }

  removeOrganization(id: number): Observable<string> {
    return this.baseAPI.delete(ApiConstant.REMOVE_ORGANIZATION_BY_ID + '/' + id);
  }

  removeOrganizationCustomer(organizationId: number, customerId: number): Observable<Organization> {
    return this.baseAPI.put(ApiConstant.REMOVE_ORGANIZATION_CUSTOMERS, { organizationId, customerId }).pipe(
      map((res) => {
        return this.mapper.fromJson(Organization, res.data);
      })
    );
  }

  getCustomersWithoutOrganization(): Observable<Customer[]> {
    return this.baseAPI.get(ApiConstant.GET_CUSTOMERS_WITHOUT_ORGANIZATION).pipe(
      map((res) => {
        return this.mapper.fromJson(Customer, res.data);
      })
    );
  }

  setTempPassword(body:{
    id:number,
    tempPassword:string
  }){
    return this.baseAPI.post(ApiConstant.SET_TEMP_PASSWORD,body).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
