<form class="form-group flex column filter-form" [formGroup]="form" #f="ngForm">
  <div class="field flex column">
    <mat-label class="field-label">{{ "Filter.Form.Status" | translate }}</mat-label>
    <mat-form-field class="form-control" appearance="outline">
      <mat-select (selectionChange)="onSelectClicked('status', $event)" formControlName="status">
        <mat-option *ngFor="let item of status | enumToArray" [value]="item.value">{{ "Enum.Status." + item.value | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="field flex column" *ngIf="false">
    <mat-label class="field-label">{{ "Filter.Form.RegistrationDate" | translate }}</mat-label>
    <mat-form-field class="form-control" appearance="outline">
      <mat-select (selectionChange)="onSelectClicked('registrationDate', $event)" formControlName="registrationDate">
        <mat-option *ngFor="let item of filterByDate | enumToArray" [value]="item.value">
          {{ "Enum.DateRangeType." + item.key | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="field flex column">
    <mat-label class="field-label">{{ "Filter.Form.activationDate" | translate }}</mat-label>
    <mat-form-field class="form-control" appearance="outline">
      <mat-select (selectionChange)="onSelectClicked('activationDate', $event)" formControlName="activationDate">
        <mat-option
          *ngFor="let item of filterByDate | enumToArray"
          [value]="item.value"
          [ngClass]="{'hide-option': item.key === dateRangeType.CUSTOM}"
        >
          <ng-container *ngIf="item.key !== dateRangeType.CUSTOM">
            {{ "Enum.DateRangeType." + item.key | translate }}
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
