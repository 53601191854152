import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,  ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Lookup } from '@shared-features/models/Lookup.model';
import { LookupService } from '@shared-features/services/lookups.service';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ConfigConstant } from '@shared/constants/config.constant';
import { SearchCategory } from "@shared/enums/search-category.enum";
import { LookupType } from "@shared/enums/lookup-type.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { BehaviorSubject, combineLatest, fromEvent, timer } from 'rxjs';
import { debounceTime, filter, tap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent extends BaseComponent {

  @ViewChild('scrollElement') scrollElementRef: ElementRef<HTMLElement>;
  @ViewChild('loadMoreTrigger') loadMoreTriggerElementRef: ElementRef<HTMLElement>;

  searchBarActive = false;
  SearchCategory = SearchCategory;
  selectedCategory: SearchCategory;
  showSearchOptions = true;
  showSearchResult = false;
  searchControl = new UntypedFormControl('');
  entities: Lookup[] = [];
  limit = 10;
  margin = 20;
  loading = false;
  endOfResult = false;
  private pagination$: BehaviorSubject<{ offset: number }>;
  clearOffset = false;
  SectionStateStatus = SectionStateStatus;
  searchToogle = false;
  closeSearch = false;

  constructor( private lookupService: LookupService, private router: Router, private changeDetector:ChangeDetectorRef) {
    super();
    this.searchControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((v) => {
      if (v) {
        this.showSearchOptions = true;
        this.showSearchResult = false;
      }
    });
  }

  ngOnInit(): void {
    this.pagination$ = new BehaviorSubject({ offset: 0 });

    combineLatest([this.pagination$])
    .pipe(
      filter(() => !this.endOfResult),
      tap(() => this.loading = true),
      debounceTime(500),
      takeUntil(this.destroy$)
    )
    .subscribe((data) => {
      this.offset = data[0].offset | ConfigConstant.INIT_PAGE_OFFSET;
      this.getEntities(this.selectedCategory);
    });
  }

  ngAfterViewInit(): void {
    this.handleScrollEvent();
  }

  searchByCategory(value) {
    this.showSearchOptions = false;
    this.selectedCategory = value;
    this.offset = ConfigConstant.INIT_PAGE_OFFSET;
    this.endOfResult = false;
    this.entities = [];
    this.getEntities(value);
  }

  getEntities(value){
    switch (value) {
      case SearchCategory.Driver:
        this.getDrivers();
        break;

      case SearchCategory.Truck:
        this.getTrucks();
        break;
    }
  }

  getDrivers() {
    this.load(
      this.lookupService.getGlobalSearchLookup(
        LookupType.Drivers,
        {
          offset: this.offset,
          limit: this.limit,
          searchTerm: this.searchControl.value,
        },
        false
      )
    ).subscribe((res) => {
      if (res.length) {
        this.entities.push(...res);
      } else {
        this.endOfResult = true;
      }
      this.loading = false;
      this.showSearchResult = true;
      this.showSearchOptions = false;
    });
  }

  getTrucks() {
    this.load(
      this.lookupService.getGlobalSearchLookup(
        LookupType.Trucks,
        {
          offset: this.offset,
          limit: this.limit,
          searchTerm: this.searchControl.value,
        },
        false
      )
    ).subscribe((res) => {
      if (res.length) {
        this.entities.push(...res);
      } else {
        this.endOfResult = true;
      }
      this.loading = false;
      this.showSearchResult = true;
      this.showSearchOptions = false;
    });
  }

  navigateToDetailsPage(id) {
    switch (this.selectedCategory) {
      case SearchCategory.Driver:
        this.router.navigate([RoutesUtil.FleetsDriversView.url({ id })]);
        break;

      case SearchCategory.Truck:
        this.router.navigate([RoutesUtil.FleetsTrucksView.url({ id })]);
        break;
    }

    this.clear();
    this.searchToogle=false
  }

    // private methods
    handleScrollEvent(): void {
      const $scrollElement = this.scrollElementRef?.nativeElement;
      if (!$scrollElement) {
        return;
      }
      fromEvent($scrollElement, 'scroll')
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(500),
          filter(() => !this.loading && !this.endOfResult),
          filter(() => this.isLoadMoreTriggerVisible())
        )
        .subscribe(() => {
          this.loadMore();
        });
    }

    private isLoadMoreTriggerVisible(): boolean {
      const $scrollElement = this.scrollElementRef?.nativeElement;
      const $triggerElement = this.loadMoreTriggerElementRef?.nativeElement;

      if (!$scrollElement || !$triggerElement) {
        return false;
      }

      const { height } = $scrollElement.getBoundingClientRect();
      const { top } = $triggerElement.getBoundingClientRect();
      const scroll = $scrollElement.scrollTop;

      if (top === 0 && height === 0) {
        return false;
      }
      return top <= height + scroll + this.margin;
    }

    loadMore(): void {
      this.pagination$.next({ offset: this.offset + 1  });
    }

    clear() {
      this.endOfResult = false;
      this.offset = ConfigConstant.INIT_PAGE_OFFSET;
      this.showSearchOptions = false;
      this.showSearchResult = false;
      this.entities = [];
      this.searchControl.setValue(null);
      if(this.searchToogle){
        this.closeSearch = !this.closeSearch
        timer(500).subscribe(()=>{
          this.closeSearch = !this.closeSearch
          this.searchToogle = !this.searchToogle
          this.changeDetector.markForCheck()
        })
      }
    }
    resize$ = fromEvent(window, 'resize').pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(() => {
      this.showSearchOptions = false;
      this.showSearchResult = false;
    });
}
