import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnChanges {
  @Input() list: any[];
  @Input() actions: { id: string, label: string }[];
  @Input() translationPrefix: string;
  @Input() valuePath = 'value';
  @Input() labelPath = 'label';
  @Input() datePath = 'createdAt';
  @Input() bulletSize: 'md'|'lg' = 'md';
  @Input() labelBold:boolean = false;
  @Input() handleEmptyRequestState = false;
  @Input() emptyRequestActions: { id: string, label: string }[];
  @Output() actionClicked = new EventEmitter();
  displayedList: any[];

  constructor() { }

  ngOnInit(): void {
    this.generateDisplayedList();
  }

  ngOnChanges({ list }: SimpleChanges): void {
    if (list && list.currentValue !== list.previousValue) {
      this.generateDisplayedList();
    }
  }

  getValueFromPath(obj, path): any {
    const keys = path.split('.');
    return keys.reduce((group, key) => {
      return group[key];
    }, obj);
  }

  handleActionClick(item, action): void {
    this.actionClicked.emit({ item: item.item, action: action.id });
  }

  generateDisplayedList(): void {
    this.displayedList = (this.list || []).map(item => ({
      item,
      value: this.getValueFromPath(item, this.valuePath),
      label: this.getValueFromPath(item, this.labelPath),
      date: this.getValueFromPath(item, this.datePath),
      danger: item.danger,
    }));
  }
}
