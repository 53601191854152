import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiBaseService } from '@core/services/api-base.service';
import { User } from '@features/users/models/user';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { ApiConstant } from '@shared/constants/api.constant';
import { UserParam } from '@features/users/models/user-param.model';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}
  getUserById(userId: string): Observable<User> {
    return this.baseAPI.get(ApiConstant.GET_USER_BY_ID + userId).pipe(
      map((res) => {
        return this.mapper.fromJson(User, res.data);
      })
    );
  }

  updateUser(user: User): Observable<User> {
    const body = filterNullEntity({
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobileNumber: user.mobileNumber,
      countryId: user.country?.id,
      status: user.status,
      roleIds: user?.roles?.map((role) => role.id),
      shippingType: user.shippingType,
      allowToLogin: user.allowToLogin,
      params: user.params || [],
    });

    return this.baseAPI.put(ApiConstant.UPDATE_USER, body).pipe(
      map((res) => {
        return this.mapper.fromJson(User, res.data);
      })
    );
  }

  getUserParams(userId: number): Observable<UserParam[]> {
    return this.baseAPI
      .get(ApiConstant.GET_USER_PARAMS, { params: filterNullEntity({ userId }) })
      .pipe(map((res) => this.mapper.fromJson(UserParam, res.data)));
  }

  saveUserParams(userId: number, params: UserParam[]): Observable<UserParam[]> {
    return this.baseAPI
      .post(ApiConstant.ADD_EDIT_PARAMS, filterNullEntity({ userId, params }))
      .pipe(map((res) => this.mapper.fromJson(UserParam, res.data)));
  }

  changePassword(newPassword?: string): Observable<string> {
    return this.baseAPI.post(ApiConstant.CHANGE_PASSWORD, { newPassword });
  }
}
