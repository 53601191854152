import { Directive, Output } from '@angular/core';

@Directive({
  selector: '[appResponsive]'
})
export class ResponsiveDirective {

  isMobile = false;
  isTablet = false;

  constructor() { }

}
