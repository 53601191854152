import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedConstants } from '@shared/model/shared-constants';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { ShipmentStatus } from '@features/shipments/enums/shipment-status.enum';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormAction } from '@shared/enums/form.action.enum';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { AuthService } from '@core/services/auth.service';
import { UserRoleService } from '@core/services/user-role.service';
import { RolesConstants } from '@shared/model/roles-constants';
import { filterInjectionToken } from '@shared/services/filter-dialog.service';
import { IFilterController } from '@shared/interfaces/filter-controller.interface';
import { UserPreferencesService } from '@shared-features/services/user-preferences.service';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';
import { Customer } from '@features/customers/models/customer';
import { Fleet } from '@features/fleets/models/fleet';
import { TruckType } from '@features/fleets/models/truck-type';
import { User } from '@features/users/models/user';

@Component({
  selector: 'create-filter',
  templateUrl: './create-shipment-filter.component.html',
  styleUrls: ['./create-shipment-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateShipmentFilterComponent extends BaseComponent implements OnInit {
  @Input() data: any;

  // enums
  protected readonly ShipmentStatus = ShipmentStatus;
  protected readonly SharedConstants = SharedConstants;

  form: FormGroup;
  filterActionSubscription: Subscription;
  params: any = null;
  isB2c = false;

  dispose$ = new Subject();

  customersSearch = new FormControl('');
  customers: Customer[] = [];
  fleets: Fleet[] = [];
  truckTypes: TruckType[] = [];
  creators: User[] = [];
  assignedByUsers: User[] = [];
  companyLabels: any[];

  shipmentStatus: any;
  currentURL: any;
  currentTabIndex = 0;

  showCreatorByField = true;
  userId: number;
  isAllCustomersSelected = true;

  searchResultCustomers: Customer[] = [];
  filteredCreators: User[] = [];
  selectedCustomerIds: number[] = [];
  filteredAssignedBy: User[] = [];

  filterFormAction: FormAction;

  favFilters: any[];
  favFiltersIndex: number = -1;
  selectedFeild: FormGroup;

  constructor(
    private router: Router,
    private ref: ChangeDetectorRef,

    private authService: AuthService,
    private userRoleService: UserRoleService,
    private formBuilder: FormBuilder,
    private userPreferencesService: UserPreferencesService,
    @Inject(filterInjectionToken) private parentRef: IFilterController
  ) {
    super();

    parentRef.action$.subscribe((value) => {
      this.filterFormAction = value;
      this.handleAction();
    });
  }

  ngOnInit(): void {
    this.getData();
    const { url } = this.router;
    this.isB2c = url.toLowerCase().includes(BusinessType.B2C.toLowerCase());
    this.showCreatorByField =
      !this.router.url.includes(RoutesUtil.B2bShipmentsList.path) &&
      this.userRoleService.isUserHasRoles(RolesConstants.USERS_SHIPMENTS_FILTER_VIEW);
    this.userId = this.authService.currentUser$.value?.id;

    this.shipmentStatus = { ...this.ShipmentStatus };
    delete this.shipmentStatus.None;

    this.customersSearch.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe(() => {
        this.search();
      });

    this.currentURL = this.router.url;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  handleAction() {
    if (this.filterFormAction === FormAction.Submit) this.submitForm();
  }

  initForm() {
    let formValue = {
      customerId: this.searchResultCustomers.map((item) => item.id),
      fleetId: this.fleets.map((item) => item.id),
      customerLabel: [],
      creator: SharedConstants.ALL,
      assignedBy: SharedConstants.ALL,
      withoutPrice: false,
      withoutDeliveryNote: false,
      isReceived: false,
      customerSearchTerm: '',
      fleetSearchTerm: '',
      creatorSearchTerm: '',
      assignedBySearchTerm: '',
    };

    if (this.selectedFeild) {
      formValue = this.selectedFeild.value;
    }

    this.form = this.formBuilder.group({
      filterName: ['', [Validators.required]],
      customerId: [formValue.customerId],
      customerLabel: [formValue.customerLabel],
      fleetId: [formValue.fleetId],
      creator: [this.showCreatorByField ? formValue.creator : this.userId],
      assignedBy: [formValue.assignedBy || SharedConstants.ALL],
      withoutPrice: [formValue.withoutPrice],
      withoutDeliveryNote: [formValue.withoutDeliveryNote],
      isReceived: [formValue.isReceived],
      customerSearchTerm: [''],
      fleetSearchTerm: [''],
      creatorSearchTerm: [''],
      assignedBySearchTerm: [''],
    });

    this.form.controls.creatorSearchTerm.valueChanges
      .pipe(takeUntil(this.dispose$))
      .subscribe(() => {
        this.filteredCreators = this.creators.filter((element) =>
          element.dropDownDisplayName
            .toLowerCase()
            .includes(this.form.controls.creatorSearchTerm.value?.toLowerCase())
        );
      });

    this.form.controls.assignedBySearchTerm.valueChanges
      .pipe(takeUntil(this.dispose$))
      .subscribe(() => {
        this.filteredAssignedBy = this.assignedByUsers.filter((element) =>
          element.dropDownDisplayName
            .toLowerCase()
            .includes(this.form.controls.assignedBySearchTerm.value?.toLowerCase())
        );
      });
  }

  submitForm() {
    const favObj = this.form.value;
    const favFilterAfterAdded = [...this.favFilters.map((item) => ({ ...item }))];

    this.favFiltersIndex === -1
      ? favFilterAfterAdded.push(favObj)
      : (favFilterAfterAdded[this.favFiltersIndex] = favObj);

    if (favFilterAfterAdded?.length <= 6 && favObj.filterName) {
      this.load(
        this.userPreferencesService.updateUserPreferences(
          favFilterAfterAdded,
          UserPreferencesType.Filters
        )
      ).subscribe((statusCode) => {
        if (+statusCode === 200) {
          this.favFiltersIndex === -1
            ? this.favFilters.push(favObj)
            : (this.favFilters[this.favFiltersIndex] = favObj);
          this.parentRef.action$.next(FormAction.Cancel);
        }
      });
    } else {
      this.form.get('filterName').markAsTouched();
    }
  }

  onWithoutPriceSelectionChange(value: MatCheckboxChange) {
    this.form.controls['withoutPrice'].setValue(value.checked);
  }
  onWithoutDeliveryNoteSelectionChange(value: MatCheckboxChange) {
    this.form.controls['withoutDeliveryNote'].setValue(value.checked);
  }

  onIsReceivedSelectionChange(value: MatCheckboxChange) {
    this.form.controls['isReceived'].setValue(value.checked);
  }

  checkAllCustomersSelected(): void {
    const { customerId } = this.form.value;
    const isAllSelectedNow = (customerId || []).includes(this.SharedConstants.ALL);
    if (this.isAllCustomersSelected && isAllSelectedNow && customerId?.length > 1) {
      this.form.controls.customerId.setValue(
        customerId.filter((v) => v !== this.SharedConstants.ALL)
      );
      this.isAllCustomersSelected = false;
    } else if (!this.isAllCustomersSelected && isAllSelectedNow) {
      this.form.controls.customerId.setValue([this.SharedConstants.ALL]);
      this.isAllCustomersSelected = true;
    }
  }

  search(): void {
    const { value } = this.customersSearch;
    const keywords = (value || '')
      .toLowerCase()
      .split(' ')
      .filter((i) => !!i);
    if (!keywords?.length) {
      this.searchResultCustomers = this.customers.concat([]);
      return;
    }
    this.searchResultCustomers = this.customers.filter((option: Customer) =>
      keywords.find((keyword) => option.title.toLowerCase().includes(keyword))
    );
  }

  isIndeterminate(): boolean {
    return (
      this.form.controls.customerId.value &&
      this.searchResultCustomers.length &&
      this.form.controls.customerId.value.length < this.searchResultCustomers.length
    );
  }

  isChecked(): boolean {
    return (
      this.form.controls.customerId.value &&
      this.searchResultCustomers.length &&
      this.form.controls.customerId.value.length === this.searchResultCustomers.length
    );
  }

  onToggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.form.controls.customerId.patchValue([
        ...this.searchResultCustomers.map((item) => item.id),
      ]);
      this.selectedCustomerIds = [...this.form.controls.customerId.value];
    } else {
      this.form.controls.customerId.patchValue([]);
      this.selectedCustomerIds = [];
    }
  }

  getData() {
    const data = this.data;
    this.customers = data.customers;
    this.searchResultCustomers = [...this.customers];
    this.selectedCustomerIds = [...this.searchResultCustomers.map((item) => item.id)];
    this.fleets = data.fleets;
    this.truckTypes = data.truckTypes;
    this.creators = data.creators;
    this.assignedByUsers = data.assignedByUsers;
    this.filteredCreators = [...this.creators];
    this.filteredAssignedBy = [...this.assignedByUsers];
    this.favFilters = data.favFilters || [];
    this.selectedFeild = data.selectedFeild;
    this.companyLabels = data.companyLabels || [];
    this.initForm();

    if (data.editData) {
      this.favFiltersIndex = data.index;
      this.form.patchValue({
        filterName: data.editData.filterName,
        customerId: data.editData.customerId,
        customerLabel: data.editData.customerLabel
          ?.filter((i) => !!i)
          .map((item) =>
            this.companyLabels
              ?.filter((i) => !!i)
              .find((i) => i.dropDownValue === item.dropDownValue)
          ),
        fleetId: data.editData.fleetId,
        creator: data.editData.creator,
        assignedBy: data.editData.assignedBy,
        withoutPrice: data.editData.withoutPrice,
        withoutDeliveryNote: data.editData.withoutDeliveryNote,
        isReceived: data.editData.isReceived,
      });
    }
  }
}
