import { Directive, Input, TemplateRef } from '@angular/core';
import { ActivityLogTemplate } from '@shared/enums/activity-log-template.enum';

@Directive({
  selector: '[appActivityLogTemplate]',
  exportAs: 'activityLogTemplate',
})
export class ActivityLogTemplateDirective {
  type: ActivityLogTemplate;
  @Input() set appActivityLogTemplate(type: ActivityLogTemplate) {
    this.type = type;
  }

  constructor(public templateRef: TemplateRef<any>) {}
}
