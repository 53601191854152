<mat-nav-list class="nav-item" [class.nav-item--primary]="color === 'primary'">
  <ng-container *ngFor="let menuItem of menuList">
    <ng-container [ngTemplateOutlet]="menuItem.subMenu ?  menu : item" [ngTemplateOutletContext]="{menuItem:menuItem}"></ng-container>
  </ng-container>
</mat-nav-list>

<ng-template #item let-menuItem="menuItem" let-itemPanel="itemPanel">
  <mat-divider *ngIf="divider && menuItem.isVisible" class="divider mb-2"></mat-divider>
  <a
    *ngIf="menuItem.path && menuItem.isVisible"
    mat-list-item
    [routerLink]="'/' + menuItem.path"
    routerLinkActive="active-item"
    [routerLinkActiveOptions]="{exact: false}"
    [class.rtl]="layoutService.websiteDirection$.value === 'rtl'"
  >
    <div class="flex align-center">
      <app-icon *ngIf="menuItem.icon" class="d-inline-block align-center" size="sm" [name]="menuItem.icon"></app-icon>
      <div [class.collapsed]="collapsed" class="nav-item__title ml-4" *ngIf="!htmlItemsMap[menuItem.title]">{{menuItem.title}}</div>
      <div
        [class.collapsed]="collapsed"
        class="nav-item__title ml-4"
        *ngIf="htmlItemsMap[menuItem.title]"
        [innerHTML]="htmlItemsMap[menuItem.title]"
      ></div>
    </div>
  </a>
  <mat-list-item
    *ngIf="!menuItem.path && menuItem.isVisible"
    [class.rtl]="layoutService.websiteDirection$.value === 'rtl'"
    (click)="action.emit(menuItem.action)"
  >
    <div class="flex align-center nav-item__title">
      <app-icon *ngIf="menuItem.icon" class="d-inline-block align-center" size="sm" [name]="menuItem.icon"></app-icon>
      <div [class.collapsed]="collapsed" class="ml-4">{{menuItem.title}}</div>
    </div>
  </mat-list-item>
  <mat-divider *ngIf="divider && menuItem.isVisible" class="divider"></mat-divider>
</ng-template>

<ng-template #menu let-menuItem="menuItem" hideToggle="true" #example>
  <mat-divider *ngIf="divider && menuItem.isVisible" class="mb-2"></mat-divider>
  <mat-expansion-panel *ngIf="menuItem.isVisible" #panel [class.mat-elevation-z0]="true" [class.collaps]="collapsed" hideToggle="true">
    <mat-expansion-panel-header
      [popoverTrigger]="collapsed ? popover : null"
      #popoverInstance="popoverTrigger"
      [closeOnClickOutside]="true"
      [position]="position"
      [mouseOnHover]="collapsed"
      [width]="'auto'"
      [backDrop]="false"
      (click)="stopPropagation($event)"
      (mouseenter)="handleOpenPopover(popoverInstance)"
    >
      <div class="flex align-center nav-item__title">
        <app-icon *ngIf="menuItem.icon" class="d-inline align-center" size="sm" [name]="menuItem.icon"></app-icon>
        <div [class.collapsed]="collapsed" class="ml-4">{{menuItem.title}}</div>
      </div>

      <div class="ml-auto">
        <app-icon name="arrow_drop_up" type="MaterialDesign" class="d-inline align-center" size="sm" *ngIf="panel.expanded"></app-icon>
        <app-icon name="arrow_drop_down" type="MaterialDesign" class="d-inline align-center" size="sm" *ngIf="!panel.expanded"></app-icon>
      </div>
    </mat-expansion-panel-header>

    <ng-template #popover>
      <mat-nav-list class="nav-item__popover-container">
        <div (click)="popoverInstance.close()">
          <ng-container
            *ngFor="let submenu of menuItem.subMenu"
            [ngTemplateOutlet]="submenu.subMenu ?  menu : item"
            [ngTemplateOutletContext]="{menuItem:submenu}"
          ></ng-container>
        </div>
      </mat-nav-list>
    </ng-template>

    <mat-nav-list *ngIf="!collapsed">
      <ng-container
        *ngFor="let submenu of menuItem.subMenu"
        [ngTemplateOutlet]="submenu.subMenu ?  menu : item"
        [ngTemplateOutletContext]="{menuItem:submenu}"
      ></ng-container>
    </mat-nav-list>
  </mat-expansion-panel>
  <mat-divider *ngIf="divider && menuItem.isVisible"></mat-divider>
</ng-template>
