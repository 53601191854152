import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@shared/services/layout.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends BaseChartComponent {

constructor(translate: TranslateService, layoutService: LayoutService, changeDetector: ChangeDetectorRef) {
    super(translate, layoutService, changeDetector);
    this.setupUI();
    this.type = 'pie';
  }

  private setupUI(){
    this.chartPlugins.push({
      afterDraw: (chart) => {
        if (!chart.data.datasets[0].data.length) {
          var ctx = chart.chart.ctx;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "18px Arial";
          ctx.fillStyle = "gray";
          ctx.fillText(this.translationsList.Charts.NoDataAvailableYet, chart.chart.width / 2, chart.chart.height / 2);
          ctx.restore();
        }
      }
    });

    this.chartOptions.scales = null;
    this.render = true;
  }
}
