<div class="container" [class.rtl]="layoutService.websiteDirection$.value === 'rtl'">
  <div class="info">
    <app-icon [name]="data?.icon " type="MaterialDesign" class="icon"></app-icon>
    <div class="flex column justify-flex-start align-items-start">
      <span>{{ data?.message }}</span>
      <a target="_blank" *ngIf="data?.url" [href]="data?.url">{{ data?.urlText ? data?.urlText : data?.url }}</a>
    </div>
  </div>
  <div class="close-button">
    <app-icon name="close" type="MaterialDesign" class="icon" (click)="snackBarRef.dismiss()"></app-icon>
  </div>
</div>
