import { Component, EventEmitter, forwardRef, Input, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownModel } from '@shared-features/models/dropdown-model';

import { ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { DropdownBloc } from '../../bloc/dropdown-bloc/dropdown-bloc';
import { DropDownCallBack, DropdownLoadEvent } from '../../bloc/dropdown-bloc/dropdown-event';
import { DropdownReadyState, DropdownState } from '../../bloc/dropdown-bloc/dropdown-state';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { SharedConstants } from '@shared/model/shared-constants';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppDropdownComponent),
      multi: true,
    },
    DropdownBloc,
  ],
})
export class AppDropdownComponent implements ControlValueAccessor {
  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.touched = true;
    this.onChanged(this._value);
    this.onTouched();
    this.changeEvent.emit(this.value);
  }

  constructor(public dropdownBloc: DropdownBloc) {
    this.dropdownBloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
    this.form = new UntypedFormGroup({
      dropdown: new UntypedFormControl(null),
      searchTerm: new UntypedFormControl(null),
    });

    this.form.controls.searchTerm?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.filterList(value);
    });
  }
  @Output() changeEvent = new EventEmitter<any>();
  @Output() listChanged = new EventEmitter<any>();
  @Input() title: string;
  @Input() placeholder: string;
  @Input() caller: DropDownCallBack<any>;
  @Input() list: DropdownModel[];
  @Input() disabled = false;
  @Input() selectFirstByDefault = false;
  @Input() isRequired: boolean;

  @Input() prefixText: String;
  @Input() multipleMode: boolean = false;
  @Input() includeALLOption: boolean = false;
  @Input() selectAllByDefault = false;
  allSelected = false;
  filteredOptions: DropdownModel[];
  @Output() focusEvent = new EventEmitter<any>();

  filteredOptions$: BehaviorSubject<DropdownModel[]> = new BehaviorSubject<DropdownModel[]>([]);

  form: UntypedFormGroup;
  touched = false;
  _disabled = false;
  _list: DropdownModel[] = [];

  isLoading = false;

  private _value: any;
  private destroy$ = new Subject();

  onChanged: any = () => {};
  onTouched: any = () => {};

  SharedConstants = SharedConstants;

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && changes['disabled'].currentValue) {
      this._disabled = changes['disabled'].currentValue;
      if (this._disabled) {
        this.form.get('dropdown').disable();
      } else {
        this.form.get('dropdown').enable();
      }
    }
    if (changes['list'] && changes['list'].currentValue) {
      this._list = changes['list'].currentValue.sort((a, b) =>
        a.dropDownDisplayName.toLowerCase().localeCompare(b.dropDownDisplayName.toLowerCase())
      );
      this.listChanged.emit(this._list);
      this.filterList(this.form.controls.searchTerm.value);

      if (changes['selectFirstByDefault'] && changes['selectFirstByDefault'].currentValue) {
        this.value = this._list[0];
        this.form.get('dropdown').setValue(this._list[0]);
      }

      if (changes['selectAllByDefault'] && changes['selectAllByDefault'].currentValue) {
        if (this.multipleMode) {
          Promise.resolve().then(() => {
            if (typeof this.value === 'undefined' || this.value === null || (Array.isArray(this.value) && !this.value.length)) {
              this.value = [...this.filteredOptions];
              this.form.get('dropdown').patchValue([...this.filteredOptions]);
              this.checkALLOptionSelection();
            }
          });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  filterList(value) {
    if (!value) {
      this.filteredOptions = [...this._list];
      return;
    }
    this.filteredOptions = this._list.filter((item) => item.dropDownDisplayName.toLowerCase().includes(value.toLowerCase()));
  }

  // Dropdown Callbacks
  selectionChange(item: MatSelectChange) {
    this.value = item?.value;
    this.checkALLOptionSelection();
  }

  checkALLOptionSelection() {
    if (
      this.multipleMode &&
      this.form.controls.dropdown.value &&
      this.form.controls.dropdown.value.length === this.filteredOptions.length
    ) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  // ControlValueAccessor Overrides
  writeValue(value: any): void {
    // console.error(value);
    this._value = value;
    this.form.get('dropdown').setValue(this._value);
    if (this.multipleMode && this.selectAllByDefault) {
      this.checkALLOptionSelection();
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  openedChange() {
    this.markAsTouched();
  }

  setDisabledState(disabled: boolean) {
    this._disabled = disabled;
    if (this._disabled) {
      this.form.get('dropdown').disable();
    } else {
      this.form.get('dropdown').enable();
    }
    this.fetchData();
  }

  // Privates
  fetchData() {
    if (!this._disabled && this.caller) {
      this.dropdownBloc.setEvent.next(new DropdownLoadEvent(this.caller));
    } else {
      if (this.caller) {
        this._list = [];
        //this.listChanged.emit(this._list);
      }
    }
  }
  private handleBlocStates(state: DropdownState) {
    if (!state) {
      return;
    }
    if (state instanceof ProcessingState) {
      this.isLoading = true;
    } else if (state instanceof DropdownReadyState) {
      this.isLoading = false;
      this._list = state.list.sort((a, b) => a.dropDownDisplayName.toLowerCase().localeCompare(b.dropDownDisplayName.toLowerCase()));
      this.filteredOptions = [...this._list];
      this.listChanged.emit(this._list);
      if (this.selectFirstByDefault) {
        const value = this.multipleMode ? [this._list[0]] : this._list[0];
        this.form.get('dropdown').setValue(value);
        this.value = value;
      }

      if (this.multipleMode && this.selectAllByDefault) {
        this.value = [...this.filteredOptions];
        this.form.controls.dropdown.patchValue([...this.filteredOptions]);
        this.checkALLOptionSelection();
      }

      if (this._value && !this.multipleMode) {
        const value = this._list.find((item) => item.dropDownValue === this._value || item['id'] === this._value['id']);
        this._value = value;
        this.form.get('dropdown').setValue(this._value);
      } else if (this._value && this._value.length) {
        const values = this._list.filter((item: any) => {
          return this._value.find((i) => i === item.dropDownValue || item.id === i.id);
        });
        this._value = values;
        this.form.get('dropdown').setValue(this._value);
      }
    } else if (state instanceof ErrorState) {
      this.isLoading = false;
    }
  }

  isIndeterminate() {
    return (
      this.form.controls.dropdown.value &&
      this.filteredOptions.length &&
      this.form.controls.dropdown.value.length < this.filteredOptions.length
    );
  }

  onToggleSelection(change: MatCheckboxChange): void {
      const allOptions = [...this._list];
      const selectedOptions = change.checked ? [...this.filteredOptions] : [];
      this.value = (selectedOptions.length === allOptions.length) ? selectedOptions : [...selectedOptions];
      this.form.controls.dropdown.setValue(this.value);
  }
}
