<div class="search-bar">
  <form [formGroup]="form">
    <app-row>
      <app-col cols="12">
        <mat-form-field class="w-100" appearance="outline">
          <input (keydown.enter)="search()" formControlName="searchTerm" matInput type="search" [placeholder]="placeholder" />
          <div matSuffix class="search-bar__icon d-inline-block mb-2">
            <button mat-icon-button [disabled]="disabled">
              <app-icon name="search" type="MaterialDesign" (click)="search()"></app-icon>
            </button>
            <button mat-icon-button [disabled]="disabled">
              <app-icon name="close" type="MaterialDesign" (click)="clear()"></app-icon>
            </button>
          </div>
        </mat-form-field>
      </app-col>
    </app-row>
  </form>
</div>
