import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { Fleet } from './fleet';

export class FleetDetails extends Model {
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();

  @Expose() totalContractsCount: number;
  @Expose() totalDriversCount: number;
  @Expose() totalTrucksCount: number;
  @Expose() totalTrailersCount: number;
  @Expose() totalUsersCount: number;
  @Expose() totalRentedTrucksCount: number;
}
