import { FormArray, FormGroup, Validators } from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FieldControl } from '@shared/interfaces/field-control.interface';
import { Field, FieldElement } from '@shared/interfaces/field.interface';
import { FieldElementType } from '@shared/enums/field-element-type.enum';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ColorPalette } from '@shared/enums/color-palette.enum';
import { IconComponent } from '@shared/components/icon/icon.component';
import { IconType } from '@shared/enums/icon-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({ template: '' })
export abstract class FieldBase implements FieldControl, OnChanges, AfterViewInit, OnDestroy {
  abstract field: Field & { name: string };
  abstract group: FormGroup | FormArray;
  abstract element: ElementRef<HTMLElement>;

  @Input() theme: 'outlined' | 'highlighted' | 'unbounded' = 'outlined';
  @Input() color: ColorPalette = ColorPalette.Primary;
  @Input() size: 'sm' | 'md' = 'md';

  @Output() prefixClick = new EventEmitter<MouseEvent>();
  @Output() suffixClick = new EventEmitter<MouseEvent>();
  @Output() fieldClick = new EventEmitter<MouseEvent>();
  @Output() change = new EventEmitter();

  @ViewChild('prefixRef', { read: ViewContainerRef }) prefixRef: ViewContainerRef;
  @ViewChild('suffixRef', { read: ViewContainerRef }) suffixRef: ViewContainerRef;

  uuid = Date.now().toString(36) + Math.random().toString(36).substr(2);
  errors: Record<string, any>[] = [];
  protected translateService = inject(TranslateService);

  protected destroy$ = new Subject();
  protected changeDetectorRef = inject(ChangeDetectorRef);

  private parsingErrorsSubscription;

  protected get isRequired() {
    return this.field.validators && this.field.validators.includes(Validators.required);
  }

  ngOnChanges({ field }: SimpleChanges): void {
    if (field?.currentValue !== field?.previousValue) {
      this.bindAttrs();
      this.parseErrors();
    }
  }

  ngAfterViewInit(): void {
    this.bindAttrs();
    this.handleExtraElements();
    this.parseErrors();
    this.group
      .get(this.field.name)
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.change.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected bindAttrs(): void {
    const element = this.element?.nativeElement;
    const attrs = this.field.attrs || {};

    if (!element) {
      return;
    }

    Object.keys(attrs).forEach((key) => {
      element.setAttribute(key, attrs[key]);
    });
    this.changeDetectorRef.detectChanges();
  }

  handleExtraElements(): void {
    if (this.prefixRef && this.field.prefix) {
      this.handleExtraElement(this.prefixRef, this.field.prefix);
    }

    if (this.suffixRef && this.field.suffix) {
      this.handleExtraElement(this.suffixRef, this.field.suffix);
    }
  }

  protected handleExtraElement(containerRef: ViewContainerRef, element: FieldElement): void {
    containerRef.clear();
    switch (element.type) {
      case FieldElementType.Icon:
        const matIconComponent = containerRef.createComponent(IconComponent);
        matIconComponent.instance.type = IconType.MaterialDesign;
        matIconComponent.instance.name = element.value;
        Object.keys(element.inputs || {}).forEach((key) => {
          matIconComponent.instance[key] = element.inputs[key];
        });
        break;
      case FieldElementType.SvgIcon:
        const iconComponent = containerRef.createComponent(IconComponent);
        iconComponent.instance.type = IconType.SVG;
        iconComponent.instance.name = element.value;
        Object.keys(element.inputs || {}).forEach((key) => {
          iconComponent.instance[key] = element.inputs[key];
        });
        break;

      case FieldElementType.Component:
        const component = containerRef.createComponent(element.value);
        Object.keys(element.inputs || {}).forEach((key) => {
          component.instance[key] = element.inputs[key];
        });
        break;

      case FieldElementType.Template:
        containerRef.createEmbeddedView(element.value, element.inputs);
        break;

      default:
        break;
    }

    this.changeDetectorRef.markForCheck();
  }

  protected parseErrors(): void {
    const control = this.group?.get(this.field?.name);
    if (!control) {
      return;
    }

    if (this.parsingErrorsSubscription) {
      this.parsingErrorsSubscription.unsubscribe();
    }

    this.parsingErrorsSubscription = control.valueChanges
      .pipe(takeUntil(this.destroy$), startWith(true))
      .subscribe((value) => {
        const errorsSrc = control?.errors || {};

        this.errors = Object.keys(errorsSrc).map((key) => {
          let projection = errorsSrc[key];
          if (projection && typeof projection !== 'object') {
            projection = { [key]: projection };
          }
          projection.field = Number.isNaN(+this.field.name)
            ? this.translateService.instant(this.field.label)
            : '';
          return {
            key,
            projection,
          };
        });
        this.changeDetectorRef.markForCheck();
      });
  }
}
