import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { FeedbackModel, FeedbackType } from '../model/feedback';

@Injectable({
  providedIn: 'root',
})
export class AsyncFeedbackService {
  constructor(private snackBar: MatSnackBar) {}

  public showFeedback(feedback: FeedbackModel) {
    console.log(feedback);
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: feedback.message,
        icon:
          feedback.type === FeedbackType.Success
            ? 'check_box'
            : feedback.type === FeedbackType.Failure
            ? 'error'
            : 'warning',
        url: feedback.url,
        urlText: feedback.urlText,
      },
      duration: feedback.duration ? feedback.duration : 5000,
      panelClass:
        feedback.type === FeedbackType.Failure
          ? ['snack-bar-error']
          : feedback.type === FeedbackType.Warning
          ? ['snack-bar-warring']
          : [],
    });
  }

  public showFailureMessage(message: string, url: string = null, urlText: string = null) {
    this.showFeedback(new FeedbackModel(FeedbackType.Failure, message, null, url, urlText));
  }

  public showSuccessMessage(message: string, url: string = null, urlText: string = null) {
    this.showFeedback(new FeedbackModel(FeedbackType.Success, message, null, url, urlText));
  }

  public showWarningMessage(message: string, url: string = null, urlText: string = null) {
    this.showFeedback(new FeedbackModel(FeedbackType.Warning, message, null, url, urlText));
  }
}
