import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-static-section',
  templateUrl: './static-section.component.html',
  styleUrls: ['./static-section.component.scss']
})
export class StaticSectionComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() imagePath: string;
  @Input() showButton = false;
  @Input() buttonUrl: string;
  @Input() buttonText: string;

  constructor() { }

  ngOnInit(): void {
  }

}
