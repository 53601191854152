import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { Driver } from './driver';

export class DriverDetails extends Model {
  @Type(() => Driver)
  @Expose()
  drivers: Driver[];
  @Expose() count: number;
}
