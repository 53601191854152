import { Model } from './model';
import { Expose } from 'class-transformer';

export class Media extends Model {
  @Expose() id: number = null;
  @Expose() url = '';
  @Expose() mimetype = '';
  @Expose() type = '';
  @Expose() createdAt: number;
  @Expose() updatedAt: number;

  constructor() {
    super();
  }
}
