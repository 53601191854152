import { Model } from '@shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { RentedTruckStatus } from '@features/fleets/models/rented-truck-status.model';

export class RentedTruckStatusHistory extends Model {
  @Expose() count: number;
  @Type(() => RentedTruckStatus)
  @Expose()
  list: RentedTruckStatus[];
}
