import { Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutService } from '@shared/services/layout.service';
import { ScreenType } from "@shared/enums/screen-type.enum";

@Pipe({
  name: 'responsive'
})
export class ResponsivePipe implements PipeTransform {
  currentMedia = null;
  private destroy$ = new Subject();
  constructor(private layoutService: LayoutService) {
    this.layoutService.currentMedia$.pipe(takeUntil(this.destroy$)).subscribe((media) => {
      this.currentMedia = media;
    });
  }

  transform(value: ScreenType): boolean {
    if (value === ScreenType.desktop) {
      if (this.currentMedia === ScreenType.desktop) {
        return true;
      }
    } else if (value === ScreenType.tablet) {
      if (this.currentMedia === ScreenType.tablet || this.currentMedia === ScreenType.phone) {
        return true;
      }
    } else if (value === ScreenType.phone) {
      if (this.currentMedia === ScreenType.phone) {
        return true;
      }
    }
    return false;
  }

  onDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.layoutService.dispose();
  }

}
