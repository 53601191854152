<div class="madar--v2">
  <mat-card class="aside-card scrollable scrollable--y" style="max-height: 80vh">
    <mat-card-content>
      <span class="small text-primary weight-normal">{{"shared.components.cardItemsList.PalletItems" | translate }}</span>
      <mat-divider class="my-2 aside-card__divider"></mat-divider>

      <div class="aside-card__item" *ngFor="let item of selectedPallet?.items; let last = last">
        <app-rect-tag [color]="ColorPalette.Primary">{{item?.genericNumber || '___'}}</app-rect-tag>
        <div class="aside-card__item-title">{{item?.itemDescription || '___'}}</div>
        <div class="flex justify-space-between">
          <div class="aside-card__item-desc">{{"shared.components.cardItemsList.Qty" | translate }}: {{item?.quantity || '___'}}</div>
          <div class="aside-card__item-desc">{{"shared.components.cardItemsList.Batch" | translate }}: {{selectedPallet?.batch || '___'}}</div>
        </div>
        <div class="flex justify-space-between">
          <div class="aside-card__item-desc text-success">
            {{"shared.components.cardItemsList.Accepted" | translate }}: {{item?.acceptedQuantity || '0'}}
          </div>
          <div class="aside-card__item-desc text-warn">
            {{"shared.components.cardItemsList.Returned" | translate }}: {{item?.rejectedQuantity || '0'}}
          </div>
        </div>
        <mat-divider *ngIf="!last" class="my-2 aside-card__divider"></mat-divider>
      </div>
    </mat-card-content>
  </mat-card>
</div>
