export enum FieldType {
  Input = 'INPUT',
  TextArea = 'TEXTAREA',
  Dropdown = 'DROPDOWN',
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
  File = 'FILE',
  Custom = 'CUSTOM',

  // For utils only
  Head = 'HEAD',
  Separator = 'SEPARATOR',
}
