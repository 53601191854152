<div class="sidenav-container h-100" [class.sidenav-container__basic]="openMenu">
  <div></div>

  <div class="sidenav-container__btns" [class.collapsed]="collapsed" *ngIf="!openMenu" [class.create-btn]="viewCardBtn">
    <div class="sidenav-container__bg" [class.collapsed]="collapsed && viewCardBtn"></div>
    <div class="sidenav-container__collapse-btn" [class.collapsed]="collapsed" (click)="handlecollapse()">
      <app-icon *ngIf="collapsed" class="d-inline" size="sm" name="arrow-side-left"></app-icon>
      <app-icon *ngIf="!collapsed" class="d-inline align-center" size="sm" name="arrow-side-right"></app-icon>
    </div>

    <div class="sidenav-container__btn-card" [matMenuTriggerFor]="menu" [class.collapsed]="collapsed" *grantAccess="RolesConstants.SHIPMENT_ADD">
      <app-icon class="align-center justify-center" name="box-add"></app-icon>

      <span class="flex align-center mt-4" *ngIf="!collapsed">
        <span class="unified-line-height">{{'Enum.RequestHistoryAction.CREATE_SHIPMENT' | translate}}</span>
        <app-icon name="arrow_drop_down" type="MaterialDesign" class="d-inline icon icon--small align-center"></app-icon>
      </span>

      <mat-menu #menu="matMenu" xPosition="before" class="w-100">
        <ng-container *ngFor="let template of ShipmentTemplateTypes | enumToArray">
          <button mat-menu-item (click)="navigateToTemplate(ShipmentTemplateTypes[template.key])" class="w-100">
            {{('Enum.ShipmentCreationTemplateTypes.' + template.value) | translate}}
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>

  <mat-nav-list class="sidenav-container__popover-container" *ngIf="openMenu">
    <mat-list-item
      [popoverTrigger]="avatarPopover"
      [closeOnClickOutside]="true"
      [position]="position"
      [width]="'auto'"
      [backDrop]="false"
      (click)="showArrow = !showArrow"
    >
      <div class="flex justify-space-between align-center w-100">
        <div class="flex align-center" *ngIf="!showArrow">
          <img class="mr-2" src="../../../../assets/images/avatar.med.svg" />
          <div class="flex column m">
            <span class="sidenav-container__avatar-title">{{userFirstName}} {{userLastName}}</span>
            <span class="sidenav-container__avatar-subtitle">{{userEmail}}</span>
          </div>
        </div>
        <app-icon name="arrow_forward" type="MaterialDesign" class="d-inline align-center" *ngIf="!showArrow"></app-icon>
        <app-icon name="arrow_back" type="MaterialDesign" class="d-inline align-center" *ngIf="showArrow"></app-icon>
      </div>
    </mat-list-item>
  </mat-nav-list>

  <app-nav-list
    [menuList]="items"
    [color]="openMenu ? 'basic' : 'primary'"
    [collapsed]="collapsed"
    [divider]="openMenu"
    (click)="closeMenu()"
    #navList
  ></app-nav-list>
</div>

<ng-template #avatarPopover>
  <mat-nav-list class="popover-container">
    <app-avatar-Menu (click)="closeMenu();showArrow = !showArrow"></app-avatar-Menu>
  </mat-nav-list>
</ng-template>
