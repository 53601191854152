import { Shipment } from '@features/shipments/models/shipment';
import { Model } from '../shared-features/models/model';
import { Expose } from 'class-transformer';

export class TransportationPaymentDetails extends Model {
  @Expose()
  count: number;

  @Expose()
  totalCount: number;

  @Expose()
  pendingCount: number;

  @Expose()
  rejectedCount: number;

  @Expose()
  paidCount: number;

  @Expose()
  shipments: Shipment[];
}
