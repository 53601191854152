import { Component, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Output() searchTerm = new EventEmitter();
  @Input() placeholder: string;
  @Input() disabled = false;
  form = this.fb.group({
    searchTerm: [''],
  });

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {}

  ngOnChanges({ disabled }: SimpleChanges): void {
    if (disabled?.currentValue !== disabled?.previousValue) {
      if (this.disabled) {
        this.form?.get('searchTerm').disable();
      } else {
        this.form?.get('searchTerm').enable();
      }
    }
  }

  search() {
    this.searchTerm.emit(this.form.controls.searchTerm.value);
  }

  clear() {
    this.form.controls.searchTerm.setValue('');
    this.searchTerm.emit(this.form.controls.searchTerm.value);
  }
}
