import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ShipmentCreationTemplateTypes } from "@features/shipment-creation-template/enums/shipment-creation-template-types.enum";
import { Router } from '@angular/router';
import { ShipmentTemplateService } from '@features/shipment-creation-template/services/shipment-template.service';

@Component({
  selector: 'app-add-shipment-button',
  templateUrl: './add-shipment-button.component.html',
  styleUrls: ['./add-shipment-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddShipmentButtonComponent {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary';

  ShipmentTemplateTypes = ShipmentCreationTemplateTypes;

  constructor(private shipmentTemplateService: ShipmentTemplateService, private router: Router) {}

  navigateToTemplate(template: ShipmentCreationTemplateTypes): void {
    this.router.navigateByUrl(this.shipmentTemplateService.getCreateUrl(template));
  }
}
