import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { RolesConstants } from '@shared/model/roles-constants';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { ShipmentCreationTemplateTypes } from '@features/shipment-creation-template/enums/shipment-creation-template-types.enum';
import { UserRole } from '@features/users/enums/user-role.enum';
import { ShipmentTemplateService } from '@features/shipment-creation-template/services/shipment-template.service';
import { fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent extends BaseComponent implements OnInit {
  @Input() items: NavBarItem[];
  @Input() collapsed = false;
  @Input() openMenu = false;
  @Output() changeOpenMenu = new EventEmitter<boolean>();
  @Output() navbarCollapse = new EventEmitter<boolean>(this.collapsed);
  @ViewChild('navList') navList;

  public userFirstName = '';
  public userLastName = '';
  public userEmail = '';
  ShipmentTemplateTypes = ShipmentCreationTemplateTypes;
  position: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    panelClass: 'full-Width',
  };
  showArrow = false;

  RolesConstants = RolesConstants;
  viewCardBtn: boolean = false;

  constructor(
    private router: Router,
    private shipmentTemplateService: ShipmentTemplateService,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userFirstName = this.authService.currentUser$.value?.firstName;
    this.userLastName = this.authService.currentUser$.value?.lastName;
    this.userEmail = this.authService.currentUser$.value?.email;
    this.viewCardBtn = RolesConstants.SHIPMENT_ADD.includes(
      this.authService.currentUser$.value.roles[0].userRole as any
    );
  }
  resize$ = fromEvent(window, 'resize')
    .pipe(debounceTime(250), takeUntil(this.destroy$))
    .subscribe(() => {
      if (window.innerWidth < 960) {
        this.navbarCollapse.emit(false);
      } else {
        this.changeOpenMenu.emit(false);
      }
    });

  navigateToTemplate(template: ShipmentCreationTemplateTypes): void {
    this.router.navigateByUrl(this.shipmentTemplateService.getCreateUrl(template));
  }

  closeMenu(): void {
    if (this.openMenu) {
      this.changeOpenMenu.emit(false);
    }
  }

  handlecollapse(): void {
    this.collapsed = !this.collapsed;
    this.navbarCollapse.emit(this.collapsed);
    this.navList.handleOpenPopover(null);
  }
}
