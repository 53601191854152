import { Expose, Type } from 'class-transformer';
import { User } from './user';

export class UserLocation {
  @Expose()
  id: number;

  @Expose()
  isDefault: boolean;

  @Expose()
  locationId: number;

  @Expose()
  @Type(() => User)
  user: User;

  @Expose()
  userId: number;
}
