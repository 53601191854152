import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { GridBase } from '@shared/components/grid/grid.base';

export declare type Alignment = 'stretch'|'center'|'flex-start'|'flex-end'|'baseline';

export declare type Justification = 'flex-start'|'flex-end'|'center'|'space-between'|'space-around'|'space-evenly';

@Component({
  selector: 'app-row',
  template: `<ng-content></ng-content>`,
  styles: [`
    :host[align]:not(.text-center) {
      text-align: initial;
    }

    :host.column {
      display: flex;
      flex-direction: column;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowComponent extends GridBase {
  @Input() direction: 'row'|'column' = 'row';
  @Input() align: Alignment;
  @Input() justify: Justification;
  @Input() gutter: 'xs'|'sm'|'lg'|'x-xs'|'x-sm'|'x-lg'|'y-xs'|'y-sm'|'y-lg';

  constructor(protected changeDetector: ChangeDetectorRef) {
    super();
  }

  calculateClassList(): void {
    const row = this.direction || 'row';
    const align = this.align ? `align-${this.align}` : '';
    const justify = this.justify ? `justify-${this.justify}` : '';
    const [size, dir] = this.gutter ? this.gutter.split('-').reverse() : [];
    const gutter = dir ? `g${dir}-${size}` : `g-${size}`;

    this.classList$.next([row, align, justify, gutter].filter(c => !!c));
    // this.changeDetector.markForCheck();
  }
}
