import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { map } from 'rxjs/operators';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import {
  ICreateTransportPaymentPayload,
  IExportTransportPaymentPayload, IGetFleetsWithPayments, IGetPaymentDetailsPayload,
  IUpdateTransportationPaymentPayload,
} from '../interfaces/transportation-payment-payload.interface';
import { ApiConstant } from '@shared/constants/api.constant';
import { FleetHasPaymentsDetails } from '@features/fleets/models/fleet-has-payments-details.model';
import { TransportationPaymentsStatus } from "@features/transportation-payments/enums/transportation-payments-status.enum";
import { TransportationPaymentDetails } from '@features/invoices/transportation-payment-details';
import { ExportedFile } from '@shared-features/models/exported-file';

@Injectable({
  providedIn: 'root'
})
export class TransportationPaymentsApiService {

  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}


  createTransportationPayment(payload: ICreateTransportPaymentPayload): Observable<string> {
    return this.baseAPI.put(ApiConstant.CREATE_TRANSPORTATION_PAYMENT, filterNullEntity<typeof payload>(payload)).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res.data);
      })
    );
  }

  updateTransportationPayment(payload: IUpdateTransportationPaymentPayload): Observable<string> {
    return this.baseAPI.put(ApiConstant.UPDATE_TRANSPORTATION_PAYMENT, filterNullEntity<typeof payload>(payload)).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res.data);
      })
    );
  }

  exportTransportationPayment(payload: IExportTransportPaymentPayload): Observable<ExportedFile> {
    return this.baseAPI.get(ApiConstant.EXPORT_TRANSPORTATION_PAYMENT, { params: filterNullEntity<typeof payload>(payload) }).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  getPaymentDetails(payload: IGetPaymentDetailsPayload): Observable<TransportationPaymentDetails> {
    return this.baseAPI.get(ApiConstant.GET_TRANSPORTATION_PAYMENTS, { params: filterNullEntity<typeof payload>(payload) }).pipe(
      map((res) => {
        return this.mapper.fromJson(TransportationPaymentDetails, res.data);
      })
    );
  }

  deleteShipmentFromTransportationInvoice(payload: { shipmentIds: number[]; invoiceId: number; }): Observable<string> {
    return this.baseAPI.put(ApiConstant.DELETE_SHIPMENT_FROM_TRANSPORTATION_INVOICE, filterNullEntity<typeof payload>(payload))
      .pipe(map((res) => this.mapper.fromJson(String, res.data)));
  }

  getActiveFleetsWithPayments(payload: IGetFleetsWithPayments = {}): Observable<FleetHasPaymentsDetails> {
    return this.baseAPI.get(ApiConstant.GET_ACTIVE_FLEETS_WITH_PAYMENTS, { params: filterNullEntity<typeof payload>(payload) } )
      .pipe(map((res) => this.mapper.fromJson(FleetHasPaymentsDetails, res.data)));
  }

  exportShipmentsPayment(payload: IGetPaymentDetailsPayload): Observable<ExportedFile> {
    return this.baseAPI.get(ApiConstant.EXPORT_SHIPMENTS_PAYMENT, { params: filterNullEntity<typeof payload>(payload) }).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }

  exportPayments(paymentStatus: TransportationPaymentsStatus): Observable<ExportedFile> {
    return this.baseAPI.get(ApiConstant.EXPORT_TRANSPORTATION_PAYMENTS, { params: filterNullEntity({ paymentStatus }) }).pipe(
      map((res) => {
        return this.mapper.fromJson(ExportedFile, res.data);
      })
    );
  }
}
