import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConstant } from '@shared/constants/api.constant';
import { Mapper } from '@core/services/mapper';
import { ApiBaseService } from '@core/services/api-base.service';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { TruckType } from '@features/fleets/models/truck-type';

@Injectable({
  providedIn: 'root',
})
export class TruckTypesApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getAllTruckTypes(): Observable<TruckType[]> {
    return this.baseAPI.get(ApiConstant.TRUCK_GET_ACTIVE).pipe(
      map((res) => {
        return this.mapper.fromJson(TruckType, res.data);
      })
    );
  }

  getActiveTruckTypesContractCustomer(customerId: number): Observable<TruckType[]> {
    return this.baseAPI.get(
      ApiConstant.GET_ACTIVE_TRUCK_TYPES_CONTRACT_CUSTOMER,
      { params: filterNullEntity({ customerId })
      }).pipe(map((res) => this.mapper.fromJson(TruckType, res.data)));
  }
}
