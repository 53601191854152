import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderAPIService } from '@features/orders/services/order-api.service';
import { Order } from '@features/orders/models/order.model';
import { Pallet } from '@features/orders/models/pallet.model';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { ColorPalette } from '@shared/enums/color-palette.enum';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DefinitionOrderTemperatureControl } from '@features/orders/enums/definition-order-temperature-control.enum';
import { OrderTypeShortcuts } from '@features/orders/enums/order-types-shortcuts.enum';

@Component({
  selector: 'app-orders-according',
  templateUrl: './orders-according.component.html',
  styleUrls: ['./orders-according.component.scss'],
})
export class OrdersAccordingComponent extends BaseComponent {
  @Input() orders;
  @Input() pallets;
  @Input() isTimeLineStyle: false;
  @Input() shipmentId;
  @Input() palletsNo;
  @Input() itemsNo;
  @Input() ordersNo;
  @Output() onAddOrderClick = new EventEmitter();
  @Output() onAddAllOrder = new EventEmitter();
  protected readonly ColorPalette = ColorPalette;
  protected readonly definitionOrderTemperatureControl = DefinitionOrderTemperatureControl;
  protected readonly OrderTypeShortcuts = OrderTypeShortcuts;

  selectedRowIndex: number | null = null;
  selectedOrder: Order;
  selectedPallet: Pallet;

  position: ConnectedPosition = {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private orderAPIService: OrderAPIService
  ) {
    super();
  }

  getPallets(order: Order) {
    this.selectedPallet = null;
    if (!order.pallets?.length) {
      this.load(
        this.orderAPIService.getPalletList({
          orderId: order.id,
          isForCreation: !this.isTimeLineStyle,
          shipmentId: this.shipmentId,
        })
      ).subscribe((pallets) => {
        order.pallets = pallets;
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  selectPallet(pallet: Pallet) {
    this.selectedPallet = pallet;
  }
  handleAddOrderClick(event: MouseEvent, order) {
    event.preventDefault();
    event.stopPropagation();

    this.onAddOrderClick.emit({ order: order.order });
    this.selectedPallet = null;
  }
}
