import { Model } from '@shared-features/models/model';
import { Receiver } from '@features/receivers/models/receiver';
import { Expose, Type } from 'class-transformer';

export class Receivers extends Model {
  @Type(() => Receiver)
  @Expose()
  receivers: Receiver[];

  @Expose()
  count: number;
}
