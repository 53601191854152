import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { LanguageService } from '@shared/services/language.service';

@Injectable({
  providedIn: 'root',
})
export class AppNavigationService {
  // tslint:disable-next-line:variable-name
  private _previousUrl: string;

  public get previousUrl(): string {
    return this._previousUrl;
  }

  constructor(private router: Router, private languageService: LanguageService) {
    this.router.events
    .pipe(
      filter((e) => e instanceof RoutesRecognized),
      pairwise()
      )
      .subscribe((event: any[]) => {
        this.languageService.delegate = null;
        if (event[0].urlAfterRedirects === '/404') {
          this._previousUrl = null;
        } else {
          this._previousUrl = event[0].urlAfterRedirects;
        }
      });
  }
}
