<app-state-section [state]="sectionState">
  <app-shipment-list-filter-form [form]="form">
    <div [formGroup]="form" extra-input>
      <mat-form-field>
        <input matInput [placeholder]="'shipments.shipmentListFilter.nameYouFilter' | translate" formControlName="filterName" />
        <mat-error *ngIf="form.get('filterName')?.touched">{{"ErrorMessages.Required" | translate}}</mat-error>
      </mat-form-field>
      <mat-divider class="pos-static my-3"></mat-divider>
    </div>
  </app-shipment-list-filter-form>

  <app-row justify="flex-end" gutter="xs">
    <app-col cols="auto">
      <button mat-button color="primary" class="flex w-100 align-center" (click)="backToFilter()">
        <app-icon class="align-middle mr-2" size="sm" name="arrow-left"></app-icon>
        {{'shipments.shipmentListFilter.backToFilter' | translate}}
      </button>
    </app-col>

    <app-col cols="auto" lg="2">
      <button mat-button class="btn-secondary d-block w-100" (click)="submitForm()">
        {{ isCreateFilter ? ('shipments.shipmentListFilter.create' | translate ) : ('shipments.shipmentListFilter.modify' | translate)}}
      </button>
    </app-col>
  </app-row>
</app-state-section>
