<mat-drawer-container class="drawer-container" [hasBackdrop]="hasBackdrop" (backdropClick)="onCloseButtonClicked()">
  <mat-drawer
    class="drawer-content"
    [class.opened]="drawerOpened"
    [class.main-page-height]="applyMainPageHeight"
    [style.width.px]="drawerOpened && openDrawerWidth ? openDrawerWidth : null"
    #drawer
    [mode]="mode"
    [position]="position"
  >
    <div *ngIf="showCloseButton" id="drawer-close-button-container" class="close-button-container" [class.end]="position === DrawerPosition.Start">
      <button id="drawer-close-button" mat-icon-button (click)="onCloseButtonClicked()">
        <app-icon name="close" type="MaterialDesign" class="mat-icon-rtl-mirror"></app-icon>
      </button>
    </div>
    <ng-content select="[drawer-content]"></ng-content>
  </mat-drawer>
  <mat-drawer-content class="main-content">
    <ng-content select="[main-content]"></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
