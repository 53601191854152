import { Injectable } from '@angular/core';
import { UserType } from "@features/users/enums/user-type.enum";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import { ApiConstant } from '@shared/constants/api.constant';
import { ShippingType } from "@features/shipments/enums/shipping-type.enum";
import { OpsScore } from '@features/users/models/ops-score.model';
import { User } from '@features/users/models/user';

@Injectable({
  providedIn: 'root'
})
export class UsersCommonApiService {

  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getAllUsers(userType: UserType = UserType.ADMIN, id?: number, shippingType?: ShippingType): Observable<User[]> {
    const params = filterNullEntity({
      customerId: userType === UserType.CUSTOMER ? id?.toString() : null,
      fleetId: userType === UserType.FLEET ? id?.toString() : null,
      shippingType
    });

    return this.baseAPI.get(ApiConstant.GET_ALL_USERS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(User, res.data);
      })
    );
  }

  resetPassword(body:{
    id?: number,
    type?: UserType,
    password?: string,
  }): Observable<string> {
    return this.baseAPI.post(ApiConstant.RESET_PASSWORD, filterNullEntity(body))
  }

  setNewPassword(body:{
    id?: number,
    type?: UserType,
    newPassword?: string,
    currentPassword?: string,
    mobileNumber?: string,
  }): Observable<string> {
    return this.baseAPI.post(ApiConstant.SET_NEW_PASSWORD, filterNullEntity(body))
  }

  activateAndResetPassword(id: number, type: UserType): Observable<string> {
    return this.baseAPI.post(ApiConstant.ACTIVATE_AND_RESET_PASSWORD, { id, type });
  }

  getCurrentUserScore(date: string): Observable<OpsScore> {
    return this.baseAPI.get(ApiConstant.GET_OPS_SCORE, { params: { date } }).pipe(
      map((res) => {
        return this.mapper.fromJson(OpsScore, res.data);
      }));
  }
}
