import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { startWith, takeUntil } from 'rxjs/operators';
import { LanguageService } from '@shared/services/language.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export const DIALOG_PANEL_CLASS = 'dialog-layout__panel';

@Component({
  selector: 'app-dialog-layout',
  templateUrl: './dialog-layout.component.html',
  styleUrls: ['./dialog-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DialogLayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('closeButton') closeButton;
  @ViewChild('actions') actions;
  @HostBinding('class.dialog-layout') dialogLayoutClass = true;
  @Input() headerAlignment = 'center';
  protected enableExternalIcon = true;
  protected disableActions = true;
  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dialogRef: MatDialogRef<any>,
    private changeDetectorRef: ChangeDetectorRef,
    private languageService: LanguageService,
    protected translationService: TranslateService
  ) {}

  ngAfterViewInit(): void {
    // work around to avoid setting diriction of layout everytime we open the dialog
    this.translationService.onLangChange
      .pipe(takeUntil(this.destroy$), startWith(true))
      .subscribe(() => {
        const language = this.languageService.currentLanguage$.value;
        const dir = this.languageService.checkLanguageDirection(language.isoCode);
        const dialog = document.getElementById(this.dialogRef.id);
        dialog.setAttribute('dir', dir);
      });

    const enableExternalIcon = this.closeButton?.children?.length > 2;
    const disableActions = !this.actions?.nativeElement?.children.length;
    this.dialogRef?.addPanelClass(DIALOG_PANEL_CLASS);
    this.dialogRef?.addPanelClass('madar--v2');
    Promise.resolve().then(() => {
      this.enableExternalIcon = enableExternalIcon;
      this.disableActions = disableActions;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
