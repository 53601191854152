import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { NotificationType } from "@features/notifications/enums/notification-type.enum";
import { NotificationItem } from '@shared-features/models/notification-item';
import { SharedConstants } from '../model/shared-constants';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public notification$ = new Subject<NotificationItem>();
  public markNotification$ = new Subject<NotificationItem>();

  constructor(private router: Router) { }

  showNotification(notification: NotificationItem) {
    this.notification$.next(notification);
  }

  navigateToNotificationTarget(notification: NotificationItem) {
    if (notification.type === NotificationType.Shipment) {
      this.router.navigate([RoutesUtil.ShipmentsViewWithoutRange.url({ id: notification.elementId, type: ShipmentsListType.All })]);
    }
  }
}
