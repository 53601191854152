import { Component, OnInit, Output, EventEmitter, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core';
import { LayoutService } from '@shared/services/layout.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DrawerType } from "@shared/enums/drawer-type.enum";
import { AuthService } from '@core/services/auth.service';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { NotificationsApiService } from '@features/notifications/services/notifications-api.service';
import { ConfigConstant } from '@shared/constants/config.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() openMenu = false;

  @Output() openMenuChange = new EventEmitter<boolean>();
  @Output() drawerOpened = new EventEmitter();

  public unreadCount = 0;
  public userFirstName = '';
  public userLastName = '';
  public userEmail = '';
  ConfigConstant = ConfigConstant

  public navigationItems: NavBarItem[];
  sideMenuActive = false;
  position : ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  };

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private notificationApiService: NotificationsApiService,

  ) {
    super();
  }

  ngOnInit(): void {
    this.userFirstName = this.authService.currentUser$.value?.firstName;
    this.userLastName = this.authService.currentUser$.value?.lastName;
    this.userEmail = this.authService.currentUser$.value?.email;
    this.notificationApiService.getUnreadNotificationCount()
      .subscribe((count) => {
      this.unreadCount = count;
    });
  }

  onNotificationsButtonClicked(): void {
    this.unreadCount = 0;
    this.drawerOpened.emit({
      open: true,
      type: DrawerType.Notifications,
    });
  }

  openSideMenu() {
    this.openMenuChange.emit(true)
  }

  closeSideMenu() {
    this.openMenuChange.emit(false)
  }

  handelClick(){
    if(this.openMenu){
      this.closeSideMenu()
      this.openMenuChange.emit(false)
    }else{
      this.openSideMenu()
      this.openMenuChange.emit(true)
    }
  }
}
