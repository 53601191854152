<div class="time-field">
  <app-row align="center" gutter="x-xs" justify="center" *ngIf="form" [formGroup]="form">
    <app-col>
      <mat-form-field appearance="outline" class="w-100 d-block time-field__wrapper">
        <mat-select formControlName="hours" hideSingleSelectionIndicator>
          <mat-option *ngFor="let hr of hours" [value]="hr.padStart(2, '0')">{{hr.padStart(2, '0')}}</mat-option>
          <mat-option [value]="'00'">12</mat-option>
        </mat-select>
      </mat-form-field>
    </app-col>

    <app-col cols="auto"><span class="d-inline-block pb-6 weight-bold">:</span></app-col>

    <app-col>
      <mat-form-field appearance="outline" class="w-100 d-block time-field__wrapper">
        <mat-select formControlName="minutes" hideSingleSelectionIndicator>
          <mat-option *ngFor="let min of minsAndSecs" [value]="min.padStart(2, '0')">{{min.padStart(2, '0')}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-col>

    <app-col cols="auto">
      <mat-form-field appearance="outline" class="w-100 d-block time-field__wrapper">
        <mat-select formControlName="period" hideSingleSelectionIndicator>
          <mat-option [value]="0">{{'AM'}}</mat-option>
          <mat-option [value]="12">{{'PM'}}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-col>
  </app-row>

  <app-row class="time-field__message">
    <app-col cols="12" *ngIf="selectedValueInDisabledRanges">
      You cannot selected time in between the following times
      <span *ngFor="let range of filteredDisabledRanges" class="px-1">
        <span>({{range.from | date:'hh:mm a'}}</span>
        <span *ngIf="range.from.toDate().getTime() !== range.to.toDate().getTime()">
          <span class="d-inline-block px-1">-</span>
           {{range.to | date:'hh:mm a'}}
        </span>)
      </span>
    </app-col>

    <app-col cols="12">
      <ng-content select="[error], mat-error"></ng-content>
    </app-col>
  </app-row>
</div>
