import { UserType } from "@features/users/enums/user-type.enum";
import { MediaType } from "@shared/enums/media-type.enum";

export abstract class BlocEvent {
}

export class InitEvent<T> extends BlocEvent {
  constructor(public args?: T) {
    super();
  }
}

export class LoadEvent<T> extends BlocEvent {}

export class SubmitEvent<T> extends BlocEvent {
  constructor(public item: T) {
    super();
  }
}

export class ExportReportEvent<T> extends BlocEvent {
  constructor(public item: T) {
    super();
  }
}

export class SearchEvent extends BlocEvent {
  constructor(public phrase: string) {
    super();
  }
}

export class UploadMediaEvent extends BlocEvent {
  constructor(public file: File, public type: MediaType) {
    super();
  }
}

export class ActivateResetPasswordEvent extends BlocEvent {
  constructor(public id: number, public type: UserType) {
    super();
  }
}

