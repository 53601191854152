import { Component, Input, OnInit } from '@angular/core';
import { Status } from "@features/shipments/enums/status.enum";

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss'],
})
export class StatusLabelComponent implements OnInit {

  @Input() value: Status | 'Free'|'Busy'|'NotReady';
  status = Status;

  constructor() {}

  ngOnInit(): void {}
}
