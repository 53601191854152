<div class="file-pond">
  <file-pond
  #myPond
  [options]="pondOptions"
  (onaddfile)="pondHandleAddFile($event)"
  (onremovefile)="pondHandleRemoveFile($event)"
  (onactivatefile)="pondHandleActivateFile($event)"
  >
</file-pond>
</div>


