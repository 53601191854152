<div class="row gx-sm" [formGroup]="form" *ngIf="form">
  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <input
        #left
        matInput
        type="text"
        [placeholder]="'Placeholder.Left' | translate"
        formControlName="left"
        maxlength="1"
        (keyup)="moveCursorToNextField( language === Language.English ? 'middle' : null)"
      />
    </mat-form-field>
  </div>

  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <input
        #middle
        matInput
        type="text"
        [placeholder]="'Placeholder.Middle' | translate"
        formControlName="middle"
        maxlength="1"
        (keyup)="moveCursorToNextField(language === Language.English ? 'right' : 'left')"
      />
    </mat-form-field>
  </div>

  <div class="col-4">
    <mat-form-field appearance="outline" class="w-100">
      <input
        #right
        matInput
        type="text"
        [placeholder]="'Placeholder.Right' | translate"
        formControlName="right"
        maxlength="1"
        (keyup)="moveCursorToNextField( language === Language.English ? null : 'middle')"
      />
    </mat-form-field>
  </div>

  <div class="col-12"  style="margin: -.75rem -.75rem 0 0;">
    <ng-content select="mat-error, [error]"></ng-content>
  </div>

</div>
