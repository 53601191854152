import { Expose, Type } from 'class-transformer';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { LocationType } from '@shared/enums/location-type.enum';
import { City } from '../../shared-features/models/city';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { CityArea } from '../../shared-features/models/city-area.model';
import { ShippingType } from '@features/shipments/enums/shipping-type.enum';
import { AddLocationType } from '@features/customers/enums/add-location-type.enum';
import { UserLocation } from '../../users/models/user-location.model';
import { WCCRegion } from '../../shipment-creation-template/models/wcc-region.model';
import { WCCReceiver } from '../../shipment-creation-template/models/wcc-receiver.model';
import { GateDefinition } from '@shared/model/gate-definition.model';

export class LocationModel extends DropdownModel {
  @Expose() id?: number;
  @Expose() title = '';
  @Expose() cityId: number;
  @Type(() => City)
  @Expose()
  city?: City = new City();
  @Expose() address = '';
  @Expose() latitude: number = null;
  @Expose() longitude: number = null;
  @Expose() isDefault = false;
  @Expose() selected? = false;
  @Expose() type: LocationType = LocationType.Customer;
  @Expose() businessType?: BusinessType;
  @Expose() cityArea?: CityArea;
  @Expose()
  totalShipmentsCount?: number;

  @Expose()
  shippingType?: ShippingType;

  @Expose() reference?: string;

  @Expose()
  @Type(() => UserLocation)
  usersLocations?: UserLocation[];

  @Expose()
  @Type(() => WCCReceiver)
  wccReceiver?: WCCReceiver;

  @Expose()
  @Type(() => WCCRegion)
  wccRegion?: WCCRegion;

  locationType?: AddLocationType;

  @Expose()
  @Type(() => GateDefinition)
  gates?: GateDefinition[];

  get dropDownDisplayName(): string {
    return this.title + ' - ' + this.city.name;
  }

  get dropDownValue(): object {
    return this;
  }

  @Expose()
  get latlng(): { lat: number; lng: number } {
    return {
      lat: this.latitude,
      lng: this.longitude,
    };
  }
}
