import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment from 'moment';
import { FunctionUtil } from '@shared/utils/function.util';
import { default as _rollupMoment, Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';

export const DATE_INPUT_FORMATE = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AppDateInputComponent,
      multi: true,
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMATE },
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'date-field',
  },
})
export class AppDateInputComponent
  extends BaseComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('calendar') calendar: ElementRef;

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (
      this.isOpen &&
      !this.eRef.nativeElement.contains(event.target) &&
      !this.calendar.nativeElement?.contains(event.target) &&
      this.closeWhenClickOut &&
      !this.isYearSelected
    ) {
      this.close();
      this.isYearSelected = false;
    }
  }

  @Input() title: string;
  @Input() required = false;
  @Input('min') minDate = null;
  @Input('max') maxDate = null;
  @Input() disabled = false;
  @Input() enableEditing = false;
  @Input() enableTime = false;
  @Input() selectMonthAndYear = false;
  @Input() startView = 'month';
  @Input() closeWhenClickOut = true;
  @Input() placeHolder = 'Placeholder.DatePlaceholder';
  @Output() changeEvent = new EventEmitter<any>();
  value: Date;
  isOpen = false;
  isYearSelected = false

  onTouched: any = () => {};
  onChange: any = () => {};

  form: FormGroup = this.formBuilder.group({
    date: [],
    time: [],
  });

  constructor(private formBuilder: FormBuilder, private eRef: ElementRef) {
    super();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue) {
      this.form.disable();
    } else if (changes.disabled) {
      this.form.enable();
    }

    if (changes.required?.currentValue === true) {
      this.form.get('date').setValidators([Validators.required]);
      if (this.enableTime) {
        this.form.get('time').setValidators([Validators.required]);
      }
    }
  }

  dateSelected(event: any) {
    if (this.enableEditing && event.value) {
      this.form.get('date').setValue(FunctionUtil.convertDate(new Date(event.value)));
    } else {
      this.form.get('date').setValue(event.value);
    }
    this.changeEvent.emit(FunctionUtil.convertDate(new Date(event.value)));
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  writeValue(date: any): void {
    if (date) {
      this.form.setValue({ date, time: moment(date).format('HH:mm') });
      this.value = date;
    } else {
      this.form.setValue({ date: null, time: null });
      this.value = null;
    }
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.get('date').disable();
    } else {
      this.form.get('date').enable();
    }
  }

  onKeydown(event): void {
    if (event.key === 'Escape') {
      this.close();
    }
  }

  close(): void {
    // this.reset();
    this.isOpen = false;
  }

  updateValue(): void {
    this.value = this.parseFormValue();
    this.onChange(this.value);
    this.onTouched();
    this.isOpen = false;
  }

  parseFormValue(): Date {
    const { date, time } = this.form.value;
    const [hours, minutes] = time ? time.split(':') : [0, 0];
    const dateTime = moment(date);
    dateTime.hours(+hours);
    dateTime.minutes(+minutes);
    return dateTime.toDate();
  }

  yearSelected() {
    this.isYearSelected = true;
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const date = normalizedMonthAndYear.toDate();

    this.form.setValue({
      date,
      time: null,
    });

    this.updateValue();
  }
}
