import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { ConfigConstant } from '@shared/constants/config.constant';
import { AsyncFeedbackService } from '@shared/services/async-feedback.service';
import { FiltersBase } from '@shared/services/filters-base.service';
import { getValueByPath } from '@shared/utils/get-value-by-path.util';
import { ConflictError } from '@shared/bloc/errors';

type HandlerOptions = {
  startWith?: (...args) => any;
  fetchOnce?: boolean;
  isLoadingTransparent?: boolean;
  contentPath?: string;
  emptyContentCheck?: (value: any) => boolean;
  errorMessagePath?: string;
  successMessagePath?: string;
  existingEntityURL?: (id: number, type?: string) => string;
};

@Component({
  selector: 'app-base-component',
  template: ``,
})
export class BaseComponent<Filters = any> implements OnInit, OnDestroy {
  protected destroy$ = new Subject();
  protected translationsList: any = {};
  protected translationReady$ = new Subject();
  protected translationService: TranslateService;
  protected asyncFeedbackService: AsyncFeedbackService;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  /////////////////////////////////////////////////////////
  // @Todo: these pagination props should be removed once
  // filters prop is used on all pages instead.
  /////////////////////////////////////////////////////////
  public pageSize: number = ConfigConstant.PAGE_SIZE;
  public pageIndex: number = ConfigConstant.INIT_PAGE_INDEX;
  public offset: number = ConfigConstant.INIT_PAGE_OFFSET;
  public searchTerm: string;
  /////////////////////////////////////////////////////////
  public filters: FiltersBase<Filters, any>;
  public totalRowsCount = 0;
  protected injector: Injector;

  constructor(@Inject(String) protected translationArr: string[] = []) {
    this.injector = inject(Injector);
    this.translationArr = ['SuccessMessages', 'ErrorMessages', ...this.translationArr];

    this.translationService = this.injector.get<TranslateService>(TranslateService);
    this.asyncFeedbackService = this.injector.get<AsyncFeedbackService>(AsyncFeedbackService);

    this.translationService.onLangChange
      .pipe(
        takeUntil(this.destroy$),
        startWith(true),
        switchMap(() => this.translationService.get([...this.translationArr]))
      )
      .subscribe((translations) => {
        this.translationsList = translations;
        this.translationReady$.next(true);
      });

    this.filters = this.injector.get<FiltersBase<Filters, any>>(FiltersBase);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  protected translate(key: string, projection = {}): string {
    if (this.translationService) {
      return this.translationService.instant(key, projection);
    }
    return key;
  }

  showErrorMessage(
    error: { errors: string[] },
    errorMessagePath = 'ErrorMessages.ErrorHappened',
    extraText = '',
    url: any = null,
    urlText: string = null
  ): void {
    this.asyncFeedbackService.showFailureMessage(
      error?.errors
        ? error.errors[0]
        : getValueByPath<string>(this.translationsList, errorMessagePath) + ' ' + extraText,
      url,
      urlText ? getValueByPath<string>(this.translationsList, urlText) : ''
    );
  }

  showSuccessMessage(messagePath = 'SuccessMessages.ActionDoneSuccessfully'): void {
    this.asyncFeedbackService.showSuccessMessage(
      getValueByPath<string>(this.translationsList, messagePath)
    );
  }

  load<T>(
    observable: Observable<T> | ((...args) => Observable<T>),
    extra?: HandlerOptions
  ): Observable<T> {
    const observable$: Observable<T> = typeof observable === 'function' ? observable() : observable;
    const fetchOnce = extra && typeof extra.fetchOnce !== 'undefined' ? extra.fetchOnce : true;
    const contentPath =
      extra && typeof extra.contentPath !== 'undefined' ? extra.contentPath : null;
    const changeDetector = this.injector?.get(ChangeDetectorRef);

    this.sectionState =
      extra && extra.isLoadingTransparent
        ? SectionStateStatus.LoadingTransparent
        : SectionStateStatus.Loading;

    if (typeof extra?.startWith === 'function') {
      extra.startWith();
    }

    changeDetector?.markForCheck();

    return observable$.pipe(
      fetchOnce ? take(1) : takeUntil(this.destroy$),
      tap((data) => {
        Promise.resolve().then(() => {
          let isEmpty = false;
          if (contentPath) {
            const value = getValueByPath<any>(data, contentPath);
            if (extra && extra.emptyContentCheck) {
              isEmpty = extra.emptyContentCheck(value);
            } else {
              isEmpty =
                (Array.isArray(value) && !value.length) ||
                (typeof value === 'object' && !Object.keys(value).length) ||
                !value;
            }
          } else if (extra && extra.emptyContentCheck) {
            isEmpty = extra.emptyContentCheck(data);
          }
          this.sectionState = isEmpty ? SectionStateStatus.Empty : SectionStateStatus.Ready;
          if (extra?.successMessagePath) {
            this.showSuccessMessage(extra?.successMessagePath);
          }
          changeDetector?.markForCheck();
        });
      }),
      catchError((err) => {
        if (err && err instanceof ConflictError) {
          this.showErrorMessage(
            { errors: err.errors },
            extra?.errorMessagePath || 'ErrorMessages.ErrorHappened',
            '',
            extra?.existingEntityURL ? extra?.existingEntityURL(err.id) : '',
            'ErrorMessages.OpenTheExistingItem'
          );
        } else {
          this.showErrorMessage(err, extra?.errorMessagePath || 'ErrorMessages.ErrorHappened');
        }
        this.sectionState = SectionStateStatus.Ready;
        changeDetector?.markForCheck();
        return throwError(err);
      })
    );
  }
}
