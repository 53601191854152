import moment, { Moment } from 'moment';

export function timeToMoment(value: string): Moment {
  const [time, meridian] = value.split(' ');
  const [hour, minute] = time.split(':').map((v) => parseInt(v, 10));

  let hours = hour;

  if (meridian === 'pm' && hours !== 12) {
    hours += 12;
  }

  const date = moment();
  date.hours(hours);
  date.minutes(minute);
  date.seconds(0);
  date.milliseconds(0);
  return date;
}
