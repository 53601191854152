<div class="flex justify-space-between align-center radius-2 px-3 py-1 h6 mb-0" [style.border]="'1px dashed var(--color-gray-300)'">
  <div>
    <app-icon class="align-middle text-accent mr-2" size="sm" name="bold-password-check"></app-icon>
    <span>{{'shared.showPassword.Password' | translate}}</span>
    <span *ngIf="!showPassword">
      <span *ngFor="let _ of [].constructor(10)">&#x2022;</span>
    </span>
    <span *ngIf="showPassword">{{user?.tempPassword}}</span>
  </div>

  <div class="flex align-center small">
    <div class="mr-2" [ngClass]="{'text-400':!user?.isTempPasswordChanged , 'success':user?.isTempPasswordChanged}">
      (
      <app-icon class="align-middle mr-1" size="sm" name="bold-lock"></app-icon>
      <span *ngIf="user?.isTempPasswordChanged">{{'shared.showPassword.Changed' | translate}}</span>
      <span *ngIf="!user?.isTempPasswordChanged">{{'shared.showPassword.NotChangedYet' | translate}}</span>
      )
    </div>

    <button mat-button color="primary" (click)="showPassword = !showPassword" [disabled]="user?.isTempPasswordChanged">
      <span *ngIf="!showPassword">{{'shared.showPassword.ShowPassword' | translate}}</span>
      <span *ngIf="showPassword">{{'shared.showPassword.HiddenPassword' | translate}}</span>
    </button>
  </div>
</div>
