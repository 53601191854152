import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StorageConstant } from '@shared/constants/storage.constant';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { UserPreferencesService } from '@shared-features/services/user-preferences.service';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';
import { map, tap } from 'rxjs/operators';
import { ColumnOptionsState } from '@shared-features/models/column-options-state.model';

@Injectable({
  providedIn: 'root',
})
export class ColumnOptionsService {
  state$ = new Subject<ColumnOptionsState>();
  drawer$ = new Subject();
  currentStateId: string;

  private storage: {
    [k: string]: ColumnOptionsState;
  } = {};

  constructor(private userPreferencesService: UserPreferencesService) {
    this.storage = LocalStorageUtil.getItem(StorageConstant.COLUMN_OPTIONS_KEY) || {};
    this.getSavedStorage();
  }

  set state({ id, keys, state, preferences: newPreferences }: Partial<ColumnOptionsState>) {
    const currentColOptions = this.storage[id];
    const oldState = currentColOptions?.defaultState || state || [];
    const newState = state && state.length ? state : oldState;

    const oldKeys = currentColOptions?.keys;
    const newKeys = keys ? keys : oldKeys;
    const preferences = newPreferences || currentColOptions?.preferences || [];

    this.storage = {
      ...this.storage,
      [id]: { id, keys: newKeys, state: newState, defaultState: oldState, preferences },
    };

    LocalStorageUtil.setItem(StorageConstant.COLUMN_OPTIONS_KEY, this.storage);
    this.currentStateId = id;
    this.state$.next(this.storage[id]);
  }

  getStateByName(id: string): ColumnOptionsState {
    return this.storage[id];
  }

  toggleDrawer(shown?: boolean): void {
    this.drawer$.next(shown);
  }

  getSavedStorage(): void {
    this.userPreferencesService
      .getUserPreferences(UserPreferencesType.Columns)
      .pipe(
        map((res) =>
          this.userPreferencesService.mapJsonToModel<ColumnOptionsState[]>(ColumnOptionsState, res)
        )
      )
      .subscribe((storage) => {
        this.storage = Object.assign(
          this.storage,
          (storage || []).reduce((group, item) => {
            group[item.id] = item;
            return group;
          }, {})
        );
        LocalStorageUtil.setItem(StorageConstant.COLUMN_OPTIONS_KEY, this.storage);
        if (this.currentStateId) {
          this.state$.next(this.storage[this.currentStateId]);
        }
      });
  }

  saveStorage(): Observable<any> {
    const storage = Object.values(this.storage);
    return this.userPreferencesService
      .updateUserPreferences(storage, UserPreferencesType.Columns)
      .pipe(tap(() => this.getSavedStorage()));
  }
}
