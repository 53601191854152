import { ComponentFactoryResolver, Injectable, InjectionToken, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FilterLayoutComponent } from '@shared/components/filter-layout/filter-layout.component';
import { ButtonTheme } from "@shared/enums/button-theme.enum";
import { FilterButtonConfig } from '@shared/interfaces/filter-Button-Config.interface';
import { IFilterController } from '@shared/interfaces/filter-controller.interface';
import { ShipmentFilterLabels } from "@features/filter/enums/shipment-filter-labels.enum";
import { ShipmentFilterLayout } from "@features/shipments/enums/shipment-filter-layout.enum";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollStrategy } from '@angular/cdk/overlay';

export const filterInjectionToken = new InjectionToken<IFilterController>('FILTER_INJECTION_TOKEN');

type position = 'right' | 'left';

@Injectable({ providedIn: 'root' })
export class FilterDialogService {
  private destroy$ = new Subject<boolean>();
  private context: IFilterController;
  private defaultDialogSettings: MatDialogConfig = {
    hasBackdrop: true,
    closeOnNavigation: true,
    disableClose: false,
    enterAnimationDuration: 400,
    height: '100%',
    width: '400px',
  };

  constructor(
    private componentFactoryResolver?: ComponentFactoryResolver,
    private matDialog?: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  open(
    component: any,
    data?: any,
    options?: {
      filterLayoutType?: ShipmentFilterLayout;
      position?: position;
      scrollStrategy?: ScrollStrategy;
      title?: string;
      buttons?: {
        submit?: FilterButtonConfig;
        cancel?: FilterButtonConfig;
        reset?: FilterButtonConfig;
      };
    }
  ): Promise<IFilterController> {
    const dialogPosition = this.getPosition(options?.position);

    const dialogRef = this.matDialog.open(FilterLayoutComponent, {
      ...this.defaultDialogSettings,
      scrollStrategy: options?.scrollStrategy,
      position: dialogPosition,
    });

    return new Promise((resolve) => {
      dialogRef
        .afterOpened()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          const viewContainerRef = dialogRef.componentInstance.dynamicFilterTemplate;
          dialogRef.componentInstance.options = options;
          viewContainerRef.clear();
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          this.context = {
            dialogRef,
            layoutComponentRef: dialogRef.componentInstance,
            close: (args) => dialogRef.close(args),
            action$: dialogRef.componentInstance.action$,
          };
          const filterInjector = Injector.create({
            providers: [
              {
                provide: filterInjectionToken,
                useValue: this.context,
              },
            ],
          });

          const dynamicComponent = viewContainerRef.createComponent(
            componentFactory,
            0,
            filterInjector
          ) as any;
          if (data) {
            dynamicComponent.instance.data = data;
          }
          this.context.filterComponentRef = dynamicComponent;
          resolve(this.context);
        });
    });
  }

  private getPosition(position: position) {
    let pos = {};
    switch (position) {
      case 'right':
        pos = { right: '0', top: '0', bottom: '0' };
        break;

      case 'left':
      default:
        pos = { left: '0', top: '0', bottom: '0' };
        break;
    }
    return pos;
  }
}
