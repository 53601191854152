<app-notification></app-notification>
<app-confirmation></app-confirmation>
<app-loading></app-loading>

<div class="madar--v2">
  <mat-card class="floating-card bg-accent text-white" [class.floating-card--active]="!isNavigatorOnline">
    <mat-card-content>
      <app-icon name="wifi_off" type="MaterialDesign"></app-icon>
      <div class="small">{{'shared.Errors.NetworkError' | translate}}</div>
    </mat-card-content>
  </mat-card>
</div>
