import { Expose } from 'class-transformer';
import { DropdownModel } from './dropdown-model';

export class City extends DropdownModel {
    @Expose() id: number;
    @Expose() isoCode?: string;
    @Expose() name?: string;
    @Expose() status?: string;
    @Expose() polygonCoordinates?: string;
    @Expose() isUnknown?:boolean;
    selectedBy?: number = null;

    get dropDownDisplayName(): string {
        return this.name;
      }

      get dropDownValue(): Object {
        return this;
      }
}
