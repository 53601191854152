import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SharedConstants } from '@shared/model/shared-constants';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { ButtonTheme } from '@shared/enums/button-theme.enum';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { ShipmentStatus } from '@features/shipments/enums/shipment-status.enum';
import { Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentFilterLabels } from '@features/filter/enums/shipment-filter-labels.enum';
import { ShipmentFilterLayout } from '@features/shipments/enums/shipment-filter-layout.enum';
import { TabGroup } from '@shared/enums/tab-group.enum';
import { FormAction } from '@shared/enums/form.action.enum';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { FilterService } from '@shared/services/filter.service';
import { TruckType } from '@features/fleets/models/truck-type';
import { ShipmentsFilterBloc } from '../../../shipments/bloc/shipments-filter/shipments-filter-bloc';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { debounceTime, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ShipmentsFilterReadyState } from '../../../shipments/bloc/shipments-filter/shipments-filter-state';
import { BlocState, ProcessingState } from '@core/bloc/bloc-state';
import { InitEvent } from '@core/bloc/bloc-event';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { AuthService } from '@core/services/auth.service';
import { UserRoleService } from '@core/services/user-role.service';
import { RolesConstants } from '@shared/model/roles-constants';
import { IFilterController } from '@shared/interfaces/filter-controller.interface';
import { FilterDialogService } from '@shared/services/filter-dialog.service';
import { fromPromise } from 'rxjs/internal/observable/fromPromise';
import { CreateShipmentFilterComponent } from '../../../shipments/components/create-shipment-filter/create-shipment-filter.component';
import { EnumToArrayPipe } from '@shared/pipes/enum-to-array.pipe';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from '@shared-features/services/user-preferences.service';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';
import { User } from '@features/users/models/user';
import { Customer } from '@features/customers/models/customer';
import { Fleet } from '@features/fleets/models/fleet';

@Component({
  selector: 'app-shipments-filter',
  templateUrl: './shipments-filter.component.html',
  styleUrls: ['./shipments-filter.component.scss'],
  providers: [ShipmentsFilterBloc],
})
export class ShipmentsFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<void>;

  form: FormGroup;
  sharedConstants = SharedConstants;
  filterActionSubscription: Subscription;
  params: any = null;
  isB2c = false;

  dispose$ = new Subject();

  // enums
  allShipmentStatus = ShipmentStatus;

  customersSearch = new FormControl('');
  customers: Customer[] = [];
  fleets: Fleet[] = [];
  fleetById: { [key: string]: Fleet } = {};
  truckTypes: TruckType[] = [];
  creators: User[] = [];
  assignedByUsers: User[] = [];

  sectionStateStatus = SectionStateStatus;
  shipmentStatus: any;

  currentURL: any;
  currentTabIndex = 0;

  showCreatorByField = true;
  userId: number;
  isAllCustomersSelected = true;

  searchResultCustomers: Customer[] = [];
  selectedCustomerIds: number[] = [];

  favFilters: any[];
  selectedFilter: any;
  isFormChanged = false;
  indexOfSelectedFilter = -1;
  selectedFromFunctionSelect = false;
  CompanyLabelType = CompanyLabelType;
  enumToArrayPipe = new EnumToArrayPipe();
  companyLabels;
  companyNameByValue: any = {};

  constructor(
    private filterService: FilterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private bloc: ShipmentsFilterBloc,

    private authService: AuthService,
    private userRoleService: UserRoleService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private filterDialogService: FilterDialogService,
    private userPreferencesService: UserPreferencesService
  ) {
    super();
    this.bloc.onState
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    const { url } = this.router;
    this.isB2c = url.toLowerCase().includes(BusinessType.B2C.toLowerCase());
    this.showCreatorByField =
      !this.router.url.includes(RoutesUtil.B2bShipmentsList.path) &&
      this.userRoleService.isUserHasRoles(RolesConstants.USERS_SHIPMENTS_FILTER_VIEW);
    this.userId = this.authService.currentUser$.value?.id;

    this.shipmentStatus = { ...this.allShipmentStatus };
    delete this.shipmentStatus.None;

    // this.bloc.setEvent.next(
    //   new InitEvent({
    //     businessType: this.isB2c ? BusinessType.B2C : BusinessType.B2B,
    //     getUsers: true,
    //   })
    // );

    this.customersSearch.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe(() => {
        this.search();
      });

    this.currentURL = this.router.url;
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.subscribeToCurrentTabIndex();
    this.translateService.onLangChange
      .pipe(
        takeUntil(this.destroy$),
        startWith(true)
        // switchMap(() => of(this.enumToArrayPipe.transform(this.CompanyLabelType))),
        // map((labels) => labels.map(item => ({
        //   dropDownDisplayName: this.translateService.instant('Enum.CompanyLabelType.' + item.key),
        //   dropDownValue: item.value,
        // }))),
      )
      .subscribe(() => {
        // this.companyLabels = labels;
        // this.initForm();
        this.bloc.setEvent.next(
          new InitEvent({
            businessType: this.isB2c ? BusinessType.B2C : BusinessType.B2B,
            getUsers: true,
          })
        );
      });
    this.getFilterPreferences();
  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.dispose$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  subscribeToFilterAction() {
    this.filterActionSubscription = this.action.subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  subscribeToCurrentTabIndex() {
    this.filterService.currentTabIndex$.pipe(takeUntil(this.destroy$)).subscribe((index) => {
      this.currentTabIndex = index;
    });
  }

  getFilterValuesBasedOnURLParams(): typeof ShipmentsFilterComponent.prototype.params {
    const params = Object.assign({}, this.params);
    const keysToBeArrays: (keyof typeof params)[] = ['customerId', 'fleetId', 'customerLabel'];
    const keysToBeBoolean: (keyof typeof params)[] = [
      'withoutPrice',
      'withoutDeliveryNote',
      'isReceived',
    ];

    keysToBeArrays.forEach((key) => {
      const hasAll = params[key]?.includes(SharedConstants.ALL);
      if (hasAll) {
        params[key] = [];
      }
      params[key] =
        key === 'customerLabel'
          ? this.array(params[key])
          : this.mapArrayOfObjToKey(this.array(params[key]));
    });

    keysToBeBoolean.forEach((key) => {
      params[key] = this.parseBoolean(params[key]);
    });

    params.creator =
      params.creator === SharedConstants.ALL || !params.creator
        ? SharedConstants.ALL
        : Number(params.creator);

    params.assignedBy =
      params.assignedBy && params.assignedBy !== SharedConstants.ALL
        ? Number(params.assignedBy)
        : SharedConstants.ALL;

    return params;
  }

  initForm(): void {
    const params = this.getFilterValuesBasedOnURLParams();

    this.form = this.formBuilder.group({
      customerId: [params.customerId || []],
      fleetId: [(params.fleetId || []).map((id) => this.fleetById[id])],
      customerLabel: [(params.customerLabel || []).map((id) => this.companyNameByValue[id])],
      creator: [this.showCreatorByField ? params.creator : this.userId],
      assignedBy: [params.assignedBy || SharedConstants.ALL],
      withoutPrice: [params.withoutPrice],
      withoutDeliveryNote: [params.withoutDeliveryNote],
      isReceived: [params.withoutDeliveryNote],
      customerSearchTerm: [''],
      fleetSearchTerm: [''],
      creatorSearchTerm: [''],
      assignedBySearchTerm: [''],
    });
    this.form.valueChanges.subscribe((changedValues) => {
      if (!this.selectedFromFunctionSelect) {
        this.selectedFilter = false;
      }
    });
  }

  get filteredCustomers() {
    return this.customers.filter((element) =>
      element.dropDownDisplayName
        .toLowerCase()
        .includes(this.form.controls.customerSearchTerm.value?.toLowerCase())
    );
  }

  get filteredFleets() {
    return this.fleets.filter((element) =>
      element.dropDownDisplayName
        .toLowerCase()
        .includes(this.form.controls.fleetSearchTerm.value?.toLowerCase())
    );
  }

  get filteredCreators() {
    return this.creators.filter((element) =>
      element.dropDownDisplayName
        .toLowerCase()
        .includes(this.form.controls.creatorSearchTerm.value?.toLowerCase())
    );
  }

  get filteredAssignedBy() {
    return this.assignedByUsers.filter((element) =>
      element.dropDownDisplayName
        .toLowerCase()
        .includes(this.form.controls.assignedBySearchTerm.value?.toLowerCase())
    );
  }

  cancelChanges(): void {
    if (this.params && Object.keys(this.params).length !== 0) {
      const params = this.getFilterValuesBasedOnURLParams();
      this.form?.setValue({
        customerId: params.customerId || [],
        fleetId: (params.fleetId || []).map((id) => this.fleetById[id]),
        customerLabel: [(params.customerLabel || []).map((id) => this.companyNameByValue[id])],
        creator: this.showCreatorByField ? params.creator : this.userId,
        assignedBy: params.assignedBy,
        withoutPrice: params.withoutPrice,
        withoutDeliveryNote: params.withoutDeliveryNote,
        isReceived: params.withoutDeliveryNote,
        customerSearchTerm: '',
        fleetSearchTerm: '',
        creatorSearchTerm: '',
        assignedBySearchTerm: '',
      });
    } else {
      this.resetForm();
    }
  }

  resetForm(): void {
    this.form?.setValue({
      customerId: [],
      fleetId: [],
      customerLabel: [],
      creator: this.showCreatorByField ? SharedConstants.ALL : this.userId,
      assignedBy: SharedConstants.ALL,
      withoutPrice: false,
      withoutDeliveryNote: false,
      isReceived: false,
      customerSearchTerm: '',
      fleetSearchTerm: '',
      creatorSearchTerm: '',
      assignedBySearchTerm: '',
    });
    this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    this.selectedFilter = false;
    this.submitForm();
  }

  submitForm(): void {
    const obj = this.form.value;
    const customerId = obj.customerId;
    const fleets = (obj.fleetId || []).filter((i) => !!i);
    let selectedFilterValues = null;
    if (!this.isB2c) {
      obj.tabIndex = 0;
      obj.tabGroup = TabGroup.Shipments;
      obj.customerName = this.customers
        ?.filter(
          (customer) =>
            customerId !== this.sharedConstants.ALL && (customerId || []).includes(customer.id)
        )
        .map((i) => i.title);
      obj.fleetName = fleets.length ? fleets.map((i) => i.name) : null;
      obj.driverName = null;
      obj.creatorName = this.showCreatorByField
        ? this.creators?.find((creator) => creator.id === this.form.controls.creator.value)?.name
        : `${
            this.authService.currentUser$.value?.firstName +
            ' ' +
            this.authService.currentUser$.value?.lastName
          }`;
      obj.assignedByName = this.assignedByUsers?.find((u) => u.id === obj.assignedBy)?.name;
      obj.fleetId = fleets.map((fleet) => fleet.id);
      obj.customerLabel = obj?.customerLabel?.length
        ? obj.customerLabel.map((item) => item.dropDownValue)
        : null;
      selectedFilterValues = {
        ...obj,
        customerId: obj.customerId?.length ? obj.customerId : null,
        // customerId: obj.customerId.filter((c) => c !== this.sharedConstants.ALL),
      };
    } else {
      selectedFilterValues = {
        fleetId: obj.fleetId,
        fleetName: this.fleets?.find((fleet) => fleet.id === obj.fleetId)?.name,
        tabGroup: TabGroup.ShipmentsB2c,
        customerId: obj.customerId?.length ? obj.customerId : null,
      };
    }
    this.filterService.navigateToTargetPageWithFilter(selectedFilterValues);
  }

  onWithoutPriceSelectionChange(value: MatCheckboxChange): void {
    this.form.controls.withoutPrice?.setValue(value.checked);
  }

  onWithoutDeliveryNoteSelectionChange(value: MatCheckboxChange): void {
    this.form.controls.withoutDeliveryNote?.setValue(value.checked);
  }

  onIsReceivedSelectionChange(value: MatCheckboxChange): void {
    this.form.controls.isReceived?.setValue(value.checked);
  }

  checkAllCustomersSelected(): void {
    const { customerId } = this.form.value;
    const isAllSelectedNow = (customerId || []).includes(this.sharedConstants.ALL);
    if (this.isAllCustomersSelected && isAllSelectedNow && customerId?.length > 1) {
      this.form.controls.customerId?.setValue(
        customerId.filter((v) => v !== this.sharedConstants.ALL)
      );
      this.isAllCustomersSelected = false;
    } else if (!this.isAllCustomersSelected && isAllSelectedNow) {
      this.form.controls.customerId?.setValue([this.sharedConstants.ALL]);
      this.isAllCustomersSelected = true;
    }
  }

  search(): void {
    const { value } = this.customersSearch;
    const keywords = (value || '')
      .toLowerCase()
      .split(' ')
      .filter((i) => !!i);
    if (!keywords?.length) {
      this.searchResultCustomers = this.customers.concat([]);
      return;
    }
    this.searchResultCustomers = this.customers.filter((option: Customer) =>
      keywords.find((keyword) => option.title.toLowerCase().includes(keyword))
    );
  }

  isIndeterminate(): boolean {
    return (
      this.form.controls.customerId.value &&
      this.searchResultCustomers.length &&
      this.form.controls.customerId.value.length < this.searchResultCustomers.length
    );
  }

  isChecked(): boolean {
    return (
      this.form.controls.customerId.value &&
      this.searchResultCustomers.length &&
      this.form.controls.customerId.value.length === this.searchResultCustomers.length
    );
  }

  onToggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.form.controls.customerId.patchValue([
        ...this.searchResultCustomers.map((item) => item.id),
      ]);
      this.selectedCustomerIds = [...this.form.controls.customerId.value];
    } else {
      this.form.controls.customerId.patchValue([]);
      this.selectedCustomerIds = [];
    }
  }

  private handleBlocStates(state: BlocState): void {
    if (!state) {
      return;
    }

    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof ShipmentsFilterReadyState) {
      this.customers = state.customers;
      this.searchResultCustomers = [...this.customers];
      this.selectedCustomerIds = [...this.searchResultCustomers.map((item) => item.id)];
      this.fleets = state.fleets;
      this.fleetById = (state.fleets || []).reduce((group, fleet) => {
        group[fleet.id] = fleet;
        return group;
      }, {});
      this.truckTypes = state.truckTypes;
      this.creators = state.creators;
      this.assignedByUsers = state.creators;
      this.companyLabels = this.enumToArrayPipe.transform(this.CompanyLabelType).map((item) => {
        const newItem = {
          dropDownDisplayName: this.translateService.instant('Enum.CompanyLabelType.' + item.key),
          dropDownValue: item.value,
        };
        this.companyNameByValue[item.value] = newItem;
        return newItem;
      });
      this.initForm();
      this.sectionState = SectionStateStatus.Ready;
    }
  }

  private array<T>(value: T | T[]): T[] {
    return Array.isArray(value) ? value : value ? [value] : [];
  }

  private mapArrayOfObjToKey(
    value: any[],
    key: string = 'id',
    valueParser?: (v: any) => any
  ): any[] {
    const parse = valueParser || Number;
    return (value || []).map((item) => {
      if (typeof item === 'object' && Number.isNaN(item[key])) {
        return item;
      } else if (typeof item === 'object') {
        return parse(item[key]);
      }
      return parse(item);
    });
  }

  private parseBoolean(value: string | boolean): boolean {
    return typeof value === 'boolean' || (typeof value === 'string' && value === 'true');
  }

  getFilterPreferences() {
    this.userPreferencesService
      .getUserPreferences(UserPreferencesType.Filters)
      .subscribe((data) => {
        this.favFilters = data || [];
      });
  }

  selectFilter(filter, i) {
    this.selectedFromFunctionSelect = true;
    this.selectedFilter = filter;

    this.form.patchValue({
      customerId: filter.customerId,
      fleetId: filter.fleetId,
      creator: filter.creator,
      assignedBy: filter.assignedBy,
      customerLabel: filter.customerLabel
        ?.filter((i) => !!i)
        .map((item) => {
          return this.companyLabels.find((i) =>
            item.dropDownValue ? i.dropDownValue === item.dropDownValue : i.dropDownValue === item
          );
        }),
      withoutPrice: filter.withoutPrice,
      withoutDeliveryNote: filter.withoutDeliveryNote,
      isReceived: filter.isReceived,
    });
    this.selectedFromFunctionSelect = false;
  }

  deleteFilter(filterItem) {
    const favFiltersAfterDelete = this.favFilters.filter(
      (filter) => JSON.stringify(filter) !== JSON.stringify(filterItem)
    );
    this.load(
      this.userPreferencesService.updateUserPreferences(
        favFiltersAfterDelete,
        UserPreferencesType.Filters
      )
    ).subscribe((statusCode) => {
      if (+statusCode === 200) {
        this.favFilters = favFiltersAfterDelete;
      }
    });
  }

  editFilter(filterItem, i) {
    this.openFilter(
      {
        customers: this.customers,
        fleets: this.fleets,
        companyLabels: this.companyLabels,
        creators: this.creators,
        assignedByUsers: this.assignedByUsers,
        favFilters: this.favFilters,
        index: i,
        editData: filterItem,
      },
      {
        submit: {
          label: ShipmentFilterLabels.Update,
          theme: ButtonTheme.Raised,
          color: 'secondary',
        },
      }
    );
  }

  openFilter(data, buttonsObj) {
    const createShipmentFilter$ = this.filterDialogService.open(
      CreateShipmentFilterComponent,
      data,
      {
        filterLayoutType: ShipmentFilterLayout.Nested,
        position: 'right',
        buttons: Object.assign({}, buttonsObj, {
          reset: {
            label: ShipmentFilterLabels.BackToFilter,
            theme: ButtonTheme.Flat,
            color: 'primary',
          },
        }),
      }
    );

    fromPromise(createShipmentFilter$)
      .pipe(
        switchMap((controller: IFilterController) =>
          combineLatest([controller.action$, of(controller)])
        )
      )
      .subscribe(([action, controller]) => {
        if (action === FormAction.Cancel) {
          controller.close();
        }
        this.resetForm();
      });
  }

  createFilter() {
    this.openFilter(
      {
        customers: this.customers,
        fleets: this.fleets,
        companyLabels: this.companyLabels,
        creators: this.creators,
        assignedByUsers: this.assignedByUsers,
        favFilters: this.favFilters,
        selectedFeild: this.form,
      },
      {
        submit: {
          label: ShipmentFilterLabels.Create,
          theme: ButtonTheme.Raised,
          color: 'secondary',
        },
      }
    );
  }
}
