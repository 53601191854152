import { Component, Inject, Input } from '@angular/core';
import { filterInjectionToken } from '@shared/services/filter-dialog.service';
import { IFilterController } from '@shared/interfaces/filter-controller.interface';
import { ColumnOptionsStateKey } from '@shared-features/models/column-options-state-key.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColumnOptionsService } from '@shared/services/column-options.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { FormAction } from '@shared/enums/form.action.enum';

@Component({
  selector: 'app-column-options-preferences',
  templateUrl: './column-options-preferences.component.html',
  styleUrls: ['./column-options-preferences.component.scss'],
})
export class ColumnOptionsPreferencesComponent {
  @Input() data: {
    id: string;
    keys: any;
    state: ColumnOptionsStateKey[];
    name: string;
  };

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  sectionState = SectionStateStatus.Ready;

  constructor(
    @Inject(filterInjectionToken) private filterController: IFilterController,
    private columnOptionsService: ColumnOptionsService
  ) {
    this.handleFilterActions();
    Promise.resolve().then(() => {
      this.formGroup.controls.name.setValue(this.data.name);
    });
  }

  handleStateChange(state: ColumnOptionsStateKey[]) {
    this.data = { ...this.data, state };
  }

  handleFilterActions(): void {
    this.filterController.action$
      .pipe(
        filter(
          (action) =>
            action !== FormAction.Submit ||
            (this.formGroup.valid && (this.formGroup?.value as any)?.state?.length)
        ),
        switchMap((action) => (action === FormAction.Submit ? this.save() : of(false)))
      )
      .subscribe((updated) => {
        this.sectionState = SectionStateStatus.Ready;
        this.filterController.dialogRef.close(updated);
      });
  }

  save(): Observable<boolean> {
    const oldState = this.columnOptionsService.getStateByName(this.data.id);
    const { id, keys, state } = this.data;
    const { name } = this.formGroup.value;
    const isPreferenceExist = oldState?.preferences?.find((pref) => pref.name === this.data.name);
    this.columnOptionsService.state = {
      id,
      keys: keys || oldState.keys,
      state: oldState.state,
      defaultState: oldState.defaultState,
      preferences: isPreferenceExist
        ? oldState?.preferences.map((pref) => {
            if (pref.name === this.data.name) {
              return { ...pref, name, state };
            }
            return pref;
          })
        : [...(oldState?.preferences || []), { name, state }],
    };
    this.sectionState = SectionStateStatus.Loading;
    return this.columnOptionsService.saveStorage().pipe(map(() => true));
  }
}
