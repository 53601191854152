import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, of, race, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { SharedConstants } from '@shared/model/shared-constants';
import { NotificationItem } from '@shared-features/models/notification-item';
import { NotificationService } from '@shared/services/notification.service';

@Injectable()
export class MessagingService implements OnDestroy {
  public currentMessage$ = new BehaviorSubject(null);
  public notificationToken$ = new BehaviorSubject(SharedConstants.INIT_VALUE);
  private destroy$ = new Subject();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationService
    ) { }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  requestPermission(): Promise<void> {
    const Notification = window.Notification || null;
    if (Notification && Notification.requestPermission) {
      return new Promise((resolve) => {
        this.angularFireMessaging.requestPermission
          .pipe(take(1))
          .subscribe((val) => {
            this.getToken();
            resolve();
          }, (err) => {
            this.notificationToken$.next(null);
            resolve();
          });
      });
    }
    this.notificationToken$.next(SharedConstants.NOT_SUPPORTED);
    return Promise.resolve();
  }

  getToken(): void {
    this.angularFireMessaging.getToken.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (token) => {
        this.notificationToken$.next(token);
        this.receiveMessage();
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        this.notificationToken$.next(SharedConstants.NOT_SUPPORTED);
        this.notificationToken$.next(null);
      }
    );
  }

  receiveMessage(): void {
    this.angularFireMessaging.messages.pipe(takeUntil(this.destroy$)).subscribe(
      (payload) => {
        const notification = new NotificationItem();
        notification.id = payload.data.notificationId;
        notification.title = payload.notification.title;
        notification.body = payload.notification.body;
        notification.elementId = +payload.data.id;
        this.notificationService.showNotification(notification);
        this.currentMessage$.next(payload);
      });
  }
}
