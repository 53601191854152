import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ShipmentDateFilterType } from '@features/filter/enums/shipment-date-filter-type.enum';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { SortOptions } from '@shared/enums/sort-options.enum';
import { TabModel } from '../../model/tab.model';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FunctionUtil } from '../../utils/function.util';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { EnumToArrayPipe } from '@shared/pipes/enum-to-array.pipe';
import { TrackingType } from '@features/live-tracking/enums/tracking-type.enum';
import { ShipmentRequestsMultiplicationType } from '@features/shipments/enums/shipment-requests-multiplication-type.enum';
import { ColumnOptionsListPage } from '@features/reports/enums/column-options-list-page.enum';
import { CustomerType } from '@features/customers/enums/customer-type.enum';
import { BaseComponent } from '../base-component/base.component';
import { dateRangePreview } from '@shared-features/utils/date-range-preview.util';
import debounce from 'lodash/debounce';
import { ColumnOptionsService } from '@shared/services/column-options.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-list-filter-controls',
  templateUrl: './list-filter-controls.component.html',
  styleUrls: ['./list-filter-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListFilterControlsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() includeSearchControl = true;
  @Input() includeShipmentFilter = false;
  @Input() includeDateControl = false;
  @Input() includePickupDateControl = false;
  @Input() includeSortByControl = true;
  @Input() includeFilterButton = true;
  @Input() includeShipmentSpecificFleetFilter = false;
  @Input() breakControlsIntoMultipleLines = false;
  @Input() includeLastRefreshTime = false;
  @Input() includeShipmentRequestsMultiplicationFilter = false;
  @Input() includeTrackingTypeFilter = false;
  @Input() includeBayanTypeFilter = false;
  @Input() isB2c = false;
  @Input() includeAutoCacheFilter = false;

  @Input() filterNavigationConfig: TabModel;
  @Input() showCustomDateOption = false;
  @Input() searchPlaceholder: string;
  @Input() selectedDateRange: DateRangeType;
  @Input() customerServiceType: CustomerType;
  @Input() shipmentRequestFilterCount: ShipmentRequestsMultiplicationType;
  @Input() trackingType: TrackingType;
  @Input() isBayanVerified: boolean;
  @Input() autoCache;

  @Input() lastRefreshTime: Date = new Date();
  @Input() showSearchActions = true;
  @Input() ShipmentDateFilterType: any = ShipmentDateFilterType;
  @Input() searchKeyEnum;
  @Input() isMultiple = false;
  @Output() sortBy = new EventEmitter();
  @Output() searchValue = new EventEmitter();
  @Output() selectedDateRangeChanged = new EventEmitter();
  @Output() includeSpecificFleets = new EventEmitter();
  @Output() startFrom = new EventEmitter();
  @Output() endAt = new EventEmitter();
  @Output() selectedShipmentDateFilterTypeChanged = new EventEmitter();
  @Output() shipmentRequestsMultiplicationTypeFilter = new EventEmitter();
  @Output() trackingTypeFilter = new EventEmitter();
  @Output() bayanTypeFilter = new EventEmitter();
  @Output() autoUpdateListToggled = new EventEmitter();
  @Output() autoCacheChange = new EventEmitter<boolean>();


  // enum
  public sortOptions = SortOptions;
  public dateRangeType = DateRangeType;
  dateRangeValue: DateRangeType = DateRangeType.TODAY;
  previewSelectedDate: string;

  form: UntypedFormGroup = this.formBuilder.group({
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
  });

  maxDate: Date = new Date();
  customerTypeControl = new UntypedFormControl(null);
  shipmentRequestsMultiplicationTypeControl = new UntypedFormControl(null);
  CustomerType = CustomerType;
  ShipmentRequestsMultiplicationType = ShipmentRequestsMultiplicationType;
  @Input() shipmentDateFilterTypeValue: any = this.getDateTypeFilterValue();
  trackingTypeControl = new UntypedFormControl(TrackingType.All);
  bayanTypeFilterControl = new UntypedFormControl(null);
  TrackingType = TrackingType;

  position: ConnectedPosition = {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  };

  @Input() includeColumnOptions = false;
  @Input() includeLastRefreshTimeShipmentList = false;
  // at most cases it should be enum of key in entity = key in translation
  @Input() columns: { [key: string]: string };
  // it must list of keys in entity provided in columnOptionKeys
  @Input() defaultVisibleColumns: string[];
  @Input() pageName: ColumnOptionsListPage;

  @Output() columnOptionsChanges = new EventEmitter();
  @Output() exportedColumnsFilter = new EventEmitter();
  // columnOptionsStatus: any[] = [];
  shipmentListExportFilter = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private columnOptionsService: ColumnOptionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.columnOptionsService.state$
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(({ state, keys }) => {
        this.shipmentListExportFilter = (state || []).map(({ key }) => key);
        this.exportedColumnsFilter.emit(this.shipmentListExportFilter);
        this.changeDetector.markForCheck();
        this.columnOptionsChanges.emit();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      selectedDateRange,
      customerServiceType,
      shipmentRequestFilterCount,
      trackingType,
      isBayanVerified,
    } = changes;

    if (selectedDateRange && selectedDateRange.currentValue !== selectedDateRange.previousValue) {
      this.dateRangeValue = this.selectedDateRange;
    }

    if (
      customerServiceType &&
      customerServiceType.currentValue !== customerServiceType.previousValue
    ) {
      this.customerTypeControl.setValue(this.customerServiceType);
    }

    if (
      shipmentRequestFilterCount &&
      shipmentRequestFilterCount.currentValue !== shipmentRequestFilterCount.previousValue
    ) {
      this.shipmentRequestsMultiplicationTypeControl.setValue(this.shipmentRequestFilterCount);
    }

    if (trackingType && trackingType.currentValue !== trackingType.previousValue) {
      this.customerTypeControl.setValue(this.customerServiceType);
      this.trackingTypeControl.setValue(this.trackingType);
    }

    if (changes.ShipmentDateFilterType) {
      this.shipmentDateFilterTypeValue = this.getDateTypeFilterValue();
    }

    if (isBayanVerified && isBayanVerified.currentValue !== isBayanVerified.previousValue) {
      this.bayanTypeFilterControl.setValue(this.isBayanVerified);
    }

    debounce(() => this.changeDetector.markForCheck(), 500);
  }

  onSearch(value: string): void {
    this.searchValue.emit(value);
    this.selectedDateRangeChanged.emit(DateRangeType.ALL_TIME);
    this.checkPreviewSelectedDate();
  }

  onSelectSortBy(value: string): void {
    this.sortBy.emit(value);
  }

  customDateChanged(): void {
    if (this.form.controls.to.value) {
      this.startFrom.emit(FunctionUtil.convertDate(this.form.controls.from.value));
      this.endAt.emit(FunctionUtil.convertDate(this.form.controls.to.value));
      this.selectedDateRangeChanged.emit(this.dateRangeValue);
    }
  }

  OnDateSelectionChange(item: MatSelectChange): void {
    this.dateRangeValue = item.value;
    if (this.dateRangeValue !== DateRangeType.CUSTOM) {
      this.selectedDateRangeChanged.emit(this.dateRangeValue);
    } else {
      this.form.controls.from.setValue(null);
      this.form.controls.to.setValue(null);
    }
    this.checkPreviewSelectedDate();
    this.changeDetector.markForCheck();
  }

  onCustomerTypeChanges(event: MatButtonToggleChange): void {
    this.includeSpecificFleets.emit(event.value);
  }

  onShipmentRequestsMultiplicationTypeChanges(event: MatButtonToggleChange): void {
    this.shipmentRequestsMultiplicationTypeFilter.emit(event.value);
  }

  ontrackingTypeChanges(event: MatButtonToggleChange): void {
    this.trackingTypeFilter.emit(event.value);
  }
  ontBayanTypeChanges(event: MatButtonToggleChange): void {
    this.bayanTypeFilter.emit(event.value);
  }

  private getDateTypeFilterValue(): string {
    const types = new EnumToArrayPipe().transform(this.ShipmentDateFilterType);
    return types[1] ? types[1].value : types[0]?.value;
  }

  // subscribeOnColumnOptionsStatusChanges(): void {
  //   this.colOptionsService.status$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
  //     if (res && res.length) {
  //       this.columnOptionsStatus = res;
  //       this.columnOptionsChanges.emit(this.columnOptionsStatus);
  //     }
  //     this.changeDetector.markForCheck();
  //   });
  // }

  // onColumnOptionsClicked(): void {
  //   this.colOptionsService.activeState$.next(this.columnOptionsKey);
  //   this.colOptionsService.saveState(this.columnOptionsStatus);
  // }

  checkPreviewSelectedDate(): void {
    this.previewSelectedDate = dateRangePreview(this.dateRangeValue);
  }

  toggleColumnOptionsMenu(): void {
    this.columnOptionsService.toggleDrawer(true);
  }
  autoUpdateChanges(event) {
    this.autoUpdateListToggled.emit(event.checked);
  }
}
