<form class="flex justify-center column" [formGroup]="form">
    <mat-label>{{ 'Providers.Drivers.DateOfBirth' | translate }}</mat-label>
    <mat-form-field appearance="outline">
      <input
        matInput
        [min]="minDate"
        [max]="maxDate"
        formControlName="date"
        [matDatepicker]="date"
        [placeholder]="'MM / YYYY'"
        readonly
        (click)="date.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
      <mat-datepicker
      #date
      startView="multi-year"
      (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, date)"

      ></mat-datepicker>
      <mat-error *ngIf="form.invalid && form.get('date').touched && form.get('date').hasError('required')">
        {{ "ErrorMessages.Required" | translate }}
      </mat-error>
    </mat-form-field>
  </form>
