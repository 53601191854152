import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { ShipmentAction } from "@features/shipments/enums/shipment-action.enum";

export class G2gLocation extends Model {
  @Expose() positionId: number;
  @Expose() longitude: number;
  @Expose() latitude: number;
  @Expose() createdAt: number;
  @Expose() actions: ShipmentAction[];
}
