import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-avatar',
  template: `
    <div class="avatar__icon" *ngIf="!src && !title">
      <app-icon
        [name]="icon"
        type="MaterialDesign"
        *ngIf="iconType === 'material'"
        [size]="iconSize"
      ></app-icon>
      <app-icon *ngIf="iconType === 'svg'" [name]="icon" [size]="iconSize"></app-icon>
    </div>
    <div class="avatar__title" *ngIf="title && !src">
      {{ title }}
    </div>
    <div class="avatar__img" *ngIf="src">
      <img [src]="src" alt="" />
    </div>
  `,
  styles: [
    `
      :host.avatar {
        width: 3rem;
        height: 3rem;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: black;
        color: white;
      }

      :host.avatar--round {
        border-radius: 50%;
      }

      .avatar__text,
      .avatar__img,
      .avatar__icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .avatar__icon {
        display: inline-flex;
      }

      .avatar__icon > svg-icon {
        display: inline-flex;
        align-content: center;
      }

      .avatar__img {
        width: 100%;
        height: 100%;
      }

      .avatar__img img {
        width: 100%;
        height: 100%;
      }
    `,
  ],
})
export class AvatarComponent implements OnChanges {
  @HostBinding('class.avatar') avatarClass = true;
  @Input() bgColor = '#eee';
  @Input() fgColor = '#222222';
  @Input() src = '';
  @Input() name = '';
  @Input() size = 48;
  @Input() round = false;
  @Input() icon = 'person';
  @Input() iconType: 'svg' | 'material' = 'material';
  @Input() iconSize: 'sm' | 'md' | 'lg' = 'md';
  @Input() radius = 0;

  @HostBinding('class.avatar--round') get roundClass(): boolean {
    return this.round;
  }

  @HostBinding('attr.style') get style(): string {
    const size = `${(this.size || 16) / 16}rem`;
    const fontSize = `${(this.size || 16) / 36}rem`;
    const borderRadius = `${(this.radius || 16) / 16}rem`;
    return `
      width: ${size};
      height: ${size};
      font-size: ${fontSize};
      background-color: ${this.bgColor || ''};
      color: ${this.fgColor || ''};
      border-radius: ${borderRadius};
    `;
  }

  title = '';

  ngOnChanges({ name }: SimpleChanges): void {
    if (name && name.currentValue?.length) {
      const [firstName, lastName] = this.name.split(' ');
      this.title =
        typeof this.name === 'string' ? (firstName?.[0] || '') + (lastName?.[0] || '') : '';
    }
  }
}
