import { Expose } from 'class-transformer';
import { Status } from "@features/shipments/enums/status.enum";
import { DropdownModel } from '../../shared-features/models/dropdown-model';

type CapacityRanges = {
  maxCapacityMv: {
    to: number;
    from: number;
  };
  minCapacityMv: {
    to: number;
    from: number;
  };
  maxCapacityTon: {
    to: number;
    from: number;
  };
  minCapacityTon: {
    to: number;
    from: number;
  };
};


export class TruckType extends DropdownModel {
  @Expose() id: number;
  @Expose() name?: string;
  @Expose() createdAt?: number;
  @Expose() updatedAt?: number;
  @Expose() createdBy?: string;
  @Expose() status?: Status = Status.ACTIVE;
  @Expose() capacity?: number;
  @Expose() numOfPallets?: number;
  @Expose() capacityRanges?: CapacityRanges;
  selectedBy?: number = null;

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): object {
    return this;
  }
}
