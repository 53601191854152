export enum UserType {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  Receiver = 'RECEIVER',
  FLEET = 'FLEET',
  DRIVER = 'DRIVER',
  ORGANIZATION = 'ORGANIZATION',
  USER = 'USER',
  RENTAL_MANAGER = 'RENTAL_MANAGER',
}
