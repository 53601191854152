import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,  Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';
import { UserPreferencesService } from '@shared-features/services/user-preferences.service';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'app-shipment-list-filter-edit-and-create',
  templateUrl: './shipment-list-filter-edit-and-create.component.html',
  styleUrls: ['./shipment-list-filter-edit-and-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentListFilterEditAndCreateComponent extends BaseComponent implements OnInit {
  @Input() form :FormGroup
  @Input() params
  @Input() isCreateFilter :boolean
  @Input() favFiltersIndex :number
  @Output() clickEditFilter = new EventEmitter<boolean>()
  @Output() clickCreateFilter = new EventEmitter()
  @Output() setFavFiltersIndex = new EventEmitter<number>()

  previousFilter
  constructor(
    private userPreferencesService :UserPreferencesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super()
  }

  ngOnInit() {
    this.previousFilter = this.form.value
  }

  backToFilter(): void {
    this.form.patchValue(this.previousFilter)
    this.clickEditFilter.emit(false)
    this.clickCreateFilter.emit(false)
    this.form.get('filterName').clearValidators()
    this.changeDetectorRef.markForCheck()
  }

  submitForm() {
    const { filterName,
      customersIds,
      fleetsIds,
      creator,
      assignedBy,
      customerLabels,
      withoutPrice,
      withoutDeliveryNote,
      isReceived} =  this.form.value

    const favObj = {
      filterName: filterName,
      customerId: customersIds?.map(customer=> customer.id) ,
      customerName: customersIds?.map(customer=> customer.value) ,
      fleetId: fleetsIds?.map(fleet=> fleet.id) ,
      fleetName: fleetsIds?.map(fleet=> fleet.value) ,
      creator: creator?.map(creator=> creator.id),
      creatorName: creator?.map(creator=> creator.value),
      assignedBy: assignedBy?.map(assignedBy=> assignedBy.id),
      assignedByName: assignedBy?.map(assignedBy=> assignedBy.value),
      customerLabel: customerLabels?.map(customerLabel => customerLabel?.id) || [],
      isReceived,
      withoutPrice ,
      withoutDeliveryNote,
    }

    const favFilterAfterAdded = [...this.form.get('favFilter').value.map((item) => ({ ...item }))];

    this.isCreateFilter
      ? favFilterAfterAdded.push(favObj)
      : (favFilterAfterAdded[this.favFiltersIndex] = favObj);

    if (favFilterAfterAdded?.length <= 6 && filterName) {
      this.load(
        this.userPreferencesService.updateUserPreferences(
          favFilterAfterAdded,
          UserPreferencesType.Filters
        ),
        { isLoadingTransparent: true }
      ).subscribe((statusCode) => {
        this.clickCreateFilter.emit(false);
        this.clickEditFilter.emit(false);
      });
    } else {
      this.form.get('filterName').markAsTouched();
    }
  }
}
