import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, switchMap, take } from 'rxjs/operators';
import { User } from '@features/users/models/user';
import { JwtDecoderService } from '../services/jwt-decoder.service';
import { AuthService } from '../services/auth.service';
import { ApiConstant } from '@shared/constants/api.constant';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { RoutesUtil } from '@shared-features/utils/routes.util';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector, private router: Router, private jwtInterceptor: JwtInterceptor) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && request.url.indexOf(ApiConstant.REFRESH_TOKEN) > -1) {
          const jwtDecoderService = this.injector.get(JwtDecoderService);
          jwtDecoderService.removeCurrentToken();
          location.reload();
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const authService = this.injector.get(AuthService);

      return authService.refreshTheToken().pipe(
        switchMap((user: User) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(user.refreshToken);
          return this.jwtInterceptor.intercept(request, next);
        }),
        catchError((error) => this.router.navigate([RoutesUtil.AuthLogin.url()]))
      );
    } else {
      return this.postponeRequestUntilToken(request, next);
    }
  }

  private postponeRequestUntilToken(request, next): Observable<HttpEvent<any>> {
    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      delay(250),
      switchMap((jwt) => {
        return this.jwtInterceptor.intercept(request, next);
      }),
    );
  }
}
