import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';
import { Receiver } from '@features/receivers/models/receiver';

export class PermittedAccess extends Model {
  @Expose() id: number;
  @Expose() label: string;
  @Expose() expirationDate: Date|string;
  @Expose() receiver: Receiver;
}
