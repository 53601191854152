import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class DoorHistoryAdditionalData extends Model {
  @Expose() bleTemp1: number;
  @Expose() bleTemp2: number;
  @Expose() latitude: string;
  @Expose() longitude: string;
  @Expose() tempDifference: number;
}
