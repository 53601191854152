<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form" [formGroup]="form" *ngIf="sectionState === sectionStateStatus.Ready">
    <!-- date -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Date" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select               (selectionChange)="onDateSelectionChange($event.value)"
         formControlName="dateRange">
          <mat-option *ngFor="let range of dateRangeType | enumToArray" [value]="range.value">
            {{ 'Enum.DateRangeType.' + range.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- custom -->
    <div *ngIf="dateRangeValue === dateRangeType.CUSTOM">
      <mat-form-field appearance="outline">
        <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
          <input readonly formControlName="from" matInput matStartDate [placeholder]="'Placeholder.StartDate' | translate" />
          <input
            readonly
            formControlName="to"
            (dateChange)="customDateChanged()"
            matInput
            matEndDate
            [placeholder]="'Placeholder.EndDate' | translate"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>


    <!-- From -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.From" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="fromCity">
          <app-dropdown-search formControlName="fromCitySearchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of filteredFromCities | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- To -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.To" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="toCity">
          <app-dropdown-search formControlName="toCitySearchTerm"></app-dropdown-search>
          <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
          <mat-option *ngFor="let item of filteredToCities | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- waittingCharge -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.WaitingCharge" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [placeholder]="
            'Placeholder.Insert' | translate
          "
          formControlName="waittingCharge"
        />
      </mat-form-field>
    </div>

    <!-- multiDis -->
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.MultiDistance" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <input
          matInput
          type="number"
          [placeholder]="
              'Placeholder.Insert' | translate
            "
          formControlName="multiDis"
        />
      </mat-form-field>
    </div>
  </form>
</app-state-section>
