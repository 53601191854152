import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { Fleet } from './fleet';

export class FleetsDetails extends Model {
  @Type(() => Fleet)
  @Expose()
  fleets: Fleet[] = [];
  @Expose() totalFleetRequestsCount: number;
  @Expose() totalFleetsCount: number;
  @Expose() totalRegularFleetsCount: number;
  @Expose() totalRentalFleetsCount: number;
  @Expose() fileUri: string;
  @Expose() message: string;
}
