<div class="madar--v2">
  <mat-card class="aside-card scrollable scrollable--y">
    <mat-card-content>
      <span class="small text-primary weight-normal">{{"shared.components.cardItemsList.LineItems" | translate }}</span>
      <mat-divider class="my-2 aside-card__divider"></mat-divider>

      <div class="aside-card__item" *ngFor="let item of lineItems; let last = last">
        <app-rect-tag [color]="ColorPalette.Primary">{{item?.productType || '___'}}</app-rect-tag>
        <div class="aside-card__item-title">{{item?.productItem || '___'}}</div>
        <div class="flex justify-space-between">
          <div class="aside-card__item-desc">{{"shared.components.cardItemsList.Qty" | translate }}: {{item?.quantity || '___'}} {{item?.unit || '___'}}</div>
        </div>

        <mat-divider *ngIf="!last" class="my-2 aside-card__divider"></mat-divider>
      </div>

      <ng-template #undetailedItemsPopover>
        <app-order-list-line-items [orderId]="activeUndetailedOrder"></app-order-list-line-items>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
