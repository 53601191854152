import { DropdownModel } from '@shared-features/models/dropdown-model';
import { Expose, Type } from 'class-transformer';
import { User } from '../../users/models/user';
import { ProductTypeItem } from '@features/shipments/models/product-type-item.model';

export class ProductType extends DropdownModel {
  @Expose() id: number;
  @Expose() name? = '';
  @Expose() customerId?: number;
  @Expose() productTypeId?: number;
  @Expose() isDefault? = false;
  @Expose()
  @Type(() => User)
  createdBy? = new User();
  @Expose()
  @Type(() => User)
  updatedBy? = new User();
  @Expose() createdAt?: number;
  @Expose() updatedAt?: number;

  @Expose() productItemCount?: number;

  @Expose()
  @Type(() => ProductTypeItem)
  items?: ProductTypeItem[] = [];

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): Object {
    return this;
  }
}
