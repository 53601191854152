import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class TruckPlateNumberDetails  extends Model {
  @Expose()
  plateNumberDigits?: string;

  @Expose()
  leftCharAR?: string;

  @Expose()
  middleCharAR?: string;

  @Expose()
  rightCharAR?: string;

  @Expose()
  leftCharEN?: string;

  @Expose()
  rightCharEN?: string;

  @Expose()
  middleCharEN?: string;
}
