import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRoleService } from '@core/services/user-role.service';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { UserType } from '@features/users/enums/user-type.enum';
import { User } from '@features/users/models/user';
import { Lookup } from '@shared-features/models/Lookup.model';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { LookupType } from '@shared/enums/lookup-type.enum';
import { RolesConstants } from '@shared/model/roles-constants';
import { modelToLookup } from '@shared/utils/model-to-lookup.util';
import { parseQueryParam } from '@shared/utils/parse-query-param';

@Component({
  selector: 'app-shipment-list-filter-form',
  templateUrl: './shipment-list-filter-form.component.html',
  styleUrls: ['./shipment-list-filter-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentListFilterFormComponent extends BaseComponent implements OnInit {
  @Input() form: FormGroup;

  isB2c;
  showCreatorByField;

  customerLabelsOptions = Object.keys(CompanyLabelType).map((key) =>
    Object.assign(new Lookup(), {
      id: key,
      value: CompanyLabelType[key],
    })
  );

  protected LookupType = LookupType;

  constructor(private router: Router, private userRoleService: UserRoleService) {
    super();
  }

  ngOnInit() {
    this.showCreatorByField =
      !this.router.url.includes(RoutesUtil.B2bShipmentsList.path) &&
      this.userRoleService.isUserHasRoles(RolesConstants.USERS_SHIPMENTS_FILTER_VIEW);

    this.isB2c = this.router.url.toLowerCase().includes(BusinessType.B2C.toLowerCase());
  }
}
