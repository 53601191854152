import { Expose, Type } from 'class-transformer';
import { TruckType } from './truck-type';
import { Fleet } from './fleet';
import { Model } from '@shared-features/models/model';
import { DropdownModel } from '@shared-features/models/dropdown-model';

export class Trailer extends DropdownModel {
  @Expose() id: number;
  @Type(() => TruckType)
  @Expose()
  truckType: TruckType = new TruckType();
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();
  @Expose() doorNumber: string;

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.doorNumber;
  }

  get dropDownValue(): Trailer {
    return this;
  }
}
