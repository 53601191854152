<app-shipment-list-filter-form [form]="form" >
</app-shipment-list-filter-form>

<mat-divider class="pos-static my-3"></mat-divider>

<app-shipment-list-form-fav-section
  [form]="form"
  [favFiltersIndex]="favFiltersIndex"
  (setFormValues)="setFormValues.emit($event)"
  (clickCreateFilter)="clickCreateFilter.emit($event)"
  (clickEditFilter)="clickEditFilter.emit($event)"
  (setFavFiltersIndex)="setFavFiltersIndex.emit($event)"
>
</app-shipment-list-form-fav-section>

<app-row justify="flex-end" gutter="xs">
  <app-col cols="auto" >
    <button  mat-button color="primary" class="d-block w-100" (click)="resetForm()">{{'shipments.shipmentListFilter.reset' | translate}}</button>
  </app-col>

  <app-col cols="auto" lg="2">
    <button  mat-flat-button color="primary" class="d-block w-100" (click)="applyFilter()">{{'shipments.shipmentListFilter.apply' | translate}}</button>
  </app-col>
</app-row>
