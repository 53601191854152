<div class="text-600" *ngIf="label || labelIcon">
  <app-icon [name]="labelIcon" type="MaterialDesign" class="align-middle" *ngIf="labelIcon"></app-icon>
  <span>{{label}}</span>
</div>

<div class="text-900">
  <p class="m-0" *ngIf="!href"><b class="weight-medium">{{value}}</b></p>
  <a class="m-0 btn-link text-primary" *ngIf="href" [href]="href" [target]="target"><b class="weight-medium">{{value}}</b></a>
</div>
<div class="text-600" *ngIf="hint || hintIcon">
  <app-icon [name]="hintIcon" type="MaterialDesign" class="align-middle" *ngIf="hintIcon"></app-icon>
  {{hint}}
</div>
