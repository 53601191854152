import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ButtonTheme } from "@shared/enums/button-theme.enum";
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnChanges {
  @Input() theme: ButtonTheme;
  @Input() size: 'sm'|'md' = 'md';
  @Input() square = false;
  @Input() type: 'button'|'submit';
  @Input() disabled = false;

  @Input() set color(clr: ThemePalette | 'secondary'|'link') {
    this.buttonColor = clr === 'secondary' || clr === 'link' ? undefined : clr;
    this.buttonClass = clr === 'secondary' ? 'bg-primary-lighter' : 'btn--' + clr;
  }

  buttonClass: string;
  buttonColor: ThemePalette;


  protected readonly ButtonTheme = ButtonTheme;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDetector.detectChanges();
  }
}
