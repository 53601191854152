import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Icon } from '@shared/model/icon.model';
import { IconType } from "@shared/enums/icon-type.enum";

@Component({
  selector: 'app-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandablePanelComponent implements OnInit {
  @Input() themeless = true;
  @Input() inline = true;
  @Input() expanded = false;
  @Input() disabled = false;
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Input() icon: Icon & { rotate?: boolean };

  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.expansionPanel.toggle();
  }

  protected readonly IconType = IconType;
}
