import { ShipmentsFilterComponent } from "@features/filter/components/shipments-filter/shipments-filter.component";

export function  convertToArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : value ? [value] : [];
}

export function parseBoolean(value) {
  if (typeof value === 'string') {
      value = value.trim().toLowerCase();
      if (value === 'true') {
          return true;
      } else if (value === 'false' ) {
          return false;
      }
  }
  return Boolean(value);
}

export function parseQueryParam(paramsURL, keysToBeArrays , keysToBeBoolean = []): typeof ShipmentsFilterComponent.prototype.params {
  const params = Object.assign({}, paramsURL);

  keysToBeArrays.forEach((key) => {
    const hasAll = params[key]?.length === 0;
    if (hasAll) {
      params[key] = [];
    }

    params[key] = convertToArray(params[key])
  });

  keysToBeBoolean.forEach((key) => {
    params[key] = parseBoolean(params[key]);
  });

  return params;
}
