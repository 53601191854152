<div [formGroup]="form">
  <app-row *ngFor="let row of fieldsGroupedByRow$ | async" [align]="align">
    <ng-container *ngFor="let field of (row || [])">
      <ng-container *ngIf="field.classify === 'field'">
        <app-col
          *ngIf="(field.data.grid?.colConfig || {}) as colConfig"
          [cols]=" colConfig.cols"
          [xs]="colConfig.xs"
          [sm]="colConfig.sm"
          [md]="colConfig.md"
          [lg]="colConfig.lg"
          [xl]="colConfig.xl"
          [xxl]="colConfig.xxl"
          [offset]="colConfig?.offset"
          [ngClass]="colConfig?.class || ''"
        >
          <ng-container *ngIf="field.data?.type === FieldType.Head">
            <div
              *ngIf="field.data as head"
              class="group-title"
              [ngClass]="(head.attrs?.class || '').toString().replace(',', ' ') + ' h' + (head.level || 5)"
              [id]="head.id"
            >
              {{head.label | translate}}
            </div>
          </ng-container>
          <ng-container
            *ngIf="field.data.type !== FieldType.Head && field.data.type !== FieldType.Separator"
            [appField]="field.data"
            [group]="form"
            (change)="change.emit($event)"
            (prefixClick)="prefixClick.emit($event)"
            (suffixClick)="suffixClick.emit($event)"
            (fieldClick)="fieldClick.emit($event)"
          ></ng-container>
          <ng-container *ngIf="field.data.type === FieldType.Separator">
            <mat-divider
              class="group-divider"
              *ngIf="field.data as divider"
              [ngClass]="(divider.attrs?.class || '').toString().replace(',', ' ')"
              [id]="divider.id"
            ></mat-divider>
          </ng-container>
        </app-col>
      </ng-container>

      <ng-container *ngIf="field.classify !== 'field'">
        <app-col cols="12">
          <div [formGroup]="form.get(field.name)">
            <app-form
              [id]="id + '-' + field.name"
              [isChildForm]="true"
              [fields]="field.data"
              [value]="value && value[field.name]"
              (change)="change.emit({ field: field.name + '.' + $event.field, value: $event.value })"
              (prefixClick)="prefixClick.emit({ field: field.name + '.' + $event.field, event: $event.event })"
              (suffixClick)="suffixClick.emit({ field: field.name + '.' + $event.field, event: $event.event })"
              (fieldClick)="fieldClick.emit({ field: field.name + '.' + $event.field, event: $event.event })"
              (created)="handleInnerFormCreation(field.name, $event)"
            ></app-form>
          </div>
        </app-col>
      </ng-container>
    </ng-container>
  </app-row>

  <ng-content></ng-content>
</div>
