<section class="order-list-wrapper">
  <app-row>
    <app-col>
      <div class="py-2" *ngIf="!isTimeLineStyle && ordersNo">
        <app-row justify="space-between" align="center">
          <app-col cols="6">
            <button mat-button class="p-0 pr-2">
              <div class="flex align-center" (click)="onAddAllOrder.emit()">
                <div class="panel-title__icon">
                  <app-icon class="align-middle" size="sm" name="add-square"></app-icon>
                </div>
                <span class="h6 text-accent weight-semibold m-0">
                  {{ 'shared.accordion.All' | translate }} {{ ordersNo }} {{ 'shared.accordion.Orders' | translate }}
                </span>
              </div>
            </button>
          </app-col>
          <app-col cols="auto" class="ml-auto mr-8">
            <div class="panel-description">
              <span class="text-400 small px-2">{{'shared.accordion.Pallet' | translate}} {{ palletsNo }}</span>
              <span class="text-400 small px-2">{{'shared.accordion.Items' | translate}} {{ itemsNo }}</span>
            </div>
          </app-col>
        </app-row>
      </div>
      <div class="scrollable scrollable--y panel-height">
        <mat-accordion>
          <mat-expansion-panel
            [disabled]="!(order?.isPalletDetails || order?.order?.isPalletDetails)"
            class="panel"
            (afterExpand)="selectedOrder = item"
            (afterCollapse)="selectedOrder = null"
            *ngFor="let order of orders; let i = index"
          >
            <mat-expansion-panel-header (click)="isTimeLineStyle ? getPallets(order) : getPallets(order.order)" *ngIf="!order?.selected">
              <div *ngIf="isTimeLineStyle" class="panel__content-line"></div>
              <mat-panel-title class="panel-title pt-1">
                <div class="panel-title__icon" *ngIf="!isTimeLineStyle" (click)="handleAddOrderClick($event, order)">
                  <app-icon class="align-middle" size="sm" name="add-square"></app-icon>
                </div>

                <span class="panel__item-cyrcle" *ngIf="isTimeLineStyle">
                  <span class="panel__item-cyrcle-content">{{ i + 1 }}</span>
                </span>

                <div class="panel-title__text">
                  <h6 class="p-0 m-0 d-inline-block" [ngClass]="{'weight-normal': !isTimeLineStyle}">
                    {{isTimeLineStyle ? ('shared.accordion.OrderID' | translate) : ''}} {{ isTimeLineStyle ? order.reference : order.order.reference
                    }}
                  </h6>
                  <div class="ml-3 mt-1 d-inline-block" *ngIf="order?.order?.orderType">
                    <app-rect-tag [color]="ColorPalette.Default">
                      {{'shared.Enum.OrderType.'+ OrderTypeShortcuts[order?.order?.orderType] | translate }}
                    </app-rect-tag>
                  </div>
                  <div class="ml-3 mt-1 d-inline-block" *ngIf="order?.order?.rush">
                    <app-icon name="clock"></app-icon>
                  </div>
                  <div class="text-400 small weight-normal">
                    {{ isTimeLineStyle ? order.dropOffLocationTitle : order.order.dropOffLocationTitle }}
                  </div>
                  <div class="text-accent weight-normal small mt-1">
                    <app-icon class="align-middle" size="sm" name="temperature"></app-icon>
                    {{definitionOrderTemperatureControl[order?.temperatureControl || order.order.temperatureControl]}}
                  </div>

                  <a
                    target="_blank"
                    class="text-success text-deco"
                    [href]="order?.deliveryNoteUrl"
                    [matTooltip]="'shared.accordion.Download' | translate"
                    [matTooltipPosition]="'above'"
                  >
                    <div
                      class="panel-header__button flex justify-space-between justify-center align-center py-1"
                      *ngIf="order?.deliveryNoteUrl && isTimeLineStyle"
                    >
                      <span class="small text-success mr-6">{{ 'shared.accordion.ProofOfDelivery' | translate }}</span>
                      <app-icon name="bold-download-square" size="sm"></app-icon>
                    </div>
                  </a>
                </div>
              </mat-panel-title>

              <mat-panel-description>
                <div *ngIf="isTimeLineStyle" class="panel__content-line"></div>

                <div class="panel-description">
                  <div *ngIf="isTimeLineStyle">
                    <app-rect-tag *ngIf="(order.order?.sealNumber || order.sealNumber) as sealNumber" size="sm" theme="dashed">
                      {{'shared.accordion.SealNo' | translate}}: {{sealNumber}}
                    </app-rect-tag>
                  </div>
                  <span class="text-400 small px-2">{{ isTimeLineStyle ? (order.createdAt | date) : (order.order.createdAt | date)}}</span>
                  <span class="text-400 small px-2">
                    {{'shared.accordion.Pallet' | translate}} {{isTimeLineStyle ? order.actualNoOfPallets : order.numOfPallet}}
                  </span>
                  <span class="text-400 small px-2">
                    {{'shared.accordion.Items' | translate}} {{isTimeLineStyle ? order.actualNoOfItems : order.numOfItems}}
                  </span>
                </div>
              </mat-panel-description>
              <div class="panel__icon" *ngIf="order?.isPalletDetails" [ngClass]="{'rotate-icon': selectedOrder === item}">
                <app-icon [name]="'arrow_drop_down' " type="MaterialDesign"></app-icon>
              </div>
              <app-icon
                *ngIf="!(order?.isPalletDetails || order?.order?.isPalletDetails)"
                [popoverTrigger]="undetailedItemsPopover"
                [closeOnClickOutside]="true"
                [position]="position"
                class="icon icon--small align-middle text-primary-light cursor-pointer"
                src="exclamation-mark.svg"
              ></app-icon>
              <ng-template #undetailedItemsPopover>
                <app-order-list-line-items [orderId]="order.id"></app-order-list-line-items>
              </ng-template>
            </mat-expansion-panel-header>

            <div class="panel-list" *ngIf="!order?.selected">
              <div class="flex justify-space-between">
                <span class="mr-2 text-400 small">{{'shared.accordion.Pallet' | translate}}</span>
                <span class="mx-2 text-400 small">{{'shared.accordion.Items' | translate}}</span>
              </div>

              <ng-container *ngFor="let pallet of isTimeLineStyle ? order.pallets : order.order.pallets  ; let i = index">
                <div *ngIf="isTimeLineStyle ? true :!pallets[pallet.id]?.selected">
                  <div class="flex justify-space-between py-2 panel-list__item" [class.selected-row]="i === selectedRowIndex">
                    <div class="flex">
                      <div
                        class="panel-title__icon bg-primary-lighter"
                        *ngIf="!isTimeLineStyle"
                        (click)="onAddOrderClick.emit({order:order.order,pallet})"
                      >
                        <app-icon class="align-middle text-primary" size="sm" name="add-square"></app-icon>
                      </div>
                      <span class="mr-2 text-900 small">{{pallet?.reference || '0'}}</span>
                    </div>
                    <div>
                      <span class="mx-2 text-900 small">{{pallet?.noOfItems || '0'}}</span>
                      <app-icon
                        [popoverTrigger]="popover"
                        [closeOnClickOutside]="true"
                        [position]="position"
                        (click)="selectPallet(pallet)"
                        class="icon icon--small align-middle text-primary-light"
                        name="exclamation-mark"
                      ></app-icon>
                    </div>
                  </div>

                  <ng-template #popover>
                    <app-order-items-card [selectedPallet]="selectedPallet"></app-order-items-card>
                  </ng-template>
                  <mat-divider></mat-divider>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </app-col>
  </app-row>
</section>
