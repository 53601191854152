import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { AthrOperationCardStatusEnum } from '../enums/athr-operation-card-status.enum';

export class AthrOperationCard extends Model {
  @Expose() cardTypeId?: number;
  @Expose() cardStatus?: AthrOperationCardStatusEnum;
  @Expose() cardNumber?: string;
  @Expose() cardExpiryDate?: string;
  @Expose() driverName?: string;
  @Expose() organizationName?: string;
  @Expose() cardCategoryTypeId?: string;
  @Expose() cardTypeText?: string;
  @Expose() lastUpdate?: string;
  @Expose() cardTypeArabicText?: string;
  @Expose() cardTypeEnglishText?: string;
  @Expose() cardCategoryTypeNameArabic?: string;
  @Expose() cardCategoryTypeNameEnglish?: string;
}
