import { ColumnOptionsStateKey } from './column-options-state-key.model';
import { ColumnOptionsStatePreference } from '@shared-features/models/column-options-state-preference.model';
import { Expose, Type } from 'class-transformer';
import { Model } from '@shared-features/models/model';

export class ColumnOptionsState extends Model {
  @Expose()
  id: string;

  @Expose()
  keys: any;

  @Expose()
  @Type(() => ColumnOptionsStateKey)
  state: ColumnOptionsStateKey[];

  @Expose()
  @Type(() => ColumnOptionsStateKey)
  defaultState?: ColumnOptionsStateKey[];

  @Expose()
  @Type(() => ColumnOptionsStatePreference)
  preferences: ColumnOptionsStatePreference[];
}
