<app-timeline
  [actions]="[{id: 'request', label: ('shipments.Requests.Request' | translate)}, {id: 'response', label: ('shipments.Response' | translate)}]"
  [list]="translatedLogList"
  [handleEmptyRequestState]="handleEmptyRequestState"
  [emptyRequestActions]="[{id: 'missing_data', label: ('shipments.MissingData' | translate)}]"
  (actionClicked)="handleClickedAction($event)"
></app-timeline>

<app-modal
  #requestModal
  [title]="selectedItem?.label"
  [subTitle]=" selectedAction === 'missing_data' ? ('shipments.response' | translate) : ('shipments.' + selectedAction | translate)"
  [maxWidth]="800"
  [padding]="20"
  [textColor]="'var(--color-on-surface-high-emphasis)'"
  [editCloseIcon]="true"
>
  <div *ngIf="requestModal.isModalOpened && selectedItem">
    <app-state-section [state]="selectedItemState">
      <app-request-and-response-log
        [selectedItem]="selectedItem"
        [selectedItemPayload]="selectedItemPayload"
        (closeModal)="requestModal.closeModal()"
      ></app-request-and-response-log>
    </app-state-section>
  </div>
</app-modal>
