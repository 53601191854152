import { Model } from '../../shared-features/models/model';

export class UserDevice extends Model {

  constructor(
    public deviceId: string = '',
    public appVersion: string = '',
    public osVersion: string = '',
    public deviceModel: string = '',
    public notificationToken: string = '',
    public osType: string = 'WEB',
  ) {
    super();
  }

  toJson() {
    return JSON.stringify(this);
  }
}
