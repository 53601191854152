import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';
import { User } from '../../users/models/user';
import { StatusLabel } from "@features/rental-profit/enums/status-label.enum";

export class ShipmentStatusLabel extends Model {
  @Expose()
  id: number;

  @Expose()
  createdAt: number;

  @Expose()
  color: string;

  @Expose()
  icon: string;

  @Expose()
  label: StatusLabel;

  @Expose()
  updatedAt: number;
}
