import { Component, OnInit } from '@angular/core';
import { SharedConstants } from '@shared/model/shared-constants';

import { AppNavigationService } from '../../services/app-navigation.service';

@Component({
  selector: 'app-permission-denied-page',
  templateUrl: './permission-denied-page.component.html',
  styleUrls: ['./permission-denied-page.component.scss'],
})
export class PermissionDeniedPageComponent implements OnInit {

  // Enums
  SharedConstants = SharedConstants;

  constructor(public appNavigationService: AppNavigationService) {}

  ngOnInit(): void {}
}
