import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';
import { ShipmentAction } from "@features/shipments/enums/shipment-action.enum";
import { ShipmentRequest } from '../../shipments/models/shipment-request.model';

export class AVLGeofencingUpdates extends Model {
  @Expose() id: number;
  @Expose() currentStatus: ShipmentAction;
  @Expose() isLatestAction: boolean;
  @Expose() upcomingStatus: ShipmentAction;
  @Expose() shipment: number;
  @Expose() request: ShipmentRequest;
  @Expose() upcomingZoneRange: number;
  @Expose() createdAt: Date;
  constructor() {
    super();
  }
}
