import { Injectable } from '@angular/core';
import { Bloc } from '@core/bloc/bloc';
import { take } from 'rxjs/operators';
import {
    BlocState,
  ErrorState,
  ProcessingState,
  ReadyState,
} from '@core/bloc/bloc-state';
import { BlocEvent, InitEvent } from '@core/bloc/bloc-event';
import { Drawing } from '@shared-features/models/drawing';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { Customer } from '@features/customers/models/customer';

@Injectable()
export class OperationPerformanceFilterBloc extends Bloc<BlocEvent, BlocState> {
  constructor(private sharedApiService: SharedApiService) {
    super();
  }

  onIncomingEvents(event: BlocEvent): void {
    if (event instanceof InitEvent) {
        this.onState$.next(new ProcessingState<Drawing>(new Drawing()));
        this.sharedApiService.getAllCustomers()
          .pipe(take(1))
          .subscribe(
            (customers) => {
              this.onState$.next(new ReadyState<Customer[]>(customers));
            },
            (error) => {
              this.onState$.next(new ErrorState<Drawing>(new Drawing(), error));
            }
          );

    }
  }
}
