import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { DrawerMode } from "@shared/enums/drawer-mode.enum";
import { DrawerPosition } from "@shared/enums/drawer-position.enum";

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements AfterViewInit {
  @Input() position = DrawerPosition.Start;
  @Input() mode = DrawerMode.Side;
  @Input() hasBackdrop = true;
  @Input() drawerOpened = true;
  @Input() showCloseButton = false;
  @Input() openDrawerWidth = 0;
  @Input() applyMainPageHeight = false;
  @Output() drawerClosed = new EventEmitter();
  @ViewChild('drawer', { static: true }) drawer: MatDrawer;

  public DrawerPosition = DrawerPosition;

  constructor() {}

  ngAfterViewInit() {
    this.drawer.openedChange.subscribe((opened) => {
      if (opened) {
        document.body.classList.add('no-overflow');
      } else {
        document.body.classList.remove('no-overflow');
      }
    });
  }

  // UI Events
  onCloseButtonClicked() {
    this.drawerClosed.emit();
  }

  toggleDrawer() {
    this.drawer.toggle();
  }
}
