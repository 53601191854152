import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit {

  isNavigatorOnline = navigator.onLine;
  constructor() { }
  ngOnInit(): void {
    window.addEventListener('online', () => {
      this.isNavigatorOnline = true;
    });
    window.addEventListener('offline', () => {
      this.isNavigatorOnline = false;
    });
  }
}
