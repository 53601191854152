import { Model } from "@shared-features/models/model";
import { Expose } from "class-transformer";

export class Announcement extends Model {
  @Expose() id: number;
  @Expose() message: string;
  @Expose() url: string;
  @Expose() urlName: string;
  @Expose() isForCustomer: boolean;
  @Expose() isForAdmin: boolean;
  @Expose() createdAt: number;
 }
