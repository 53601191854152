import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { FilterService } from '@shared/services/filter.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormAction } from "@shared/enums/form.action.enum";
import { FilterSelector } from "@features/filter/enums/filter-selector.enum";
import { DrawerType } from "@shared/enums/drawer-type.enum";
import { Router, ActivatedRoute } from '@angular/router';
import { SharedConstants } from '@shared/model/shared-constants';
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { TabNavigationService } from '@shared/services/tab-navigation.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() drawerOpened = new EventEmitter<any>();
  isOpened = false;

  dispose$ = new Subject();

  filterSelector = FilterSelector;
  filterActionSubject: Subject<any> = new Subject<any>();
  filterAction = FormAction;
  sharedConstants = SharedConstants;

  tabGroup: string = '';
  tabIndex: number = null;
  pageIndex: number = null;
  params: any;

  groups = TabGroup;
  indices = TabIndex;
  lastNavigatedUrl: string;

  filterAction$: Observable<FormAction>;

  constructor(
    private filterService: FilterService,
    private router: Router,
    private route: ActivatedRoute,
    private tabNavigationService: TabNavigationService
  ) {}

  ngOnInit(): void {
    this.lastNavigatedUrl = (this.router.routerState.snapshot.url || '').split('?')[0];
    this.filterAction$ = this.filterActionSubject.asObservable();


    this.router.events
      .pipe(debounceTime(250))
      .subscribe((e) => {
      const url = (this.router.routerState.snapshot.url || '').split('?')[0];
      this.params = null;
      this.tabIndex = null;
      if (this.lastNavigatedUrl !== url) {
        this.tabGroup = '';
        this.lastNavigatedUrl = url;
      }
    });

    this.subscribeToQueryParams();
    this.subscribeToFilterButton();
  }

  ngOnDestroy(): void {
    this.dispose$.complete();
    this.filterService.clearLastPositionOnStream();
  }

  subscribeToQueryParams(): void {
    this.route.queryParams.pipe(takeUntil(this.dispose$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
        this.tabGroup = params.tabGroup;
        this.tabIndex = params.tabIndex;
        this.tabNavigationService.activatedTab$.next({ group: params.tabGroup, index: params.tabIndex });
      }

      this.filterService.URLParams$.next(this.params);
    });
  }

  // filter drawer will opened in the main page when filter button clicked
  subscribeToFilterButton(): void {
    this.filterService.filterNavigationConfig$.pipe(takeUntil(this.dispose$)).subscribe((res) => {
      if (res) {
        this.tabGroup = res.group;
        this.tabIndex = res.index;
        this.pageIndex = res.pageIndex;
        this.drawerOpened.emit({ open: true, type: DrawerType.Filters });
        this.isOpened = true;
      }
    });
  }

  // filter drawer will closed in the main page when any form button clicked
  emitEventToFilterForm(action: any) {
    this.filterActionSubject.next(action);
    this.drawerOpened.emit({ open: false, type: DrawerType.Filters });
    this.isOpened = false;
  }
}
