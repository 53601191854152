import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Fleet } from '../../fleets/models/fleet';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { CustomerType } from '@features/customers/enums/customer-type.enum';
import { FleetType } from '@features/fleets/enums/fleet-type.enum';
import { UserType } from '@features/users/enums/user-type.enum';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { InvoiceType } from '@features/invoices/enums/invoice-type.enum';
import { AuditActionType } from '@features/fleets/enums/audit-action-type.enum';
import { AuditTrailsDateRangeType } from '@features/fleets/enums/audit-trails-date-range-type.enum';
import { VatType } from '@features/invoices/enums/vat-type.enum';
import { DeliveryNoteStatus } from '@features/delivery-notes/enums/delivery-note-status.enum';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { IFilterPayload, IPagingPayload } from '../interfaces/common-api-payloads.interface';
import { ExportDeliveryNote } from '../../invoices/models/export-delivery-note';
import { ApiConstant } from '@shared/constants/api.constant';
import { AuditTrail } from '@features/fleets/models/audit-trail.model';
import { MemoizeService } from '@core/services/memoize.service';
import { User } from '@features/users/models/user';
import { Customer } from '@features/customers/models/customer';
import { InsuranceModel } from '@features/fleets/models/insurance-model';
import { Shipment } from '@features/shipments/models/shipment';
import { FleetDetails } from '@features/fleets/models/fleet-details';
import { CustomerDetails } from '@features/customers/models/customer-details';
import { Tracking } from '@features/live-tracking/models/tracking-driver';
import { DeliveryNoteDetails } from '@features/invoices/models/delivery-note-details';
import { Country } from '@shared-features/models/country';
import { Announcement } from '@shared-features/models/announcement.model';
import { RunningShipmentCheckType } from '@features/shipments/enums/running-shipment-check-type.enum';
import { IRunningShipmentPayload } from '@shared-features/interfaces/running-shipment-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedApiService {
  constructor(
    private baseAPI: ApiBaseService,
    private mapper: Mapper,
    private memoizeService: MemoizeService
  ) {}

  getAllCountries(): Observable<Country[]> {
    return this.memoizeService
      .memoize('COUNTRIES', () => this.baseAPI.get(ApiConstant.GET_ALL_COUNTRIES))
      .pipe(map((res) => this.mapper.fromJson(Country, res.data)));
  }

  getAllCustomers(customerType?: CustomerType, searchTerm?: any): Observable<Customer[]> {
    const params = filterNullEntity({
      customerType,
      searchTerm,
    });
    return this.baseAPI.get(ApiConstant.GET_ALL_CUSTOMERS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(Customer, res.data);
      })
    );
  }

  getCustomerById(customerId: string): Observable<CustomerDetails> {
    return this.baseAPI.get(ApiConstant.GET_CUSTOMER_BY_ID + customerId).pipe(
      map((res) => {
        return this.mapper.fromJson(CustomerDetails, res.data);
      })
    );
  }

  getAllActiveFreelancers(): Observable<Fleet[]> {
    return this.baseAPI.get(ApiConstant.GET_ACTIVE_FLEETS).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  getAllInsuranceCompanies(): Observable<InsuranceModel[]> {
    return this.baseAPI.get(ApiConstant.GET_ALL_INSURANCE_COMPANIES).pipe(
      map((res) => {
        return this.mapper.fromJson(InsuranceModel, res.data);
      })
    );
  }

  getAllInsuranceCovers(): Observable<InsuranceModel[]> {
    return this.baseAPI.get(ApiConstant.GET_ALL_INSURANCE_COVERS).pipe(
      map((res) => {
        return this.mapper.fromJson(InsuranceModel, res.data);
      })
    );
  }

  getLatestDriversLocation(): Observable<Tracking[]> {
    // related to drivers
    return this.baseAPI.get(ApiConstant.GET_DRIVER_LOCATION).pipe(
      map((res) => {
        return this.mapper.fromJson(Tracking, res);
      })
    );
  }

  getActiveFleets(
    includesDefault: boolean = false,
    businessType = BusinessType.B2B
  ): Observable<Fleet[]> {
    const params = filterNullEntity({ includesDefault, businessType });
    return this.baseAPI.get(ApiConstant.GET_ACTIVE_FLEETS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  getFleetById(id: number, type: FleetType): Observable<FleetDetails> {
    const params = filterNullEntity({ id, type });
    return this.baseAPI.get(ApiConstant.GET_FLEET_BY_ID, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(FleetDetails, res.data);
      })
    );
  }

  addUser(user: User, userType: UserType = UserType.ADMIN, id?: number): Observable<User> {
    const body = filterNullEntity({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobileNumber: user.mobileNumber,
      countryId: user.country?.id,
      roleIds: [user.roles[0]?.id],
      userType: userType !== UserType.ADMIN ? userType : null,
      id: userType !== UserType.ADMIN ? id : null,
      shippingType: user.shippingType,
      allowToLogin: user.allowToLogin,
      params: user.params || [],
    });

    return this.baseAPI.post(ApiConstant.ADD_USER, body).pipe(
      map((res) => {
        return this.mapper.fromJson(User, res.data);
      })
    );
  }

  // invoices / delivery notes management
  getDeliveryNotes(payload: {
    customerId?: number;
    createdBy?: number;
    deliveryNoteStatus?: DeliveryNoteStatus;
    vatType?: VatType;
    offset?: number;
    limit?: number;
    searchTerm?: string;
    dateRangeType?: DateRangeType;
    startFrom?: string;
    endAt?: string;
    invoiceType?: InvoiceType;
    searchKey?: string;
  }): Observable<DeliveryNoteDetails> {
    return this.baseAPI
      .get(ApiConstant.GET_DELIVERY_NOTES, { params: filterNullEntity(payload) })
      .pipe(map((res) => this.mapper.fromJson(DeliveryNoteDetails, res.data)));
  }

  exportDeliveyNotes({
    isForExporting,
    ...params
  }: IPagingPayload &
    Omit<IFilterPayload, 'dateRangeType'> & {
      customerId?: number;
      isForExporting?: boolean;
      deliveryNoteStatus?: DeliveryNoteStatus;
    }): Observable<ExportDeliveryNote> {
    return this.baseAPI
      .get(ApiConstant.GET_EXPORT_DELIVERY_NOTES, {
        params: filterNullEntity({
          ...params,
          isForExporting:
            isForExporting === null || typeof isForExporting === 'undefined'
              ? true
              : isForExporting,
        }),
      })
      .pipe(map((res) => this.mapper.fromJson(ExportDeliveryNote, res.data)));
  }

  getShipmentById(shipmentId: string): Observable<Shipment> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_BY_ID + shipmentId)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data)));
  }

  getShipmentDeliveryNoteById(shipmentId: string): Observable<Shipment> {
    return this.baseAPI
      .get(ApiConstant.GET_SHIPMENT_BY_ID + shipmentId)
      .pipe(map((res) => this.mapper.fromJson(Shipment, res.data)));
  }

  getAuditTrails(params: {
    objectId: number;
    actionType: AuditActionType;
    dateRange: AuditTrailsDateRangeType;
  }): Observable<AuditTrail[]> {
    const cacheId = this.memoizeService.hashInputs(params, params.dateRange);
    return this.memoizeService
      .memoize(cacheId, () => this.baseAPI.get(ApiConstant.GET_AUDIT_TRAILS, { params }))
      .pipe(map((res) => this.mapper.fromJson(AuditTrail, res.data)));
  }

  getAnnouncement(): Observable<Announcement> {
    return this.baseAPI.get(ApiConstant.GET_ANNOUNCEMENT).pipe(
      map((res) => {
        return this.mapper.fromJson(Announcement, res.data);
      })
    );
  }

  setAnnouncement(body: {
    message: string;
    urlName: string;
    url: string;
    isForCustomer: boolean;
    isForAdmin: boolean;
  }): Observable<Announcement> {
    return this.baseAPI.post(ApiConstant.SET_ANNOUNCEMENT, filterNullEntity(body)).pipe(
      map((res) => {
        return this.mapper.fromJson(Announcement, res.data);
      })
    );
  }

  getRunningShipments(payload: IRunningShipmentPayload): Observable<Shipment> {
    const { driverId, truckId, type } = payload;

    return this.baseAPI
      .get(ApiConstant.GET_RUNNING_SHIPMENTS + (driverId ? driverId : truckId), {
        params: { type },
      })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(Shipment, res.data.shipment);
        })
      );
  }
}
