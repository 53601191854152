import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { SentryConstant } from '@shared/constants';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: 'https://e09d30754dd0483b920e3ba01f18bd8a@o4504882214928384.ingest.sentry.io/4504887881170944',
  enabled: environment.production,
  enableTracing: environment.production,
  ignoreErrors: SentryConstant.IgnoredErrors,
  denyUrls: SentryConstant.DeniedUrls,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ['localhost', environment.base_url, environment.socket_url],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: environment.production ? 1.0 : 0.1,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
