<section class="receivers-list-wrapper">
  <app-list-filter-controls
    [includeFilterButton]="false"
    (sortBy)="onSortChange($event)"
    (searchValue)="setFilterParams({ searchTerm: $event, offset: 0 }, null)"
  ></app-list-filter-controls>
  <app-entity-listing
    [totalCount]="totalRowsCount"
    [state]="sectionState"
    [pageIndex]="(filters.params.offset + 1)"
    [pageSize]="filters.params.limit"
    (pageIndexChanged)="setFilterParams({ offset: $event - 1}, null)"
    [emptyStateMainLabel]="'Customers.Receivers.emptyStateMainLabel' | translate"
    [emptyStateSubLabel]="'Customers.Receivers.emptyStateSubLabel' | translate"
    [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate"
  >
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ "Customers.Table.CompanyName" | translate }}</th>
        <td mat-cell *matCellDef="let element" app-click-stop-propagation>
          <a [routerLink]="getDetailsRoute(element.id)">{{ element.name }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="contactName">
        <th mat-header-cell *matHeaderCellDef>{{ "Customers.Table.ContactName" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.contactPerson[0]?.fullName() || "________"}}</td>
      </ng-container>

      <ng-container matColumnDef="mobileNumber">
        <th mat-header-cell *matHeaderCellDef>{{ "Customers.Table.MobileNumber" | translate }}</th>
        <td mat-cell *matCellDef="let element" [class.rtl]="layoutService.websiteDirection$.value === 'rtl'">
          {{ element.contactPerson[0]?.mobileNumber || "________"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="shipments">
        <th mat-header-cell *matHeaderCellDef>{{ "Table.Shipments" | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.totalShipmentsCount || '_____' }}</td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{ "Customers.Table.Locations" | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="location-limited-text">{{ element.locations[0]?.address }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th class="center" mat-header-cell *matHeaderCellDef>{{ "Customers.Table.Actions" | translate }}</th>

        <td [matMenuTriggerFor]="menu" class="center action" mat-cell *matCellDef="let element" app-click-stop-propagation>
          <div class="flex justify-center align-center">
            <div class="flex justify-center">
              <img src="../../../../../assets/images/menu.svg" />
            </div>

            <mat-menu class="item-actions" #menu>
              <mat-option (click)="navigateToDetailsPage(element.id)">
                <span>{{ "Buttons.Details" | translate }}</span>
              </mat-option>

              <mat-option *ngIf="false && (RolesConstants.GLOBAL_RECEIVER_DELETE | userRoleChecker)">
                <span class="delete-action">{{ "Buttons.Delete" | translate }}</span>
              </mat-option>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="navigateToDetailsPage(row.id)"></tr>
    </table>
  </app-entity-listing>
</section>
