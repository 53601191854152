<ng-container [formGroup]="form">
  <mat-form-field class="dropdown w-100 default-gray no-hint" [appearance]="appearance" [class.form-field--small]="size === 'sm'">
    <div matPrefix class="prefix" *ngIf="label">{{label}}</div>
    <mat-select formControlName="range" hideSingleSelectionIndicator>
      <mat-select-trigger class="dropdown__placholder">
        <app-icon class="d-inline align-center mr-1" *ngIf="showIcon" size="sm" name="calendar"></app-icon>
        <ng-container *ngIf="selectedValue">
          <span>
            {{ selectedValue === rangeType.CUSTOM && customSelectedValue ? (customSelectedValue || '') : ("Enum."+ rangeTypeTranslation + "." +
            selectedValue | translate) }}
          </span>
        </ng-container>
      </mat-select-trigger>

      <div class="scrollable scrollable--y">
        <ng-container *ngFor="let item of rangeType | enumToArray">
          <mat-option
            *ngIf="item.key !== rangeType?.CUSTOM"
            [value]="item.value"
            [ngClass]="{'hide-option': item.key === rangeType?.CUSTOM && !showCustomDateOption}"
          >
            {{ "Enum."+ rangeTypeTranslation + "." + item.key | translate }}
          </mat-option>

          <mat-option
            *ngIf="item.key === rangeType?.CUSTOM && showCustomDateOption"
            [value]="rangeType?.CUSTOM"
            (click)="dateTimeRange ? openDateTimeRange(): handleClickPicker(picker); "
          >
            {{ "Enum."+ rangeTypeTranslation + "." + item.key | translate }}
          </mat-option>
        </ng-container>
      </div>
    </mat-select>
  </mat-form-field>

  <div class="flexible-select__hidden-item">
    <mat-date-range-input [max]="maxDate" [rangePicker]="picker">
      <input readonly formControlName="startFrom" matInput matStartDate [placeholder]="'Placeholder.StartDate' | translate" />
      <input readonly formControlName="endAt" matInput matEndDate [placeholder]="'Placeholder.EndDate' | translate" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
  <mat-date-range-picker #picker></mat-date-range-picker>

  <app-datetime-range [(open)]="isOpenDateTimeRange" [formControl]="dateTimeRangeFormControl" [hiddenInput]="true"></app-datetime-range>
</ng-container>
