export enum SortField {
  None = '',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Priority = 'priority',
  CreatedAt = 'createdAt',

  // @Bassem TODO we need to standardize the naming of enums, i left the above because they are used in another modules , i think when we apply new sorting behavior to all tables we will remove the above enums.
  NONE = '',
  NAME = 'name',
  TITLE = 'title',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PICK_UP_DATE = 'pickUpDate',
  FROM = 'from',
  TO = 'to',
  STATUS = 'status',
  COST = 'cost',
  PRICE = 'price',
  NO = 'no',
  COUNT = 'count',
  AMOUNT = 'amount',
  DUE_DAYS = 'dueDays',
  REMAINING_DUE_DAYS = 'remainingDueDays',
  INVOICE_DATE = 'invoiceDate',
  Type = 'type',
}
