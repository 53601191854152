import { Expose, Type } from 'class-transformer';
import { PalletItem } from './pallet-item.model';
import { Model } from '../../shared-features/models/model';

export class Pallet extends Model {
  @Expose() id: number;
  @Expose() reference: string;
  @Expose() batch: string;
  @Expose() noOfItems: number;
  @Expose() createdAt: Date;
  @Type(() => PalletItem)
  @Expose()
  items: PalletItem[] = [];

  @Expose() orderId?: number;
}
