import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone } from '@angular/core';
import { MapOverlayComponent } from '@shared/components/map-overlay/map-overlay.component';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-map-clusterer-overlay',
  template: `<div #content><div style="position:absolute; transform: translate(-50%, -50%)" [hidden]="!visible">
    <ng-content></ng-content>
  </div></div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class MapClustererOverlayComponent extends MapOverlayComponent {
  @Input() count: number;

  constructor(
    googleMap: GoogleMap,
    ngZone: NgZone,
    changeDetector: ChangeDetectorRef,
  ) {
    super(googleMap, ngZone, changeDetector);
  }
}
