export enum OrderTypeShortcuts {
  'R' = 'CustomerReturn',
  'RN' = 'CustomerReturnNupcoStock',
  'ZN04' = 'NupcoSalesReturns',
  'C' = 'CustomerOutboundOrders',
  'D' = 'DistributionOutboundOrder',
  'T' = 'TransferOrders',
  'V' = 'VendorSupplierReturns',
  'S' = 'SalesOrder',
  'ZN05' = 'NupcoRoyalCourt',
  'ZN02' = 'NupcoDonationIssue',
  'Z122' = 'StoTransfer',
  'SCR' = 'ScrapOrders',
  'ZNCG' = 'PrepareOrder',
  'ZNNC' = 'StockToCustomerStock',
  'ZN01' = 'SalesWithPrice',
  'ZPG1'= 'ZPG1',
  'MOI-R' = "MOIR",
}
