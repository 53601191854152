<app-state-section [state]="sectionState">
  <app-shipment-list-preview-filter
    *ngIf="!isCreateFilter && !isEditFilter"
    [form]="form"
    [params]="params"
    [isCreateFilter]="isCreateFilter"
    [isEditFilter]="isEditFilter"
    [favFiltersIndex]="favFiltersIndex"
    (setFormValues)="setFormValues($event)"
    (clickCreateFilter)="clickCreateFilter($event)"
    (clickEditFilter)="clickEditFilter($event)"
    (setFavFiltersIndex)="setFavFiltersIndex($event)"
    (closeFilter)="closeFilter.emit()"
  ></app-shipment-list-preview-filter>

  <app-shipment-list-filter-edit-and-create
    *ngIf="isCreateFilter || isEditFilter"
    [form]="form"
    [params]="params"
    [isCreateFilter]="isCreateFilter"
    [favFiltersIndex]="favFiltersIndex"
    (clickCreateFilter)="clickCreateFilter($event)"
    (clickEditFilter)="clickEditFilter($event)"
    (setFavFiltersIndex)="setFavFiltersIndex($event)"
  >
  </app-shipment-list-filter-edit-and-create>
</app-state-section>

