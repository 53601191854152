<div class="labels-list">
  <div *ngFor="let shipment of shipments" class="labels-list__item mb-3 p-3">
    <div class="row align-center mb-1 no-gutters">
      <p class="labels-list__label col-auto mb-0 pr-1">{{ "Shipments.Table.Id" | translate }}:</p>
      <a [routerLink]="routesUtil.ShipmentsViewWithoutRange.url({ id: shipment.id, type: ShipmentsListType.All })" class="col-auto">
        <span class="h6 col-auto">{{ shipment.id }}</span>
      </a>
    </div>

    <div class="row align-center mb-1 no-gutters">
      <p class="labels-list__label col-auto mb-0 pr-1">{{ "Shipments.Table.From" | translate }}:</p>
      <span class="h6 col-auto">{{ shipment.pickUpLocation?.city.name || "_______" }}</span>
    </div>

    <div class="row align-center mb-1 no-gutters">
      <p class="labels-list__label col-auto mb-0 pr-1">{{ "Shipments.Table.To" | translate }}:</p>
      <span class="h6 col-auto">{{ shipment.dropOffLocation?.city.name || "_______" }}</span>
    </div>

    <div class="row align-center mb-1 no-gutters">
      <p class="labels-list__label col-auto mb-0 pr-1">{{ "Shipments.Table.TotalDistance" | translate }}:</p>
      <span class="h6 col-auto">{{ shipment.totalDistance ? ((+shipment.totalDistance)?.toFixed(2) ) : "0" }} {{"Shipments.km" | translate}}</span>
    </div>
  </div>
</div>
