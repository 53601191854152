import { Component,  OnInit } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { Announcement } from '@shared-features/models/announcement.model';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-announcement-dialog',
  templateUrl: './announcement-dialog.component.html',
  styleUrls: ['./announcement-dialog.component.scss']
})
export class AnnouncementDialogComponent extends BaseComponent implements OnInit {
  form:FormGroup
  announcement:Announcement
  showDialog:boolean = false

  constructor(
    
    private formBuilder: FormBuilder,
    private sharedApiService:SharedApiService,
    private matDialogRef:MatDialogRef<AnnouncementDialogComponent>
  ) {
    super()
  }

  ngOnInit() {
    this.getAnnouncement()
  }

  initForm(){
    this.form = this.formBuilder.group({
      message: [this.announcement?.message ,[Validators.required , Validators.maxLength(100)]],
      urlName:[this.announcement?.urlName],
      url:[this.announcement?.url],
      isForCustomer: [this.announcement?.isForCustomer],
      isForAdmin: [this.announcement?.isForAdmin]
    })

    this.form.get("url").valueChanges.pipe(
      debounceTime(300)
    ).subscribe(() => {
      this.updateUrlNameValidation();
    });
  }

  updateUrlNameValidation() {
    const urlControl = this.form.get('url');
    const urlNameControl = this.form.get('urlName');

    if (urlControl.value) {
      urlNameControl.markAsTouched();
      urlNameControl.setValidators(Validators.required);
    } else {
      urlNameControl.clearValidators();
    }
    urlNameControl.updateValueAndValidity();
  }

  getAnnouncement(){
    this.load(this.sharedApiService.getAnnouncement(),{isLoadingTransparent:true}).subscribe((announcement) => {
      this.announcement = announcement
      this.showDialog = true
      this.initForm()
    })
  }

  setAnnouncement(){
    const {message,url,isForCustomer,isForAdmin,urlName} = this.form.value

    const payload={
      message,
      urlName,
      url,
      isForCustomer,
      isForAdmin
    }

    this.load(this.sharedApiService.setAnnouncement(payload),{isLoadingTransparent:true}).subscribe(res=>{
      this.matDialogRef.close()
    })
  }

}
