import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class OpsScore extends Model {
  @Expose()
  tripsCount: number;

  @Expose()
  customersCount: number;

  @Expose()
  externalCount: number;

  @Expose()
  internalCount: number;

  @Expose()
  totalMargin: number;

  @Expose()
  totalMissingDN: number;

  @Expose()
  averageMargin: number;

  @Expose()
  incentive: number;

  @Expose()
  carriersCount: number;

  @Expose()
  freelancersCount: number;
}
