import { ChangeDetectorRef, Component, HostBinding, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({ template: '' })
// tslint:disable-next-line:component-class-suffix
export abstract class GridBase implements OnInit, OnChanges, OnDestroy {
  protected abstract changeDetector: ChangeDetectorRef;

  @HostBinding('class') classList;

  classList$ = new Subject<string[]>();
  destroy$ = new Subject();

  ngOnInit(): void {
    this.classList$
      .pipe(
        takeUntil(this.destroy$),
      ).subscribe((classList: string[]) => {
      this.classList = classList.join(' ');
      this.changeDetector.markForCheck();
    });
    this.calculateClassList();
  }

  ngOnChanges(): void {
    this.calculateClassList();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  abstract calculateClassList(): void;
}
