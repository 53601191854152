<app-state-section [state]="offset ? SectionStateStatus.Ready : sectionState">
  <div class="search" [class.search__relative]="!searchToogle">
    <app-icon class="icon icon--xsmall align-center d-inline d-none-md search__icon text-primary" name="search"
      (click)="searchToogle = !searchToogle" *ngIf="!searchToogle" size="sm"></app-icon>

    <div class="search__input d-none d-block-md"
      [ngClass]="{'search__open-search':searchToogle,'search__close-search':closeSearch }">
      <app-icon name="close" type="MaterialDesign" *ngIf="searchToogle"
        class="search__icon crucial-text clickable icon icon--small text-primary" (click)="clear()"></app-icon>

      <input type="text" placeholder="{{ 'Placeholder.Search' | translate }}" [formControl]="searchControl" />

      <app-icon class="d-inline align-center icon icon--xsmall ml-md-3"
        [ngClass]="{'mr-3' : searchToogle, 'text-primary': searchToogle , 'text-primary-200':searchToogle}"
        name="search" size="sm"></app-icon>
    </div>

    <div class="search__options" *ngIf="searchControl.value && showSearchOptions">
      <p class="crucial-text px-3 my-1">{{ "Placeholder.SearchFor" | translate }}</p>

      <div class="px-2 my-1">
        <mat-divider class="pos-static"></mat-divider>
      </div>

      <ul class="search__options-list" *ngFor="let category of SearchCategory | enumToArray">
        <li class="search__option crucial-text" (click)="searchByCategory(category.value)">
          {{ "NavigationBar.SubMenu." + category.value | translate }}
        </li>
      </ul>
    </div>

    <div class="search__options" [hidden]="!showSearchResult">
      <div class="scrollable scrollable--y" #scrollElement
        style="margin: 0 -1rem; padding: 0 1rem; max-height: 320px; overflow-x: hidden; background-color: white">
        <ul class="search__options-list" *ngFor="let entity of entities; last as isLast">
          <li class="search__option crucial-text" (click)="navigateToDetailsPage(entity.id)">{{ entity.value | translate
            }}</li>
          <div class="px-2 my-1" *ngIf="!isLast">
            <mat-divider class="pos-static"></mat-divider>
          </div>
        </ul>

        <div class="pb-2" #loadMoreTrigger>
          <div class="flex align-center justify-center" *ngIf="loading">
            <app-spinner [small]="true"></app-spinner>
          </div>
          <div class="flex align-center text-600 weight-semibold pb-1 pt-6" *ngIf="endOfResult">
            <small class="px-3">{{'General.noMoreResult' | translate}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-state-section>