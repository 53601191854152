import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration } from '@shared/utils/duration.util';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: string | number | Date, type: 'hours'|'date' = 'hours', startDate?): unknown {
    if (type === 'date') {
      const start = startDate ? new Date(startDate) : new Date();
      const end = new Date(value);
      return formatDuration(end.getTime() - start.getTime(), false);
    }

    return formatDuration(+value * 1000 * 60 * 60, false);
  }
}
