import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { DrawerType } from '@shared/enums/drawer-type.enum';
import { DrawerPosition } from '@shared/enums/drawer-position.enum';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';
import { LayoutService } from '@shared/services/layout.service';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit, OnDestroy {
  @Input() navItems: NavBarItem[];

  sectionState = SectionStateStatus.Ready;
  drawerPosition: DrawerPosition = DrawerPosition.End;
  drawerType: DrawerType = null;
  DrawerType = DrawerType;
  drawerOpened = false;
  navbarCollapsed = false;
  destroy$ = new Subject();
  mobileBreakpoint = 960;
  openMenu = false;

  constructor(
    private layoutService: LayoutService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(
          (event) => event instanceof RouteConfigLoadStart || event instanceof RouteConfigLoadEnd
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        if (event instanceof RouteConfigLoadStart) {
          this.sectionState = SectionStateStatus.Loading;
        } else {
          this.sectionState = SectionStateStatus.Ready;
        }
      });

    if (window.innerWidth < this.mobileBreakpoint) {
      this.navbarCollapsed = false;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onDrawerOpened(event): void {
    this.drawerType = event.type;
    if (event.open) {
      this.drawerOpened = event.open;
      this.drawerPosition = DrawerPosition.End;
    }
  }

  onDrawerClosed(): void {
    if (this.layoutService.websiteDirection$.value === 'rtl') {
      this.drawerOpened = false;
    } else {
      setTimeout(() => {
        this.drawerOpened = false;
      }, 250);
    }

    this.drawerType = null;
  }

  onNavbarCollapseChange(event: boolean): void {
    this.navbarCollapsed = event;
  }

  handelOpenMenu(event) {
    this.openMenu = event;
  }
}
