import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import { environment } from '@env/environment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, retry, switchMap, tap } from 'rxjs/operators';
import { Mapper } from '@core/services/mapper';
import { ApiBaseService } from '@core/services/api-base.service';
import { UsersApiService } from '@features/users/services/users-api.service';
import { DynamicScriptService } from '@shared/services/dynamic-script.service';
import { AuthService } from '@core/services/auth.service';
import { LazyLibId } from '@shared/enums/lazy-lib-id.enum';
import { LazyLibURL } from '@shared/enums/lazy-lib-url.enum';
import { ApiConstant } from '@shared/constants';

declare var zE: any;
@Injectable({ providedIn: 'root' })
export class ZendeskService {
  private loggedIn = new BehaviorSubject(false);
  constructor(
    private baseAPI: ApiBaseService,
    private mapper: Mapper,
    private userApiService: UsersApiService,
    private authService: AuthService,
    private dynamicScriptService: DynamicScriptService
  ) {}

  init(): void {
    combineLatest([fromPromise(this.loadScript()), this.authService.currentUser$.asObservable()])
      .pipe(
        debounceTime(1500),
        filter(() => !!zE),
        tap(([_, user]) => zE && this.close()),
        tap(([_, user]) => (zE && this.loggedIn.value && !user?.id ? this.logout() : null)),
        switchMap(([_, user]) =>
          user?.id && !this.loggedIn.value ? this.getCustomerSupportToken() : of(null)
        )
      )
      .subscribe((token) => {
        if (token) {
          this.logInOnOpen(token);
        }
      });
  }

  loadScript(): Promise<any> {
    const { zenDeskKey } = environment;
    return zenDeskKey
      ? this.dynamicScriptService.loadDynamicScript(
          LazyLibId.zenDesk,
          `${LazyLibURL.zenDesk}?key=${zenDeskKey}`
        )
      : Promise.reject();
  }

  getCustomerSupportToken(): Observable<string> {
    return this.baseAPI.post(ApiConstant.GET_SUPPORT_TOKEN, {}).pipe(
      map((res) => res.data.token),
      retry(3),
      catchError((err) => {
        console.error('Getting Error while getting support token');
        console.error(err);
        return of(null);
      })
    );
  }

  private logInOnOpen(token): void {
    if (!this.loggedIn.value) {
      zE('messenger', 'loginUser', (callback) => {
        try {
          callback(token);
          this.loggedIn.next(true);
        } catch (e) {
          console.error(e);
        }
      });
    }
  }

  private logout(): void {
    zE('messenger', 'logoutUser');
    this.close();
    this.loggedIn.next(false);
  }

  private close(): void {
    zE('messenger', 'close');
  }

  private open(): void {
    zE('messenger', 'open');
    zE('messenger:set', 'cookies', true);
  }
}
