import { Model } from '@shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { AvlDeviceAttributes } from './avl-device-attributes';

export class AvlDevice extends Model {
  @Expose() id: number;
  @Expose() uniqueId: string;
  @Expose() name: string;
  @Expose() status: string;
  @Expose() disabled: boolean;
  @Expose() lastUpdate: string;
  @Expose() positionId: number;
  @Expose() groupId: number;
  @Expose() phone: string;
  @Expose() model: string;
  @Expose() contact: string;
  @Expose() category: string;
  @Expose() selected: boolean;
  @Type(() => AvlDeviceAttributes)
  @Expose()
  attributes: AvlDeviceAttributes = new AvlDeviceAttributes();

  constructor() {
    super();
  }
}
