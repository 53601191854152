export function padNumber(n: number|string, length = 2): string {
  const nStr = `${n}`;

  if (nStr.length >= length) {
    return nStr;
  }

  const str = `${'0'.repeat(length)}${n}`;
  return str.slice(str.length - length);
}

export function getDurationInDetails(duration: number) {
  const dFactor = 1000 * 60 * 60 * 24;
  const hFactor = 1000 * 60 * 60;
  const mFactor = 1000 * 60;
  const days = Math.floor(duration / dFactor);
  const hours = Math.floor((duration - days * dFactor) / hFactor);
  const mins = Math.floor((duration - days * dFactor - hours * hFactor) / mFactor);
  const secs = Math.floor((duration - days * dFactor - hours * hFactor - mins * mFactor) / 1000);
  return {
    days,
    hours,
    mins,
    secs
  }
}
/**
 * parse duration to be 00:00:00
 * @param duration number - timestamp in millisecond
 * @param withSeconds boolean - include seconds in format or not
 */
export function formatDuration(duration: number, withSeconds = true): string {
  const formatResult = getDurationInDetails(duration);
  const secondsString = withSeconds ? `:${padNumber(formatResult.secs)}` : '';
  return `${formatResult.days ? padNumber(formatResult.days) + ':' : ''}${padNumber(formatResult.hours)}:${padNumber(formatResult.mins)}${secondsString}`;
}

export function formatDurationWithUnits(duration: number, withSeconds = true): string {
  const formatResult = getDurationInDetails(duration);
  const secondsString = withSeconds ? `m  ${padNumber(formatResult.secs)}s` : 'm';
  return `${formatResult.days ? padNumber(formatResult.days) + 'd  ' : ''}${padNumber(formatResult.hours)}hr  ${padNumber(formatResult.mins)}${secondsString}`;
}
