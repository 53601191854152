import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import { Observable } from 'rxjs';
import { ApiConstant } from '@shared/constants/api.constant';
import { map } from 'rxjs/operators';
import { ReceiverType } from "@features/receivers/enums/receiver-type.enum";
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { HttpParams } from '@angular/common/http';
import { IFilterPayload, IPagingPayload, ISortPayload } from '@shared-features/interfaces';
import { Receivers } from '@features/receivers/models/receivers';
import { Receiver } from '@features/receivers/models/receiver';

@Injectable({
  providedIn: 'root',
})
export class ReceiversCommonApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  // shipments
  getAllReceivers(receiverType: ReceiverType, customerId: number = null): Observable<Receiver[]> {
    let params = new HttpParams();
    params = params.append('receiverType', receiverType);
    if (customerId) { params = params.append('customerId', customerId.toString()); }
    return this.baseAPI.get(ApiConstant.GET_ALL_RECEIVERS, { params })
      .pipe(map((res) => this.mapper.fromJson(Receiver, res.data)));
  }

  getPaginatedReceivers(params: ISortPayload&IPagingPayload&IFilterPayload&{receiverType?: ReceiverType}): Observable<Receivers> {
    return this.baseAPI.get(ApiConstant.GET_PAGINATED_RECEIVERS, { params: filterNullEntity(params)})
      .pipe(map((res) => this.mapper.fromJson(Receivers, res.data)));
  }

  // shipments & receivers
  getReceiverById(receiverId: number): Observable<Receiver> {
    return this.baseAPI.get(ApiConstant.GET_RECEIVER_BY_ID + receiverId).pipe(
      map((res) => {
        return this.mapper.fromJson(Receiver, res.data);
      })
    );
  }

  // shipments & receivers
  addReceiver(receiver: Receiver, receiverType: ReceiverType, locationIds: number[], customerId?: number): Observable<Receiver> {
    receiver.contactPerson.forEach((contact) => {
      for (let propName in contact) {
        if (contact[propName] === null || contact[propName] === undefined || contact[propName] === "") {
          delete contact[propName];
        }
      }
    });

    const body = filterNullEntity({
      name: receiver.name,
      receiverType,
      contactPerson: receiver.contactPerson,
      locationIds,
      customerId: (receiverType === ReceiverType.Customer) ? customerId : null,
    });

    return this.baseAPI.post(ApiConstant.ADD_RECEIVER, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Receiver, res.data);
      })
    );
  }
}
