import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { FilterComponent } from './components/filter/filter.component';
import { ShipmentsFilterComponent } from './components/shipments-filter/shipments-filter.component';
import { CustomersFilterComponent } from './components/customers-filter/customers-filter.component';
import { ProvidersFilterComponent } from './components/providers-filter/providers-filter.component';
import { OrderStatusFilterComponent } from './components/order-status-filter/order-status-filter.component';
import { DriversFilterComponent } from './components/drivers-filter/drivers-filter.component';
import { DeliveryNotesFilterComponent } from './components/delivery-notes-filter/delivery-notes-filter/delivery-notes-filter.component';
import { CustomerInvoicesFilterComponent } from './components/customer-invoices-filter/customer-invoices-filter/customer-invoices-filter.component';
import { OperationPerformanceFilterComponent } from './components/operation-performance-filter/operation-performance-filter.component';
import { TransportationPaymentsShipmentsFilterComponent } from './components/transportation-payments-shipments-filter/transportation-payments-shipments-filter.component';
import { B2cRequestLogsFilterComponent } from './components/b2c-request-logs-filter/b2c-request-logs-filter.component';
import { B2cRulesFilterComponent } from './components/b2c-rules-filter/b2c-rules-filter.component';

@NgModule({
  declarations: [
    FilterComponent,
    CustomersFilterComponent,
    ShipmentsFilterComponent,
    ProvidersFilterComponent,
    OrderStatusFilterComponent,
    DriversFilterComponent,
    DeliveryNotesFilterComponent,
    CustomerInvoicesFilterComponent,
    OperationPerformanceFilterComponent,
    TransportationPaymentsShipmentsFilterComponent,
    B2cRequestLogsFilterComponent,
    B2cRulesFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [FilterComponent],
})
export class FilterModule {}
