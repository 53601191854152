import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { Contract } from '@features/contracts/models/contract';

export class ShipmentPrices extends Model {
  @Expose() fleetSpotPrice: number;
  @Expose() customerSpotPrice: number;

  @Type(() => Contract)
  @Expose()
  costContract: Contract;
  @Expose()
  priceContract: Contract;
  @Expose()
  costUpdatedAt: Date;
  @Expose()
  priceUpdatedAt: Date;
}
