import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ColumnOptionsStateKey } from '@shared-features/models/column-options-state-key.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { enumToArray } from '@shared/utils/enum-to-array.util';
import { EnumModel } from '@shared/model/enum-model';

@Component({
  selector: 'app-column-options-field-list',
  templateUrl: './column-options-field-list.component.html',
  styleUrls: ['./column-options-field-list.component.scss'],
})
export class ColumnOptionsFieldListComponent implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() keys: any[];
  @Input() state: ColumnOptionsStateKey[];
  @Output() stateUpdate = new EventEmitter();

  optionsPerColumn: { [order: number]: EnumModel[] };

  internalStateChange = false;

  get form(): FormArray {
    return this.formGroup.get('state') as FormArray;
  }

  draggedColumnOrder = -1;
  draggedOverColumnOrder = -1;

  ngOnChanges({ state }: SimpleChanges): void {
    if (state?.currentValue !== state?.previousValue) {
      if (!this.internalStateChange) {
        this.setStateOptions();
        this.initForm();
      } else {
        this.internalStateChange = false;
      }
    }
  }

  initForm(): void {
    this.formGroup.removeControl('state');
    this.formGroup.addControl('state', new FormArray([]));

    const state = [...(this.state || [])];

    state
      .sort((a, b) => a.order - b.order)
      .forEach((item) => {
        this.form.push(new FormControl(item.key, [Validators.required]));
      });

    this.form.valueChanges.subscribe((values) => {
      const newState = (values || []).map((key, order) => ({ key, order }));
      this.internalStateChange = true;
      this.stateUpdate.emit(newState);
    });
  }

  swapOrder(): void {
    if (this.draggedOverColumnOrder > -1 && this.draggedColumnOrder > -1) {
      const draggedKey = this.state[this.draggedColumnOrder]?.key;
      const draggedOverKey = this.state[this.draggedOverColumnOrder]?.key;
      const newState = this.state.map((item) => {
        if (item.order === this.draggedColumnOrder) {
          return { ...item, key: draggedOverKey };
        } else if (item.order === this.draggedOverColumnOrder) {
          return { ...item, key: draggedKey };
        }
        return { ...item };
      });

      this.draggedOverColumnOrder = -1;
      this.draggedColumnOrder = -1;
      // this.setStateOptions();
      // this.initForm();
      this.internalStateChange = false;
      this.stateUpdate.emit(newState);
    }
  }

  removeColumn(key, order): void {
    const state = this.state
      .filter((item) => item.key !== key)
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        ...item,
        order: item.order < order ? item.order : item.order - 1,
      }));

    // this.setStateOptions();
    // this.initForm();
    this.internalStateChange = false;
    this.stateUpdate.emit(state);
  }

  addMoreColumns(): void {
    const control = new FormControl(null, [Validators.required]);
    this.optionsPerColumn[this.form.length] = enumToArray(this.keys).filter(
      (item) => !this.form.value.includes(item.key)
    );
    this.form.push(control);
    control.updateValueAndValidity({ onlySelf: false });
  }

  setStateOptions(): void {
    const state = this.state || [];
    this.optionsPerColumn = {};
    state.forEach((item) => {
      const options = enumToArray(this.keys).filter((keyItem) => {
        const exist = state.find(
          (stateItem) => stateItem.key !== item.key && keyItem.key === stateItem.key
        );
        return !exist;
      });
      this.optionsPerColumn[item.order] = options;
    });
  }
}
