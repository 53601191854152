export enum RequestCreatedByType {
  Madar = 'MADAR',
  Admin = 'ADMIN',
  Customer = 'CUSTOMER',
  Fleet = 'FLEET',
  Driver = 'DRIVER',
  Receiver = 'RECEIVER',
  Organization = 'ORGANIZATION',
  Vendor = 'VENDOR',
  Bayan = 'Bayan',
  Cement = "CEMENT",
  Barq = 'BARQ',
  Pharmaciaty = 'PHARMACIATY',
  Dal = 'DAL',
  Saee = 'SAEE',
  'Wfc@Madar' = 'WFC@MADAR',
}
