import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptService {
  private loading: { [key: string]: boolean } = {};
  private loaded: { [key: string]: boolean } = {};

  // Returns a Promise that loads the script
  loadDynamicScript(id: string, url: string, delay = 1000): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isScriptElementAlreadyIncluded(id) && this.loaded[id]) {
        resolve();
      } else if (this.isScriptElementAlreadyIncluded(id)) {
        this.loading[id] = true;
        setTimeout(() => {
          if (this.loaded[id]) {
            resolve();
          } else {
            reject();
          }
        }, delay);
      } else if (!this.loading[id]) {
        this.loading[id] = true;
        const scriptElement = window.document.createElement('script');
        scriptElement.onload = () => {
          this.loading[id] = false;
          this.loaded[id] = true;
          resolve();
        };

        scriptElement.id = id;
        scriptElement.src = url;

        scriptElement.onerror = () => {
          this.loading[id] = false;
          reject();
        };

        window.document.body.appendChild(scriptElement);
      }
    });
  }

  private isScriptElementAlreadyIncluded(id: string): boolean {
    return !!document.getElementById(id);
  }
}
