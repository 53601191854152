<div class="state-container" [class.page]="isPageSection">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="SectionStateStatus.Loading">
      <div class="state background fade-in" [class.ready-s]="transparentBackground">
        <app-spinner></app-spinner>
        <div *ngIf="loadingLabel" class="loadin-text">
          <span class="ellipsis">{{ loadingLabel }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionStateStatus.Disconnected">
      <div class="state background problematic fade-in" [class.ready-s]="transparentBackground">
        <!-- @rehab:i didn't find any error icon in the app will ask menna to provide us one -->
        <!-- <div class="error-icon">
          <img src="assets/images/error-state.svg" alt="error.svg" />
        </div> -->
        <div class="error-message">
          <span>Connection was broken while loading</span>
        </div>
        <div class="try-again" (click)="onReload()">Try Again</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionStateStatus.Error">
      <div class="state background problematic fade-in" [class.ready-s]="transparentBackground">
        <!-- <div class="error-icon">
          <app-icon  name="error" ></app-icon>
        </div> -->
        <div *ngIf="errorStateLabel" class="error-message">
          <span>{{ errorStateLabel }}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionStateStatus.Empty">
      <div class="state background empty fade-in">
        <div class="empty-icon">
          <img [src]="emptyStateImagePath || 'assets/images/empty-state.svg'" />
        </div>
        <div class="empty-message">
          <h3 class="main-text">{{ emptyStateMainLabel }}</h3>
          <p class="sub-text">{{ emptyStateSubLabel }}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionStateStatus.ComingSoon">
      <div class="state background coming-soon fade-in">
        <div class="coming-soon-icon">
          <img src="../../../../assets/images/coming-soon-state.svg" />
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionStateStatus.SearchResultEmpty">
      <div class="state background search-result-empty fade-in">
        <div class="search-result-empty-icon">
          <img src="../../../../assets/images/search-result-empty-state.svg" />
        </div>
        <div class="search-result-empty-message">
          <h3 class="main-text">{{ "SectionStateStatus.SearchResultEmptyStateMainLabel" | translate }}</h3>
          <p class="sub-text">{{ "SectionStateStatus.SearchResultEmptyStateSubLabel" | translate}}</p>
        </div>
        <button mat-raised-button class="btn-secondary btn" (click)="onClearButtonClicked()">{{ "Buttons.Clear" | translate }}</button>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        state === SectionStateStatus.Ready ||
        state === SectionStateStatus.LoadingTransparent
      "
    >
      <div *ngIf="state === SectionStateStatus.LoadingTransparent" class="background transparent" [class.flexible-height]="flexibleHeight">
        <app-spinner></app-spinner>
        <div *ngIf="loadingLabel" class="loadin-text">
          <span class="ellipsis">{{ loadingLabel }}</span>
        </div>
      </div>
      <div class="fade-in state-section__content" [class.ready-s]="transparentBackground" [class.active]="SectionStateStatus.Ready">
        <ng-content></ng-content>
      </div>
    </ng-container>
  </ng-container>
</div>
