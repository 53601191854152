import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from 'moment';
import { Moment } from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export const ManufactureYearFormat = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    yearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    yearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-manufacture-year',
  templateUrl: './manufacture-year.component.html',
  styleUrls: ['./manufacture-year.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ManufactureYearComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: ManufactureYearFormat },
  ],
})
export class ManufactureYearComponent implements ControlValueAccessor {
  form: UntypedFormGroup;
  private _value: any;

  onChanged: any = () => {};
  onTouched: any = () => {};

  maxManufactureYear = new Date();
  destroy$ = new Subject();

  @Input() showLabel: boolean = true;
  @Input() isExternal

  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChanged(this._value);
    this.onTouched();
  }

  constructor() {
    this.form = new UntypedFormGroup({
      year: new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  yearSelected(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    let ctrlValue = moment();
    ctrlValue.year(normalizedYear.year());
    this.form.get('year').setValue(ctrlValue);
    datepicker.close();
  }

  writeValue(value: any): void {
    this.value = value;
    this.form.get('year').setValue(value);
  }

  registerOnChange(onChange: any): void {
    this.form.get('year').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(onChange);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
