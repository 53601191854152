import { Component, Inject,  OnInit } from '@angular/core';
import { BaseComponent } from '../base-component/base.component';
import { generateComplexPassword } from '@shared/utils/generate-complex-password.util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationsApiService } from '@features/organization/services/organizations-api.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-generate-complex-password-dialog',
  templateUrl: './generate-complex-password-dialog.component.html',
  styleUrls: ['./generate-complex-password-dialog.component.scss']
})
export class GenerateComplexPasswordDialog extends BaseComponent implements OnInit {

  generatedPassword: string
  constructor(
    
    private organizationsApiService: OrganizationsApiService,
    private clipboard: Clipboard,
    private matDialogRef: MatDialogRef<GenerateComplexPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {userId:number},

  ) {
    super()
  }

  ngOnInit() {
    this.generatedPassword = generateComplexPassword(10)
  }

  confirmPassword(){
    const payload = {
      id:this.data.userId,
      tempPassword: this.generatedPassword
    }

    this.load(this.organizationsApiService.setTempPassword(payload), {
      isLoadingTransparent: true,
      successMessagePath: 'SuccessMessages.TemporaryPasswordHasBeenSetSuccessfully',
    }).subscribe((res) => {
      this.matDialogRef.close();
    });
  }
  clickToCopy(){
    this.clipboard.copy(this.generatedPassword);
    this.showSuccessMessage('SuccessMessages.passwordCopiedSuccessfully');

  }
}
