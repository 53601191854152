<div class="container flex" [class.not-read]="!notification.isRead" (click)="onNotificationClicked()">
  <div class="flex column details">
    <span class="title">
      {{ notification.title }}
      <span *ngIf="notification?.elementId">({{ "Shipments.Table.Id" | translate }}: {{ notification.elementId }})</span>
    </span>
    <span class="body">{{ notification.body }}</span>
  </div>
  <div class="box center">
    <app-icon name=" arrow_forward_ios " type="MaterialDesign" class="mat-icon-rtl-mirror"></app-icon>
  </div>
</div>
