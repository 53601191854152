<app-row>
  <app-col
    *ngFor="let item of items"
    [cols]="item.cols?.cols || 12"
    [xs]="item.cols?.xs"
    [sm]="item.cols?.sm || 4"
    [md]="item.cols?.md"
    [lg]="item.cols?.lg"
    [xl]="item.cols?.xl"
    [xxl]="item.cols?.xxl"
    [offset]="item.cols?.offset"
  >
    <div class="info-list" [ngClass]="['info-list--'+ type]">
      <app-labeled-info-item [item]="item" [type]="type" [direction]="direction"></app-labeled-info-item>
    </div>
  </app-col>
</app-row>
