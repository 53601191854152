import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class UserParam extends Model {
  @Expose()
  id: number;

  @Expose()
  creationTemplate: 'Basic' | 'Cement';

  @Expose()
  name: string;

  @Expose()
  key: string;

  @Expose()
  value: string;
}
