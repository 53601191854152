import { Expose, Type } from 'class-transformer';
import { AvlDevice } from '../../live-tracking/models/avl-device';
import { AvlDevicePosition } from '../../live-tracking/models/avl-device-position';
import { Model } from '../../shared-features/models/model';

export class ShipmentRoadFromTraccar extends Model {

    @Type(()=> AvlDevicePosition)
    @Expose()
    deviceHistory: AvlDevicePosition[]=[];

    @Type(()=> AvlDevice)
    @Expose()
    deviceStatus: AvlDevice = new AvlDevice();

}
