import {
  Component,
  OnInit,
  ViewChild,
  
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Receiver } from '@features/receivers/models/receiver';
import { MatSort } from '@angular/material/sort';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { Status } from "@features/shipments/enums/status.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { ReceiverType } from "@features/receivers/enums/receiver-type.enum";
import { Router } from '@angular/router';
import { ConfigConstant } from '@shared/constants/config.constant';
import { LayoutService } from '@shared/services/layout.service';
import { RolesConstants } from '@shared/model/roles-constants';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { handleSortChange } from '@shared/utils/handle-sort-change.util';
import { ReceiversCommonApiService } from '@shared-features/services/receivers-common-api';
import { Receivers } from '@features/receivers/models/receivers';

@Component({
  selector: 'app-receivers-list',
  templateUrl: './receivers-list.component.html',
  styleUrls: ['./receivers-list.component.scss'],
})
export class ReceiversListComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() receiverType: ReceiverType;
  public dataSource: MatTableDataSource<Receiver>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'name',
    'contactName',
    'mobileNumber',
    'shipments',
    'address',
    'action',
  ];

  status = Status;
  sectionState: SectionStateStatus;
  customerId: number;

  receiverTypes = ReceiverType;
  RolesConstants = RolesConstants;

  totalRowsCount: number;

  constructor(
    
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private receiversCommonApiService: ReceiversCommonApiService
  ) {
    super();
  }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params?.id;
    this.filters.fields = { customerId: id || null, receiverType: this.receiverType };
    this.filters.params = {
      dateRangeType: DateRangeType.ALL_TIME,
      offset: ConfigConstant.INIT_PAGE_OFFSET,
      limit: ConfigConstant.PAGE_SIZE,
    };

    this.filters.data$.pipe(takeUntil(this.destroy$), debounceTime(250)).subscribe(() => {
      this.getReceivers();
    });
  }

  ngOnChanges({ receiverType }: SimpleChanges): void {
    if (receiverType?.currentValue) {
      this.setFilterParams({ offset: 0, receiverType: this.receiverType });
    }
  }

  getReceivers(): void {
    this.load(this.receiversCommonApiService.getPaginatedReceivers(this.filters.params), {
      contentPath: 'receivers',
    }).subscribe((data) => {
      this.setupTable(data);
    });
  }

  onSortChange(value): void {
    this.setFilterParams(handleSortChange(value), null);
  }

  setFilterParams(params, displays?: any): void {
    this.filters.params = params;
    if (displays) {
      this.filters.display = displays;
    }
  }

  navigateToDetailsPage(receiverId: number): void {
    if (this.receiverType === ReceiverType.Customer) {
      this.router.navigate([
        RoutesUtil.CustomersReceiversView.url({ customerId: this.customerId, receiverId }),
      ]);
    } else {
      this.router.navigate([RoutesUtil.ReceiversView.url({ receiverId })]);
    }
  }

  getDetailsRoute(receiverId: number): string {
    const detailsRoute = this.receiverType === ReceiverType.Customer
      ? RoutesUtil.CustomersReceiversView.url({ customerId: this.customerId, receiverId })
      : RoutesUtil.ReceiversView.url({ receiverId });

    return detailsRoute;
  }

  private setupTable(data: Receivers): void {
    this.dataSource = new MatTableDataSource<Receiver>(data.receivers);
    this.totalRowsCount = data.count;
  }
}
