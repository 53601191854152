import { Expose } from 'class-transformer';
import { Model } from '@shared-features/models/model';

export class ShipmentHistoryUpdate extends Model {
  @Expose()
  newDate: number;

  @Expose()
  updatedBy: number;

  @Expose()
  updatedByName: string;

  @Expose()
  previousDate: number;
}
