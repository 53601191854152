import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class AvlShipmentStop extends Model {
  @Expose() deviceId: number;
  @Expose() deviceName: string;
  @Expose() distance: number;
  @Expose() averageSpeed: number;
  @Expose() maxSpeed: number;
  @Expose() spentFuel: number;
  @Expose() startOdometer: number;
  @Expose() endOdometer: number;
  @Expose() startTime: Date;
  @Expose() endTime: Date;
  @Expose() positionId: number;
  @Expose() latitude: number;
  @Expose() longitude: number;
  @Expose() address: string;
  @Expose() duration: number;
  @Expose() engineHours: number;
}
