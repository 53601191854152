import { Component, EventEmitter,  Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '@features/users/models/user';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FilterService } from '@shared/services/filter.service';
import { SharedConstants } from '@shared/model/shared-constants';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { InvoicesStatus } from "@features/invoices/enums/invoices-status.enum";
import { InvoicesFilterBloc } from '../../../bloc/invoices-filter/invoices-filter-bloc';
import { InitEvent } from './../../../../../core/bloc/bloc-event';
import { BlocState, ProcessingState } from './../../../../../core/bloc/bloc-state';
import { ReadyState } from './../../../../../core/bloc/bloc-state';
@Component({
  selector: 'app-customer-invoices-filter',
  templateUrl: './customer-invoices-filter.component.html',
  styleUrls: ['./customer-invoices-filter.component.scss'],
  providers: [InvoicesFilterBloc],
})
export class CustomerInvoicesFilterComponent extends BaseComponent implements OnInit {
  form: UntypedFormGroup;
  sharedConstants = SharedConstants;

  filterActionSubscription: Subscription;
  @Input() lastSelectedDateRange: DateRangeType;
  @Input() action: Observable<FormAction>;
  params: any = null;
  dateRangeType = DateRangeType;
  creators: User[] = [];
  //filteredCreators: User[] = [];
  dateRangeTypes: any;
  sectionStateStatus = SectionStateStatus;
  currentTabIndex = 0;
  dispose$ = new Subject();
  // enums
  InvoicesStatus = InvoicesStatus;
  @Output() startFrom = new EventEmitter();
  @Output() endAt = new EventEmitter();
  @Output() selectedDateRangeChanged = new EventEmitter();
  constructor(
    private filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    
    private bloc: InvoicesFilterBloc
  ) {
    super();
    this.bloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    this.dateRangeTypes = { ...this.dateRangeType };
    delete this.dateRangeTypes.CUSTOM;
    this.bloc.setEvent.next(new InitEvent());
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.subscribeToCurrentTabIndex();
    this.initForm();
  }

  ngOnDestroy() {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.dispose$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  subscribeToFilterAction() {
    this.filterActionSubscription = this.action.subscribe((action: FormAction) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();

          break;
      }
    });
  }
  subscribeToCurrentTabIndex() {
    this.filterService.currentTabIndex$.pipe(takeUntil(this.destroy$)).subscribe((index) => {
      this.currentTabIndex = index;
    });
  }

  getFilterValuesBasedOnURLParams() {
    return {
      dateRange: this.params.dateRange === DateRangeType.ALL_TIME ? DateRangeType.ALL_TIME : String(this.params.dateRange),
      creatorId: this.params.creatorId === SharedConstants.ALL ? SharedConstants.ALL : Number(this.params.creatorId),
      invoicesStatus: this.params.invoicesStatus === SharedConstants.ALL ? SharedConstants.ALL : String(this.params.invoicesStatus),
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form = new UntypedFormGroup({
        dateRange: new UntypedFormControl(filterValues.dateRange ? filterValues.dateRange : DateRangeType.ALL_TIME),
        searchTerm: new UntypedFormControl(''),
        creatorId: new UntypedFormControl(filterValues.creatorId),
        invoicesStatus: new UntypedFormControl(filterValues?.invoicesStatus),
      });
    } else {
      this.form = new UntypedFormGroup({
        dateRange: new UntypedFormControl(DateRangeType.ALL_TIME),
        searchTerm: new UntypedFormControl(''),
        creatorId: new UntypedFormControl(SharedConstants.ALL),
        invoicesStatus: new UntypedFormControl(SharedConstants.ALL),
      });
    }
  }

  get filteredCreators() {
    return this.creators.filter((user) => user.name.toLowerCase().includes(this.form.controls.searchTerm.value?.toLowerCase()));
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.controls['dateRange'].setValue(filterValues.dateRange);
      this.form.controls['creatorId'].setValue(filterValues.creatorId);
      this.form.controls['invoicesStatus'].setValue(filterValues.invoicesStatus);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.form.controls['dateRange'].setValue(DateRangeType.ALL_TIME);
    this.form.controls['creatorId'].setValue(SharedConstants.ALL);
    this.form.controls['invoicesStatus'].setValue(SharedConstants.ALL);

    this.submitForm();
  }

  submitForm() {
    const obj = this.form.value;
    obj.tabGroup = TabGroup.CustomerInvoices;
    this.filterService.navigateToTargetPageWithFilter(obj);
  }

  private handleBlocStates(state: BlocState) {
    if (!state) {
      return;
    }

    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof ReadyState) {
      this.creators = state.data;
     // this.filteredCreators = [...this.creators]
      this.initForm();
      this.sectionState = SectionStateStatus.Ready;
    }
  }
}
