import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { DeliveryNotesManagementPageComponent } from './pages/delivery-notes-management-page/delivery-notes-management-page.component';
import { DeliveryNotesRoutingModule } from './delivery-notes-routing.module';

@NgModule({
  declarations: [DeliveryNotesManagementPageComponent],
  imports: [CommonModule, SharedModule, DeliveryNotesRoutingModule],
  exports: [],
})
export class DeliveryNotesModule {}
