import { ClassConstructor } from 'class-transformer';
import { Observable } from 'rxjs';

import { BlocEvent } from '@core/bloc/bloc-event';

export type DropDownCallBack<T> = () => Observable<T[]>;

export abstract class DropdownEvent extends BlocEvent {}

// Initiate
export class DropdownInitiateEvent extends DropdownEvent {}

// Load
export class DropdownLoadEvent<T> extends DropdownEvent {
  constructor(public caller: DropDownCallBack<T>) {
    super();
  }
}
