<ng-template #dialogTemplate let-data>
  <section class="iconic-dialog madar--v2"
    [class.iconic-dialog--warn]="data.type === data.ConfirmationType?.DestructiveAction"
    [class.iconic-dialog--primary]="data.type === data.ConfirmationType?.NonDestructiveAction"
    [class.iconic-dialog--success]="data.type === data.ConfirmationType?.Informative">
    <header class="iconic-dialog__header">
      <app-row>
        <app-col>
          <div class="iconic-dialog__icon d-flex justify-center align-center" *ngIf="data.icon as icon">
            <app-icon *ngIf="icon.type === IconType.SVG" [name]="icon.name"></app-icon>
            <app-icon [name]="icon.name" type="MaterialDesign" *ngIf="icon.type === IconType.MaterialDesign"></app-icon>
            <img *ngIf="icon.type === IconType.Image" [src]="icon.name" />
          </div>

          <div class="iconic-dialog__icon d-flex justify-center align-center" *ngIf="!data.icon">
            <app-icon name="outline-warning"></app-icon>
          </div>
        </app-col>
        <app-col cols="auto">
          <button mat-icon-button mat-dialog-close [autofocus]="false" tabindex="-1"
            class="dialog-layout__icon-button reset-space" type="button">
            <app-icon class="text-300 mb-1" name="close-circle"></app-icon>
          </button>
        </app-col>
      </app-row>
    </header>

    <mat-dialog-content class="iconic-dialog__body scrollable scollable--y">
      <h5 class="iconic-dialog__title weight-semibold text-900">{{data.headerTitle}}</h5>
      <p class="iconic-dialog__text">{{data.message}}</p>
      <form [formGroup]="form" *ngIf="form">
        <div class="iconic-dialog__checkbox" *ngIf="form.controls.checkboxOption">
          <mat-checkbox formControlName="checkboxValue"
            [color]="data.warnAction ? 'warn' : 'primary'">{{data.checkboxOption}}</mat-checkbox>
        </div>
        <div *ngIf="form.controls.textarea" class="text-area">
          <mat-form-field appearance="outline">
            <textarea formControlName="textarea" [placeholder]="data.textAreaText" type="text" rows="4" matInput
              style="height: 70px"></textarea>
            <mat-error *ngIf="form?.controls?.textarea.touched && form?.controls?.textarea?.errors?.required">
              {{ "ErrorMessages.Required" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions class="iconic-dialog__actions">
      <div class="w-100">
        <app-row gutter="x-sm">
          <app-col *ngIf="data.enableCancel ">
            <button mat-stroked-button class="d-block w-100 brd-primary-light bg-white"
              (click)="data?.reject()">{{data?.cancelActionText}}</button>
          </app-col>
          <app-col>
            <button mat-flat-button class="d-block w-100"
              [color]="data.type === data.ConfirmationType.DestructiveAction ? 'warn' : data.type === data.ConfirmationType?.Informative? null : 'primary'"
              [ngClass]="{'bg-success text-lightest':data.type === data.ConfirmationType?.Informative}"
              (click)="data?.confirm(data?.checkboxValue)">
              {{data?.mainActionText}}
            </button>
          </app-col>
        </app-row>
      </div>
    </mat-dialog-actions>
  </section>
</ng-template>
