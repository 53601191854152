import { RolesConstants } from '@shared/model/roles-constants';
import { UserRoleService } from '@core/services/user-role.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { RoutesUtil } from '@shared-features/utils/routes.util';
import { AuthService } from '@core/services/auth.service';
import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      if (state.url.indexOf(RoutesUtil.AuthLogin.path) > 0) {
        if (this.userRoleService.isUserHasRoles(RolesConstants.DASHBOARD_VIEW)) {
          this.router.navigate([RoutesUtil.Dashboard.url()]);
        } else {
          this.router.navigate([RoutesUtil.B2bShipmentsList.url({ type: ShipmentsListType.All})]);
        }
      }
      return true;
    }
    this.authService.redirectionURL = state.url;
    this.router.navigate([RoutesUtil.AuthLogin.url()]);
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.redirectionURL = state.url;
    this.router.navigate([RoutesUtil.AuthLogin.url()]);
    return false;
  }

  constructor(private authService: AuthService, private router: Router, private userRoleService: UserRoleService) {}
}
