export class BreadCrumb {
  public set path(url: string) {
    const [path, queries] = url.split('?');
    this.url = decodeURI(path);
    this.queryParams = (decodeURIComponent(queries) || '').split('&').reduce((group, param) => {
      const [key, value] = param.split('=');
      if (group[key] && !Array.isArray(group[key])) {
        group[key] = [group[key], value];
      } else if (group[key]) {
        group[key].push(value);
      } else {
        group[key] = value;
      }
      return group;
    }, {});
  }

  public get path(): string {
    return this.url;
  }

  public queryParams = {};
  private url = '';

  constructor(
    public labelKey: string = '',
    path: string = '',
    public isValue: boolean = false,
    public isActive: boolean = false
  ) {
    this.path = path;
  }
}
