<app-row>
  <app-col cols="12" sm="auto">
    <div class="labeled-area__label">
      <div class="labeled-area__label-icon" *ngIf="icon">
        <app-icon [type]="icon.type || IconType.SVG" [name]="icon.name"></app-icon>
      </div>
      <div class="labeled-area__label-text" *ngIf="label">{{label}}</div>
    </div>
  </app-col>
  <app-col>
    <ng-content></ng-content>
  </app-col>
</app-row>
