import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class AvlDeviceAttributes extends Model {
  @Expose() battery: number;
  @Expose() distance: number;
  @Expose() event: number;
  @Expose() hours: number;
  @Expose() ignition: boolean;
  @Expose() motion: boolean;
  @Expose() power: number;
  @Expose() sat: number;
  @Expose() totalDistance: number;
  @Expose() alarm: string;
  @Expose() temp0: number;
  @Expose() io22: number;
  @Expose() io86: number;
  @Expose() bleTemp1: number;
  @Expose() isTempExceed?: boolean;
  @Expose() isHumidityExceed?: boolean;

  constructor() {
    super();
  }
}
