import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() total: number;
  @Input() selectionEnabled = false;
  @Input() showDisplayCount = true;
  @Input() showLastUpdatedAt = true;
  @Input() pageIndex: number;
  @Output() pageIndexChange: EventEmitter<number> = new EventEmitter();

  @Input() pageSize: number;
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter();

  displayedPages: number[] = [];
  isFirstPage = true;
  isLastPage = false;
  lastPageIndex = 0;
  pageSizes = [10, 20, 30];
  form: FormGroup;
  lastUpdatedAt: Date = new Date();

  get displayedCount(): number {
    let display = this.pageSize * this.pageIndex;
    if (display > this.total) {
      display = this.total;
    }
    return display;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.generatePages();
    this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { pageIndex, total, pageSize } = changes;

    if (pageIndex || pageSize || total) {
      this.generatePages();
      this.updateLastUpdatedAt();
    }
  }

  private updateLastUpdatedAt(): void {
    this.lastUpdatedAt = new Date();
  }

  nextPage(): void {
    this.changePage(this.pageIndex + 1);
  }

  previousPage(): void {
    this.changePage(this.pageIndex - 1);
  }

  changePage(index: number): void {
    this.pageIndexChange.emit(index);
  }

  private getLastIndex(): number {
    if(this.total === 0) {
      this.lastPageIndex = 1;
      return 1;
    }
    this.lastPageIndex = Math.ceil(this.total / this.pageSize);
    return this.lastPageIndex;
  }

  private validatePage(index): number {
    if (index <= 0) {
      return 1;
    } else if (index > this.getLastIndex()) {
      return this.getLastIndex();
    }
    return index;
  }

  private generatePages(): void {
    const isFirst = this.pageIndex === 1;
    const isLast = this.pageIndex === this.getLastIndex();
    const additions = [-1, 0, 1];
    const pagesSet = new Set<number>();

    additions.forEach((factor) => {
      let page = null;
      if (isFirst) {
        page = this.pageIndex + factor + 1;
      } else if (isLast) {
        page = this.pageIndex + factor - 1;
      } else {
        page = this.pageIndex + factor;
      }
      pagesSet.add(this.validatePage(page));
    });

    this.isFirstPage = isFirst;
    this.isLastPage = isLast;
    this.displayedPages = Array.from(pagesSet);
  }

  handlePageSizeChange(value) {
    this.updateLastUpdatedAt();
    this.pageSizeChange.emit(value);
  }
}
