import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { AuditTrailActions } from "@features/fleets/enums/audit-trail-actions.enum";
import { AuditActionType } from "@features/fleets/enums/audit-action-type.enum";
import { UserType } from "@features/users/enums/user-type.enum";
import { Shipment } from '../../shipments/models/shipment';
import { Driver } from './driver';
import { Truck } from './truck';
import { User } from '../../users/models/user';

export class AuditTrail extends Model {
  @Expose()
  id: number;

  @Expose()
  action: AuditTrailActions;

  @Expose()
  actionType: AuditActionType;

  @Expose()
  createdByType: UserType;

  @Expose()
  @Type(()=> Shipment|| Driver || Truck)
  oldJson: Shipment | Driver | Truck;

  @Expose()
  @Type(()=> User)
  createdBy: User = new User();

  @Expose()
  createdAt: number;

  constructor() {
    super();
  }
}
