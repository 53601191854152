<mat-accordion class="expandable" [class.expandable--themeless]="themeless" hideToggle>
  <div class="expandable__header" (click)="toggle()">
    <div class="w-100">
      <app-row gutter="x-xs" align="center" justify="space-between">
        <app-col [cols]="inline ? 'auto' : null">
          <ng-content select="[toggle]"></ng-content>
        </app-col>
        <app-col cols="auto">
          <app-icon *ngIf="!icon || icon.type === IconType.MaterialDesign"
            class="align-middle text-500 expandable__icon" [name]="!icon ? 'arrow_drop_down' : icon.name"
            type="MaterialDesign" [class.expandable__icon--rotate]="!icon || icon.rotate"
            [class.expandable__icon--active]="expanded"></app-icon>
          <span *ngIf="icon?.type === IconType.SVG" class="expandable__icon expandable__icon--image"
            [class.expandable__icon--rotate]="icon?.rotate" [class.expandable__icon--active]="expanded">
            <app-icon class="align-middle d-inline-block" [name]="icon?.name"></app-icon>
          </span>
          <span *ngIf="icon?.type === IconType.Image" class="expandable__icon expandable__icon--image"
            [class.expandable__icon--rotate]="icon?.rotate" [class.expandable__icon--active]="expanded">
            <img [src]="icon?.name" />
          </span>
        </app-col>
      </app-row>
    </div>
  </div>
  <mat-expansion-panel class="expandable__panel" [class.mat-elevation-z0]="themeless" #expansionPanel
    [disabled]="disabled" [expanded]="expanded" (opened)="expanded = true; opened.emit();"
    (closed)="expanded = false; closed.emit();">
    <ng-content></ng-content>
  </mat-expansion-panel>
</mat-accordion>