import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateRange } from '@shared/model/date-range';
import { FunctionUtil } from '@shared/utils/function.util';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  host: { class: 'range-select' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRangeComponent,
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent
  extends BaseComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() showCustomDateOption: boolean;
  @Input() maxDate: string | Date;
  @Input() label: string;
  @Input() set mode(val) {
    console.error('mode is deprecated now only one mode is available');
  }

  @Input() showIcon = false;
  @Input() appearance: 'fill' | 'outline' = 'outline';
  @Input() size: 'sm' | 'md' = 'md';
  @Input() rangeType = DateRangeType;
  @Input() rangeTypeTranslation = 'DateRangeType';
  @Input() dateTimeRange = false;

  isOpenDateTimeRange = false;
  dateTimeRangeFormControl = new FormControl({});

  /**
   * value
   * { dateRange: DateRangeTypes, startFrom: YYYY-MM-DD, endAt: YYYY-MM-DD};
   */
  dateRange: DateRange;
  form: FormGroup;
  touched = false;
  disabled = false;
  destroy$ = new Subject();
  isUpdatingSelectedValueLabel = false;
  selectedValue = '';
  customSelectedValue = '';
  private readonly datePipe = new DatePipe(this.translationService.currentLang);

  onChange = (value: DateRange) => {};
  onTouched = () => {};

  get rangeControl(): AbstractControl {
    return this.form && this.form.get('range');
  }

  get isCustomRange(): boolean {
    return this.rangeControl && this.rangeControl.value === this.rangeType.CUSTOM;
  }

  constructor(private formBuilder: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      range: [''],
      startFrom: [''],
      endAt: [''],
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(50),
        filter(() => !this.isUpdatingSelectedValueLabel)
      )
      .subscribe((value) => {
        if (value.range === DateRangeType.CUSTOM) {
          this.customSelectedValue =
            (this.datePipe.transform(value.startFrom, 'dd MMM yyyy') ?? '') +
            ' - ' +
            (this.datePipe.transform(value.endAt, 'dd MMM yyyy') ?? '');
          this.selectedValue = value.range;
        } else {
          this.selectedValue = value.range;
        }
        this.handleRangeChange();
        this.changeDetectorRef.markForCheck();
      });

    this.dateTimeRangeFormControl.valueChanges.subscribe((res: { from: Date; to: Date }) => {
      const { from, to } = res;
      this.form.value.startFrom = from;
      this.form.value.endAt = to;

      this.customSelectedValue =
        (this.datePipe.transform(from, 'M/d/yy, h:mm a') ?? '') +
        ' - ' +
        (this.datePipe.transform(to, 'M/d/yy, h:mm a') ?? '');

      this.handleRangeChange();
      this.changeDetectorRef.markForCheck();
    });

    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  writeValue(value: DateRange): void {
    if (!value) {
      return;
    }

    if (JSON.stringify(value) !== JSON.stringify(this.form.value)) {
      this.syncFormWithValue(value);
    }
    this.dateRange = value;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private handleRangeChange(): void {
    const { range, startFrom, endAt } = this.form.value;
    const dateRange = new DateRange();
    dateRange.range = range;
    this.markAsTouched();

    if (this.isCustomRange) {
      dateRange.startFrom = this.isOpenDateTimeRange
        ? startFrom
        : (startFrom && FunctionUtil.convertDate(startFrom)) || '';
      dateRange.endAt = this.isOpenDateTimeRange
        ? endAt
        : (endAt && FunctionUtil.convertDate(endAt)) || '';

      if (startFrom && endAt) {
        this.onChange(dateRange);
      }
    } else {
      dateRange.startFrom = '';
      dateRange.endAt = '';
      this.onChange(dateRange);
    }
  }

  private syncFormWithValue(value: DateRange): void {
    this.form.setValue({ ...this.form.value, ...value }, { onlySelf: true, emitEvent: false });
    this.calculateSelectedValue(value);
    this.changeDetectorRef.markForCheck();
  }

  private calculateSelectedValue(value: DateRange) {
    /**
     * @Note: Prevent updating selected value label when form value changes
     * this is hack since if label changed mat select will fire the change event
     */
    this.isUpdatingSelectedValueLabel = true;
    this.form.valueChanges.pipe(take(1)).subscribe(() => {
      this.isUpdatingSelectedValueLabel = false;
    });

    this.customSelectedValue =
      value.range === DateRangeType.CUSTOM
        ? (this.datePipe.transform(value.startFrom, 'dd MMM yyyy') ?? '') +
          ' - ' +
          (this.datePipe.transform(value.endAt, 'dd MMM yyyy') ?? '')
        : '';
    this.selectedValue = value.range;
  }

  handleClickPicker(picker): void {
    this.form.controls.range.setValue(this.rangeType.CUSTOM);
    picker.open();
    this.calculateSelectedValue({ range: '' } as unknown as DateRange);
    this.changeDetectorRef.markForCheck();
  }

  openDateTimeRange() {
    this.isOpenDateTimeRange = true;
  }
}
