import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { Customer } from './customer';

export class CustomerDetails extends Model {
  @Type(() => Customer)
  @Expose()
  customer: Customer = new Customer();
  @Expose() totalContractsCount: number;
  @Expose() totalReceiversCount: number;
  @Expose() totalUsersCount: number;
  @Expose() totalFleetContractsCount: number;
  @Expose() totalSpecificFleetsCount: number;
  @Expose() totalGlobalSpecificContractsCount: number;
  @Expose() totalMyCustomersCount: number;
}
