import { drawingType } from "@shared/enums/drawing-type.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { ExportedFile } from '@shared-features/models/exported-file';
import { BlocErrorModel } from '@shared-features/models/error';
import { Drawing } from "@shared-features/models/drawing";

export abstract class BlocState {}

export abstract class InitState extends BlocState {
  constructor() {
    super();
  }
}

//processing or loading
export class DrawingType<T> extends BlocState {
  public drawingType;

  constructor(public drawing: T, public loading: boolean = false) {
    super();
    if (this.drawing instanceof Drawing) {
      this.drawingType = this.loading
        ? SectionStateStatus.Loading
        : drawingType.Drawing;
    } else {
      this.drawingType = this.loading
        ? SectionStateStatus.LoadingTransparent
        : drawingType.NonDrawing;
    }
  }
}

//processing or loading
export class ProcessingState<T> extends DrawingType<T> {
  constructor(public drawing: T) {
    super(drawing, true);
  }
}

//Ready state
export class ReadyState<T> extends BlocState {
  constructor(public data?: T | T[], public count?: number) {
    super();
  }
}

//Empty state
export class EmptyState extends BlocState {
  constructor() {
    super();
  }
}

//coming soon
export class ComingSoonState extends BlocState {
  constructor() {
    super();
  }
}

//Error state
export class ErrorState<T> extends DrawingType<T> {
  constructor(public drawing: T, public error?: BlocErrorModel) {
    super(drawing);
  }
}

//submitted state
export class SubmittedState<T> extends BlocState {
  constructor(public item: T = null) {
    super();
  }
}

//Added state
export class AddedState<T> extends BlocState {
  constructor(public data: T | T[] = null) {
    super();
  }
}

export class DisconnectedState<T> extends DrawingType<T> {
  constructor(public drawing: T) {
    super(drawing);
  }
}

export class FileExportedState extends BlocState {
  constructor(public response: ExportedFile) {
    super();
  }
}

export class ActivateResetPasswordReadyState extends BlocState {
  constructor(public message: string) {
    super();
  }
}


