import { Expose, Type } from 'class-transformer';
import { AvlDeviceAttributes } from './avl-device-attributes';
import { AvlCoordinateBase } from './avl-coordinate-base.model';

export class AvlDevicePosition extends AvlCoordinateBase {
  @Expose() id: number;
  @Expose() devicId: number;
  @Expose() address: number;
  @Expose() altitude: number;
  @Expose() course: number;
  @Expose() deviceId: number;
  @Expose() deviceTime: string;
  @Expose() fixTime: string;
  @Expose() latitude: number;
  @Expose() longitude: number;
  @Expose() network: number;
  @Expose() outdated: boolean;
  @Expose() protocol: string;
  @Expose() serverTime: string;
  @Expose() speed: number;
  @Expose() valid: boolean;
  @Expose() selected: boolean;
  @Expose() isTempExceed?: boolean;
  @Expose() isHumidityExceed?: boolean;
  @Type(() => AvlDeviceAttributes)
  @Expose()
  attributes: AvlDeviceAttributes = new AvlDeviceAttributes();

  constructor() {
    super();
  }

  @Expose()
  get lat(): number {
    return this.latitude;
  }

  @Expose()
  get lng(): number {
    return this.longitude;
  }
}
