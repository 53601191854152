import { Injectable } from '@angular/core';
import { UserDevice } from '@features/users/models/user-device.model';
import { MapperInterface } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserDeviceMapper implements MapperInterface<UserDevice> {
  constructor() {}

  fromJson(json: any): UserDevice {
    return new UserDevice(
      json.deviceId,
      json.appVersion,
      json.osVersion,
      json.deviceModel,
      json.notificationToken,
      json.osType,
    );
  }

  fromList(json: any): UserDevice[] {
    const list: UserDevice[] = [];
    if (json) {
      json.forEach((element) => {
        list.push(this.fromJson(element));
      });
    }
    return list;
  }
}
