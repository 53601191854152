<div class="fixed-side"
     [ngClass]="[
    'fixed-side--' + (direction || 'left'),
    'fixed-side--' + (corners || 'square'),
    'fixed-side--space-' + (spacing || 'none'),
    'fixed-side--content-' + (contentWidth || '50')
  ]">
  <div class="fixed-side__side">
    <div class="fixed-side__side-header">
      <ng-content select="[side-header]"></ng-content>
    </div>
    <div class="fixed-side__side-media" [ngStyle]="{backgroundImage: 'url(' + mediaImageUrl + ')'}">
      <div class="side-media__header">
        <ng-content select="[media-header]"></ng-content>
      </div>

      <div class="side-media__center">
        <ng-content select="[media-center]"></ng-content>
      </div>

      <div class="side-media__footer">
        <ng-content select="[media-footer]"></ng-content>
      </div>

    </div>
  </div>

  <div class="fixed-side__content">
    <div class="fixed-side__content-separator">
    </div>
    <div class="fixed-side__content-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>

