<div class="container" [formGroup]="form" #sequenceNumberContainer>
  <input type="number" id="field1" (keyup)="moveCursorToNextField(1)" min=0 max="9" formControlName="number1" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field2" (keyup)="moveCursorToNextField(2)" min=0 max="9" formControlName="number2" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field3" (keyup)="moveCursorToNextField(3)" min=0 max="9" formControlName="number3" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field4" (keyup)="moveCursorToNextField(4)" min=0 max="9" formControlName="number4" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field5" (keyup)="moveCursorToNextField(5)" min=0 max="9" formControlName="number5" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field6" (keyup)="moveCursorToNextField(6)" min=0 max="9" formControlName="number6" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field7" (keyup)="moveCursorToNextField(7)" min=0 max="9" formControlName="number7" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field8" (keyup)="moveCursorToNextField(8)" min=0 max="9" formControlName="number8" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field9" (keyup)="moveCursorToNextField(9)" min=0 max="9" formControlName="number9" oninput="validity.valid||(value=value[value.length-1]);">
  <input type="number" id="field10" (keyup)="moveCursorToNextField(10)" min=0 max="9" formControlName="number10" oninput="validity.valid||(value=value[value.length-1]);">
</div>
