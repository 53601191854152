import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class TruckProfits extends Model {
    @Expose()
    totalCost: number;

    @Expose()
    totalPrice: number;

    @Expose()
    margin: number;

    @Expose()
    totalKm: number;
    
    @Expose()
    totalShipmentsCount: number;
    
    @Expose()
    shipmentKm: number;

    @Expose()
    nonOperationDays: number;
    
    @Expose()
    operationDays: number;
    
    @Expose()
    wastedKm: number;
}