/*
import { ConfigConstant } from '@shared/constants/config.constant';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { DynamicScriptService } from '@shared/services/dynamic-script.service';
import { AuthService } from '@core/services/auth.service';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { UsersApiService } from '@features/users/services/users-api.service';
import { StorageConstant } from '@shared/constants/storage.constant';
import * as Sentry from '@sentry/angular-ivy';
import { ZendeskService } from '@core/services/zendesk.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

export function appConfigFactory(
  authService: AuthService,
  featureFlagService: FeatureFlagService,
  dynamicScriptService: DynamicScriptService,
  usersApiService: UsersApiService,
  zendeskService: ZendeskService,
  http: HttpClient
): () => Promise<any> {
  return () => {
    const oldVersion = LocalStorageUtil.getItem(ConfigConstant.APP_VERSION_KEY) || null;
    const currentUser = JSON.parse(localStorage.getItem(StorageConstant.AUTH_USER)) || null;
    console.log(currentUser, LocalStorageUtil.getItem(StorageConstant.AUTH_USER));
    let userPromise = Promise.resolve(currentUser);

    if (!oldVersion || oldVersion !== ConfigConstant.VERSION) {
      localStorage.clear();
      LocalStorageUtil.setItem(ConfigConstant.APP_VERSION_KEY, ConfigConstant.VERSION);
      if (currentUser?.accessToken) {
        LocalStorageUtil.setItem(StorageConstant.AUTH_USER, currentUser);
        userPromise = new Promise((resolve) => {
          usersApiService.getUserById(currentUser?.id).subscribe((user) => {
            const modifiedUser = Object.assign(user, {
              userRoles: user?.roles?.map((role) => role.userRole) || [],
            });
            resolve(modifiedUser as any);
          });
        });
      }
    }

    zendeskService.init();

    return userPromise
      .then((user) => {
        const settings$ = http.get('/assets/app-settings.json').pipe(catchError(() => of(null)));
        return Promise.all([Promise.resolve(user), settings$.toPromise()]);
      })
      .then(([user, settings]) => {
        console.error({ settings });
        const newCurrentUser = currentUser?.accessToken
          ? Object.assign({}, currentUser, user, {
              userRoles: user?.roles?.map((role) => role.userRole) || [],
              accessToken: currentUser?.accessToken,
              refreshToken: currentUser?.refreshToken,
            })
          : null;
        LocalStorageUtil.setItem(StorageConstant.AUTH_USER, newCurrentUser);
        authService.currentUser$.next(newCurrentUser);
        if (newCurrentUser?.id) {
          Sentry.setUser({ id: newCurrentUser?.id, email: newCurrentUser?.email });
        }
        return featureFlagService.init(newCurrentUser || ({ name: 'guest' } as any));
      });
  };
}

 */

import { AppConfigService } from '@core/services/app-config.service';
import { Observable } from 'rxjs';

export function appConfigFactory(appConfigService: AppConfigService): () => Promise<any> {
  return () => appConfigService.init();
}
