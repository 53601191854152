<div class="modal" [class.modal-page]="applyModalPage" *ngIf="isModalOpened">
  <div
    class="modal-content"
    [class.modal-page]="applyModalPage"
    [class.edit-close-icon]="editCloseIcon"
    [class.rtl]="layoutService.websiteDirection$.value === 'rtl'"
    [style.max-width.px]="maxWidth"
    [style.padding.px]="padding"
    [style.background-color]="backgroundColor"
    [ngClass]="{'content-full-screen': contentFillTheScreen}"
  >
    <div class="modal-header flex align-center justify-space-between" [ngClass]="{'modal-header': applyHeaderStyle }">
      <div class="modal-header-title flex align-baseline" [style.color]="textColor">
        <span>{{title}}</span>
        <span *ngIf="subTitle" class="model-header-sub-title">{{subTitle}}</span>
      </div>

      <app-icon class="modal-header-close-icon"   name="close-circle" (click)="closeCurrentModal()" [style.fill]="textColor"></app-icon>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
