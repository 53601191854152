import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';
import { ShipmentAction } from '../enums/shipment-action.enum';

export class SamTruck extends Model {
  @Expose()
  plateNumber?: string;
  @Expose()
  fleetName?: string;
  @Expose()
  fleetId?: number;
  @Expose()
  truckId?: number;
  @Expose()
  shipmentAction?: ShipmentAction;
  @Expose()
  from?: string;
  @Expose()
  to?: string;
  @Expose()
  loadingStatus?: boolean;
  @Expose()
  shipmentId?: number;
  @Expose()
  reservedBy?: string;
  @Expose()
  reservedAt?: string;
  @Expose()
  isReserved?: boolean;
  @Expose()
  isResolved?: boolean;
  @Expose()
  awayKM?: number;
  @Expose()
  awayTime?: number;
  @Expose()
  restrictedDays: number[];
  @Expose()
  isRestricted: boolean;
  @Expose()
  expectedDeliveryDate: string;
  @Expose()
  label: string;
  @Expose()
  currentCity: string;
}

export class SamInitialAssignment extends Model {
  @Expose()
  trucks: SamTruck[];
  @Expose()
  count: number;
}
