import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NamedField } from '@shared/interfaces/field.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  CheckboxFieldComponent,
  DropdownFieldComponent,
  FileFieldComponent,
  RadioFieldComponent,
  TextareaFieldComponent,
  TextFieldComponent,
} from 'src/app/shared/components/forms';
import { FieldType } from '@shared/enums/field-type.enum';

// type Components = TextFieldComponent;

const componentsMapper = {
  [FieldType.Input]: TextFieldComponent,
  [FieldType.Dropdown]: DropdownFieldComponent,
  [FieldType.TextArea]: TextareaFieldComponent,
  [FieldType.Checkbox]: CheckboxFieldComponent,
  [FieldType.Radio]: RadioFieldComponent,
  [FieldType.File]: FileFieldComponent,
};

@Directive({
  selector: '[appField]',
})
export class FieldDirective implements OnInit, OnChanges, OnDestroy {
  private readonly container = inject(ViewContainerRef);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly element = inject(ElementRef);

  @Input('appField') field!: NamedField;
  @Input() group: FormGroup | FormArray;
  @Output() suffixClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() prefixClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() fieldClick = new EventEmitter<{ field: string; event: MouseEvent }>();
  @Output() change = new EventEmitter<{ field: string; value: any }>();
  component: ComponentRef<any>;
  template: EmbeddedViewRef<any>;
  private destroy$ = new Subject();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component) {
      this.component.instance.field = this.field;
      this.component.instance.group = this.group;
    }
  }

  ngOnInit(): void {
    const { type, component } = this.field;
    if (type === 'CUSTOM' && component instanceof TemplateRef) {
      this.template = this.container.createEmbeddedView(component, {
        ...(this.field.inputs || {}),
        field: this.field,
        group: this.group,
      });
      return;
    } else if (type === 'CUSTOM') {
      this.component = this.container.createComponent(component as any);
      this.component.instance.field = this.field;
      this.component.instance.group = this.group;
      this.bindInputs();
      this.listenToFormElementsEvents();
      return;
    }

    this.component = this.container.createComponent(componentsMapper[type]);
    this.component.instance.field = this.field;
    this.component.instance.group = this.group;
    this.bindInputs();
    this.listenToFormElementsEvents();
    this.changeDetectorRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private listenToFormElementsEvents(): void {
    const events = ['prefixClick', 'suffixClick', 'fieldClick', 'change'];
    const valueMap = { change: 'value' };
    events.forEach((eventName) => {
      const event = this.component.instance[eventName];
      if (!event) {
        return;
      }
      event.pipe(takeUntil(this.destroy$)).subscribe((value) => {
        this[eventName].emit({
          field: this.field.name,
          [valueMap[eventName] || 'event']: value,
        });
      });
    });
  }
  private bindInputs(): void {
    if (this.field.inputs) {
      Object.keys(this.field.inputs).forEach((key) => {
        this.component.instance[key] = this.field.inputs[key];
      });
    }
  }
}
