import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartOptions, ChartType, PositionType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';

import { LayoutService } from '@shared/services/layout.service';
import { ChartColumnClick } from '@shared/model/chart-column-click';
import { ChartDataColumn } from '@shared/model/chart-data-column';
import { ChartDataParetoColumn } from '@shared/model/chart-data-pareto-column';
import { ScreenType } from '@shared/enums/screen-type.enum';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import Chart from 'chart.js';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, delay, filter, takeUntil, tap } from 'rxjs/operators';
import { IAnnotation } from '@features/shipments/interfaces/annotation.interface';

@Component({
  selector: 'app-base-chart',
  template: '',
})
export class BaseChartComponent implements OnInit, OnChanges {
  @Input() chartData: ChartDataColumn[] = [];
  @Input() labels: Label[] = [];
  @Input() showParetoLine = false;
  @Input() barThickness?: number = 13;
  @Input() canvasWidth?: number = 390;
  @Input() canvasHeight?: number = 130;
  @Input() isAxisVisible?: boolean = true;
  @Input() annotations: IAnnotation[] = [];
  annotations$ = new BehaviorSubject<any[]>([]);
  chartData$ = new BehaviorSubject<ChartDataColumn[]>([]);
  labels$ = new BehaviorSubject<string[]>([]);
  destroy$ = new Subject();

  @Output() onColumnClicked = new EventEmitter(null);
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  chartDataSets: ChartDataSets[];
  chartLabels: Label[];
  pieChartData: number[] = [];
  chartColors = [];
  chartPlugins: any = [ChartDataLabels];
  chartOptions: ChartOptions & { annotation?: any } = {
    responsive: true,
    onClick: this.onClick.bind(this),
    responsiveAnimationDuration: 100,
    maintainAspectRatio: true,
    aspectRatio: 2.3,
    layout: {
      padding: {
        top: 5,
        bottom: 5,
        left: 5,
        right: 5,
      },
    },
    legend: {
      position: 'top',
      align: 'start',
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        fontSize: 10,
        padding: 20,
      },
    },
    annotation: {
      annotations: [],
    },
    scales: {
      xAxes: [
        {
          display: this.isAxisVisible,
          gridLines: {},
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      yAxes: [
        {
          display: this.isAxisVisible,
          id: 'y-axis-0',
          position: 'left',
          gridLines: {},
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    elements: {
      line: {
        fill: false,
      },
    },
    plugins: {
      datalabels: {
        display: false,
        formatter: (value, ctx) => {
          return ctx.dataset.yAxisID === 'y-axis-1' ? `${value}%` : value;
        },
      },
    },
  };

  defaultColors: Color[] = [
    {
      borderColor: '#32d74b',
      backgroundColor: '#32d74b',
      hoverBackgroundColor: '#32d74b',
      pointBackgroundColor: '#32d74b',
    },
    {
      borderColor: '#ff453a',
      backgroundColor: '#ff453a',
      hoverBackgroundColor: '#ff453a',
      pointBackgroundColor: '#ff453a',
    },
    {
      borderColor: '#2f5cbd',
      backgroundColor: '#2f5cbd',
      hoverBackgroundColor: '#2f5cbd',
      pointBackgroundColor: '#2f5cbd',
    },
    {
      borderColor: '#ffb829',
      backgroundColor: '#ffb829',
      hoverBackgroundColor: '#ffb829',
      pointBackgroundColor: '#ffb829',
    },
  ];

  render = false;
  type: ChartType | 'RoundedDoughnut';
  isPercentage = false;
  barPercentage: number = null;
  categoryPercentage: number = null;
  centerX = 0;
  centerY = 0;
  centerWidth = 0;
  centerHeight = 0;

  protected translationsList: any = {};

  constructor(
    private translate: TranslateService,
    private layoutService: LayoutService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.translate.get(['Charts']).subscribe((translations: any) => {
      this.translationsList = translations;
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get(['Charts', 'Dashboard']).subscribe((translations: any) => {
        this.translationsList = translations;
      });
    });

    const type = this.layoutService.currentMedia;
    if (type === ScreenType.phone) {
      this.chartOptions.aspectRatio = 1.2;
    } else if (type === ScreenType.tablet) {
      this.chartOptions.aspectRatio = 2.6;
    } else {
      this.chartOptions.aspectRatio = 2.3;
    }
  }

  ngOnInit(): void {
    Chart.plugins.register(AnnotationPlugin);
    this.initializeChart();
  }

  ngOnChanges({ chartData, annotations, labels }: SimpleChanges): void {
    if (chartData?.currentValue !== chartData?.previousValue) {
      this.chartData$.next(chartData.currentValue);
        if(this.chartOptions?.scales?.xAxes[0]){
          this.chartOptions.scales.xAxes[0].display = this.isAxisVisible;
          this.chartOptions.scales.yAxes[0].display = this.isAxisVisible;
        }
    }

    if (annotations?.currentValue !== annotations?.previousValue) {
      this.annotations$.next(annotations.currentValue);
    }
    if (labels.currentValue !== labels.previousValue) {
      this.labels$.next(labels.currentValue);
    }
  }

  initializeChart() {
    combineLatest([this.chartData$, this.annotations$, this.labels$])
      .pipe(
        takeUntil(this.destroy$),
        filter(([data]) => !!data?.length),
        tap(() => (this.render = false)),
        tap(() => this.changeDetector.markForCheck()),
        tap(() => this.changeDetector.detectChanges())
      )
      .subscribe(([chartData, annotations, labels]) => {
        const options = JSON.parse(JSON.stringify(this.chartOptions));
        options.annotation.annotations = [];
        annotations.forEach((annotation, i) => {
          options.annotation.annotations.push({
            type: 'box',
            drawTime: 'beforeDatasetsDraw',
            xScaleID: 'x-axis-0',
            xMin: annotation.XMin,
            xMax: annotation.XMax,
            yScaleID: 'y-axis-0',
            backgroundColor: '#7986931A',
            borderColor: '#7986931A',
          });
        });
        this.chartOptions = options;
        this.renderTheChart(chartData, labels);
      });
  }

  toggleDatasetVisibility(datasetIndex: number) {
    if (this.chart && this.chart.chart && this.chart.chart.data.datasets) {
      const datasets = this.chart.chart.data.datasets;

      if (datasets[datasetIndex]) {
        datasets[datasetIndex].hidden = !datasets[datasetIndex].hidden;
        this.chart.chart.update();
      }
    }
  }

  protected onClick(event, activeElements) {
    if (activeElements.length === 0) {
      // Check Center Circle Click
      if (this.type === 'RoundedDoughnut') {
        if (
          event.layerX >= this.centerX &&
          event.layerX <= this.centerX + this.centerWidth &&
          event.layerY >= this.centerY &&
          event.layerY <= this.centerY + this.centerHeight
        ) {
          this.onColumnClicked.emit(
            new ChartColumnClick(0, 0, this.chartData[0], this.chartData[0].data[0])
          );
        }
      }
      return;
    }

    const activeElement = activeElements[0]._chart.getElementAtEvent(event)[0];
    const columnIndex: number = activeElement['_datasetIndex'];
    const valueIndex: number = activeElement['_index'];
    this.onColumnClicked.emit(
      new ChartColumnClick(
        columnIndex,
        valueIndex,
        this.chartData[columnIndex],
        this.chartData[columnIndex].data[valueIndex]
      )
    );
  }

  protected add_Y_Axis(id: string, position: PositionType = 'right', showGridLines = false) {
    const axis = {
      id: id,
      position: position,
      gridLines: {
        display: false,
      },
      ticks: {
        beginAtZero: true,
      },
      scaleLabel: {
        display: false,
      },
    };
    if (showGridLines) {
      axis.gridLines.display = true;
      axis.gridLines['borderDash'] = [2, 4];
    }
    if (this.isPercentage) {
      axis['scaleLabel']['labelString'] = '%';
    }
    this.chartOptions.scales.yAxes.push(axis);
  }

  protected renderTheChart(chartData: ChartDataColumn[], labels: Label[]) {
    this.chartDataSets = [];
    this.pieChartData = [];
    this.chartColors = [];
    this.chartLabels = labels;

    if (this.showParetoLine) {
      if (chartData.length === 1) {
        chartData.push(
          new ChartDataParetoColumn(
            '-1',
            this.translationsList['Charts']['ParetoAxis'],
            chartData[0].data,
            '#EE4340',
            'transparent',
            '#EE4340',
            '#EE4340'
          )
        );
      }
    }

    if (this.type === 'doughnut') {
      let type;
      const data = [];
      const labels = [];

      const borderColors = [];
      const backgroundColors = [];
      const hoverBackgroundColors = [];
      const pointBackgroundColors = [];

      chartData.map((item, index) => {
        const currentIndex = index % this.defaultColors.length;
        type = 'doughnut';
        data.push(item.data);
        labels.push(item.label);

        if (item.borderColor) {
          borderColors.push(item.borderColor);
        } else {
          borderColors.push(this.defaultColors[currentIndex].borderColor as string);
        }

        if (item.backgroundColor) {
          backgroundColors.push(item.backgroundColor);
        } else {
          backgroundColors.push(this.defaultColors[currentIndex].backgroundColor as string);
        }

        if (item.hoverBackgroundColor) {
          hoverBackgroundColors.push(item.hoverBackgroundColor);
        } else {
          hoverBackgroundColors.push(
            this.defaultColors[currentIndex].hoverBackgroundColor as string
          );
        }

        if (item.pointBackgroundColor) {
          pointBackgroundColors.push(item.pointBackgroundColor);
        } else {
          pointBackgroundColors.push(
            this.defaultColors[currentIndex].pointBackgroundColor as string
          );
        }
      });

      this.chartLabels = labels;
      this.addChartData(
        null,
        type,
        data,
        null,
        borderColors,
        backgroundColors,
        hoverBackgroundColors,
        pointBackgroundColors
      );
    } else if (this.type === 'pie') {
      let type;
      let data = [];
      let labels = [];

      let borderColors = [];
      let backgroundColors = [];
      let hoverBackgroundColors = [];
      let pointBackgroundColors = [];

      chartData.map((item, index) => {
        const currentIndex = index % this.defaultColors.length;
        type = 'pie';
        data.push(item.data);
        labels.push(item.label);

        if (item.borderColor) {
          borderColors.push(item.borderColor);
        } else {
          borderColors.push(this.defaultColors[currentIndex].borderColor as string);
        }

        if (item.backgroundColor) {
          backgroundColors.push(item.backgroundColor);
        } else {
          backgroundColors.push(this.defaultColors[currentIndex].backgroundColor as string);
        }

        if (item.hoverBackgroundColor) {
          hoverBackgroundColors.push(item.hoverBackgroundColor);
        } else {
          hoverBackgroundColors.push(
            this.defaultColors[currentIndex].hoverBackgroundColor as string
          );
        }
      });
      this.chartLabels = labels;
      this.addChartData(
        null,
        type,
        data,
        null,
        borderColors,
        backgroundColors,
        hoverBackgroundColors,
        pointBackgroundColors
      );
    } else {
      chartData.map((item, index) => {
        const currentIndex = index % this.defaultColors.length;
        if ((this.showParetoLine && item.isParetoLine) || !item.isParetoLine) {
          const type = item.isParetoLine === true ? 'line' : this.type;
          const data =
            item.isParetoLine === true
              ? this.isPercentage
                ? (item as ChartDataParetoColumn).percentageData
                : (item as ChartDataParetoColumn).absoluteData
              : item.data;
          const yAxisID = item.isParetoLine ? 'y-axis-1' : 'y-axis-0';
          const borderColor = item.borderColor
            ? item.borderColor
            : (this.defaultColors[currentIndex].borderColor as string);
          const backgroundColor = item.backgroundColor
            ? item.backgroundColor
            : (this.defaultColors[currentIndex].backgroundColor as string);

          const hoverBackgroundColor = item.hoverBackgroundColor
            ? item.hoverBackgroundColor
            : (this.defaultColors[currentIndex].hoverBackgroundColor as string);

          const pointBackgroundColor = item.pointBackgroundColor
            ? item.pointBackgroundColor
            : (this.defaultColors[currentIndex].pointBackgroundColor as string);

          this.addChartData(
            item.label,
            type,
            data,
            yAxisID,
            borderColor,
            backgroundColor,
            hoverBackgroundColor,
            pointBackgroundColor,

            100 - index
          );

          if (this.type === 'RoundedDoughnut') {
            this.pieChartData.push(item.data[0]);
            this.chartLabels.push(item.label);
          }
        }
      });
    }

    if (this.showParetoLine) {
      this.add_Y_Axis('y-axis-1');
    }
    if (this.type === 'RoundedDoughnut') {
      let total = 0;
      this.pieChartData.forEach((value) => {
        total += value;
      });
      const chartDataPieCenter = chartData.find((item) => item.isPieCenter === true);
      if (chartDataPieCenter) {
        (this.chartOptions.elements as any).center.text = chartDataPieCenter.data[0];
      }
    }
    this.render = true;
    this.changeDetector.markForCheck();
  }

  private addChartData(
    label: string,
    type: string,
    data: number[],
    yAxisID: string,
    borderColor: string | string[],
    backgroundColor: string | string[],
    hoverBackgroundColor: string | string[],
    pointBackgroundColor: string | string[],
    order?: number
  ) {
    this.chartDataSets = [
      ...this.chartDataSets,
      {
        data: data,
        label: label,
        type: type,
        yAxisID: yAxisID,
        lineTension: 0,
        pointStyle: 'circle',
        pointRadius: 4,
        order: order,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        pointBackgroundColor: pointBackgroundColor,
        barPercentage: this.barPercentage,
        categoryPercentage: this.categoryPercentage,
        barThickness: this.barThickness,
        borderWidth: 1,
      },
    ];
    this.addChartColumnColor(
      borderColor,
      backgroundColor,
      hoverBackgroundColor,
      pointBackgroundColor
    );
    this.changeDetector.detectChanges();
    this.changeDetector.markForCheck();
  }

  private addChartColumnColor(
    borderColor: string | string[],
    backgroundColor: string | string[],
    hoverBackgroundColor: string | string[],
    pointBackgroundColor: string | string[]
  ) {
    if (this.type === 'RoundedDoughnut') {
      if (this.chartColors.length === 0) {
        this.chartColors.push({
          borderColor: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          pointBackgroundColor: [],
        });
      }
      this.chartColors[0].borderColor.push(borderColor);
      this.chartColors[0].backgroundColor.push(backgroundColor);
      this.chartColors[0].hoverBackgroundColor.push(hoverBackgroundColor);
      this.chartColors[0].pointBackgroundColor.push(pointBackgroundColor);
    } else {
      this.chartColors.push({
        borderColor: borderColor,
        backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor,
        pointBackgroundColor: pointBackgroundColor,
      });
    }
  }
}
