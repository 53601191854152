<app-page-content>
  <ng-container title>{{"DeliveryNotes.DeliveryNotes"| translate }}</ng-container>
  <div class="btns flex row" trailing *ngIf=" sectionState != SectionStateStatus.Empty">
    <app-export-button (buttonClicked)="onExportClicked()"></app-export-button>
  </div>
  <ng-container content>
    <div class="validate flex">
      <form class="flex" [formGroup]="form">
        <div class="field flex form-control mr-inline">
          <mat-label class="label-text">{{ "Invoices.DateFrom" | translate}}</mat-label>
          <app-date-input formControlName="from" [required]="true"></app-date-input>
        </div>

        <div class="field flex form-control mr-inline">
          <mat-label class="label-text">{{ "Invoices.DateTo" | translate}}</mat-label>
          <app-date-input [min]="form.get('from').value" formControlName="to" [required]="true"></app-date-input>
        </div>
      </form>

      <div class="mr-inline">
        <button mat-raised-button class="btn-secondary btn" [disabled]="form.invalid" (click)="onValidateButtonClicked()">
          {{ "Buttons.Validate" | translate }}
        </button>
      </div>
    </div>
    <div class="list-content" content>
      <div>
        <div class="row" *ngIf="filterSection">
          <div class="col-6">
            <app-search-input
              class="search"
              [placeholder]=" 'Placeholder.EnterDeliveryNoteID' | translate"
              (searchValue)="onSearch($event)"
            ></app-search-input>
          </div>

          <div class="col-6 ml-auto">
            <form class="filters-form flex" [formGroup]="filterForm" *ngIf=" dataSource.data.length >0">
              <div class="field flex column">
                <mat-label class="field-label">{{ "Filter.Form.CustomerName" | translate }}</mat-label>
                <mat-form-field appearance="outline" class="form-control-full-width">
                  <mat-select formControlName="customer" (selectionChange)="onCustomerSelectionChange($event)">
                    <app-dropdown-search formControlName="customerSearchTerm"></app-dropdown-search>
                    <mat-option [value]="sharedConstants.ALL">{{ 'Options.ALL' | translate }}</mat-option>
                    <mat-option *ngFor="let item of filteredCustomers | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="field flex column">
                <mat-label class="field-label">{{ "Filter.Form.CreatedBy" | translate }}</mat-label>
                <mat-form-field appearance="outline" class="form-control-full-width">
                  <mat-select formControlName="creator" (selectionChange)="onCreatorSelectionChange($event)">
                    <app-dropdown-search formControlName="creatorSearchTerm"></app-dropdown-search>
                    <mat-option [value]="sharedConstants.ALL">{{ 'Options.ALL' | translate }}</mat-option>
                    <mat-option *ngFor="let item of filteredCreators | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </div>
        </div>

        <div>
          <app-entity-listing
            [totalCount]="totalRowsCount"
            [state]="sectionState"
            [pageIndex]="pageIndex"
            [pageSize]="pageSize"
            [emptyStateMainLabel]="'Invoices.DNEmptyStateMainLabel' | translate"
            [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate"
            (pageIndexChanged)="onPageIndexChange($event)"
          >
            <div class="calculations" top>
              <p>
                [
                <span class="all">{{allStatusCount}} {{ "Enum.DeliveryNoteStatus.ALL" | translate }}</span>
                -
                <span class="green-text">{{availableToBeIssuedCount}} {{ "Enum.DeliveryNoteStatus.AVAILABLE_TO_BE_ISSUED" | translate }}</span>
                -
                <span class="red-text">{{notReceivedCount}} {{ "Enum.DeliveryNoteStatus.NOT_RECEIVED" | translate }}</span>
                -
                <span class="orange-text">{{issuedCount}} {{ "Enum.DeliveryNoteStatus.ISSUED" | translate }}</span>
                ]
              </p>
            </div>

            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
              <ng-container matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.CustomerName" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.customerName}">{{ element.customerName }}</td>
              </ng-container>

              <ng-container matColumnDef="from">
                <th mat-header-cell *matHeaderCellDef class="col-width-min">{{ "DeliveryNotes.Table.From" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.from}">{{ element.from }}</td>
              </ng-container>

              <ng-container matColumnDef="to">
                <th mat-header-cell *matHeaderCellDef class="col-width-min">{{ "DeliveryNotes.Table.To" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.to}">{{ element.to }}</td>
              </ng-container>

              <ng-container matColumnDef="deliveryNoteId">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.DeliveryNoteId" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.deliveryNoteId || element.deliveryNoteId === 'null' }">
                  <ng-container *ngIf="element.deliveryNoteId && element.deliveryNoteId !== 'null' ">{{ element.deliveryNoteId }}</ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="transportationCost">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.TransportationCost" | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.transportationCost }}</td>
              </ng-container>

              <ng-container matColumnDef="customerPrice">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.CustomerPrice" | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.totalRate }}</td>
              </ng-container>

              <ng-container matColumnDef="margin">
                <th mat-header-cell *matHeaderCellDef class="col-width-min">{{ "DeliveryNotes.Table.Margin" | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element.margin }}</td>
              </ng-container>

              <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.CreatedBy" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.createdByName}">{{ element.createdByName }}</td>
              </ng-container>

              <ng-container matColumnDef="truckNo">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.TruckNo" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.truckNo}">{{ element.truckNo }}</td>
              </ng-container>

              <ng-container matColumnDef="driverName">
                <th mat-header-cell *matHeaderCellDef class="col-width">{{ "DeliveryNotes.Table.DriverName" | translate }}</th>
                <td mat-cell *matCellDef="let element" [ngClass]="{ 'cell-no-data': !element.driverName}">{{ element.driverName }}</td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef class="col-width-max">{{ "Invoices.Status" | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container [ngSwitch]="element.status">
                    <ng-container *ngSwitchCase="DeliveryNoteStatus.AVAILABLE_TO_BE_ISSUED">
                      <div class="green-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="DeliveryNoteStatus.NOT_RECEIVED">
                      <div class="red-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                    </ng-container>
                    <ng-container *ngSwitchCase="DeliveryNoteStatus.ISSUED">
                      <div class="orange-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="invoiceStatus">
                <th mat-header-cell *matHeaderCellDef class="col-width-max">{{ "Invoices.InvoiceStatus" | translate }}</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  [ngClass]="{ 'cell-no-data': !element.invoiceStatus , 'green-text': element.invoiceStatus == InvoicesStatus.PAID , 'red-text': element.invoiceStatus == InvoicesStatus.UNPAID , 'orange-text': element.invoiceStatus == InvoicesStatus.REJECTED }"
                >
                  <ng-container *ngIf="element.invoiceStatus">{{ 'Enum.InvoicesStatus.' + element.invoiceStatus | translate }}</ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="expand">
                <th class="center" mat-header-cell *matHeaderCellDef class="expand-header"></th>
                <td app-click-stop-propagation mat-cell *matCellDef="let element" (click)="expandRow(element)">
                  <app-icon name="expand_more" type="MaterialDesign" *ngIf=" expandedElement?.id === element.id "></app-icon>
                  <app-icon name="expand_less" type="MaterialDesign" *ngIf="expandedElement?.id !== element.id"></app-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="table-delivery-note-data">
                  <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <table class="delivery-note-table">
                      <tbody>
                        <tr *ngFor="let deliveryNote of element.deliveryNote; let i = index">
                          <td class="col-width" [ngClass]="{ 'cell-no-data': !element.customerName}">
                            <ng-container *ngIf="element.customerName ">{{ element.customerName }}</ng-container>
                          </td>

                          <td class="col-width-min" [ngClass]="{ 'cell-no-data': !element.from}">
                            <ng-container *ngIf="element.from ">{{ element.from }}</ng-container>
                          </td>

                          <td class="col-width-min" [ngClass]="{ 'cell-no-data': !element.to}">
                            <ng-container *ngIf="element.to ">{{ element.to }}</ng-container>
                          </td>

                          <td class="col-width" [ngClass]="{ 'cell-no-data': !deliveryNote.referenceNumber}">
                            <ng-container *ngIf="deliveryNote.referenceNumber ">{{ deliveryNote.referenceNumber }}</ng-container>
                          </td>

                          <td class="col-width">{{ element.transportationCost }}</td>

                          <td class="col-width" [ngClass]="{ 'cell-no-data': !element.totalRate}">
                            <ng-container *ngIf="element.totalRate ">{{ element.totalRate }}</ng-container>
                          </td>

                          <td class="col-width-min">{{ element.margin }}</td>

                          <td class="col-width" [ngClass]="{ 'cell-no-data': !element.createdByName}">
                            <ng-container *ngIf="element.createdByName ">{{ element.createdByName }}</ng-container>
                          </td>

                          <td class="col-width" [ngClass]="{ 'cell-no-data': !element.truckNo}">
                            <ng-container *ngIf="element.truckNo ">{{ element.truckNo }}</ng-container>
                          </td>

                          <td class="col-width" [ngClass]="{ 'cell-no-data': !element.driverName}">
                            <ng-container *ngIf="element.driverName ">{{ element.driverName }}</ng-container>
                          </td>

                          <td class="col-width-max">
                            <ng-container [ngSwitch]="element.status">
                              <ng-container *ngSwitchCase="DeliveryNoteStatus.AVAILABLE_TO_BE_ISSUED">
                                <div class="green-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                              </ng-container>
                              <ng-container *ngSwitchCase="DeliveryNoteStatus.NOT_RECEIVED">
                                <div class="red-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                              </ng-container>
                              <ng-container *ngSwitchCase="DeliveryNoteStatus.ISSUED">
                                <div class="orange-text">{{ "Enum.DeliveryNoteStatus." + element.status | translate}}</div>
                              </ng-container>
                            </ng-container>
                          </td>
                          <ng-container>
                            <td></td>
                          </ng-container>
                          <ng-container>
                            <td></td>
                          </ng-container>
                          <ng-container>
                            <td></td>
                          </ng-container>
                          <ng-container>
                            <td></td>
                          </ng-container>
                          <ng-container>
                            <td></td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: displayedColumns;"
                class="element-row"
                [class.expanded-row]="expandedElement?.id === element.id"
                (click)="expandRow(element)"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
          </app-entity-listing>
        </div>
      </div>
    </div>
  </ng-container>
</app-page-content>
