<div class="container flex flex justify-center">
  <div style="display: block; width: 100%; height: 100%">
    <app-legend-labels
      *ngIf="chartData && this.isAxisVisible"
      [chartData]="chartData"
      (removeChartClicked)="toggleDatasetVisibility($event)"
      [showLastPoint]="showLastPoint"
    ></app-legend-labels>

    <canvas
      *ngIf="render"
      #chart
      baseChart
      [datasets]="chartDataSets"
      [labels]="chartLabels"
      [options]="chartOptions"
      [colors]="chartColors"
      [plugins]="chartPlugins"
      [legend]="false"
      [chartType]="type"
      width="390"
      height="130"
    ></canvas>
  </div>
</div>
