import { ColumnOptionsStateKey } from '@shared-features/models/column-options-state-key.model';
import { Expose, Type } from 'class-transformer';

export class ColumnOptionsStatePreference {
  @Expose()
  @Type(() => ColumnOptionsStateKey)
  state: ColumnOptionsStateKey[];
  @Expose()
  selected?: boolean;
  @Expose()
  name: string;
}
