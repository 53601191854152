import { Model } from '@shared-features/models/model';
import { RequestCreatedByType } from "@features/logs/enums/request-created-by-type.enum";
import { RequestHistoryStatus } from "@features/logs/enums/request-history-status.enum";
import { RequestHistoryAction } from "@features/logs/enums/request-history-action.enum";
import { Expose } from 'class-transformer';
import { User } from '@features/users/models/user';

export class RequestLog extends Model {
  @Expose() action: RequestHistoryAction;
  @Expose() createdAt: Date;
  @Expose() createdBy: User;
  @Expose() creatorType: RequestCreatedByType;
  @Expose() toType: RequestCreatedByType;
  @Expose() fromType: RequestCreatedByType;
  @Expose() requestBody: string;
  @Expose() requestUrl: string;
  @Expose() responseData: string;
  @Expose() status: RequestHistoryStatus;
}
