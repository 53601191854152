<header class="dialog-layout__header">
  <app-row gutter="x-xs" [align]="headerAlignment">
    <app-col>
      <h6 mat-dialog-title class="dialog-layout__title text-900"><ng-content select="[dialog-title]"></ng-content></h6>
    </app-col>
    <app-col cols="auto">
      <div class="flex align-center">
        <ng-content select="[dialog-header-action]"></ng-content>
        <button mat-icon-button #closeButton mat-dialog-close [autofocus]="false" tabindex="-1" class="dialog-layout__icon-button" type="button">
          <app-icon class="text-300" [class.d-none]="enableExternalIcon" name="close-circle"></app-icon>
        </button>
      </div>
    </app-col>
  </app-row>
</header>

<mat-dialog-content class="dialog-layout__content scrollable scrollable--y">
  <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions class="dialog-layout__actions" #actions [class.d-none]="disableActions">
  <ng-content select="[dialog-action]"></ng-content>
</mat-dialog-actions>
