import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogState, MinimizableDialogsService } from '@shared/services/minimizable-dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { LanguageService } from '@shared/services/language.service';
import { combineLatest, Subject } from 'rxjs';

export interface MinimizableDisplay {
  shown: boolean;
  minimized: boolean;
}

@Component({
  selector: 'app-minimizable-dialog',
  templateUrl: './minimizable-dialog.component.html',
  styleUrls: ['./minimizable-dialog.component.scss'],
})
export class MinimizableDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogContent') dialogContent: TemplateRef<HTMLElement>;

  @Input() id: string;

  @Output() closed = new EventEmitter();

  state: DialogState;
  minimizeWidth = 200;
  minimizeHeight = 32;

  minimizeOffset: number;
  dialogOverlay: HTMLElement;

  backdropClass = 'minimizable-dialog__backdrop';
  minimizeClass = 'minimizable-dialog--dialog-minimized';
  activeClass = 'minimizable-dialog--dialog-active';

  private viewInit$ = new Subject<boolean>();

  private dialogRef: MatDialogRef<HTMLElement>;

  private defaultDialogSettings = {
    hasBackdrop: false,
    closeOnNavigation: false,
    disableClose: true,
    enterAnimationDuration: 400,
    existAnimationDuration: 400,
    backdropClass: this.backdropClass,
  };

  private startPosition: 'left'|'right' = 'right';

  private destroy$ = new Subject<boolean>();

  constructor(
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private minimizableDialogs: MinimizableDialogsService,
  ) {
  }

  ngOnInit(): void {
    this.handleActiveDialog();
    this.handleDialogsChanges();
    this.handleLanguageDirection();
  }

  handleActiveDialog(): void {
    this.minimizableDialogs.activeDialogs$.pipe(
      takeUntil(this.destroy$),
      filter((dialogs) => !!dialogs.length),
      map((dialogs) => dialogs[dialogs.length - 1]),
    ).subscribe((activeDialogId) => {
      if (this.dialogOverlay) {
        if (activeDialogId === this.id) {
          this.dialogOverlay.classList.add(this.activeClass);
        } else if (this.dialogOverlay.classList.contains(this.activeClass)) {
          this.dialogOverlay.classList.remove(this.activeClass);
        }
      }
    });
  }

  private handleLanguageDirection(): void {
    this.languageService.currentLanguage$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
      if (this.languageService.checkLanguageDirection(lang.isoCode) === 'rtl') {
        this.startPosition = 'left';
      } else {
        this.startPosition = 'right';
      }
      if (this.dialogRef) {
        this.updatePosition();
      }
    });
  }

  private handleDialogsChanges(): void {
    combineLatest([
      this.minimizableDialogs.dialogs$,
      this.viewInit$,
      this.minimizableDialogs.updates$,
    ]).pipe(
      takeUntil(this.destroy$),
      filter(([_, viewInit]) => !!this.id && viewInit),
      map(([states]) => states.find(state => state.id === this.id)),
      filter((state) => !!state),
    ).subscribe((state) => {
      const { minimizeOrder, ...restOfState } = state;
      const minimizeChanged = this.checkPropertyChanged(this.state, restOfState, 'minimized');
      const refChanged = this.checkPropertyChanged(this.state, restOfState, 'ref');

      if (refChanged && !restOfState.ref && this.dialogRef) {
        this.dialogRef.close();
        this.dialogRef = null;
        this.minimizableDialogs.remove(this.id);
      }

      this.state = restOfState;
      this.minimizeOffset = minimizeOrder;

      if (this.dialogRef) {
        this.updatePosition();
      }
    });
  }

  onClosed(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.viewInit$.next(true);
  }

  open(): void {
    const size = this.getSizeAsString(!!this.state?.minimized);
    const position = this.getPosition(!!this.state?.minimized);

    if (this.dialogRef) { return; }

    this.dialogRef = this.matDialog.open(this.dialogContent, {
      ...this.defaultDialogSettings,
      ...size,
      position,
    });
    this.minimizableDialogs.add({
      id: this.id,
      ref: this.dialogRef,
      size,
      position,
      minimized: false,
      shown: true
    });
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getOverlay();
    });
  }

  close(options = { emitUpdates: true }): void {
    this.dialogRef.close();
    this.dialogRef = null;
    this.minimizableDialogs.remove(this.id);
    this.closed.emit();
    this.onClosed();
  }

  minimize(options = { emitUpdates: true }): void {
    this.minimizableDialogs.minimize(this.id);
  }

  maximize(options = { emitUpdates: true }): void {
    this.minimizableDialogs.maximize(this.id);
  }

  updatePosition(): void {
    const size = this.getSizeAsString(this.state?.minimized);
    const position = this.getPosition(this.state?.minimized);
    this.dialogRef.updateSize(size.width, size.height);
    this.dialogRef.updatePosition(position);
    if (!this.dialogOverlay) {
      return;
    }
    if (this.state?.minimized) {
      this.dialogOverlay.classList.add(this.minimizeClass);
    } else {
      this.dialogOverlay.classList.remove(this.minimizeClass);
    }
  }

  private getOverlay(): void {
    const dialogElement = document.getElementById(this.dialogRef.id);
    this.dialogOverlay = dialogElement.parentNode?.parentNode as HTMLElement;
  }

  private getSize(minimized?: boolean): { width: number, height: number } {
    return minimized ? {
      width: this.minimizeWidth,
      height: this.minimizeHeight,
    } : {
      width: innerWidth / 2,
      height: innerHeight,
    };
  }

  private getSizeAsString(minimized?: boolean): { width: string, height: string } {
    const size = this.getSize(minimized);
    return {
      width: `${size.width}px`,
      height: `${size.height}px`,
    };
  }

  private getPosition(minimized?: boolean): any {
    const { width, height } = this.getSize(minimized);
    const { innerWidth } = window;
    const maximumCols = this.minimizableDialogs.maximumColumns; // to be added from top
    const offset = this.minimizeOffset === null  || typeof this.minimizeOffset === 'undefined' ? 0 : this.minimizeOffset;
    const heightOffset = Math.floor(offset / maximumCols);
    const widthOffset = offset % maximumCols;
    let pos = {};
    switch (this.startPosition) {
      case 'left':
        pos = minimized
          ? { left: `${((Math.max(widthOffset, 0) * width) / innerWidth) * 100}%`, bottom: `${Math.max(heightOffset, 0) * height}px` }
          : { right: '50%', bottom: '0' };
        break;
      case 'right':
      default:
        pos = minimized
          ? {right: `${((Math.max(widthOffset, 0) * width) / innerWidth) * 100}%`, bottom: `${Math.max(heightOffset, 0) * height}px` }
          : { left: '50%', bottom: '0'};
        break;
    }

    return pos;
  }



  private checkPropertyChanged(previous: { [k: string]: any }, current: { [k: string]: any }, key: string): boolean {
    return (!current || !previous)
      || (current && previous && current[key] !== previous[key]);
  }
}
