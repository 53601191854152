<section class="legend-list">
  <app-row [gutter]="!reverseOrder ? 'y-md' : 'y-0'">
    <ng-container *ngFor="let dataPoint of chartData; let i = index">
      <app-col [md]="columns" xs="12">
        <div class="legend-list__item" (click)="chartToggleClick(i)">
          <div class="legend-list__item-label" [style.background-color]="dataPoint.backgroundColor"></div>
          <div class="legend-list__item-content">
            <!-- Check the reverseOrder property to determine the order of label -->
            <ng-container *ngIf="!reverseOrder; else reversedOrder">
              <p class="legend-list__item-content-suffix" [ngClass]="{'removed': clickedIndexes.includes(i)}">
                <ng-container *ngIf="dataPoint.label === 'Completed'; else otherLabel">
                  {{ calculateTotal(dataPoint.data) + ' - ' + ((calcCompletedPercentage() || 0) | number: '1.0-1') + '%' }}
                </ng-container>
                <ng-template #otherLabel>{{ showLastPoint ? lastPoint(dataPoint.data) : calculateTotal(dataPoint.data) }}</ng-template>
                <span
                  *ngIf="dataPoint.label === 'Not Fulfilled'"
                  [matTooltip]="'dashboard.NotFulfilledDescription' | translate"
                  matTooltipPosition="above"
                  matTooltipClass="mat-tooltip-no-wrap"
                >
                  <img class="reset-spacing" src="assets/images/icons/message-question.svg" />
                </span>
              </p>
              <span class="legend-list__item-content-prefix">{{ dataPoint.label }}</span>
            </ng-container>

            <ng-template #reversedOrder>
              <span class="legend-list__item-content-prefix">{{ dataPoint.label | translate }}</span>
              <p class="legend-list__item-content-suffix mt-1 weight-normal" style="font-size: 12px">{{ dataPoint.data || 0 }}</p>
            </ng-template>
          </div>
        </div>
      </app-col>
    </ng-container>
  </app-row>
</section>
