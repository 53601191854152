import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabModel } from '../model/tab.model';

@Injectable({
  providedIn: 'root',
})

export class TabNavigationService {
  public activatedTab$: BehaviorSubject<TabModel> = new BehaviorSubject(new TabModel());
}
