import { RolesConstants } from '@shared/model/roles-constants';
import { PermissionDeniedPageComponent } from '@shared/pages/permission-denied-page/permission-denied-page.component';
import { PermissionGuard } from '@core/guards/permission.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { SharedConstants } from '@shared/model/shared-constants';
import { WrapperComponent } from '@layout/pages/wrapper/wrapper.component';
import { RoutesUtil } from '@shared-features/utils/routes.util';

const routes: Routes = [
  {
    path: RoutesUtil.Auth.path,
    loadChildren: () =>
      import(/* webpackChunkName: "Auth" */ './features/auth/auth.module').then(
        (m) => m.AuthModule
      ),
  },
  {
    path: '',
    component: WrapperComponent,
    children: [
      {
        path: RoutesUtil.Dashboard.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Dashboard" */ './features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: RoutesUtil.ShipmentsB2b.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(/* webpackChunkName: "Shipments" */ './features/shipments/shipments.module').then(
            (m) => m.ShipmentsModule
          ),
      },

      {
        path: RoutesUtil.ShipmentTemplate.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "ShipmentCreationTemplate" */ '@features/shipment-creation-template/shipment-creation-template.module'
          ).then(({ ShipmentCreationTemplateModule }) => ShipmentCreationTemplateModule),
      },

      {
        path: RoutesUtil.ZonesB2C.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(
            /* webpackChunkName: "B2cShipments" */ './features/b2c-zones/b2c-zones.module'
          ).then((m) => m.B2cZonesModule),
      },
      {
        path: RoutesUtil.ConfigurationB2c.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(
            /* webpackChunkName: "B2cShipments" */ './features/b2c-configuration/b2c-configuration.module'
          ).then((m) => m.B2cConfigurationModule),
      },
      {
        path: RoutesUtil.ShipmentsB2c.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(
            /* webpackChunkName: "B2cShipments" */ './features/b2c-shipments/b2c-shipments.module'
          ).then((m) => m.B2cShipmentsModule),
      },
      {
        path: RoutesUtil.CustomersB2B.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(/* webpackChunkName: "Customers" */ './features/customers/customers.module').then(
            (m) => m.CustomersModule
          ),
      },
      {
        path: RoutesUtil.Sellers.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(/* webpackChunkName: "Sellers" */ './features/sellers/sellers.module').then(
            (m) => m.SellersModule
          ),
      },
      {
        path: RoutesUtil.Fleets.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Fleets" */ './features/fleets/fleets.module').then(
            (m) => m.FleetsModule
          ),
      },
      {
        path: RoutesUtil.FleetsB2c.path,
        loadChildren: () =>
          import(/* webpackChunkName: "FleetsB2c" */ './features/b2c-fleets/fleet-b2c.module').then(
            (m) => m.FleetsB2cModule
          ),
      },
      {
        path: RoutesUtil.TransPays.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "TransportationPayments" */ './features/transportation-payments/transportation-payments.module'
          ).then((m) => m.TransportationPaymentsModule),
      },
      {
        path: RoutesUtil.Payment.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "TransportationPayments" */ './features/payment/payment.module'
          ).then((m) => m.PaymentModule),
      },
      {
        path: RoutesUtil.Settings.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Settings" */ './features/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: RoutesUtil.Reports.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Reports" */ './features/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
      },
      {
        path: RoutesUtil.SalesOrders.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "SalesOrders" */ './features/sales-orders/sales-orders.module'
          ).then((m) => m.SalesOrdersModule),
      },
      {
        path: RoutesUtil.Orders.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Orders" */ './features/orders/orders.module').then(
            (m) => m.OrdersModule
          ),
      },
      {
        path: RoutesUtil.Receivers.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Receivers" */ './features/receivers/receivers.module').then(
            (m) => m.ReceiversModule
          ),
      },
      {
        path: RoutesUtil.Users.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Users" */ './features/users/users.module').then(
            (m) => m.UsersModule
          ),
      },
      {
        path: RoutesUtil.Invoices.path,
        canActivate: [PermissionGuard],
        data: {
          roles: RolesConstants.INVOICES,
        },
        loadChildren: () =>
          import(/* webpackChunkName: "Invoices" */ './features/invoices/invoices.module').then(
            (m) => m.InvoicesModule
          ),
      },
      {
        path: RoutesUtil.Organizations.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "Organizations" */ './features/organization/organization.module'
          ).then((m) => m.OrganizationModule),
      },
      {
        path: RoutesUtil.LiveTracking.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "LiveTracking" */ './features/live-tracking/live-tracking.module'
          ).then((m) => m.LiveTrackingModule),
        canActivate: [PermissionGuard],
        data: {
          roles: RolesConstants.LIVE_TRACKING_VIEW,
        },
      },
      {
        path: RoutesUtil.B2cLiveTracking.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "B2cLiveTracking" */ './features/b2c-live-tracking/b2c-live-tracking.module'
          ).then((m) => m.B2cLiveTrackingModule),
      },
      {
        path: RoutesUtil.Leads.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Leads" */ './features/leads/leads.module').then(
            (m) => m.LeadsModule
          ),
      },
      {
        path: RoutesUtil.DeliveryNotes.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "DeliveryNotes" */ './features/delivery-notes/delivery-notes.module'
          ).then((m) => m.DeliveryNotesModule),
      },
      {
        path: RoutesUtil.RulesB2C.pathFromRoot.replace(/^\//, ''),
        loadChildren: () =>
          import(
            /* webpackChunkName: "DeliveryNotes" */ './features/b2c-rules/b2c-rules.module'
          ).then((m) => m.B2cRulesModule),
      },
      {
        path: RoutesUtil.Logs.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Logs" */ './features/logs/logs.module').then(
            (m) => m.LogsModule
          ),
      },
      {
        path: RoutesUtil.TripPlanning.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "TripPlanning" */ './features/trip-planning/trip-planning.module'
          ).then((m) => m.TripPlanningModule),
      },
      {
        path: RoutesUtil.TripPerformance.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "TripPerformance" */ './features/trip-performance/trip-performance.module'
          ).then((m) => m.TripPerformanceModule),
      },
      {
        path: RoutesUtil.Support.path,
        loadChildren: () =>
          import(/* webpackChunkName: "Support" */ './features/support/support.module').then(
            (m) => m.SupportModule
          ),
      },
      {
        path: RoutesUtil.RentalProfit.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "TripPlanning" */ './features/rental-profit/rental-profit.module'
          ).then((m) => m.RentalProfitModule),
      },
      {
        path: RoutesUtil.SmartCenter.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "SmartCenter" */ './features/smart-center/smart-center.module'
          ).then((m) => m.SmartCenterModule),
      },

      {
        path: RoutesUtil.ReleaseNotes.path,
        loadChildren: () =>
          import(
            /* webpackChunkName: "ReleaseNotes" */ './features/release-notes/release-notes.module'
          ).then((m) => m.ReleaseNotesModule),
      },
      {
        path: 'public',
        loadChildren: () =>
          import(/* webpackChunkName: "Public" */ './features/public/public.module').then(
            (m) => m.PublicModule
          ),
      },

      {
        path: SharedConstants.ACCESS_DENIED,
        component: PermissionDeniedPageComponent,
      },
      { path: '', redirectTo: RoutesUtil.Home.url(), pathMatch: 'full' },
    ],
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
