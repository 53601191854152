import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { FieldBase } from '@shared/components/forms';
import { NamedField } from '@shared/interfaces/field.interface';
import { FormArray, FormGroup } from '@angular/forms';
import { ColsWithBreakpoint } from '@shared/components/grid/col.component';

@Component({
  selector: 'app-radio-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-label class="field__label" *ngIf="field.label"
        >{{ field.label | translate }}
        <span *ngIf="isRequired" class="text-warn">*</span></mat-label
      >
      <mat-radio-group
        [formControlName]="field.name"
        #element
        class="field__input"
        (click)="fieldClick.emit($event)"
      >
        <app-row>
          <app-col
            *ngFor="let option of options"
            [cols]="option.colsConfig?.cols"
            [xs]="option.colsConfig?.xs"
            [sm]="option.colsConfig?.sm"
            [md]="option.colsConfig?.md"
            [lg]="option.colsConfig?.lg"
            [xl]="option.colsConfig?.xl"
            [xxl]="option.colsConfig?.xxl"
          >
            <mat-radio-button [value]="option.value" [color]="color">{{
              option.label | translate
            }}</mat-radio-button>
          </app-col>
        </app-row>
      </mat-radio-group>
      <mat-error *ngIf="errors.length" class="field__error">
        <app-field-errors [errors]="errors"></app-field-errors>
      </mat-error>
    </ng-container>
  `,

  styles: [
    `
      :host.field--inline {
        display: flex;
        min-width: 100%;
      }

      :host.field--inline mat-label {
        margin-bottom: 0;
        padding: 0.5rem 0;
        padding-inline-end: 0.25rem;
        line-height: 1.5rem;
      }
    `,
  ],
  host: { class: 'field' },
})
export class RadioFieldComponent extends FieldBase {
  @Input() options: { label: string; value: string; colsConfig?: ColsWithBreakpoint }[];
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;
  @Input() inline: boolean;
  @HostBinding('class.field--inline') get isInline() {
    return this.inline;
  }

  @ViewChild('element') element: ElementRef;
}
