import { Expose, Type } from 'class-transformer';
import { TruckOperationStatus } from "@features/fleets/enums/truck-operation-status.enum";
import { Model } from '../../shared-features/models/model';
import { User } from '../../users/models/user';

export class StatusHistory extends Model {
  @Expose() from: string;
  @Expose() to: string;
  @Expose() status: TruckOperationStatus;
  @Type(() => User)
  @Expose()
  createdBy: User;

  constructor() {
    super();
  }
}
