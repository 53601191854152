import { BlocState } from '@core/bloc/bloc-state';
import { Customer } from '@features/customers/models/customer';
import { Fleet } from '@features/fleets/models/fleet';

export abstract class OrderStatusFilterState extends BlocState {}

export class OrderStatusFilterReadyState extends OrderStatusFilterState {
    constructor(
      public customers: Customer[],
      public fleets: Fleet[],
      ) {
      super();
    }
  }
