import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';
import { LicenseType } from "@features/fleets/enums/license-type.enum";
import { Nationality } from "@features/fleets/enums/nationality.enum";

export class Elm extends Model {
  @Expose() firstName: string;
  @Expose() lastName: string;
  @Expose() iqamaExpiryDate: Date;
  @Expose() licenseExpiryDate: Date;
  @Expose() licenseType: LicenseType;
  @Expose() nationality: Nationality;
  @Expose() owner: string;
  @Expose() issueNumber: number;
  @Expose() elmObj: any;

  constructor() {
    super();
  }
}
