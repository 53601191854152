import { Expose } from 'class-transformer';
import { Model } from '@shared-features/models/model';
import { CityArea } from '../../shared-features/models/city-area.model';

export class TruckLocation extends Model {
  @Expose() resolvedCity: CityArea;
  @Expose() currentCity: string;

  constructor() {
    super();
  }
}
