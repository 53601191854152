import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FilterModule } from '@features/filter/filter.module';
import { NotificationsModule } from '@features/notifications/notifications.module';
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { WrapperComponent } from './pages/wrapper/wrapper.component';
import { SearchComponent } from './components/search/search.component';
import { OpsScoreDialogComponent } from './components/ops-score-dialog/ops-score-dialog.component';
import { LanguageService } from '@shared/services/language.service';
import i18n from '@layout/i18n';
import { DateAdapter } from '@angular/material/core';
import { DateFormat } from '@shared/model/date-format';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    MainPageComponent,
    WrapperComponent,
    SearchComponent,
    OpsScoreDialogComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FilterModule,
    NotificationsModule,
  ],
  exports: [ MainPageComponent, WrapperComponent ],
  providers: [
    // {provide: DateAdapter, useClass}
    { provide: DateAdapter, useClass: DateFormat },
  ]
})
export class LayoutModule {
  constructor(private dateAdapter: DateAdapter<Date>, private languageService: LanguageService) {
    languageService.registerModule(i18n);
    dateAdapter.setLocale(this.languageService.currentLanguage$.value.isoCode); // DD/MM/YYYY
  }
}
