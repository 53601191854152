import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class Brand extends Model {
  @Expose() id: number;
  @Expose() name: string;
  @Expose() createdAt: number;
  @Expose() createdBy: string;

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): object {
    return this;
  }
}
