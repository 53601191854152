;
import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class OrdersStatusCount extends Model {
  @Expose()
  allCount: number;
  @Expose()
  dispatchedCount: number;
  @Expose()
  stagedCount: number;
}
