import { Component, EventEmitter,  Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TabGroup } from '@shared/enums/tab-group.enum';

@Component({
  selector: 'app-shipment-list-preview-filter',
  templateUrl: './shipment-list-preview-filter.component.html',
  styleUrls: ['./shipment-list-preview-filter.component.scss']
})

export class ShipmentListPreviewFilterComponent extends BaseComponent implements OnInit {
  @Input() form:FormGroup
  @Input() params: any = null;
  @Input() isCreateFilter
  @Input() favFiltersIndex
  @Input() isEditFilter
  @Output() setFormValues = new EventEmitter()
  @Output() clickEditFilter = new EventEmitter<boolean>()
  @Output() clickCreateFilter = new EventEmitter<boolean>()
  @Output() setFavFiltersIndex = new EventEmitter<number>()
  @Output() closeFilter = new EventEmitter<number>()

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {}

  resetForm(): void {
    this.setFavFiltersIndex.emit(-1)
    this.setFormValues.emit({})
  }

  applyFilter(): void {
    const {
      customersIds,
      fleetsIds,
      creator,
      assignedBy,
      customerLabels,
      withoutPrice,
      withoutDeliveryNote,
      isReceived
    } =  this.form.value

    const selectedFilterValues = {
      tabIndex: 0,
      tabGroup: TabGroup.Shipments,
      favFiltersIndex:this.favFiltersIndex,
      driverName: null,
      customerId: customersIds?.map(customer=> customer.id) ,
      customerName: customersIds?.map(customer=> customer.value) ,
      fleetId: fleetsIds?.map(fleet=> fleet.id) ,
      fleetName: fleetsIds?.map(fleet=> fleet.value) ,
      creator: creator?.map(creator=> creator.id),
      creatorName: creator?.map(creator=> creator.value),
      assignedBy: assignedBy?.map(assignedBy=> assignedBy.id),
      assignedByName: assignedBy?.map(assignedBy=> assignedBy.value),
      customerLabel: customerLabels?.map(customerLabels=> customerLabels.id),
      withoutPrice :withoutPrice || false,
      withoutDeliveryNote :withoutDeliveryNote || false,
      isReceived :isReceived || false,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...selectedFilterValues,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });

    this.closeFilter.emit()
  }
}


