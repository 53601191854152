import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fixed-side-layout',
  templateUrl: './fixed-side-layout.component.html',
  styleUrls: ['./fixed-side-layout.component.scss']
})
export class FixedSideLayoutComponent implements OnInit {
  @Input() spacing: 'none'|'sm'|'md'|'lg';
  @Input() direction: 'left|right';
  @Input() corners: 'square|rounded';
  @Input() mediaImageUrl: string;
  @Input() contentWidth: '40'|'45'|'50'|'55'|'60';

  constructor() { }

  ngOnInit(): void {
  }

}
