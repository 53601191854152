import { Component, EventEmitter,  OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ColumnOptionsService } from '@shared/services/column-options.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base-component/base.component';
import { ColumnOptionsStateKey } from '@shared-features/models/column-options-state-key.model';
import { ColumnOptionsStatePreference } from '@shared-features/models/column-options-state-preference.model';
import { FilterDialogService } from '@shared/services/filter-dialog.service';
import { ColumnOptionsPreferencesComponent } from '@shared/components/column-options-preferences/column-options-preferences.component';
import { ColumnOptionsState } from '@shared-features/models/column-options-state.model';
import { ColumnOptionsLabels } from '@shared/enums/column-options-labels.enum';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DrawerType } from '@shared/enums/drawer-type.enum';
import { ShipmentFilterLayout } from '@features/shipments/enums/shipment-filter-layout.enum';
import { ButtonTheme } from '@shared/enums/button-theme.enum';
import { SectionStateStatus } from '@shared/enums/section-state-status.enum';

@Component({
  selector: 'app-column-options',
  templateUrl: './column-options.component.html',
  styleUrls: ['./column-options.component.scss'],
})
export class ColumnOptionsComponent extends BaseComponent implements OnInit {
  @Output() drawerOpened = new EventEmitter<{ open: true; type: DrawerType }>();
  @Output() drawerClosed = new EventEmitter<void>();
  listName: string;
  keys: any;
  state: ColumnOptionsStateKey[];
  defaultState: ColumnOptionsStateKey[];
  preferences: ColumnOptionsStatePreference[];
  selectedPreference: string = null;
  form = new FormGroup({});
  isShown = false;
  destroy$ = new Subject();
  scrollStrategy = this.scrollStrategyOptions.block();

  constructor(
    private scrollStrategyOptions: ScrollStrategyOptions,
    private columnOptionsService: ColumnOptionsService,
    private filterDialogService: FilterDialogService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.columnOptionsService.state$
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => this.getColumnState(state));

    this.columnOptionsService.drawer$.subscribe((shown: boolean) => {
      if (!shown && this.isShown) {
        this.closeDrawer();
      } else if (shown) {
        this.drawerOpened.emit({ open: true, type: DrawerType.ColumnOptions });
      }
      this.isShown = shown;
    });
  }

  applyChanges(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    this.columnOptionsService.state = {
      id: this.listName,
      keys: this.keys,
      state: this.state,
      preferences: this.preferences || [],
    };
    this.closeDrawer();
    this.columnOptionsService.saveStorage().subscribe(() => {
      this.showSuccessMessage();
    })
  }

  closeDrawer(): void {
    this.drawerClosed.emit();
  }

  resetOptions(): void {
    const { keys, defaultState } = this.columnOptionsService.getStateByName(this.listName);
    this.keys = keys;
    this.state = defaultState || [];
    this.selectedPreference = '';
  }

  setPreference(preference: ColumnOptionsStatePreference): void {
    this.selectedPreference = preference.name;
    this.preferences = this.preferences.map((item) => {
      if (item.name === preference.name) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    this.state = preference.state;
  }

  private async openPreferenceDialog(
    id: string,
    state: ColumnOptionsStateKey[],
    keys: any,
    name: string
  ) {
    const controller = await this.filterDialogService.open(
      ColumnOptionsPreferencesComponent,
      {
        id,
        state,
        name,
        keys,
      },
      {
        filterLayoutType: ShipmentFilterLayout.Nested,
        scrollStrategy: this.scrollStrategy,
        position: 'right',
        title: 'shared.components.ColumnOptions.CustomTitle',
        buttons: {
          reset: {
            label: ColumnOptionsLabels.BackToOptions,
            theme: ButtonTheme.Flat,
            color: 'primary',
          },
          submit: {
            label: name ? ColumnOptionsLabels.Update : ColumnOptionsLabels.Create,
            theme: ButtonTheme.Raised,
            color: 'secondary',
          },
        },
      }
    );

    controller.dialogRef
      .afterClosed()
      .pipe(switchMap((submit) => (submit ? this.columnOptionsService.saveStorage() : of(null))))
      .subscribe((updated) => {
        if (updated) {
          this.getColumnState(this.columnOptionsService.getStateByName(this.listName));
        }
      });
  }

  openCreatePreferenceDialog(): void {
    this.openPreferenceDialog(this.listName, this.defaultState || [], this.keys, '');
  }

  openEditPreferenceDialog(preference: ColumnOptionsStatePreference): void {
    this.openPreferenceDialog(
      this.listName,
      preference.state || [],
      this.keys,
      preference.name || 'untitled'
    );
  }

  deletePreference(preference: ColumnOptionsStatePreference) {
    const preferences = this.preferences.filter((p) => p.name !== preference.name);
    this.columnOptionsService.state = {
      id: this.listName,
      keys: this.keys,
      state: this.state,
      defaultState: this.defaultState,
      preferences,
    };
    this.sectionState = SectionStateStatus.Loading;
    this.columnOptionsService.saveStorage().subscribe(() => {
      this.showSuccessMessage();
      this.getColumnState(this.columnOptionsService.getStateByName(this.listName));
      this.sectionState = SectionStateStatus.Ready;
    });
  }

  getColumnState({ id, keys, state, preferences, defaultState }: ColumnOptionsState): void {
    const selectedPreference = this.preferences?.find((p) => p.selected);
    this.listName = id;
    this.keys = keys;
    this.preferences = preferences || [];
    this.state = selectedPreference?.state || state || [];
    this.defaultState = defaultState;
    if (selectedPreference) {
      this.selectedPreference = selectedPreference.name;
    }
  }
}
