import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Bloc } from '@core/bloc';
import { BlocEvent, InitEvent } from '@core/bloc/bloc-event';
import { BlocState, ErrorState, ProcessingState, EmptyState, ReadyState } from '@core/bloc/bloc-state';
import { NotificationsApiService } from '@features/notifications/services/notifications-api.service';
import { NonDrawing } from '@shared-features/models/non-drawing';
import { NotificationItem } from '@shared-features/models/notification-item';

@Injectable()
export class NotificationBloc extends Bloc<BlocEvent, BlocState> {
  constructor(private notificationsApiService: NotificationsApiService) {
    super();
  }

  protected onIncomingEvents(event: BlocEvent): void {
    if (event instanceof InitEvent) {
      this.onState$.next(new ProcessingState<NonDrawing>(new NonDrawing()));

      this.notificationsApiService
        .getAllNotifications(event.args)
        .pipe(take(1))
        .subscribe(
          (notifications) => {
            if (Array.isArray(notifications) && !notifications.length) {
              this.onState$.next(new EmptyState());
              return;
            }
            this.onState$.next(new ReadyState<NotificationItem>(notifications));
          },
          (error) => {
            this.onState$.next(new ErrorState<NonDrawing>(new NonDrawing(), error));
          }
        );
    }
  }
}
