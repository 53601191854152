<div class="row gx-xs mb-2">
    <div class="col-auto"><b>{{'Shipments.RequestUrl'|translate}}:</b></div>
    <div class="col">
      {{selectedItem?.requestUrl  || '____'}}
    </div>
  </div>

  <div class="row gx-xs mb-4">
    <div class="col-auto"><b>{{'Shipments.CreatedAt'|translate}}:</b></div>
    <div class="col">
      {{(selectedItem.createdAt | date) || '____'}}
    </div>
  </div>

  <div class="brd brd-1 brd-300 p-4 bg-200">
    <pre class="my-0 scrollable scrollable--x">{{selectedItemPayload|json}}</pre>
  </div>

  <div class="pt-4 row">
    <div class="col-auto ml-auto">
      <a
        mat-flat-button
        [href]="downloadString"
        download="request-{{selectedItem.createdAt | date}}-.json"
        (click)="closeModal.emit()"
        color="primary">
        <span class="text-white">{{'Shipments.Download' | translate}}</span>
      </a>
    </div>
  </div>
