import { Injectable } from '@angular/core';
import { IAppSettings } from '@core/interfaces/app-settings.interface';
import { FeatureFlagService } from './feature-flag.service';
import { ZendeskService } from './zendesk.service';
import { AuthService } from './auth.service';
import { UsersApiService } from '@features/users/services/users-api.service';
import { ConfigConstant, StorageConstant } from '@shared/constants';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { User } from '@features/users/models/user';
import * as Sentry from '@sentry/angular-ivy';
import { fromPromise } from 'rxjs/internal-compatibility';
import { ApiBaseService } from '@core/services/api-base.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config = new BehaviorSubject<IAppSettings>(null);
  private destroy$ = new Subject();
  private appVersion = LocalStorageUtil.getItem(ConfigConstant.APP_VERSION_KEY) || null;

  config$ = this.config.asObservable();
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
    private zendeskService: ZendeskService,
    private usersApiService: UsersApiService,
    private apiBaseService: ApiBaseService
  ) {}

  init() {
    const hasVersionChanged = !this.appVersion || this.appVersion !== ConfigConstant.VERSION;

    return this.getAppSettings()
      .pipe(
        tap((settings) => this.config.next(settings)),
        filter((settings) => !!settings),
        tap((settings) => this.apiBaseService.setBaseUrl(settings.API_URL)),
        debounceTime(50),
        switchMap(() =>
          combineLatest([this.getStoredUser(), this.updateVersion(hasVersionChanged)])
        ),
        switchMap(([currentUser, versionChanged]) =>
          this.updateStoredUser(currentUser, versionChanged)
        ),
        tap((user) => user?.id && Sentry.setUser({ id: user.id, email: user.email } as any)),
        switchMap((user) =>
          fromPromise(this.featureFlagService.init(user || ({ name: 'guest' } as any)))
        ),
        tap(() => this.zendeskService.init())
      )
      .toPromise();
  }

  private getAppSettings() {
    return this.http.get<IAppSettings>('/assets/app-settings.json').pipe(tap(console.error));
  }

  private getStoredUser(): Observable<User> {
    return of(LocalStorageUtil.getItem(StorageConstant.AUTH_USER) || null);
  }

  private updateStoredUser(currentUser: User, hasToRefresh: boolean): Observable<User> {
    if (!hasToRefresh || !currentUser?.accessToken) {
      return of(currentUser);
    }

    return this.usersApiService.getUserById(`${currentUser}`).pipe(
      map((user) =>
        Object.assign(user, {
          userRoles: user?.roles?.map((role) => role.userRole) || [],
          accessToken: currentUser?.accessToken,
          refreshToken: currentUser?.refreshToken,
        })
      ),
      catchError((err) => {
        console.error('Error while updating user', err);
        return of(null);
      })
    );
  }

  private updateVersion(versionChanged: boolean) {
    if (versionChanged) {
      localStorage.clear();
      LocalStorageUtil.setItem(ConfigConstant.APP_VERSION_KEY, ConfigConstant.VERSION);
      this.appVersion = ConfigConstant.VERSION;
    }
    return of(versionChanged);
  }
}
