import { Component, OnInit, Input,  ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { SharedConstants } from '@shared/model/shared-constants';
import { Fleet } from '@features/fleets/models/fleet';
import { FilterService } from '@shared/services/filter.service';
import { ActivatedRoute } from '@angular/router';
import { OrderStatusFilterBloc } from '../../../dashboard/bloc/order-status-filter/order-status-filter-bloc';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BlocState, ProcessingState } from '@core/bloc/bloc-state';
import { InitEvent } from '@core/bloc/bloc-event';
import { OrderStatusFilterReadyState } from '../../../dashboard/bloc/order-status-filter/order-status-filter-state';
import { LocalStorageDashboardStatus } from '@shared/model/dashboard.model';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { FunctionUtil } from '@shared/utils/function.util';
import { StorageConstant } from '@shared/constants/storage.constant';
import { Customer } from '@features/customers/models/customer';

@Component({
  selector: 'app-order-status-filter',
  templateUrl: './order-status-filter.component.html',
  styleUrls: ['./order-status-filter.component.scss'],
  providers: [OrderStatusFilterBloc],
})
export class OrderStatusFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<void>;
  form: UntypedFormGroup;
  dispose$ = new Subject();

  sharedConstants = SharedConstants;
  dateRangeType = DateRangeType;
  filterActionSubscription: Subscription;
  sectionStateStatus = SectionStateStatus;
  params: any = null;
  customers: Customer[] = [];
  fleets: Fleet[] = [];
  currentURL: any;
  maxDate: Date = new Date();

  dashboardStatus: LocalStorageDashboardStatus = new LocalStorageDashboardStatus();

  constructor(
    private filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private bloc: OrderStatusFilterBloc,
    
  ) {
    super();
    this.bloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    this.bloc.setEvent.next(new InitEvent());
    this.dashboardStatus = LocalStorageUtil.getItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS);
    this.subscribeToFilterAction();
    this.subscribeOnGeneralDashboardDateRange();
  }

  ngOnDestroy() {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  subscribeOnGeneralDashboardDateRange() {
    this.filterService.generalDashboardConfig$.subscribe((config) => {
      const value = config?.range?.range;
      if (value && value !== DateRangeType.ALL_TIME) {
        this.dashboardStatus.orderStatusTime = value;
        this.form.controls['time'].setValue(value);
        LocalStorageUtil.setItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS, this.dashboardStatus);
        this.submitForm();
      }
    });
  }

  initForm() {
    this.form = new UntypedFormGroup({
      customerId: new UntypedFormControl(SharedConstants.ALL),
      fleetId: new UntypedFormControl(SharedConstants.ALL),
      time: new UntypedFormControl(DateRangeType.TODAY),
      startFrom: new UntypedFormControl(null),
      endAt: new UntypedFormControl(null),
      fleetSearchTerm: new UntypedFormControl(''),
      customerSearchTerm: new UntypedFormControl(''),
    });

    if (this.dashboardStatus.orderStatusCustomerId) {
      const _customerId =
        this.dashboardStatus.orderStatusCustomerId === SharedConstants.ALL
          ? SharedConstants.ALL
          : parseInt(this.dashboardStatus.orderStatusCustomerId);
      this.form.controls['customerId'].setValue(_customerId);
    }

    if (this.dashboardStatus.orderStatusFleetId) {
      const _fleetId =
        this.dashboardStatus.orderStatusFleetId === SharedConstants.ALL
          ? SharedConstants.ALL
          : parseInt(this.dashboardStatus.orderStatusFleetId);
      this.form.controls['fleetId'].setValue(_fleetId);
    }

    if (this.dashboardStatus.orderStatusTime) {
      this.form.controls['time'].setValue(this.dashboardStatus.orderStatusTime);

      if (
        this.dashboardStatus.orderStatusTime === DateRangeType.CUSTOM &&
        this.dashboardStatus.orderStatusTimeStartFrom &&
        this.dashboardStatus.orderStatusTimeEndAt
      ) {
        this.form.controls['startFrom'].setValue(this.dashboardStatus.orderStatusTimeStartFrom);
        this.form.controls['endAt'].setValue(this.dashboardStatus.orderStatusTimeEndAt);
      }
    }

    this.form
      .get('time')
      .valueChanges.pipe(takeUntil(this.destroy$), debounceTime(250))
      .subscribe((value) => {
        if (value !== DateRangeType.CUSTOM) {
          this.form.controls['startFrom'].setValue(null);
          this.form.controls['endAt'].setValue(null);
        }
      });
  }

  get filteredFleets() {
    return this.fleets.filter((user) =>
      user.dropDownDisplayName.toLowerCase().includes(this.form.controls.fleetSearchTerm.value?.toLowerCase())
    );
  }

  get filteredCustomers() {
    return this.customers.filter((user) =>
      user.dropDownDisplayName.toLowerCase().includes(this.form.controls.customerSearchTerm.value?.toLowerCase())
    );
  }

  subscribeToFilterAction() {
    this.filterActionSubscription = this.action.subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  resetForm() {
    this.form.controls['customerId'].setValue(SharedConstants.ALL);
    this.form.controls['fleetId'].setValue(SharedConstants.ALL);
    this.form.controls['time'].setValue(DateRangeType.TODAY);
    this.form.controls['startFrom'].setValue(null);
    this.form.controls['endAt'].setValue(null);
    this.form.controls['customerSearchTerm'].setValue('');
    this.form.controls['fleetSearchTerm'].setValue('');
    this.submitForm();
  }

  cancelChanges() {
      this.resetForm();
  }

  submitForm() {
    this.dashboardStatus.orderStatusTime = this.form.controls.time.value;
    this.dashboardStatus.orderStatusCustomerId = this.form.controls.customerId.value;
    this.dashboardStatus.orderStatusFleetId = this.form.controls.fleetId.value;

    if (this.form.controls.time.value === DateRangeType.CUSTOM && this.form.controls.startFrom.value && this.form.controls.endAt.value) {
      this.dashboardStatus.orderStatusTimeStartFrom = FunctionUtil.convertDate(this.form.controls['startFrom'].value);
      this.dashboardStatus.orderStatusTimeEndAt = FunctionUtil.convertDate(this.form.controls['endAt'].value);
    } else {
      this.dashboardStatus.orderStatusTimeStartFrom = null;
      this.dashboardStatus.orderStatusTimeEndAt = null;
    }

    this.filterService.selectedFiltrationValue$.next({
      group: TabGroup.Dashboard,
      index: TabIndex.OrderStatus,
      filteredValue: this.dashboardStatus,
    });
    LocalStorageUtil.setItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS, this.dashboardStatus);
  }

  onCustomerSelectionChange(value) {
    this.dashboardStatus.orderStatusCustomerId = value.value;
    LocalStorageUtil.setItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS, this.dashboardStatus);
  }

  onFleetSelectionChange(value) {
    this.dashboardStatus.orderStatusFleetId = value.value;
    LocalStorageUtil.setItem(StorageConstant.LOCAL_STORAGE_DASHBOARD_STATUS, this.dashboardStatus);
  }

  private handleBlocStates(state: BlocState) {
    if (!state) {
      return;
    }

    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof OrderStatusFilterReadyState) {
      this.customers = state.customers;
      this.fleets = state.fleets;
      this.initForm();
      this.sectionState = SectionStateStatus.Ready;
    }
  }
}
