import { Component, Input, OnInit } from '@angular/core';
import { User } from '@features/users/models/user';

@Component({
  selector: 'app-show-password',
  templateUrl: './show-password.component.html',
  styleUrls: ['./show-password.component.scss']
})
export class ShowPasswordComponent implements OnInit {
  @Input() user: User;
  showPassword = false;
  constructor() { }

  ngOnInit() {
  }

}
