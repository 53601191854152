<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form" [formGroup]="form" *ngIf="form">
    <div class="field flex column mb-5">
      <app-dropdown [list]="fromCities" [title]=" 'Rules.From' | translate" formControlName="from"></app-dropdown>
    </div>

    <div class="field flex column mb-5">
      <app-dropdown [list]="toCities" [title]=" 'Rules.To' | translate" formControlName="to"></app-dropdown>
    </div>

    <div class="field flex column mb-5">
      <app-dropdown [list]="FleetsB2c" [title]=" 'Rules.FleetB2c' | translate" formControlName="FleetsB2c"></app-dropdown>
    </div>
  </form>
</app-state-section>
