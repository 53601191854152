<div class="madar--v2">
  <mat-card class="stats-card" [class]="'stats-card--' + state?.theme">
    <mat-card-content>
      <app-row align="center" gutter="sm">
        <app-col cols="auto">
          <div class="stats-card__icon">
            <app-icon class="align-middle" [name]="state?.iconUrl"></app-icon>
          </div>
        </app-col>
        <app-col>
          <app-row align="center" gutter="sm">
            <app-col>
              <div class="stats-card__content">
                <div class="stats-card__label">{{state?.label}}</div>
                <div class="stats-card__value">
                  {{hidePrice ? 1 : state?.value}}
                  <span class="stats-card__suffix" *ngIf="state?.suffix">/{{hidePrice ? 1 : state?.suffix}}</span>
                </div>
              </div>
            </app-col>
            <app-col cols="auto" *ngIf="state?.additionalValue">
              <div class="stats-card__box">{{hidePrice ? 1 : state?.additionalValue}}</div>
            </app-col>
          </app-row>
        </app-col>
      </app-row>
    </mat-card-content>
  </mat-card>
</div>
