import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSectionComponent implements OnInit, AfterViewChecked {
  @ViewChild('iconColumn', {static: true}) iconColumn: ElementRef;
  showIcon: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewChecked(): void {
    this.showIcon = !!this.iconColumn.nativeElement.children?.length;
    this.changeDetectorRef.detectChanges();
  }
}

