import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Status } from "@features/shipments/enums/status.enum";
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { ApiConstant } from '@shared/constants/api.constant';
import { BusinessType } from "@features/users/enums/business-type.enum";
import { Fleet } from '@features/fleets/models/fleet';
import { FleetDetails } from '@features/fleets/models/fleet-details';
import { FleetsDetails } from '@features/fleets/models/fleets-details';

@Injectable({
  providedIn: 'root',
})
export class FleetsB2cApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getAllFleetsB2c(status: Status[] = []): Observable<FleetsDetails> {
    let params = new HttpParams();

    for (let i = 0; i < status.length; i++) {
      params = params.append('status[]', status[i]);
    }

    params = params.append('businessType', BusinessType.B2C);

    return this.baseAPI.get(ApiConstant.GET_ALL_FLEETS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(FleetsDetails, res.data);
      })
    );
  }

  addFleetB2c(FleetsB2c: Fleet, commercialRegistrationId: number = null): Observable<Fleet> {
    const body = filterNullEntity({
      name: FleetsB2c.name,
      mobileNumber: FleetsB2c.mobileNumber,
      email: FleetsB2c.email,
      insuranceCompanyId: FleetsB2c.insuranceCompany?.id,
      insuranceCoverId: FleetsB2c.insuranceCover?.id,
      insuranceExpiryDate: FleetsB2c.insuranceExpiryDate,
      userFirstName: FleetsB2c.contactFirstName,
      userLastName: FleetsB2c.contactLastName,
      commercialNumber: FleetsB2c.commercialNumber,
      vatNumber: FleetsB2c.vatNumber,
      controlCenterMobile: FleetsB2c.controlCenterMobile,
      dayCapacity: FleetsB2c.dayCapacity,
      commercialRegistrationId: commercialRegistrationId,
      businessType: BusinessType.B2C,
    });

    return this.baseAPI.post(ApiConstant.ADD_FLEET, { ...body, isMultiPickup: FleetsB2c.isMultiPickup }).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  getFleetB2cById(id: number): Observable<FleetDetails> {
    const params = filterNullEntity({ id });
    return this.baseAPI.get(ApiConstant.GET_FLEET_BY_ID, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(FleetDetails, res.data);
      })
    );
  }

  updateFleetB2cStatus(FleetsB2c: Fleet, status: Status): Observable<Fleet> {
    const body = {
      id: FleetsB2c.id,
      status: status,
    };
    return this.baseAPI.put(ApiConstant.UPDATE_FLEET, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  updateFleetB2c(FleetsB2c: Fleet, logoId: number = null, commercialRegistrationId: number = null): Observable<Fleet> {
    const body = filterNullEntity({
      id: FleetsB2c.id,
      type: FleetsB2c.type,
      name: FleetsB2c.name,
      contactFirstName: FleetsB2c.contactFirstName,
      contactLastName: FleetsB2c.contactLastName,
      email: FleetsB2c.email,
      vatNumber: FleetsB2c.vatNumber,
      commercialNumber: FleetsB2c.commercialNumber,
      primaryTheme: FleetsB2c.primaryTheme,
      logoId: logoId,
      commercialRegistrationId: commercialRegistrationId,
      controlCenterMobile: FleetsB2c.controlCenterMobile,
      dayCapacity: FleetsB2c.dayCapacity,
      businessType: FleetsB2c.businessType,
    });
    return this.baseAPI.put(ApiConstant.UPDATE_FLEET, { ...body, isMultiPickup: FleetsB2c.isMultiPickup }).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }

  verifyFleetB2c(id: number): Observable<Fleet> {
    const body = {
      id: id,
    };

    return this.baseAPI.put(ApiConstant.VERIFY_FLEET, body).pipe(
      map((res) => {
        return this.mapper.fromJson(Fleet, res.data);
      })
    );
  }
}
