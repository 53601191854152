import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { TruckType } from './truck-type';

export class TruckTypeRouteTime extends Model {
  @Expose()
  id: number;

  @Expose()
  timeInHours: number;

  @Expose()
  @Type(() => TruckType)
  truckType: TruckType;
}
