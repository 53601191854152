import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { TruckType } from '../../fleets/models/truck-type';
import { ProductType } from '../../shipments/models/product-type';
import { ContractActionItem } from "@features/contracts/enums/contract-action-item.enum";

export class TradeLaneTruckType extends Model {
  @Expose() id: number;
  @Expose() name?: string;
  @Type(() => TruckType)
  @Expose()
  truckType: TruckType = new TruckType();
  @Expose() price: number;
  @Expose() cost: number;
  @Type(() => ProductType)
  @Expose()
  productTypeId?: ProductType = new ProductType();
  /**
   * this for UI
   * it's used for collecting action types that should be sent to BE
   * in case of add/update/delete
   */
  @Expose() actionItem?: ContractActionItem;
}
