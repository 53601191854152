<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form class="form-group flex column filter-form" [formGroup]="form" *ngIf="form">
    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Time" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-control-full-width">
        <mat-select formControlName="time">
          <mat-option *ngFor="let range of dateRangeType | enumToArray" [value]="range.value">
            {{ 'Enum.DateRangeType.' + range.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- custom range -->
    <div class="field flex column" *ngIf="form.get('time').value === dateRangeType.CUSTOM ">
      <mat-label class="field-label">{{ "Filter.Form.SelectDateRange" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <mat-date-range-input [rangePicker]="picker">
          <input readonly [max]="maxDate" formControlName="startFrom" matInput matStartDate [placeholder]="'Placeholder.StartDate' | translate" />

          <input readonly formControlName="endAt" matInput matEndDate [placeholder]="'Placeholder.EndDate' | translate" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <app-row>
      <app-col cols="12">
        <mat-label>{{'Dashboard.CustomersType'|translate}}</mat-label>
      </app-col>
      <app-col cols="12">
        <mat-form-field appearance="outline" class="d-block w-100">
          <mat-select formControlName="customersType">
            <mat-option value="">{{'Enum.Status.All' | translate}}</mat-option>
            <mat-option [value]="CustomerType.Service">{{'Enum.CustomerTypes.Service' | translate}}</mat-option>
            <mat-option [value]="CustomerType.Saas">{{'Enum.CustomerTypes.Saas' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </app-col>
    </app-row>

    <app-row>
      <app-col cols="12">
        <mat-label>{{ 'Dashboard.CustomerLabel' | translate }}</mat-label>
      </app-col>
      <app-col cols="12">
        <app-dropdown [list]="companyLabels" [multipleMode]="true" formControlName="customerLabel"></app-dropdown>
      </app-col>
    </app-row>

    <app-row>
      <app-col cols="12">
        <mat-label>{{ 'Dashboard.FleetLabel' | translate }}</mat-label>
      </app-col>
      <app-col cols="12">
        <app-dropdown [list]="companyLabels" [multipleMode]="true" formControlName="fleetLabel"></app-dropdown>
      </app-col>
    </app-row>

    <div class="field flex column">
      <mat-label class="field-label">{{ "Filter.Form.Customer" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="flex row dropdown input-field">
        <ng-container>
          <mat-select class="select-type" multiple [placeholder]="'Placeholder.Search' | translate" formControlName="customers">
            <div class="search-field">
              <app-icon matPrefix name="search" class="search-icon"></app-icon>

              <input
                autocomplete="off"
                matInput
                type="search"
                placeholder="{{ 'Placeholder.Search' | translate }}"
                (keyup)="applyFilter($event.target.value)"
              />
            </div>

            <mat-checkbox
              app-click-stop-propagation
              class="mat-option"
              [disableRipple]="true"
              [indeterminate]="isIndeterminate()"
              [checked]="isChecked()"
              (change)="onToggleSelection($event)"
            >
              {{ "Options.SELECT_ALL" | translate }}
            </mat-checkbox>

            <ng-container *ngFor="let customer of searchResultCustomers">
              <mat-option [value]="customer.id" *ngIf="!form?.value.customersType || form?.value.customersType === customer.customerType">
                {{ customer.title }}
                <mat-divider></mat-divider>
              </mat-option>
            </ng-container>
          </mat-select>
        </ng-container>
      </mat-form-field>
    </div>

    <app-row>
      <app-col cols="12">
        <mat-label>{{'Dashboard.DeliveryNoteStatus'|translate}}</mat-label>
      </app-col>
      <app-col cols="12">
        <mat-form-field appearance="outline" class="d-block w-100">
          <mat-select formControlName="deliveryNoteStatus">
            <mat-option [value]="DeliveryNoteStatus.All">{{'Enum.OperationPerformanceDNStatus.All' | translate}}</mat-option>
            <mat-option [value]="DeliveryNoteStatus.WithDN">{{'Enum.OperationPerformanceDNStatus.WithDN' | translate}}</mat-option>
            <mat-option [value]="DeliveryNoteStatus.WithoutDN">{{'Enum.OperationPerformanceDNStatus.WithoutDN' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </app-col>
    </app-row>
  </form>
</app-state-section>
