import { Component, Input } from '@angular/core';
import { ColComponent } from '@shared/components/grid/col.component';

@Component({
  selector: 'app-labeled-text',
  templateUrl: './labeled-text.component.html',
  styleUrls: ['./labeled-text.component.scss']
})
export class LabeledTextComponent extends ColComponent {
  @Input() label: string;
  @Input() labelIcon: string;
  @Input() value: string;
  @Input() href: string;
  @Input() target = '_blank';
  @Input() hint: string;
  @Input() hintIcon: string;
}
