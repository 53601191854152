import { Expose, Type } from 'class-transformer';
import { ClassifiedTradelane } from '@features/fleets/models/classified-tradelane.model';
import { Model } from '@shared-features/models/model';

export class ClassifiedTadelanes extends Model {
  @Expose()
  @Type(() => ClassifiedTradelane)
  areas: ClassifiedTradelane[];
  @Expose()
  count: number;
}
