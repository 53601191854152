import { Model } from '@shared-features/models/model';
import { OperationStatusCount } from '@features/trip-performance/models/operation-status-count.model';
import { Expose, Type } from 'class-transformer';
import { PlanningTruck } from '@features/fleets/models/planning-truck.model';

export class TripPlanningTrucks extends Model {
  @Expose()
  counts: OperationStatusCount;

  @Expose()
  @Type(() => PlanningTruck)
  trucks: PlanningTruck[];
}
