import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedConstants } from '@shared/model/shared-constants';

import { UserRole } from "@features/users/enums/user-role.enum";
import { UserRoleService } from '@core/services/user-role.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private userRoleService: UserRoleService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const roles = next.data['roles'] ?? [] as UserRole[];
    let isUserHasPermission = false;

    isUserHasPermission = this.userRoleService.isUserHasRoles(roles);

    if (isUserHasPermission === false) {
      this.router.navigate([SharedConstants.ACCESS_DENIED]);
    }

    return isUserHasPermission;
  }
}
