import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  ContentChild,
} from '@angular/core';
import { Lookup } from '@shared-features/models/Lookup.model';
import { Model } from '@shared-features/models/model';
import { LookupService } from '@shared-features/services/lookups.service';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DropdownFilter } from '@shared/components/forms';
import { OptionLabelDirective } from '@shared/directives/option-label.directive';
import { LookupType } from '@shared/enums/lookup-type.enum';
import { ClassConstructor } from 'class-transformer/types/interfaces';
import { Observable, Subject, EMPTY, fromEvent, BehaviorSubject, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  catchError,
  takeUntil,
  filter,
  finalize,
} from 'rxjs/operators';

@Component({
  selector: 'app-custom-search',
  templateUrl: './custom-search.component.html',
  styleUrls: ['./custom-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSearchComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('scrollElement') scrollElementRef: ElementRef<HTMLElement>;
  @ViewChild('searchInput') searchInputRef: ElementRef<HTMLInputElement>;
  @ContentChild(OptionLabelDirective)
  optionLabelDirective: OptionLabelDirective;

  @Input() lookup: LookupType;
  @Input() lookupExtraParams;
  @Input() placeholder: string;
  @Input() Model: ClassConstructor<any> = Lookup;
  @Input() delay = 500;

  @Output() optionSelected = new EventEmitter<Lookup>();

  searchTerm = '';
  searchResults: Lookup[] = [];
  pagination$: BehaviorSubject<{ limit: number; offset: number }> = new BehaviorSubject({
    limit: 10,
    offset: 0,
  });
  endOfResult = false;
  isInputFocused = false;
  isLoading = false;

  searchTerms$ = new Subject<string>();

  constructor(private lookupService: LookupService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.handleChanges();
  }

  ngAfterViewInit(): void {
    this.handleScrollEvent();
    this.changeDetectorRef.markForCheck();
  }

  search(): void {
    this.searchTerms$.next(this.searchTerm);
  }

  onFocus(): void {
    this.isInputFocused = true;
    this.searchResults.length ? this.isLoading = false : this.isLoading = true;
    this.search();
  }

  onBlur(): void {
    setTimeout(() => {
      this.isInputFocused = false;
      this.isLoading = false;
      this.changeDetectorRef.markForCheck();
    }, 300);
  }

  selectOption(value: Lookup): void {
    this.optionSelected.emit(value);
  }

  private handleChanges(): void {
    combineLatest([
      this.searchTerms$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((term) => this.handleSearchStart(term))
      ),
      this.pagination$,
    ]).pipe(
      switchMap(([searchTerm, pagination]) => {
        this.isLoading = true;
        return this.searchInLookup(searchTerm, pagination).pipe(
          catchError(() => EMPTY),
          finalize(() => this.isLoading = false)
        );
      })
    ).subscribe((results) => {
      this.handleSearchResults(results);
    });
  }


  private handleSearchStart(term: string): void {
    this.endOfResult = false;
    this.searchResults = [];
    this.pagination$.next({ limit: 10, offset: 0 });
    this.searchTerm = term;
  }

  private handleSearchResults(results: Lookup[]): void {
    if (results.length === 0) {
      this.endOfResult = true;
    } else {
      const uniqueResults = results.filter(result => !this.searchResults.some(existingResult => existingResult.id === result.id));

      this.searchResults.push(...uniqueResults);
    }
    this.isLoading = false;
    this.changeDetectorRef.detectChanges();
  }


  private searchInLookup(
    term: string,
    pagination: { limit: number; offset: number }
  ): Observable<Lookup[]> {
    let filters: DropdownFilter = {
      offset: pagination.offset,
      limit: pagination.limit,
      searchTerm: null,
      lang: 'en',
    };

    if (term.trim() !== '') {
      filters = { ...filters, searchTerm: term };
    }

    return this.getFromPreConfiguredLookups(filters).pipe(catchError(() => EMPTY), finalize(() => this.isLoading = false)
    );
  }

  private getFromPreConfiguredLookups(dropdownFilter: DropdownFilter): Observable<Lookup[]> {
    const { offset, limit, searchTerm, lang } = dropdownFilter;
    return this.lookupService.getLookup(
      this.lookup,
      { lang, offset, limit, searchTerm, ...(this.lookupExtraParams || {}) },
      false,
      this.Model
    );
  }

  private handleScrollEvent(): void {
    const $scrollElement = this.scrollElementRef?.nativeElement;

    if (!$scrollElement) {
      return;
    }

    fromEvent($scrollElement, 'scroll')
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(this.delay),
        filter(() => !this.isLoading && !this.endOfResult)
      )
      .subscribe(() => {
        this.loadMore();
        this.changeDetectorRef.markForCheck();
      });
  }

  private loadMore(): void {
    const currentOffset = this.pagination$.value.offset;
    const currentLimit = this.pagination$.value.limit;
    this.pagination$.next({ offset: currentOffset + currentLimit, limit: currentLimit });
  }
}
