<app-dialog-layout class="cards-group madar--v2">
  <div dialog-title class="d-block w-100">
    <span class="d-inline-flex mr-2 radius-2 nmt-2" [style.border]="'8px solid var(--color-primary-lighter)'">
      <app-avatar
        bgColor="var(--color-primary-light)"
        fgColor="var(--color-primary)"
        iconType="svg"
        icon="bold-password-check"
        [round]="false"
        [radius]="1"
        [size]="32"
      ></app-avatar>
    </span>
  </div>

  <app-state-section [state]="sectionState">
    <mat-card>
      <mat-card-content class="px-0">
        <div class="flex column">
          <span class="text-black weight-semibold mb-2">{{'shared.GenerateComplexPasswordDialog.passwordGenerated' | translate}}</span>
          <span class="text-400 h6 m-0">{{'shared.GenerateComplexPasswordDialog.passwordGeneratedText' | translate}}</span>
        </div>

        <div
          class="flex justify-center align-center radius-2 py-2 mt-3 cursor-pointer"
          [style.border]="'1px dashed var(--color-gray-300)'"
          (click)="clickToCopy()"
        >
          <span class="weight-semibold default-primary-200">{{generatedPassword}}</span>
          <app-icon class="align-middle mx-2" size="sm" name="outline-copy"></app-icon>
        </div>
      </mat-card-content>
    </mat-card>
  </app-state-section>

  <div class="w-100" dialog-action>
    <app-row>
      <app-col cols="6">
        <button mat-stroked-button color="primary" class="w-100 d-block" mat-dialog-close>{{'shared.Buttons.Cancel'|translate}}</button>
      </app-col>
      <app-col cols="6">
        <button mat-flat-button color="primary" class="w-100 d-block" (click)="confirmPassword()">{{'shared.Buttons.Confirm'|translate}}</button>
      </app-col>
    </app-row>
  </div>
</app-dialog-layout>
