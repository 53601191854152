export enum UserRole {
  Admin = 'ADMIN',
  OperationManager = 'OPERATION_MANAGER',
  Operation = 'OPERATION',
  SalesManager = 'SALES_MANAGER',
  Sales = 'SALES',
  Supervisor = 'SUPERVISOR',
  FleetB2c = 'Fleet_B2C',
  Admin_B2C = 'Admin_B2C',
  SuperAdmin = 'SUPER_ADMIN',
  RentalManager = 'RENTAL_MANAGER',
  Support = 'SUPPORT',
}
