import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { Expose, Type, Transform } from 'class-transformer';
import { Country } from '../../shared-features/models/country';
import { Role } from '../../auth/models/role';
import { environment } from 'src/environments/environment';
import { AES, enc } from 'crypto-js';
import { Media } from '../../shared-features/models/media';
import { UserRole } from "@features/users/enums/user-role.enum";
import { ShippingType } from "@features/shipments/enums/shipping-type.enum";
import { UserParam } from '@features/users/models/user-param.model';

export class User extends DropdownModel {
  @Expose() id: number;
  @Expose() firstName: string;
  @Expose() lastName: string;
  @Expose() email: string;
  @Expose() mobileNumber: string;
  @Expose() code: string;
  @Expose() country: Country;
  @Expose() roles: Role[];
  @Expose() status: string;
  @Expose() isVerified: boolean;
  @Expose() isEmailVerified: boolean;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;
  @Expose() accessToken: string;
  @Expose() refreshToken: string;
  @Expose() dateOfBirth: Date;
  @Expose() password: string;
  @Expose() lastLogin?: number;
  @Expose() lastRefreshTime:Date;
  @Transform((transformParams) =>
    transformParams.value ? transformParams.value : [UserRole.Supervisor]
  )
  @Expose()
  userRoles?: UserRole[] = [UserRole.Supervisor, UserRole.Admin];
  @Type(() => Media)
  @Expose()
  profilePicture: Media = new Media();
  @Expose() hidePrice: boolean;
  @Expose() isMobileNumberVerified: boolean;

  @Expose()
  shippingType: ShippingType;

  @Expose()
  allowToLogin: boolean;

  @Expose()
  @Type(() => UserParam)
  params: UserParam[];
  @Expose() isTempPasswordChanged?: boolean;
  @Expose() tempPassword?: string;

  constructor() {
    super();
  }

  @Expose()
  public get name(): string {
    return (this.firstName || '') + ' ' + (this.lastName || '');
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): object {
    return this;
  }

  get decryptedPassword(): string {
    let originalText;
    if (this.password) {
      const bytes = AES.decrypt(this.password, environment.encryptPasswordKey);
      originalText = bytes.toString(enc.Utf8);
    }
    return originalText;
  }
}
