import { Injectable } from '@angular/core';
import { Bloc } from '@core/bloc/bloc';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { BlocEvent, InitEvent } from '@core/bloc/bloc-event';
import { Drawing } from '@shared-features/models/drawing';
import { OrderStatusFilterState, OrderStatusFilterReadyState } from './order-status-filter-state';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { FleetsCommonApiService } from '@shared-features/services/fleets-common-api.service';

@Injectable()
export class OrderStatusFilterBloc extends Bloc<BlocEvent, OrderStatusFilterState> {
  constructor(private sharedApiService: SharedApiService, private fleetsCommonApiService: FleetsCommonApiService) {
    super();
  }

  onIncomingEvents(event: BlocEvent): void {
    if (event instanceof InitEvent) {
      this.onState$.next(new ProcessingState<Drawing>(new Drawing()));
      forkJoin([this.sharedApiService.getAllCustomers(), this.fleetsCommonApiService.getAllFleets({})])
        .pipe(take(1))
        .subscribe(
          ([customers, fleets]) => {
            this.onState$.next(new OrderStatusFilterReadyState(customers, fleets.fleets));
          },
          (error) => {
            this.onState$.next(new ErrorState<Drawing>(new Drawing(), error));
          }
        );
    }
  }
}
