import { Injectable } from '@angular/core';
import { Bloc } from '@core/bloc';
import { Drawing } from '@shared-features/models/drawing';
import { NonDrawing } from '@shared-features/models/non-drawing';
import { EmptyState, ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import {
  DeliveryNotesManagementEvent,
  ExportDeliveryNotesEvent,
  GetAllCustomersEvent,
  GetAllUsersEvent,
} from './delivery-notes-management-event';
import {
  DeliveryNotesManagementState,
  ExportDeliveryNotesReadyState,
  GetAllCreatorsReadyState,
  GetAllCustomersReadyState,
} from './delivery-notes-management-state';
import { take } from 'rxjs/operators';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';

@Injectable()
export class DeliveryNotesManagementBloc extends Bloc<DeliveryNotesManagementEvent, DeliveryNotesManagementState> {
  constructor(
    private sharedApiService: SharedApiService,
    private usersCommonApiService: UsersCommonApiService,
  ) {
    super();
  }

  onIncomingEvents(event: DeliveryNotesManagementEvent): void {
    if (event instanceof GetAllCustomersEvent) {
      this.onState$.next(new ProcessingState<Drawing>(new Drawing()));
      this.sharedApiService
        .getAllCustomers()
        .pipe(take(1))
        .subscribe(
          (customers) => {
            if (Array.isArray(customers) && !customers.length) {
              this.onState$.next(new EmptyState());
              return;
            }
            this.onState$.next(new GetAllCustomersReadyState(customers));
          },
          (error) => {
            this.onState$.next(new ErrorState(error));
          }
        );
    } else if (event instanceof GetAllUsersEvent) {
      this.onState$.next(new ProcessingState<Drawing>(new Drawing()));
      this.usersCommonApiService.getAllUsers(event.userType, event.id).pipe(take(1)).subscribe(
        (users) => {
          if (Array.isArray(users) && !users.length) {
            this.onState$.next(new EmptyState());
            return;
          }
          this.onState$.next(new GetAllCreatorsReadyState(users));
        },
        (error) => {
          this.onState$.next(new ErrorState<NonDrawing>(new NonDrawing(), error));
        }
      );
    } else if (event instanceof ExportDeliveryNotesEvent) {
      this.onState$.next(new ProcessingState<NonDrawing>(new NonDrawing()));
      this.sharedApiService
        .exportDeliveyNotes(event)
        .pipe(take(1))
        .subscribe(
          (exportDeliveryNotes) => {
            this.onState$.next(new ExportDeliveryNotesReadyState(exportDeliveryNotes));
          },
          (error) => {
            this.onState$.next(new ErrorState<NonDrawing>(new NonDrawing(), error));
          }
        );
    }
  }
}
