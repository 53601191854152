<form class="form-group flex column filter-form" [formGroup]="form">
    <div class="field flex column">
      <mat-label class="field-label">{{
        "Filter.Form.Status" | translate
      }}</mat-label>
      <mat-form-field class="form-control" appearance="outline">
        <mat-select
          (selectionChange)="onSelectClicked('status', $event)"
          formControlName="status"
        >
          <mat-option *ngFor="let item of status | enumToArray" [value]="item.value">{{ "Enum.Status." + item.value | translate }}</mat-option>

        </mat-select>
      </mat-form-field>
    </div>
  
    <div class="field flex column" *ngIf="false">
      <mat-label class="field-label">{{
        "Filter.Form.RegistrationDate" | translate
      }}</mat-label>
      <mat-form-field class="form-control" appearance="outline">
        <mat-select
          (selectionChange)="onSelectClicked('registrationDate', $event)"
          formControlName="registrationDate"
        >
          <mat-option
            *ngFor="let item of filterByDate | enumToArray"
            [value]="item.value"
          >
            {{ "Enum.FilterByDate." + item.key | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
    <div class="field flex column">
      <mat-label class="field-label">{{
        "Filter.Form.activationDate" | translate
      }}</mat-label>
      <mat-form-field class="form-control" appearance="outline">
        <mat-select
          (selectionChange)="onSelectClicked('activationDate', $event)"
          formControlName="activationDate"
        >
          <mat-option
            *ngFor="let item of filterByDate | enumToArray"
            [value]="item.value"
            [ngClass]="{'hide-option':item.key === filterByDate.CUSTOM}"
            >
          <ng-container
          *ngIf="item.key !== filterByDate.CUSTOM">
          {{ "Enum.DateRangeType." + item.key | translate }}
        </ng-container>
        </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>