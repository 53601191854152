import { Expose } from 'class-transformer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';

export class WCCRegion extends DropdownModel {
  @Expose()
  id: number;

  @Expose()
  name: string;

  constructor() {
    super();
  }

 get dropDownDisplayName(): string {
    return this.name;
  }

  get dropDownValue(): Object {
    return this;
  }
}
