import { Model } from '@shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { TraccarUtilityStatus } from '@features/shipments/enums/traccar-utility-status.enum';
import { TraccarUtilityActions } from '@features/shipments/enums/traccar-utility-actions.enum';
import { DoorHistoryAdditionalData } from './door-history-additional-data.model';

export class DoorHistory extends Model {
  @Expose() id: number;
  @Expose() action: TraccarUtilityActions;
  @Expose() status: TraccarUtilityStatus;
  @Type(() => DoorHistoryAdditionalData)
  @Expose()
  additionalData: DoorHistoryAdditionalData;
  @Expose() isLatestAction: boolean;
  @Expose() actionTime: string;
  @Expose() createdAt: string;
}
