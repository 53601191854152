import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconType } from "@shared/enums/icon-type.enum";
import { LabeledInfoItem } from '@shared/model/labeled-info-item.model';

@Component({
  selector: 'app-labeled-info-list',
  templateUrl: './labeled-info-list.component.html',
  styleUrls: ['./labeled-info-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledInfoListComponent implements OnChanges {
  @Input() type: 'lg' | 'md' | 'xs' = 'md';
  @Input() direction: 'column-reverse' | 'column' = 'column';
  @Input() items: LabeledInfoItem[];

  IconType = IconType;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnChanges({ items }: SimpleChanges): void {
    if (items && items.currentValue !== items.previousValue) {
        this.changeRef.markForCheck();
    }
  }
}
