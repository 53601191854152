import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConstant } from '@shared/constants';
import { map } from 'rxjs/operators';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import { UserPreferencesType } from '@shared-features/enums/user-preferences-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getUserPreferences(type: UserPreferencesType): Observable<any> {
    return this.baseAPI.get(ApiConstant.GET_USER_PREFERENCES + `/${type}`).pipe(
      map((res) => {
        return res.data;
      })
    );
  }

  updateUserPreferences(jsonObj: any, type: UserPreferencesType): Observable<string> {
    return this.baseAPI.put(ApiConstant.UPDATE_USER_PREFERENCES, { jsonObj, type }).pipe(
      map((res) => {
        return this.mapper.fromJson(String, res.code);
      })
    );
  }

  mapJsonToModel<T>(Model: any, jsonObj: any): T {
    return this.mapper.fromJson(Model, jsonObj);
  }
}
