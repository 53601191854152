<form class="form-group flex column filter-form" [formGroup]="form" *ngIf="form">
  <div class="field flex column">
    <mat-label class="field-label">{{ "Filter.Form.Status" | translate }}</mat-label>
    <mat-form-field appearance="outline" class="form-control-full-width">
      <mat-select formControlName="status">
        <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
        <mat-option *ngFor="let item of requestHistoryStatus | enumToArray" [value]="item.value">
          {{ 'Enum.RequestHistoryStatus.' + item.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="field flex column">
    <mat-label class="field-label">{{ "Filter.Form.Action" | translate }}</mat-label>
    <mat-form-field appearance="outline" class="form-control-full-width">
      <mat-select formControlName="action">
        <mat-option [value]="sharedConstants.ALL">
            {{ 'Options.ALL' | translate }}
          </mat-option>
        <mat-option *ngFor="let item of requestHistoryAction | enumToArray" [value]="item.value">
          {{ 'Enum.RequestHistoryAction.' + item.value | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
