import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ColorPalette } from '@shared/enums/color-palette.enum';

@Component({
  selector: 'app-rect-tag',
  templateUrl: './rect-tag.component.html',
  styleUrls: ['./rect-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RectTagComponent {
  /** @todo: add support for sm and lg */
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() color: ColorPalette | 'custom' = ColorPalette.Default;
  @Input() theme: 'solid' | 'dashed' = 'solid';
  @Input() displayLabel = true;
  @Input() excretionWidth: string = '100%';
  @Input() inverted: boolean;
}
