import { Type, Expose, Transform } from 'class-transformer';
import { WccIntegrationStatusType } from '@shared/enums/wcc-integration-status-type.enum';
import { ShipmentCreationTemplateTypes } from '@features/shipment-creation-template/enums/shipment-creation-template-types.enum';
import { CustomerType } from '@features/customers/enums/customer-type.enum';
import { CustomerPriceType } from '@features/customers/enums/customer-price-type';
import { ShipmentStatus } from '@features/shipments/enums/shipment-status.enum';
import { ContactPerson } from '../../customers/models/contact-person';
import { Customer } from '../../customers/models/customer';
import { Driver } from '../../fleets/models/driver';
import { Fleet } from '../../fleets/models/fleet';
import { LocationModel } from '../../live-tracking/models/location.model';
import { Model } from '../../shared-features/models/model';
import { ProductType } from './product-type';
import { Receiver } from '../../receivers/models/receiver';
import { Transaction } from '../../fleets/models/transaction';
import { TruckType } from '../../fleets/models/truck-type';
import { User } from '../../users/models/user';
import { ShipmentDeliveryNote } from './shipment-delivery-note';
import { ShipmentTransportationPayment } from './shipment-transportation-payment';
import { MeasurementUnit } from './measurement-unit.model';
import { ShipmentRequest } from './shipment-request.model';
import { Trailer } from '../../fleets/models/trailer.model';
import { Truck } from '../../fleets/models/truck';
import { ShipmentLabel } from './shipment-label.model';
import { ShipmentInvoice } from './shipment-invoice.model';
import { RequestLog } from '../../shared-features/models/request-log.model';
import { FleetInvoiceStatus } from '@features/invoices/enums/fleet-invoice-status.enum';
import { InvoiceStatus } from '@features/invoices/enums/invoice-status.enum';
import { ShipmentHistoryItem } from '@features/shipments/models/shipment-history-item.model';
import { GateDefinition } from '@shared/model/gate-definition.model';
import { Media } from '../../shared-features/models/media';
import { ShipmentMetadata } from '@shared-features/models/shipment-metadata.model';

export class Shipment extends Model {
  @Expose()
  id: number = null;

  @Expose()
  code = '';

  @Expose()
  name = '';

  @Expose()
  status: ShipmentStatus;

  @Expose()
  weight: number;

  @Expose()
  fleetSpotRate: number;

  @Expose()
  customerSpotRate: number;

  @Expose()
  isFreeLanced: boolean;

  @Expose()
  notes: string;

  @Type(() => Customer)
  @Expose()
  customer: Customer = new Customer();

  @Type(() => Customer)
  @Expose()
  childCustomer: Customer = new Customer();

  @Type(() => ProductType)
  @Expose()
  productType: ProductType = new ProductType();

  @Type(() => TruckType)
  @Expose()
  truckType: TruckType = new TruckType();

  @Type(() => TruckType)
  @Expose()
  optionalTruckTypes: TruckType[];

  @Type(() => Receiver)
  @Expose()
  receiver: Receiver = new Receiver();

  @Type(() => Driver)
  @Expose()
  driver: Driver = new Driver();

  @Type(() => Truck)
  @Expose()
  truck: Truck = new Truck();

  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();

  @Type(() => LocationModel)
  @Expose()
  pickUpLocation: LocationModel = new LocationModel();

  @Type(() => LocationModel)
  @Expose()
  dropOffLocation: LocationModel = new LocationModel();

  @Expose()
  @Type(() => ShipmentHistoryItem)
  history: ShipmentHistoryItem[] = [];

  @Type(() => Transaction)
  @Expose()
  transactions: Transaction[] = [];

  @Expose()
  repeatedNumber: number = null;

  @Type(() => LocationModel)
  @Expose()
  receiverLocation: LocationModel = new LocationModel();

  @Type(() => ContactPerson)
  @Expose()
  contactPerson: ContactPerson = new ContactPerson();

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null), { toClassOnly: true })
  pickedDateTime: Date;

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null), { toClassOnly: true })
  pickUpDate: Date;

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null), { toClassOnly: true })
  createdAt: Date;

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null), { toClassOnly: true })
  updatedAt: number;

  @Expose()
  totalFleetCost: number;

  @Expose()
  creatorType: string;

  @Type(() => User)
  @Expose()
  createdBy: User = new User();

  @Expose()
  priceComment: string;

  @Expose()
  customerPriceType: CustomerPriceType;

  @Expose()
  detentionCharge: number;

  @Expose()
  returnCharge: number;

  @Expose()
  multiDestinationCharge: number;

  @Expose()
  deliveryNoteNumber: string;

  @Expose()
  deliveryNoteComment: string;

  @Expose()
  shortDynamicLink: string;

  @Type(() => ShipmentDeliveryNote)
  @Expose()
  deliveryNote: ShipmentDeliveryNote[] = [];

  @Expose()
  archivedTrackingUrl: string;

  @Expose()
  archivedAvlTrackingUrl: string;

  @Expose()
  otp: string;

  @Type(() => ShipmentTransportationPayment)
  @Expose()
  transportationPayment: ShipmentTransportationPayment = new ShipmentTransportationPayment();

  @Expose()
  etaTime: number;

  @Expose()
  isPaidForDriver: boolean;

  @Expose()
  weightMeasurementUnit: MeasurementUnit;

  @Expose()
  quantity: number;

  @Expose()
  delayedAction: boolean;

  @Expose()
  shipmentDelayed: boolean;

  @Expose()
  driverAllowance: number;

  selected?: boolean = false;

  @Type(() => ShipmentRequest)
  @Expose()
  requests: ShipmentRequest[];

  @Type(() => Trailer)
  @Expose()
  trailer: Trailer;

  @Expose() droppingOffTime: number;
  @Expose() loadingOffTime: number;

  @Expose()
  isMobileTrackingAvailable: boolean;

  @Expose()
  isAvlTrackingAvailable: boolean;

  @Expose()
  customerType: CustomerType;

  @Type(() => ShipmentLabel)
  @Expose()
  label: ShipmentLabel[] = [];

  @Expose() noOfRequests: number;

  @Type(() => ShipmentInvoice)
  @Expose()
  invoice: ShipmentInvoice = new ShipmentInvoice();

  @Type(() => ShipmentInvoice)
  @Expose()
  fleetInvoice: ShipmentInvoice = new ShipmentInvoice();

  @Expose()
  totalPrice: number;

  @Expose()
  isVerifiedByBayan: boolean;

  @Type(() => RequestLog)
  @Expose()
  requestLogHistory: RequestLog[] = [];

  @Expose()
  shipmentCreationTemplate: ShipmentCreationTemplateTypes;

  @Type(() => Customer)
  @Expose()
  dropOffCustomer: Customer = new Customer();

  @Type(() => User)
  @Expose()
  dropOffUser: User = new User();

  @Expose()
  loadedAmount: number;

  @Expose()
  @Transform(({ value }) => (value ? new Date(value) : null), { toClassOnly: true })
  lastJobUpdate: Date;

  @Expose()
  wccId: number;

  @Expose()
  productPrice: number;

  @Expose()
  totalProductPrice: number;

  @Expose()
  wccIntegrationStatus: WccIntegrationStatusType;

  @Expose()
  customerPrice: number;

  @Expose()
  fleetPrice: number;

  @Expose()
  fleetInvoiceStatus?: FleetInvoiceStatus;

  @Expose()
  customerInvoiceStatus?: InvoiceStatus;

  @Expose()
  gate?: GateDefinition;

  @Expose()
  numOfPallets?: number;

  @Expose()
  noOfPallets?: number;

  @Expose()
  noOfCustomers?: number;

  @Expose()
  noOfOrders: number;

  @Expose()
  etaInSeconds: Date;

  @Expose()
  waitingChargeProfAttachment: Media;

  @Expose()
  waitingChargeProfId: number;

  @Expose()
  @Type(() => ShipmentMetadata)
  shipmentMetadata?: ShipmentMetadata;

  @Expose()
  violated: boolean;

  @Expose()
  isPlanned: boolean;

  @Expose()
  deliveryNoteReference: string;

  @Expose() startShipment: boolean;
  @Expose() forceClose: boolean;
  @Expose() assignAndAutoStart: boolean;
  @Expose() comment: string;

  @Expose() customerName: string;

  constructor() {
    super();
  }

  public get shipmentName() {
    if (this.name) {
      return '#' + this.name;
    } else {
      return '#' + this.code;
    }
  }
}
