import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NamedField } from '@shared/interfaces/field.interface';
import { FormArray, FormGroup } from '@angular/forms';
import { FieldBase } from './field-base';
import { ClassConstructor } from 'class-transformer';
import { Lookup } from '@shared-features/models/Lookup.model';
import { LookupType } from '@shared/enums/lookup-type.enum';
import { Observable } from 'rxjs';
import { Model } from '../controls/lazy-dropdown/lazy-dropdown.component';

@Component({
  selector: 'app-dropdown-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-label class="field__label" *ngIf="field.label"
        >{{ field.label | translate }}
        <span *ngIf="isRequired" class="text-warn">*</span></mat-label
      >
      <app-lazy-dropdown
        [id]="field.name + '_' + uuid"
        [formControl]="group.get(field.name)"
        [placeholder]="field.placeholder | translate"
        [theme]="theme"
        [size]="size"
        [displayPath]="displayPath"
        [valuePath]="valuePath"
        [listPath]="listPath"
        [multiple]="multiple"
        [delay]="delay"
        [margin]="margin"
        [limit]="limit"
        [valuePersist]="valuePersist"
        [selectFirstByDefault]="selectFirstByDefault"
        [label]="label"
        [allowSelectAll]="allowSelectAll"
        [appearance]="appearance"
        [readonly]="readonly"
        [filter]="filter"
        [clearable]="clearable"
        [Model]="Model"
        [lookup]="lookup"
        [lookupExtraParams]="lookupExtraParams"
        [highlightSelection]="highlightSelection"
        (close)="onClose()"
        class="field__input"
        appearance="outline"
        #element
        (click)="fieldClick.emit($event)"
      >
        <div *ngIf="field.prefix" prefix (click)="prefixClick.emit($event)">
          <ng-container #prefixRef></ng-container>
        </div>
        <div *ngIf="field.suffix" suffix (click)="suffixClick.emit($event)">
          <ng-container #suffixRef></ng-container>
        </div>
        <mat-error *ngIf="group.get(field.name)?.touched && errors.length" class="field__error">
          <app-field-errors [errors]="errors"></app-field-errors>
        </mat-error>
      </app-lazy-dropdown>
    </ng-container>
  `,
  host: { class: 'field' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFieldComponent extends FieldBase {
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;
  @ViewChild('element') element: ElementRef;

  @Input() displayPath = 'dropDownDisplayName';
  // [{ id: 1 }, {...}]
  // if valuePath is null || '' it would be the whole object.
  @Input() valuePath = 'dropDownValue';
  // response => {data: { list: [] }} => listPath = 'data.list'
  // if listPath null means the list input or response is Array
  @Input() listPath = null;
  @Input() multiple: boolean;
  @Input() delay = 500;
  @Input() margin = 30;
  @Input() limit = 20;
  @Input() valuePersist: boolean;
  @Input() selectFirstByDefault: boolean;
  @Input() placeholder: string;
  @Input() label = '';
  @Input() allowSelectAll = true;
  @Input() appearance: 'outline' | 'fill' = 'outline';
  @Input() readonly: boolean;
  @Input() filter: (options: Model[]) => Model[];
  @Input() clearable: boolean;
  @Input() Model: ClassConstructor<any> = Lookup;

  @Input() lookup:
    | LookupType
    | ((filters: { offset: number; limit: number; searchTerm: string }) => Observable<Model[]>)
    | Model[];

  @Input() lookupExtraParams: any;
  @Input() highlightSelection: boolean;

  firstLoad = true;

  onClose() {
    if (this.firstLoad) {
      this.firstLoad = false;
      this.parseErrors();
    }
  }
}
