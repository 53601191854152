import { Expose, Type } from 'class-transformer';
import { Truck } from '@features/fleets/models/truck';
import { Model } from '@shared-features/models/model';

export class RentedTruckContract extends Model {
  @Expose() id: number;
  @Expose() dailyCost: number;
  @Expose() monthlyCost: number;
  @Type(() => Truck)
  @Expose()
  truck: Truck = new Truck();
  @Expose() createdBy: string;
  @Expose() createdAt: number;
  @Expose() startDate: number;
  @Expose() endDate: number;

  constructor() {
    super();
  }
}
