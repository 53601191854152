import { environment } from '@env/environment';
import { URLSegment } from '@shared/enums/url-segment.enum';

export class ApiConstant {
  static URL_BASE = environment.base_url + environment.api_version + environment.api_prefix;
  static REFRESH_TOKEN = ApiConstant.URL_BASE + URLSegment.Auth + 'refresh-access-token';
  static LOGIN = ApiConstant.URL_BASE + URLSegment.Auth + 'login';
  static CHANGE_PASSWORD = ApiConstant.URL_BASE + URLSegment.Auth + 'change-password';
  static VERIFY_EMAIL = ApiConstant.URL_BASE + URLSegment.Auth + 'verify-email';
  static REGISTER_DEVICE = ApiConstant.URL_BASE + URLSegment.Auth + 'register-device';
  static RESET_PASSWORD = ApiConstant.URL_BASE + URLSegment.Auth + 'reset-password';
  static SET_NEW_PASSWORD = ApiConstant.URL_BASE + URLSegment.Auth + 'set-new-password';
  static SET_TEMP_PASSWORD = ApiConstant.URL_BASE + URLSegment.Auth + 'set-temp-password';
  static GET_ALL_USERS = ApiConstant.URL_BASE + URLSegment.User + 'users';
  static GET_ALL_COUNTRIES = ApiConstant.URL_BASE + URLSegment.Shared + 'countries';
  static GET_ALL_INSURANCE_COMPANIES =
    ApiConstant.URL_BASE + URLSegment.Shared + 'insurance-companies';
  static GET_ALL_INSURANCE_COVERS = ApiConstant.URL_BASE + URLSegment.Shared + 'insurance-covers';
  static GET_ACTIVE_ROLES = ApiConstant.URL_BASE + URLSegment.Role + 'active-roles';
  static GET_USER_BY_ID = ApiConstant.URL_BASE + URLSegment.User;
  static ADD_USER = ApiConstant.URL_BASE + URLSegment.User + 'add';
  static ADD_CUSTOMER = ApiConstant.URL_BASE + URLSegment.Customer + 'add';
  static UPLOAD_MEDIA = ApiConstant.URL_BASE + 'media';
  static UPDATE_USER = ApiConstant.URL_BASE + URLSegment.User + 'update';
  static ADD_EDIT_PARAMS = ApiConstant.URL_BASE + URLSegment.User + 'config/add-edit-params';
  static GET_USER_PARAMS = ApiConstant.URL_BASE + URLSegment.User + 'config/params';
  static HIDE_PRICE = ApiConstant.URL_BASE + URLSegment.User + 'update-hide-price';
  static ACTIVATE_AND_RESET_PASSWORD =
    ApiConstant.URL_BASE + URLSegment.Auth + 'activate-reset-password';
  static FORGET_PASSWORD = ApiConstant.URL_BASE + URLSegment.Auth + 'forget-password';
  static FORGET_PASSWORD_VERIFY_MOBILE =
    ApiConstant.URL_BASE + URLSegment.Auth + 'forget-password-verify-mobile';
  static REQUEST_OTP = ApiConstant.URL_BASE + URLSegment.Auth + 'request-otp';

  static GET_OPS_SCORE = ApiConstant.URL_BASE + URLSegment.Shipment + 'performance-ops-card';

  static GET_TRANSACTION_HISTORY =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'transaction-changes';

  static SAVE_INITIAL_ASSIGNMENT = ApiConstant.URL_BASE + URLSegment.AssignmentRequest;
  static GET_TRUCK_FOR_SAM = ApiConstant.URL_BASE + URLSegment.Truck + 'trucks-for-sam';

  static UPDATE_EXPECTED_DELIVERY_DATE =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'update-expected-delivery-date';
  // Notification
  static NOTIFICATIONS = ApiConstant.URL_BASE + URLSegment.Shared + 'notifications';
  static UNREAD_NOTIFICATION_COUNT =
    ApiConstant.URL_BASE + URLSegment.Shared + 'unread-notifications-count';
  static MARK_NOTIFICATIONS_AS_READ =
    ApiConstant.URL_BASE + URLSegment.Shared + 'mark-notifications-as-read';

  // Payment
  static API_INIT_PAYMENT = ApiConstant.URL_BASE + URLSegment.Payment + 'init-payment';
  static API_CHECK_TRANSACTION = ApiConstant.URL_BASE + URLSegment.Payment + 'check-transaction';
  static CREATE_VIRTUAL_IBAN = ApiConstant.URL_BASE + URLSegment.Payment + 'viban';

  // Announcement
  static GET_ANNOUNCEMENT = ApiConstant.URL_BASE + URLSegment.Announcement;
  static SET_ANNOUNCEMENT = ApiConstant.URL_BASE + URLSegment.Announcement + 'add';
  // Customers
  static GET_ALL_CUSTOMERS = ApiConstant.URL_BASE + URLSegment.Customer + 'customers';
  static GET_ALL_CUSTOMERS_WITH_PAGINATION =
    ApiConstant.URL_BASE + URLSegment.Customer + 'customers-with-pagination';
  static GET_CUSTOMER_BY_ID = ApiConstant.URL_BASE + URLSegment.Customer;
  static UPDATE_CUSTOMER = ApiConstant.URL_BASE + URLSegment.Customer + 'update';
  static GET_ALL_PRODUCT_TYPES = ApiConstant.URL_BASE + URLSegment.ProductType + 'product-types';
  static ADD_PRODUCT_TYPES = ApiConstant.URL_BASE + URLSegment.ProductType + 'add';
  static ADD_UPDATE_PRODUCT_TYPE = ApiConstant.URL_BASE + URLSegment.ProductType + 'add';
  static GET_PRODUCT_TYPE_ITEMS = ApiConstant.URL_BASE + URLSegment.Shared + 'lookups/PRODUCT_ITEM';
  static UPDATE_PRODUCT_TYPES = ApiConstant.URL_BASE + URLSegment.ProductType + 'update';
  static GET_PRODUCT_BY_ID = ApiConstant.URL_BASE + URLSegment.ProductType;
  static CUSTOMER_FLEETS = ApiConstant.URL_BASE + URLSegment.Customer + 'customer-fleets';
  static GET_CUSTOMERS_WITHOUT_ORGANIZATION =
    ApiConstant.URL_BASE + URLSegment.Customer + 'customers-without-organization';
  static UPDATE_CUSTOMER_SHOW_TRUCK_TYPES =
    ApiConstant.URL_BASE + URLSegment.Customer + 'update-customer-show-truck-types';
  static REJECT_CUSTOMER_REQUEST =
    ApiConstant.URL_BASE + URLSegment.Customer + 'reject-customer-request';
  static UPDATE_CUSTOMER_ETA = ApiConstant.URL_BASE + URLSegment.Customer + 'update-customer-eta';
  static GET_CUSTOMER_OPERATION_CONFIG =
    ApiConstant.URL_BASE + URLSegment.Customer + 'operation-config';
  static UPDATE_CUSTOMER_OPERATION_CONFIG =
    ApiConstant.URL_BASE + URLSegment.Customer + 'update-operation-configuration';
  static UPDATE_CUSTOMER_FLEETS_CAN_ASSIGN_SHIPMENTS =
    ApiConstant.URL_BASE + URLSegment.Customer + 'update-fleet-new-shipments';
  static GET_FLEETS_CAN_PICK_NEW_SHIPMENTS =
    ApiConstant.URL_BASE + URLSegment.Customer + 'list-new-shipment-fleets';
  static CUSTOMER_EMAIL_CONFIG = ApiConstant.URL_BASE + URLSegment.Customer + 'email-config';
  static CUSTOMER_UPDATE_EMAIL_CONFIG =
    ApiConstant.URL_BASE + URLSegment.Customer + 'updateemail-config';
  static ADD_LOCATION_CUSTOMER =
    ApiConstant.URL_BASE + URLSegment.Customer + 'add-location-customer';
  static GET_MY_CUSTOMERS = ApiConstant.URL_BASE + URLSegment.Customer + 'my-customers';
  static UPDATE_R_CUSTOMERS = ApiConstant.URL_BASE + URLSegment.Customer + 'update-R-customers';
  static CUSTOMER_LOCATIONS = ApiConstant.URL_BASE + URLSegment.Customer + 'location';
  static UPDATE_CREATION_TEMPLATE =
    ApiConstant.URL_BASE + URLSegment.Customer + 'update-creation-template';
  static GET_CREATION_TEMPLATE = ApiConstant.URL_BASE + URLSegment.Customer + 'creation-template';
  static GET_GEOFENCING_CONFIG =
    ApiConstant.URL_BASE + URLSegment.Customer + 'fleet-geofencing-config';
  static ADD_GEOFENCING_CONFIG =
    ApiConstant.URL_BASE + URLSegment.Customer + 'fleet-geofencing-config';
  static GET_DYNAMICS_INTEGRATION =
    ApiConstant.URL_BASE + URLSegment.Customer + 'integration-configrations';
  static UPDATE_DYNAMICS_INTEGRATION =
    ApiConstant.URL_BASE + URLSegment.Customer + 'integration-configrations';
  static SEND_DIRECT_SMS = ApiConstant.URL_BASE + URLSegment.Customer + 'sms';

  static GET_WALLET_SUMMARY = ApiConstant.URL_BASE + URLSegment.Wallet + 'wallets';
  static GET_WALLET_TRANSACTIONS = ApiConstant.URL_BASE + URLSegment.Wallet + 'transactions';
  static GET_VIRTUAL_IBAN = ApiConstant.URL_BASE + URLSegment.Customer + 'viban';
  // Invoices
  static API_GET_INVOICES = ApiConstant.URL_BASE + URLSegment.Invoice + 'invoices';
  static API_GET_INVOICES_COUNTS = ApiConstant.URL_BASE + URLSegment.Invoice + 'counts';
  static API_EXPORT_ALL_INVOICES =
    ApiConstant.URL_BASE + URLSegment.Invoice + 'export-all-invoices';
  static GET_CUSTOMER_INVOICES = ApiConstant.URL_BASE + URLSegment.Invoice + 'invoices';
  static REJECT_INVOICE = ApiConstant.URL_BASE + URLSegment.Invoice + 'reject';
  static DASHBOARD_STATUS_COUNT = ApiConstant.URL_BASE + URLSegment.Invoice + 'dashboard';
  static PARTIAL_INVOICE_STATUS_COUNT =
    ApiConstant.URL_BASE + URLSegment.PartialInvoice + 'dashboard';
  static GET_PAYTABS_DETAILS = ApiConstant.URL_BASE + 'payment/batch-transaction-details';

  static MARK_PAID = ApiConstant.URL_BASE + URLSegment.Invoice + 'mark-as-paid';
  static MARK_UN_PAID = ApiConstant.URL_BASE + URLSegment.Invoice + 'mark-as-unpaid';
  static DELETE_DRAFT = ApiConstant.URL_BASE + URLSegment.Invoice + 'delete-invoice/';

  static ADD_INVOICE = ApiConstant.URL_BASE + URLSegment.Invoice + 'add';
  static UPDATE_INVOICE_DATE = ApiConstant.URL_BASE + URLSegment.Invoice + 'update-invoice-date';
  static UPDATE_INVOICE = ApiConstant.URL_BASE + URLSegment.Invoice + 'update';
  static EXPORT_INVOICE_PDF = ApiConstant.URL_BASE + URLSegment.Invoice + 'export-invoice-pdf';

  static FLEET_PAYMENT_DETAILS =
    ApiConstant.URL_BASE + URLSegment.Payment + 'fleet-payment-details';
  static REQUEST_EMAIL_OTP = ApiConstant.URL_BASE + URLSegment.Auth + 'request-email-otp';
  static FLEET_PAY = ApiConstant.URL_BASE + URLSegment.Payment + 'fleet-pay';

  static GET_CUSTOMERS_PERFORMANCE =
    ApiConstant.URL_BASE + URLSegment.Customer + 'customer-performance';
  static REFRESH_CUSTOMERS_PERFORMANCE =
    ApiConstant.URL_BASE + URLSegment.Customer + 'refresh-customer-performance';
  static EDIT_SHIPMENTS_DRAFT_INVOICE =
    ApiConstant.URL_BASE + URLSegment.Invoice + 'edit-shipments-draft-invoice';
  static CREATE_INVOICE = ApiConstant.URL_BASE + URLSegment.Invoice + 'add';
  static GET_FLEET_DELIVERY_NOTES =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'fleet-delivery-notes';

  static RENTED_TRUCKS_INVOICING =
    ApiConstant.URL_BASE + URLSegment.Truck + 'rented-trucks-invoicing';
  static GET_RENTED_TRUCKS_ShIPMENTS =
    ApiConstant.URL_BASE + URLSegment.Truck + 'rented-trucks-shipments-invoicing';

  static API_GET_INVOICES_ACTIVITY_STATUS = ApiConstant.URL_BASE + URLSegment.InvoiceHistory;
  static CREATE_PARTIAL_PAYMENT = ApiConstant.URL_BASE + URLSegment.PartialInvoice + 'save';
  static GET_PARTIALLY_PAYMENT_HISTORY =
    ApiConstant.URL_BASE + URLSegment.PartialInvoice.replace(/\/$/, '');
  static REJECT_PARTIALLY_PAYMENT =
    ApiConstant.URL_BASE + URLSegment.PartialInvoice + 'mark-as-rejected';
  static PAID_PARTIALLY_PAYMENT = ApiConstant.URL_BASE + URLSegment.PartialInvoice + 'mark-as-paid';

  // Delivery Notes
  static GET_INVOICE_DELIVERY_NOTES =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'invoice-delivery-notes';
  static GET_EXPORT_DELIVERY_NOTE =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'invoice-delivery-notes-export';
  static GET_EXPORT_INVOICE_PDF = ApiConstant.URL_BASE + URLSegment.Invoice + 'export-invoice-pdf';
  static GET_PARTIAL_INVOICE = ApiConstant.URL_BASE + URLSegment.PartialInvoice;

  // Receivers
  static GET_ALL_RECEIVERS = ApiConstant.URL_BASE + URLSegment.Receiver + 'receivers';
  static GET_PAGINATED_RECEIVERS =
    ApiConstant.URL_BASE + URLSegment.Receiver + 'receivers-with-pagination';
  static ADD_RECEIVER = ApiConstant.URL_BASE + URLSegment.Receiver + 'add';
  static GET_RECEIVER_BY_ID = ApiConstant.URL_BASE + URLSegment.Receiver;
  static UPDATE_RECEIVER = ApiConstant.URL_BASE + URLSegment.Receiver + 'update';
  static API_EXPORT_RECEIVERS =
    ApiConstant.URL_BASE + URLSegment.Receiver + 'export-receivers-excel';

  // Location
  static ADD_LOCATION = ApiConstant.URL_BASE + URLSegment.Shared + 'location/add';
  static EDIT_LOCATION = ApiConstant.URL_BASE + URLSegment.Shared + 'location/update';
  static LOCATION_GET_ALL = ApiConstant.URL_BASE + URLSegment.Shared + 'active-locations';
  static GET_LOCATION_DETAILS = ApiConstant.URL_BASE + URLSegment.Shared + 'location';
  static GET_LEAD_DRIVERS_LOCATIONS = ApiConstant.URL_BASE + URLSegment.Lead + 'leads';
  static API_GET_WCC_REGIONS = ApiConstant.URL_BASE + URLSegment.WCC + 'regions';
  static API_GET_WCC_RECEIVERS = ApiConstant.URL_BASE + URLSegment.WCC + 'receivers';

  // Fleet Provider
  static GET_ALL_FLEETS = ApiConstant.URL_BASE + URLSegment.Fleet + 'fleets';
  static ADD_FLEET = ApiConstant.URL_BASE + URLSegment.Fleet + 'add';
  static GET_FLEET_BY_ID = ApiConstant.URL_BASE + 'fleet';
  static UPDATE_FLEET = ApiConstant.URL_BASE + URLSegment.Fleet + 'update';
  static GET_ACTIVE_FLEETS = ApiConstant.URL_BASE + URLSegment.Fleet + 'active-fleets';
  static VERIFY_FLEET = ApiConstant.URL_BASE + URLSegment.Fleet + 'verify';
  static GET_SPECIFIC_FLEET = ApiConstant.URL_BASE + URLSegment.Fleet + 'specific-fleets';
  static GET_SPECIFIC_FLEET_WITHOUT_CONTRACT =
    ApiConstant.URL_BASE + URLSegment.Fleet + 'specific-fleets-without-contracts';
  static VALID_FLEETS_TO_MOVE =
    ApiConstant.URL_BASE + URLSegment.Fleet + 'valid-fleets-to-transfer';
  static GET_ACTIVE_FLEETSB2C = ApiConstant.URL_BASE + URLSegment.Fleet + 'active-fleets';
  static GET_ACTIVE_FLEETS_WITH_PAYMENTS =
    ApiConstant.URL_BASE + URLSegment.Fleet + 'active-fleets/payments';
  static UPDATE_FLEET_CONFIG = ApiConstant.URL_BASE + URLSegment.Fleet + 'fleet-config';
  static GET_ATHR_DRIVER = ApiConstant.URL_BASE + URLSegment.Athr + 'driver';
  static UPDATE_ATHR_DRIVER = ApiConstant.URL_BASE + URLSegment.Driver + 'update-driver-by-athr';
  static GET_ATHR_FLEET = ApiConstant.URL_BASE + URLSegment.Athr + 'fleet';
  static UPDATE_ATHR_FLEET = ApiConstant.URL_BASE + URLSegment.Fleet + 'update-fleet-by-athr';
  static GET_ATHR_TRUCK = ApiConstant.URL_BASE + URLSegment.Athr + 'truck';
  static UPDATE_ATHR_TRUCK = ApiConstant.URL_BASE + URLSegment.Truck + 'update-truck-by-athr';
  static CHANGE_TRUCK_OPERATION_STATUS =
    ApiConstant.URL_BASE + URLSegment.Truck + 'operation-status';
  static GET_CLASSIFIED_TRADELANES = ApiConstant.URL_BASE + 'truck-area-preference';

  // Assinging logs
  static GET_ASSIGNMENT_LOGS = ApiConstant.URL_BASE + URLSegment.AssingingLogs + 'logs';

  // Shipments
  static GET_SHIPMENT_BY_ID = ApiConstant.URL_BASE + URLSegment.Shipment;
  static GET_SHIPMENT_DELIVERY_NOTE_BY_ID = ApiConstant.URL_BASE + URLSegment.DeliveryNotes;
  static ASSIGN_FLEET = ApiConstant.URL_BASE + URLSegment.Shipment + 'assign';
  static ASSIGN_FLEETB2C = ApiConstant.URL_BASE + URLSegment.Shipment + 'assign-carrier';
  static GET_ALL_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'shipments';
  static GET_ALL_SHIPMENTS_CACHE = ApiConstant.URL_BASE + URLSegment.Shipment + 'cache';
  static GET_ALL_SHIPMENTS_COUNT = ApiConstant.URL_BASE + URLSegment.Shipment + 'shipments-count';
  static GET_ALL_SHIPMENTS_COUNT_CACHE =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'shipments-count/cache';
  static GET_ALL_B2C_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'b2c-shipments';
  static EXPORT_B2C_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'export-b2c-shipments';
  static SHIPMENT_ADD = ApiConstant.URL_BASE + URLSegment.Shipment + 'add/beta';
  static SHIPMENT_UPDATE = ApiConstant.URL_BASE + URLSegment.Shipment + 'update';
  static SHIPMENT_UPDATE_STATUS = ApiConstant.URL_BASE + URLSegment.Shipment + 'update/' + 'status';
  static GET_CUSTOMER_RATE = ApiConstant.URL_BASE + URLSegment.Contract + 'customer';
  static API_GET_SPOT_PRICES_RATE = ApiConstant.URL_BASE + URLSegment.Contract + 'spot-price';
  static GET_USER_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.Shipment + 'user-performance';
  static GET_DELIVERY_NOTES = ApiConstant.URL_BASE + URLSegment.Shipment + 'delivery-notes';
  static GET_EXPORT_DELIVERY_NOTES =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'export-delivery-notes';
  static DELETE_SHIPMENT = ApiConstant.URL_BASE + URLSegment.Shipment;
  static RESEND_OTP = ApiConstant.URL_BASE + URLSegment.Shipment + 'resend-otp';
  static GET_TRANSPORTATION_PAYMENTS =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'transportation-payment';
  static UPDATE_TRANSPORTATION_PAYMENT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'update-transportation-payment';
  static CREATE_TRANSPORTATION_PAYMENT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'create-transportation-payment';
  static EXPORT_TRANSPORTATION_PAYMENT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'export-transportation-payment';
  static DELETE_SHIPMENT_FROM_TRANSPORTATION_INVOICE =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'delete-shipment-from-transportation-invoice';
  static MARK_SHIPMENT_AS_PAID_FOR_DRIVER =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'mark-as-paid-for-driver';
  static GET_INVOICES_TRANSPORTATION =
    ApiConstant.URL_BASE + URLSegment.InvoiceTransportation + 'invoices-transportation';
  static DOWNLOAD_EWAYBILL =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'preview-shipment-ewaybill';
  static GET_B2C_SHIPMENT_BY_ID =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'b2c-shipment-detials';
  static GET_B2C_SHIPMENT_LOGS = ApiConstant.URL_BASE + URLSegment.Shipment + 'shipment-log/';
  static API_EXPORT_SHIPMENTS =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'export-shipments-list-csv';
  static GET_SHIPMENT_REQUESTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'requests/';
  static GET_SHIPMENT_AVL_SUMMARY = ApiConstant.URL_BASE + URLSegment.Traccar + 'summary/';
  static CANCEL_B2C_SHIPMENT = ApiConstant.URL_BASE + URLSegment.Shipment + 'b2c-shipment-cancel';
  static DOWNLOAD_B2C_AIR_WAYBILL =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'b2c-shipment-waybill';
  static MARK_SHIPMENT_DELIVERED = ApiConstant.URL_BASE + URLSegment.Shipment + 'mark-as-received/';
  static PRINT_BAYAN_TRIP = ApiConstant.URL_BASE + URLSegment.Shipment + 'print-bayan-trip';
  static BAYAN_INTEGRATE_TRIP = ApiConstant.URL_BASE + URLSegment.Shipment + 'create-bayan-trip';
  static BAYAN_CANCEL_WAYBILL = ApiConstant.URL_BASE + URLSegment.Shipment + 'cancel-bayan-waybill';
  static GET_RUNNING_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'running-shipments/';
  static GET_SHIPMENT_INFO_COUNTS =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'shipment-info-counts';
  static EXPORT_SHIPMENTS_PAYMENT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'export-transportation-payment-excel';
  static GET_LIVE_TRACKING_SHIPMENTS_LIST =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'live-tracking-shipments';

  // integrations
  static GET_SHIPMENT_INTEGRATIONS = ApiConstant.URL_BASE + URLSegment.Integration + 'shipments';
  static GET_FLEET_DYNAMIC_INTEGRATION = ApiConstant.URL_BASE + URLSegment.Fleet + 'integrations';
  static INTEGRATE_SHIPMENT = ApiConstant.URL_BASE + URLSegment.Integration + 'integrate';
  static FLEET_INTEGRATE_SHIPMENT =
    ApiConstant.URL_BASE + URLSegment.Integration + 'fleet-integrate';

  static EXPORT_TRANSPORTATION_PAYMENTS =
    ApiConstant.URL_BASE + URLSegment.InvoiceTransportation + 'export-invoices-transportation';
  static UPDATE_GATE_TO_GATE_TIME = ApiConstant.URL_BASE + URLSegment.Shipment + 'history/update';
  static GET_SHIPMENT_HISTORY_ITEM = ApiConstant.URL_BASE + URLSegment.Shipment + 'history/';
  static GET_SHIPMENT_DOOR_OPEN_HISTORY =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'door-open-history';
  static GET_TEMPERATURE_AND_HUMIDITY = ApiConstant.URL_BASE + URLSegment.Shipment + 'alerts/';

  // Geofencing
  static GET_GEOFENCING_SHIPMENT_UPDATES =
    ApiConstant.URL_BASE + URLSegment.Geofencing + 'geofencing-updates/';

  // Requests
  static UPDATE_TRAILER_LABEL = ApiConstant.URL_BASE + URLSegment.Request + 'update/trailer-label';

  // Trucks
  static GET_SHIPMENT_PATH =
    environment.socket_url +
    environment.api_version +
    environment.api_prefix +
    URLSegment.ShipmentLocation;
  static GET_SHIPMENT_AVL_PATH = ApiConstant.URL_BASE + URLSegment.Traccar + 'shipments/';
  static GET_TRUCK_CUSTOM_REPORT = ApiConstant.URL_BASE + URLSegment.Traccar + 'custom-report';
  static GET_AVL_GROUPS = ApiConstant.URL_BASE + URLSegment.Traccar + 'groups';
  static GET_TRUCK_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Truck + 'truck-shipment/';
  static GET_RENTED_TRUCK_DASHBOARD =
    ApiConstant.URL_BASE + URLSegment.Truck + 'rented-trucks-dashboard';

  // Truck Types
  static TRUCK_GET_ACTIVE = ApiConstant.URL_BASE + URLSegment.TruckType + 'active-truck-types';
  static GET_ACTIVE_TRUCK_TYPES_CONTRACT_CUSTOMER =
    ApiConstant.URL_BASE + URLSegment.TruckType + 'active-truck-types-contract-customer';

  // Trucks
  static GET_ALL_TRUCKS = ApiConstant.URL_BASE + URLSegment.Truck + 'trucks';
  static GET_FLEET_ACTIVE_TRUCKS = ApiConstant.URL_BASE + URLSegment.Truck + 'active-trucks';
  static CALCULATE_TRUCK_DISTANCE =
    ApiConstant.URL_BASE + URLSegment.Truck + 'calculate-trucks-distance';
  static GET_ALL_BRANDS = ApiConstant.URL_BASE + URLSegment.Brand + 'brands';
  static ADD_TRUCK = ApiConstant.URL_BASE + URLSegment.Truck + 'add';
  static GET_TRUCK_BY_ID = ApiConstant.URL_BASE + URLSegment.Truck;
  static CREATE_TRUCK_AREA_PREFERENCE =
    ApiConstant.URL_BASE + URLSegment.TruckAreaPreference + 'add';
  static UPDATE_TRUCK_AREA_PREFERENCE =
    ApiConstant.URL_BASE + URLSegment.TruckAreaPreference + 'update';
  static DELETE_TRUCK_AREA_PREFERENCE =
    ApiConstant.URL_BASE + URLSegment.TruckAreaPreference + 'delete/';
  static UPDATE_TRUCK = ApiConstant.URL_BASE + URLSegment.Truck + 'update';
  static INTEGRATE_FLEET_WITH_3LEM = ApiConstant.URL_BASE + URLSegment.Elm + URLSegment.Vehicle;
  static ADD_BULK_TRUCKS = ApiConstant.URL_BASE + URLSegment.Truck + 'add-bulk-trucks';
  static CHECK_BULK_TRUCKS = ApiConstant.URL_BASE + URLSegment.Truck + 'check-bulk-trucks';
  static GET_TRUCK_BY_AVL_ID = ApiConstant.URL_BASE + URLSegment.Truck;
  static GET_FLEET_TRUCKS_PLANNING =
    ApiConstant.URL_BASE + URLSegment.Truck + 'fleet-trucks-planing';
  static RELEASE_TRUCK = ApiConstant.URL_BASE + URLSegment.Truck + 'release';
  static GET_TRUCK_LOCATION = ApiConstant.URL_BASE + URLSegment.Truck + 'truck-location';
  static TRUCK_STATUS_CALENDAR = ApiConstant.URL_BASE + URLSegment.Truck + 'calendar';
  static TRUCK_OPERATION_STATUS = ApiConstant.URL_BASE + URLSegment.Truck + 'operation-status';
  static OPERATION_STATUS_EXPORT =
    ApiConstant.URL_BASE + URLSegment.Truck + 'operation-status-export';
  static VERIFY_TRUCK_BY_3LEM = ApiConstant.URL_BASE + URLSegment.Truck + 'verify-truck-by-elm';
  static TRUCKS_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.Truck + 'trucks-performance';
  static GET_TRUCKS_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Truck + 'trucks-shipments';
  static GET_TRUCK_PROFITS = ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts + 'profits';
  static GET_TRUCK_NON_CONTRACT_DAYS =
    ApiConstant.URL_BASE + URLSegment.Truck + 'rented_trucks-shipments';
  static ADD_RENTED_TRUCK_CONTRACT =
    ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts + 'add';
  static UPDATE_RENTED_TRUCK_CONTRACT =
    ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts + 'update';
  static GET_RENTED_TRUCK_CONTRACT_BY_ID = ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts;
  static GET_ALL_RENTED_TRUCK_CONTRACTS =
    ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts + 'trucks-rented-contracts';
  static GET_TRUCKS_NON_CONTRACT_DAYS =
    ApiConstant.URL_BASE + URLSegment.RentedTrucksContracts + 'non-contracted-days';
  static GET_TRUCK_TEMPERATURE_LOG_PDF =
    ApiConstant.URL_BASE + URLSegment.Traccar + 'export-traccar-report';

  // Drivers
  static GET_ACTIVE_DRIVERS = ApiConstant.URL_BASE + URLSegment.Driver + 'active-drivers';
  static ADD_DRIVER = ApiConstant.URL_BASE + URLSegment.Driver + 'add';
  static GET_ALL_DRIVERS = ApiConstant.URL_BASE + URLSegment.Driver + 'drivers';
  static GET_DRIVER_BY_ID = ApiConstant.URL_BASE + URLSegment.Driver;
  static UPDATE_DRIVER = ApiConstant.URL_BASE + URLSegment.Driver + 'update';
  static INTEGRATE_WITH_3LEM = ApiConstant.URL_BASE + URLSegment.Elm + URLSegment.Driver;
  static VERIFY_BY_3LEM = ApiConstant.URL_BASE + URLSegment.Driver + 'verify-driver-by-elm';
  static SEND_PASSWORD = ApiConstant.URL_BASE + URLSegment.Driver + 'send-password';
  static GET_DRIVER_LOCATION =
    environment.socket_url +
    environment.api_version +
    environment.api_prefix +
    URLSegment.DriverLocation +
    'all';
  static GET_DRIVER_SHIPMENTS = ApiConstant.URL_BASE + URLSegment.Driver + 'driver-shipments/';
  static ADD_DRIVER_WITH_TRUCK = ApiConstant.URL_BASE + URLSegment.Driver + 'driver-with-truck';
  static DRIVER_MOVE_TO_FLEET = ApiConstant.URL_BASE + URLSegment.Driver + 'transfer-to-fleet';
  static FILTER_DRIVER_BY_FLEET_TYPE =
    ApiConstant.URL_BASE + URLSegment.Driver + 'filter-driver-by-fleet-type';
  static ADD_BULK_DRIVERS = ApiConstant.URL_BASE + URLSegment.Driver + 'add-bulk-drivers';
  static CHECK_BULK_DRIVERS = ApiConstant.URL_BASE + URLSegment.Driver + 'check-bulk-drivers';
  static ADD_BULK_DRIVERS_WITH_TRUCKS =
    ApiConstant.URL_BASE + URLSegment.Driver + 'add-bulk-trucks-with-drivers';
  static CHECK_BULK_DRIVERS_WITH_TRUCKS =
    ApiConstant.URL_BASE + URLSegment.Driver + 'check-bulk-trucks-with-drivers';
  static API_GET_EXPORT_DRIVERS = ApiConstant.URL_BASE + URLSegment.Driver + 'export-drivers-excel';
  static RELEASE_DRIVER = ApiConstant.URL_BASE + URLSegment.Driver + 'release';
  static UPDATE_DRIVER_BLACKLIST = ApiConstant.URL_BASE + 'driver-black-list/add';

  // Trailer
  static ADD_TRAILER = ApiConstant.URL_BASE + URLSegment.Trailer + 'add';
  static UPDATE_TRAILER = ApiConstant.URL_BASE + URLSegment.Trailer + 'update';
  static GET_ALL_FLEET_TRAILERS = ApiConstant.URL_BASE + URLSegment.Trailer + 'fleet-trailers';
  static GET_TRILER_BY_ID = ApiConstant.URL_BASE + URLSegment.Trailer;

  // Contract
  static GET_ALL_CONTRACTS = ApiConstant.URL_BASE + URLSegment.Contract + 'contracts';
  static GET_RELATED_CONTRACT = ApiConstant.URL_BASE + URLSegment.Contract + 'related-contract';
  static GET_CONTRACT = ApiConstant.URL_BASE + URLSegment.Contract;
  static GET_MATCHED_FLEETS = ApiConstant.URL_BASE + URLSegment.Contract + 'matched';
  static ADD_CONTRACT = ApiConstant.URL_BASE + URLSegment.Contract + 'add';
  static UPDATE_CONTRACT = ApiConstant.URL_BASE + URLSegment.Contract + 'update';
  static GET_GLOBAL_CONTRACT = ApiConstant.URL_BASE + URLSegment.Contract + 'global-contract';
  static ADD_TRADE_LANE = ApiConstant.URL_BASE + URLSegment.Contract + 'trade-lane/add';
  static UPDATE_TRADE_LANE = ApiConstant.URL_BASE + URLSegment.Contract + 'trade-lane/update';
  static GET_CUSTOMER_PRICES = ApiConstant.URL_BASE + URLSegment.Contract + 'trade-lane/prices';

  // City
  static GET_ACTIVE_CITIES = ApiConstant.URL_BASE + URLSegment.City + 'active-cities';
  static GET_ALL_CITIES = ApiConstant.URL_BASE + URLSegment.City + 'cities';

  // Lead
  static GET_ALL_LEADS = ApiConstant.URL_BASE + URLSegment.Lead + 'leads';
  static DELETE_LEAD = ApiConstant.URL_BASE + URLSegment.Lead;

  //dashboard
  static GET_ORDER_STATUS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'order-status';
  static GET_ORDER_FULFILLMENT = ApiConstant.URL_BASE + URLSegment.Dashboard + 'order-fulfillment';
  static GET_MADAR_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.Dashboard + 'madar-performance';
  static GET_TOP_DRIVERS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'top-drivers';
  static GET_OPERATION_PERFORMANCE =
    ApiConstant.URL_BASE + URLSegment.Dashboard + 'operation-performance';
  static EXPORT_OPERATION_PERFORMANCE =
    ApiConstant.URL_BASE + URLSegment.Dashboard + 'export-operation-performance';
  static GET_TOTAL_DELIVERY_NOTES =
    ApiConstant.URL_BASE + URLSegment.Dashboard + 'delivery-notes-counts';
  static GET_TOTAL_JOINED =
    ApiConstant.URL_BASE + URLSegment.Dashboard + 'joined-fleets-and-customers-counts';
  static GET_TOTAL_CONTRACTS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'contracts-counts';
  static GET_TOTAL_DRIVERS_COUNTS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'drivers-counts';
  static GET_TOTAL_TRUCKS_COUNTS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'trucks-counts';
  static GET_ORDER_COUNT = ApiConstant.URL_BASE + URLSegment.Dashboard + 'order-count';
  static GET_ORDER_TRADE_LANE_COUNT =
    ApiConstant.URL_BASE + URLSegment.Dashboard + 'order-trade-lane-count';
  static GET_GROWTH_ANALYSIS = ApiConstant.URL_BASE + URLSegment.Dashboard + 'growth-analysis';

  // invoice
  static GET_INVOICE_STATUS = ApiConstant.URL_BASE + URLSegment.Invoice + 'count-and-amount';

  // Organizations
  static GET_ALL_ORGANIZATIONS = ApiConstant.URL_BASE + URLSegment.Organization + 'organizations';
  static ADD_ORGANIZATION = ApiConstant.URL_BASE + URLSegment.Organization + 'add';
  static GET_ORGANIZATION_BY_ID = ApiConstant.URL_BASE + URLSegment.Organization;
  static REMOVE_ORGANIZATION_BY_ID = ApiConstant.URL_BASE + URLSegment.Organization;
  static UPDATE_ORGANIZATION = ApiConstant.URL_BASE + URLSegment.Organization + 'update';
  static ADD_ORGANIZATION_CUSTOMERS =
    ApiConstant.URL_BASE + URLSegment.Organization + 'add-customers';
  static REMOVE_ORGANIZATION_CUSTOMERS =
    ApiConstant.URL_BASE + URLSegment.Organization + 'remove-customers';

  // Reports
  static TRUCK_REPORT = ApiConstant.URL_BASE + URLSegment.Truck + 'truck-report';
  static TRUCK_REPORT_CSV = ApiConstant.URL_BASE + URLSegment.Truck + 'truck-report-csv';
  static DRIVER_REPORT = ApiConstant.URL_BASE + URLSegment.Driver + 'driver-report';
  static DRIVER_REPORT_CSV = ApiConstant.URL_BASE + URLSegment.Driver + 'driver-report-csv';
  static SHIPMENT_REPORT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'shipment-consolidation-report';
  static SHIPMENT_REPORT_CSV =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'shipment-consolidation-report-csv';
  static GET_AVL_REPORT = ApiConstant.URL_BASE + URLSegment.Traccar + 'devices-report';
  static EXPORT_AVL_REPORT = ApiConstant.URL_BASE + URLSegment.Traccar + 'export-devices';
  static INTEGRATION_REPORT =
    ApiConstant.URL_BASE + URLSegment.Integration + 'integration-actions-report';
  static SHIPMENT_GATES_REPORT =
    ApiConstant.URL_BASE + URLSegment.Shipment + 'shipment-gates-report';
  static TRUCK_UTILIZATION_REPORT =
    ApiConstant.URL_BASE + URLSegment.Truck + 'truck-report-utilization';

  // Sales Orders
  static GET_ALL_SALES_ORDERS = ApiConstant.URL_BASE + URLSegment.SalesOrder + 'list';
  static GET_SALES_ORDER_BY_ID = ApiConstant.URL_BASE + URLSegment.SalesOrder;

  static GET_ORDERS_PALLET_LIST = ApiConstant.URL_BASE + URLSegment.Pallet + 'orders-pallet-list';
  static GET_PLAN_TRUCKS = ApiConstant.URL_BASE + URLSegment.Truck + 'available-trucks';
  static CREATE_SHIPMENT_ORDER = ApiConstant.URL_BASE + URLSegment.Shipment + 'add/list';

  // Route Time
  static ADD_ROUTE_TIME = ApiConstant.URL_BASE + URLSegment.RouteTime + 'add';
  static GET_ROUTE_TIMES = ApiConstant.URL_BASE + URLSegment.RouteTime + 'route-times';
  static DELETE_TRUCK_TYPE_ROUTE_TIME = ApiConstant.URL_BASE + URLSegment.RouteTime;
  static UPDATE_ROUTE_TIME = ApiConstant.URL_BASE + URLSegment.RouteTime + 'update';

  // smart detection
  static DETECT_DRIVERS =
    environment.socket_url +
    environment.api_version +
    environment.api_prefix +
    URLSegment.DriverLocation +
    'nearest-drivers';

  // B2c Logs
  static GET_B2C_SHIPMENT_REQUESTS = ApiConstant.URL_BASE + URLSegment.Shipment + 'requests';

  // Rules
  static ADD_B2C_SHIPMENT_RULE = ApiConstant.URL_BASE + URLSegment.ShipmentRule + 'add';
  static GET_SHIPMENT_RULES = ApiConstant.URL_BASE + URLSegment.ShipmentRule;
  static GET_ZONES = ApiConstant.URL_BASE + URLSegment.ShipmentRule + '/zones';
  static GET_SHIPMENT_RULE = ApiConstant.URL_BASE + URLSegment.ShipmentRule;
  static UPDATE_B2C_SHIPMENT_RULE = ApiConstant.URL_BASE + URLSegment.ShipmentRule + 'update';

  //sellers
  static GET_ALL_SELLERS = ApiConstant.URL_BASE + URLSegment.Seller + 'sellers';
  static UPDATE_SELLERS_CONFIGURATION = ApiConstant.URL_BASE + URLSegment.Seller + 'direct-fleets';
  static GET_SELLERS_CONFIGURATION =
    ApiConstant.URL_BASE + URLSegment.Seller + 'direct-fleets-list';

  static GET_PRIMARY_CONFIGURATION = ApiConstant.URL_BASE + URLSegment.PrimaryConfiguration;
  static UPDATE_PRIMARY_CONFIGURATION = ApiConstant.URL_BASE + URLSegment.PrimaryConfiguration;

  // measurements
  static GET_ALL_MEASUREMENT_UNITS =
    ApiConstant.URL_BASE + URLSegment.MeasurementUnit + 'measurement-units';

  // permits
  static UPDATE_DRIVER_PERMIT = ApiConstant.URL_BASE + URLSegment.Permits;
  static UPDATE_TRUCK_PERMIT = ApiConstant.URL_BASE + URLSegment.Permits + 'trucks/';

  static GET_LOOKUPS = ApiConstant.URL_BASE + URLSegment.Shared + 'lookups/';
  static GET_GLOBAL_SEARCH_LOOKUPS = ApiConstant.URL_BASE + URLSegment.Shared + 'search-any-where/';

  // avl
  static GET_AVL_DEVICES = ApiConstant.URL_BASE + URLSegment.Traccar + 'devices';
  static GET_AVL_POSITIONS = ApiConstant.URL_BASE + URLSegment.Traccar + 'positions';
  static GET_AVL_SHIPMENT_STOPS = ApiConstant.URL_BASE + URLSegment.Traccar + 'shipment-stops';
  static GET_AVL_EXTERNAL_DEVICES =
    ApiConstant.URL_BASE + URLSegment.ExternalTracking + 'devices/' + 'AFAQY';
  static GET_AVL_EXTERNAL_DEVICE_SUMMARY =
    ApiConstant.URL_BASE + URLSegment.ExternalTracking + 'device-summary/' + 'AFAQY';

  // exception logs
  static GET_EXCEPTION_LOGS = ApiConstant.URL_BASE + URLSegment.Logs;

  // Customer Support
  static ADD_TICKET = ApiConstant.URL_BASE + URLSegment.CustomerSupport + URLSegment.Ticket + 'add';
  static UPLOAD_TICKET_MEDIA = ApiConstant.URL_BASE + URLSegment.CustomerSupport + 'ticket-upload';
  static GET_SUPPORT_TOKEN = ApiConstant.URL_BASE + URLSegment.CustomerSupport + 'token';
  static GET_TICKET = ApiConstant.URL_BASE + URLSegment.CustomerSupport + 'ticket';

  // Audit Trail
  static GET_AUDIT_TRAILS = ApiConstant.URL_BASE + 'audit-trails';

  static GET_TRIP_PERFORMANCES = ApiConstant.URL_BASE + URLSegment.TripPerformance + 'list';
  static GET_TRIP_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.TripPerformance;
  static ADD_TRIP_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.TripPerformance + 'add';
  static UPDATE_TRIP_PERFORMANCE = ApiConstant.URL_BASE + URLSegment.TripPerformance + 'update';

  // Smart Center
  static GET_SHIPMENTS_COUNT_STATUS =
    ApiConstant.URL_BASE + URLSegment.SmartCenter + 'shipments-count-status';
  static GET_OFFLINE_DEVICES_COUNT =
    ApiConstant.URL_BASE + URLSegment.SmartCenter + 'offline-devices';

  static SEND_WHATSAPP_ALERT =
    ApiConstant.URL_BASE + URLSegment.SmartCenter + 'send_whatsapp_alert';
  static GET_EWAYBILL_HEALTHY = ApiConstant.URL_BASE + URLSegment.SmartCenter + 'ewaybill-healthy';
  static GET_SHIPMENT_PERFORMANCE_SUMMARY =
    ApiConstant.URL_BASE + URLSegment.SmartCenter + 'shipment-performance-summary';

  static GET_FIX_SHIPMENT = ApiConstant.URL_BASE + URLSegment.SmartCenter + 'shipment-smart-fix';

  static WCC_GET_BALANCE = ApiConstant.URL_BASE + URLSegment.WCC + 'landing-balance';
  static WCC_GET_PRODUCTS = ApiConstant.URL_BASE + URLSegment.WCC + 'products';
  static WCC_GET_PRODUCT_PRICE = ApiConstant.URL_BASE + URLSegment.WCC + 'get-product-price';
  static WCC_GET_TIME_SLOTS = ApiConstant.URL_BASE + URLSegment.WCC + 'get-available-slots';
  static WCC_CREATE_SHIPMENT = ApiConstant.URL_BASE + URLSegment.Shipment + 'add/beta';
  static WCC_GET_DELIVERY_NOTE_LINE =
    ApiConstant.URL_BASE + URLSegment.WCC + 'get-delivery-note-line';
  static WCC_USERS_WITH_PARAMS = ApiConstant.URL_BASE + URLSegment.Customer + 'users-with-params';
  static WCC_GET_ORDER_LOGS = ApiConstant.URL_BASE + URLSegment.WCC + 'order-logs';
  static WCC_INTEGRATE_WITH_CEMENT = ApiConstant.URL_BASE + URLSegment.WCC + 'update-order';

  // Shipment Routing
  static SHIPMENT_ROUTING = ApiConstant.URL_BASE + URLSegment.ShipmentRouting;
  static SHIPMENT_ROUTING_INFO = ApiConstant.URL_BASE + URLSegment.ShipmentRouting + 'routing-info';

  static GET_USER_PREFERENCES = ApiConstant.URL_BASE + URLSegment.User + 'get_user_preferences';
  static UPDATE_USER_PREFERENCES =
    ApiConstant.URL_BASE + URLSegment.User + 'update_user_preferences';

  // Orders
  static API_GET_ORDER_LIST = ApiConstant.URL_BASE + URLSegment.Order + 'list';
  static API_GET_ORDERS_COUNT = ApiConstant.URL_BASE + URLSegment.Order + 'count';
  static API_GET_PALLET_LIST = ApiConstant.URL_BASE + URLSegment.Pallet + 'pallet-list';
  static GET_ORDERS_CUSTOMERS = ApiConstant.URL_BASE + URLSegment.Order + 'orders-customers/';
  static API_GET_UNDETAILED_ORDER_INFO =
    ApiConstant.URL_BASE + URLSegment.Order + 'order-line-items';

  // Sales Orders
  static API_ADD_SALES_ORDER = ApiConstant.URL_BASE + URLSegment.SalesOrder + 'add';

  static GET_SHIPMENT_ASSIGNMENT_REQUESTS =
    ApiConstant.URL_BASE + URLSegment.AssignmentRequest + 'shipment-assignment-requests';

  static UPDATE_ASSIGNMENT_REQUEST_STATUS = ApiConstant.URL_BASE + URLSegment.AssignmentRequest;

  // Release Notes
  static GET_RELEASE_NOTES = ApiConstant.URL_BASE + URLSegment.ReleaseNote + '/release-notes';
  static GET_RELEASE_NOTE = ApiConstant.URL_BASE + URLSegment.ReleaseNote;
  static ADD_RELEASE_NOTE = ApiConstant.URL_BASE + URLSegment.ReleaseNote + 'add';
  static UPDATE_RELEASE_NOTE = ApiConstant.URL_BASE + URLSegment.ReleaseNote + 'update';
}
