import { Expose, Type } from 'class-transformer';
import { ReceiverType } from "@features/receivers/enums/receiver-type.enum";
import { ContactPerson } from '../../customers/models/contact-person';
import { Customer } from '../../customers/models/customer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { LocationModel } from '../../live-tracking/models/location.model';
import { User } from '../../users/models/user';

export class Receiver extends DropdownModel {
  @Expose() id: number = null;
  @Expose() code: number = null;
  @Expose() name: string;

  @Type(() => LocationModel)
  @Expose()
  locations: LocationModel[] = [];

  @Type(() => ContactPerson)
  @Expose()
  contactPerson: ContactPerson[] = [];

  @Type(() => User)
  @Expose()
  createdBy: User;

  @Type(() => User)
  @Expose()
  updatedBy: User;

  @Expose() createdAt: number;
  @Expose() updatedAt: number;

  @Type(() => Customer)
  @Expose()
  customer: Customer = new Customer();

  @Expose() receiverType: ReceiverType;

  @Expose()
  totalShipmentsCount: number;

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): Object {
    return this;
  }
}
