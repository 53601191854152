<form class="form-group flex column filter-form" [formGroup]="form" *ngIf="form">
  <div class="field flex column">
    <mat-label class="field-label">{{ "Filter.Form.Status" | translate }}</mat-label>
    <mat-form-field appearance="outline" class="form-control-full-width">
      <mat-select formControlName="appDownloadedFilter">
        <mat-option [value]="sharedConstants.ALL">
          {{ 'Options.ALL' | translate }}
        </mat-option>
        <mat-option *ngFor="let item of installationStatus | enumToArray" [value]="item.value">
          {{ 'Enum.InstallationStatus.' + item.key | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

</form>
