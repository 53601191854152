import { Injectable } from '@angular/core';
import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper';
import { CustomerFleetType } from '@features/fleets/enums/customer-fleet-type.enum';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { FleetStatus } from '@features/fleets/enums/fleet-status.enum';
import { Status } from '@features/shipments/enums/status.enum';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiConstant } from '@shared/constants/api.constant';
import { BusinessType } from '@features/users/enums/business-type.enum';
import { filterNullEntity } from '@shared/utils/filter-null-entity.util';
import { Fleet } from '@features/fleets/models/fleet';
import { FleetsDetails } from '@features/fleets/models/fleets-details';
import { PlanningProviderType } from '@features/trip-planning/enums';
import { SortDirection } from '@shared/enums/sort-direction.enum';

@Injectable({
  providedIn: 'root',
})
export class FleetsCommonApiService {
  constructor(private baseAPI: ApiBaseService, private mapper: Mapper) {}

  getAllFleets(params : {
    "status[]"?: FleetStatus[] | Status[],
    "type[]"?: CustomerFleetType,
    customerId?: number,
    searchTerm?: string,
    offset?: number,
    limit?: number,
    pageSize?: number,
    activationDate?: DateRangeType,
    isForExport?: boolean,
    fleetClassification?: PlanningProviderType,
    sortField?: string,
    sortDirection?: SortDirection,
    dateRangeType?: DateRangeType,
    businessType?: BusinessType,
  }): Observable<FleetsDetails> {
    return this.baseAPI
      .get(ApiConstant.GET_ALL_FLEETS, { params: filterNullEntity<typeof params>(params) })
      .pipe(
        map((res) => {
          return this.mapper.fromJson(FleetsDetails, res.data);
        })
      );
  }

  getFleetsCanPickNewShipments(customerId: number): Observable<Fleet[]> {
    return this.baseAPI
      .get(ApiConstant.GET_FLEETS_CAN_PICK_NEW_SHIPMENTS + `/${customerId}`)
      .pipe(map((res) => this.mapper.fromJson(Fleet, res.data)));
  }
}
