<div class="search-wrapper madar--v2">
  <div class="w-100" appearance="outline">
    <mat-form-field appearance="outline" class="search-wrapper__input-field">
      <app-icon matSuffix name="/search" class="search-wrapper__icon"></app-icon>
      <input
        #searchInput
        matInput
        type="text"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [ngModel]="searchTerms$ | async"
        (ngModelChange)="searchTerms$.next($event)"
        [placeholder]="placeholder"
      />
    </mat-form-field>
  </div>

  <div [hidden]="!isInputFocused || searchResults.length === 0">
    <ul class="search-wrapper__list scrollable scrollable--y" #scrollElement>
      <div>
        <li class="search-wrapper__list-item" *ngFor="let result of searchResults" (click)="selectOption(result)">
          <ng-container *ngIf="optionLabelDirective; else defaultOptionLabel">
            <ng-container *ngTemplateOutlet="optionLabelDirective.templateRef; context: { $implicit: result, index: i }"></ng-container>
          </ng-container>

          <ng-template #defaultOptionLabel>
            <span>{{ result.value }}</span>
          </ng-template>
        </li>
        <div class="flex align-center justify-center" *ngIf="isLoading">
          <app-spinner [small]="true"></app-spinner>
        </div>
      </div>
    </ul>
  </div>
</div>
