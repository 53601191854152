import { Expose, Type } from 'class-transformer';
import { Customer } from '../../customers/models/customer';
import { InvoicesStatus } from "@features/invoices/enums/invoices-status.enum";
import { Model } from '../../shared-features/models/model';
// import { Shipment } from './shipment';
import { User } from '../../users/models/user';

export class ShipmentInvoice extends Model {
  @Expose() id: number;

  // @Type(() => Shipment)
  // @Expose()
  // shipment: Shipment = new Shipment();

  @Expose()
  amount: number;

  @Expose()
  referenceNumber: string;

  @Expose()
  remarks: string;

  @Expose()
  rejectionReason: string;

  @Expose()
  status: InvoicesStatus;

  @Expose()
  paymentDate: number;

  @Expose()
  dueDate: number;

  @Expose()
  startDate: number;

  @Expose()
  invoiceDate: number;

  @Expose()
  endDate: number;

  @Expose()
  numberOfDays: number;

  @Expose()
  issuedTripsCount: number;

  @Expose()
  nonIssuedTripsCount: number;

  @Type(() => Customer)
  @Expose()
  customer: Customer = new Customer();

  @Expose()
  @Type(() => User)
  createdBy: User = new User();

  @Expose()
  @Type(() => User)
  updatedBy: User = new User();

  @Expose()
  @Type(() => User)
  rejectedBy: User = new User();

  @Expose()
  dueDays: number;

  @Expose()
  createdAt: number;

  @Expose()
  updatedAt: number;

  constructor() {
    super();
  }
}
