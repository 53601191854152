import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class TrucksReport extends Model {
    @Expose() id: number;
    @Expose() name: string;
    @Expose() iqama: string;
    @Expose() Sponsor: string;
    @Expose() numberOfShipment: number;
    @Expose() mobileNumber: string;
    @Expose() driverId: number;
    @Expose() truckType: string;
    @Expose() truckSponsor: string;
    @Expose() plateNumber: string;
    @Expose() model: number;
}

export class TrucksReportWithCount extends Model {
    @Expose()
    @Type(() => TrucksReport)
    data: TrucksReport[];

    @Expose() count: number;
}
