import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RentedTruckContract } from '@features/fleets/models/rented-truck-contract.model';
import { TrucksApiService } from '@features/fleets/services/trucks-api.service';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-add-edit-rented-truck-dialog',
  templateUrl: './add-edit-rented-truck-dialog.component.html',
  styleUrls: ['./add-edit-rented-truck-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditRentedTruckDialogComponent extends BaseComponent implements OnInit {
  form: FormGroup;
  rentedTruckContract: RentedTruckContract;

  constructor(
    private formBuilder: FormBuilder,
    private trucksApiService: TrucksApiService,
    @Inject(MAT_DIALOG_DATA)
    public data: { truckId: number; selectedContract: RentedTruckContract },
    private matDialogRef: MatDialogRef<AddEditRentedTruckDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSaveContract(): void {
    const rentedTruckContract = { ...this.data.selectedContract, ...this.form.value };
    const isEdit = rentedTruckContract.id;
    const save$ = isEdit
      ? this.trucksApiService.updateRentedTruckContract(rentedTruckContract)
      : this.trucksApiService.addRentedTruckContract(rentedTruckContract, this.data.truckId);

    this.load(save$, {
      isLoadingTransparent: true,
    }).subscribe((updatedRentedTruckContract) => {
      this.data.selectedContract = updatedRentedTruckContract;
      this.matDialogRef.close(true);
      if (isEdit) {
        this.showSuccessMessage('SuccessMessages.ContractUpdatedSuccessfully');
      } else {
        this.showSuccessMessage('SuccessMessages.ContractAddedSuccessfully');
      }
    });
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      startDate: [
        this.data.selectedContract?.startDate
          ? new Date(this.data.selectedContract?.startDate)
          : null,
        Validators.required,
      ],
      endDate: [
        this.data.selectedContract?.endDate ? new Date(this.data.selectedContract?.endDate) : null,
        Validators.required,
      ],
      dailyCost: [this.data.selectedContract?.dailyCost || null, Validators.required],
      monthlyCost: [this.data.selectedContract?.monthlyCost || null, Validators.required],
    });
  }
}
