import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ApiBaseService } from '@core/services/api-base.service';
import { ApiConstant } from '@shared/constants/api.constant';
import { Mapper } from '@core/services/mapper';
import { NotificationItem } from '@shared-features/models/notification-item';
import { NotificationService } from '@shared/services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService implements OnDestroy {
  /**
   * todo:
   * why we implement it like this
   */
  currentNotification$: BehaviorSubject<NotificationItem> = new BehaviorSubject<NotificationItem>(new NotificationItem());
  private destroy$ = new Subject();

  constructor(
    private baseApi: ApiBaseService,
    private mapper: Mapper,
    private notificationService: NotificationService,
  ){
    /**
     * todo:
     * same as above
     */
    this.notificationService.notification$.pipe(takeUntil(this.destroy$)).subscribe((notification) => {
      this.currentNotification$.next(this.currentNotification$.value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getAllNotifications(params?: { offset: number, limit: number }): Observable<NotificationItem> {
    return this.baseApi.get(ApiConstant.NOTIFICATIONS, { params }).pipe(
      map((res) => {
        return this.mapper.fromJson(NotificationItem, res.data);
      })
    );
  }

  getUnreadNotificationCount( ): Observable<number> {
    return this.baseApi.get(ApiConstant.UNREAD_NOTIFICATION_COUNT).pipe(
      map((res) => {
        return res.data;
      })
    );
  }


  markAsRead(ids: number|number[]): Observable<any> {
    const notificationIds = Array.isArray(ids) ? ids : [ids];
    return this.baseApi.post(ApiConstant.MARK_NOTIFICATIONS_AS_READ, { notificationIds });
  }
}
