<div class="container flex" [formGroup]="form">
  <div class="flex column main">
    <input [placeholder]="'Placeholder.PlateNumberFirstField' | translate" type="text" formControlName="main" oninput="validity.valid || (value[value.length-1] = '')">
    <mat-error *ngIf="form.invalid && form.get('main').touched && form.get('main').hasError('maxlength')">
      {{ "ErrorMessages.InValidLength4" | translate }}
    </mat-error>
  </div>
  <input type="text" [placeholder]="'Placeholder.PlateNumberSecondField' | translate" maxlength="1" formControlName="number1">
  <input type="text" [placeholder]="'Placeholder.PlateNumberThirdField' | translate" maxlength="1" formControlName="number2">
  <input type="text" [placeholder]="'Placeholder.PlateNumberFourthField' | translate" maxlength="1" formControlName="number3">
</div>
