import { Component, Input } from '@angular/core';
import { Alignment, RowComponent } from '@shared/components/grid/row.component';

@Component({
  selector: 'app-labeled-text-list',
  templateUrl: './labeled-text-list.component.html',
  styleUrls: ['./labeled-text-list.component.scss']
})
export class LabeledTextListComponent extends RowComponent {
  @Input() gutter: 'xs'|'sm'|'lg'|'x-xs'|'x-sm'|'x-lg'|'y-xs'|'y-sm'|'y-lg' = 'sm';
  @Input() align: Alignment = 'center';
}
