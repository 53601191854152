import { Expose, Type } from 'class-transformer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';

import { Media } from '../../shared-features/models/media';
import { Model } from '../../shared-features/models/model';
import { DeliveryNoteType } from '../enums/delivery-note-type.enum';

export class ShipmentDeliveryNote extends DropdownModel {
  @Expose()
  id: number;

  @Expose()
  referenceNumber: number;

  @Type(() => Media)
  @Expose()
  attachment: Media = new Media();

  @Expose()
  shipment_id: number;

  @Expose()
  updatedByName: string;

  @Expose()
  updatedAt: string;

  @Expose()
  uploadedAt: Date;

  @Expose()
  type: DeliveryNoteType;

  get dropDownDisplayName(): string {
    return this.referenceNumber.toString();
  }
  get dropDownValue(): Object {
    return this;
  }
}
