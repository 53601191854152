import { SortField } from "@shared/enums/sort-field.enum";
import { SortDirection } from "@shared/enums/sort-direction.enum";
import { SortOptions } from "@shared/enums/sort-options.enum";
import { ISortPayload } from '@shared-features/interfaces';

export function handleSortChange(value: SortOptions): ISortPayload {
  let sortDirection: SortDirection = null;
  let sortField: SortField = null;

  switch (value) {
    case SortOptions.MostRecent:
      sortDirection = SortDirection.Descending;
      sortField = SortField.UpdatedAt;
      break;

    case SortOptions.NameAZ:
      sortDirection = SortDirection.Ascending;
      sortField = SortField.Name;
      break;

    case SortOptions.NameZA:
      sortDirection = SortDirection.Descending;
      sortField = SortField.Name;
      break;
  }

  return {
    sortField,
    sortDirection,
  };
}
