<mat-label *ngIf="title">{{ title }}</mat-label>
<mat-form-field appearance="outline" class="d-block w-100">
  <!--  [name]="uuid"-->
  <input class="d-block w-100" type="text" matInput [readonly]="!enableEditing" [placeholder]="placeHolder | translate"
    [value]="value | date: (selectMonthAndYear ? 'MMM yyyy' : enableTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy')"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin" (focus)="isOpen = !isOpen;" [disabled]="disabled"/>
  <button matSuffix mat-icon-button type="button" class="date-field__button" [disabled]="disabled">
    <app-icon name="event" type="MaterialDesign" size="sm" class="text-300"></app-icon>
  </button>
  <mat-error *ngIf="form.invalid && form.touched && form.get('date').hasError('required')">{{ "ErrorMessages.Required" |
    translate }}</mat-error>
</mat-form-field>

<ng-template cdkConnectedOverlay #connectedPopup="cdkConnectedOverlay" [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayDisableClose]="true" [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayHasBackdrop]="true"
  (overlayKeydown)="onKeydown($event)">
  <div class="madar--v2" #calendar>
    <mat-card class="date-field__card" [formGroup]="form">
      <mat-calendar class="date-field__calendar" [minDate]="minDate" [maxDate]="maxDate"
        [selected]="form.get('date').value"
        (selectedChange)="form.get('date').setValue($event); !enableTime ? updateValue() : null"
        (monthSelected)="selectMonthAndYear ? setMonthAndYear($event, connectedPopup) : null"
        (yearSelected)="yearSelected($event)"
        [startView]="startView"></mat-calendar>

      <div class="px-3" *ngIf="enableTime">
        <app-time-field class="date-field__time-field" formControlName="time"></app-time-field>
      </div>

      <mat-card-actions *ngIf="enableTime">
        <button mat-button (click)="isOpen = false" class="ml-auto">{{ "Buttons.Cancel" | translate }}</button>
        <button mat-button color="primary" (click)="updateValue()">{{ "Buttons.Save" | translate }}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>
