<app-dialog-layout class="madar--v2 stacked-info">
  <ng-container dialog-title>{{'layout.opsScore.myScore' | translate}}</ng-container>

  <app-state-section [state]="sectionState">
    <mat-card class="mat-elevation-z2">
      <mat-card-content class="m-0 p-4">
        <mat-form-field appearance="outline">
          <input
            matInput
            [max]="maxDate"
            [formControl]="dateControl"
            [matDatepicker]="date"
            [placeholder]="'MM / YYYY'"
            readonly
            (click)="date.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="date">
            <app-icon name="calendar" matDatepickerToggleIcon></app-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #date
            startView="multi-year"
            (yearSelected)="chosenYearHandler($event)"
            (monthSelected)="chosenMonthHandler($event, date)"
          ></mat-datepicker>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="mt-2">
      <mat-card-header class="pt-6">
        <div class="flex align-center">
          <div class="mr-3 align-middle">
            <app-avatar
              class="d-block"
              bgColor="var(--color-accent-lighter)"
              fgColor="var(--color-accent)"
              iconType="svg"
              icon="group-small"
              [size]="32"
            ></app-avatar>
          </div>
          <div class="stacked-info__head">
            <div class="h6 weight-semibold m-0 text-900">{{userScore.customersCount || 0}}</div>
            <div class="text-400 small">{{'layout.opsScore.customers' | translate}}</div>
          </div>
        </div>
      </mat-card-header>
      <mat-card-content class="my-6">
        <mat-divider class="mb-3"></mat-divider>

        <div class="w-100 p-3 flex justify-space-between text-900 weight-semibold">
          <h6 class="my-0">{{'layout.opsScore.trips' | translate}}</h6>
          <div class="h6 my-0">{{userScore.tripsCount || 0}}</div>
        </div>

        <div class="w-100 bg-primary-lighter p-3 mb-3 radius-1">
          <app-row>
            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{userScore.internalCount || 0}}</h6>
              <div class="small text-400">{{'layout.opsScore.internal' | translate}}</div>
            </app-col>

            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{userScore.externalCount || 0}}</h6>
              <div class="small text-400">{{'layout.opsScore.external' | translate}}</div>
            </app-col>
          </app-row>
        </div>

        <div class="w-100 p-3 flex justify-space-between text-900 weight-semibold">
          <h6 class="my-0">{{'layout.opsScore.totalMargin' | translate}}</h6>
          <div class="h6 my-0">
            {{(userScore.totalMargin || 0) | number: '1.0-2'}}
            <small class="text-400">{{'layout.opsScore.SAR' | translate}}</small>
          </div>
        </div>

        <div class="w-100 bg-primary-lighter p-3 mb-3 radius-1">
          <app-row>
            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{(userScore.averageMargin || 0) | number: '1.0-2'}}</h6>
              <div class="small text-400">{{'layout.opsScore.avg' | translate}}</div>
            </app-col>

            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{(userScore.incentive || 0) | number: '1.0-2'}}</h6>
              <div class="small text-400">{{'layout.opsScore.incentive' | translate}}</div>
            </app-col>
          </app-row>
        </div>

        <div class="w-100 p-3 flex justify-space-between text-900 weight-semibold">
          <h6 class="my-0">{{'layout.opsScore.missingDn' | translate}}</h6>
          <div class="h6 my-0">{{userScore.totalMissingDN}}</div>
        </div>

        <div class="w-100 bg-primary-lighter p-3 radius-1">
          <app-row>
            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{userScore.carriersCount || 0}}</h6>
              <div class="small text-400">{{'layout.opsScore.fleetB2c' | translate}}</div>
            </app-col>

            <app-col>
              <h6 class="my-0 text-900 weight-semibold">{{userScore.freelancersCount || 0}}</h6>
              <div class="small text-400">{{'layout.opsScore.freelancer' | translate}}</div>
            </app-col>
          </app-row>
        </div>
      </mat-card-content>
    </mat-card>
  </app-state-section>
</app-dialog-layout>
