export enum InvoicesStatus {
  All = 'ALL',
  UnderReview = 'UNDER_REVIEW',
  InProgress = 'IN_PROGRESS',
  OverDue = 'OVER_DUE',
  Paid = 'PAID',
  UnPaid = 'UNPAID',
  Rejected = 'REJECTED',
  Drafted = "DRAFTED"
}
