import { Component, Input, OnInit,  SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { Customer } from '@features/customers/models/customer';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilterService } from '@shared/services/filter.service';
import { take, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { SharedConstants } from '@shared/model/shared-constants';
import { ActivatedRoute } from '@angular/router';
import { FunctionUtil } from '@shared/utils/function.util';
import { FleetHasPayments } from '@features/fleets/models/fleet-has-payments.model';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { TransportationPaymentsApiService } from '@features/transportation-payments/services/transportation-payments-api.service';
import { User } from '@features/users/models/user';

@Component({
  selector: 'app-transportation-payments-shipments-filter',
  templateUrl: './transportation-payments-shipments-filter.component.html',
  styleUrls: ['./transportation-payments-shipments-filter.component.scss'],
})

export class TransportationPaymentsShipmentsFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<void>;
  @Input() tabGroup: TabGroup;
  @Input() tabIndex: TabIndex;

  form: UntypedFormGroup;
  params: any = null;
  sharedConstants = SharedConstants;
  dateRangeType = DateRangeType;
  customers: Customer[] = [];
  users: User[] = [];
  fleets: FleetHasPayments[]=[];
  maxDate: Date = new Date();
  indices = TabIndex;

  constructor(
    private filterService: FilterService,
    
    private activatedRoute: ActivatedRoute,
    private sharedApiService: SharedApiService,
    private usersCommonApiService: UsersCommonApiService,
    private transportationPaymentApiService: TransportationPaymentsApiService,

  ) {
    super();
  }

  ngOnInit(): void {
    this.getPrerequisiteData();
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
  }

  getPrerequisiteData() {
    this.sectionState = SectionStateStatus.Loading;
    forkJoin([
      this.sharedApiService.getAllCustomers(),
      this.usersCommonApiService.getAllUsers(),
      this.transportationPaymentApiService.getActiveFleetsWithPayments()]
      )
    .pipe(take(1))
    .subscribe(
      ([customers, users, fleets]) => {
        this.customers = customers;
        this.users = users;
        this.fleets = fleets.fleet;
        this.initForm();
        this.sectionState = SectionStateStatus.Ready;
      },
      (error) => {
          this.showErrorMessage(error);
          this.sectionState = SectionStateStatus.Error;
        }
      );
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  getFilterValuesBasedOnURLParams() {
    return {
      customerId: this.params.customerId === SharedConstants.ALL ? SharedConstants.ALL : Number(this.params.customerId),
      dateRange: this.params.dateRange === DateRangeType.ALL_TIME ? DateRangeType.ALL_TIME : String(this.params.dateRange),
      startFrom: this.params.startFrom ? String(this.params.startFrom) : null,
      endAt: this.params.endAt ? String(this.params.endAt) : null,
      creatorId: this.params.creatorId === SharedConstants.ALL ? SharedConstants.ALL : Number(this.params.creatorId),
      fleetId: this.params.fleetId === SharedConstants.ALL ? SharedConstants.ALL : Number(this.params.fleetId),
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form = new UntypedFormGroup({
        customerId: new UntypedFormControl(filterValues.customerId),
        creatorId: new UntypedFormControl(filterValues.creatorId),
        fleetId: new UntypedFormControl(filterValues.fleetId),
        dateRange: new UntypedFormControl(filterValues.dateRange ? filterValues.dateRange : DateRangeType.ALL_TIME),
        startFrom: new UntypedFormControl(filterValues?.startFrom),
        endAt: new UntypedFormControl(filterValues?.endAt),
        creatorSearchTerm: new UntypedFormControl(''),
        customerSearchTerm: new UntypedFormControl(''),
        fleetSearchTerm: new UntypedFormControl(''),
      });
    } else {
      this.form = new UntypedFormGroup({
        customerId: new UntypedFormControl(SharedConstants.ALL),
        creatorId: new UntypedFormControl(SharedConstants.ALL),
        fleetId: new UntypedFormControl(SharedConstants.ALL),
        dateRange: new UntypedFormControl(DateRangeType.ALL_TIME),
        startFrom: new UntypedFormControl(null),
        endAt: new UntypedFormControl(null),
        creatorSearchTerm: new UntypedFormControl(''),
        customerSearchTerm: new UntypedFormControl(''),
        fleetSearchTerm: new UntypedFormControl(''),
      });
    }

    this.form
      .get('dateRange')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (value !== DateRangeType.CUSTOM) {
          this.form.controls['startFrom'].setValue(null);
          this.form.controls['endAt'].setValue(null);
        }
      });
  }

  get filteredCreators() {
    return this.users.filter((user) => user.dropDownDisplayName.toLowerCase().includes(
      this.form.controls.creatorSearchTerm?.value?.toString().toLowerCase()));
  }

  get filteredCustomers() {
    return this.customers.filter((user) => user.dropDownDisplayName.toLowerCase().includes(this.form.controls.customerSearchTerm.value?.toString().toLowerCase()));
  }

  get filteredFleets() {
    return this.fleets.filter((user) => user.dropDownDisplayName.toLowerCase().includes(this.form.controls.fleetSearchTerm.value?.toString().toLowerCase()));
  }

  subscribeToFilterAction() {
    this.action.pipe(takeUntil(this.destroy$)).subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  resetForm() {
    this.form.setValue({
      customerId:  SharedConstants.ALL,
      fleetId: SharedConstants.ALL,
      creatorId:  SharedConstants.ALL,
      dateRange: DateRangeType.ALL_TIME,
      startFrom: new UntypedFormControl(null),
      endAt: new UntypedFormControl(null),
      creatorSearchTerm: new UntypedFormControl(''),
      customerSearchTerm: new UntypedFormControl(''),
      fleetSearchTerm: new UntypedFormControl(''),
    });
    this.submitForm();
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.setValue({
        customerId: filterValues.customerId,
        fleetId: filterValues.fleetId,
        creatorId: filterValues.creatorId,
        dateRange: filterValues.dateRange,
        startFrom: filterValues.startFrom,
        endAt: filterValues.endAt,
        creatorSearchTerm: '',
        customerSearchTerm: '',
        fleetSearchTerm: ''
      });
    } else {
      this.resetForm();
    }
  }

  submitForm() {
    const submittedFilterValues = this.form.value;

    if (this.form.controls.startFrom.value && this.form.controls.endAt.value && this.form.controls.dateRange.value === DateRangeType.CUSTOM) {
      submittedFilterValues['startFrom'] = FunctionUtil.convertDate(this.form.controls.startFrom.value);
      submittedFilterValues['endAt'] = FunctionUtil.convertDate(this.form.controls.endAt.value);
    } else {
      submittedFilterValues['startFrom'] = null;
      submittedFilterValues['endAt'] = null;
    }

    submittedFilterValues.tabGroup = TabGroup.TransportationPayments;
    submittedFilterValues.tabIndex = this.tabIndex;
    this.filterService.navigateToTargetPageWithFilter(submittedFilterValues);
  }

}
