import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '@shared/services/layout.service';
import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class LineChartComponent extends BaseChartComponent implements OnInit {
  @Input() showLastPoint:boolean = false
  constructor(translate: TranslateService, layoutService: LayoutService, changeDetector: ChangeDetectorRef) {
    super(translate, layoutService, changeDetector);
    this.setupUI();
    this.type = 'line';
  }

  private setupUI(){
    this.chartPlugins.push({
      afterDraw: function(chart) {
        if (chart.data.datasets[0].data.length === 0) {
          var ctx = chart.chart.ctx;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = "18px Arial";
          ctx.fillText(this.translationsList['Charts']['NoDataAvailableYet'], chart.chart.width / 2, chart.chart.height / 2);
          ctx.restore();
        }

      }.bind(this),

      // margin between chart and legends
      beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
          this.height += 20;
        };
      }
    });
  }

  toggleDatasetVisibility(datasetIndex: number) {
    if (this.chart && this.chart.chart && this.chart.chart.data.datasets) {
      const datasets = this.chart.chart.data.datasets;

      if (datasets[datasetIndex]) {
        datasets[datasetIndex].hidden = !datasets[datasetIndex].hidden;
        this.chart.chart.update();
      }
    }
  }
}
