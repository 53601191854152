<div class="uploaded-file">
  <app-row align="center">
    <app-col>
      <app-icon class="uploaded-file__icon" name="bold-document"></app-icon>
      <span class="uploaded-file__title">{{title}}</span>
    </app-col>
    <app-col cols="auto">
      <button mat-icon-button class="uploaded-file__action" (click)="onDownloadClicked()">
        <app-icon name="bold-download-square" size="sm"></app-icon>
      </button>
    </app-col>
  </app-row>
</div>
