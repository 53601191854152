import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';

import { FilePondOptions } from 'filepond';
import { FilePondComponent, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { ConfigConstant } from '@shared/constants/config.constant';
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
@Component({
  selector: 'app-dragable-file',
  templateUrl: './dragable-file.component.html',
  styleUrls: ['./dragable-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragableFile implements OnInit {
  @ViewChild('myPond') myPond: FilePondComponent;
  @Input() multiple: boolean = false;
  @Input() label: string = 'Drag and drop your files here';
  @Input() acceptedFileTypes: string[] = ['application/pdf'];
  @Input() maxFiles: number = 1;
  @Input() icon: string = 'attach-square';
  @Input() buttonLabel: string = 'Upload File';

  @Input() pondHandleAddFile: (event: string) => void;
  @Input() pondHandleActivateFile: (event: string) => void;
  @Input() pondHandleRemoveFile: (event: string) => void;

  pondOptions: FilePondOptions;

  constructor() {}

  ngOnInit(): void {
    this.pondOptions = {
      allowMultiple: this.multiple,
      labelIdle: `
      <div class="filepond--content">
        <div class="flex align-center">
          <img class="mr-3" src="../../../../assets/images/icons/${this.icon}.svg">
          <span class="filepond--title"> ${this.label}</span>
        </div>

        <div class="filepond--label-action">${this.buttonLabel}</div>
      </div>`,
      acceptedFileTypes: this.acceptedFileTypes,
      allowReorder: true,
      maxFiles: this.maxFiles,
      maxFileSize: `${(ConfigConstant.FILE_MAX_SIZE / 1000) * 1000}mb`,
    };
  }
}
