import { Component, OnInit } from '@angular/core';
import { ConfigConstant } from '@shared/constants/config.constant';
import { MessagingService } from '@core/services/messaging.service';
import { LoadingService } from '@shared/services/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = ConfigConstant.APP_TITLE;

  constructor(
    private loadingService: LoadingService,
    private messagingService: MessagingService,
  ) {
  }

  ngOnInit(): void {
    this.messagingService.requestPermission();
  }
}
