import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { map, takeUntil } from 'rxjs/operators';
import { FeatureFlagConstant } from '@shared/constants/feature-flag.constant';

@Directive({
  selector: '[featureFlag]',
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
  @Input()
  set featureFlagHideIfFeatureEnabled(value: boolean) {
    this.displayInverted$.next(value);
  }

  @Input()
  set featureFlag(featureFlagName: FeatureFlagConstant) {
    this.feature = featureFlagName || '';
    this.display$.next(this.featureFlagService.isEnabled(this.feature));
  }
  destroy$ = new Subject();
  display$ = new BehaviorSubject(false);
  displayInverted$ = new BehaviorSubject(false);
  private feature: string;
  private displayed = false;

  constructor(
    private featureFlagService: FeatureFlagService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.featureFlagService.flags$.pipe(
      takeUntil(this.destroy$),
      map((flags) => this.feature && flags[this.feature]),
    ).subscribe((display) => {
      this.display$.next(display);
    });

    combineLatest([this.display$, this.displayInverted$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([featureEnabled, inverted]) => {
        const enabled = inverted ? !featureEnabled : featureEnabled;
        if (enabled && !this.displayed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.displayed = true;
        } else if (!enabled && this.displayed) {
          this.viewContainer.clear();
          this.displayed = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
