import { IFilterPayload, IPagingPayload, ISortPayload } from '@shared-features/interfaces';
import { SortField } from '@shared/enums/sort-field.enum';
import { SortDirection } from '@shared/enums/sort-direction.enum';
import { SharedConstants } from '@shared/model/shared-constants';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigConstant } from '@shared/constants/config.constant';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import moment from 'moment';

export type FiltersBaseParams = IPagingPayload & ISortPayload & IFilterPayload;

type FilterBaseParam = IPagingPayload | ISortPayload | IFilterPayload;

@Injectable({ providedIn: 'root' })
export class FiltersBase<T = {}, D = {}> {
  private pagination: IPagingPayload = {
    offset: ConfigConstant.INIT_PAGE_OFFSET,
    limit: ConfigConstant.PAGE_SIZE,
  };
  private sort: ISortPayload = { sortDirection: SortDirection.None, sortField: SortField.None };
  private search: IFilterPayload = {
    dateRangeType: DateRangeType.TODAY,
    searchTerm: '',
    startFrom: '',
    endAt: '',
  };
  public fields: T = {} as T;
  public displays: D = {} as D;

  private dataSubject = new BehaviorSubject(this.params);

  public data$ = this.dataSubject.asObservable();

  constructor() {}

  get params(): FiltersBaseParams & T {
    return {
      ...this.pagination,
      ...this.sort,
      ...this.search,
      ...this.fields,
    };
  }

  set params(filterParams: FiltersBaseParams & T) {
    this.pagination = {
      ...this.pagination,
      ...this.getParamsOfRelevantObj(this.pagination, filterParams),
    };
    this.sort = { ...this.sort, ...this.getParamsOfRelevantObj(this.sort, filterParams) };
    this.search = { ...this.search, ...this.getParamsOfRelevantObj(this.search, filterParams) };
    this.fields = { ...this.fields, ...this.getParamsOfRelevantObj(this.fields, filterParams) };
    this.dataSubject.next(this.params);
  }

  get sourceOfAllParams(): FiltersBaseParams & T & D {
    const fields = { ...this.params, ...this.displays };
    return Object.keys(fields).reduce((group, key) => {
      if (fields[key] === null) {
        group[key] = SharedConstants.ALL;
      } else {
        group[key] = fields[key];
      }
      return group;
    }, {} as FiltersBaseParams & T & D);
  }

  set display(displaySet: D) {
    this.displays = { ...this.displays, ...this.getParamsOfRelevantObj(this.displays, displaySet) };
  }

  static parseValue(value: any): any {
    if (typeof value === 'string' && value.toLowerCase() === 'true') {
      return true;
    }

    if (typeof value === 'string' && value.toLowerCase() === 'false') {
      return false;
    }

    if (typeof value === 'string' && value !== '' && Number(value) === Number(value)) {
      return Number(value);
    }

    if (typeof value === 'string' && value !== '' && moment(value).isValid()) {
      return value;
    }

    return value;
  }

  private getParamsOfRelevantObj(
    obj: FilterBaseParam | T | D,
    params: (FiltersBaseParams & T) | D
  ): Partial<FilterBaseParam | T | D> {
    const newValue: Partial<FilterBaseParam | T | D> = {};
    Object.keys(obj).forEach((key) => {
      if (typeof params[key] !== 'undefined') {
        newValue[key] =
          params[key] === SharedConstants.ALL ? null : FiltersBase.parseValue(params[key]);
      }
    });
    return newValue;
  }

  resetToDefault(): void {
    this.search = { dateRangeType: DateRangeType.TODAY, searchTerm: '', startFrom: '', endAt: '' };
    this.pagination = { offset: ConfigConstant.INIT_PAGE_OFFSET, limit: ConfigConstant.PAGE_SIZE };
    this.sort = { sortDirection: SortDirection.None, sortField: SortField.None };
  }
}
