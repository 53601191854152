import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-sticky-widget',
  templateUrl: './sticky-widget.component.html',
  styleUrls: ['./sticky-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyWidgetComponent {

}
