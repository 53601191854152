import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class ShipmentMetadata extends Model {
  @Expose()
  id: number;
  @Expose()
  isCheckedIn: boolean;
  @Expose()
  checkedInByAvl: boolean;
  @Expose()
  checkInDate: number;
  @Expose()
  checkInLatitude: number;
  @Expose()
  checkInLongitude: number;
}
