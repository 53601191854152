import { Expose, Type } from 'class-transformer';
import { Model } from '../../shared-features/models/model';
import { Truck } from './truck';

export class TrucksDetails extends Model {
  @Type(() => Truck)
  @Expose()
  trucks: Truck[];
  @Expose() totalCount: number;
  @Expose() totalRentedCount: number;
  @Expose() activeCount: number;
  @Expose() inActiveCount: number;
}
