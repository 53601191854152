import { Component, OnInit, Input,  ChangeDetectorRef } from '@angular/core';
import { InstallationStatus } from "@shared/enums/installation-status.enum";
import { SharedConstants } from '@shared/model/shared-constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { FilterService } from '@shared/services/filter.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { FilterAction } from '@features/filter/models/filter-action';

@Component({
  selector: 'app-drivers-filter',
  templateUrl: './drivers-filter.component.html',
  styleUrls: ['./drivers-filter.component.scss'],
})
export class DriversFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<FilterAction>;
  installationStatus = InstallationStatus;
  sharedConstants = SharedConstants;
  form: UntypedFormGroup;
  filterActionSubscription: Subscription;
  params: any = null;
  dispose$ = new Subject();
  @Input() tabGroup: TabGroup;
  @Input() tabIndex: TabIndex;
  currentTabIndex = 0;
  constructor(
    private filterService: FilterService,
    private activatedRoute: ActivatedRoute,
    
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.subscribeToCurrentTabIndex();
    this.initForm();
  }

  ngOnDestroy() {
    this.filterActionSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.dispose$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  subscribeToFilterAction() {
    this.filterActionSubscription = this.action.subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  subscribeToCurrentTabIndex() {
    this.filterService.currentTabIndex$.pipe(takeUntil(this.destroy$)).subscribe((index) => {
      this.currentTabIndex = index;
    });
  }

  getFilterValuesBasedOnURLParams() {
    return {
      appDownloadedFilter:
        this.params.appDownloadedFilter === InstallationStatus.Downloaded
          ? InstallationStatus.Downloaded
          : String(this.params.appDownloadedFilter),
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      this.form = new UntypedFormGroup({
        appDownloadedFilter: new UntypedFormControl(this.params.appDownloadedFilter ? this.params.appDownloadedFilter : SharedConstants.ALL),
      });
    } else {
      this.form = new UntypedFormGroup({
        appDownloadedFilter: new UntypedFormControl(SharedConstants.ALL),
      });
    }
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.controls['appDownloadedFilter'].setValue(filterValues.appDownloadedFilter);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.form.controls['appDownloadedFilter'].setValue(SharedConstants.ALL);
    for (const property in this.form.value) {
      if (property !== 'tabIndex' && property !== 'tabGroup' && property !== 'appDownloadedFilter') {
        this.form.controls[property].setValue(SharedConstants.ALL);
      }
      this.submitForm();
    }
  }

  submitForm() {
    const obj = this.form.value;
    obj.tabIndex = this.tabIndex;
    obj.tabGroup = this.tabGroup;
    this.filterService.navigateToTargetPageWithFilter(obj);
  }
}
