export class ChartDataColumn {
    public isParetoLine = false;
    public hide = false;
    constructor(
      public id: string,
      public label: string,
      public data: number[],
      public borderColor?: string,
      public backgroundColor?: string,
      public pointBackgroundColor?: string,
      public hoverBackgroundColor?: string,
      public isPieCenter: boolean = false,
    ) {}
  }
