import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { City } from '../../shared-features/models/city';
import { Media } from '../../shared-features/models/media';
import { TradeLaneTruckType } from './trade-lane-truck-type';
import { ContractActionItem } from "@features/contracts/enums/contract-action-item.enum";

export class TradeLane extends Model {
  @Expose() id: number;
  @Expose() name?: string;

  @Type(() => City)
  @Expose()
  fromCity: City = new City();

  @Type(() => City)
  @Expose()
  toCity: City = new City();

  @Type(() => TradeLaneTruckType)
  @Expose()
  tradeLaneTruckTypes: TradeLaneTruckType[]=[];

  @Type(() => Media)
  @Expose()
  media?: Media[];

  @Expose() note?: string;

  @Expose()
  matchedFleetsContracts?: any[];

  @Expose() createdAt?: Date;

  @Expose() updatedAt?: Date;

  /**
   * this for UI
   * it's used for collecting action types that should be sent to BE
   * in case of add/update/delete
   */
  @Expose() actionItem?: ContractActionItem;
}
