import { Expose } from 'class-transformer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';

export class InsuranceModel extends DropdownModel {
  @Expose() id: string;
  @Expose() name: string;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;

  constructor() {
    super();
  }

  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): Object {
    return this;
  }
}
