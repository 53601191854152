import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MemoizeService } from '@core/services/memoize.service';
import { OrderAPIService } from '@features/orders/services/order-api.service';
import { BaseComponent } from '../base-component/base.component';
import { OrderLineItem } from '@features/orders/models/order-line-item.model';
import { ColorPalette } from '@shared/enums/color-palette.enum';


@Component({
  selector: 'app-order-list-line-items',
  templateUrl: './order-list-line-items.component.html',
  styleUrls: ['./order-list-line-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderListLineItemsComponent extends BaseComponent implements OnChanges, AfterViewInit {
  @Input() orderId: number;
  @Output() loaded = new EventEmitter();
  lineItems: OrderLineItem[];
  constructor(
    private memoizeService: MemoizeService,
    private orderAPIService: OrderAPIService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterViewInit() {}

  ngOnChanges({ orderId }: SimpleChanges) {
    if (orderId?.currentValue !== orderId?.previousValue) {
      this.load(this.getUndetailedLineItems()).subscribe((lineItems) => {
        this.lineItems = lineItems;
        this.changeDetectorRef.markForCheck();
        this.loaded.emit();
      });
    }
  }

  getUndetailedLineItems() {
    return this.orderAPIService.getUndetailedOrderInfo({
      orderId: this.orderId,
    });
  }

  protected readonly ColorPalette = ColorPalette;
}
