import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-errors',
  template: `
    <div *ngIf="errors.length">
      <ng-container *ngFor="let error of errors">{{
        'shared.Validation.' + error.key | translate : error.projection
      }}</ng-container>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldErrorsComponent {
  @Input() errors: Record<string, { key: string; projection?: any }>[];
}
