export class StorageConstant {
  static LOCAL_STORAGE_DASHBOARD_STATUS = 'LOCAL_STORAGE_DASHBOARD_STATUS';
  static AUTH_USER = 'auth_token';
  static USER_DEVICE_KEY = 'UDK';
  static BREADCRUMB = 'breadcrumb';
  static COLUMN_OPTIONS_KEY = 'ColumnOptions';
  static DASHBOARD_ORDER_FULFILLMENT = 'DASHBOARD_ORDER_FULFILLMENT';
  static DASHBOARD_FLEET_ORDER_FULFILLMENT = 'DASHBOARD_FLEET_ORDER_FULFILLMENT';
  static DASHBOARD_FINANCIAL_KPIS = 'DASHBOARD_FINANCIAL_KPIS';
  static DASHBOARD_ORDER_STATUS = 'DASHBOARD_ORDER_STATUS';
  static DASHBOARD_TOP_DRIVERS = 'DASHBOARD_TOP_DRIVERS';
  static DASHBOARD_CUSTOMER_ORDER_FULFILLMENT_KPIS = 'DASHBOARD_CUSTOMER_ORDER_FULFILLMENT_KPIS';
  static DASHBOARD_CREATED_BY = 'DASHBOARD_CREATED_BY';
  static DASHBOARD_ASSIGNED_BY = 'DASHBOARD_ASSIGNED_BY';
  static DASHBOARD_TRADE_LANE = 'DASHBOARD_TRADE_LANE';
  static DASHBOARD_USER_INTERACTIONS = 'DASHBOARD_USER_INTERACTIONS';
  static DASHBOARD_DELIVERY_NOTES = 'DASHBOARD_DELIVERY_NOTES';
  static DASHBOARD_CONFIG = 'DASHBOARD_CONFIG';
  static DASHBOARD_TOTAL_TRUCKS = 'DASHBOARD_TOTAL_TRUCKS';
  static DASHBOARD_TOTAL_DRIVERS = 'DASHBOARD_TOTAL_DRIVERS';
  static DASHBOARD_GROWTH_ANALYSIS = 'DASHBOARD_GROWTH_ANALYSIS';
  static ANNOUNCEMENT_BAR = 'ANNOUNCEMENT_BAR';
  static LAST_REFRESH_DATE='LAST_REFRESH_DATE'
}
