import { Model } from '@shared-features/models/model';
import { Expose } from 'class-transformer';

export class RentedTruck extends Model {
  @Expose() id: number;
  @Expose() type: string;
  @Expose() plateNumber: string;
  @Expose() brandModel: string;
  @Expose() noOfOperationDays: number;
  @Expose() noOfNonOperationDays: number;
  @Expose() cost: string;
  @Expose() label: string;
  @Expose() rentalDuration: number;
}
