<app-row class="no-gutters nowrap">
  <app-col>
    <button mat-raised-button class="d-block w-100 btn--start" [color]="color" (click)="navigateToTemplate(ShipmentTemplateTypes.Basic)">
      <app-icon name="add" type="MaterialDesign" class="mr-1 unified-line-height"></app-icon>
      <span class="unified-line-height">{{'Buttons.AddNewShipment' | translate}}</span>
    </button>
  </app-col>
  <app-col cols="auto">
    <button mat-raised-button [matMenuTriggerFor]="templatesMenu" class="btn--end px-2 mw-auto" [color]="color">
      <app-icon name="keyboard_arrow_down" type="MaterialDesign" class="unified-line-height"></app-icon>
    </button>
  </app-col>
</app-row>

<mat-menu #templatesMenu="matMenu" xPosition="before">
  <ng-container *ngFor="let template of ShipmentTemplateTypes | enumToArray">
    <button *ngIf="template.value !== ShipmentTemplateTypes.Basic" mat-menu-item (click)="navigateToTemplate(ShipmentTemplateTypes[template.key])">
      {{('Enum.ShipmentCreationTemplateTypes.' + template.value) | translate}}
    </button>
  </ng-container>
</mat-menu>
