import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { TabModel } from '@shared/model/tab.model';

@Component({
  selector: 'app-trapezoid-tabs-layout',
  templateUrl: './trapezoid-tabs-layout.component.html',
  styleUrls: ['./trapezoid-tabs-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrapezoidTabsLayoutComponent implements OnChanges{
  @Input() tabs: TabModel[] = [];
  @Input() selectedTabIndex: number;
  @Output() selectedTabIndexChange = new EventEmitter<number>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    this.changeDetectorRef.markForCheck();
  }
}
