import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { IconType } from '@shared/enums/icon-type.enum';
import { Icon } from '@shared/model/icon.model';

@Component({
  selector: 'app-labeled-area',
  templateUrl: './labeled-area.component.html',
  styleUrls: ['./labeled-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'labeled-area' },
})
export class LabeledAreaComponent implements OnChanges {
  @Input() icon: Icon;
  @Input() label: string;
  @Input() color: ThemePalette | 'success' = 'accent';
  @HostBinding('class.labeled-area--primary') get isPrimary() {
    return this.color === 'primary';
  }
  @HostBinding('class.labeled-area--accent') get isAccent() {
    return this.color === 'accent';
  }
  @HostBinding('class.labeled-area--warn') get isWarn() {
    return this.color === 'warn';
  }
  @HostBinding('class.labeled-area--success') get isSuccess() {
    return this.color === 'success';
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.changeDetectorRef.markForCheck();
  }

  protected readonly IconType = IconType;
}
