import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import debounce from 'lodash/debounce';

@Component({
  selector: 'app-entity-listing',
  templateUrl: './entity-listing.component.html',
  styleUrls: ['./entity-listing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityListingComponent implements OnInit, OnChanges {
  @Input() state: SectionStateStatus;
  @Input() emptyStateMainLabel: string;
  @Input() emptyStateSubLabel: string;
  @Input() errorStateLabel: string;
  @Input() pageSize = 0;
  @Input() pageIndex = 0;
  @Input() totalCount = 0;
  @Input() addTopSpace = true;
  @Output() pageIndexChanged = new EventEmitter();
  @Output() displayed = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() pageSizeChange = new EventEmitter();
  @Input() selectionEnabled = false;
  sectionState = SectionStateStatus;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.displayed.emit();
  }

  ngOnChanges(): void {
    debounce(() => this.changeDetector.markForCheck(), 800);
  }

  onPageIndexChange(index: number): void {
    this.pageIndexChanged.emit(index);
  }
  onPageSizeChange(value: number): void {
    this.pageSizeChange.emit(value);
  }
  get displayedCount(): number {
    let display = this.pageSize * this.pageIndex;
    if (display > this.totalCount) {
      display = this.totalCount;
    }
    return display;
  }
}
