<app-state-section
  [state]="sectionState"
  [formGroup]="formGroup">

  <mat-label class="d-block w-100">
    {{'shared.components.ColumnOptions.ColumnSettingName' | translate}}
  </mat-label>
<mat-form-field
  appearance="outline"
  class="w-100"
>
  <input type="text" matInput formControlName="name">
</mat-form-field>
<app-column-options-field-list
  *ngIf="data?.id"
  [state]="data?.state"
  [keys]="data?.keys"
  [formGroup]="formGroup"
  (stateUpdate)="handleStateChange($event)"
></app-column-options-field-list>
</app-state-section>
