<ng-container [ngSwitch]="theme">
  <button
    *ngSwitchCase="ButtonTheme.Basic"
    mat-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>


  <button
    *ngSwitchCase="undefined"
    mat-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    *ngSwitchCase="ButtonTheme.Raised"
    mat-raised-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
  <button
    *ngSwitchCase="ButtonTheme.Stroked"
    mat-stroked-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    *ngSwitchCase="ButtonTheme.Flat"
    mat-flat-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    *ngSwitchCase="ButtonTheme.Fab"
    mat-fab
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    *ngSwitchCase="ButtonTheme.Icon"
    mat-icon-button
    [type]="type || 'button'"
    [disabled]="disabled"
    [ngClass]="[buttonClass]"
    [class.btn--small]="size === 'sm'"
    [class.btn--square]="square"
    [color]="buttonColor">
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</ng-container>


<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>
