import { Expose, Type } from 'class-transformer';

import { Customer } from '../../customers/models/customer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { User } from '@features/users/models/user';

export class Organization extends DropdownModel {
  @Expose() id: number;
  @Expose() code = '';
  @Expose({ name: 'title' }) name: string;
  @Expose() email: string;
  @Expose() contactFirstName: string;
  @Expose() contactLastName: string;
  @Expose() mobileNumber: string;
  @Type(() => Customer)
  @Expose()
  customers: Customer[];
  @Expose() customersCount: number;
  @Expose() status: string;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;
  @Expose() usersCount: number;
  @Type(() => User)
  @Expose()
  users: User[] = [];

  constructor() {
    super();
  }
  get fullContactName(): string {
    let fullName;
    if (this.contactFirstName && this.contactLastName) fullName = this.contactFirstName + ' ' + this.contactLastName;
    else if (this.contactFirstName) fullName = this.contactFirstName;
    else if (this.contactLastName) fullName = this.contactLastName;
    return fullName;
  }
  get dropDownDisplayName(): string {
    return this.name;
  }
  get dropDownValue(): object {
    return this;
  }
}
