/**
 * Labels is the translated word for each breadcrumb item
 */
export enum BreadcrumbLabels {
  Dashboard = 'Dashboard',

  Shipments = 'Shipments',
  ShipmentsView = 'ViewShipment',

  Users = 'Users',
  UsersView = 'User',
  UsersAdd = 'AddUser',

  Receivers = 'ManageReceivers',
  ReceiversView = 'Receiver',
  ReceiversAdd = 'AddReceiver',

  Customers = 'Customers',
  CustomersMyCustomers = 'MyCustomers',
  CustomersView = 'Customer',
  CustomersAdd = 'AddCustomer',
  CustomersVerify = 'VerifyCustomer',
  CustomersSupport = 'CustomersSupport',
  BusinessUnits = 'BusinessUnits',

  Invoices = 'Invoices',
  InvoicesView = 'Invoice',
  InvoicesAdd = 'AddInvoice',

  Providers = 'Providers',
  ProvidersView = 'Provider',
  ProvidersAdd = 'AddProvider',
  ProvidersAddBulk = 'AddBulk',
  ProvidersRequest = 'Request',

  Drivers = 'Drivers',
  DriversView = 'Driver',
  DriversAdd = 'AddDriver',

  Trucks = 'Trucks',
  TrucksRented = 'RentedTrucks',
  TrucksView = 'Truck',
  TrucksAdd = 'AddTruck',

  Trailers = 'Trailers',
  TrailersView = 'Trailer',
  TrailersAdd = 'AddTrailer',

  TripPlanning = 'TripPlanning',
  TripPlanningRegularTrucks = 'RegularTrucks',
  TripPlanningRentalTrucks = 'RentalTrucks',

  LiveTracking = 'LiveTracking',
  Orders = 'Orders',

  // Manage
  Settings = 'Settings',
  PreviewOrders = 'PreviewOrders',

  Contracts = 'Contracts',
  ContractsView = 'ContractsView',
  ContractsAdd = 'ContractsAdd',
  ContractsEdit = 'ContractsEdit',
  ContractsTradeLanesAdd = 'ContractsTradeLanesAdd',
  ContractsTradeLanesEdit = 'ContractsTradeLanesEdit',

  Tickets = 'Tickets',
}
