import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { TabModel } from '../../model/tab.model';
import { LayoutService } from '@shared/services/layout.service';
import { Justification } from '../grid/row.component';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnChanges {
  @Input() selectedIndex = 0;
  @Input() tabs: TabModel[] = [];
  @Output() currentIndex = new EventEmitter<number>();
  @Input() stretchTabs = true;
  @Input() alignTabs: 'start' | 'center' | 'end' = 'center';
  @Input() justify: Justification;
  @Input() theme: 'underline' | 'trapezoid' = 'underline';

  constructor(public layoutService: LayoutService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.currentIndex.emit(this.selectedIndex);
  }

  ngOnChanges(): void {
    this.changeDetector.markForCheck();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentIndex.emit(tabChangeEvent.index);
  }
}
