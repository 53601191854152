<div class="container flex column">
  <div class="content flex">
    <app-state-section
      [state]="sectionState"
      [emptyStateSubLabel]="'Notifications.EmptyLabel' | translate"
      [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate"
    >
      <div class="list flex column">
        <div class="period-section flex column">
          <span class="title">{{ 'Notifications.Title' | translate }}</span>
          <app-notification-item
            class="item"
            *ngFor="let notification of notifications"
            [notification]="notification"
            (notificationClicked)="onNotificationClicked(notification)"
          ></app-notification-item>

          <div class="pt-4" *ngIf="hasMoreNotifications">
            <button mat-raised-button color="primary" class="d-block w-100" (click)="loadMore()">
              {{ 'Notifications.LoadMore' | translate }}
            </button>
          </div>
        </div>
      </div>
    </app-state-section>
  </div>
</div>
