import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { Expose } from 'class-transformer';
import { BusinessType } from "@features/users/enums/business-type.enum";

export class ContactPerson extends DropdownModel {
  @Expose() id?: number;
  @Expose() name?: string = '';
  @Expose() firstName?: string = '';
  @Expose() lastName?: string = '';
  @Expose() mobileNumber?: string = '';
  @Expose() email?: string = '';
  @Expose() isDefault?: boolean = false;
  @Expose() businessType?: BusinessType;
  @Expose() contactType?: string;

  constructor() {
    super();
  }

   fullName?(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get dropDownDisplayName(): string {
    return this.name ? this.name : (this.firstName + ' ' + this.lastName);
  }

  get dropDownValue(): Object {
    return this;
  }
}
