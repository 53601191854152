import { EnumModel } from '@shared/model/enum-model';

export function enumToArray(value: { [k: string]: any }): EnumModel[] {
  const result: EnumModel[] = [];
  const keys = Object.keys(value);
  const values = Object.values(value);
  for (let i = 0; i < keys.length; i++) {
    result.push(new EnumModel(keys[i], values[i]));
  }
  return result;
}
