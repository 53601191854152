import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { DisconnectedState } from './bloc-state';
import { Drawing } from '@shared-features/models/drawing';

export abstract class Bloc<BlocEvent, BlocState>  {

    protected onState$ = new BehaviorSubject<BlocState>(null);
    protected setEvent$ = new BehaviorSubject<BlocEvent>(null);
    protected destroy$ = new Subject();
    private currentState: BlocState;

    constructor() {
        this.setEvent.subscribe((event: BlocEvent) => this.handleIncomingEvents(event));
        this.onState$.subscribe(state => this.currentState = state);
    }

    get onState(): Observable<BlocState> {
        return this.onState$.asObservable();
    }

    get setEvent(): BehaviorSubject<BlocEvent> {
        return this.setEvent$;
    }

    dispose(): void {
        this.onState$.complete();
        this.setEvent$.complete();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private handleIncomingEvents(event: BlocEvent) {
        if (!event) { return; }
        if (!this.checkNetworkConnection()) { return; }
        this.onIncomingEvents(event, this.currentState);
    }

    private checkNetworkConnection(): boolean {
        const isConnected = navigator.onLine;
        if (!isConnected) {
            this.onState$.next((new DisconnectedState(new Drawing()) as any));
        }
        return isConnected;
    }

    protected abstract onIncomingEvents(event: BlocEvent, currentState: BlocState);
}
