import { Expose } from 'class-transformer';
import { DropdownModel } from './dropdown-model';

export class CityArea extends DropdownModel {
    @Expose() id: number;
    @Expose() name: string;

    get dropDownDisplayName(): string {
      return this.name
    }

    get dropDownValue(): object {
      return this;
    }

}
