import { RolesConstants } from '@shared/model/roles-constants';
import { UserRoleService } from '@core/services/user-role.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { LayoutService } from '@shared/services/layout.service';
import { LanguageService } from '@shared/services/language.service';
import { SharedConstants } from '@shared/model/shared-constants';
import { NavBarItem } from '@shared/model/nav-bar-item';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { UserRole } from '@features/users/enums/user-role.enum';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { StorageConstant } from '@shared/constants/storage.constant';
import { FeatureFlagService } from '@core/services/feature-flag.service';
import { DashboardGlobalStateService } from '@features/dashboard/services/dashboard-global-state.service';
import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';
@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
  public navigationItems: NavBarItem[];
  public showOutlet = false;

  private destroy$ = new Subject();

  hideData = this.dashboardGlobalStateService.config?.hidePrice;

  constructor(
    public layoutService: LayoutService,
    public languageService: LanguageService,
    private translate: TranslateService,
    private userRoleService: UserRoleService,
    private dashboardGlobalStateService: DashboardGlobalStateService,
    private featureFlagService: FeatureFlagService
  ) {
    this.setupNavBarItems();
    this.layoutService.websiteDirection$.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.setupNavBarItems();
    });
  }

  ngOnInit(): void {
    LocalStorageUtil.changes()
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(() => {
        const hideData = this.dashboardGlobalStateService.config?.hidePrice;
        if (this.hideData !== hideData) {
          this.hideData = hideData;
          this.setupNavBarItems();
        }
      });
    this.showOutlet = true;
    this.languageService.languageChanged.pipe(takeUntil(this.destroy$)).subscribe((_) => {
      this.showOutlet = false;
      setTimeout(() => {
        this.showOutlet = true;
      }, 100);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setupNavBarItems(): void {
    combineLatest([this.translate.get(['NavigationBar']), this.featureFlagService.flags$])
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(([translations]: any) => {
        const { NavigationBar } = translations;
        const { SubMenu } = NavigationBar || {};

        const aggregatorSubmenu = [
          {
            key: SharedConstants.NAVIGATION_KEY_B2C_SHIPMENTS,
            pageTitle: this.userRoleService.isUserHasRoles([UserRole.Admin_B2C])
              ? NavigationBar.Shipments
              : NavigationBar.B2cShipments,
            title: this.userRoleService.isUserHasRoles([UserRole.Admin_B2C])
              ? NavigationBar.Shipments
              : NavigationBar.B2cShipments,
            path: RoutesUtil.ShipmentsB2c.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.B2C_SHIPMENTS_VIEW),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_CUSTOMERS,
            pageTitle: NavigationBar.Sellers,
            title: NavigationBar.Sellers,
            path: RoutesUtil.Sellers.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.SELLER_VIEW),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_FLEETSB2C,
            pageTitle: NavigationBar.FleetsB2c,
            title: NavigationBar.FleetsB2c,
            path: RoutesUtil.FleetsB2c.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.FLEETSB2C_VIEW),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_B2C_ZONES,
            pageTitle: NavigationBar.Zones,
            title: NavigationBar.Zones,
            path: RoutesUtil.ZonesB2C.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.B2C_ZONES_VIEW),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_B2C_RULES,
            pageTitle: NavigationBar.Rules,
            title: NavigationBar.Rules,
            path: RoutesUtil.RulesB2C.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.B2C_RULES_VIEW),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_B2C_CONFIGURATION,
            pageTitle: NavigationBar.Configuration,
            title: NavigationBar.Configuration,
            path: RoutesUtil.ConfigurationB2c.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.B2C_CONFIGURATION_VIEW),
          },
          // {
          //   key: SharedConstants.NAVIGATION_KEY_LIVE_TRACKING,
          //   pageTitle: SubMenu.LiveTrackingB2c,
          //   title: SubMenu.LiveTrackingB2c,
          //   path: RoutesUtil.B2cLiveTracking.url(),
          //   isSelected: false,
          //   isVisible: this.userRoleService.isUserHasRoles(RolesConstants.B2C_LIVE_TRACKING_VIEW),
          // },
        ];

        const invoicesSubmenu = [
          {
            key: SharedConstants.NAVIGATION_KEY_CUSTOMER_INVOICES,
            pageTitle: NavigationBar.CustomerInvoices,
            title: NavigationBar.CustomerInvoices,
            path: RoutesUtil.InvoicesCustomer.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.INVOICES),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_FLEET_INVOICES,
            pageTitle: NavigationBar.FleetInvoices,
            title: NavigationBar.FleetInvoices,
            path: RoutesUtil.InvoicesFleet.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.TRANSPORTATION_PAYMENTS),
          },
        ];
        const transportationPaymentSubmenu = [
          {
            key: SharedConstants.NAVIGATION_KEY_SHIPMENTS,
            pageTitle: NavigationBar.Shipments,
            title: NavigationBar.Shipments,
            path: RoutesUtil.TransPaysShipments.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.TRANSPORTATION_PAYMENTS),
          },
          {
            key: SharedConstants.NAVIGATION_KEY_PAYMENTS,
            pageTitle: NavigationBar.Payments,
            title: NavigationBar.Payments,
            path: RoutesUtil.TransPaysPayments.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.TRANSPORTATION_PAYMENTS),
          },
        ];

        this.navigationItems = [
          {
            key: SharedConstants.NAVIGATION_KEY_DASHBOARD,
            pageTitle: NavigationBar.Dashboard,
            title: NavigationBar.Dashboard,
            path: RoutesUtil.Dashboard.url() || SharedConstants.HOME_PATH,
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.DASHBOARD_VIEW),
            icon: 'home',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_SHIPMENTS,
            pageTitle: this.userRoleService.isUserHasRoles([UserRole.Admin, UserRole.RentalManager])
              ? NavigationBar.B2bShipments
              : NavigationBar.Shipments,
            title: this.userRoleService.isUserHasRoles([UserRole.Admin, UserRole.RentalManager])
              ? NavigationBar.B2bShipments
              : NavigationBar.Shipments,
            path: RoutesUtil.B2bShipmentsList.url({ type: ShipmentsListType.All }),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.SHIPMENT_VIEW),
            icon: 'shipments',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_MY_SHIPMENTS,
            pageTitle: NavigationBar.MyShipments,
            title: NavigationBar.MyShipments,
            path: RoutesUtil.B2bShipmentsList.url({ type: ShipmentsListType.MyShipemnts }),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.MY_SHIPMENTS_VIEW),
            icon: 'shipments',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_SALES_ORDERS,
            pageTitle: NavigationBar.OrdersManagement,
            title: NavigationBar.OrdersManagement,
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.SALES_ORDERS_ADD),
            icon: 'shipments',
            subMenu: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS)
              ? [
                  {
                    key: SharedConstants.NAVIGATION_KEY_SALES_ORDERS,
                    pageTitle: SubMenu.SalesOrders,
                    title: SubMenu.SalesOrders,
                    path: RoutesUtil.SalesOrders.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.SALES_ORDERS_ADD),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_ORDERS,
                    pageTitle: SubMenu.Orders,
                    title: SubMenu.Orders,
                    path: RoutesUtil.Orders.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.ORDER_VIEW),
                  },
                ]
              : null,
          },
          {
            key: SharedConstants.NAVIGATION_KEY_TRIP_PLANNING,
            pageTitle: translations.NavigationBar.TripPlanning,
            title: translations.NavigationBar.TripPlanning,
            path: RoutesUtil.TripPlanning.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.TRIP_PLANNING_VIEW),
            icon: 'trip-planning',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_CUSTOMERS,
            pageTitle: this.userRoleService.isUserHasRoles([UserRole.Admin, UserRole.RentalManager])
              ? NavigationBar.B2BCustomers
              : NavigationBar.Customers,
            title: this.userRoleService.isUserHasRoles([UserRole.Admin, UserRole.RentalManager])
              ? NavigationBar.B2BCustomers
              : NavigationBar.Customers,
            path: RoutesUtil.CustomersB2B.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.CUSTOMER_VIEW),
            icon: 'customers',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_FLEET_PROVIDERS,
            pageTitle: NavigationBar.FleetProviders,
            title: NavigationBar.FleetProviders,
            path: RoutesUtil.Fleets.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.FLEET_VIEW),
            icon: 'fleet',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_MADAR_PROVIDERS,
            pageTitle: NavigationBar.MadarProviders,
            title: NavigationBar.MadarProviders,
            path: RoutesUtil.FleetsMadar.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.FLEET_VIEW),
            icon: 'madar-providers',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_LIVE_TRACKING,
            pageTitle: SubMenu.LiveTracking,
            title: SubMenu.LiveTracking,
            path: RoutesUtil.LiveTracking.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.LIVE_TRACKING_VIEW),
            icon: 'live-tracking',
          },
          {
            key: SharedConstants.NAVIGATION_KEY_INVOICES,
            pageTitle: NavigationBar.Invoices,
            title: NavigationBar.Invoices,
            path: RoutesUtil.InvoicesCustomer.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.INVOICES),
            icon: 'invoices',
            subMenu: invoicesSubmenu,
            showSubMenu: false,
          },
          {
            key: SharedConstants.NAVIGATION_KEY_TRANSPORTATION_PAYMENT,
            pageTitle: NavigationBar.TransportationPayments,
            title: NavigationBar.TransportationPayments,
            path: RoutesUtil.TransPays.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.TRANSPORTATION_PAYMENTS),
            icon: 'transportation-payments',
            subMenu: transportationPaymentSubmenu,
            showSubMenu: false,
          },
          ...(this.userRoleService.isUserHasRoles(RolesConstants.AGGREGATOR)
            ? []
            : aggregatorSubmenu),
          {
            key: SharedConstants.NAVIGATION_KEY_AGGREGATOR,
            pageTitle: NavigationBar.Aggregator,
            title: NavigationBar.Aggregator,
            path: RoutesUtil.ShipmentsB2c.url(),
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.AGGREGATOR),
            icon: 'aggregator',
            showSubMenu: false,
            subMenu: this.userRoleService.isUserHasRoles(RolesConstants.AGGREGATOR)
              ? aggregatorSubmenu
              : null,
          },
          {
            key: SharedConstants.NAVIGATION_KEY_REPORTS,
            pageTitle: NavigationBar.Reports,
            title: NavigationBar.Reports,
            path: SharedConstants.REPORTS_PATH,
            isSelected: false,
            isVisible:
              this.userRoleService.isUserHasRoles(RolesConstants.REPORTS) && !this.hideData,
            icon: 'reports',
            showSubMenu: false,
            subMenu: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS)
              ? [
                  {
                    key: SharedConstants.NAVIGATION_KEY_CONSOLIDATION_REPORT,
                    pageTitle: SubMenu.Consolidation,
                    title: SubMenu.Consolidation,
                    path: RoutesUtil.ReportsConsolidation.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_SHIPMENTS_REPORT,
                    pageTitle: NavigationBar.Shipments,
                    title: NavigationBar.Shipments,
                    path: RoutesUtil.ReportsShipments.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_SHIPMENT_INVOICES_REPORT,
                    pageTitle: NavigationBar.ShipmentInvoices,
                    title: NavigationBar.ShipmentInvoices,
                    path: RoutesUtil.ReportsShipmentInvoices.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_DRIVERS_REPORT,
                    pageTitle: SubMenu.Driver,
                    title: SubMenu.Driver,
                    path: RoutesUtil.ReportsDrivers.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_TRUCKS_REPORT,
                    pageTitle: SubMenu.Truck,
                    title: SubMenu.Truck,
                    path: RoutesUtil.ReportsTrucks.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_WASL_REPORT,
                    pageTitle: SubMenu.Wasl,
                    title: SubMenu.Wasl,
                    path: RoutesUtil.ReportsWasl.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_AVL_REPORT,
                    pageTitle: SubMenu.AVL,
                    title: SubMenu.AVL,
                    path: RoutesUtil.ReportsAVL.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_CEMENT_REPORT,
                    pageTitle: SubMenu.Cement,
                    title: SubMenu.Cement,
                    path: RoutesUtil.ReportsCement.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_CONTRACT_REPORT,
                    pageTitle: SubMenu.Contract,
                    title: SubMenu.Contract,
                    path: RoutesUtil.ReportsContract.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_G2G_REPORT,
                    pageTitle: NavigationBar.G2G,
                    title: NavigationBar.G2G,
                    path: RoutesUtil.ReportsG2G.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_G2G_INTEGRATION,
                    pageTitle: NavigationBar.G2GIntegration,
                    title: NavigationBar.G2GIntegration,
                    path: RoutesUtil.ReportsG2gIntegration.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                  {
                    key: SharedConstants.NAVIGATION_KEY_TRUCK_UTILIZATION_REPORT,
                    pageTitle: NavigationBar.TruckUtilization,
                    title: NavigationBar.TruckUtilization,
                    path: RoutesUtil.ReportsTruckUtilization.url(),
                    isSelected: false,
                    isVisible: this.userRoleService.isUserHasRoles(RolesConstants.REPORTS),
                  },
                ]
              : null,
          },
          {
            key: SharedConstants.NAVIGATION_KEY_MANAGE,
            pageTitle: NavigationBar.Manage,
            title: NavigationBar.Manage,
            path: SharedConstants.MANAGE_PATH,
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.MANAGE_VIEW),
            icon: 'manage',
            showSubMenu: false,
            subMenu: [
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_USERS,
                pageTitle: SubMenu.Users,
                title: SubMenu.Users,
                path: SharedConstants.USERS_PATH,
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.USER_VIEW),
                activePaths: [RoutesUtil.Users.url(), RoutesUtil.UsersAdd.url()],
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_LEADS,
                pageTitle: SubMenu.Leads,
                title: SubMenu.Leads,
                path: SharedConstants.LEADS_PATH,
                isSelected: this.userRoleService.isUserHasRoles(RolesConstants.LEAD_VIEW),
                isVisible: true,
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_RECEIVERS,
                pageTitle: SubMenu.Receivers,
                title: SubMenu.Receivers,
                path: RoutesUtil.Receivers.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.GLOBAL_RECEIVER_VIEW),
                activePaths: [RoutesUtil.ReceiversAdd.url()],
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_DELIVERY_NOTES,
                pageTitle: SubMenu.DeliveryNotes,
                title: SubMenu.DeliveryNotes,
                path: RoutesUtil.DeliveryNotes.url(),
                isSelected: false,
                isVisible: true,
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_ORGANIZATIONS,
                pageTitle: SubMenu.Organizations,
                title: SubMenu.Organizations,
                path: RoutesUtil.Organizations.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.ORGANIZATION_VIEW),
                activePaths: [RoutesUtil.OrganizationsAdd.url(), RoutesUtil.Organizations.url()],
              },
              {
                key: SharedConstants.NAVIGATION_KEY_TRIP_PERFORMANCE,
                pageTitle: SubMenu.TripPerformance,
                title: SubMenu.TripPerformance,
                path: RoutesUtil.TripPerformance.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(
                  RolesConstants.TRIP_PERFORMANCE_VIEW
                ),
                activePaths: [
                  RoutesUtil.TripPerformanceAdd.url(),
                  RoutesUtil.TripPerformance.url(),
                ],
              },
              {
                key: SharedConstants.NAVIGATION_KEY_SMART_CENTER,
                pageTitle: translations['NavigationBar']['SubMenu']['SmartCenter'],
                title: translations['NavigationBar']['SubMenu']['SmartCenter'],
                path: RoutesUtil.SmartCenter.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.SMART_CENTER_VIEW),
              },
              {
                key: SharedConstants.NAVIGATION_KEY_RENTAL_PROFIT,
                pageTitle: translations['NavigationBar']['SubMenu']['RentalProfit'],
                title: translations['NavigationBar']['SubMenu']['RentalProfit'],
                path: RoutesUtil.RentalProfit.url(),
                isSelected: false,
                isVisible: true,
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_RELEASE_NOTES,
                pageTitle: SubMenu.ReleaseNotes,
                title: SubMenu.ReleaseNotes,
                path: RoutesUtil.ReleaseNotes.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.VIEW_RELEASE_NOTES),
                activePaths: [RoutesUtil.ReleaseNotesAdd.url(), RoutesUtil.ReleaseNotes.url()],
              },
            ],
          },
          {
            key: SharedConstants.NAVIGATION_KEY_LOGS,
            pageTitle: NavigationBar.Logs,
            title: NavigationBar.Logs,
            path: SharedConstants.LOGS_PATH,
            isSelected: false,
            isVisible: this.userRoleService.isUserHasRoles(RolesConstants.LOGS_VIEW),
            icon: 'logs',
            showSubMenu: false,
            subMenu: [
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_B2C_LOGS,
                pageTitle: SubMenu['RequestLogs(B2C)'],
                title: SubMenu['RequestLogs(B2C)'],
                path: RoutesUtil.LogsShipmentRequests.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(
                  RolesConstants.B2C_SHIPMENT_REQUESTS_VIEW
                ),
                activePaths: [RoutesUtil.LogsShipmentRequests.url()],
              },
              {
                key: SharedConstants.NAVIGATION_KEY_MANAGE_EXCEPTION_LOGS,
                pageTitle: SubMenu.ExceptionLogs,
                title: SubMenu.ExceptionLogs,
                path: RoutesUtil.LogsExceptions.url(),
                isSelected: false,
                isVisible: this.userRoleService.isUserHasRoles(RolesConstants.EXCEPTION_LOGS_VIEW),
                activePaths: [RoutesUtil.LogsExceptions.url()],
              },
            ],
          },
        ];
      });
  }
}
