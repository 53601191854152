import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  ViewEncapsulation,
} from '@angular/core';
import { IconType } from '@shared/enums/icon-type.enum';
import { SvgIconType } from '@ngneat/svg-icon/lib/providers';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'icon',
  },
})
export class IconComponent implements OnChanges {
  @Input() name: string;
  @Input() type = IconType.SVG;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @HostBinding('class') get iconSize(): string {
    const sizes = { sm: 'icon--xsmall', md: 'icon--small', lg: '', xl: 'icon--large' };
    return this.size ? sizes[this.size] : sizes.md;
  }
  protected readonly IconType = IconType;
  nameAsIconType: SvgIconType;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
  }
}
