<div
  class="timeline__item timeline--avatar-bullet"
  *ngFor="let item of items; let i = index; last as isLast; first as isFirst"
  [style]="{'--avatar-size': (avatarSize / 16) + 'rem'}"
>
  <div class="timeline__bullet">
    <ng-container
      *ngTemplateOutlet="customTemplates[ActivityLogTemplate.User] || defaultUserTemplate; context: { $implicit: item, name: names[i], avatar: avatars[i] }"
    ></ng-container>
  </div>

  <div class="timeline__item-content">
    <div class="weight-medium text-900 h6 mb-1">
      <ng-container
        *ngTemplateOutlet="customTemplates[ActivityLogTemplate.Title] || defaultTitleTemplate; context: { $implicit: item, name: names[i] }"
      ></ng-container>
    </div>

    <div class="timeline__description small text-400">
      <ng-container
        *ngTemplateOutlet="customTemplates[ActivityLogTemplate.Subtitle] || defaultSubTitleTemplate; context: { $implicit: item }"
      ></ng-container>
      <ng-container
        *ngTemplateOutlet="customTemplates[ActivityLogTemplate.Date] || defaultDateTemplate; context: { $implicit: item, date: dates[i] }"
      ></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultUserTemplate let-item let-avatar="avatar" let-name="name">
  <app-avatar
    [size]="avatarSize"
    class="timeline__avatar text-semibold"
    bgColor="var(--color-gray-200)"
    fgColor="var(--color-gray-500)"
    [name]="name"
    [src]="avatar"
  ></app-avatar>
</ng-template>
<ng-template #defaultTitleTemplate let-item let-name="name">{{name}}</ng-template>
<ng-template #defaultSubTitleTemplate let-item></ng-template>
<ng-template #defaultDateTemplate let-item let-date="date">{{date | date}} | {{date | date:'shortTime' }}</ng-template>
