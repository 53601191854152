<mat-form-field
  [appearance]="appearance"
  class="mb-0 pb-0 form-field"
  [ngClass]="{
    'form-field--small': size === 'sm',
    'readonly': readonlyState,
    'theme--disabled': disabled,
    'form-field--unbounded': theme === 'unbounded',
    'theme--highlighted': theme === 'highlighted',
    'theme--outlined': theme === 'outlined'
    }"
>
  <div class="form-field__prefix" matPrefix>
    <span [hidden]="multiple && selectedCount">
      <ng-content select="[prefix]"></ng-content>
    </span>
    <span class="lazy-dropdown__rect" *ngIf="multiple && selectedCount">{{selectedCount}}</span>
  </div>
  <div class="form-field__suffix" matSuffix>
    <app-row>
      <app-col cols="auto" *ngIf="loading">
        <app-spinner [small]="true"></app-spinner>
      </app-col>
      <app-col cols="auto">
        <ng-content select="[suffix]"></ng-content>
      </app-col>
    </app-row>
  </div>
  <mat-select
    #matSelect
    [panelClass]="'select--has-min-width'"
    [multiple]="multiple"
    [formControl]="valueControl"
    [placeholder]="placeholder | translate"
    (openedChange)="handleOpenChange($event)"
  >
    <mat-select-trigger #matSelectTrigger>
      <div class="row gx-xs align-center">
        <div class="col-auto">
          <span [ngClass]="{'weight-semibold': highlightSelection }">{{displayText | translate}}</span>
        </div>

        <div class="col-auto ml-auto" *ngIf="clearable">
          <app-icon name="close" type="MaterialDesign" (click)="clearValue($event)" size="sm" class="align-middle text-300 mr-1"></app-icon>
        </div>
      </div>
    </mat-select-trigger>

    <div class="dropdown dropdown--lazy scrollable scrollable--y" #scrollElement>
      <app-dropdown-search class="dropdown__search" [ngModel]="searchTerm$.value" (ngModelChange)="searchTerm$.next($event)"></app-dropdown-search>

      <div class="dropdown__options" *ngIf="optionIds?.length">
        <mat-option
          *ngFor="let optionId of optionIds; let i = index;"
          class="dropdown__option"
          [class.dropdown__option--highlighted]="options[optionId].highlighted"
          [class.dropdown__option--hidden]="!!(searchTerm$ | async)?.toString().trim() && optionId === allOption?.id && !allOption?.selected"
          [value]="optionId"
          [disabled]="options[optionId].disabled"
        >
          <ng-container *ngIf="optionLabelDirective; else defaultOptionLabel">
            <ng-container
              *ngTemplateOutlet="optionLabelDirective.templateRef; context: { $implicit: options[optionId]?.option, index: i }"
            ></ng-container>
          </ng-container>

          <ng-template #defaultOptionLabel>
            <span>{{(options[optionId].display || '') | translate}}</span>
          </ng-template>
        </mat-option>
      </div>

      <div class="dropdown__loading pb-2" #loadMoreTrigger>
        <div class="flex align-center justify-center" *ngIf="loading">
          <app-spinner [small]="true"></app-spinner>
        </div>
        <div class="flex align-center text-600 weight-semibold pb-1 pt-6" *ngIf="endOfResult">
          <small class="px-3">{{'General.noMoreResult' | translate}}</small>
          <small class="text-underline text-primary px-3 py-2 ml-auto text-btn" (click)="forceUpdate()">
            {{'General.DontFindNewData' | translate}}
          </small>
        </div>
      </div>
    </div>
  </mat-select>
</mat-form-field>

<div class="error-handler">
  <div class="row align-center">
    <div class="col-auto mr-auto">
      <ng-content select="[error], mat-error, error"></ng-content>
    </div>
    <div class="col-auto ml-auto">
      <mat-hint *ngIf="!optionIds?.length && !loading">{{'General.EmptyList' | translate}}</mat-hint>
    </div>
  </div>
</div>
