import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';


export class UserDevice extends Model {
  @Expose() deviceId: string = '';
  @Expose() appVersion: string = '';
  @Expose() osVersion: string = '';
  @Expose() deviceModel: string = '';
  @Expose() notificationToken: string = '';
  @Expose() osType: string = 'WEB';
  @Expose() languageIsoCode: string = 'en';

  constructor(){
    super();
  }

}
