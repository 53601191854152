import { Pipe, PipeTransform } from '@angular/core';
import { EnumModel } from '../model/enum-model';
import { enumToArray } from '@shared/utils/enum-to-array.util';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  transform(value: object): EnumModel[] {
    return enumToArray(value);
  }

}
