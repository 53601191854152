import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, combineLatest } from 'rxjs';
import { filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { Confirmation, ConfirmationConfig, ConfirmationType } from '../../model/confirmation';
import { ConfirmationService } from '@shared/services/confirmation.service';
import { BackgroundScrollService } from '@shared/services/background-scroll.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '@shared/services/language.service';
import { IconType } from "@shared/enums/icon-type.enum";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit, OnDestroy {
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  confirmData: ConfirmationConfig = new Confirmation();

  protected readonly IconType = IconType;
  protected readonly ConfirmationType = ConfirmationType;
  private dispose$ = new Subject();
  private dialogRefs: { [id: string]: MatDialogRef<any> } = {};
  form: FormGroup;

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private backgroundScrollService: BackgroundScrollService,
    private dialogService: MatDialog,
    private languageService: LanguageService,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.dispose$.next(true);
    this.dispose$.complete();
  }

  onClickReject(dialogId) {
    this.confirmationService.onConfirmMessageAction(false, false);
    this.dialogRefs[dialogId]?.close();
    this.backgroundScrollService.setScrollState(true);
    this.changeDetectorRef.markForCheck();
  }

  onClickConfirm(dialogId, checkboxValue) {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      return;
    }
    const textAreaValueToSend = this.form.value.textarea;
    this.confirmationService.setTextareaValue(textAreaValueToSend);
    this.confirmationService.onConfirmMessageAction(true, checkboxValue);
    this.dialogRefs[dialogId]?.close();
    this.backgroundScrollService.setScrollState(true);
    this.form?.get('textarea')?.setValue(null);
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    combineLatest([
      this.confirmationService.confirmationMessage$,
      this.translate.onLangChange.pipe(startWith(true) , switchMap(() => this.translate.get(['shared']))),
    ])
      .pipe(
        takeUntil(this.dispose$),
        filter(([confirmation]) => confirmation !== null)
      )
      .subscribe(([confirm, translations]) => {
        const direction: any = this.languageService.checkLanguageDirection(
          this.translate.currentLang
        );
        const {
          shared: {
            components: { confirmation },
          },
        } = translations;
        const refUniqueId = Date.now().toString(32) + Math.random().toString(32).substr(2);
        confirm.mainActionText = confirm.mainActionText ?? confirmation.confirm;
        confirm.cancelActionText = confirm.cancelActionText ?? confirmation.discard;
        this.confirmData = confirm;
        this.backgroundScrollService.setScrollState(false);
        this.dialogRefs[refUniqueId] = this.dialogService.open(this.dialogTemplate, {
          data: {
            ConfirmationType,
            ...confirm,
            reject: () => this.onClickReject(refUniqueId),
            confirm: (checkboxValue) => this.onClickConfirm(refUniqueId, checkboxValue),
          },
          direction,
        });
        this.initForm();
      });
  }
  private initForm(): void {
    this.form = this.formBuilder.group({});
    if (this.confirmData.textAreaText) {
      this.form.registerControl('textarea', new FormControl('', [Validators.required,this.noSpacesValidator]));
    }
    if (this.confirmData.checkboxOption) {
      this.form.registerControl('checkboxOption', new FormControl(false));
    }
  }

  private noSpacesValidator(control: FormControl) {
    const hasSpaces = control.value?.trim()
    return hasSpaces === '' ? { 'noSpaces': true } : null;
  }
}
