import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-form-field-group',
  template: ` 
    <div class="form-field-group">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./form-field-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldGroupComponent {}
