import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-elm-logo',
  templateUrl: './elm-logo.component.html',
  styleUrls: ['./elm-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElmLogoComponent {
  @Input() isVerifiedByElm: boolean;
  @Input() size: 'sm'|'md' = 'md';

  constructor() { }
}
