<app-drawer
  class="main-page__drawer w-100"
  #drawer
  [position]="drawerPosition"
  [drawerOpened]="drawerOpened"
  (drawerClosed)="onDrawerClosed()"
>
  <div class="main-page__drawer-content" drawer-content>
    <app-filter
      [hidden]="drawerType !== DrawerType.Filters"
      (drawerOpened)="onDrawerOpened($event); drawer.toggleDrawer();"></app-filter>
    <app-notifications *ngIf="drawerType === DrawerType.Notifications"
                       (closeNotificationClicked)="drawer.toggleDrawer(); onDrawerClosed();"></app-notifications>
    <app-column-options
      [hidden]="drawerType !== DrawerType.ColumnOptions"
      (drawerOpened)="onDrawerOpened($event); drawer.toggleDrawer()"
      (drawerClosed)="drawerOpened && drawer.toggleDrawer(); onDrawerClosed()">
    </app-column-options>
  </div>

  <div main-content>
    <div
      class="layout"
      [ngClass]="{
        'layout--sidebar-collapsed': navbarCollapsed,
        'layout--sidebar-active': navbarCollapsed
      }">
      <div class="layout__header">
        <app-header
          class="layout__header"
          (drawerOpened)="onDrawerOpened($event);
          drawerOpened = true; drawer.toggleDrawer();"
          (openMenuChange)="handelOpenMenu($event)"
          [openMenu]="openMenu"
        >
        </app-header>

      </div>

      <div class="layout__inner">
        <aside class="layout__sidebar">
          <app-sidenav
            class="layout__nav"
            [class.layout__nav-full-screen]="openMenu"
            [items]="navItems"
            (navbarCollapse)="onNavbarCollapseChange($event)"
            [class.navbarIsCollapsed]="navbarCollapsed"
            [collapsed]="navbarCollapsed"
            [openMenu]="openMenu"
            (changeOpenMenu)="handelOpenMenu($event)">
            >
          </app-sidenav>
        </aside>
        <div class="layout__content">
          <app-state-section [state]="sectionState">
            <app-announcement-bar></app-announcement-bar>
            <router-outlet></router-outlet>
          </app-state-section>
        </div>

        <!--        for development mode -->
        <div class="grid-preview" *ngIf="false">
          <div class="container">
            <app-row class="bg-red grid-row">
              <app-col *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" cols="1" class="bg-accent grid-col">
                <div class="text-white bg-success"></div>
              </app-col>
            </app-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-drawer>
