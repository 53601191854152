import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ButtonTheme } from "@shared/enums/button-theme.enum";
import { ShipmentFilterLabels } from "@features/filter/enums/shipment-filter-labels.enum";
import { ShipmentFilterLayout } from "@features/shipments/enums/shipment-filter-layout.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { Subject } from 'rxjs';
type labels = { create; apply; cancel; reset; backTofilter };

@Component({
  selector: 'app-filter-layout',
  templateUrl: './filter-layout.component.html',
  styleUrls: ['./filter-layout.component.scss'],
})
export class FilterLayoutComponent {
  @ViewChild('dynamicFilterTemplate', { read: ViewContainerRef })
  dynamicFilterTemplate: ViewContainerRef;
  filterAction = FormAction;
  action$ = new Subject<FormAction>();
  ButtonTheme = ButtonTheme;
  shipmentFilterLayout = ShipmentFilterLayout;
  defaultValue = {
    filterLayoutType: ShipmentFilterLayout.Basic,
    title: '',
    buttons: {
      submit: { label: ShipmentFilterLabels.Apply, theme: ButtonTheme.Raised, color: 'primary' },
      cancel: { label: ShipmentFilterLabels.Cancel, theme: ButtonTheme.Raised, color: 'secondary' },
      reset: { label: ShipmentFilterLabels.Reset, theme: ButtonTheme.Flat, color: 'primary' },
    },
  };
  settings = this.defaultValue;

  @Input() set options(opts: any) {
    this.settings = {
      ...this.defaultValue,
      ...opts,
      buttons: {
        ...this.defaultValue.buttons,
        ...opts?.buttons,
      },
    };
  }

  constructor() {}

  ngOnDestroy(): void {
    this.action$.complete();
  }

  emitAction(action: FormAction) {
    this.action$.next(action);
  }
}
