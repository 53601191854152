<app-dialog-layout class="madar--v2">
  <div dialog-title class="d-block w-100">
    <span>{{'shared.announcementDialog.announcement' | translate}}</span>
  </div>

  <app-state-section [state]="sectionState">
    <form [formGroup]="form" *ngIf="form">
      <app-row gutter="sm">
        <app-col sm="6">
          <mat-label class="d-block w-100 asterisk-after">{{ "Providers.Trucks.StartDate" | translate }}</mat-label>
          <app-date-input formControlName="startDate" [required]="true"></app-date-input>

          <mat-error
            *ngIf="form.invalid && form.get('startDate').touched && form.get('startDate').hasError('required')">
            {{ "ErrorMessages.Required" | translate }}
          </mat-error>
        </app-col>

        <app-col sm="6">
          <mat-label class="d-block w-100 asterisk-after">{{ "Providers.Trucks.EndDate" | translate }}</mat-label>
          <app-date-input formControlName="endDate" [min]="form.get('startDate').value"
            [required]="true"></app-date-input>
          <mat-error *ngIf="form.invalid && form.get('endDate').touched && form.get('endDate').hasError('required')">
            {{ "ErrorMessages.Required" | translate }}
          </mat-error>
        </app-col>

        <app-col sm="6">
          <mat-label class="d-block w-100 asterisk-after">{{ "Providers.Trucks.DailyCost" | translate }}</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="number" min="0"  step="any" [placeholder]="'Providers.Trucks.DailyCost' | translate"
              formControlName="dailyCost" oninput="validity.valid||(value='')" />
          </mat-form-field>

          <mat-error
            *ngIf="form.invalid && form.get('dailyCost').touched && form.get('dailyCost').hasError('required')">
            {{ "ErrorMessages.Required" | translate }}
          </mat-error>
        </app-col>

        <app-col sm="6">
          <mat-label class="d-block w-100 asterisk-after">{{ "Providers.Trucks.MonthlyCost" | translate }}</mat-label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput type="number" min="0"  step="any" [placeholder]="'Providers.Trucks.MonthlyCost' | translate"
              formControlName="monthlyCost" oninput="validity.valid||(value='')" />
          </mat-form-field>

          <mat-error
            *ngIf="form.invalid && form.get('monthlyCost').touched && form.get('monthlyCost').hasError('required')">
            {{ "ErrorMessages.Required" | translate }}
          </mat-error>
        </app-col>
      </app-row>
    </form>
  </app-state-section>


  <div dialog-action>
    <app-row justify="end">
      <app-col cols="6">
        <button mat-stroked-button color="primary" class="w-100 d-block" mat-dialog-close>
          {{'shared.Buttons.Cancel'|translate}}
        </button>
      </app-col>
      <app-col cols="6">
        <button mat-flat-button color="primary" class="w-100 d-block" (click)="onSaveContract()"
          [disabled]="form?.invalid" cdkFocusInitial>
          {{'shared.Buttons.Confirm'|translate}}
        </button>
      </app-col>
    </app-row>
  </div>
</app-dialog-layout>