import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { User } from '../../users/models/user';
import { Truck } from '../../fleets/models/truck';
import { DriversByDetectionType } from "@features/fleets/enums/drivers-by-detection-type.enum";
import { Nationality } from "@features/fleets/enums/nationality.enum";
import { Fleet } from '../../fleets/models/fleet';

export class Tracking extends Model {
  @Expose() id: number;
  @Expose() driverId: number;
  @Expose() shipmentId: number;
  @Expose() longitude: number;
  @Expose() latitude: number;
  @Expose() state: string;
  @Expose() selected: boolean;
  @Expose() nationality: Nationality;
  @Type(() => User)
  @Expose()
  user: User = new User();
  @Type(() => Truck)
  @Expose()
  truck: Truck = new Truck();
  @Type(() => Fleet)
  @Expose()
  fleet: Fleet = new Fleet();
  @Expose() createdAt: string;
  @Expose() date: string;
  @Expose() distanceInMeters: number;
  @Expose() name: string;
  @Expose() mobileNumber: string;
  @Expose() plateNumber: string;
  @Expose() truckType: string;
  @Expose() truckId: number;
  @Expose() fleetId: number;
  @Expose() fleetType: DriversByDetectionType;

  constructor() {
    super();
  }
}
