export enum TabGroup {
  Customers = 'Customers',
  Customer = 'Customer',
  Providers = 'Providers',
  Provider = 'Provider',
  Drivers = 'Drivers',
  Freelance = 'Freelance',
  Shipments = 'Shipments',
  ShipmentsB2c = 'ShipmentsB2c',
  MyShipments = 'MyShipments',
  Dashboard = 'Dashboard',
  Tracking = 'Tracking',
  DeliveryNotes = 'Delivery Notes',
  CustomerInvoices = 'Customer Invoices',
  TransportationPayments = 'TransportationPayments',
  FleetsB2c = 'FleetsB2c',
  B2CRequestLogs = 'B2CRequestLogs',
  RulesB2C = 'RulesB2C',
  GlobalContract = 'GlobalContract',
  GlobalSpesificContracts = 'GlobalSpesificContracts',
  Organization = 'Organization'
}
