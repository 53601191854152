<app-row >
  <app-col>
    <div cdkOverlayOrigin  #trigger="cdkOverlayOrigin">
      <mat-form-field appearance="outline" class="d-block w-100" *ngIf="!hiddenInput">
        <input
          class="d-block w-100"
          type="text"
          matInput
          [name]="uuid"
          placeholder="start - end"
          cdkOverlayOrigin
          [value]="((fromLabel | date:'short') || 'From') + ' - ' + ((toLabel | date:'short') || 'To')"
          (focus)="handleOpen()">
      </mat-form-field>
    </div>
  </app-col>
</app-row>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayDisableClose]="true"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayKeydown)="onKeydown($event)"
>
  <mat-card [formGroup]="form" class="picker-card scrollable scrollable--y p-0" [style]="'max-height:'+ maxHight">
    <mat-tab-group [(selectedIndex)]="selectedTabIndex">
      <mat-tab [label]="(fromLabel | date:'short') || 'From'">
        <div>
          <ng-container *ngTemplateOutlet="picker; context: { name: 'from', group: form.controls.from }"></ng-container>
        </div>
      </mat-tab>

      <mat-tab [label]="(toLabel | date:'short') || 'To'" [disabled]="!form?.value.from?.date">
        <ng-container *ngTemplateOutlet="picker; context: { name: 'to', group: form.controls.to }"></ng-container>
      </mat-tab>
    </mat-tab-group>

    <div class="px-4">
      <app-row class="py-2">
        <app-col>
          <button mat-raised-button (click)="isOpen = false; openChange.emit(false)">cancel</button>
        </app-col>

        <app-col class="text-center">
          <button mat-raised-button (click)="reset()" class="btn-secondary">Reset</button>
        </app-col>

        <app-col class="text-right">
          <button
            mat-raised-button
            [disabled]="(selectedTabIndex === 0 && !isFromValid) || (selectedTabIndex === 1 && !isToValid)"
            (click)="handleChange()"
            color="primary">{{selectedTabIndex ? 'Save' : 'Next'}}</button>
        </app-col>
      </app-row>
    </div>
  </mat-card>
</ng-template>

<ng-template #picker let-name="name" let-group="group">
  <div *ngIf="name && group" class="p-1 datetime-range" [formGroup]="group">
        <mat-calendar
          [name]="name + '-date'"
          [minDate]="name === 'to' ? fromLabel : null"
          [maxDate]="name === 'from' ? toLabel : null"
          [selected]="group?.value.date"
          (selectedChange)="group.get('date').setValue($event)">
        </mat-calendar>

    <div class="times">
      <app-row align="center" gutter="x-xs" justify="center">
        <app-col cols="auto">
          <mat-form-field appearance="outline" class="small-field">
            <mat-select formControlName="hours">
              <mat-option *ngFor="let hr of hours" [value]="hr.padStart(2, '0')">{{hr.padStart(2, '0')}}</mat-option>
              <mat-option [value]="'00'">12</mat-option>
            </mat-select>
          </mat-form-field>
        </app-col>

        <app-col cols="auto"><span class="colon">:</span></app-col>

        <app-col cols="auto">
          <mat-form-field appearance="outline" class="small-field">
            <mat-select formControlName="minutes">
              <mat-option *ngFor="let min of minsAndSecs" [value]="min.padStart(2, '0')">{{min.padStart(2, '0')}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-col>

        <app-col cols="auto">
          <mat-form-field appearance="outline" class="small-field">
            <mat-select formControlName="period">
              <mat-option [value]="0">{{'AM'}}</mat-option>
              <mat-option [value]="12">{{'PM'}}</mat-option>
            </mat-select>
          </mat-form-field>
        </app-col>
      </app-row>
    </div>
  </div>
</ng-template>
