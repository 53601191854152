import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-uploaded-file',
  templateUrl: './uploaded-file.component.html',
  styleUrls: ['./uploaded-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadedFileComponent {
  @Input() title: string;
  @Input() item: any;

  constructor() {}

  onDownloadClicked(): void {
    window.open(this.item, '_blank');
  }
}
