<ul class="timeline" [class.timeline--lg-bullet]="bulletSize === 'lg'">
  <li class="timeline__item" *ngFor="let item of displayedList" [ngClass]="{'timeline__item--danger': item.danger}">
    <div class="timeline__bullet">
      <app-icon [name]="item.icon" type="MaterialDesign" *ngIf="item?.icon"></app-icon>
      <span *ngIf="!item?.icon" class="timeline__bullet-shape"></span>
    </div>
    <div class="timeline__item-content">
      <div class="row align-center gx-sm">
        <div class="col">
          <div class="row gx-xs align-center">
            <div class="col-auto">
              <div class="timeline__item-label" [class.timeline__item-label-bold]="labelBold">
                <ng-container *ngIf="translationPrefix">{{ translationPrefix + '.' + item.label | translate}}</ng-container>
                <ng-container *ngIf="!translationPrefix">{{ item.label }}</ng-container>
              </div>
            </div>
            <div class="col-12" *ngIf="item.value">
              <div class="timeline__item-value">{{item.value}}</div>
            </div>
            <div class="col-12" *ngIf="item.date">
              <time class="timeline__item-date">{{item?.date | date : 'dd-MM-yyyy hh:mm aa'}}</time>
            </div>
          </div>
        </div>
        <div
          class="col-auto"
          *ngIf="(actions && actions.length && !handleEmptyRequestState) || (actions && actions.length && item.item.requestBody && handleEmptyRequestState)"
        >
          <div class="row no-gutters">
            <div class="col" *ngFor="let action of actions">
              <button
                type="button"
                class="px-2 btn--text-primary"
                mat-button
                color="primary"
                (click)="actionClicked.emit({ item: item.item, action: action.id })"
              >
                {{action.label}}
              </button>
            </div>
          </div>
        </div>

        <div class="col-auto" *ngIf="emptyRequestActions && emptyRequestActions.length && handleEmptyRequestState && !item.item.requestBody">
          <div class="row no-gutters">
            <div class="col" *ngFor="let action of emptyRequestActions">
              <button type="button" class="px-2 text-warn" mat-button (click)="actionClicked.emit({ item: item.item, action: action.id })">
                {{action.label}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>
