import { SharedConstants } from '../model/shared-constants';
import { Country } from '@shared-features/models/country';
import { ConfigConstant } from '@shared/constants/config.constant';

type duration = {
  days: string,
  hours: string,
  mins: string
}
export class FunctionUtil {
  static deepClone(obj): any {
    if (!obj || true === obj) {
      // this also handles boolean as true and false
      return obj;
    }
    const objType = typeof obj;
    if ('number' === objType || 'string' === objType || 'object' === objType) {
      // add your immutables here
      return obj;
    }
    const result = Array.isArray(obj) ? [] : !obj.constructor ? {} : new obj.constructor();
    if (obj instanceof Map) {
      for (const key of obj.keys()) {
        result.set(key, this.deepClone(obj.get(key)));
      }
    }
    for (const key2 in obj) {
      if (obj?.hasOwnProperty(key2)) {
        result[key2] = this.deepClone(obj[key2]);
      }
    }
    return result;
  }

  /**
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(4) : 95ca-361a-f8a1-1e73
   */
  static getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  static formatFileSize(size): string {
    for (let i = 1; i < SharedConstants.fileSizes.length; i++) {
      if (size < Math.pow(1024, i)) {
        return Math.round((size / Math.pow(1024, i - 1)) * 100) / 100 + SharedConstants.fileSizes[i - 1];
      }
    }
    return size;
  }

  static convertDate(value): string {
    const date = new Date(value);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), month, day].join('-');
  }

  static convertDateWithoutDays(value): string {
    const date = new Date(value);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return [date.getFullYear(), month].join('-');
  }
  
  static formateCallDurationByFractions(duration): duration {
    if (isNaN(duration)) {
    return {
        days: '00',
        hours: '00',
        mins:  '00'
       }
    }
    const num = Number(duration) / 1000;
    let days = num / (24 * 60 * 60);
    if (days < 1) {
      days = 0;
    }
    let hour = num / 3600;
    hour = hour - Math.floor(days) * 24;
    let fractionhour = hour - Math.floor(hour);
    if (hour < 1.0) {
      fractionhour = hour;
      hour = 0;
    }
    let min = fractionhour * 60;
    let fractionmin = min - Math.floor(min);
    if (min < 1.0) {
      fractionmin = min;
      min = 0;
    }

    return {
      days: Math.floor(days).toString(),
      hours: Math.floor(hour).toString(),
      mins:  Math.floor(min).toString()

    }
  }

  static getSaudiArabiaPhoneKey(countries: Country[]): string {
    let newCountry = new Country();
    countries.some((country) => {
      if (country.isoCode === ConfigConstant.SAUDI_ARABIA_ISO_CODE) {
        newCountry = country;
        return true;
      }
    });
    return newCountry.phoneKey;
  }

  static arrayEquals(a, b): boolean {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  static sortListDescending(list: any[], colName: string): any[] {
    return list.sort((a, b) => {
      const keyA = a[colName];
      const keyB = b[colName];
      if (keyA > keyB) { return -1; }
      if (keyA < keyB) { return 1; }
      return 0;
    });
  }
}
