export enum TabIndex {
  AllRequests = 1,
  AllCustomers = 0,
  CustomerCompanyInfo = 0,
  CustomerContract = 1,
  CustomerFleetContract = 2,
  Trucks = 3,
  Trailers = 5,
  Drivers = 6,
  CustomerUsers = 6,
  Receivers = 5,
  AllProviders = 0,
  ProviderCompanyInfo = 0,
  ProviderContract = 1,
  ProviderUsers = 7,
  OrderStatus = 0,
  DriversTracking = 0,
  ShipmentTracking = 1,
  OperationPerformance = 1,
  Shipments = 0,
  Payments = 1,
  FleetB2cInfo = 0,
  FleetB2cTrucks = 1,
  FleetB2cDrivers = 2,
  FleetB2cUsers = 3,
  MyCustomers = 6,
  OrganizationInfo = 0,
  OrganizationUsers = 1,

  None = 0,
  New = 1,
  // DelayedAction = 'DELAYED_ACTION',
  Assigned = 2,
  Accepted = 3,
  InTransit = 4,
  ArrivePickupLocation = 5,
  GoodsLoaded = 6,
  ArriveDropOffLocation = 7,
  GoodsDelivered = 8,
  // Closed = 'CLOSED',
  NotFulfilled = 9,
  Rejected = 10,
  Canceled = 11
}
