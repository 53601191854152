import { CustomerFleetType } from "@features/fleets/enums/customer-fleet-type.enum";
import { Expose } from 'class-transformer';
import { DropdownModel } from '../../shared-features/models/dropdown-model';
import { Model } from "../../shared-features/models/model";

export class FleetHasPayments extends DropdownModel {
    @Expose() id: number;
    @Expose() fleetName: string;
    @Expose() fleetType: CustomerFleetType;
    @Expose() paidCount: number;
    @Expose() pendingCount: number;
    @Expose() rejectedCount: number;
    @Expose() totalCount: number;

    constructor() {
        super();
      }


      get dropDownDisplayName(): string {
        return this.fleetName;
      }
      get dropDownValue(): FleetHasPayments {
        return this;
      }
}
