import { Component,  OnInit } from '@angular/core';
import { StorageConstant } from '@shared/constants';
import { LocalStorageUtil } from '@shared/utils/local-storage.util';
import { BaseComponent } from '../base-component/base.component';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { Announcement } from '@shared-features/models/announcement.model';

@Component({
  selector: 'app-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.scss']
})
export class AnnouncementBarComponent extends BaseComponent implements OnInit {
  announcement:Announcement
  announcementLocalStorage:{showAnnouncementBar: boolean , id:number}

  constructor(
    
    private sharedApiService :SharedApiService
  ) {
    super()
   }

  ngOnInit() {
    this.announcementLocalStorage = LocalStorageUtil.getItem(StorageConstant.ANNOUNCEMENT_BAR) || {}
    this.getAnnouncement()
  }

  hideAnnouncementBar(){
    this.announcementLocalStorage.showAnnouncementBar = false
    this.announcementLocalStorage.id = this.announcement.id
    LocalStorageUtil.setItem(StorageConstant.ANNOUNCEMENT_BAR,this.announcementLocalStorage)
  }

  getAnnouncement(){
    this.load(this.sharedApiService.getAnnouncement()).subscribe((announcement) => {
      this.announcement = announcement;
      if (!announcement?.isForAdmin) {
        this.announcementLocalStorage.showAnnouncementBar = false;
      } else if (announcement?.isForAdmin && announcement.id !== this.announcementLocalStorage.id) {
        this.announcementLocalStorage.showAnnouncementBar = true;
        this.announcementLocalStorage.id = this.announcement.id;
        LocalStorageUtil.setItem(StorageConstant.ANNOUNCEMENT_BAR, this.announcementLocalStorage);
      }
    });
  }

  private isEmail(value: string): boolean {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }

  private isLink(value: string): boolean {
    return /^https?:\/\/\S+$/.test(value);
  }
}
