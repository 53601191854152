import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  
  Injector,
  
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment, { Moment } from 'moment';
import { timeToMoment } from '@shared/utils/time-to-moment.util';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFieldComponent),
      multi: true,
    },
  ],
})
export class TimeFieldComponent
  implements ControlValueAccessor, OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() disabledRanges: { from?: string; to?: string }[] = [];
  filteredDisabledRanges: { from: Moment; to: Moment }[] = [];
  selectedValueInDisabledRanges = false;
  ngControl: NgControl;

  form: FormGroup<{
    minutes: FormControl<string>;
    hours: FormControl<string>;
    period: FormControl<number>;
  }>;

  hours = new Array(11).fill(1).map((_, i) => `${i + 1}`);
  minsAndSecs = new Array(60).fill(1).map((_, i) => `${i}`);
  isDisabled = false;

  value: string;
  previousValue = { hours: '', minutes: '', period: 0 };

  destroy$ = new Subject();

  onChange = (value: string) => null;
  onTouched = () => null;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private builder: FormBuilder,
    private injector: Injector
  ) {}

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    if (value) {
      this.updateFormFromString(value || '');
    }
    this.changeDetector.markForCheck();
  }

  updateFormFromString(value: string): void {
    const [time, meridian] = (value || '').split(' ');
    const [hoursCount, minutes] = time.split(':').map((i) => parseInt(i, 10));
    let period = 0;
    let hours = hoursCount;

    if (meridian && meridian.toLowerCase() === 'pm') {
      if (hoursCount !== 12) {
        hours += 12;
      }
      period = 12;
    } else if (hoursCount >= 12) {
      period = 12;
      hours -= 12;
    }

    this.form.setValue({
      hours: (hours?.toString() || '').padStart(2, '0'),
      minutes: (minutes?.toString() || '').padStart(2, '0'),
      period,
    });
  }
  getValueFromForm(): string {
    const { hours, minutes, period } = this.form.value;
    const fullHours = period && period === 12 ? `${Number(hours) + 12}`.padStart(2, '0') : hours;
    return `${fullHours}:${minutes}`;
  }
  updateValueFromForm(value: string): void {
    this.value = value;
    this.onChange(this.value);
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.isDisabled = isDisabled;
  }

  ngOnChanges({ disabledRanges }: SimpleChanges): void {
    if (disabledRanges) {
      this.filterDisabledRanges();
    }
  }

  filterDisabledRanges(): void {
    this.filteredDisabledRanges = (this.disabledRanges || [])
      .filter((range) => range.to || range.from)
      .map((range) => {
        let newRange = range as { from: string; to: string };
        if (!range.to) {
          newRange = { ...range, to: '23:59' } as { from: string; to: string };
        } else if (!range.from) {
          newRange = { ...range, from: '00:00' } as { from: string; to: string };
        }
        const from = timeToMoment(newRange.from);
        const to = timeToMoment(newRange.to);

        if (to.diff(from) < 0) {
          to.add(1, 'day');
        }

        return {
          from,
          to,
        };
      });
  }

  ngOnInit(): void {
    this.form = this.builder.group({
      hours: [''],
      minutes: [''],
      period: [0],
    });

    this.form.valueChanges
      .pipe(debounceTime(100), takeUntil(this.destroy$))
      .subscribe((formValue) => {
        if (!formValue.hours || !formValue.minutes) {
          return;
        }
        const value = this.getValueFromForm();
        const isTimeDisabled = this.checkIfTimeDisabled(value);
        if (!isTimeDisabled) {
          this.updateValueFromForm(value);
          this.selectedValueInDisabledRanges = false;
          this.toggleInternalFormErrors(false);
        } else {
          this.selectedValueInDisabledRanges = true;
          this.toggleInternalFormErrors(true);
          this.onChange('');
        }
        this.changeDetector.markForCheck();
      });
  }

  checkIfTimeDisabled(time: string): boolean {
    const date = timeToMoment(time);
    return this.filteredDisabledRanges.some((range) => this.isBetween(date, range.from, range.to));
  }

  isBetween(date: Moment, start: Moment, end: Moment): boolean {
    const time = date.toDate().getTime();

    if (end.diff(start) < 0) {
      // If the end time is less than the start time, it means the range spans across two days.
      // We need to check if the date is between the start time and midnight on the same day,
      // or between midnight and the end time on the next day.
      return (
        date.isBetween(start, moment(start).endOf('day')) ||
        date.isBetween(moment(end).startOf('day'), end)
      );
    }

    return (
      date.isBetween(start, end) ||
      time === start.toDate().getTime() ||
      time === end.toDate().getTime()
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.ngControl = this.injector.get(NgControl);

    this.ngControl.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(200)).subscribe(() => {
      this.toggleInternalFormErrors(this.ngControl.invalid);
    });
  }

  toggleInternalFormErrors(error: boolean | string): void {
    Object.values(this.form.controls).forEach((control) => {
      if (error) {
        control.setErrors({ error });
        control.markAsTouched();
      } else {
        control.setErrors(null);
      }
      this.changeDetector.markForCheck();
    });
  }
}
