import { Injectable } from '@angular/core';

import { UserRole } from '@features/users/enums/user-role.enum';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  constructor(private authService: AuthService) {}

  isUserHasRoles(roles: UserRole[]): boolean {
    for (const role of roles) {
      const userRole = this.authService.currentUser$.value.userRoles.find((item) => item === role);

      if (userRole) {
        return true;
      }
    }
    return false;
  }
}
