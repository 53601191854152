import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { LocationModel } from '../../live-tracking/models/location.model';

export class ShipmentSummaryPerTruck extends Model {
  @Expose() id: number;
  @Expose() totalDistance: number;
  @Type(() => LocationModel)
  @Expose()
  pickUpLocation: LocationModel = new LocationModel();

  @Type(() => LocationModel)
  @Expose()
  dropOffLocation: LocationModel = new LocationModel();


  constructor() {
    super();
  }
}
