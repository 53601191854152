import { Lookup } from '@shared-features/models/Lookup.model';
import { OperationPerformanceDNStatus } from "@features/dashboard/enums/operation-performance-DN-status.enum";
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { CompanyLabelType } from "@shared/enums/company-label-type.enum";
import { CustomerType } from "@features/customers/enums/customer-type.enum";

export class LocalStorageDashboardStatus {
    generalTime: { range: DateRangeType, startFrom?: Date, endAt?: Date };
    generalCustomers: number[];
    generalCustomerNames: string[];
    generalCustomerLabels: string[];
    generalCustomerLabelNames: string[];

    orderStatusTime: DateRangeType;
    orderStatusTimeStartFrom: string;
    orderStatusTimeEndAt: string;
    orderStatusCustomerId: string;
    orderStatusFleetId: string;
    operationPerformanceTime: DateRangeType;
    operationPerformanceTimeStartFrom: string;
    operationPerformanceTimeEndAt: string;
    operationPerformanceCustomers: number[];
    operationPerformanceCustomersType: CustomerType;
    customerLabel: string[];
    fleetLabel: string[];
    operationPerformanceDeliveryNoteStatus: OperationPerformanceDNStatus;
    orderFulfillmentTime: DateRangeType;
    orderFulfillmentTimeStartFrom: string;
    orderFulfillmentTimeEndAt: string;
    orderFulfillmentCustomers: Lookup[];
    orderFulfillmentFleets: Lookup[];
    madarPerformanceTime: DateRangeType;
    madarPerformanceTimeStartFrom: string;
    madarPerformanceTimeEndAt: string;
    madarPerformanceCustomers: number[];
    topDriversTime: DateRangeType;
    topDriversTimeStartFrom: string;
    topDriversTimeEndAt: string;
    topDriversCustomer: number[];
    totalJoinedTime: DateRangeType;
    totalJoinedTimeStartFrom: string;
    totalJoinedTimeEndAt: string;
    totalContractsTime: DateRangeType;
    totalContractsTimeStartFrom: string;
    totalContractsTimeEndAt: string;
    totalDriversTime: DateRangeType;
    totalDriversTimeStartFrom: string;
    totalDriversTimeEndAt: string;
    totalTrucksTime: DateRangeType;
    totalTrucksTimeStartFrom: string;
    totalTrucksTimeEndAt: string;
    totalDNTime: DateRangeType;
    totalDNTimeStartFrom: string;
    totalDNTimeEndAt: string;
    totalDNCustomers: number[];
    tradeLanesToCities: number[];
    tradeLanesFromCity: number;
    tradeLanesDate: DateRangeType;
    orderCreatedByCustomers: number[];
    orderCreatedByTime:DateRangeType;
    orderCreatedByTimeStartFrom: string;
    orderCreatedByTimeEndAt: string;
    orderAssignedByCustomers: number[];
    orderAssignedByTime:DateRangeType;
    orderAssignedByTimeStartFrom: string;
    orderAssignedByTimeEndAt: string;
}
