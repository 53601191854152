import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { LayoutService } from '@shared/services/layout.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnChanges {
  @Input() title: string;
  @Input() subTitle = '';
  @Input() textColor = 'white';
  @Input() closeIconPath = 'assets/images/close.svg';
  @Input() applyModalPage = false;
  @Input() editCloseIcon = false;
  @Input() maxWidth = '1440';
  @Input() padding = '0';
  @Input() applyHeaderStyle = true;
  @Input() backgroundColor = 'var(--color-lightest)';
  @Input() contentFillTheScreen = false;
  @Output() closeClicked = new EventEmitter<any>();

  isModalOpened = false;
  isVisible = false;

  constructor(public layoutService: LayoutService, private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.changeDetector.markForCheck();
  }

  showModal(): void {
    this.isModalOpened = true;
    this.changeDetector.markForCheck();
  }

  closeModal(): void {
    this.isModalOpened = false;
    this.changeDetector.markForCheck();
  }

  closeCurrentModal(): void {
    this.closeClicked.emit(null);
    this.isModalOpened = false;
    this.changeDetector.markForCheck();
  }
}
