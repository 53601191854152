export class RouteConfig<p= void, q= void> {
  public pathFromRoot: string;

  constructor(public path: string, public parent?: RouteConfig<any, any>) {
    this.pathFromRoot = RouteConfig.getPathUntilRoot(this);
  }

  static getPathUntilRoot(node: RouteConfig<any, any>): string {
    let root = node;
    let path = node.path;

    while (root.parent) {
      root = root.parent;
      path = `${root.path}/${path}`;
    }
    return `/${path.replace(/^\//, '')}`;
  }

  url(params?: p|null, queryParams?: q): string {
    let pathFromRoot = this.pathFromRoot;

    if (params) {
      Object.keys(params).forEach((param) => {
        if (pathFromRoot.includes(`:${param}`)) {
          pathFromRoot = pathFromRoot.replace(`:${param}`, params[param]);
        }
      });
    }

    if (queryParams) {
      const queries = Object.keys(queryParams).reduce((list, query) => {
        if (queryParams[query]) {
          return list ? `${list}&${query}=${queryParams[query]}` : `${query}=${queryParams[query]}`
        }
        return list;
      }, '');
      pathFromRoot = `${pathFromRoot}?${queries}`;
    }

    return pathFromRoot;
  }
}
