import { Component, Input, OnChanges, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sequence-number',
  templateUrl: './sequence-number.component.html',
  styleUrls: ['./sequence-number.component.scss'],
})
export class SequenceNumberComponent implements OnInit {
  @Input() number = null;
  public form: UntypedFormGroup;
  
  constructor(private formBuilder: UntypedFormBuilder, private eleRef: ElementRef) {}

  ngOnInit(): void {
    this.initForm();
    if (this.number) {
      this.number
        .toString()
        .split('')
        .forEach((element, index) => {
          this.form.controls[`number${index + 1}`].setValue(element);
        });
    }
  }

  moveCursorToNextField(tb) {
    if (tb < 10) this.eleRef.nativeElement.querySelector('#field' + (tb + 1)).focus();
  }

  getValue() {
    const value = [];
    Object.keys(this.form.controls).forEach((control) => {
      value.push(this.form.controls[control]?.value);
    });
    return value.join('');
  }

  private initForm() {
    this.form = this.formBuilder.group({
      number1: new UntypedFormControl('', Validators.required),
      number2: new UntypedFormControl('', Validators.required),
      number3: new UntypedFormControl('', Validators.required),
      number4: new UntypedFormControl('', Validators.required),
      number5: new UntypedFormControl('', Validators.required),
      number6: new UntypedFormControl('', Validators.required),
      number7: new UntypedFormControl(''),
      number8: new UntypedFormControl(''),
      number9: new UntypedFormControl(''),
      number10: new UntypedFormControl(''),
    });
  }
}
