<div class="page-wrapper__tabs">
    <app-tab
    theme="trapezoid"
    [tabs]="tabs"
    [stretchTabs]="false"
    alignTabs="start"
    [selectedIndex]="selectedTabIndex"
      (currentIndex)="selectedTabIndexChange.emit($event)"
  ></app-tab>
</div>
<mat-card class="page-wrapper__content p-0">
  <ng-content></ng-content>
</mat-card>
