import { Model } from '../../shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { User } from '../../users/models/user';
import { ShipmentStatusLabel } from './shipment-status-label';

export class ShipmentLabel extends Model {
  @Expose() createdAt: number;
  @Expose() deletedAt: number;
  @Expose() id: number;
  @Expose() isActive: boolean;
  @Type(()=> ShipmentStatusLabel)
  @Expose() label: ShipmentStatusLabel;
  @Expose() comment: string;
  @Expose() createdBy: User;
}
