export enum Status {
  All = 'All',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  REGISTERED = 'REGISTERED',
  EXPIRED = 'EXPIRED',
  NEW = 'NEW',
  RENEWED = 'RENEWED',
  REJECTED = 'REJECTED',
  AVAILABLE = 'AVAILABLE',
  ASSIGNED = 'ASSIGNED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  FAILED = 'FAILED',
  PASS = 'PASS',
  NOT_READY = 'NOT_READY'
}
