import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class TrucksPerformance extends Model {
  @Expose()
  avgCount: number;

  @Expose()
  belowCount: number;

  @Expose()
  superCount: number;
}
