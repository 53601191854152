<div class="pagination">
  <app-row align="center">
    <app-col cols="12" md="6" *ngIf="showDisplayCount || selectionEnabled">
      <div class="pagination__options" *ngIf="selectionEnabled">
        <app-row gutter="x-sm" align="center">
          <app-col cols="auto">
            <mat-form-field appearance="outline" class="no-hint">
              <mat-select [ngModel]="pageSize" (ngModelChange)="handlePageSizeChange($event)">
                <mat-option *ngFor="let item of pageSizes" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>
          </app-col>
          <app-col>
            <div class="text-500 small">{{ "Pagination.EntriesPerPage" | translate }}</div>
          </app-col>
        </app-row>
      </div>

      <div *ngIf="!selectionEnabled && showDisplayCount" class="text-500 small">
        {{ "Pagination.Displaying" | translate }} {{ displayedCount }} {{ "Pagination.OutOf" | translate }} {{ total }}
      </div>
    </app-col>

    <app-col cols="12" sm="auto" class="mr-auto mb-3" *ngIf="showDisplayCount || selectionEnabled"></app-col>

    <app-col cols="12" sm="auto" [class.mx-auto]="!showDisplayCount && !selectionEnabled">
      <app-row gutter="x-xs">
        <app-col cols="auto" *ngIf="showLastUpdatedAt">
          <span class="small text-500">{{ "Pagination.LastUpdate" | translate }} {{ lastUpdatedAt | date: 'dd-MMM h:mm a' }}</span>
        </app-col>
        <app-col cols="auto">
          <div class="pagination__actions">
            <button mat-button type="button" class="pagination__action" [disabled]="isFirstPage" (click)="previousPage()">
              <app-icon class="d-inline-block align-middle icon--flipped-rtl" name="bold-arrow-left"></app-icon>
            </button>
          </div>
        </app-col>
        <app-col cols="auto">
          <div class="pagination__actions px-2">
            <app-row class="no-gutters">
              <app-col cols="auto" *ngIf="pageIndex > 2 && lastPageIndex > 3">
                <div class="flex align-center">
                  <button mat-button type="button" class="pagination__action" (click)="changePage(1)">
                    <span class="d-inline-block">1</span>
                  </button>
                  <span class="pagination__placeholder" *ngIf="pageIndex > 3">...</span>
                </div>
              </app-col>

              <app-col *ngFor="let page of displayedPages;" cols="auto">
                <button
                  mat-button
                  type="button"
                  class="pagination__action"
                  [class.pagination__action--active]="pageIndex === page"
                  [color]="page === pageIndex ? 'primary' : undefined"
                  (click)="page !== pageIndex && changePage(page)"
                >
                  <span class="d-inline-block">{{page}}</span>
                </button>
              </app-col>

              <app-col cols="auto" *ngIf="pageIndex < lastPageIndex - 1 && lastPageIndex > 3">
                <div class="flex align-center">
                  <span class="pagination__placeholder" *ngIf="pageIndex < lastPageIndex - 2">...</span>
                  <button mat-button type="button" class="pagination__action" (click)="changePage(lastPageIndex)">
                    <span class="d-inline-block">{{lastPageIndex}}</span>
                  </button>
                </div>
              </app-col>
            </app-row>
          </div>
        </app-col>
        <app-col cols="auto">
          <div class="pagination__actions">
            <button mat-button type="button" class="pagination__action" [disabled]="isLastPage" (click)="nextPage()">
              <app-icon class="d-inline-block align-middle icon--flipped-rtl" size="sm" name="bold-arrow-right"></app-icon>
            </button>
          </div>
        </app-col>
      </app-row>
    </app-col>
  </app-row>
</div>
