import { LazyLibURL } from "@shared/enums/lazy-lib-url.enum"

export class SentryConstant {
  static IgnoredErrors = [/ChunkLoadError/, /Non-Error/, /NG04002/ ]
  static DeniedUrls = [
    ...Object.values(LazyLibURL),
    /localhost/,
    /https:\/\/static\.zdassets\.com\//,
    /https?:\/\/((maps|cdn|www)\.)?googleapis\.com/,
  ]
}

