import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { FilterByDate } from '@features/filter/enums/filter-by-date.enum';
import { TabModel } from '../model/tab.model';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public showFilter$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public filterForm$: BehaviorSubject<any> = new BehaviorSubject(null);
  public filterNavigationConfig$: BehaviorSubject<TabModel> = new BehaviorSubject(null);
  public URLParams$: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentTabIndex$: BehaviorSubject<any> = new BehaviorSubject(null);
  public generalDashboardConfig$: BehaviorSubject<{
    range: { range: DateRangeType; startFrom?: string; endAt?: string };
    customers: any[];
    customerLabels: any[];
  }> = new BehaviorSubject(null);
  public selectedFiltrationValue$: BehaviorSubject<any> = new BehaviorSubject(null);
  public previousSelectedShipmentDateRange$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  clearLastPositionOnStream() {
    this.showFilter$.next(null);
    this.filterForm$.next(null);
    this.filterNavigationConfig$.next(null);
    this.URLParams$.next(null);
    this.URLParams$.next(null);
    this.currentTabIndex$.next(null);
    this.generalDashboardConfig$.next(null);
    this.selectedFiltrationValue$.next(null);
    this.previousSelectedShipmentDateRange$.next(null);
  }

  navigateToTargetPageWithFilter(obj: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        ...obj,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: false,
    });
  }

  removeFiltrationParamsFromURL() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
    });
  }
  closeFilter() {
    this.showFilter$.next(false);
  }

  withoutTime(dateTime) {
    let date = new Date(dateTime);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  }

  isInRange(selectedFilterValue: FilterByDate, columnValue: any): boolean {
    if (typeof columnValue === 'string') {
      columnValue = this.withoutTime(new Date(Date.parse(columnValue)));
    } else {
      columnValue = this.withoutTime(new Date(parseInt(columnValue)));
    }

    const today = this.withoutTime(new Date());
    const yesterday = this.withoutTime(new Date(new Date(today.valueOf() - 1000 * 60 * 60 * 24)));
    const lastWeek = this.withoutTime(new Date(new Date().setDate(new Date().getDate() - 7)));
    const lastTwoWeeks = this.withoutTime(
      new Date(new Date(lastWeek).setDate(new Date(lastWeek).getDate() - 7))
    );
    const lastMonth = this.withoutTime(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const lastYear = this.withoutTime(
      new Date(new Date().setFullYear(new Date().getFullYear() - 1))
    );

    switch (selectedFilterValue) {
      case FilterByDate.AllTime:
        return true;

      case FilterByDate.Today:
        return columnValue === today;

      case FilterByDate.Yesterday:
        return columnValue === yesterday;

      case FilterByDate.ThisWeek:
        return columnValue > lastWeek;

      case FilterByDate.Last14Day:
        return columnValue > lastTwoWeeks;

      case FilterByDate.LastMonth:
        return columnValue >= lastMonth;

      case FilterByDate.LastYear:
        return columnValue >= lastYear;
    }
  }
}
