import { Model } from '@shared-features/models/model';
import { Expose, Type } from 'class-transformer';
import { TradelaneClassification } from '@features/fleets/enums';
import { City } from '@shared-features/models/city';
import { Customer } from '@features/customers/models/customer';

export class ClassifiedTradelane extends Model {
  @Expose() id: number;
  @Expose() customerIds: number[];

  @Expose() days: number[];

  @Expose()
  @Type(() => City)
  fromCity: City;
  @Expose()
  @Type(() => City)
  toCity: City;
  @Expose()
  @Type(() => Customer)
  customers: Customer[];
  @Expose() type: TradelaneClassification;
  @Expose() createdAt: Date;
  @Expose() updatedAt: Date;
}
