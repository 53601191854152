import en from './en';
import ar from './ar';
import { Locales } from '@shared/model/locales.model';

export default {
  modulePrefix: 'layout',
  phrasesPerLang: {
    en,
    ar,
  },
} as Locales;
