<mat-nav-list class="avatar">
  <div class="flex align-center justify-space-between m-2 px-2 py-1">
    <div class="flex align-center">
      <app-icon class="d-inline align-center" size="sm" name="global"></app-icon>
      <span class="avatar__list-item mx-4">{{ 'layout.header.userMenu.changeLanguage' | translate}}</span>
    </div>
    <div>
      <button mat-flat-button *ngIf="currentLang === language.Arabic" (click)="setLanguage(language.English)" class="btn-secondary">
        <span class="weight-medium d-inline-block mr-2">{{ "Lang.EN" | translate}}</span>
        <app-icon class="d-inline align-center" size="sm" name="en-flag"></app-icon>
      </button>

      <button mat-flat-button *ngIf="currentLang === language.English" (click)="setLanguage(language.Arabic)" class="btn-secondary">
        <span class="weight-medium d-inline-block mr-2">{{ "Lang.AR" | translate}}</span>
        <app-icon class="d-inline align-center" size="sm" name="sr-flag"></app-icon>
      </button>
    </div>
  </div>
  <app-nav-list [menuList]="navigationItems" color="basic" [divider]="true" (action)="navService.action$.next($event)"></app-nav-list>
</mat-nav-list>
