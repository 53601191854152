<div
  class="container"
  [ngClass]="{
    'active': value == status.ACTIVE,
    'inActive': value == status.INACTIVE,
    'pending': value == status.PENDING || value == status.ASSIGNED || value == status.NOT_READY || value === 'NotReady',
    'registered': value == status.REGISTERED,
    'rejected': value == status.REJECTED,
    'available': value == status.AVAILABLE || value == status.PASS || value === 'Free',
    'notAvailable': value == status.NOT_AVAILABLE || value == status.FAILED || value ==='Busy'
  }"
>
  <span>{{ "Enum.Status." + value | translate }}</span>
</div>
