import { Expose, Type } from 'class-transformer';
import { Media } from '../../shared-features/models/media';
import { Model } from '../../shared-features/models/model';

export class SubDeliveryNote extends Model {
  @Expose() id: number;
  @Expose() referenceNumber: string;
  @Expose() deletedAt: number;
  @Type(() => Media)
  @Expose()
  attachment: Media = new Media();
  @Expose() createdAt: number;
  @Expose() updatedAt: number;

  constructor() {
    super();
  }
}
