import { Lookup } from '@shared-features/models/Lookup.model';
import { getValueByPath } from './get-value-by-path.util';

export function modelToLookup(data: any, idPath: string, valuePath: string, cityIdPath?: string): Lookup {
  const convertedObj = new Lookup();
  convertedObj.id = Number(getValueByPath(data, idPath));
  convertedObj.value = getValueByPath(data, valuePath);
  const isNan = isNaN(convertedObj.id);
  if (isNan) {
    convertedObj.id = getValueByPath(data, idPath);
  }

  if(cityIdPath) {
   convertedObj.cityId = getValueByPath(data, cityIdPath);
  }
  return convertedObj;
}
