import { Injectable } from '@angular/core';
import LDClient, { LDContext } from 'launchdarkly-js-client-sdk';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@features/users/models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  client;

  private flags = new BehaviorSubject({});

  get flags$(): Observable<any> {
    return this.flags.asObservable();
  }

  get flagsSnapShot(): any {
    return this.flags.value;
  }

  constructor() { }

  init(user: User): Promise<User> {
    const { name, userRoles, email, id } = user;
    return new Promise((resolve, reject) => {
      const context = { kind: id ? 'user' : 'guest', name, roles: userRoles, email, key: id ? `${id}` : 'guest' };
      this.client = LDClient.initialize(environment.launchDarklyClient, context);
      this.client.on('ready', () => {
        const currentFlags = this.client.allFlags();
        this.flags.next(currentFlags);
        this.client.on('change', (flagChanges) => {
          const flags = Object.keys(flagChanges).reduce((group, key) => {
            group[key] = flagChanges[key]?.current;
            return group;
          }, {});
          this.flags.next({ ...this.flags.value, ...flags });
        });
        resolve(user);
      });
      this.client.on('failed', () => {
        console.error('FF: failed to connect launchDarkly');
        resolve(null);
      });
    });
  }

  isEnabled(feature: string): boolean {
    return this.flags.value[feature];
  }

  isEnabled$(feature: string): Observable<string> {
    return this.flags$.pipe(map((flags) => flags[feature]));
  }
}

