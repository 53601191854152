import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationItem } from '@shared-features/models/notification-item';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit {

  @Input() notification: NotificationItem;
  @Output() notificationClicked = new EventEmitter<NotificationItem>();

  constructor() { }

  ngOnInit(): void {
  }

  onNotificationClicked() {
    this.notificationClicked.emit(this.notification);
  }

}
