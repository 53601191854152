import { BlocState } from '@core/bloc/bloc-state';
import { Customer } from '@features/customers/models/customer';
import { Fleet } from '@features/fleets/models/fleet';
import { User } from '@features/users/models/user';
import { TruckType } from '@features/fleets/models/truck-type';

export abstract class ShipmentsFilterState extends BlocState {}

export class ShipmentsFilterReadyState extends ShipmentsFilterState {
    constructor(
      public customers: Customer[],
      public fleets: Fleet[],
      public truckTypes: TruckType[],
      public creators: User[],
      public customerLabel?: string[]

      ) {
      super();
    }
  }
