<div class="file-input" [ngClass]="{'file-input--dragging-over': isDragging }">
  <div
    class="file-input__drag-area"
    (dragover)="isDragging = true"
    (dragleave)="isDragging = false"
    *ngIf="multiple || (!multiple && !filesList.length)"
  >
    <div class="file-input__label">
      <app-row align="center" justify="space-between" gutter="x-xs">
        <app-col cols="auto">
          <app-icon name="attach-square" size="md" class="align-middle"></app-icon>
        </app-col>
        <app-col>{{filePlaceHolder||'Drag and drop your files here'}}</app-col>

        <app-col cols="auto">
          <span class="cursor-pointer" style="color: var(--color-primary-200)">{{(label || 'shared.Buttons.SelectFile') | translate}}</span>
        </app-col>
      </app-row>
    </div>
    <input
      class="file-input__input"
      *ngIf="showInput"
      type="file"
      #uploaderInput
      [accept]="accept"
      [multiple]="multiple"
      (change)="onInputValueChange($event)"
    />
  </div>

  <div class="file-input__preview-list preview-list">
    <app-row gutter="x-sm">
      <app-col cols="12" [xl]="isFirst ? 12 : 6" *ngFor="let file of filesList; let i = index; let isFirst = first" [@listAnimation]>
        <div class="preview-list__item" [class.preview-list__item--dark]="isFirst">
          <app-row align="center" gutter="x-xs" class="flex-nowrap">
            <app-col cols="auto">
              <div class="preview-list__item-icon flex align-center">
                <app-icon name="attach-square" size="md"></app-icon>
              </div>
            </app-col>
            <app-col class="col--text-container align-center" style="width: calc(100% - 9rem)">
              <div
                class="preview-list__item-title"
                [class.preview-list__item-title--clickable]="file && file.media"
                (click)="handleDownloadFile(file)"
              >
                <span class="text-ellipsis text-nowrap py-1">{{file.name}}</span>
              </div>
            </app-col>
            <app-col cols="auto" class="ml-auto">
              <button mat-icon-button type="button" *ngIf="file.media?.url" (click)="handleDownloadFile(file)">
                <app-icon name="bold-download-minimalistic" size="sm"></app-icon>
              </button>
            </app-col>
            <div class="col-auto">
              <button mat-icon-button type="button" (click)="removeFile(file)">
                <app-icon name="outline-trash" size="sm"></app-icon>
              </button>
            </div>
          </app-row>
          <mat-progress-bar *ngIf="progressMap[i] > 0" mode="determinate" [value]="file.media ? 100 : progressMap[i]"></mat-progress-bar>
        </div>
      </app-col>
    </app-row>
  </div>
</div>
