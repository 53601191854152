import { Truck } from '@features/fleets/models/truck';
import { Expose, Type } from 'class-transformer';
import { ProductType } from '../../shipments/models/product-type';

export class TruckHasShipment extends Truck {
  @Expose()
  shipmentId?: number;

  @Type(() => ProductType)
  @Expose()
  productType?: ProductType;
}
