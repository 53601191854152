import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateRangeType } from '@shared/enums/date-range-type.enum';
import { Lookup } from '@shared-features/models/Lookup.model';
interface Config {
  hidePrice: boolean;
  enableHidePriceOption: boolean;
  filter: {
    dateRange: { range: DateRangeType; startFrom?: string; endAt?: string };
    customers: Lookup[];
    customerLabels: Lookup[];
    enableCache: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DashboardGlobalStateService {
  private _config$ = new BehaviorSubject<Config>({
    hidePrice: false,
    enableHidePriceOption: true,
    filter: {
      dateRange: { range: DateRangeType.TODAY },
      customers: [],
      customerLabels: [],
      enableCache: true,
    },
  });
  private _lastRefreshTime$ = new BehaviorSubject<Date>(new Date(0));
  constructor() {}

  set config(options: Partial<Config>) {
    const currentConfig = this._config$.value;
    const newConfig = { ...currentConfig, ...options };
    this._config$.next(newConfig);
  }

  get config(): Config {
    return this._config$.value;
  }

  get config$(): Observable<Config> {
    return this._config$.asObservable();
  }

  set lastRefreshTime(date: Date) {
    const currentDate = new Date(this._lastRefreshTime$.value);
    const newRefreshTime = date || new Date();
    const newestRefreshTime = newRefreshTime > currentDate ? newRefreshTime : currentDate;
    this._lastRefreshTime$.next(newestRefreshTime);
  }

  get lastRefreshTime$(): Observable<Date> {
    return this._lastRefreshTime$.asObservable();
  }
}
