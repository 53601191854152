import { accentBoxIcon } from '../../icons/accent-box';
import { addCircleIcon } from '../../icons/add-circle';
import { aggregatorIcon } from '../../icons/aggregator';
import { allInvoiceIcon } from '../../icons/all-invoice';
import { allIcon } from '../../icons/all';
import { arrowCircleDownIcon } from '../../icons/arrow-circle-down';
import { arrowDownIcon } from '../../icons/arrow-down';
import { arrowSideLeftIcon } from '../../icons/arrow-side-left';
import { arrowSideRightIcon } from '../../icons/arrow-side-right';
import { arrowSwapIcon } from '../../icons/arrow-swap';
import { arrowUpIcon } from '../../icons/arrow-up';
import { attachSquareIcon } from '../../icons/attach-square';
import { avlIcon } from '../../icons/avl';
import { balanceIcon } from '../../icons/balance';
import { blendIcon } from '../../icons/blend';
import { boldArrowDownIcon } from '../../icons/bold-arrow-down';
import { boldArrowLeftIcon } from '../../icons/bold-arrow-left';
import { boldArrowRightIcon } from '../../icons/bold-arrow-right';
import { boldArrowUpIcon } from '../../icons/bold-arrow-up';
import { boldBlockCircleIcon } from '../../icons/bold-block-circle';
import { boldCpuIcon } from '../../icons/bold-cpu';
import { boldDocumentAltIcon } from '../../icons/bold-document-alt';
import { boldDocumentIcon } from '../../icons/bold-document';
import { boldDownloadSquareIcon } from '../../icons/bold-download-square';
import { boldDriverIcon } from '../../icons/bold-driver';
import { boldDropIcon } from '../../icons/bold-drop';
import { boldEditIcon } from '../../icons/bold-edit';
import { boldEditRectIcon } from '../../icons/bold-edit-rect';
import { boldExportIcon } from '../../icons/bold-export';
import { boldFileIcon } from '../../icons/bold-File';
import { boldInformationIcon } from '../../icons/bold-information';
import { boldLocationIcon } from '../../icons/bold-location';
import { boldLockIcon } from '../../icons/bold-lock';
import { boldLongArrowLeftIcon } from '../../icons/bold-long-arrow-left';
import { boldMinusCircleIcon } from '../../icons/bold-minus-circle';
import { boldMobileIcon } from '../../icons/bold-mobile';
import { boldMonitorIcon } from '../../icons/bold-monitor';
import { boldPasswordCheckIcon } from '../../icons/bold-password-check';
import { boldRecordCircleIcon } from '../../icons/bold-record-circle';
import { boldSearchIcon } from '../../icons/bold-search';
import { boldTemperatureIcon } from '../../icons/bold-temperature';
import { boldTimerIcon } from '../../icons/bold-timer';
import { boldUserCircleIcon } from '../../icons/bold-user-circle';
import { boldUserGroupOutlineIcon } from '../../icons/bold-user-group-outline';
import { boldVerifyIcon } from '../../icons/bold-verify';
import { boldWarningIcon } from '../../icons/bold-warning';
import { boldWhatsappIcon } from '../../icons/bold-whatsapp';
import { boxAddIcon } from '../../icons/box-add';
import { boxIcon } from '../../icons/box';
import { brakeIcon } from '../../icons/brake';
import { buildIcon } from '../../icons/build';
import { bulkBoxIcon } from '../../icons/bulk-box';
import { bulkStarIcon } from '../../icons/bulk-star';
import { calendarIcon } from '../../icons/calendar';
import { calqueIcon } from '../../icons/calque';
import { cementTruckIcon } from '../../icons/cement-truck';
import { chartIcon } from '../../icons/chart';
import { clipboardCloseIcon } from '../../icons/clipboard-close';
import { clipboardTickIcon } from '../../icons/clipboard-tick';
import { closeCircleIcon } from '../../icons/close-circle';
import { closeSquareIcon } from '../../icons/close-square';
import { contactIcon } from '../../icons/contact';
import { crownIcon } from '../../icons/crown';
import { customersIcon } from '../../icons/customers';
import { detailsIcon } from '../../icons/details';
import { distanceIcon } from '../../icons/distance';
import { documentIcon } from '../../icons/document';
import { driverIcon } from '../../icons/driver';
import { drivingIcon } from '../../icons/driving';
// import { editIconIcon } from '../../icons/edit-icon';
import { elmLogoIcon } from '../../icons/elm-logo';
import { engineActiveIcon } from '../../icons/engine-active';
import { engineIdleIcon } from '../../icons/engine-idle';
import { exclamationMarkIcon } from '../../icons/exclamation-mark';
import { externalUrlIcon } from '../../icons/external-url';
import { fleetIcon } from '../../icons/fleet';
import { fromIcon } from '../../icons/from';
import { globalIcon } from '../../icons/global';
import { groupSmallIcon } from '../../icons/group-small';
import { groupIcon } from '../../icons/group';
import { groupedPackageIcon } from '../../icons/grouped-package';
import { headphoneIcon } from '../../icons/headphone';
import { homeIcon } from '../../icons/home';
import { inProgressIcon } from '../../icons/in-progress';
import { infoCircleIcon } from '../../icons/info-circle';
import { invoicesIcon } from '../../icons/invoices';
import { linearMenuIcon } from '../../icons/linear-menu';
import { linkIcon } from '../../icons/link';
import { liveTrackingIcon } from '../../icons/live-tracking';
import { logoutIcon } from '../../icons/logout';
import { logsIcon } from '../../icons/logs';
import { madarProvidersIcon } from '../../icons/madar-providers';
import { manageIcon } from '../../icons/manage';
import { maskGroupIcon } from '../../icons/mask-group';
import { messageQuestionIcon } from '../../icons/message-question';
import { minusCirlceIcon } from '../../icons/minus-cirlce';
import { mobileIcon } from '../../icons/mobile';
import { moneyAccentIcon } from '../../icons/money-accent';
import { moneyIcon } from '../../icons/money';
import { moreIcon } from '../../icons/more';
import { notificationIcon } from '../../icons/notification';
import { outlineAddSquareIcon } from '../../icons/outline-add-square';
import { outlineArrowRightIcon } from '../../icons/outline-arrow-right';
import { outlineBarcodeIcon } from '../../icons/outline-barcode';
import { outlineCloseCircleIcon } from '../../icons/outline-close-circle';
import { outlineCopyIcon } from '../../icons/outline-copy';
import { outlineDocumentIcon } from '../../icons/outline-document';
import { outlineEdit2Icon } from '../../icons/outline-edit-2';
import { outlineEyeIcon } from '../../icons/outline-eye';
import { outlineScanIcon } from '../../icons/outline-scan';
import { outlineTickCircleIcon } from '../../icons/outline-tick-circle';
import { outlineWalletIcon } from '../../icons/outline-wallet';
import { outlineWarningIcon } from '../../icons/outline-warning';
import { overDueIcon } from '../../icons/over-due';
import { paidStatusIcon } from '../../icons/paid-status';
import { passwordCheckIcon } from '../../icons/password-check';
import { personIcon } from '../../icons/person';
import { primaryEditIcon } from '../../icons/primary-edit';
import { profileUserIcon } from '../../icons/profile-user';
import { refreshSquareIcon } from '../../icons/refresh-square';
import { removeIcon } from '../../icons/remove';
import { reportsIcon } from '../../icons/reports';
import { rightArrowIcon } from '../../icons/right-arrow';
import { rightTickCircleIcon } from '../../icons/right-tick-circle';
import { rightIcon } from '../../icons/right';
import { routeIcon } from '../../icons/route';
import { searchLoactionIcon } from '../../icons/search-loaction';
import { settingsIcon } from '../../icons/settings';
import { shipmentsIcon } from '../../icons/shipments';
import { showMapIcon } from '../../icons/show-map';
import { sortArrowIcon } from '../../icons/sort-arrow';
import { speedIcon } from '../../icons/speed';
import { stopIcon } from '../../icons/stop';
// import { tagIcon } from '../../icons/tag';
import { temperatureIcon } from '../../icons/temperature';
import { tickCircleIcon } from '../../icons/tick-circle';
import { tickSquareIcon } from '../../icons/tick-square';
import { toIcon } from '../../icons/to';
import { transportationPaymentsIcon } from '../../icons/transportation-payments';
import { tripPlanningIcon } from '../../icons/trip-planning';
import { truckMdIcon } from '../../icons/truck-md';
import { truckTickIcon } from '../../icons/truck-tick';
import { truckIcon } from '../../icons/truck';
import { underReviewIcon } from '../../icons/under-review';
import { userTagIcon } from '../../icons/user-tag';
import { warehouseIcon } from '../../icons/warehouse';
import { warningAccentIcon } from '../../icons/warning-accent';
import { trapezoidEdgeIcon } from '../../icons/trapezoid-edge';
import { regularLocationIcon } from '../../icons/regular-location';
import { defaultLocationIcon } from '../../icons/default-location';

import { addSquareIcon } from '../../icons/add-square';
import { boldAllIcon } from '../../icons/bold-all';
import { boldArrowIcon } from '../../icons/bold-arrow';
import { boldBoxIcon } from '../../icons/bold-box';
import { boldLoginIcon } from '../../icons/bold-login';
import { boldMessageQuestionIcon } from '../../icons/bold-message-question';
import { boldMinusIcon } from '../../icons/bold-minus';
import { boldNoteIcon } from '../../icons/bold-note';
import { boldTextDocumentIcon } from '../../icons/bold-text-document';
import { bulkChartSquareIcon } from '../../icons/bulk-chart-square';
import { clockIcon } from '../../icons/clock';
import { correctIcon } from '../../icons/correct';
import { editIcon } from '../../icons/edit';
import { editAltIcon } from '../../icons/edit-alt';
import { flagIcon } from '../../icons/flag';
import { infoIcon } from '../../icons/info';
import { informationIcon } from '../../icons/information';
import { likeTagIcon } from '../../icons/like-tag';
import { locationIcon } from '../../icons/location';
import { locationAltIcon } from '../../icons/location-alt';
import { routingIcon } from '../../icons/routing';
import { menuVertIcon } from '../../icons/menu-vert';
import { trashIcon } from '../../icons/trash';
import { wrongIcon } from '../../icons/wrong';
import { searchIcon } from '../../icons/search';
import { bulkRoutingIcon } from '../../icons/bulk-routing';
import { outlineTimerIcon } from '../../icons/outline-timer';
import { truckTimeIcon } from '../../icons/truck-time';
import { boldArrowDownCircleIcon } from '../../icons/bold-arrow-down-circle';
import { boldArrowUpCircleIcon } from '../../icons/bold-arrow-up-circle';
import { sidebarRightIcon } from '../../icons/sidebar-right';
import { outlineCalendarIcon } from '../../icons/outline-calendar';
import { outlineAddIcon } from '../../icons/outline-add';
import { boldArrowCircleDownIcon } from '../../icons/bold-arrow-circle-down';
import { boldNotificationStatusIcon } from '../../icons/bold-notification-status';
import { highIcon } from '../../icons/high';
import { urgentIcon } from '../../icons/urgent';
import { lowIcon } from '../../icons/low';
import { normalIcon } from '../../icons/normal';
import { urlIcon } from '../../icons/url';
import { bulkTicketIcon } from '../../icons/bulk-ticket';
import { boldWalletIcon } from '../../icons/bold-wallet';
import { boldMagicStickIcon } from '../../icons/bold-magic-stick';
import { boldLinkIcon } from 'src/app/icons/bold-link';
import { boldEyeIcon } from 'src/app/icons/bold-eye';
import { boldRefreshIcon } from 'src/app/icons/bold-refresh';
import { outlineTrashIcon } from 'src/app/icons/outline-trash';
import { boldDownloadMinimalisticIcon } from 'src/app/icons/bold-download-minimalistic';

export const ICONS = [
  accentBoxIcon,
  addCircleIcon,
  aggregatorIcon,
  allInvoiceIcon,
  allIcon,
  arrowCircleDownIcon,
  arrowDownIcon,
  arrowSideLeftIcon,
  arrowSideRightIcon,
  arrowSwapIcon,
  arrowUpIcon,
  attachSquareIcon,
  avlIcon,
  balanceIcon,
  blendIcon,
  boldArrowDownIcon,
  boldArrowLeftIcon,
  boldArrowRightIcon,
  boldArrowUpIcon,
  boldBlockCircleIcon,
  boldCpuIcon,
  boldDocumentAltIcon,
  boldDocumentIcon,
  boldDownloadSquareIcon,
  boldDriverIcon,
  boldDropIcon,
  boldEditIcon,
  boldEditRectIcon,
  boldExportIcon,
  boldFileIcon,
  boldInformationIcon,
  boldLocationIcon,
  boldLockIcon,
  boldLongArrowLeftIcon,
  boldMinusCircleIcon,
  boldMobileIcon,
  boldMonitorIcon,
  boldPasswordCheckIcon,
  boldRecordCircleIcon,
  boldSearchIcon,
  boldTemperatureIcon,
  boldTimerIcon,
  boldUserCircleIcon,
  boldUserGroupOutlineIcon,
  boldVerifyIcon,
  boldWarningIcon,
  boldWhatsappIcon,
  boxAddIcon,
  boxIcon,
  brakeIcon,
  buildIcon,
  bulkBoxIcon,
  bulkStarIcon,
  calendarIcon,
  calqueIcon,
  cementTruckIcon,
  chartIcon,
  clipboardCloseIcon,
  clipboardTickIcon,
  closeCircleIcon,
  closeSquareIcon,
  contactIcon,
  crownIcon,
  customersIcon,
  detailsIcon,
  distanceIcon,
  documentIcon,
  driverIcon,
  drivingIcon,
  // editIconIcon,
  elmLogoIcon,
  engineActiveIcon,
  engineIdleIcon,
  exclamationMarkIcon,
  externalUrlIcon,
  fleetIcon,
  fromIcon,
  globalIcon,
  groupSmallIcon,
  groupIcon,
  groupedPackageIcon,
  headphoneIcon,
  homeIcon,
  inProgressIcon,
  infoCircleIcon,
  invoicesIcon,
  linearMenuIcon,
  linkIcon,
  liveTrackingIcon,
  logoutIcon,
  logsIcon,
  madarProvidersIcon,
  manageIcon,
  maskGroupIcon,
  messageQuestionIcon,
  minusCirlceIcon,
  mobileIcon,
  moneyAccentIcon,
  moneyIcon,
  moreIcon,
  notificationIcon,
  outlineAddSquareIcon,
  outlineArrowRightIcon,
  outlineBarcodeIcon,
  outlineCloseCircleIcon,
  outlineCopyIcon,
  outlineDocumentIcon,
  outlineEdit2Icon,
  outlineEyeIcon,
  outlineScanIcon,
  outlineTickCircleIcon,
  outlineWalletIcon,
  outlineWarningIcon,
  overDueIcon,
  paidStatusIcon,
  passwordCheckIcon,
  personIcon,
  primaryEditIcon,
  profileUserIcon,
  refreshSquareIcon,
  removeIcon,
  reportsIcon,
  rightArrowIcon,
  rightTickCircleIcon,
  rightIcon,
  routeIcon,
  searchLoactionIcon,
  settingsIcon,
  shipmentsIcon,
  showMapIcon,
  sortArrowIcon,
  speedIcon,
  stopIcon,
  // tagIcon,
  temperatureIcon,
  tickCircleIcon,
  tickSquareIcon,
  toIcon,
  transportationPaymentsIcon,
  tripPlanningIcon,
  truckMdIcon,
  truckTickIcon,
  truckIcon,
  underReviewIcon,
  userTagIcon,
  warehouseIcon,
  warningAccentIcon,
  trapezoidEdgeIcon,
  regularLocationIcon,
  defaultLocationIcon,
  addSquareIcon,
  boldAllIcon,
  boldArrowIcon,
  boldBoxIcon,
  boldLoginIcon,
  boldMessageQuestionIcon,
  boldMinusIcon,
  boldNoteIcon,
  boldTextDocumentIcon,
  bulkChartSquareIcon,
  clockIcon,
  correctIcon,
  editIcon,
  editAltIcon,
  flagIcon,
  infoIcon,
  informationIcon,
  likeTagIcon,
  locationIcon,
  locationAltIcon,
  routeIcon,
  routingIcon,
  menuVertIcon,
  trashIcon,
  wrongIcon,
  boldArrowDownCircleIcon,
  boldArrowUpCircleIcon,
  sidebarRightIcon,
  searchIcon,
  shipmentsIcon,
  bulkRoutingIcon,
  fleetIcon,
  liveTrackingIcon,
  invoicesIcon,
  manageIcon,
  locationAltIcon,
  outlineTimerIcon,
  truckTimeIcon,
  outlineAddIcon,
  outlineCalendarIcon,
  boldArrowCircleDownIcon,
  boldNotificationStatusIcon,
  highIcon,
  urgentIcon,
  lowIcon,
  normalIcon,
  urlIcon,
  bulkTicketIcon,
  boldWalletIcon,
  boldMagicStickIcon,
  boldLinkIcon,
  boldEyeIcon,
  boldRefreshIcon,
  outlineTrashIcon,
  boldDownloadMinimalisticIcon,
];
