import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NamedField } from '@shared/interfaces/field.interface';
import { FieldBase } from './field-base';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox-field',
  template: `
    <ng-container [formGroup]="group">
      <mat-checkbox
        class="field__input"
        #element
        [id]="field.name + '_' + uuid"
        [color]="color"
        [formControl]="group.get(field.name)"
        (click)="fieldClick.emit($event)"
      >
        {{ field.label | translate }}
        <span *ngIf="isRequired" class="text-warn">*</span>
      </mat-checkbox>
      <mat-error *ngIf="errors.length" class="field__error">
        <app-field-errors [errors]="errors"></app-field-errors>
      </mat-error>
    </ng-container>
  `,
  host: { class: 'field' },
})
export class CheckboxFieldComponent extends FieldBase {
  @Input() field: NamedField;
  @Input() group: FormGroup | FormArray;

  @ViewChild('element') element: ElementRef;
}
