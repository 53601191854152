import { Expose } from 'class-transformer';
import { Model } from '../../shared-features/models/model';

export class PalletItem extends Model {
  @Expose() id: number;
  @Expose() itemDescription: string;
  @Expose() quantity: number;
  @Expose() batch: string;
  @Expose() genericNumber: number;
  @Expose() tradeCode?: number;
  @Expose() acceptedQuantity: number;
  @Expose() rejectedQuantity: number;
}
