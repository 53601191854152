import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,

  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { UserRoleService } from '@core/services/user-role.service';
import { User } from '@features/users/models/user';
import { Lookup } from '@shared-features/models/Lookup.model';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ALL_OPTION_VALUE } from '@shared/components/forms/controls/lazy-dropdown/lazy-dropdown.component';
import { CompanyLabelType } from '@shared/enums/company-label-type.enum';
import { RolesConstants } from '@shared/model/roles-constants';
import { modelToLookup } from '@shared/utils/model-to-lookup.util';
import { parseQueryParam } from '@shared/utils/parse-query-param';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-list-filter',
  templateUrl: './shipment-list-filter.component.html',
  styleUrls: ['./shipment-list-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentListFilterComponent extends BaseComponent implements OnInit {
  @Output() closeFilter = new EventEmitter();
  form: FormGroup;
  user: User;
  showCreatorByField;
  params: any = null;

  isCreateFilter = false;
  isEditFilter = false;
  favFiltersIndex = -1;

  customerLabelsOptions = Object.keys(CompanyLabelType).map((key) =>
    Object.assign(new Lookup(), {
      id: key,
      value: CompanyLabelType[key],
    })
  );

  constructor(

    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private userRoleService: UserRoleService
  ) {
    super();
  }

  ngOnInit() {
    this.user = this.authService.currentUser$.value;
    this.showCreatorByField =
      !this.router.url.includes(RoutesUtil.B2bShipmentsList.path) &&
      this.userRoleService.isUserHasRoles(RolesConstants.USERS_SHIPMENTS_FILTER_VIEW);

    this.subscribeToQueryParams();
    this.initForm();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  initForm() {
    this.favFiltersIndex = this.params?.favFiltersIndex
    const params = parseQueryParam(
      this.params,
      ['customerId', 'customerName', 'fleetId', 'fleetName', 'customerLabel', 'creator', 'creatorName','assignedBy','assignedByName'],
      ['withoutPrice', 'withoutDeliveryNote', 'isReceived',]
    );

    this.form = this.formBuilder.group({
      filterName: [],
      customersIds: [],
      fleetsIds: [],
      creator: [],
      assignedBy:[],
      customerLabels: [],
      withoutPrice: [],
      withoutDeliveryNote: [],
      isReceived: [],
      favFilter: [],
    });

    this.setFormValues(params)
    this.changeDetectorRef.markForCheck();
  }

  setFormValues(data) {
    const selectedCreator = this.generateSelectedOptions(data, 'creator', 'Creator', 'creatorName');
    const selectedAssignedBy = this.generateSelectedOptions(data, 'assignedBy', 'Assigned By', 'assignedByName');

    const creator = this.showCreatorByField
      ? selectedCreator
      : [
          Object.assign(new Lookup(), {
            id: this.user.id,
            value: this.user.firstName + ' ' + this.user.lastName,
          }),
        ];

    const assignedBy = this.showCreatorByField
      ? selectedAssignedBy
      : [
          Object.assign(new Lookup(), {
            id: this.user.id,
            value: this.user.firstName + ' ' + this.user.lastName,
          }),
        ];

    const selectedCustomer = this.generateSelectedOptions(
      data,
      'customerId',
      'Customer',
      'customerName'
    );

    const selectedFleet = this.generateSelectedOptions(data, 'fleetId', 'Fleet', 'fleetName');

    const customerLabels =
      data.customerLabel?.includes(ALL_OPTION_VALUE) ||
      data?.customerLabel?.[0] == 'All' ||
      data?.customerLabel?.length === 0
        ? this.generateAllLookup('Customer Labels')
        : (this.customerLabelsOptions || []).filter((customerLabel) => {
            return (data.customerLabel || []).some((el) => el == customerLabel?.id);
          });

    this.form.patchValue({
      filterName: data.filterName,
      customersIds: selectedCustomer,
      fleetsIds: selectedFleet,
      creator: creator,
      assignedBy: assignedBy,
      customerLabels: customerLabels,
      withoutPrice: data.withoutPrice,
      withoutDeliveryNote: data.withoutDeliveryNote,
      isReceived: data.isReceived,
    });

    this.form.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
  }

  generateAllLookup(string) {
    return [
      Object.assign(new Lookup(), {
        value: 'All ' + string,
        id: ALL_OPTION_VALUE,
      }),
    ];
  }

  generateSelectedOptions(data: any, dataKey: string, allLookupOption: any, key: string): any {
    const allLookup = this.generateAllLookup(allLookupOption);
    const isArray = Array.isArray(data[dataKey]);
    const includesAll = isArray ? data[dataKey]?.includes(ALL_OPTION_VALUE) : false;
    const isAllFirst = isArray ? data[dataKey]?.[0] === 'All' : false;
    const isEmpty = isArray ? data[dataKey]?.length === 0 : false;

    if (includesAll || isAllFirst || isEmpty) {
      return allLookup;
    } else {
      return (data[dataKey] || []).map((id: any, i: number) => {
        const obj: any = { id: id, name: data[key][i] };
        return modelToLookup(obj, 'id', 'name');
      });
    }
  }

  clickCreateFilter(event) {
    this.isCreateFilter = event;
  }

  clickEditFilter(event) {
    this.isEditFilter = event;
  }

  setFavFiltersIndex(event) {
    this.favFiltersIndex = event;
  }
}
