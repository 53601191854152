import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { SharedConstants } from '@shared/model/shared-constants';
import {  Observable } from 'rxjs';
import { DateRangeType } from "@shared/enums/date-range-type.enum";
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { Status } from "@features/shipments/enums/status.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { FilterService } from '@shared/services/filter.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component/base.component';

@Component({
  selector: 'app-customers-filter',
  templateUrl: './customers-filter.component.html',
  styleUrls: ['./customers-filter.component.scss'],
})
export class CustomersFilterComponent extends BaseComponent implements OnInit {
  form: UntypedFormGroup;
  sharedConstants = SharedConstants;
  status = [Status.All, Status.ACTIVE, Status.INACTIVE];
  filterByDate = DateRangeType;
  dateRangeType = DateRangeType;

  @Input() action: Observable<void>;
  @Input() pageIndex: number = 1;
  params: any = null;

  constructor(private filterService: FilterService,
    private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.subscribeFilterAction();
    this.subscribeQueryParams();
  }


  subscribeFilterAction() {
    this.action.pipe(takeUntil(this.destroy$)).subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;

        case FormAction.Cancel:
          this.cancelFormSubmission();
          break;
      }
    });
  }


  subscribeQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
      this.initForm();
    });
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      this.form = new UntypedFormGroup({
        status: new UntypedFormControl(this.params.status ? this.params.status : Status.All),
        activationDate: new UntypedFormControl(this.params.activationDate ? this.params.activationDate : DateRangeType.ALL_TIME),
      });
    } else {
      this.form = new UntypedFormGroup({
        status: new UntypedFormControl(Status.All),
        activationDate: new UntypedFormControl(DateRangeType.ALL_TIME),
      });
    }
  }

  resetForm() {
    this.form.controls['status'].setValue(Status.All);
    this.form.controls['activationDate'].setValue(DateRangeType.ALL_TIME);
    this.submitForm();
  }

  submitForm() {
    let obj = this.form.value;
    obj.tabGroup = TabGroup.Customers;
    obj.tabIndex = TabIndex.AllCustomers;
    obj.pageIndex = this.pageIndex;
    this.filterService.navigateToTargetPageWithFilter(obj);
  }

  cancelFormSubmission() {
    if (this.params && Object.keys(this.params).length !== 0) {
      this.form.controls['status'].setValue(this.params.status);
      this.form.controls['activationDate'].setValue(this.params.activationDate);
    } else this.resetForm();
  }

  onSelectClicked(controlName: string, event: MatSelectChange) {
    this.form.controls[controlName].setValue(event.value);
  }
}
