import { Icon } from './icon.model';

export interface ConfirmationConfig {
  message: string;
  headerTitle: string;
  type?: ConfirmationType;
  icon?: Icon;
  warnAction?: boolean;
  mainActionText?: string;
  cancelActionText?: string;
  checkboxOption?: string;
  textAreaText?: string;
  enableCancel?: boolean;
}

export enum ConfirmationType {
  DestructiveAction,
  NonDestructiveAction,
  Informative,
}

const DEFAULT_CONFIG: ConfirmationConfig = {
  message: '',
  headerTitle: '',
  type: ConfirmationType.DestructiveAction,
  warnAction: true,
};

export class Confirmation implements ConfirmationConfig {
  constructor(
    public type: ConfirmationType = ConfirmationType.NonDestructiveAction,
    public headerTitle: string = '',
    public message: string = '',
    public mainActionText?: string,
    public cancelActionText?: string,
    public warnAction: boolean = false,
    public checkboxOption?: string,
    public textAreaText?: string,
    public icon?: Icon,
    public enableCancel: boolean = true
  ) {}
}

export class IconicConfirmation extends Confirmation {
  config: ConfirmationConfig;
  constructor(config: ConfirmationConfig) {
    const {
      type,
      headerTitle,
      message,
      mainActionText,
      cancelActionText,
      warnAction,
      checkboxOption,
      textAreaText,
      icon,
      enableCancel,
    } = Object.assign({}, DEFAULT_CONFIG, config);
    super(
      type,
      headerTitle,
      message,
      mainActionText,
      cancelActionText,
      warnAction,
      checkboxOption,
      textAreaText,
      icon,
      enableCancel
    );
  }
}
