<div class="filter-wrapper">
  <div class="header">
    <h3 class="title">{{ "Filter.Filters" | translate }}</h3>
    <span class="reset" (click)="emitEventToFilterForm(filterAction.Reset)">{{ "Filter.Reset" | translate }}</span>
  </div>

  <ng-container [ngSwitch]="tabGroup">
    <ng-container *ngSwitchCase="groups.Shipments">
      <app-shipments-filter *ngIf="isOpened" [action]="filterAction$"></app-shipments-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.ShipmentsB2c">
      <app-shipments-filter *ngIf="isOpened" [action]="filterAction$"></app-shipments-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.Customers">
      <ng-container [ngSwitch]="tabIndex">
        <ng-container *ngSwitchCase="indices.AllCustomers">
          <app-customers-filter [pageIndex]="pageIndex" [action]="filterAction$"></app-customers-filter>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="groups.Providers">
      <ng-container [ngSwitch]="tabIndex">
        <ng-container *ngSwitchCase="indices.AllProviders">
          <app-providers-filter [pageIndex]="pageIndex" [action]="filterAction$"></app-providers-filter>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="groups.Provider">
      <ng-container [ngSwitch]="tabIndex">
        <ng-container *ngSwitchCase="indices.Drivers">
          <app-drivers-filter [pageIndex]="pageIndex" [tabIndex]="tabIndex" [tabGroup]="tabGroup" [action]="filterAction$"></app-drivers-filter>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="groups.Dashboard">
      <ng-container [ngSwitch]="tabIndex">
        <ng-container *ngSwitchCase="indices.OrderStatus">
          <app-order-status-filter [action]="filterAction$"></app-order-status-filter>
        </ng-container>

        <ng-container *ngSwitchCase="indices.OperationPerformance">
          <app-operation-performance-filter [action]="filterAction$"></app-operation-performance-filter>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="groups.DeliveryNotes">
      <app-delivery-notes-filter [action]="filterAction$"></app-delivery-notes-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.CustomerInvoices">
      <app-customer-invoices-filter [action]="filterAction$"></app-customer-invoices-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.TransportationPayments">
      <app-transportation-payments-shipments-filter [tabIndex]="tabIndex" [action]="filterAction$"></app-transportation-payments-shipments-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.B2CRequestLogs">
      <app-b2c-request-logs-filter [action]="filterAction$"></app-b2c-request-logs-filter>
    </ng-container>

    <ng-container *ngSwitchCase="groups.RulesB2C">
      <app-b2c-rules-filter [action]="filterAction$"></app-b2c-rules-filter>
    </ng-container>

  </ng-container>

  <div class="flex column btns">
    <button mat-raised-button class="btn-primary btn" (click)="emitEventToFilterForm(filterAction.Submit)">{{ "Buttons.Apply" | translate }}</button>

    <button mat-raised-button class="btn-secondary btn" (click)="emitEventToFilterForm(filterAction.Cancel)">
      {{ "Buttons.Cancel" | translate }}
    </button>
  </div>
</div>
