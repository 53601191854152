import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class ElmFleetInfo extends Model {

    @Expose() plateNumber: string;
    @Expose() plateText1: string;
    @Expose() plateText2: string;
    @Expose() plateText3: string;
    @Expose() ownerName: string
    @Expose() licenseExpiryDate: Date = null;
    @Expose() plateNumberDetails: string;
    @Expose() ownerId: number;
    @Expose() elmObj: any;

  constructor() {
    super();
  }
  }
