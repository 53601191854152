import { Model } from '@shared-features/models/model';
import { TruckOperationStatus } from '@features/fleets/enums/truck-operation-status.enum';
import { Expose } from 'class-transformer';
import { TruckAvailableStatus } from '@features/fleets/enums/truck-available-status.enum';

export class OperationStatusCount extends Model {
  @Expose()
  totalTrucksCount: number;
  @Expose()
  busyTruckCount: number;
  @Expose()
  freeTruckCount: number;
  @Expose()
  trucksNotReadyCount: number;
}
