import { Pipe, PipeTransform } from '@angular/core';

import { UserRole } from "@features/users/enums/user-role.enum";
import { UserRoleService } from '@core/services/user-role.service';

@Pipe({
  name: 'userRoleChecker'
})
export class UserRoleCheckerPipe implements PipeTransform {

  constructor(private userRoleService: UserRoleService) {}

  transform(checkingRoles: UserRole[]): boolean {
    return this.userRoleService.isUserHasRoles(checkingRoles);
  }

}
