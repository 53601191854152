import { Phrases } from '@shared/model/locales.model';

export default {
  welcome: 'مرحبا',
  'Generic.Amount': '{{amount}} ر.س',
  'Buttons.Cancel': 'الغاء',
  'Buttons.Submit': 'تقديم',
  'Buttons.Reset': 'إعادة تعيين',
  'Buttons.Apply': 'تطبيق',
  'Buttons.Save': 'حفظ',
  'Buttons.Export': 'استخراج',
  'Buttons.Print': 'طباعة',
  'Buttons.Details': 'التفاصيل',
  'Buttons.Confirm': 'تأكيد',
  'Buttons.Add': 'إضافة',
  'Buttons.Update': 'تحديث',
  'Buttons.Edit': 'تعديل',
  'Buttons.Delete': 'حذف',
  'Buttons.Remove': 'حذف',
  'Buttons.Search': 'بحث',
  'Buttons.Continue': 'متابعة',
  'Buttons.SelectFile': 'اختر ملف',
  'components.ColumnOptions.CustomTitle': 'خيارات الأعمدة المخصصة',
  'components.ColumnOptions.Title': 'خيارات الأعمدة',
  'components.ColumnOptions.ApplyMyColumnSettings': 'تطبيق إعدادات الأعمدة الخاصة بي',
  'components.ColumnOptions.SettingsHint': 'لديك ما يصل إلى 3 إعدادات للأعمدة للاستخدام السريع',
  'components.ColumnOptions.CreateCustomColumnSettings': 'إنشاء إعدادات الأعمدة المخصصة',
  'components.ColumnOptions.NoCustomAdded': 'لم يتم إضافة إعدادات مخصصة',
  'components.ColumnOptions.ColumnSettingName': 'اسم إعداد العمود',
  'components.ColumnOptions.DisplayedColumns': 'الأعمدة المعروضة ({{count}})',
  'components.ColumnOptions.NonDisplayedColumns': 'الأعمدة غير المعروضة ({{count}})',
  'components.ColumnOptions.SelectToDisplay':
    '<b>حدد للعرض،</b> يتعلق هذا الحقل بإضافة عمود آخر إلى المحتوى الخاص بك.',
  'components.ColumnOptions.NameYourColumnSetting': 'اسم حساب العمود الخاص بك',
  'components.ColumnOptions.DisplayAll': 'عرض الكل',
  'components.cardItemsList.Orders': 'الطلبات',
  'components.cardItemsList.Pallet': 'باليت',
  'components.cardItemsList.Items': 'عناصر',
  'components.cardItemsList.PalletItems': 'عناصر البالتة',
  'components.cardItemsList.LineItems': 'Line Items',
  'components.cardItemsList.NoPalletSelected': 'لم يتم تحديد أي باليت',
  'components.cardItemsList.OnceYouSelectTheDetailsWillAppearHere':
    'بمجرد تحديدك، ستظهر التفاصيل هنا',
  'components.cardItemsList.Qty': 'الكمية',
  'components.cardItemsList.Accepted': 'مقبول',
  'components.cardItemsList.Returned': 'مرتجع',
  'components.cardItemsList.Batch': 'الدفعة',
  'components.cardItemsList.EmptyPalletHint': 'عندما تقوم بالاختيار ستظهر التفاصيل هنا.',
  'components.activity.activity': 'السجل',
  'components.activity.TheInvoice': 'الفاتورة',
  'components.activity.MarkedAsPaid': 'وضع علامة مدفوعة',
  'components.activity.Converted': 'تم تحويل الفاتورة إلى متأخرة',
  'components.confirmation.cancelTitle': 'تأكيد الإلغاء!',
  'components.confirmation.cancelText':
    'هل أنت متأكد أنك تريد إلغاء هذا؟ سيتم إزالة جميع البيانات المملوءة بشكل دائم ولا يمكن التراجع عنها.',
  'components.confirmation.cancelDiscard': 'لا ، أبقها',
  'components.confirmation.cancelConfirm': 'نعم ، إلغاء',
  'components.confirmation.discard': 'تجاهل',
  'components.confirmation.confirm': 'تأكيد',
  'components.confirmation.YesConfirm': 'نعم، تأكيد',
  'components.confirmation.NoCancel': 'لا، إلغاء',
  'lazyDropdown.all': 'كل {{items}}',
  'components.sortMenu.MostRecent': 'الاكثر تحديثا',
  'components.sortMenu.NameAZ': 'من الاقل الى الاعلى',
  'components.sortMenu.NameZA': 'من الاعلى الى الاقل',
  'components.sortMenu.sort': 'ترتيب',
  'components.sortMenu.HighNoOfPallets': 'اعلي رقم من الالواح',
  'components.sortMenu.LowNoOfPallets': 'اقل رقم من الالواح',
  'components.sortMenu.ALL': 'الكل',
  'components.sortMenu.AMB': 'البيئة',
  'components.sortMenu.Chiller': 'الثلاجة',
  'components.sortMenu.Frozen': 'مجمد',
  'components.sortMenu.Deep_Frozen': 'شديد التجميد',
  'Enum.InvoiceAction.CREATED': 'انشئ',
  'Enum.InvoiceAction.UPDATED': 'عدل',
  'Enum.InvoiceAction.APPROVED': 'وافق',
  'Enum.InvoiceAction.REJECTED': 'رفض',
  'Enum.InvoiceAction.PAID': 'دفع',
  'Enum.columnOptions.id': 'الرقم التعريفي',
  'Enum.columnOptions.numberOfRequests': 'عدد الطلبات',
  'Enum.columnOptions.fees': 'الرسوم',
  'Enum.columnOptions.trip': 'الرحلة',
  'Enum.columnOptions.status': 'الحالة',
  'Enum.columnOptions.createdBy': 'تم الإنشاء بواسطة',
  'Enum.columnOptions.FleetsB2c': 'الناقل',
  'Enum.columnOptions.truckType': 'نوع الشاحنة',
  'Enum.columnOptions.driverName': 'اسم السائق',
  'Enum.columnOptions.driverAllowance': 'بدل السائق',
  'Enum.columnOptions.assignedBy': 'تم التعيين بواسطة',
  'Enum.columnOptions.trackingType': 'نوع التتبع',
  'Enum.columnOptions.deliveryNote': 'مذكرة التسليم',
  'Enum.columnOptions.notes': 'ملاحظات',
  'Enum.columnOptions.createdAt': 'تاريخ الإنشاء',
  'Enum.columnOptions.customerName': 'اسم العميل',
  'Enum.columnOptions.from': 'من',
  'Enum.columnOptions.to': 'إلى',
  'Enum.columnOptions.cost': 'التكلفة',
  'Enum.columnOptions.price': 'السعر',
  'Enum.columnOptions.margin': 'الهامش',
  'Enum.columnOptions.mobileNumber': 'رقم الجوال',
  'Enum.columnOptions.iqamaId': 'رقم الإقامة',
  'Enum.columnOptions.driverId': 'رقم السائق',
  'Enum.columnOptions.name': 'الاسم',
  'Enum.columnOptions.iqama': 'الإقامة',
  'Enum.columnOptions.sponsor': 'الكفيل',
  'Enum.columnOptions.noOfShipment': 'عدد الشحنات',
  'Enum.columnOptions.driverMobileNumber': 'رقم جوال السائق',
  'Enum.columnOptions.truckId': 'رقم الشاحنة',
  'Enum.columnOptions.truckSponsor': 'كفيل الشاحنة',
  'Enum.columnOptions.plateNumber': 'رقم اللوحة',
  'Enum.columnOptions.model': 'الموديل',
  'Enum.columnOptions.Sponsor': 'الكفيل',
  'Enum.OrderType.StockToCustomerStock': 'نقل المخزون',
  'Enum.OrderType.StoTransfer': 'نقل المخزون',
  'Enum.OrderType.Z122': 'تحويل المخزون',
  'Enum.OrderType.CustomerOutboundOrders': 'نقل الصادرات',
  'Enum.OrderType.CustomerReturn': 'إرجاع العملاء',
  'Enum.OrderType.CustomerReturnNupcoStock': 'إرجاع العملاء لمخزون Nupco',
  'Enum.OrderType.NupcoSalesReturns': 'إرجاعات مبيعات Nupco',
  'Enum.OrderType.DistributionOutboundOrder': 'طلب صادر للتوزيع',
  'Enum.OrderType.TransferOrders': 'طلبات النقل',
  'Enum.OrderType.VendorSupplierReturns': 'إرجاعات الموردين والموردين',
  'Enum.OrderType.SalesOrder': 'طلب البيع',
  'Enum.OrderType.NupcoRoyalCourt': 'محكمة نوبكو الملكية',
  'Enum.OrderType.NupcoDonationIssue': 'مشكلة التبرعات Nupco',
  'Enum.OrderType.ScrapOrders': 'طلبات الخردة',
  'Enum.OrderType.PrepareOrder': 'تجهيز الطلب',
  'Enum.OrderType.SalesWithPrice': 'المبيعات بالسعر',
  'Enum.OrderType.ZPG1': 'ZPG1',
  'Enum.OrderType.MOIR': 'MOI-R',
  'sortMenu.MostRecent': 'الاكثر تحديثا',
  'sortMenu.NameAZ': 'من الاقل الى الاعلى',
  'sortMenu.NameZA': 'من الاعلى الى الاقل',
  'sortMenu.sort': 'ترتيب',
  'sortMenu.all': 'كل {{items}}',
  'sortMenu.REVENUE': 'حسب الإيرادات',
  'sortMenu.COST': 'حسب التكلفة',
  'sortMenu.PROFIT': 'بالربح',
  'sortMenu.All': 'الكل',
  'sortMenu.MOBILE': 'محمول',
  'sortMenu.AVL': 'AVL',
  'sortMenu.EMPTY': 'لا يوجد تتبع',
  'sortMenu.SERVICE': 'OPS',
  'sortMenu.SAAS': 'SAAS',
  'sortMenu.SINGLE': 'فردي',
  'sortMenu.MULTIPLE': 'متعدد',
  'sortMenu.VERIFIED': 'تم التحقق',
  'sortMenu.NOT_VERIFIED': 'لم يتم التحقق',
  'sortMenu.HighNoOfPallets': 'اعلي رقم من الالواح',
  'sortMenu.LowNoOfPallets': 'اقل رقم من الالواح',
  'sortMenu.ALL': 'الكل',
  'sortMenu.AMB': 'البيئة',
  'sortMenu.Chiller': 'الثلاجة',
  'sortMenu.Frozen': 'مجمد',
  'sortMenu.Deep_Frozen': 'شديد التجميد',
  'accordion.OrderID': 'رقم الطلب:',
  'accordion.Pallet': 'البالتة',
  'accordion.Items': 'العناصر',
  'accordion.All': 'كل',
  'accordion.Orders': 'طلب',
  'accordion.ProofOfDelivery': 'اثبات التوصيل',
  'accordion.Download': 'تحميل',
  'accordion.SealNo': 'رقم الختم',
  AutoUpdate: 'تحديث تلقائي',
  'Errors.NetworkError':
    'تم قطع الاتصال بالشبكة، يرجى التحقق من اتصالك وإعادة تحميل التطبيق مرة أخرى!.',
  'Placeholder.EnterTheValue': 'أدخل القيمة',
  'Placeholder.SelectValue': 'اختر قيمة',
  'Placeholder.DottedSearch': 'بحث...',
  'Placeholder.WriteTheValue': 'أدخل القيمة',
  'ConfirmActivateResetPassword.Title': 'تنشيط وإعادة تعيين كلمة المرور',
  'ConfirmActivateResetPassword.Message':
    'هل أنت متأكد أنك تريد تنشيط هذا المستخدم وإعادة تعيين كلمة المرور بالقيمة الافتراضية؟',
  'ConfirmResetPassword.Title': 'أنت على وشك إعادة تعيين كلمة المرور',
  'ConfirmResetPassword.Message': 'هل أنت من متأكد؟',
  'showPassword.Password': 'كلمة المرور: ',
  'showPassword.Changed': 'تم التغيير',
  'showPassword.NotChangedYet': 'لم يتم التغيير بعد',
  'showPassword.ShowPassword': 'إظهار كلمة المرور',
  'showPassword.HiddenPassword': 'إخفاء كلمة المرور',
  'GenerateComplexPasswordDialog.passwordGenerated': 'تم إنشاء كلمة المرور',
  'GenerateComplexPasswordDialog.passwordGeneratedText':
    'كجزء من إجراءات الأمان لدينا ، قام النظام تلقائيًا بإنشاء كلمة مرور لهذا الحساب ، والتي تم إرسالها إلى عنوان البريد الإلكتروني المقدم.',
  'announcementBar.news': 'أخبار',
  'announcementBar.link': 'رابط',
  'announcementDialog.announcement': 'إعلان',
  'announcementDialog.announcementText': 'قم بتطبيق النص الذي سيتم عرضه على شريط الإعلان.',
  'announcementDialog.text': 'النص',
  'announcementDialog.link': 'الرابط',
  'announcementDialog.linkName': 'اسم الرابط',
  'announcementDialog.toBeShownFor': 'ليتم عرضه لـ',
  'announcementDialog.admin': 'المسؤول',
  'announcementDialog.customer': 'العميل',
  km: 'كم',
  hrs: 'س',
  min: 'د',
  AutoCache: 'التخزين المؤقت التلقائي',
  'currency.SAR': 'ر.س',
  'currency.EGP': 'ج.م',
  'TransactionHistory.changingLog': 'سجل التغييرات',
  'TransactionHistory.changingLogDetails':
    'يبدو أن هناك بعض التغييرات على تكلفة الرحلة. إليك سجل التحديث للإشارة.',
  'TransactionHistory.current': 'الحالي',
  'TransactionHistory.cost': 'التكلفة',
  'TransactionHistory.price': 'السعر',
  'TransactionHistory.PriceContracted': 'تعاقد',
  'TransactionHistory.PriceManual': 'يدوي',
  'Hint.DragAndDropFiles': 'اسحب وأسقط الملفات هنا',
  'Validation.required': '{{field}} مطلوب!',
  'Validation.min': '{{field}} يجب ان يكون {{min}} او اكثر!',
  'Validation.max': '{{field}} يجب ان يكون {{max}} او اقل!',
  'Validation.minLength': '{{field}} يجب ان يكون على الاقل {{minLength}} حروف!',
  'Validation.maxLength': '{{field}} يجب ان يكون على الاكثر {{maxLength}} حروف!',
  'Validation.duplicated': '{{field}} مكرر!',
  'Validation.duplicatedProjection': '{{field}} {{duplicatedProjection}} مكرر!',
  'Validation.minlength': '{{field}} يجب ان يكون على الاقل {{minLength}} حروف!',
  'Validation.maxlength': '{{field}} يجب ان يكون على الاكثر {{maxLength}} حروف!',
} as Phrases;
