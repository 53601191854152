import { Component, Input } from '@angular/core';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';
import { ShipmentSummaryPerTruck } from '@features/shipments/models/shipment-summary-per-truck.model';

@Component({
  selector: 'app-shipments-summary-per-truck',
  templateUrl: './shipments-summary-per-truck.component.html',
  styleUrls: ['./shipments-summary-per-truck.component.scss']
})
export class ShipmentsSummaryPerTruckComponent {
  @Input() shipments: ShipmentSummaryPerTruck[] = [];
  routesUtil = RoutesUtil;
  protected readonly ShipmentsListType = ShipmentsListType;

}
