import { Expose } from 'class-transformer';
import { Model } from '@shared-features/models/model';
import { Shipment } from '../../shipments/models/shipment';

export class TruckNonContractDays extends Model {
    @Expose()
    shipments: Shipment[];

    @Expose()
    count: number;
}
