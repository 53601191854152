import { Injectable } from '@angular/core';
import { Shipment } from '@features/shipments/models/shipment';
import { ShipmentCreationTemplateTypes } from '@features/shipment-creation-template/enums/shipment-creation-template-types.enum';
import { enumToArray } from '@shared/utils/enum-to-array.util';
import { RoutesUtil } from '@shared-features/utils/routes.util';
import { ShipmentsListType } from '@features/shipments/enums/shipments-list-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ShipmentTemplateService {
  private RoutesPerTemplate = {
    [ShipmentCreationTemplateTypes.Basic]: {
      add: RoutesUtil.ShipmentTemplateBasicAdd,
      edit: RoutesUtil.ShipmentTemplateBasicEdit,
    },

    [ShipmentCreationTemplateTypes.Cement]: {
      add: RoutesUtil.ShipmentTemplateCementAdd,
      edit: RoutesUtil.ShipmentTemplateCementEdit,
    },
    [ShipmentCreationTemplateTypes.Orders]: {
      add: RoutesUtil.ShipmentTemplateOrdersAdd,
      edit: null,
    },
  };

  private templates = enumToArray(ShipmentCreationTemplateTypes);
  private isTemplateAvailable(template: ShipmentCreationTemplateTypes): boolean {
    if (!template) {
      return false;
    }

    return !!this.templates.find((t) => t.value === template);
  }

  shipmentsListType: ShipmentsListType;

  constructor() {}

  getEditUrl(shipment: Shipment, shipmentsListType: ShipmentsListType): string {
    const { shipmentCreationTemplate, customer } = shipment;
    const customerDefaultTemplate = ((customer as any)?.templates || []).find((t) => t.isDefault);

    if (shipmentCreationTemplate && this.isTemplateAvailable(shipmentCreationTemplate)) {
      const route = this.RoutesPerTemplate[shipmentCreationTemplate];
      return route?.edit?.url({ id: shipment.id, type: shipmentsListType });
    } else if (customerDefaultTemplate && this.isTemplateAvailable(customerDefaultTemplate.type)) {
      const route = this.RoutesPerTemplate[customerDefaultTemplate.type];
      return route?.edit?.url({ id: shipment.id, type: shipmentsListType });
    } else {
      const route = this.RoutesPerTemplate[ShipmentCreationTemplateTypes.Basic];
      return route?.edit?.url({ id: shipment.id, type: shipmentsListType });
    }
  }

  getCreateUrl(
    template?: (typeof ShipmentCreationTemplateTypes)[keyof typeof ShipmentCreationTemplateTypes],
    redirect?: string
  ): string {
    if (!template || !this.isTemplateAvailable(template)) {
      return this.RoutesPerTemplate[ShipmentCreationTemplateTypes.Basic].add.url(undefined, {
        redirect,
      });
    }
    return this.RoutesPerTemplate[template].add.url(undefined, { redirect });
  }
}
