import { Injectable } from '@angular/core';
import { Bloc } from '@core/bloc/bloc';
import { forkJoin, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { ErrorState, ProcessingState } from '@core/bloc/bloc-state';
import { BlocEvent, InitEvent } from '@core/bloc/bloc-event';
import { ShipmentsFilterState, ShipmentsFilterReadyState } from './shipments-filter-state';
import { SharedApiService } from '@shared-features/services/shared-api.service';
import { TruckTypesApiService } from '@shared-features/services/truck-types-api.service';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { BusinessType } from "@features/users/enums/business-type.enum";
import { Drawing } from '@shared-features/models/drawing';

@Injectable()
export class ShipmentsFilterBloc extends Bloc<BlocEvent, ShipmentsFilterState> {
  constructor(
    private sharedApiService: SharedApiService,
    private truckTypesApiService: TruckTypesApiService,
    private usersCommonApiService: UsersCommonApiService,
  ) {
    super();
  }

  onIncomingEvents(event: BlocEvent): void {
    if (event instanceof InitEvent) {
        this.onState$.next(new ProcessingState<Drawing>(new Drawing()));
        forkJoin([
          BusinessType.B2C === event.args.businessType ? of(null) : this.sharedApiService.getAllCustomers(),
          this.sharedApiService.getActiveFleets(true, event.args?.businessType),
          BusinessType.B2C === event.args.businessType ? of(null) : this.truckTypesApiService.getAllTruckTypes(),
          BusinessType.B2C === event.args.businessType || (BusinessType.B2B === event.args.businessType && !event.args.getUsers)
            ? of(null) : this.usersCommonApiService.getAllUsers()
        ])
          .pipe(take(1))
          .subscribe(
            ([customers, fleets, truckTypes, creators]) => {
              this.onState$.next(new ShipmentsFilterReadyState(customers, fleets, truckTypes, creators));
            },
            (error) => {
              this.onState$.next(new ErrorState<Drawing>(new Drawing(), error));
            }
          );

    }
  }
}
