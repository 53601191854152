<app-state-section [state]="sectionState">
  <div>
    <app-row align="center">
      <app-col cols="auto" align="center">
        <app-icon class="align-middle" size="sm" name="star"></app-icon>
        <span class="pl-2 h6 mb-0">{{ "shipments.shipmentListFilter.applyMyFilter" | translate}}</span>
        <app-icon
          class="icon icon--xsmall align-middle pl-1"
          [matTooltip]="'Filter.Form.exclamationMark' | translate"
          matTooltipPosition="above"
          name="exclamation_mark"
        ></app-icon>
      </app-col>

      <app-col cols="auto" class="ml-auto">
        <button mat-button class="px-0" [disabled]="form?.value?.favFilter?.length >= 6" (click)="openCreateFilter()">
          <img src="../../../../../assets/images/arrow_right.svg" alt="" />
          <span class="text-small pl-2 text-primary">{{ "shipments.shipmentListFilter.createCustomFilter" | translate }}</span>
        </button>
      </app-col>
    </app-row>

    <ng-container *ngIf="form?.value?.favFilter && form?.value?.favFilter.length > 0; else filterEmpty">
      <app-row gutter="xs" class="control-list">
        <app-col cols="12" lg="4" *ngFor="let filter of form.value.favFilter; let i = index">
          <div class="control-list__item mb-1">
            <app-row gutter="xs" align="center">
              <app-col cols="2">
                <mat-radio-button [value]="filter" [checked]="favFiltersIndex == i" (click)="selectFilter(filter,i)"></mat-radio-button>
              </app-col>

              <app-col cols="6">
                <div>{{ filter.filterName }}</div>
              </app-col>

              <app-col cols="4">
                <div class="flex align-center justify-flex-end">
                  <app-rect-tag (click)="openEditFilter(filter , i)" class="mr-1 cursor">
                    <app-icon name="edit" type="MaterialDesign" size="sm" class="align-middle"></app-icon>
                  </app-rect-tag>

                  <app-rect-tag (click)="deleteFilter(filter)" class="cursor">
                    <app-icon name="remove_circle" type="MaterialDesign" size="sm" class="align-middle"></app-icon>
                  </app-rect-tag>
                </div>
              </app-col>
            </app-row>
          </div>
        </app-col>
      </app-row>
    </ng-container>

    <ng-template #filterEmpty>
      <span class="small text-400 ml-7">{{ "Filter.NoCustomFilterAdded" | translate}}</span>
    </ng-template>

    <mat-divider class="pos-static my-3"></mat-divider>
  </div>
</app-state-section>
