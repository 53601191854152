<app-state-section [state]="sectionState" [errorStateLabel]="'ErrorMessages.ErrorHappened' | translate">
  <form [formGroup]="form">
    <app-row cols="12">
      <mat-label>{{ "shipments.Filter.Form.FilterName" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <input matInput type="text" formControlName="filterName" [placeholder]="'shipments.Filter.Form.FilterName' | translate" />
        <mat-error *ngIf="form.get('filterName')?.touched">{{"ErrorMessages.Required" | translate}}</mat-error>
      </mat-form-field>
    </app-row>

    <app-row cols="12" *ngIf="!isB2c">
      <mat-label>{{ "shipments.Filter.Form.CustomerName" | translate }}</mat-label>
      <mat-form-field appearance="outline" class="form-field">
        <mat-select multiple [placeholder]="'Placeholder.Search' | translate" formControlName="customerId">
          <div class="form-field__search-field">
            <app-icon matPrefix name="search-icon" class="search-icon"></app-icon>

            <input
              matInput
              type="text"
              tabindex="1"
              placeholder="{{ 'Placeholder.Search' | translate }}"
              (keydown)="$event?.stopPropagation()"
              [formControl]="customersSearch"
            />
          </div>
          <mat-checkbox
            app-click-stop-propagation
            tabindex="-1"
            [disableRipple]="true"
            [indeterminate]="isIndeterminate()"
            [checked]="isChecked()"
            (change)="onToggleSelection($event)"
          >
            {{ "shipments.Options.SELECT_ALL" | translate }}
          </mat-checkbox>
          <mat-option tabindex="-1" *ngFor="let item of searchResultCustomers | sortDropdown" [value]="item.id">{{ item.title }}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-row>

    <app-row cols="12">
      <mat-label>{{ "shipments.Filter.Form.FleetName" | translate }}</mat-label>
      <app-col cols="12">
        <app-dropdown [list]="fleets" [multipleMode]="true" formControlName="fleetId"></app-dropdown>
      </app-col>
    </app-row>

    <app-row cols="12" *ngIf="showCreatorByField && !isB2c">
      <mat-label>{{ "shipments.Filter.Form.CreatedBy" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="creator">
          <app-dropdown-search formControlName="creatorSearchTerm"></app-dropdown-search>
          <mat-option [value]="SharedConstants.ALL">{{ 'shipments.Options.ALL' | translate }}</mat-option>
          <mat-option *ngFor="let item of filteredCreators | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-row>

    <app-row cols="12">
      <mat-label>{{ "shipments.Filter.Form.AssignedBy" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="assignedBy">
          <app-dropdown-search formControlName="assignedBySearchTerm"></app-dropdown-search>
          <mat-option [value]="SharedConstants.ALL">{{ 'shipments.Options.ALL' | translate }}</mat-option>
          <mat-option *ngFor="let item of filteredAssignedBy | sortDropdown" [value]="item.id">{{ item.dropDownDisplayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </app-row>

    <div class="field flex column">
      <div class="field flex column">
        <mat-label>{{ 'shipments.Filter.Form.CustomerLabel' | translate }}</mat-label>
        <app-col cols="12">
          <app-dropdown [list]="companyLabels" [multipleMode]="true" formControlName="customerLabel"></app-dropdown>
        </app-col>
      </div>
    </div>

    <div *ngIf="!isB2c">
      <mat-checkbox formControlName="withoutPrice" (change)="onWithoutPriceSelectionChange($event)">
        {{ "shipments.Filter.Form.WithoutPrice" | translate}}
      </mat-checkbox>
    </div>

    <div *ngIf="!isB2c">
      <mat-checkbox formControlName="withoutDeliveryNote" (change)="onWithoutDeliveryNoteSelectionChange($event)">
        {{ "shipments.Filter.Form.WithoutDeliveryNote" | translate}}
      </mat-checkbox>
    </div>

    <div *ngIf="!isB2c">
      <mat-checkbox formControlName="isReceived" (change)="onIsReceivedSelectionChange($event)">
        {{ "shipments.Filter.Form.IsReceived" | translate}}
      </mat-checkbox>
    </div>
  </form>
</app-state-section>
