import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { appConfigFactory } from '@core/services/app-config.factory';
// import { FeatureFlagService } from '@core/services/feature-flag.service';
// import { AuthService } from '@core/services/auth.service';
// import { DynamicScriptService } from '@shared/services/dynamic-script.service';
import { Router } from '@angular/router';
import { createErrorHandler, TraceModule, TraceService } from '@sentry/angular-ivy';
// import { UsersApiService } from '@features/users/services/users-api.service';
import { SentryErrorHandlerService } from '@core/services/sentry-error-handler.service';
// import { ZendeskService } from '@core/services/zendesk.service';
// import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@core/services';

@NgModule({
  declarations: [],
  imports: [CommonModule, TraceModule],

  providers: [
    // FeatureFlagService,
    // AppConfigService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppConfigService],
      multi: true,
    },
  ],
})
export class CoreModule {}
