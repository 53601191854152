import { DropdownModel } from '@shared-features/models/dropdown-model';
import { RCustomerLookupEntityType } from '@features/customers/enums/RCustomer-lookup-entity-type.enum';
import { Expose } from 'class-transformer';
import { Fleet } from '@features/fleets/models/fleet';
import { FleetClassification } from '@features/fleets/enums/fleet-classification.enum';

export class Lookup extends DropdownModel {
  @Expose() id: number;
  @Expose() value: string;

  // extra props
  @Expose() cityId?: number;

  // extra props for r customers lookup in add shipment
  @Expose()
  type?: RCustomerLookupEntityType;

  @Expose()
  isdefault?: boolean; // IF TRUE THEN THE FLEET IS FREELANCER

  @Expose()
  sku?: string; // FOR PRODUCT TYPE ITEMs
  @Expose()
  notes?: string; // FOR PRODUCT TYPE ITEMs

  @Expose()
  productTypeName?: string;

  @Expose()
  classification?: FleetClassification;

  @Expose()
  productTypeId?: number;

  @Expose() numofpallets?: number;

  @Expose() get dropDownDisplayName(): string {
    return this.value;
  }

  @Expose() get dropDownValue(): Lookup {
    return this;
  }
}
