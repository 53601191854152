import { ChartDataColumn } from './chart-data-column';

export class ChartDataParetoColumn extends ChartDataColumn {
  absoluteData: number[];
  percentageData: number[];

  constructor(
    id: string,
    label: string,
    data: number[],
    borderColor?: string,
    backgroundColor?: string,
    pointBackgroundColor?: string,
    hoverBackgroundColor?: string
  ) {
    super(id, label, data, borderColor, backgroundColor, pointBackgroundColor, hoverBackgroundColor);
    this.isParetoLine = true;
    this.calculateParetoValues(data);
  }

  calculateParetoValues(data: number[]) {
    this.absoluteData = [];
    this.percentageData = [];
    if (!data || !data.length) { return; }
    const totalValue = data.reduce((accumulator, currentValue) => accumulator + currentValue);
    data.forEach((value, valueIndex) => {
      const newValue = parseFloat(((valueIndex > 0) ?  this.absoluteData[valueIndex - 1] + value : value).toFixed(2));
      const percentageValue = parseFloat(((newValue / totalValue) * 100).toFixed(2));
      this.absoluteData.push(newValue);
      this.percentageData.push(percentageValue);
    });
  }
}
