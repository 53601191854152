import { Component, EventEmitter,  OnDestroy, OnInit, Output } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { InitEvent } from '@core/bloc/bloc-event';
import { BlocState, ProcessingState, EmptyState, ErrorState, ReadyState } from '@core/bloc/bloc-state';
import { NotificationBloc } from '../../bloc/notification/notification-bloc';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { drawingType } from "@shared/enums/drawing-type.enum";
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";
import { FeedbackModel, FeedbackType } from '@shared/model/feedback';
import { NotificationItem } from '@shared-features/models/notification-item';
import { NotificationService } from '@shared/services/notification.service';
import { NotificationsApiService } from '@features/notifications/services/notifications-api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  providers: [NotificationBloc],
})
export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() closeNotificationClicked = new EventEmitter();

  public Arr = Array;
  public sectionState: SectionStateStatus = SectionStateStatus.Loading;
  public SectionStateStatus = SectionStateStatus;
  public notifications: NotificationItem[] = [];

  offset = 0;
  limit = 10;

  hasMoreNotifications = true;

  constructor(
    
    private bloc: NotificationBloc,
    private notificationService: NotificationService,
    private notificationsApiService: NotificationsApiService,

  ) {
    super();
    this.bloc.onState.pipe(takeUntil(this.destroy$)).subscribe((state) => this.handleBlocStates(state));
  }

  ngOnInit(): void {
    const { offset, limit } = this;
    this.bloc.setEvent.next(new InitEvent({ offset, limit }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.bloc.dispose();
  }

  // UI Events
  onNotificationClicked(notification: NotificationItem): void {
    this.notificationsApiService.markAsRead(+notification.id).pipe(take(1)).subscribe(() => {
      this.notifications.forEach((n) => {
        if (n.id === notification.id) {
          n.isRead = true;
        }
      });
    });
    this.notificationService.navigateToNotificationTarget(notification);
    this.closeNotificationClicked.emit();
  }

  // private methods
  private handleBlocStates(state: BlocState) {
    if (!state) {
      return;
    }

    if (state instanceof ProcessingState) {
      this.sectionState = state.drawingType;
    } else if (state instanceof ReadyState) {
      this.notifications = [...this.notifications, ...state.data];
      this.hasMoreNotifications = state.data?.length;
      this.sectionState = SectionStateStatus.Ready;
    } else if (state instanceof EmptyState) {
      this.sectionState = SectionStateStatus.Empty;
    } else if (state instanceof ErrorState) {
      this.sectionState = state.drawingType;

      if (state.drawingType === drawingType.NonDrawing) {
        if (state.error && this.notifications.length) {
          this.hasMoreNotifications = false;
        }
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Failure,
            state.error ? state.error.errors[0] : this.translationsList['ErrorMessages']['ErrorHappened']
          )
        );
      } else {
        this.sectionState = SectionStateStatus.Error;
      }
    }
  }

  loadMore(): void {
    this.offset += 1;
    const { limit } = this;
    this.bloc.setEvent.next(new InitEvent({ offset: this.offset, limit }));
  }
}
