<!-- <input matInput class="mat-select-search-input mat-select-search-hidden"/> -->

<div class="mat-select-search-inner" [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
  <input
    matInput
    autocomplete="off"
    class="mat-select-search-input"
    #searchSelectInput
    (keydown)="_handleKeydown($event)"
    (input)="onInputChange($event.target.value)"
    (blur)="onBlur($event.target.value)"
    [placeholder]="'Placeholder.Search' | translate"
  />
  <button *ngIf="value" mat-icon-button aria-label="Clear" (click)="_reset(true)" class="mat-select-search-clear">
    <app-icon name="close" type="MaterialDesign"></app-icon>
  </button>
</div>
