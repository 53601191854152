import moment from 'moment';
import { DateRangeType } from "@shared/enums/date-range-type.enum";

export function dateRangePreview(dateRangeValue: DateRangeType): string {
  const format = 'YYYY/MM/DD';
  const today = moment().format(format);
  const start = moment();
  let previewSelectedDate = '';
  switch (dateRangeValue) {
    case DateRangeType.TODAY:
      previewSelectedDate = today;
      break;
    case DateRangeType.YESTERDAY:
      previewSelectedDate = `${start.add(-1, 'd').format(format)}`;
      break;

    case DateRangeType.LAST_TWO_WEEKS:
      previewSelectedDate = `${start.add(-14, 'd').format(format)} - ${today}`;
      break;

    case DateRangeType.THIS_WEEK:
      previewSelectedDate = `${start.startOf('week').day(-1).format(format)} - ${today}`;
      break;

    case DateRangeType.LAST_WEEK:
      previewSelectedDate = `${start.add(-7, 'd').format(format)} - ${today}`;
      break;

    case DateRangeType.THIS_MONTH:
      previewSelectedDate = `${start.startOf('month').format(format)} - ${today}`;
      break;

    case DateRangeType.LAST_MONTH:
      previewSelectedDate = `${start.add(-1, 'month').format(format)} - ${today}`;
      break;

    case DateRangeType.THIS_YEAR:
      previewSelectedDate = `${start.startOf('year').format(format)} - ${today}`;
      break;

    case DateRangeType.LAST_YEAR:
      previewSelectedDate = `${start.add(-1, 'year').format(format)} - ${today}`;
      break;

    default:
      previewSelectedDate = '';
      break;
  }
  return previewSelectedDate;
}
