import { BidiModule, DIR_DOCUMENT } from '@angular/cdk/bidi';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@layout/layout.module';
import { JwtModule, JwtModuleOptions, JwtInterceptor } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@env/environment';
import { AppRoutingModule } from './app-routing.module';
import { FilterModule } from '@features/filter/filter.module';
import { AppComponent } from './app.component/app.component';
import { StorageConstant } from '@shared/constants/storage.constant';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { LocaleIdHandlerService } from '@shared/services/locale-id-handler.service';
import { SharedModule } from '@shared/shared.module';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import ar from '@angular/common/locales/ar';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from '@core/services/messaging.service';
import { DeliveryNotesModule } from '@features/delivery-notes/delivery-notes.module';
import { CoreModule } from '@core/core.module';
import { ApiConstant } from '@shared/constants/api.constant';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ConfigConstant } from '@shared/constants';

registerLocaleData(en, ar);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

export function getToken(): string {
  const token = localStorage.getItem(StorageConstant.AUTH_USER);
  if (token) {
    return JSON.parse(token)?.accessToken;
  }
  return null;
}

const JWT_Module_Options: JwtModuleOptions = {
  config: {
    tokenGetter: getToken,
    allowedDomains: ConfigConstant.APP_WHITE_LISTED_DOMAINS,
    disallowedRoutes: [ApiConstant.REFRESH_TOKEN],
    headerName: 'Authorization',
    authScheme: 'Bearer ',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BidiModule,
    CoreModule,
    JwtModule.forRoot(JWT_Module_Options),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    LayoutModule,
    FilterModule,
    FormsModule,
    AngularFireMessagingModule,
    DeliveryNotesModule,
    AngularFireModule.initializeApp(environment.firebase),
    ...(environment.google_analytics
      ? [
          NgxGoogleAnalyticsModule.forRoot(environment.google_analytics),
          NgxGoogleAnalyticsRouterModule.forRoot(),
        ]
      : []),
  ],
  providers: [
    TranslateService,
    { provide: DIR_DOCUMENT, useValue: 'rtl' },
    { provide: LOCALE_ID, useClass: LocaleIdHandlerService, deps: [TranslateService] },
    JwtInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    MessagingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
