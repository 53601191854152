import { Component,  Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { ShipmentsApiService } from '@features/shipments/services/shipments-api.service';
import { AVLSummary } from '@features/live-tracking/models/avl-summary.model';

@Component({
  selector: 'app-avl-summary',
  templateUrl: './avl-summary.component.html',
  styleUrls: ['./avl-summary.component.scss']
})
export class AvlSummaryComponent extends BaseComponent implements OnChanges {
  @Input() shipmentId: number;
  @Input() summary: AVLSummary;
  avlSummary: AVLSummary;

  constructor(
    private shipmentsApiService: ShipmentsApiService,
    
  ) { super(); }

  ngOnChanges({ shipmentId, summary }: SimpleChanges): void {
    if (shipmentId && shipmentId.currentValue !== shipmentId.previousValue) {
      this.getSummary();
    }

    if (summary && summary.currentValue !== summary.previousValue) {
      this.avlSummary = this.summary;
    }
  }

  getSummary(): void {
    this.load(this.shipmentsApiService.getAVLsummary(this.shipmentId)).subscribe((summary) => {
      this.avlSummary = summary;
    });
  }
}
