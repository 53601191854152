import { Component, Input, OnInit,  SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { TabIndex } from "@shared/enums/tab-index.enum";
import { TabGroup } from "@shared/enums/tab-group.enum";
import { FormAction } from "@shared/enums/form.action.enum";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilterService } from '@shared/services/filter.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SharedConstants } from '@shared/model/shared-constants';
import { ActivatedRoute } from '@angular/router';
import { RequestHistoryStatus } from "@features/logs/enums/request-history-status.enum";
import { RequestHistoryAction } from "@features/logs/enums/request-history-action.enum";

@Component({
  selector: 'app-b2c-request-logs-filter',
  templateUrl: './b2c-request-logs-filter.component.html',
  styleUrls: ['./b2c-request-logs-filter.component.scss'],
})
export class B2cRequestLogsFilterComponent extends BaseComponent implements OnInit {
  @Input() action: Observable<void>;
  @Input() tabGroup: TabGroup;
  @Input() tabIndex: TabIndex;

  form: UntypedFormGroup;
  params: any = null;
  requestHistoryStatus = RequestHistoryStatus;
  requestHistoryAction = RequestHistoryAction;
  sharedConstants = SharedConstants;

  constructor(private filterService: FilterService,  private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToQueryParams();
    this.subscribeToFilterAction();
    this.initForm();
  }

  subscribeToQueryParams(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params && Object.keys(params).length !== 0) {
        this.params = params;
      }
    });
  }

  getFilterValuesBasedOnURLParams() {
    return {
      status: this.params.status === SharedConstants.ALL ? SharedConstants.ALL : this.params.status,
      action: this.params.action === SharedConstants.ALL ? SharedConstants.ALL : this.params.action,
    };
  }

  initForm() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form = new UntypedFormGroup({
        status: new UntypedFormControl(filterValues.status),
        action: new UntypedFormControl(filterValues.action),
      });
    } else {
      this.form = new UntypedFormGroup({
        status: new UntypedFormControl(SharedConstants.ALL),
        action: new UntypedFormControl(SharedConstants.ALL),
      });
    }
  }

  subscribeToFilterAction() {
    this.action.pipe(takeUntil(this.destroy$)).subscribe((action: any) => {
      switch (action) {
        case FormAction.Reset:
          this.resetForm();
          break;

        case FormAction.Cancel:
          this.cancelChanges();
          break;

        case FormAction.Submit:
          this.submitForm();
          break;
      }
    });
  }

  resetForm() {
    this.form.setValue({
      status: SharedConstants.ALL,
      action: SharedConstants.ALL,
    });
    this.submitForm();
  }

  cancelChanges() {
    if (this.params && Object.keys(this.params).length !== 0) {
      const filterValues = this.getFilterValuesBasedOnURLParams();
      this.form.setValue({
        status: filterValues.status,
        action: filterValues.action,
      });
    } else {
      this.resetForm();
    }
  }

  submitForm() {
    const submittedFilterValues = this.form.value;

    submittedFilterValues.tabGroup = TabGroup.B2CRequestLogs;
    this.filterService.navigateToTargetPageWithFilter(submittedFilterValues);
  }
}
