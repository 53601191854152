import { BreadcrumbLabels } from '@shared/enums/breadcrumb-labels.enum';

export const BreadcrumbIcons = {
  [BreadcrumbLabels.Dashboard]: 'home',
  [BreadcrumbLabels.Shipments]: 'shipments',
  [BreadcrumbLabels.ShipmentsView]: 'shipments',
  [BreadcrumbLabels.Providers]: 'fleet',
  [BreadcrumbLabels.LiveTracking]: 'live-tracking',
  [BreadcrumbLabels.Invoices]: 'invoices',
  [BreadcrumbLabels.Settings]: 'manage',
  [BreadcrumbLabels.Orders]: 'location-alt.svg',
  [BreadcrumbLabels.Contracts]: 'manage',
  [BreadcrumbLabels.TripPlanning]: 'bulk-routing',
};
