import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '@shared/services/language.service';
import { BreadCrumb } from '../model/breadcrumb';
import { SharedConstants } from '../model/shared-constants';
import { LocalStorageUtil } from '../utils/local-storage.util';
import { filter, startWith } from 'rxjs/operators';
import { StorageConstant } from '@shared/constants/storage.constant';


@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbList$: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject([]);
  visibility$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private breadcrumbList = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private languageService: LanguageService) {
    this.breadcrumbList = LocalStorageUtil.getItem(StorageConstant.BREADCRUMB)
      ? LocalStorageUtil.getItem(StorageConstant.BREADCRUMB)
      : [];

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router)
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.buildBreadCrumb(event);
        } else {
          this.buildBreadCrumb(event['url']);
        }
      });
  }

  buildBreadCrumb(router: any) {
    let routerPath: string;
    routerPath = router.urlAfterRedirects ? router.urlAfterRedirects : router;

    const breadcrumbObj =
      (this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data ||
        this.activatedRoute.children[0]?.children[0]?.routeConfig?.data) &&
      (this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data['breadcrumb'] ||
        this.activatedRoute.children[0]?.children[0]?.routeConfig?.data['breadcrumb'])
        ? this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data['breadcrumb'] ||
          this.activatedRoute.children[0]?.children[0]?.routeConfig?.data['breadcrumb']
        : null;

    if (breadcrumbObj) {
      const firstLevel = breadcrumbObj['isFirstLevel'] ?? false;
      const secondLevel = breadcrumbObj['isSecondLevel'] ?? false;
      const labelKey = breadcrumbObj['labelKey'] ?? '';
      const isActive = breadcrumbObj['isActive'] ?? false;
      const isVisible = breadcrumbObj['isVisible'] ?? true;
      if (firstLevel) {
        this.breadcrumbList = [];
      } else if (secondLevel) {
        this.breadcrumbList.splice(1);
      } else {
        const currentPathIndex = this.breadcrumbList.findIndex((item) => item.labelKey === labelKey);
        if (currentPathIndex !== -1) {
          this.breadcrumbList.splice(currentPathIndex);
        }
      }

      const breadcrumb = new BreadCrumb(labelKey, routerPath, false, isActive);
      this.breadcrumbList.push(breadcrumb);
      this.breadcrumbList$.next(this.breadcrumbList);
      localStorage.setItem(StorageConstant.BREADCRUMB, JSON.stringify(this.breadcrumbList));
      this.visibility$.next(isVisible);
    } else {
      this.breadcrumbList = [];
      this.breadcrumbList$.next(this.breadcrumbList);
      localStorage.setItem(StorageConstant.BREADCRUMB, JSON.stringify(this.breadcrumbList));
    }
  }

  public insertItemBeforLast(text: string) {
    const list = this.breadcrumbList$.value;
    if (list.find((item) => item.labelKey === text)) {
      return;
    }
    list.splice(list.length - 1, 0, new BreadCrumb(text, '', true, false));
    this.breadcrumbList$.next(list);
  }

  public changeBreadcrumbItem(text: string, index) {
    const list = this.breadcrumbList$.value;
    list[index] = new BreadCrumb(text, '', true, false);
    this.breadcrumbList$.next(list);
  }

  public changeTheLastItem(text: string, isActive = false) {
    const list = this.breadcrumbList$.value;
    list[list.length - 1].isValue = true;
    list[list.length - 1].labelKey = text;
    list[list.length - 1].isActive = isActive;
    this.breadcrumbList$.next(list);
  }
}
