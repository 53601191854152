import { ApiBaseService } from '@core/services/api-base.service';
import { Mapper } from '@core/services/mapper/base-mapper.mapper';
import { Injectable } from '@angular/core';
import { map, share, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiConstant } from '@shared/constants/api.constant';
import { MeasurementUnit } from '@features/shipments/models/measurement-unit.model';
import { LookupType } from "@shared/enums/lookup-type.enum";
import { hashString } from '@shared/utils/hash-string';
import { Lookup } from '@shared-features/models/Lookup.model';
import { MemoizeService } from '@core/services/memoize.service';
import { ClassConstructor } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class LookupService {
  // private cache = {};
  // private currentlyLoading = {};
  // private currentlyLoadingObservables = {};

  constructor(
    private baseAPI: ApiBaseService,
    private memoizeService: MemoizeService,
    private mapper: Mapper,
  ) {}

  getMeasurementUnits(): Observable<MeasurementUnit[]> {
    return this.memoize<MeasurementUnit[]>(
      'measurementUnit',
      () => this.baseAPI.get(ApiConstant.GET_ALL_MEASUREMENT_UNITS)
    ).pipe(
      map((res: any) => {
        return this.mapper.fromJson(MeasurementUnit, res.data);
      })
    );
  }

  getLookup<T = Lookup>(
    lookup: LookupType,
    {searchTerm, ...options}: any,
    forceCleanCache?: boolean,
    Model?: ClassConstructor<T>,
  ): Observable<T[]> {
    const rawParams = { ...options };
    const params: any = Object.keys(rawParams).reduce((group, key) => {
      if (rawParams[key] !== null && typeof rawParams[key] !== 'undefined') {
        group[key] = rawParams[key];
      }
      return group;
    }, {});

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    const cacheId = this.memoizeService.hashInputs(params, lookup);

    if (forceCleanCache) {
      this.resetCache();
    }

    if (forceCleanCache) {
      params.ignoreCache = true;
    }

    return this.memoize<any>(
      cacheId,
      () => this.baseAPI.get(`${ApiConstant.GET_LOOKUPS}${lookup}`, { params })
    ).pipe(map((res) => this.mapper.fromJson(Model || Lookup, res.data)));
  }

  getGlobalSearchLookup(lookup: LookupType, {searchTerm, ...options}: any, forceCleanCache?: boolean, Model: any = Lookup): Observable<any> {
    const rawParams = { ...options };
    const params: any = Object.keys(rawParams).reduce((group, key) => {
      if (rawParams[key] !== null && typeof rawParams[key] !== 'undefined') {
        group[key] = rawParams[key];
      }
      return group;
    }, {});

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    const cacheId = this.memoizeService.hashInputs(params, lookup);

    if (forceCleanCache) {
      this.resetCache();
    }

    return this.memoize<any>(
      cacheId,
      () => this.baseAPI.get(`${ApiConstant.GET_GLOBAL_SEARCH_LOOKUPS}${lookup}`, { params })
    ).pipe(map((res) => this.mapper.fromJson(Model, res.data)));
  }

  private memoize<T = any>(id, requestCallBack): Observable<T> {
    return this.memoizeService.memoize<T>(id, requestCallBack);
  }

  private resetCache(): void {
    this.memoizeService.resetCache();
  }
}
