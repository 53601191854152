import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SectionStateStatus } from "@shared/enums/section-state-status.enum";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  // private fields
  private loadingStatus = new Subject<SectionStateStatus>();

  // public fields
  public loadingStatus$ = this.loadingStatus.asObservable();

  constructor() { }

  // actions
  public startLoading(loadingStatus: SectionStateStatus) {
    this.loadingStatus.next(loadingStatus);
  }

  public stopLoading() {
    this.loadingStatus.next(SectionStateStatus.HideLoading);
  }
}
