import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { BaseComponent } from '@shared/components/base-component/base.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import moment, { Moment } from 'moment';
import { UsersCommonApiService } from '@shared-features/services/users-common-api.service';
import { OpsScore } from '@features/users/models/ops-score.model';

export const DATE_INPUT_FORMATE = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MMM - YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: '',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-ops-score-dialog',
  templateUrl: './ops-score-dialog.component.html',
  styleUrls: ['./ops-score-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMATE },
  ],
})
export class OpsScoreDialogComponent extends BaseComponent implements OnInit {
  maxDate = new Date();
  dateControl = new FormControl(moment());
  userScore = new OpsScore();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private usersCommonApiService: UsersCommonApiService,
    
  ) {
    super();
  }

  ngOnInit(): void {
    this.getUserScore();
  }

  chosenYearHandler(normalizedYear: Moment): void {
    this.dateControl.setValue(moment());
    const ctrlValue = this.dateControl.value;
    ctrlValue.year(normalizedYear.year());
    this.dateControl.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    const ctrlValue = this.dateControl.value;
    ctrlValue.month(normalizedMonth.month());
    this.dateControl.setValue(ctrlValue);
    this.getUserScore();
    datepicker.close();
  }

  getUserScore(): void {
    const date = this.dateControl.value;
    date.date(1);
    this.load(this.usersCommonApiService.getCurrentUserScore(date.format('YYYY-MM-DD'))).subscribe(
      (opsScore) => {
        /**
         * @todo: remove this when BE handles Nan values
         * workaround to avoid nan issues
         */
        Object.keys(opsScore || {}).forEach((key) => {
          if (Number.isNaN(+opsScore[key])) {
            opsScore[key] = 0;
          }
        });
        this.userScore = opsScore || new OpsScore();
      }
    );
  }
}
