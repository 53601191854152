<div class="container pt-3">
  <!-- Header -->
  <div class="madar--v2" *ngIf="showBreadcrumb">
    <mat-card class="mat-elevation-z4 mb-3">
      <mat-card-content class="my-2 px-2">
        <app-row justify="space-between" gutter="xs">
          <app-col cols="12" md="col">
            <div class="text">
              <div class="breadcrumb">
                <app-breadcrumb></app-breadcrumb>
              </div>
            </div>
          </app-col>
          <app-col cols="12" md="auto">
            <div class="action">
              <ng-content select="[trailing]"></ng-content>
            </div>
          </app-col>
        </app-row>
      </mat-card-content>
    </mat-card>

    <div class="weight-semibold">
      <ng-content select="[title]"></ng-content>
    </div>
  </div>

  <!-- Content -->
  <div class="page-content">
    <div class="content">
      <ng-content select="[content]"></ng-content>
    </div>
  </div>
</div>
