export enum LookupType {
  Drivers = 'DRIVERS',
  Fleets = 'FLEETS',
  Receivers = 'RECEIVERS',
  Trucks = 'TRUCKS',
  Customers = 'CUSTOMERS',
  Cities = 'CITIES',
  TruckTypes = 'TRUCK_TYPES',
  Locations = 'LOCATIONS',
  ProductTypes = 'PRODUCT_TYPES',
  Users = 'USERS',
  Trailers = 'TRAILERS',
  CustomerChildren = 'CUSTOMER_CHILDREN',
  MyCustomers = 'MYCUSTOMERS',
  RCustomers = 'RCUSTOMERS',
  Regions = 'REGIONS',
  WccReceivers = 'WCCRECEIVERS',
  actionsDynamicIntegration = 'CUSTOMER_INTEGRATION_ACTIONS',
  ProductItems = 'PRODUCT_ITEM',
  Zones = 'ZONES',
  Seller = 'SELLER',
}
