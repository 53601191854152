import { Model } from '../../shared-features/models/model';
import { Expose } from 'class-transformer';

export class Transaction extends Model {
  @Expose() id: number;
  @Expose() comment: string;
  @Expose() customerPrice: number;
  @Expose() fleetPrice: number;
  @Expose() createdAt: number;
  @Expose() updatedAt: number;
  @Expose() isCOD: boolean;
  @Expose() packageCost: string;
  @Expose() CODAmount: string;

  @Expose()
  fleetWaitingCharge: number;

  @Expose()
  waitingDays: number;

  @Expose()
  customerReturnCharge: number;

  @Expose()
  fleetReturnCharge: number;

  @Expose()
  multiDestinationChargePrice: number;

  @Expose()
  multiDestinationChargeCost: number;

  @Expose()
  weightingFine: number;

  @Expose()
  customerClearanceFees: number;

  @Expose()
  fleetWeightingFine: number;

  @Expose()
  fleetClearanceFees: number;

  @Expose()
  advancedPayment: number;

  @Expose()
  damageCost: number;

  @Expose()
  customerWaitingChargePerDay: string;

  @Expose()
  isContractedPrice: boolean;

  @Expose()
  isContractedCost: boolean;

  @Expose() hasPriceChanges: boolean;
  @Expose() hasCostChanges: boolean;

  constructor() {
    super();
  }
}
