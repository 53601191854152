<ng-template #dialogContent>
  <div class="minimizable-container" [ngClass]="{'minimizable-dialog--minimized': state?.minimized }">
    <div class="minimizable-dialog__header py-1">
      <app-row align="center" gutter="x-sm">
        <app-col>
          <div class="minimizable-dialog__title">
            <ng-content select="[title]"></ng-content>
          </div>
        </app-col>

        <app-col cols="auto">
          <ng-content select="[actions]"></ng-content>

          <button class="minimizable-dialog__action" type="button" mat-icon-button *ngIf="!state?.minimized" (click)="minimize()">
            <app-icon name="minimize" type="MaterialDesign"></app-icon>
          </button>

          <button class="minimizable-dialog__action" type="button" mat-icon-button *ngIf="state?.minimized" (click)="maximize()">
            <app-icon name="crop_square" type="MaterialDesign" class="material-icon-outlined"></app-icon>
          </button>

          <button class="minimizable-dialog__action" type="button" mat-icon-button (click)="close()">
            <app-icon name="close" type="MaterialDesign"></app-icon>
          </button>
        </app-col>
      </app-row>
    </div>

    <div class="minimizable-dialog__content scrollable scrollable--x scrollable--y" [hidden]="state?.minimized">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
