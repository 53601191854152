<app-row align="center" gutter="x-xs">
  <app-col cols="auto" *ngIf="showLabel">{{label || ('shared.sortMenu.sort' | translate)}}</app-col>
  <app-col>
    <div class="selectable-menu__trigger">
      <button mat-flat-button [matMenuTriggerFor]="sortMenu" *ngIf="selectedValue$ | async as selected"
        [ngStyle]="{ 'color': triggerButtonColor }">
        <ng-container *ngIf="icons[selected.key] as icon">
          <ng-container *ngTemplateOutlet="iconTemplate; context: {$implicit: icon }"></ng-container>
        </ng-container>
        {{translations[selected.key]}}
        <app-icon name="arrow_drop_down" size="sm" type="MaterialDesign" iconPositionEnd></app-icon>
      </button>
    </div>
  </app-col>
</app-row>

<mat-menu #sortMenu="matMenu" class="selectable-menu__list">
  <ng-container *ngFor="let option of options">
    <button mat-flat-button type="button" class="selectable-menu__item"
      [class.selectable-menu__item--selected]="option.key === value" (click)="select(option)">
      <ng-container *ngIf="icons[option.key] as icon">
        <ng-container *ngTemplateOutlet="iconTemplate; context: {$implicit: icon }"></ng-container>
      </ng-container>
      {{translations[option.key]}}
    </button>
  </ng-container>
</mat-menu>

<ng-template #iconTemplate let-icon>
  <app-icon [name]="icon.name" [type]="icon.type" size="sm"></app-icon>
</ng-template>