import { BlocState } from '@core/bloc/bloc-state';

export abstract class DropdownState extends BlocState {}

export class DropdownInitialState extends DropdownState {}

// Loading
export class DropdownLoadingState extends DropdownState {}

// Ready
export class DropdownReadyState<T> extends DropdownState {
  constructor(public list: T[]) {
    super();
  }
}
