import { CommonModule, registerLocaleData } from '@angular/common';

import ar from '@angular/common/locales/ar';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageService } from '@shared/services/language.service';
import { PaginatorLocalizationService } from './services/paginator-localization.service';
import { DateFormat } from './model/date-format';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgOtpInputModule } from 'ng-otp-input';

/**
 * Angular Material
 */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';

import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { StaticSectionComponent } from './components/static-section/static-section.component';
import { UserRoleCheckerPipe } from './pipes/user-role-checker.pipe';
// import { BaseComponent } from './forms/base-component/base.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { ExpandablePanelComponent } from './components/expandable-panel/expandable-panel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalComponent } from './components/modal/modal.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StateSectionComponent } from './components/state-section/state-section.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { ResponsiveDirective } from './directives/responsive.directive';

import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { ResponsivePipe } from './pipes/responsive.pipe';
import { PageContentComponent } from './components/page-content/page-content.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { TabComponent } from './components/tab/tab.component';
import { AddNewButtonComponent } from './components/add-new-button/add-new-button.component';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { StatusLabelComponent } from './components/status-label/status-label.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SequenceNumberComponent } from './components/sequence-number/sequence-number.component';
import { PlateNumberComponent } from './components/plate-number/plate-number.component';
import { ListFilterControlsComponent } from './components/list-filter-controls/list-filter-controls.component';
import { ManufactureYearComponent } from './components/manufacture-year/manufacture-year.component';
import { BirthDateComponent } from './components/birth-date/birth-date.component';
import { sortDropdownPipe } from './pipes/sort-dropdown.pipe';

import { PermissionDeniedPageComponent } from './pages/permission-denied-page/permission-denied-page.component';
import { SharedComponent } from './components/shared/shared.component';
import { ReceiversListComponent } from '@features/receivers/components/receivers-list/receivers-list.component';

import { FixedSideLayoutComponent } from './components/fixed-side-layout/fixed-side-layout.component';
import { LogoComponent } from './components/logo/logo.component';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { AppDropdownComponent } from './components/dropdown/dropdown.component';
import { EntityListingComponent } from './components/entity-listing/entity-listing.component';
import { PaginationComponent } from './components/pagination/pagination.component';

import { FileSizePipe } from './pipes/file-size.pipe';
import { SearchInputComponent } from './components/search/search-input/search-input.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { RowComponent } from '@shared/components/grid/row.component';
import { ColComponent } from '@shared/components/grid/col.component';
import { ParenthesePipe } from '@shared/pipes/parenthese.pipe';
import { MarkerComponent } from './components/marker/marker.component';
import { DurationPipe } from './pipes/duration.pipe';
import { FormatTimePipe } from './pipes/format-time';
import { BarcodeComponent } from './components/barcode/barcode.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { LatLngPipe } from './pipes/LatLng.pipe';
import { MapOverlayComponent } from './components/map-overlay/map-overlay.component';
import { DropdownSearchComponent } from './components/dropdown-search/dropdown-search.component';
import { ShipmentRequestHistoryComponent } from '@shared/components/shipment-request-history/shipment-request-history.component';
import { RequestAndResponseLogComponent } from './components/request-and-response-log/request-and-response-log.component';
import { MinimizableDialogComponent } from './components/minimizable-dialog/minimizable-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MapClustererOverlayComponent } from '@shared/components/map-clusterer/map-clusterer-overlay.component';
import { MapClustererComponent } from '@shared/components/map-clusterer/map-clusterer.component';
import { LabeledTextComponent } from '@shared/components/labeled-text/labeled-text.component';
import { LabeledTextListComponent } from '@shared/components/labeled-text-list/labeled-text-list.component';
import { FilterLayoutComponent } from './components/filter-layout/filter-layout.component';
import { SearchBarComponent } from './components/search/search-bar/search-bar.component';
import { ButtonComponent } from './components/button/button.component';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { GrantAccessDirective } from './directives/grant-access.directive';
import { AddShipmentButtonComponent } from './components/add-shipment-button/add-shipment-button.component';
import { CardSectionComponent } from './components/card-section/card-section.component';
import { LabeledInfoListComponent } from './components/labeled-info-list/labeled-info-list.component';
import { UploadedFileComponent } from './components/uploaded-file/uploaded-file.component';
import { PrintSectionsComponent } from './components/print-pages/print-sections.component';
import { ElmLogoComponent } from './components/elm logo/elm-logo.component';
import { StickyWidgetComponent } from './components/sticky-widget/sticky-widget.component';
import { StickyThingDirective } from './directives/sticky-thing.directive';
import i18n from '@shared/i18n';
import { DialogLayoutComponent } from './components/dialog-layout/dialog-layout.component';
import { NavListComponent } from './components/nav-list/nav-list.component';
import { MatListModule } from '@angular/material/list';
import { LabeledInfoItemComponent } from './components/labeled-info-item/labeled-info-item.component';
import { PopoverDirective } from './directives/popover-directive';
import { StatsCardComponent } from './components/stats-card/stats-card.component';
import { AvatarMenuComponent } from './components/avatar-menu/avatar-menu.component';
import { TrapezoidTabsLayoutComponent } from './components/trapezoid-tabs-layout/trapezoid-tabs-layout.component';
import { SortMenuComponent } from './components/sort-menu/sort-menu.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FilePondModule } from 'ngx-filepond';
import { RectTagComponent } from './components/rect-tag/rect-tag.component';
import { DragableFile } from './components/dragable-file/dragable-file.component';
import { OrdersAccordingComponent } from './components/orders-according/orders-according.component';
import { OrderItemsCardComponent } from './components/order-items-card/order-items-card.component';
import { TooltipListPipe } from './pipes/tooltipList.pipe';
import { AvlSummaryComponent } from './components/avl-summary/avl-summary.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { ShipmentsSummaryPerTruckComponent } from './components/shipments-summary-per-truck/shipments-summary-per-truck.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { LegendLabelsComponent } from './components/legend-labels/legend-labels.component';
import { BaseChartComponent } from './components/base-chart/base-chart.component';
import { ChartsModule } from 'ng2-charts';
import { GateToGateMarkerComponent } from '@shared-features/components/gate-to-gate-marker/gate-to-gate-marker.component';
import { FieldDirective } from '@shared/directives/field.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';

// import { ColumnOptionsComponent } from '@shared/components/column-options/view-column-options/view-column-options.component';
// import { ColumnOptionsFormComponent } from './components/column-options/column-options-form/column-options-form.component';
// import { CreateColumnOptionsPreferencesComponent } from './components/column-options/create-column-options-preferences/create-column-options-preferences.component';
// import { ColumnOptionsListComponent } from './components/column-options/column-options-list/column-options-list.component';
// import { EditColumnOptionsPreferencesComponent } from './components/column-options/edit-column-options-preferences/edit-column-options-preferences.component';
// import { ColumnOptionsButtonComponent } from './components/column-options/column-options-button/column-options-button.component';

import {
  AppDateInputComponent,
  CheckboxFieldComponent,
  DateRangeComponent,
  DatetimeRangeComponent,
  DropdownFieldComponent,
  FileFieldComponent,
  FormComponent,
  FormFieldGroupComponent,
  LazyDropdownComponent,
  FileInputComponent,
  PlateLettersComponent,
  RadioFieldComponent,
  TextareaFieldComponent,
  TextFieldComponent,
  UploaderComponent,
} from 'src/app/shared/components/forms';
import { TimeFieldComponent } from '@shared/components/forms/controls/time-field/time-field.component';
import { FieldErrorsComponent } from '@shared/components/forms/fields/field-errors.component';
import { ColumnOptionsComponent } from './components/column-options/column-options.component';
import { ColumnOptionsPreferencesComponent } from './components/column-options-preferences/column-options-preferences.component';
import { ColumnOptionsFieldListComponent } from './components/column-options-field-list/column-options-field-list.component';
import { SplitValueFieldComponent } from './components/splited-value-field/split-value-field.component';
import { ShowPasswordComponent } from './components/show-password/show-password.component';
import { GenerateComplexPasswordDialog } from './components/generate-complex-password-dialog/generate-complex-password-dialog.component';
import { OptionLabelDirective } from './directives/option-label.directive';
import { AnnouncementDialogComponent } from './components/announcement-dialog/announcement-dialog.component';
import { AnnouncementBarComponent } from './components/announcement-bar/announcement-bar.component';
import { provideSvgIcons, SvgIconComponent } from '@ngneat/svg-icon';
import { ICONS } from '@shared/constants/icons.constant';
import { IconComponent } from './components/icon/icon.component';
import { CustomSearchComponent } from './components/search/custom-search/custom-search.component';
import { OrderListLineItemsComponent } from './components/order-list-line-items/order-list-line-items.component';
import { LabeledAreaComponent } from '@shared/components/labeled-card/labeled-area.component';
import { TransactionHistoryDialogComponent } from './components/transaction-history-dialog/transaction-history-dialog.component';
import { PopoverComponent } from './components/popover/popover.component';
import { AddEditRentedTruckDialogComponent } from './components/add-edit-rented-truck-dialog/add-edit-rented-truck-dialog.component';
import { ActivityLogListComponent } from './components/activity-log-list/activity-log-list.component';
import { ActivityLogTemplateDirective } from '@shared/directives/activity-log-template.directive';
import { ShipmentListFilterComponent } from '@features/shipments/components/shipment-list-filter/shipment-list-filter.component';
import { ShipmentListFilterEditAndCreateComponent } from '@features/shipments/components/shipment-list-filter-edit-and-create/shipment-list-filter-edit-and-create.component';
import { ShipmentListFilterFormComponent } from '@features/shipments/components/shipment-list-filter-form/shipment-list-filter-form.component';
import { ShipmentListFormFavSectionComponent } from '@features/shipments/components/shipment-list-form-fav-section/shipment-list-form-fav-section.component';
import { ShipmentListPreviewFilterComponent } from '@features/shipments/components/shipment-list-preview-filter/shipment-list-preview-filter.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';

const MATERIAL_MODULES = [
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatIconModule,
  MatSelectModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTabsModule,
  MatButtonModule,
  MatTableModule,
  MatSortModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatRadioModule,
  MatBadgeModule,
  MatChipsModule,
  MatMenuModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDividerModule,
  MatCardModule,
  MatProgressBarModule,
  MatButtonToggleModule,
  ClipboardModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatListModule,
  MatStepperModule,
];

// tslint:disable-next-line:variable-name
const SHARED_MODUlES = [
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  GoogleMapsModule,
  NgOtpInputModule,
  DragDropModule,
];

const SHARED_PIPES = [
  ParenthesePipe,
  DurationPipe,
  LatLngPipe,
  FormatTimePipe,
  sortDropdownPipe,
  UserRoleCheckerPipe,
  EnumToArrayPipe,
  ResponsivePipe,
  FileSizePipe,
  TooltipListPipe,
];

const SHARED_COMPONENTS = [
  DropdownSearchComponent,
  PermissionDeniedPageComponent,
  SharedComponent,
  ReceiversListComponent,
  FixedSideLayoutComponent,
  LogoComponent,
  ConfirmationComponent,
  AppDropdownComponent,
  EntityListingComponent,
  PaginationComponent,
  SearchInputComponent,
  TimelineComponent,
  RowComponent,
  ColComponent,
  MarkerComponent,
  MapOverlayComponent,
  BarcodeComponent,
  AvatarComponent,
  LoadingComponent,
  SpinnerComponent,
  SnackBarComponent,
  StateSectionComponent,
  ClickStopPropagationDirective,
  DrawerComponent,
  ResponsiveDirective,
  ModalComponent,
  ExpandablePanelComponent,
  PageContentComponent,
  BreadcrumbComponent,
  FilterButtonComponent,
  TabComponent,
  AddNewButtonComponent,
  ExportButtonComponent,
  StatusLabelComponent,
  NotificationItemComponent,
  NotificationComponent,
  SequenceNumberComponent,
  PlateNumberComponent,
  ListFilterControlsComponent,
  ManufactureYearComponent,
  BirthDateComponent,
  StaticSectionComponent,
  ShipmentRequestHistoryComponent,
  RequestAndResponseLogComponent,
  MinimizableDialogComponent,
  RequestAndResponseLogComponent,
  MapClustererOverlayComponent,
  MapClustererComponent,
  FilterLayoutComponent,
  SearchBarComponent,
  ButtonComponent,
  SearchBarComponent,
  LabeledTextComponent,
  LabeledTextListComponent,
  FeatureFlagDirective,
  GrantAccessDirective,
  AddShipmentButtonComponent,
  CardSectionComponent,
  LabeledInfoListComponent,
  UploadedFileComponent,
  PrintSectionsComponent,
  ElmLogoComponent,
  ElmLogoComponent,
  StickyWidgetComponent,
  StickyThingDirective,
  DialogLayoutComponent,
  NavListComponent,
  LabeledInfoItemComponent,
  PopoverDirective,
  StatsCardComponent,
  AvatarMenuComponent,
  TrapezoidTabsLayoutComponent,
  SortMenuComponent,
  DragableFile,
  SortMenuComponent,
  RectTagComponent,
  OrdersAccordingComponent,
  OrderItemsCardComponent,
  AvlSummaryComponent,
  LineChartComponent,
  ShipmentsSummaryPerTruckComponent,
  PieChartComponent,
  LegendLabelsComponent,
  BaseChartComponent,
  GateToGateMarkerComponent,
  DateRangeComponent,
  UploaderComponent,
  LazyDropdownComponent,
  FileInputComponent,
  DatetimeRangeComponent,
  AppDateInputComponent,
  PlateLettersComponent,
  TimeFieldComponent,
  FormFieldGroupComponent,
  FieldErrorsComponent,
  TextFieldComponent,
  TextareaFieldComponent,
  CheckboxFieldComponent,
  FileFieldComponent,
  RadioFieldComponent,
  DropdownFieldComponent,
  FormComponent,
  ColumnOptionsComponent,
  ColumnOptionsPreferencesComponent,
  ColumnOptionsFieldListComponent,
  ShowPasswordComponent,
  GenerateComplexPasswordDialog,
  OptionLabelDirective,
  // ColumnOptionsFormComponent,
  // CreateColumnOptionsPreferencesComponent,
  // ColumnOptionsButtonComponent,
  // ColumnOptionsComponent,
  // ColumnOptionsListComponent,
  // EditColumnOptionsPreferencesComponent,
  AnnouncementBarComponent,
  AnnouncementDialogComponent,
  IconComponent,
  CustomSearchComponent,
  OrderListLineItemsComponent,
  TransactionHistoryDialogComponent,
  LabeledAreaComponent,
  AddEditRentedTruckDialogComponent,
  ActivityLogListComponent,
  ActivityLogTemplateDirective,
  ShipmentListFilterComponent,
  ShipmentListFilterEditAndCreateComponent,
  ShipmentListFilterFormComponent,
  ShipmentListFormFavSectionComponent,
  ShipmentListPreviewFilterComponent,
  DeleteButtonComponent,
];

@NgModule({
  declarations: [
    ...SHARED_PIPES,
    ...SHARED_COMPONENTS,
    ColumnOptionsFieldListComponent,
    ColumnOptionsPreferencesComponent,
    SplitValueFieldComponent,
    FieldDirective,
    PopoverComponent,
    ActivityLogListComponent,
    FileInputComponent,
  ],

  imports: [
    ...MATERIAL_MODULES,
    ...SHARED_MODUlES,
    CommonModule,
    RouterModule,
    OverlayModule,
    FilePondModule,
    ChartsModule,
    SvgIconComponent,
  ],
  exports: [
    ...MATERIAL_MODULES,
    ...SHARED_MODUlES,
    ...SHARED_PIPES,
    ...SHARED_COMPONENTS,
    RouterModule,
    ChartsModule,
    OrdersAccordingComponent,
    SplitValueFieldComponent,
    SvgIconComponent,
    PopoverComponent,
    ActivityLogListComponent,
    FileFieldComponent,
  ],
  providers: [
    CookieService,
    { provide: DateAdapter, useClass: DateFormat },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorLocalizationService(translateService).getPaginatorIntl(),
    },
    provideSvgIcons(ICONS),
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
  ],
})
export class SharedModule {
  constructor(private dateAdapter: DateAdapter<Date>, private languageService: LanguageService) {
    languageService.registerModule(i18n);
    this.languageService.languageChanged.subscribe((language) => {
      dateAdapter.setLocale(language.isoCode); // DD/MM/YYYY
    });
    dateAdapter.setLocale(this.languageService.currentLanguage$.value.isoCode); // DD/MM/YYYY
    registerLocaleData(ar);
  }
}
