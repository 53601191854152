<form class="madar--v2" [formGroup]="form" >
  <ng-content select="[extra-input]"></ng-content>

  <app-row>
    <app-col cols="12" md="6">
      <mat-label>{{ 'shipments.shipmentListFilter.customerName' | translate }}</mat-label>
      <app-lazy-dropdown
        formControlName="customersIds"
        [multiple]="true"
        [lookup]="LookupType.Customers"
        [label]="'shipments.shipmentListFilter.customer'"
      ></app-lazy-dropdown>
    </app-col>

    <app-col cols="12" md="6">
      <mat-label>{{ 'shipments.shipmentListFilter.customerLabels' | translate }}</mat-label>
      <app-lazy-dropdown
        multiple="true"
        formControlName="customerLabels"
        [lookup]="customerLabelsOptions"
        [label]="'shipments.shipmentListFilter.customerLabels'"
      ></app-lazy-dropdown>
    </app-col>

    <app-col cols="12" md="6">
      <mat-label>{{ 'shipments.shipmentListFilter.fleetName' | translate }}</mat-label>
      <app-lazy-dropdown
        formControlName="fleetsIds"
        [multiple]="true"
        [lookup]="LookupType.Fleets"
        [label]="'shipments.shipmentListFilter.fleet'"
      ></app-lazy-dropdown>
    </app-col>

    <app-col cols="12" md="6">
      <mat-label>{{ 'shipments.shipmentListFilter.createdBy' | translate }}</mat-label>
      <app-lazy-dropdown
        formControlName="creator"
        [lookup]="LookupType.Users"
        [multiple]="true"
        [label]="'shipments.shipmentListFilter.creator'"
      ></app-lazy-dropdown>
    </app-col>
    
    <app-col cols="12" md="6">
      <mat-label>{{ 'shipments.shipmentListFilter.AssignedBy' | translate }}</mat-label>
      <app-lazy-dropdown
        formControlName="assignedBy"
        [lookup]="LookupType.Users"
        [multiple]="true"
        [label]="'shipments.shipmentListFilter.AssignedBy'"
      ></app-lazy-dropdown>
    </app-col>
  </app-row>

  <mat-divider class="pos-static my-3"></mat-divider>

  <app-row gutter="xs" *ngIf="!isB2c">
    <app-col cols="auto">
      <mat-checkbox formControlName="isReceived">
        {{ "shipments.shipmentListFilter.receivedShipments" | translate}}
      </mat-checkbox>
    </app-col>

    <app-col cols="auto">
      <mat-checkbox formControlName="withoutPrice">
        {{ "shipments.shipmentListFilter.withoutPrice" | translate}}
      </mat-checkbox>
    </app-col>

    <app-col cols="auto">
      <mat-checkbox formControlName="withoutDeliveryNote">
        {{ "shipments.shipmentListFilter.withoutDN" | translate}}
      </mat-checkbox>
    </app-col>
  </app-row>
</form>
