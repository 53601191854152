import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { SortOptions } from '@shared/enums/sort-options.enum';
import { IconType } from '@shared/enums/icon-type.enum';
import { Icon } from '@shared/model/icon.model';

const DEFAULT_ICONS = {
  [SortOptions.MostRecent]: new Icon('bold-timer', IconType.SVG),
  [SortOptions.NameAZ]: new Icon('bold-arrow-down', IconType.SVG),
  [SortOptions.NameZA]: new Icon('bold-arrow-up', IconType.SVG),
};

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SortMenuComponent),
      multi: true,
    },
  ],
})
export class SortMenuComponent implements ControlValueAccessor, OnInit {
  @HostBinding('class.selectable-menu') className = true;
  @Input() label: string;
  @Input() translate: (key: string, value: string) => string;
  @Input() translationPrefix = 'shared.sortMenu';
  @Input() icons: { [key: string]: Icon } = DEFAULT_ICONS;
  @Input() triggerButtonColor: string;
  @Input() showLabel = true;

  @Input() set sortOptions(opts: { [key: string]: string }) {
    this.options = Object.keys(opts).map((key) => ({ key, value: opts[key] }));
    this.optionsIsSet = true;
    this.translationsTrigger$.next(true);
    this.changeDetectorRef.markForCheck();
  }
  @Input() set readonly(isReadOnly: boolean) {
    this.readonlyState = isReadOnly;
    this.changeDetectorRef.markForCheck();
  }

  get value(): any {
    return this.selectedValue$.getValue()?.value;
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  protected selectedValue$ = new BehaviorSubject<any>(null);
  protected options: { key: string; value: string }[] = [];
  protected optionsIsSet = false;
  protected readonlyState = false;
  protected translations: { [key: string]: string } = {};
  protected readonly IconType = IconType;
  private translationsTrigger$ = new BehaviorSubject<boolean>(true);
  private destroy$ = new Subject<boolean>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!this.optionsIsSet) {
      this.sortOptions = SortOptions;
      this.changeDetectorRef.markForCheck();
    }

    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$), startWith(true))
      .subscribe(() => {
        this.translationsTrigger$.next(true);
      });

    this.translationsTrigger$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => {
      const translate =
        this.translate && typeof this.translate === 'function'
          ? this.translate
          : this.translateDefaultFn.bind(this);
      this.translations = this.options.reduce((acc, { key, value }) => {
        acc[key] = translate(key, value);
        return acc;
      }, {});
      this.changeDetectorRef.markForCheck();
    });
  }

  writeValue(value: string) {
    if (this.onChange && this.onTouched) {
      const selectedOption = this.options.find((option) => option.value === value);
      if (selectedOption) {
        this.selectedValue$.next(selectedOption);
      } else {
        this.selectedValue$.next(this.options[0]);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  select(option) {
    this.selectedValue$.next(option);
    this.onTouched();
    this.onChange(this.value);
  }

  private translateDefaultFn(key: string, value: string) {
    return this.translateService.instant(`${this.translationPrefix}.${value}`);
  }
}
