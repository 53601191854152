<div class="pt-4" *ngIf="form && form.controls?.length">
  <div
    draggable="true"
    class="bg-white"
    *ngFor="let control of form.controls; let i = index;"
    (dragstart)="draggedColumnOrder = i"
    (dragover)="draggedOverColumnOrder = i"
    (dragend)="swapOrder()"
  >
    <app-row gutter="x-xs" align="center">
      <app-col cols="auto" class="pb-6">
        <app-icon name="drag_indicator" type="MaterialDesign" style="cursor: grab" class="text-500"></app-icon>
      </app-col>
      <app-col>
        <mat-form-field appearance="outline" class="d-block w-100">
          <mat-select [placeholder]="'Reports.SelectColumn' | translate" [formControl]="control">
            <mat-option *ngFor="let option of optionsPerColumn[i]" [value]="option.key">{{"Reports." + option.value | translate }}</mat-option>
          </mat-select>
          <mat-error *ngIf="control.invalid && control.touched && control.errors.required">{{ "ErrorMessages.Required" | translate }}</mat-error>
        </mat-form-field>
      </app-col>

      <app-col cols="auto" class="pb-6">
        <button mat-icon-button (click)="removeColumn(control.value, i)">
          <app-icon name="delete" type="MaterialDesign" class="text-error"></app-icon>
        </button>
      </app-col>
    </app-row>
  </div>

  <button class="btn--link" type="button" [disabled]="!((keys | enumToArray)?.length > form?.length && form.valid)" (click)="addMoreColumns()">
    {{ "Reports.AddMoreColumns" | translate }}
  </button>
</div>
